import React from 'react';
import { InputContainer, Label, SelectMul, Div, Error } from './styles';
var MultiSelect = function (_a) {
    var label = _a.label, type = _a.type, setSelectedOption = _a.setSelectedOption, errors = _a.errors, classNames = _a.classNames, options = _a.options, _b = _a.initialValues, initialValues = _b === void 0 ? [] || Object : _b, inputLabel = _a.inputLabel, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.isMulti, isMulti = _d === void 0 ? true : _d;
    return (React.createElement(InputContainer, { className: classNames, key: type },
        label && React.createElement(Label, null, label),
        React.createElement(SelectMul, { options: options, isMulti: isMulti, onChange: function (e) { return setSelectedOption(e); }, placeholder: inputLabel || 'Seleccionar', value: initialValues, className: classNames, isDisabled: disabled }),
        React.createElement(Div, null, errors && React.createElement(Error, null, errors[type]))));
};
export default MultiSelect;
