var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Collapse } from 'react-collapse';
import { M } from 'src/Utils/constants';
import { Container, ContentContainer, Subtitle } from './styles';
import PhoneCard from './PhoneCard';
import { useWindowWidth } from 'src/useWindowWidth';
import { CollapsableTitle } from '../Common';
import { openedArrow, closedArrow, ArrowBlue } from 'src/assets/Images';
var BuildingPhones = function (_a) {
    var data = _a.data, name = _a.name;
    var windowWidth = useWindowWidth();
    var isSmallScreen = M >= windowWidth;
    var _b = useState(!isSmallScreen), isOpen = _b[0], setIsOpen = _b[1];
    useEffect(function () {
        !isSmallScreen && setIsOpen(true);
    }, [isSmallScreen]);
    var PrevArrow = function (props) {
        var className = props.className, style = props.style, onClick = props.onClick;
        return (React.createElement("img", { className: className, src: ArrowBlue, style: __assign(__assign({}, style), { transform: 'rotate(180deg)', top: 'calc(50% - 10px)', marginLeft: '5px' }), onClick: onClick }));
    };
    var NextArrow = function (props) {
        var className = props.className, style = props.style, onClick = props.onClick;
        return (React.createElement("img", { className: className, src: ArrowBlue, style: __assign(__assign({}, style), { marginRight: '5px' }), onClick: onClick }));
    };
    return (React.createElement(ContentContainer, { key: name, className: isOpen ? '' : 'margin' },
        isSmallScreen ? (React.createElement(CollapsableTitle, { isOpen: isOpen, onClick: function () { return setIsOpen(function (prev) { return !prev; }); }, title: name, openImg: openedArrow, closeImg: closedArrow, className: "contact-dashboard" })) : (React.createElement(Subtitle, null, name)),
        isSmallScreen ? (React.createElement(Collapse, { isOpened: isOpen, style: { width: '100%' } },
            React.createElement(Container, null, data.map(function (item, index) { return (React.createElement(PhoneCard, { data: item, key: item.name, isLastItem: index == data.length - 1 })); })))) : (React.createElement(Slider, { nextArrow: React.createElement(NextArrow, null), prevArrow: React.createElement(PrevArrow, null), infinite: false, slidesToShow: data.length >= 3 ? 3 : data.length }, data.map(function (item, index) { return (React.createElement(PhoneCard, { data: item, key: item.name, className: data.length >= 3 ? 'center' : '' })); })))));
};
export default BuildingPhones;
