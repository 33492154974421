var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { components } from 'react-select';
import { Hr, OptionContainer } from './styles';
var Option = function (props) {
    var _a;
    var options = (_a = props.selectProps.options) !== null && _a !== void 0 ? _a : [];
    var isLastOption = props.data === options[options.length - 1];
    return (React.createElement(components.Option, __assign({}, props),
        React.createElement(OptionContainer, null,
            props.children,
            !isLastOption && (React.createElement(Hr, null)))));
};
export default Option;
