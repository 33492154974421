var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, S } from '../../../Utils';
export var Input = styled.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 43px;\n  box-shadow: none;\n  border-radius: 7px;\n  font-size: 18px;\n  font-family: ", ";\n  border: ", ";\n  padding: 10px 20px;\n  box-sizing: border-box;\n  &:focus {\n    outline: none;\n  }\n  &.smaller-size {\n    font-size: 14px;\n  } \n  &.xSmaller-size {\n    font-size: 8px;\n  }\n  &.minutes-size {\n    font-size: 14px;\n  }\n  &.border-admin {\n    border-radius: 7px;\n    border: ", ";\n    font-size: 16px;\n    padding: 12px 16px;\n  }\n  &.blue-background {\n    background-color: ", ";\n    border-radius: 2px;\n    border-top-right-radius: 0px;\n    border-bottom-right-radius: 0px;\n    border-right: 0px;\n    flex-grow: 1;\n  }\n  ", "\n  &.client-code {\n    border-radius: 7px;\n    margin-right: 12px;\n  }\n  &.new-code {\n    margin-right: 45px;\n  }\n  @media (max-width: ", "px) {\n    width: auto;\n    font-size: 14px;\n  }\n"], ["\n  height: 43px;\n  box-shadow: none;\n  border-radius: 7px;\n  font-size: 18px;\n  font-family: ", ";\n  border: ",
    ";\n  padding: 10px 20px;\n  box-sizing: border-box;\n  &:focus {\n    outline: none;\n  }\n  &.smaller-size {\n    font-size: 14px;\n  } \n  &.xSmaller-size {\n    font-size: 8px;\n  }\n  &.minutes-size {\n    font-size: 14px;\n  }\n  &.border-admin {\n    border-radius: 7px;\n    border: ",
    ";\n    font-size: 16px;\n    padding: 12px 16px;\n  }\n  &.blue-background {\n    background-color: ", ";\n    border-radius: 2px;\n    border-top-right-radius: 0px;\n    border-bottom-right-radius: 0px;\n    border-right: 0px;\n    flex-grow: 1;\n  }\n  ",
    "\n  &.client-code {\n    border-radius: 7px;\n    margin-right: 12px;\n  }\n  &.new-code {\n    margin-right: 45px;\n  }\n  @media (max-width: ", "px) {\n    width: auto;\n    font-size: 14px;\n  }\n"])), theme.typography.normal.raleway, function (_a) {
    var hasError = _a.hasError;
    return hasError ? "1px solid red" : "1px solid " + theme.palette.grey[25];
}, function (_a) {
    var hasError = _a.hasError;
    return hasError ? "1px solid color: " + theme.palette.error[50] + ";" : "none";
}, theme.palette.admin.primary[15], function (_a) {
    var isPassword = _a.isPassword;
    return isPassword
        ? " &.login, &.white {\n    border-top-right-radius: 0px;\n    border-bottom-right-radius: 0px;\n    border-right: 0px;\n    flex-grow: 1;\n  }"
        : '';
}, S);
export var Div = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  .MuiIconButton-root {\n    background-color: ", " !important;\n    border-top-right-radius: 2px !important;\n    border-bottom-right-radius: 2px !important;\n    border-top-left-radius: 0px !important;\n    border-bottom-left-radius: 0px !important;\n    height: 43px !important;\n    color: ", " !important;\n    border: ", ";\n    border-left: 0px !important;\n  }\n  &.login,\n  &.white {\n    .MuiIconButton-root {\n      background-color: ", " !important;\n      border-top-right-radius: 7px !important;\n      border-bottom-right-radius: 7px !important;\n      border-top-left-radius: 0px !important;\n      border-bottom-left-radius: 0px !important;\n      color: ", " !important;\n      border: ", ";\n      border-left: 0px !important;\n    }\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  .MuiIconButton-root {\n    background-color: ", " !important;\n    border-top-right-radius: 2px !important;\n    border-bottom-right-radius: 2px !important;\n    border-top-left-radius: 0px !important;\n    border-bottom-left-radius: 0px !important;\n    height: 43px !important;\n    color: ", " !important;\n    border: ", ";\n    border-left: 0px !important;\n  }\n  &.login,\n  &.white {\n    .MuiIconButton-root {\n      background-color: ", " !important;\n      border-top-right-radius: 7px !important;\n      border-bottom-right-radius: 7px !important;\n      border-top-left-radius: 0px !important;\n      border-bottom-left-radius: 0px !important;\n      color: ", " !important;\n      border: ", ";\n      border-left: 0px !important;\n    }\n  }\n"])), theme.palette.admin.primary[15], theme.palette.admin.primary[75], function (_a) {
    var hasError = _a.hasError;
    return (hasError ? "1px solid red" : "none");
}, theme.palette.white[100], theme.palette.black[100], function (_a) {
    var hasError = _a.hasError;
    return (hasError ? "1px solid red" : "none");
});
export var Image = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  padding-top: 5px;\n"], ["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  padding-top: 5px;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
