import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useFormContext } from 'react-hook-form';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AdminLayout } from 'src/Layouts';
import { CREATE_RESERVATION_TITLE, RESERVATION_SUCCESS_MESSAGE, ROUTES, } from 'src/Utils';
import { ReservationForm } from './ReservationForm/ReservationForm';
import { ExtraForm } from './ExtraForm/ExtraForm';
import { createReservation } from './services';
import { Container, InnerContainer, SuccessSnackbar, Title } from './styles';
import { useAppDispatch } from '../../redux/hooks';
import { setFormSelected } from '../../redux/slices/tableFiltersSlice';
import { formatDate, getSubmitedEnabled } from './utils';
var CreateReservation = function () {
    var push = useHistory().push;
    var dispatch = useAppDispatch();
    var _a = useFormContext(), watch = _a.watch, setValue = _a.setValue, errors = _a.formState.errors;
    var _b = watch(), amenity = _b.amenity, time = _b.time, clientCode = _b.clientCode, date = _b.date, extras = _b.extras, selectedExtras = _b.selectedExtras, startHour = _b.startHour, endHour = _b.endHour;
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var handleClick = function () { return setOpen(true); };
    var handleClose = function (event, reason) {
        if (reason === 'clickaway')
            return;
        setOpen(false);
        push(ROUTES.reservations);
    };
    var mutateCreateReservation = useMutation(createReservation, {
        onSuccess: function () {
            handleClick();
            setTimeout(function () { return push(ROUTES.reservations); }, 2000);
        },
    })[0];
    var handleCreateReservation = function () {
        return mutateCreateReservation({
            amenityId: Number(amenity.value),
            clientCode: clientCode,
            date: formatDate(date),
            scheduleId: time.value,
            extras: selectedExtras.reduce(function (acc, _a) {
                var name = _a.name, selected = _a.selected;
                if (selected)
                    acc.push(name);
                return acc;
            }, []),
            startHour: startHour,
            endHour: endHour,
        });
    };
    useEffect(function () {
        if (amenity.label) {
            var matchedAmenity = extras.find(function (item) { return item.name === amenity.label; });
            if (matchedAmenity)
                setValue('selectedExtras', matchedAmenity.extras);
        }
    }, [extras, amenity]);
    useEffect(function () {
        setValue('time', {
            label: '',
            value: 0,
            price: 0,
        });
    }, [amenity, date]);
    useEffect(function () {
        dispatch(setFormSelected(true));
    }, []);
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, null, CREATE_RESERVATION_TITLE),
            React.createElement(InnerContainer, null,
                React.createElement(ReservationForm, null),
                React.createElement(ExtraForm, { handleCreateReservation: handleCreateReservation, isSubmitEnabled: getSubmitedEnabled(watch, errors) }))),
        React.createElement(SuccessSnackbar, { anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            }, open: open, autoHideDuration: 2000, onClose: handleClose, message: RESERVATION_SUCCESS_MESSAGE, action: React.createElement(IconButton, { size: "small", color: "inherit", onClick: handleClose },
                React.createElement(CloseIcon, { fontSize: "small" })) })));
};
export default CreateReservation;
