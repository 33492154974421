import React from 'react';
import { Button } from '../Common';
import { BottomInputs, Container, FormContainer, Label, Title, Image, } from './styles';
var ResponseForm = function (_a) {
    var handleClick = _a.handleClick, buttonLabel = _a.buttonLabel, _b = _a.header, title = _b.title, description = _b.description, hasButton = _a.hasButton, icon = _a.icon;
    return (React.createElement(Container, null,
        React.createElement(Image, { src: icon }),
        React.createElement(Title, null, title),
        React.createElement(Label, { className: 'center' }, description),
        React.createElement(FormContainer, null, hasButton && (React.createElement(BottomInputs, null,
            React.createElement(Button, { className: "full-width", type: "submit", onClick: handleClick }, buttonLabel))))));
};
export default ResponseForm;
