var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { usePDF } from "@react-pdf/renderer";
import { useEffect } from "react";
import { downloadPDF } from "./utils";
var usePdfInstances = function (_a) {
    var document = _a.document, documentPrint = _a.documentPrint, dependencies = _a.dependencies, pdfName = _a.pdfName, handleFiles = _a.handleFiles;
    var _b = usePDF({ document: document }), instance = _b[0], updateInstance = _b[1];
    var _c = usePDF({ document: documentPrint }), instancePrintable = _c[0], updateInstancePrintable = _c[1];
    useEffect(function () {
        if (!instance.loading && !instance.blob) {
            updateInstance();
        }
        if (!instancePrintable.loading && !instancePrintable.blob) {
            updateInstancePrintable();
        }
        if (instance.blob &&
            !instance.loading &&
            instancePrintable.blob &&
            !instancePrintable.loading) {
            var url = '' + instance.url;
            var urlPrintable = '' + instancePrintable.url;
            downloadPDF(url, pdfName);
            downloadPDF(urlPrintable, pdfName + " Imprimible");
            var file = new File([instance === null || instance === void 0 ? void 0 : instance.blob], pdfName + ".pdf", {
                type: 'application/pdf',
            });
            var filePrint = new File([instancePrintable === null || instancePrintable === void 0 ? void 0 : instancePrintable.blob], pdfName + ".pdf", {
                type: 'application/pdf',
            });
            handleFiles(file, filePrint);
        }
    }, __spreadArrays(dependencies, [instance.loading, instancePrintable.loading]));
    return {
        instance: instance,
        instancePrintable: instancePrintable,
        isLoading: instance.loading || instancePrintable.loading
    };
};
export default usePdfInstances;
