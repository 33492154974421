var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useRef, useState } from 'react';
import { MultiSelectOption, MultiSelectValueContainer } from './index';
import { theme } from 'src/Utils';
import { InputContainer, Label, SelectMul, Div, Error } from './styles';
var componentStyles = {
    dropdownIndicator: function (base) { return (__assign(__assign({}, base), { color: theme.palette.primary[25] })); },
    indicatorSeparator: function () { return ({
        display: 'none',
    }); },
    placeholder: function (defaultStyles) { return (__assign(__assign({}, defaultStyles), { textTransform: 'none' })); },
    multiValueRemove: function () { return ({
        display: 'none',
    }); },
    control: function (base, state) { return (__assign(__assign({}, base), { borderColor: state.isFocused || (state.hasValue && !state.isMulti)
            ? theme.palette.blue[5]
            : 'transparent', boxShadow: '0px 0px 8px 0px rgba(173, 173, 173, 0.25)', '&:hover': {
            borderColor: theme.palette.blue[5],
            cursor: 'pointer',
        } })); },
    menu: function (base) { return (__assign(__assign({}, base), { borderColor: theme.palette.blue[5], border: "1px solid " + theme.palette.blue[5], boxShadow: '0px 0px 8px 0px rgba(173, 173, 173, 0.25)' })); },
};
var RealEstateMultiSelect = function (_a) {
    var label = _a.label, type = _a.type, setSelectedOption = _a.setSelectedOption, errors = _a.errors, classNames = _a.classNames, options = _a.options, inputLabel = _a.inputLabel, _b = _a.disabled, disabled = _b === void 0 ? false : _b, initialValues = _a.initialValues, _c = _a.isMulti, isMulti = _c === void 0 ? true : _c, customStyles = _a.customStyles, valueContainer = _a.valueContainer, optionField = _a.optionField, _d = _a.defaultValueContainer, defaultValueContainer = _d === void 0 ? false : _d, _e = _a.defaultOptionField, defaultOptionField = _e === void 0 ? false : _e;
    var _f = useState(false), menuOpen = _f[0], setMenuOpen = _f[1];
    var selectRef = useRef(null);
    var handleClickOutside = function (event) {
        if (selectRef.current &&
            !selectRef.current.contains(event.target)) {
            setMenuOpen(false);
        }
    };
    useEffect(function () {
        document.addEventListener('mousedown', handleClickOutside);
        return function () {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    var customComponents = {};
    if (!defaultValueContainer) {
        customComponents.ValueContainer =
            valueContainer || MultiSelectValueContainer;
    }
    if (!defaultOptionField) {
        customComponents.Option = optionField || MultiSelectOption;
    }
    return (React.createElement(InputContainer, { className: classNames, key: type, ref: selectRef },
        label && React.createElement(Label, null, label),
        React.createElement(SelectMul, { options: options, isMulti: isMulti, defaultValue: initialValues, onChange: function (e) { return setSelectedOption(e); }, placeholder: inputLabel || 'Seleccionar', className: classNames, isDisabled: disabled, styles: __assign(__assign({}, componentStyles), customStyles), isClearable: false, hideSelectedOptions: false, isSearchable: false, components: customComponents, menuIsOpen: menuOpen, onMenuOpen: function () { return setMenuOpen(true); }, onMenuClose: function () { return setMenuOpen(false); }, closeMenuOnSelect: !isMulti }),
        React.createElement(Div, null, errors && React.createElement(Error, null, errors[type]))));
};
export default RealEstateMultiSelect;
