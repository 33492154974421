import React from 'react';
import { SOLUTIONS_APP_DESCRIPTION, SOLUTIONS, SOLUTIONS_APP_TITLE, SOLUTIONS_EASY, SOLUTIONS_EASY_DESCRIPTION, M, } from '../../Utils';
import { Container } from './styles';
import { AppSectionImage, AppSectionMobileImage, } from '../../assets/Images';
import { AppComponent } from 'src/Components';
import { useWindowWidth } from 'src/useWindowWidth';
var items = [
    { title: SOLUTIONS_APP_TITLE, description: SOLUTIONS_APP_DESCRIPTION },
    { title: SOLUTIONS_EASY, description: SOLUTIONS_EASY_DESCRIPTION },
];
var AppSection = function () {
    var windowWidth = useWindowWidth();
    var smallScreen = windowWidth < M;
    return (React.createElement(Container, null,
        React.createElement(AppComponent, { items: items, title: SOLUTIONS, image: smallScreen ? AppSectionMobileImage : AppSectionImage })));
};
export default AppSection;
