import api from 'src/Utils/api';
export var SendForm = function (_a) {
    var values = _a.values, id = _a.id;
    return api.post("/real_estate/properties/" + id + "/contact", {
        contact: {
            name: values.name,
            email: values.email,
            phone: values.phone,
            message: values.query,
        },
    });
};
