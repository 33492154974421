var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { M, theme } from 'src/Utils';
export var Column = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: ", ";\n"])), function (_a) {
    var gap = _a.gap;
    return gap;
});
export var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  min-height: 100vh;\n  display: flex;\n  flexdirection: column;\n  margin-top: 20px;\n  @media (max-width: ", "px) {\n    width: 90%;\n    justify-content: center;\n  }\n"], ["\n  min-height: 100vh;\n  display: flex;\n  flexdirection: column;\n  margin-top: 20px;\n  @media (max-width: ", "px) {\n    width: 90%;\n    justify-content: center;\n  }\n"])), M);
export var TopContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding-bottom: 14px;\n  gap: 16px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding-bottom: 14px;\n  gap: 16px;\n"])));
export var Text = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: 20px;\n  opacity: ", ";\n  @media (max-width: ", "px) {\n    font-size: 12px;\n    font-family: ", ";\n  }\n"], ["\n  font-family: ", ";\n  font-size: 20px;\n  opacity: ", ";\n  @media (max-width: ", "px) {\n    font-size: 12px;\n    font-family: ", ";\n  }\n"])), theme.typography.bold.ralewayBold, function (_a) {
    var disable = _a.disable;
    return (disable ? 0 : 1);
}, M, theme.typography.normal.raleway);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
