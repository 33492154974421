import React from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { getData, theme } from 'src/Utils';
import { SettlementPDF } from 'src/Components/SettlementPDF/SettlementPDF';
import { PdfComponent } from 'src/Components/ReceiptForm';
import { Container, Card, Information, LoadingContainer } from './styles';
import { CLOSE_WINDOW, PDF_GENERATED } from 'src/Utils/constants';
import useGeneratePDF from './useGeneratePDF';
var PDFGenerator = function () {
    var params = useLocation();
    var id_settlement = new URLSearchParams(params.search).get('id_settlement');
    var id_receipt = new URLSearchParams(params.search).get('id_receipt');
    var receiptData = useQuery(['getReceiptMobile', id_receipt], function () { return getData("/mobile/common_expenses_receipts/" + id_receipt); }, { enabled: !!id_receipt }).data;
    var settlementData = useQuery(['getSettlementMobile', id_settlement], function () { return getData("/mobile/settlements/" + id_settlement); }, { enabled: !!id_settlement }).data;
    var documentReceipt = (React.createElement(PdfComponent, { holder: receiptData === null || receiptData === void 0 ? void 0 : receiptData.full_name, clientNumber: receiptData === null || receiptData === void 0 ? void 0 : receiptData.numeric_client_code, receiptNumber: receiptData === null || receiptData === void 0 ? void 0 : receiptData.receipt_number, location: receiptData === null || receiptData === void 0 ? void 0 : receiptData.address, issueDate: receiptData === null || receiptData === void 0 ? void 0 : receiptData.created_at, expirationDate: receiptData === null || receiptData === void 0 ? void 0 : receiptData.expiration_date, totalAmount: receiptData === null || receiptData === void 0 ? void 0 : receiptData.amount, description: receiptData === null || receiptData === void 0 ? void 0 : receiptData.description, currency: receiptData === null || receiptData === void 0 ? void 0 : receiptData.currency }));
    var pdfGeneratedResponse = (React.createElement(Container, null,
        React.createElement(Card, { className: "" },
            React.createElement(Information, { className: "title" }, PDF_GENERATED),
            React.createElement(Information, null, CLOSE_WINDOW))));
    var documentSettlement = (React.createElement(SettlementPDF, { building: settlementData === null || settlementData === void 0 ? void 0 : settlementData.building, end_date: settlementData === null || settlementData === void 0 ? void 0 : settlementData.end_date, start_date: settlementData === null || settlementData === void 0 ? void 0 : settlementData.start_date, information: settlementData === null || settlementData === void 0 ? void 0 : settlementData.information }));
    var isReceipt = !!id_receipt;
    var data = isReceipt ? receiptData : settlementData;
    var id = isReceipt ? id_receipt : id_settlement;
    var pdfComponent = isReceipt ? documentReceipt : documentSettlement;
    var _a = useGeneratePDF(pdfComponent, data, isReceipt, id), loading = _a.loading, error = _a.error;
    if (error)
        return React.createElement(React.Fragment, null, error);
    if (!data || loading)
        return (React.createElement(LoadingContainer, null,
            React.createElement("p", null, "Creating PDF"),
            React.createElement(BeatLoader, { size: "16px", color: theme.palette.primary[50] })));
    return React.createElement(React.Fragment, null, pdfGeneratedResponse);
};
export default PDFGenerator;
