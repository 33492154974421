var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { TableContainer as CommonTableContainer } from '../styles';
import { Label } from 'src/Components/Common/Form/styles';
export var Container = styled.section(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  min-height: 600px;\n  width: 100%;\n  padding: 4%;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n"], ["\n  display: flex;\n  min-height: 600px;\n  width: 100%;\n  padding: 4%;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n"])));
export var TableContainer = styled(CommonTableContainer)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: flex-start\n"], ["\n  align-items: flex-start\n"])));
export var FiltersContainer = styled.section(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: start;\n  margin: 20px 0;\n"], ["\n  display: flex;\n  align-items: start;\n  margin: 20px 0;\n"])));
export var FilterItem = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin-right: 15px;\n\n  label {\n    margin-right: 10px\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin-right: 15px;\n\n  label {\n    margin-right: 10px\n  }\n"])));
export var FilterItemButton = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  align-self: flex-end;\n  display: flex;\n  flex-direction: column;\n  margin-right: 15px;\n\n  label {\n    margin-right: 10px\n  }\n"], ["\n  align-self: flex-end;\n  display: flex;\n  flex-direction: column;\n  margin-right: 15px;\n\n  label {\n    margin-right: 10px\n  }\n"])));
export var BlackLabel = styled(Label)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: black;\n"], ["\n  color: black;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
