import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AdminLayout } from 'src/Layouts';
import { CONTACTS } from 'src/Utils';
import { Container, Title, StyledArrow, Link, } from '../styles';
import { BackIcon } from 'src/assets/Images';
import { ROUTES, EDIT_DETAILS } from 'src/Utils/constants';
import { getData } from 'src/Utils/api';
import { ContactForm } from 'src/Components/Common';
import { ApartmentForm } from 'src/Components/Common';
function createData(first, second, third) {
    return { first: first, second: second, third: third };
}
var ViewContact = function () {
    var history = useHistory();
    var id = useParams().id;
    var _a = useState([]), rows = _a[0], setRows = _a[1];
    var data = useQuery("getContactDetail", function () { return getData("/contacts/" + id); }, {
        onSuccess: function (data) {
            setRows(data.contact.apartments.map(function (_a) {
                var tower_name = _a.tower_name, building_name = _a.building_name, apartment_number = _a.apartment_number;
                return createData(tower_name, building_name, apartment_number);
            }));
        },
    }).data;
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, { className: "go-back", onClick: function () { return history.push(ROUTES.contacts); } },
                React.createElement(StyledArrow, { src: BackIcon, onClick: function () { return history.push(ROUTES.contacts); } }),
                CONTACTS),
            data && (React.createElement(React.Fragment, null,
                React.createElement(ContactForm, { name: data.contact.first_name, lastName: data.contact.last_name, mobile: data.contact.mobile, email: data.contact.email, address: data.contact.address }),
                React.createElement(ApartmentForm, { rows: rows }))),
            React.createElement(Link, { onClick: function () { return history.push(ROUTES.modify_contact + "/" + id); } }, EDIT_DETAILS))));
};
export default ViewContact;
