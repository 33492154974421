import React from 'react';
import { MapPin } from 'src/assets/Images';
import { useWindowWidth } from 'src/useWindowWidth';
import { M } from 'src/Utils';
import { Location, StyledTitle, Image, Cod, Container } from './styles';
import { Row } from '../../styles';
export var Title = function (_a) {
    var title = _a.title, location = _a.location, cod = _a.cod;
    var windowWidth = useWindowWidth();
    return (React.createElement(Container, null,
        React.createElement(StyledTitle, null, title),
        React.createElement(Row, { className: "space-between" },
            React.createElement(Row, { gap: "8px" },
                React.createElement(Image, { src: MapPin }),
                React.createElement(Location, null, location)),
            windowWidth <= M && React.createElement(Cod, null,
                "Cod: ",
                cod))));
};
