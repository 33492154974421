var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useEffect, useState, useCallback } from 'react';
import { BuildingFormContainer, BuildingFormDiv, DetailsContainer, Img, } from './styles';
import { BuildingForm, Pagination } from '.';
import { PlusIconWhite } from 'src/assets/Images';
var BuildingsComponent = function (_a) {
    var buildingInputs = _a.buildingInputs, setBuildingData = _a.setBuildingData, setBuildingErrors = _a.setBuildingErrors, buildingData = _a.buildingData, buildingErrors = _a.buildingErrors, formsBuilding = _a.formsBuilding, setFormsBuilding = _a.setFormsBuilding, setSelectedApartment = _a.setSelectedApartment, handleDelete = _a.handleDelete, setOldBuildingErrors = _a.setOldBuildingErrors, oldBuildingErrors = _a.oldBuildingErrors, oldFormsBuilding = _a.oldFormsBuilding, setOldFormsBuilding = _a.setOldFormsBuilding, successDelete = _a.successDelete, setSuccessDelete = _a.setSuccessDelete, setOldBuildingData = _a.setOldBuildingData, oldBuildingData = _a.oldBuildingData;
    var _b = useState(0), pageNumber = _b[0], setPageNumber = _b[1];
    var _c = useState(false), buttonTouched = _c[0], setButtonTouched = _c[1];
    var _d = useState(false), deleteButton = _d[0], setDeleteButton = _d[1];
    var _e = useState(-1), selectedIndex = _e[0], setSelectedIndex = _e[1];
    var formsPerPage = 2;
    var pagesVisited = pageNumber * formsPerPage;
    var addForm = function () {
        var initialBuildingData = {};
        var initialBuildingErrors = {};
        buildingInputs.forEach(function (_a) {
            var key = _a.key, value = _a.value;
            initialBuildingData[key] = value;
            initialBuildingErrors[key] = '';
        });
        setBuildingData(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[formsBuilding.length] = initialBuildingData, _a)));
        });
        setBuildingErrors(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[formsBuilding.length] = initialBuildingErrors, _a)));
        });
        setButtonTouched(true);
    };
    var deleteForm = function (index) {
        setBuildingData(function () {
            var newData = __assign({}, buildingData);
            delete newData[index];
            return newData;
        });
        setFormsBuilding(formsBuilding.filter(function (form) { return form.index !== index; }));
        setDeleteButton(true);
    };
    var deleteOldForm = function (index, apartmentId) {
        setSelectedApartment && setSelectedApartment(apartmentId);
        setSelectedIndex(index);
        handleDelete && handleDelete();
        setDeleteButton(true);
    };
    var removeFromList = useCallback(function () {
        if (setOldFormsBuilding &&
            oldFormsBuilding &&
            setSuccessDelete &&
            setOldBuildingData &&
            selectedIndex >= 0) {
            setOldFormsBuilding(oldFormsBuilding.filter(function (form) { return form.index !== selectedIndex; }));
            setOldBuildingData(oldBuildingData.filter(function (data, index) { return index !== selectedIndex; }));
            setSuccessDelete(false);
        }
    }, [selectedIndex]);
    useEffect(function () {
        successDelete && removeFromList();
    }, [successDelete]);
    useEffect(function () {
        var length = oldFormsBuilding
            ? oldFormsBuilding.length + formsBuilding.length
            : formsBuilding.length;
        if (length === 0 && deleteButton)
            addForm();
        setDeleteButton(false);
    }, [deleteButton]);
    var displayOldBuildings = oldFormsBuilding &&
        setOldBuildingErrors &&
        oldFormsBuilding.map(function (_a) {
            var setBuildingData = _a.setBuildingData, buildingData = _a.buildingData, buildingInputs = _a.buildingInputs, index = _a.index;
            return (React.createElement(DetailsContainer, { key: Math.random() },
                React.createElement(BuildingForm, { editRelation: true, deleteForm: deleteOldForm, setFormErrors: setOldBuildingErrors, setFormData: setBuildingData, formData: buildingData, errors: oldBuildingErrors, inputs: buildingInputs, index: index })));
        });
    var displayBuildings = formsBuilding.map(function (_a) {
        var setBuildingData = _a.setBuildingData, buildingData = _a.buildingData, buildingInputs = _a.buildingInputs, index = _a.index;
        return (React.createElement(DetailsContainer, { key: Math.random() },
            React.createElement(BuildingForm, { deleteForm: deleteForm, setFormErrors: setBuildingErrors, setFormData: setBuildingData, formData: buildingData, errors: buildingErrors, inputs: buildingInputs, index: index })));
    });
    useEffect(function () {
        if (buttonTouched) {
            var length_1 = oldFormsBuilding
                ? oldFormsBuilding.length + formsBuilding.length
                : formsBuilding.length;
            setFormsBuilding(function (prevState) { return __spreadArrays(prevState, [
                {
                    setBuildingData: setBuildingData,
                    buildingData: buildingData,
                    buildingInputs: buildingInputs,
                    index: formsBuilding.length,
                },
            ]); });
            setButtonTouched(false);
            setPageNumber(Math.ceil((length_1 + 1) / 2) - 1);
        }
    }, [buttonTouched]);
    return (React.createElement(React.Fragment, null,
        React.createElement(BuildingFormContainer, null,
            React.createElement(BuildingFormDiv, null, displayOldBuildings === null || displayOldBuildings === void 0 ? void 0 : displayOldBuildings.concat(displayBuildings).slice(pagesVisited, pagesVisited + formsPerPage)),
            React.createElement(Pagination, { arrayLength: oldFormsBuilding
                    ? oldFormsBuilding.length + formsBuilding.length
                    : formsBuilding.length, setPageNumber: setPageNumber, page: pageNumber, componentsPerPage: formsPerPage })),
        React.createElement(Img, { src: PlusIconWhite, onClick: addForm })));
};
export default BuildingsComponent;
