var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { Button } from 'src/Components/Common';
import { useWindowWidth } from 'src/useWindowWidth';
import { L } from 'src/Utils';
import { BottomForm } from './BottomForm/BottomForm';
import { defaultValues } from './utils';
import { Column, FormContainer } from './styles';
import { Divider } from '../../styles';
export var FormLoadingSkeleton = function () {
    var windowWidth = useWindowWidth();
    var formMethods = useForm({
        defaultValues: defaultValues,
        shouldUnregister: false,
    });
    return (React.createElement(FormProvider, __assign({}, formMethods),
        React.createElement(FormContainer, null,
            windowWidth > L && (React.createElement(React.Fragment, null,
                React.createElement(Column, { gap: "4px" },
                    React.createElement(Skeleton, { height: 28, width: "100%" }),
                    React.createElement(Column, { gap: "12px" },
                        React.createElement(Skeleton, { height: 35, width: "100%" }),
                        React.createElement(Skeleton, { height: 28, width: "100%" }))),
                React.createElement(Divider, null))),
            React.createElement(BottomForm, null),
            React.createElement(Button, { className: "full-width", disabled: true, type: "button" }, ' '))));
};
