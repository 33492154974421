var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { L, M, theme } from 'src/Utils';
export var Row = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  gap: ", ";\n  &.space-between {\n    justify-content: space-between;\n  }\n  &.align-center {\n    align-items: center;\n  }\n"], ["\n  display: flex;\n  gap: ", ";\n  &.space-between {\n    justify-content: space-between;\n  }\n  &.align-center {\n    align-items: center;\n  }\n"])), function (_a) {
    var gap = _a.gap;
    return gap;
});
export var Column = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: ", ";\n"])), function (_a) {
    var gap = _a.gap;
    return gap;
});
export var Container = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  width: 988px;\n  min-height: 270px;\n  box-shadow: 0px 0px 30px 0px rgba(111, 152, 201, 0.17);\n  border-radius: 10px;\n  cursor: pointer;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n    width: auto;\n    height: auto;\n  }\n  @media (max-width: ", "px) {\n    width: auto;\n  }\n"], ["\n  display: flex;\n  width: 988px;\n  min-height: 270px;\n  box-shadow: 0px 0px 30px 0px rgba(111, 152, 201, 0.17);\n  border-radius: 10px;\n  cursor: pointer;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n    width: auto;\n    height: auto;\n  }\n  @media (max-width: ", "px) {\n    width: auto;\n  }\n"])), M, L);
export var Img = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 380px;\n  height: 270px;\n  background-color: ", ";\n  border-radius: 10px 0px 0px 10px;\n  @media (max-width: ", "px) {\n    height: 270px;\n    border-radius: 10px 10px 0px 0px;\n  }\n  @media (min-width: ", "px) and (max-width: ", "px) {\n    width: 300px;\n  }\n"], ["\n  width: 380px;\n  height: 270px;\n  background-color: ", ";\n  border-radius: 10px 0px 0px 10px;\n  @media (max-width: ", "px) {\n    height: 270px;\n    border-radius: 10px 10px 0px 0px;\n  }\n  @media (min-width: ", "px) and (max-width: ", "px) {\n    width: 300px;\n  }\n"])), theme.palette.grey[45], M, M + 1, L);
export var InfoContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  padding: 23px;\n  justify-content: space-between;\n  @media (max-width: ", "px) {\n    width: auto;\n    padding: 16px;\n    height: 113px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  padding: 23px;\n  justify-content: space-between;\n  @media (max-width: ", "px) {\n    width: auto;\n    padding: 16px;\n    height: 113px;\n  }\n"])), M);
export var Price = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 700;\n  @media (max-width: ", "px) {\n    font-size: 18px;\n  }\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 700;\n  @media (max-width: ", "px) {\n    font-size: 18px;\n  }\n"])), theme.palette.blue[5], theme.typography.bold.ralewayBold, M);
export var Code = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  @media (max-width: ", "px) {\n    font-size: 12px;\n  }\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  @media (max-width: ", "px) {\n    font-size: 12px;\n  }\n"])), theme.palette.grey[50], theme.typography.normal.raleway, M);
export var Location = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 700;\n  @media (max-width: ", "px) {\n    font-size: 14px;\n  }\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 700;\n  @media (max-width: ", "px) {\n    font-size: 14px;\n  }\n"])), theme.palette.black[80], theme.typography.bold.ralewayBold, M);
export var Description = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  max-width: 482px;\n  display: -webkit-box;\n  -webkit-line-clamp: 3;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n  @media (max-width: ", "px) {\n    -webkit-line-clamp: 1;\n    max-width: 318px;\n    font-size: 14px;\n  }\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  max-width: 482px;\n  display: -webkit-box;\n  -webkit-line-clamp: 3;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n  @media (max-width: ", "px) {\n    -webkit-line-clamp: 1;\n    max-width: 318px;\n    font-size: 14px;\n  }\n"])), theme.palette.black[80], theme.typography.normal.raleway, M);
export var Info = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  @media (max-width: ", "px) {\n    font-size: 12px;\n  }\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  @media (max-width: ", "px) {\n    font-size: 12px;\n  }\n"])), theme.palette.grey[50], theme.typography.normal.raleway, M);
export var InfoImg = styled.img(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  @media (max-width: ", "px) {\n    width: 20px;\n    height: 20px;\n  }\n"], ["\n  @media (max-width: ", "px) {\n    width: 20px;\n    height: 20px;\n  }\n"])), M);
export var ImageContainer = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  height: 270px;\n  width: 380px;\n  @media (min-width: ", "px) and (max-width: ", "px) {\n    width: 300px;\n  }\n"], ["\n  height: 270px;\n  width: 380px;\n  @media (min-width: ", "px) and (max-width: ", "px) {\n    width: 300px;\n  }\n"])), M + 1, L);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
