import React from 'react';
import { Page, Text, Document } from '@react-pdf/renderer';
import { PAGE } from 'src/Utils/constants';
import { PDFWatermarks } from './PDFWatermakrs';
import { PDFHeader } from './PDFHeader';
import { PDFFirstSection } from './PDFFirstSection';
import { PDFSignatures } from './PDFSignatures';
import { PDFAuth } from './PDFAuth';
import { PDFInstructions } from './PDFInstructions';
import { PDFFooter } from './PDFFooter';
import styles from './styles';
export var PDFComponent = function (props) {
    var _a = props.printable, printable = _a === void 0 ? false : _a, type = props.type, building = props.building, date = props.date, place = props.place, fascioliAssists = props.fascioliAssists, modality = props.modality, link = props.link, orders = props.orders, schedules = props.schedules;
    return (React.createElement(Document, null,
        React.createElement(Page, { size: "A4", style: printable ? styles.page && styles.print : styles.page },
            React.createElement(PDFWatermarks, { printable: printable }),
            React.createElement(PDFHeader, { type: type, printable: printable }),
            React.createElement(PDFFirstSection, { building: building, schedules: schedules, date: date, place: place }),
            React.createElement(PDFInstructions, { fascioliAssists: fascioliAssists, modality: modality, link: link, orders: orders }),
            React.createElement(PDFAuth, { date: date, building: building }),
            React.createElement(PDFSignatures, { printable: true }),
            !printable && (React.createElement(React.Fragment, null,
                React.createElement(PDFFooter, null),
                React.createElement(Text, { style: styles.pageNumber, render: function (_a) {
                        var pageNumber = _a.pageNumber;
                        return PAGE + " " + pageNumber;
                    }, fixed: true }))),
            props.suspended && React.createElement(PDFWatermarks, { suspended: true, printable: printable }))));
};
export default PDFComponent;
