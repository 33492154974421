import React from 'react';
import { Img, CalendarContainer, DateContainer, Label, Div } from './styles';
import { FROM, UNTIL } from 'src/Utils';
import { Calendar } from 'src/assets/Images';
import CalendarComponent from '../Calendar';
import moment from 'moment';
export var CustomInput = React.forwardRef(function (_a, ref) {
    var value = _a.value, placeholder = _a.placeholder, onClick = _a.onClick;
    return (React.createElement(CalendarContainer, null,
        React.createElement("label", { onClick: onClick, ref: ref }, value || placeholder),
        React.createElement(Img, { src: Calendar, alt: "Calendar", className: "calendar", onClick: onClick })));
});
var renderDate = function (_a) {
    var isStartDate = _a.isStartDate, startDate = _a.startDate, setStartDate = _a.setStartDate, endDate = _a.endDate, setEndDate = _a.setEndDate, isContact = _a.isContact, className = _a.className;
    var startDateFormatted = startDate ? moment(startDate).toDate() : undefined;
    var endDateFormatted = endDate ? moment(endDate).toDate() : undefined;
    return (React.createElement(DateContainer, { className: className + " " + (isContact ? 'right' : '') },
        React.createElement(Label, null, (isStartDate ? FROM : UNTIL) + ": "),
        React.createElement(Div, null, isStartDate ? (React.createElement(CalendarComponent, { selected: startDateFormatted, onChange: setStartDate, selectsStart: true, startDate: startDateFormatted, endDate: endDateFormatted, customInput: React.createElement(CustomInput, null) })) : (React.createElement(CalendarComponent, { selected: endDateFormatted, onChange: setEndDate, selectsEnd: true, startDate: startDateFormatted, endDate: endDateFormatted, minDate: startDateFormatted, customInput: React.createElement(CustomInput, null) })))));
};
export default renderDate;
