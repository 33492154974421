import React from 'react';
import { Link } from 'react-router-dom';
import { Row } from '../../Common/Table/TableRow/styles';
import { SEE_CONTACTS } from 'src/Utils';
import { Cell } from './style';
var BuildingWithContactRow = function (_a) {
    var info = _a.info, widths = _a.widths, apartment = _a.apartment;
    var apartmentNumber = info === null || info === void 0 ? void 0 : info.first;
    var hasContacts = (apartment === null || apartment === void 0 ? void 0 : apartment.proprietary) || (apartment === null || apartment === void 0 ? void 0 : apartment.tenant);
    return (React.createElement(Row, { hover: true, tabIndex: -1, key: info.first },
        React.createElement(Cell, { style: { width: widths[0] } }, apartmentNumber),
        React.createElement(Cell, { style: { width: widths[1] } }, hasContacts ? (React.createElement(Link, { to: "/apartamentos/" + (apartment === null || apartment === void 0 ? void 0 : apartment.id) + "/contactos" }, SEE_CONTACTS)) : ('-'))));
};
export default BuildingWithContactRow;
