import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useFormContext } from 'react-hook-form';
import { AdminLayout } from '../../Layouts';
import { Container, InnerContainer, SuccessSnackbar, Title } from './styles';
import { ReservationForm } from './ReservationForm/ReservationForm';
import { EDIT_RESERVATION, RESERVATION_EDITED_SUCCESS_MESSAGE, ROUTES, } from '../../Utils';
import { ExtraForm } from './ExtraForm/ExtraForm';
import { editReservation } from './services';
import useBookingValues from './hooks/useBookingValues';
import { setFormSelected } from '../../redux/slices/tableFiltersSlice';
import { useAppDispatch } from '../../redux/hooks';
import { formatDate, getSubmitedEnabled } from '../CreateReservation/utils';
var EditReservation = function () {
    var dispatch = useAppDispatch();
    var id = useParams().id;
    var push = useHistory().push;
    var mutateEditReservation = useMutation(editReservation, {
        onSuccess: function () {
            handleClick();
            setTimeout(function () { return push(ROUTES.reservations); }, 2000);
        },
    })[0];
    var data = useBookingValues({ id: id }).data;
    var _a = useFormContext(), watch = _a.watch, errors = _a.formState.errors;
    var _b = watch(), amenity = _b.amenity, time = _b.time, selectedExtras = _b.selectedExtras, date = _b.date, startHour = _b.startHour, endHour = _b.endHour;
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var handleClick = function () { return setOpen(true); };
    var handleClose = function (event, reason) {
        if (reason === 'clickaway')
            return;
        setOpen(false);
        push(ROUTES.reservations);
    };
    var handleEditReservation = function () {
        return mutateEditReservation({
            id: id,
            amenityId: Number(amenity.value),
            clientCode: data === null || data === void 0 ? void 0 : data.numeric_client_code,
            date: formatDate(date),
            scheduleId: time.value,
            extras: selectedExtras.reduce(function (acc, _a) {
                var name = _a.name, selected = _a.selected;
                if (selected)
                    acc.push(name);
                return acc;
            }, []),
            startHour: startHour,
            endHour: endHour,
        });
    };
    useEffect(function () {
        dispatch(setFormSelected(true));
    }, []);
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, null, EDIT_RESERVATION),
            React.createElement(InnerContainer, null,
                React.createElement(ReservationForm, { data: data }),
                React.createElement(ExtraForm, { handleEditReservation: handleEditReservation, isSubmitEnabled: getSubmitedEnabled(watch, errors), data: data }))),
        React.createElement(SuccessSnackbar, { anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            }, open: open, autoHideDuration: 2000, onClose: handleClose, message: RESERVATION_EDITED_SUCCESS_MESSAGE, action: React.createElement(IconButton, { size: "small", color: "inherit", onClick: handleClose },
                React.createElement(CloseIcon, { fontSize: "small" })) })));
};
export default EditReservation;
