import { useEffect } from 'react';
import { ALL } from 'src/Utils';
var useClientCode = function (_a) {
    var selectedBuilding = _a.selectedBuilding, selectedTower = _a.selectedTower, selectedApartment = _a.selectedApartment, setClientCode = _a.setClientCode, placeData = _a.placeData;
    var getAptCode = function (apt) {
        return apt === 'Propietario' || apt === 'P'
            ? '16'
            : apt === 'Ambos' || apt === 'A'
                ? '01'
                : apt === 'Inquilino' || apt === 'I'
                    ? '09'
                    : '0';
    };
    var letterToNumber = function (letter) {
        var _a;
        var res = (_a = ((letter === null || letter === void 0 ? void 0 : letter.charCodeAt(0)) - 64)) === null || _a === void 0 ? void 0 : _a.toString();
        return (res === null || res === void 0 ? void 0 : res.length) === 1 ? '0' + res : res;
    };
    var getApartmentNumber = function (apartment) {
        while ((apartment === null || apartment === void 0 ? void 0 : apartment.length) < 4) {
            apartment = '0' + apartment;
        }
        return apartment;
    };
    var getTowerCode = function (code) {
        return code === '_' ? '00' : letterToNumber(code);
    };
    useEffect(function () {
        var _a, _b;
        var buildingNumberCode = '';
        var towerNumberCode = '';
        var apartmentNumber = '';
        var apartmentApt = '';
        buildingNumberCode = letterToNumber((_a = selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.code) === null || _a === void 0 ? void 0 : _a.slice(0, 1));
        if (selectedTower) {
            towerNumberCode = getTowerCode(selectedTower.code);
        }
        if ((selectedApartment === null || selectedApartment === void 0 ? void 0 : selectedApartment.label) !== ALL) {
            apartmentNumber = getApartmentNumber(selectedApartment === null || selectedApartment === void 0 ? void 0 : selectedApartment.label.toString());
        }
        apartmentApt = getAptCode(placeData === null || placeData === void 0 ? void 0 : placeData.value);
        setClientCode(buildingNumberCode + ((_b = selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.code) === null || _b === void 0 ? void 0 : _b.substring(1)) +
            towerNumberCode +
            (apartmentNumber !== ALL ? apartmentNumber : '') +
            apartmentApt);
    }, [
        setClientCode,
        placeData,
        selectedBuilding,
        selectedApartment,
        selectedTower,
    ]);
};
export default useClientCode;
