import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import Table from 'src/Components/GenericTable';
import { AdminLayout } from 'src/Layouts';
import { useWindowWidth } from 'src/useWindowWidth';
import { M, getData, PROVIDERS, PROVIDERS_API, NAME, BUILDINGS, PHONE, TYPE_OF_SERVICE, stableSort, getComparator, ADD_PROVIDER, ROUTES, PROVIDER_TEXT, BUILDING_API, BUILDING, } from 'src/Utils';
import { Container, TableContainer, Title, Image, TitleFilter, LeftContainer, Input, SelectCont, Img, } from '../styles';
import { DeleteModal, Popover, ProviderCard } from 'src/Components/Common';
import { Add, openedArrow } from 'src/assets/Images';
import { deleteProvider } from './duck';
var headCells = [
    { id: 'first', numeric: false, disablePadding: false, label: NAME },
    {
        id: 'second',
        numeric: false,
        disablePadding: false,
        label: TYPE_OF_SERVICE,
    },
    { id: 'third', numeric: false, disablePadding: false, label: BUILDINGS },
    { id: 'fourth', numeric: false, disablePadding: false, label: PHONE },
    { id: 'fifth', numeric: true, disablePadding: false, label: '' },
];
var WIDTHS = ['25%', '25%', '25%', '25%'];
var ProvidersPage = function () {
    var id = useParams().id;
    var history = useHistory();
    var windowWidth = useWindowWidth();
    var isSmallScreen = M >= windowWidth;
    var _a = useState(false), isDeleteModalOpen = _a[0], setIsDeleteModalOpen = _a[1];
    var _b = useState(), selectedProvider = _b[0], setSelectedProvider = _b[1];
    var _c = useState('first'), orderBy = _c[0], setOrderBy = _c[1];
    var _d = useState(), rows = _d[0], setRows = _d[1];
    var _e = useState([]), buildingList = _e[0], setBuildingList = _e[1];
    var _f = useState(buildingList === null || buildingList === void 0 ? void 0 : buildingList[0]), selectedBuilding = _f[0], setSelectedBuilding = _f[1];
    var _g = useState(null), anchorRefBuilding = _g[0], setAnchorRefBuilding = _g[1];
    var _h = useState(false), focused = _h[0], setFocused = _h[1];
    useEffect(function () {
        if (id) {
            setSelectedBuilding(buildingList === null || buildingList === void 0 ? void 0 : buildingList.find(function (building) { return building.value === id; }));
        }
    }, [id]);
    function createBuilding(label, value, rows) {
        return { label: label, value: value, rows: rows };
    }
    var handleClick = function (e, type) {
        var currentValue = e.currentTarget;
        if (anchorRefBuilding) {
            setAnchorRefBuilding(null);
        }
        else {
            setFocused(true);
            setAnchorRefBuilding(currentValue);
        }
    };
    var handleOutsideClick = function () {
        setAnchorRefBuilding(null);
    };
    var isLoadingBuildings = useQuery(BUILDING_API, function () { return getData("/buildings"); }, {
        refetchOnWindowFocus: false,
        onSuccess: function (data) {
            var buildings = data.buildings.map(function (_a) {
                var name = _a.name, id = _a.id;
                return createBuilding(name, id, rows);
            });
            buildings.sort(function (a, b) {
                return a.label.localeCompare(b.label, 'es');
            });
            setBuildingList(buildings);
            setSelectedBuilding(buildings[0]);
            if (id) {
                setSelectedBuilding(buildings.find(function (elem) { return elem.value === parseInt(id); }));
            }
        },
    }).isLoading;
    var isLoading = useQuery([PROVIDERS_API, isDeleteModalOpen, selectedBuilding], function () {
        return getData("/providers?building_id=" + (id ? id : selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value));
    }, {
        onSuccess: function (_a) {
            var providers = _a.providers;
            setRows(providers === null || providers === void 0 ? void 0 : providers.map(function (_a) {
                var name = _a.name, type_of_service = _a.type_of_service, phone = _a.phone, buildings = _a.buildings, id = _a.id;
                var buildingsName = buildings
                    .map(function (building) { return building.building_name; })
                    .join(', ');
                return {
                    first: name,
                    second: type_of_service,
                    third: buildingsName,
                    fourth: phone,
                    fifth: '',
                    id: id,
                };
            }));
        },
        retry: false,
    }).isLoading;
    var renderLeftContainer = function () { return (React.createElement(LeftContainer, { className: '' },
        React.createElement(React.Fragment, null,
            React.createElement(TitleFilter, null, BUILDING),
            React.createElement(SelectCont, { className: focused ? 'focused' : '', onClick: function (e) { return handleClick(e, 'building'); }, open: false, displayEmpty: true, renderValue: function () { return React.createElement("div", null, selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.label); }, value: selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.label, input: React.createElement(Input, null), IconComponent: function () { return React.createElement(Img, { src: openedArrow }); } })))); };
    var mutateDeleteProvider = useMutation(deleteProvider, {
        onSuccess: function () { return setIsDeleteModalOpen(function (prev) { return !prev; }); },
    })[0];
    var onDelete = function (id) {
        id && mutateDeleteProvider({ id: id });
    };
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, null, PROVIDERS),
            isSmallScreen && (React.createElement(Image, { src: Add, onClick: function () { return history.push(ROUTES.new_provider); } })),
            React.createElement(TableContainer, null,
                renderLeftContainer(),
                buildingList && (React.createElement(Popover, { data: buildingList, handleOutsideClick: handleOutsideClick, anchorRef: anchorRefBuilding, setSelected: setSelectedBuilding, isFilter: false, className: 'width' })),
                rows &&
                    (isSmallScreen ? (stableSort(rows, getComparator('asc', orderBy)).map(function (_a, index) {
                        var first = _a.first, second = _a.second, third = _a.third, fourth = _a.fourth;
                        return (React.createElement(ProviderCard, { key: first + index, first: first, second: second, third: third, fourth: fourth, handleDelete: onDelete, historyText: ROUTES.modify_provider }));
                    })) : (React.createElement(React.Fragment, null,
                        React.createElement(Table, { rows: rows, headCells: headCells, handleButtonClick: function () { return history.push(ROUTES.new_provider); }, modalButton: ADD_PROVIDER, widths: WIDTHS, topIndex: 5, className: "left", historyText: ROUTES.modify_provider, hasDots: true, shouldShowProviders: true, setProviderData: setSelectedProvider, handleDelete: function () { return setIsDeleteModalOpen(function (prev) { return !prev; }); } }))))),
            React.createElement(DeleteModal, { isOpen: isDeleteModalOpen, setIsOpen: setIsDeleteModalOpen, onDelete: function () { return onDelete(selectedProvider === null || selectedProvider === void 0 ? void 0 : selectedProvider.id); }, itemLabel: PROVIDER_TEXT, toDelete: "" + (selectedProvider === null || selectedProvider === void 0 ? void 0 : selectedProvider.first) }))));
};
export default ProvidersPage;
