import api from 'src/Utils/api';
export var submitProviderForm = function (_a) {
    var name = _a.name, type_of_service = _a.type_of_service, phone = _a.phone, buildingsArray = _a.buildingsArray;
    var buildings = buildingsArray.map(function (building) { return building.value; });
    return api.post('/providers', {
        provider: {
            name: name,
            type_of_service: type_of_service,
            phone: phone,
            buildings: buildings,
        },
    });
};
export var deleteProvider = function (_a) {
    var id = _a.id;
    return api.delete("/providers/" + id);
};
export var submitEditedProviderForm = function (_a) {
    var name = _a.name, type_of_service = _a.type_of_service, phone = _a.phone, buildingsArray = _a.buildingsArray, id = _a.id;
    var buildings = buildingsArray.map(function (building) { return building.value; });
    return api.patch("/providers/" + id, {
        provider: {
            name: name,
            type_of_service: type_of_service,
            phone: phone,
            buildings: buildings,
        },
    });
};
