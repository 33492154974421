import React from 'react';
import GoogleMapReact from 'google-map-react';
import { Container, MapContainer, ContentContainer, Title, Sub_Title, Description, Content, StyledDiv, DivContainer, StyledSpan, } from './styles';
import { FASCIOLI_LOCATION } from 'src/Utils';
var ContactMap = function (_a) {
    var _b = _a.locationInfo, title = _b.title, hours = _b.hours, box_text = _b.box_text;
    var description = box_text.split(' ');
    var renderMarkers = function (map, maps) {
        var infowindow = new maps.InfoWindow({
            content: '<div style=font-weight:bold;color:black;> Fascioli Administraciones - Gastos Comunes </div>',
            maxWidth: 190,
            borderRadius: '7px !important',
        });
        var marker = new maps.Marker({
            position: FASCIOLI_LOCATION,
            map: map,
            visible: true,
            optimized: false,
            animation: maps.Animation.DROP,
        });
        marker.addListener('click', function () {
            infowindow.open(map, marker);
        });
    };
    return (React.createElement(Container, null,
        React.createElement(MapContainer, null,
            React.createElement(GoogleMapReact, { bootstrapURLKeys: {
                    key: process.env.REACT_APP_GOOGLE_API_KEY || '',
                }, yesIWantToUseGoogleMapApiInternals: true, defaultCenter: FASCIOLI_LOCATION, defaultZoom: 15, onGoogleApiLoaded: function (_a) {
                    var map = _a.map, maps = _a.maps;
                    return renderMarkers(map, maps);
                } })),
        React.createElement(ContentContainer, null,
            React.createElement(Title, null, title),
            hours.map(function (_a) {
                var months_title = _a.months_title, days_title = _a.days_title, hours = _a.hours;
                return (React.createElement(Content, { key: months_title },
                    React.createElement(Sub_Title, null, months_title),
                    React.createElement(Description, null, days_title),
                    hours.map(function (hour) { return (React.createElement(Description, { key: hour }, hour)); })));
            }),
            React.createElement(DivContainer, null,
                React.createElement(StyledDiv, null, description.map(function (word, index) { return (React.createElement(StyledSpan, { className: index % 2 == 0 && index != 0 ? 'bold' : '', key: index }, word + " ")); }))))));
};
export default ContactMap;
