import React from 'react';
import { CloseCross } from 'src/assets/Images';
import { PROPERTY_IMAGE_ALT } from 'src/Utils';
import { ButtonContainer, Icon, ItemContainer, ItemImage } from './styles';
var GalleryItem = function (onClick, isMobile) {
    return function (_a) {
        var original = _a.original;
        return (React.createElement(ItemContainer, { isMobile: isMobile },
            React.createElement(ButtonContainer, { onClick: onClick },
                React.createElement(Icon, { src: CloseCross })),
            React.createElement(ItemImage, { isMobile: isMobile, src: original, className: "image-gallery-image", alt: PROPERTY_IMAGE_ALT })));
    };
};
export default GalleryItem;
