var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useState, useEffect } from 'react';
var useIdParam = function (_a) {
    var rows = _a.rows, setRows = _a.setRows, buildingList = _a.buildingList, setSelectedBuilding = _a.setSelectedBuilding, towerList = _a.towerList, setSelectedTower = _a.setSelectedTower, apartmentList = _a.apartmentList, setSelectedApartment = _a.setSelectedApartment, _b = _a.idRowAttribute, idRowAttribute = _b === void 0 ? 'id' : _b, refetch = _a.refetch;
    var queryParams = new URLSearchParams(window.location.search);
    var id = queryParams.get('id');
    var buildingId = queryParams.get('building_id');
    var apartmentId = queryParams.get('apartment_id');
    var towerId = queryParams.get('tower_id');
    var _c = useState(false), queryParamUsed = _c[0], setQueryParamUsed = _c[1];
    var _d = useState(!!id), isLoading = _d[0], setIsLoading = _d[1];
    var _e = useState(-1), originalRowsLength = _e[0], setOriginalRowsLength = _e[1];
    useEffect(function () {
        if (buildingList &&
            buildingList.length > 0 &&
            buildingId &&
            !queryParamUsed) {
            var building = buildingList.find(function (build) { return build.value == buildingId; });
            if (building)
                setSelectedBuilding(building);
        }
    }, [buildingList]);
    useEffect(function () {
        if (apartmentList && apartmentList.length > 0 && apartmentId) {
            var apartment = apartmentList.find(function (ap) { return ap.id == apartmentId; });
            if (apartment) {
                apartment = __assign(__assign({}, apartment), { label: apartment.number });
                setSelectedApartment === null || setSelectedApartment === void 0 ? void 0 : setSelectedApartment(apartment);
            }
        }
    }, [apartmentList]);
    useEffect(function () {
        if (towerList && towerList.length > 0 && towerId) {
            var tower = towerList === null || towerList === void 0 ? void 0 : towerList.find(function (tw) { return tw.value == towerId; });
            if (tower)
                setSelectedTower === null || setSelectedTower === void 0 ? void 0 : setSelectedTower(tower);
        }
    }, [towerList]);
    useEffect(function () {
        if (rows && rows.length > 0) {
            if (originalRowsLength === -1)
                setOriginalRowsLength(rows.length);
            var row = rows.find(function (row) { return row[idRowAttribute] == id; });
            if (row && rows.length > 1 && !queryParamUsed) {
                setRows === null || setRows === void 0 ? void 0 : setRows([row]);
                setQueryParamUsed(true);
            }
            else
                setIsLoading(false);
        }
    }, [rows]);
    var shouldUseIdParam = id && !queryParamUsed;
    var handleBuildingChange = function (building) {
        if ((rows === null || rows === void 0 ? void 0 : rows.length) === 1 && originalRowsLength > 1)
            refetch === null || refetch === void 0 ? void 0 : refetch();
        setSelectedBuilding(building);
    };
    return {
        shouldUseIdParam: shouldUseIdParam,
        setQueryParamUsed: setQueryParamUsed,
        isLoading: isLoading,
        handleBuildingChange: handleBuildingChange,
    };
};
export default useIdParam;
