import React from 'react';
import { Container, Image, Title, Description, ContentDiv, Line, } from './styles';
import { BlueTelephone } from 'src/assets/Images';
var Card = function (_a) {
    var _b = _a.data, phone = _b.phone, name = _b.name, isLastItem = _a.isLastItem, className = _a.className;
    return (React.createElement(Container, { className: className },
        React.createElement(Title, null, name),
        React.createElement(ContentDiv, null,
            React.createElement(Image, { className: "description", src: BlueTelephone }),
            React.createElement(Description, null, phone)),
        !isLastItem && React.createElement(Line, null)));
};
export default Card;
