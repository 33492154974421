import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { BACK_ERROR, ROUTES, ASSEMBLY, PRINTABLE, BUILDING, } from 'src/Utils/constants';
import { SummonForm } from 'src/Components/NewSummonForm';
import { AdminLayout } from 'src/Layouts';
import { NEW_SUMMON } from 'src/Utils';
import { Container, Title } from './styles';
import { submitDataForm, submitPreviewPDF, submitPrintablePDF, sendSummon, } from './duck';
var EditSummon = function () {
    var history = useHistory();
    var _a = useState(''), backError = _a[0], setBackError = _a[1];
    var _b = useState(), pdfData = _b[0], setPdfData = _b[1];
    var _c = useState(), receiptId = _c[0], setReceiptId = _c[1];
    var _d = useState(), instance = _d[0], setInstance = _d[1];
    var _e = useState(), instancePrint = _e[0], setInstancePrint = _e[1];
    var _f = useState(false), shouldSend = _f[0], setShouldSend = _f[1];
    var location = useLocation();
    var _g = useParams(), label = _g.label, id = _g.id;
    var mutateSendSummon = useMutation(sendSummon)[0];
    var buildingId = id;
    var mutateSummon = useMutation(submitDataForm, {
        onSuccess: function (response) {
            setBackError('');
            var id = response.data.id;
            setReceiptId(id);
            history.push({
                pathname: ROUTES.summons,
                state: { buildingId: buildingId }
            });
        },
        onError: function (error) {
            var errorMessage = error.response
                ? error.response.data.error
                : BACK_ERROR;
            setShouldSend(false);
            setBackError(errorMessage);
        },
    })[0];
    var mutatePreviewPDF = useMutation(submitPreviewPDF, {
        onSuccess: function () {
            if (instance === null || instance === void 0 ? void 0 : instance.url) {
                var tempLink = window.document.createElement('a');
                var filename = ASSEMBLY + "_" + (pdfData === null || pdfData === void 0 ? void 0 : pdfData.date);
                tempLink.setAttribute('download', filename);
                tempLink.setAttribute('target', '_blank');
                tempLink.href = instance.url;
                tempLink.click();
            }
        },
    })[0];
    var mutatePrintablePDF = useMutation(submitPrintablePDF, {
        onSuccess: function () {
            if (instancePrint === null || instancePrint === void 0 ? void 0 : instancePrint.url) {
                var tempLink = window.document.createElement('a');
                var filename = ASSEMBLY + "_" + (pdfData === null || pdfData === void 0 ? void 0 : pdfData.date) + "_" + PRINTABLE;
                tempLink.setAttribute('download', filename);
                tempLink.setAttribute('target', '_blank');
                tempLink.href = instancePrint.url;
                tempLink.click();
            }
        },
    })[0];
    useEffect(function () {
        if (receiptId && !(instance === null || instance === void 0 ? void 0 : instance.loading) && !(instancePrint === null || instancePrint === void 0 ? void 0 : instancePrint.loading)) {
            var file = new File([instance === null || instance === void 0 ? void 0 : instance.blob], ASSEMBLY + "_" + (pdfData === null || pdfData === void 0 ? void 0 : pdfData.date) + ".pdf", {
                type: 'application/pdf',
            });
            mutatePreviewPDF({ file: file, receiptId: receiptId });
            var filePrint = new File([instancePrint === null || instancePrint === void 0 ? void 0 : instancePrint.blob], ASSEMBLY + "_" + (pdfData === null || pdfData === void 0 ? void 0 : pdfData.date) + ".pdf", {
                type: 'application/pdf',
            });
            mutatePrintablePDF({ file: filePrint, receiptId: receiptId });
        }
        if (receiptId && shouldSend)
            mutateSendSummon(receiptId);
    }, [receiptId, instance, instancePrint]);
    var submitForm = function (summon_type, set_date, time_first_call, time_second_call, place, fascioli_assists, modality, link, orders_of_the_day, instance, instancePrint, building, place_specification) {
        mutateSummon({
            summon_type: summon_type,
            building_id: building === null || building === void 0 ? void 0 : building.value,
            set_date: set_date,
            time_first_call: time_first_call,
            time_second_call: time_second_call,
            place: place.value,
            fascioli_assists: fascioli_assists,
            modality: modality,
            link: link,
            orders_of_the_day: orders_of_the_day,
            place_specification: place_specification,
        });
        setPdfData({
            date: set_date,
        });
        setInstance(instance);
        setInstancePrint(instancePrint);
    };
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, { className: "non-margin" }, NEW_SUMMON + " - " + BUILDING + ": " + label),
            React.createElement(SummonForm, { onSubmit: submitForm, backError: backError, setShouldSend: setShouldSend, buildingLabel: label, buildingId: id, rowData: location.state }))));
};
export default EditSummon;
