var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { ExtraCheckbox } from 'src/Components';
import { AdminButton } from 'src/Components/Common/AdminButton';
import { ADD_EXTRAS, CANCEL, SAVE, TOTAL_COST } from 'src/Utils';
import { getTimePrice } from '../utils';
import { handleAmenityCurrency } from 'src/Views/MaintenancePages/ReservationPage/utils';
import BlocksAmenityMessage from '../BlocksAmenity/BlocksAmenityMessage';
import { BoxContainer, ButtonsContainer, ButtonsRow, CheckboxesContainer, Label, Link, Text, TextContainer, } from '../styles';
export var ExtraForm = function (_a) {
    var handleCreateReservation = _a.handleCreateReservation, isSubmitEnabled = _a.isSubmitEnabled;
    var goBack = useHistory().goBack;
    var _b = useFormContext(), setValue = _b.setValue, watch = _b.watch;
    var _c = watch(), selectedExtras = _c.selectedExtras, amenity = _c.amenity, time = _c.time, startHour = _c.startHour, endHour = _c.endHour;
    var currencyToShow = handleAmenityCurrency(amenity.currency || 'pesos');
    var price = useMemo(function () {
        var initialPrice = getTimePrice(amenity, time, startHour, endHour);
        return selectedExtras.reduce(function (acc, _a) {
            var selected = _a.selected, price = _a.price;
            var accFloat = parseFloat(acc.toString());
            var priceFloat = parseFloat(price.toString());
            return selected ? accFloat + priceFloat : accFloat;
        }, parseFloat(initialPrice.toString()));
    }, [time, startHour, endHour, selectedExtras]);
    var handleSelected = function (index) {
        var updatedExtras = selectedExtras.map(function (extra, i) {
            return i === index ? __assign(__assign({}, extra), { selected: !extra.selected }) : extra;
        });
        setValue('selectedExtras', updatedExtras);
    };
    return (React.createElement(BoxContainer, null,
        time.label && React.createElement(Label, { className: "extra" },
            "Precio base (por ",
            time.pricePerUse ? 'uso' : 'hora',
            "): ",
            time.price,
            " ",
            currencyToShow),
        !!selectedExtras.length && React.createElement(Label, { className: "extra" }, ADD_EXTRAS),
        React.createElement(CheckboxesContainer, null, selectedExtras.map(function (_a, index) {
            var name = _a.name, price = _a.price, selected = _a.selected, currency = _a.currency;
            return (React.createElement(ExtraCheckbox, { key: name + "-" + index, name: name, price: price, selected: selected, currency: currency, setSelected: function () { return handleSelected(index); } }));
        })),
        React.createElement(TextContainer, null,
            React.createElement(Text, null,
                TOTAL_COST,
                ":"),
            React.createElement(Text, null, currencyToShow === 'UR'
                ? (price >= 0 ? price : '') + " " + currencyToShow
                : currencyToShow + " " + (price >= 0 ? price : ''))),
        React.createElement(ButtonsContainer, null,
            React.createElement(BlocksAmenityMessage, null),
            React.createElement(ButtonsRow, null,
                React.createElement(Link, { onClick: goBack }, CANCEL),
                React.createElement(AdminButton, { type: "button", onClick: handleCreateReservation, className: "height", disabled: !isSubmitEnabled }, SAVE)))));
};
