import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Table, Filter, AdminCard } from 'src/Components/Common';
import { M } from 'src/Utils';
import { useWindowWidth } from 'src/useWindowWidth';
import { stableSort, getComparator } from 'src/Utils/utils';
import { FIRST, TYPE_CONTACT } from 'src/Utils/constants';
import { TableContainer } from './styles';
var FilterTable = function (_a) {
    var rows = _a.rows, headCells = _a.headCells, building = _a.building, tower = _a.tower, apartment = _a.apartment, buildingList = _a.buildingList, apartmentList = _a.apartmentList, towerList = _a.towerList, width = _a.width, userWidth = _a.userWidth, handleButtonClick = _a.handleButtonClick, isModalOpen = _a.isModalOpen, setIsModalOpen = _a.setIsModalOpen, isAssembly = _a.isAssembly, isInvoice = _a.isInvoice, componentStartDate = _a.startDate, componentSetStartDate = _a.setStartDate, componentEndDate = _a.endDate, componentSetEndDate = _a.setEndDate, componentSelectedBuilding = _a.selectedBuilding, componentSetSelectedBuilding = _a.setSelectedBuilding, componentSelectedTower = _a.selectedTower, componentSetSelectedTower = _a.setSelectedTower, componentSelectedApartment = _a.selectedApartment, componentSetSelectedApartment = _a.setSelectedApartment, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b, modalButton = _a.modalButton, modalField = _a.modalField, modalTitle = _a.modalTitle, handleModalSubmit = _a.handleModalSubmit, handleDeleteRow = _a.handleDeleteRow, _c = _a.dateFilter, dateFilter = _c === void 0 ? true : _c, _d = _a.isPaymentHistory, isPaymentHistory = _d === void 0 ? false : _d, setSelectedId = _a.setSelectedId, _e = _a.hasDateFilter, hasDateFilter = _e === void 0 ? true : _e, hasDelete = _a.hasDelete, isSettlement = _a.isSettlement, refetch = _a.refetch, onPageChange = _a.onPageChange, totalPages = _a.totalPages, _f = _a.paginated, paginated = _f === void 0 ? false : _f, page = _a.page, isDocument = _a.isDocument, buttonDisabled = _a.buttonDisabled, handleRowClick = _a.handleRowClick, _g = _a.showDownloadPDF, showDownloadPDF = _g === void 0 ? false : _g, _h = _a.compareDateWithoutHours, compareDateWithoutHours = _h === void 0 ? false : _h, _j = _a.isBookings, isBookings = _j === void 0 ? false : _j, _k = _a.hasDots, hasDots = _k === void 0 ? false : _k, handleView = _a.handleView, handleEdit = _a.handleEdit, handleDelete = _a.handleDelete;
    var _l = useState(componentSelectedBuilding), selectedBuilding = _l[0], setSelectedBuilding = _l[1];
    var _m = useState(componentSelectedTower), selectedTower = _m[0], setSelectedTower = _m[1];
    var _o = useState(componentSelectedApartment), selectedApartment = _o[0], setSelectedApartment = _o[1];
    var _p = useState(''), searchedItem = _p[0], setSearchedItem = _p[1];
    var _q = useState(), startDate = _q[0], setStartDate = _q[1];
    var _r = useState(), endDate = _r[0], setEndDate = _r[1];
    var _s = useState(FIRST), orderBy = _s[0], setOrderBy = _s[1];
    var windowWidth = useWindowWidth();
    var isSmallScreen = M >= windowWidth;
    var isContact = Cookies.get('User') === TYPE_CONTACT;
    useEffect(function () {
        if (isContact &&
            buildingList &&
            buildingList[0] &&
            buildingList.length === 1 &&
            setSelectedBuilding) {
            setSelectedBuilding(buildingList[0]);
        }
    }, [buildingList, isContact]);
    useEffect(function () {
        if (isContact &&
            buildingList &&
            buildingList[0] &&
            buildingList.length === 1 &&
            componentSetSelectedBuilding) {
            componentSetSelectedBuilding(buildingList[0]);
        }
    }, [buildingList, isContact]);
    useEffect(function () {
        if (isContact && towerList && towerList[0] && componentSetSelectedTower) {
            componentSetSelectedTower(towerList[0]);
        }
    }, [towerList, isContact]);
    useEffect(function () {
        if (isContact &&
            apartmentList &&
            apartmentList[0] &&
            componentSetSelectedApartment) {
            componentSetSelectedApartment(apartmentList[0]);
        }
    }, [apartmentList, isContact]);
    useEffect(function () {
        if (selectedBuilding && refetch) {
            refetch();
        }
    }, [selectedBuilding]);
    var renderFilter = function () { return (React.createElement(Filter, { building: (componentSelectedBuilding === null || componentSelectedBuilding === void 0 ? void 0 : componentSelectedBuilding.label) ||
            (selectedBuilding ? selectedBuilding.label : building === null || building === void 0 ? void 0 : building.label), tower: (componentSelectedTower === null || componentSelectedTower === void 0 ? void 0 : componentSelectedTower.label) ||
            (selectedTower ? selectedTower.label : tower === null || tower === void 0 ? void 0 : tower.label), apartment: (componentSelectedApartment === null || componentSelectedApartment === void 0 ? void 0 : componentSelectedApartment.label) ||
            (selectedApartment ? selectedApartment.label : apartment === null || apartment === void 0 ? void 0 : apartment.label), setBuilding: componentSetSelectedBuilding || setSelectedBuilding, setTower: componentSetSelectedTower || setSelectedTower, setApartment: componentSetSelectedApartment || setSelectedApartment, searchedItem: searchedItem, setSearchedItem: setSearchedItem, startDate: componentStartDate || startDate, setStartDate: componentSetStartDate || setStartDate, endDate: componentEndDate || endDate, setEndDate: componentSetEndDate || setEndDate, setOrderBy: setOrderBy, orderBy: orderBy, hasSearch: false, data: buildingList, towerList: towerList, apartmentList: apartmentList, sortData: [
            { label: 'Apartamento', value: 'Apartamento' },
            { label: 'Fecha', value: 'Fecha' },
        ], isPaymentHistory: isPaymentHistory, hasDateFilter: hasDateFilter, showDownloadPDF: showDownloadPDF })); };
    return (React.createElement(TableContainer, null,
        dateFilter ? renderFilter() : React.createElement("br", null),
        isSmallScreen ? (stableSort(rows, getComparator('asc', orderBy)).map(function (row, index) {
            return row.first.includes(searchedItem) && (React.createElement(AdminCard, { item: row, key: row.first + index, isSettelmentPage: isContact, isAssembly: isAssembly }));
        })) : (React.createElement(Table, { rows: rows.filter(function (row) { return row.first.includes(searchedItem); }), headCells: headCells, hasModal: !isContact, hasButton: !isContact, className: isContact ? 'width' : '', widths: isContact ? userWidth : width, handleButtonClick: handleButtonClick, modalButton: modalButton, modalField: modalField, modalTitle: modalTitle, isModalOpen: isModalOpen, setIsModalOpen: setIsModalOpen, building: componentSelectedBuilding, isAssembly: isAssembly, isInvoice: isInvoice, checkSelect: isInvoice, isLoading: isLoading, handleModalSubmit: handleModalSubmit, handleDeleteRow: handleDeleteRow, isPaymentHistory: isPaymentHistory, setSelectedId: setSelectedId, hasDelete: hasDelete, isSettlement: isSettlement, onPageChange: onPageChange, totalPages: totalPages, paginated: paginated, page: page, isDocument: isDocument, buttonDisabled: buttonDisabled, handleRowClick: handleRowClick, compareDateWithoutHours: compareDateWithoutHours, isBookings: isBookings, hasDots: hasDots, handleView: handleView, handleEdit: handleEdit, handleDelete: handleDelete }))));
};
export default FilterTable;
