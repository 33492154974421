var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, XS } from '../../../Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 80%;\n  max-width: 450px;\n  background-color: ", ";\n  border-radius: 16px;\n  padding: 35px;\n  @media (max-width: ", "px) {\n    width: 70%;\n  }\n"], ["\n  width: 80%;\n  max-width: 450px;\n  background-color: ", ";\n  border-radius: 16px;\n  padding: 35px;\n  @media (max-width: ", "px) {\n    width: 70%;\n  }\n"])), theme.palette.white[100], XS);
export var RowContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  &.right {\n    align-items: flex-end;\n  }\n  &.close {\n    .MuiSvgIcon-root {\n      width: 15px;\n      cursor: pointer;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  &.right {\n    align-items: flex-end;\n  }\n  &.close {\n    .MuiSvgIcon-root {\n      width: 15px;\n      cursor: pointer;\n    }\n  }\n"])));
export var Label = styled.label(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 16px;\n  margin-top: 14px;\n  text-align: center;\n  font-family: ", ";\n  &.bold {\n    font-family: ", ";\n    margin: 0px 4px;\n  }\n  &.cancel {\n    font-size: 14px;\n    text-decoration-line: underline;\n    cursor: pointer;\n  }\n  &.question {\n    margin-left: -4px;\n  }\n"], ["\n  font-size: 16px;\n  margin-top: 14px;\n  text-align: center;\n  font-family: ", ";\n  &.bold {\n    font-family: ", ";\n    margin: 0px 4px;\n  }\n  &.cancel {\n    font-size: 14px;\n    text-decoration-line: underline;\n    cursor: pointer;\n  }\n  &.question {\n    margin-left: -4px;\n  }\n"])), theme.typography.normal.raleway, theme.typography.bold.ralewayBold);
export var Center = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n"], ["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n"])));
export var DeleteButton = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  border-radius: 7px;\n  display: flex;\n  align-items: center;\n  width: 160px;\n  height: 43px;\n  margin-top: 26px;\n  justify-content: center;\n  color: ", ";\n  background: ", ";\n  cursor: pointer;\n"], ["\n  border-radius: 7px;\n  display: flex;\n  align-items: center;\n  width: 160px;\n  height: 43px;\n  margin-top: 26px;\n  justify-content: center;\n  color: ", ";\n  background: ", ";\n  cursor: pointer;\n"])), theme.palette.white[100], theme.palette.error[50]);
export var Text = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: inline-block;\n  text-align: center;\n  margin-top: 16px;\n"], ["\n  display: inline-block;\n  text-align: center;\n  margin-top: 16px;\n"])));
export var ErrorMessage = styled.label(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: 16px;\n  color: ", ";\n  font-size: 12px;\n  margin-bottom: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 0px;\n  padding-left: 10px;\n"], ["\n  margin-top: 16px;\n  color: ", ";\n  font-size: 12px;\n  margin-bottom: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 0px;\n  padding-left: 10px;\n"])), theme.palette.error[50]);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
