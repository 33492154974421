import React from 'react';
import moment from 'moment';
import { DownloadRE } from 'src/assets/Images';
import { ALL, AMOUNT, CLIENT_CODE, COLLECTION_MANAGEMENT, DATE_FORMAT_MOMENT, DOWNLOAD, PAYED_AT, PAYMENT_DATE_FROM, PAYMENT_DATE_TO, PAYMENT_METHOD, PAYMENT_PROVIDER, RECEIPT_NUMBER, STATE, } from 'src/Utils';
import { Container, StyledPDFDownloadLink, Text } from './styles';
import useReceipts from '../../useReceipts';
import BasicPDF from '../../../../../Components/Common/BasicPDF/BasicPDF';
function createData(receipt_number, numeric_client_code, amount, payed_at, status, payment_method) {
    return {
        receipt_number: receipt_number,
        numeric_client_code: numeric_client_code,
        amount: amount,
        payed_at: payed_at,
        status: status,
        payment_method: payment_method,
    };
}
export var DownloadButton = function (_a) {
    var _b, _c, _d, _e, _f;
    var customerCode = _a.customerCode, paymentProvider = _a.paymentProvider, paymentStatus = _a.paymentStatus, startDate = _a.startDate, endDate = _a.endDate;
    var enabled = customerCode || (paymentProvider && paymentStatus && startDate);
    var _g = useReceipts(customerCode, paymentProvider, paymentStatus, startDate, endDate, null, enabled), isLoadingReceipts = _g.isLoading, data = _g.data;
    var rows = ((_b = data === null || data === void 0 ? void 0 : data.receipts) === null || _b === void 0 ? void 0 : _b.map(function (_a) {
        var receipt_number = _a.receipt_number, numeric_client_code = _a.numeric_client_code, amount = _a.amount, payed_at = _a.payed_at, status = _a.status, payment_method = _a.payment_method;
        return createData(receipt_number, numeric_client_code, amount, payed_at, status, payment_method);
    })) || [];
    var headerRows = [
        CLIENT_CODE + ": " + (customerCode !== null && customerCode !== void 0 ? customerCode : ALL),
        PAYMENT_PROVIDER + ": " + ((_d = (_c = paymentProvider) === null || _c === void 0 ? void 0 : _c.label) !== null && _d !== void 0 ? _d : ALL),
        STATE + ": " + ((_f = (_e = paymentStatus) === null || _e === void 0 ? void 0 : _e.label) !== null && _f !== void 0 ? _f : ALL),
        PAYMENT_DATE_FROM + ": " + (startDate ? moment(startDate).format(DATE_FORMAT_MOMENT) : ''),
        PAYMENT_DATE_TO + ": " + (endDate ? moment(endDate).format(DATE_FORMAT_MOMENT) : ''),
    ];
    var headers = [
        RECEIPT_NUMBER,
        CLIENT_CODE,
        AMOUNT,
        PAYED_AT,
        STATE,
        PAYMENT_METHOD,
    ];
    return rows && rows.length > 0 ? (React.createElement(StyledPDFDownloadLink, { document: React.createElement(BasicPDF, { rows: rows, headers: headers, headerRows: headerRows, title: COLLECTION_MANAGEMENT }), fileName: 'recibos.pdf' },
        React.createElement(Container, null,
            React.createElement("img", { src: DownloadRE }),
            React.createElement(Text, null, DOWNLOAD)))) : (React.createElement(React.Fragment, null));
};
