import React from 'react';
import { Page, Text, Document, View } from '@react-pdf/renderer';
import styles from './styles';
import Header from './Header';
import { Table } from './Table';
import Footer from './Footer';
export var BasicPDF = function (_a) {
    var headers = _a.headers, headerRows = _a.headerRows, rows = _a.rows, title = _a.title;
    return (React.createElement(Document, null,
        React.createElement(Page, { size: "A4", style: styles.page },
            React.createElement(Header, { title: title }),
            React.createElement(View, { style: styles.informationContainer }, headerRows === null || headerRows === void 0 ? void 0 : headerRows.map(function (row) { return (React.createElement(Text, { key: row, style: styles.information }, row)); })),
            React.createElement(Table, { headers: headers, rows: rows })),
        React.createElement(Footer, null)));
};
export default BasicPDF;
