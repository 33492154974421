import React from 'react';
import { IconButton } from '@material-ui/core';
import { ArrowLeft, ArrowRight } from 'src/assets/Images';
import { PaginationLabel } from '../Common/Table/styles';
import { PaginationContainer } from './styles';
import { NEXT_PAGE, PREVIOUS_PAGE } from 'src/Utils/constants';
var Pagination = function (_a) {
    var arrayLength = _a.arrayLength, setPageNumber = _a.setPageNumber, page = _a.page, componentsPerPage = _a.componentsPerPage;
    var pageCount = Math.ceil(arrayLength / componentsPerPage);
    return (React.createElement(PaginationContainer, null,
        React.createElement(IconButton, { onClick: function () { return setPageNumber(page - 1); }, disabled: !page, "aria-label": "previous page" },
            React.createElement("img", { src: ArrowLeft, alt: PREVIOUS_PAGE })),
        React.createElement(PaginationLabel, null, page + 1 + "/" + pageCount),
        React.createElement(IconButton, { onClick: function () { return setPageNumber(page + 1); }, disabled: page >= pageCount - 1, "aria-label": "next page" },
            React.createElement("img", { src: ArrowRight, alt: NEXT_PAGE }))));
};
export default Pagination;
