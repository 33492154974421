var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@react-pdf/styled-components';
import { theme } from 'src/Utils';
export var BlueRow = styled.View(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-sizing: border-box;\n  width: 100%;\n  padding: 12px 20px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  gap: 20px;\n  background-color: ", ";\n  border-radius: 2px;\n"], ["\n  box-sizing: border-box;\n  width: 100%;\n  padding: 12px 20px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  gap: 20px;\n  background-color: ", ";\n  border-radius: 2px;\n"])), theme.palette.primary[14]);
export var WhiteRow = styled.View(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  box-sizing: border-box;\n  width: 100%;\n  padding: 10px 20px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  gap: 20px;\n  border-radius: 2px;\n"], ["\n  box-sizing: border-box;\n  width: 100%;\n  padding: 10px 20px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  gap: 20px;\n  border-radius: 2px;\n"])));
export var LightBlueRow = styled.View(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  box-sizing: border-box;\n  width: 100%;\n  padding: 10px 20px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  gap: 20px;\n  background-color: ", ";\n  border-radius: 2px;\n"], ["\n  box-sizing: border-box;\n  width: 100%;\n  padding: 10px 20px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  gap: 20px;\n  background-color: ", ";\n  border-radius: 2px;\n"])), theme.palette.primary[10]);
export var BoldText = styled.Text(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: Courier;\n  font-weight: bold;\n  font-size: 14px;\n  color: ", ";\n  width: 100%;\n  word-wrap: break-word;\n"], ["\n  font-family: Courier;\n  font-weight: bold;\n  font-size: 14px;\n  color: ", ";\n  width: 100%;\n  word-wrap: break-word;\n"])), theme.palette.black[80]);
export var BoldTextRight = styled.Text(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  font-family: Courier;\n  font-weight: bold;\n  font-size: 14px;\n  color: ", ";\n  text-align: right;\n  word-wrap: break-word;\n"], ["\n  width: 100%;\n  font-family: Courier;\n  font-weight: bold;\n  font-size: 14px;\n  color: ", ";\n  text-align: right;\n  word-wrap: break-word;\n"])), theme.palette.black[80]);
export var Text = styled.Text(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  font-family: Courier;\n  font-weight: normal;\n  font-size: 10px;\n  color: ", ";\n  word-wrap: break-word;\n"], ["\n  width: 100%;\n  font-family: Courier;\n  font-weight: normal;\n  font-size: 10px;\n  color: ", ";\n  word-wrap: break-word;\n"])), theme.palette.black[80]);
export var TextRight = styled.Text(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  font-family: Courier;\n  font-weight: normal;\n  font-size: 12px;\n  color: ", ";\n  text-align: right;\n  word-wrap: break-word;\n"], ["\n  width: 100%;\n  font-family: Courier;\n  font-weight: normal;\n  font-size: 12px;\n  color: ", ";\n  text-align: right;\n  word-wrap: break-word;\n"])), theme.palette.black[80]);
export var PageView = styled.View(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  box-sizing: border-box;\n  position: relative;\n  height: 100%;\n  width: 100%;\n  padding: 20px 40px;\n"], ["\n  box-sizing: border-box;\n  position: relative;\n  height: 100%;\n  width: 100%;\n  padding: 20px 40px;\n"])));
export var Header = styled.View(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  background-color: ", ";\n  border-radius: 4px;\n  justify-content: space-between;\n  min-height: 6%;\n  max-height: 6%;\n  margin-bottom: 40px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  background-color: ", ";\n  border-radius: 4px;\n  justify-content: space-between;\n  min-height: 6%;\n  max-height: 6%;\n  margin-bottom: 40px;\n"])), theme.palette.primary[50]);
export var TextHeader = styled.Text(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: 20px;\n  font-family: Courier;\n  font-weight: bold;\n  color: ", ";\n  text-align: left;\n  margin: 10px;\n  margin-left: 20px;\n"], ["\n  font-size: 20px;\n  font-family: Courier;\n  font-weight: bold;\n  color: ", ";\n  text-align: left;\n  margin: 10px;\n  margin-left: 20px;\n"])), theme.palette.white[100]);
export var ImageHeader = styled.Image(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 60px;\n  height: 30px;\n  margin: 10px;\n  margin-right: 20px;\n"], ["\n  width: 60px;\n  height: 30px;\n  margin: 10px;\n  margin-right: 20px;\n"])));
export var FooterContainer = styled.View(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  width: 100%;\n  position: absolute;\n  bottom: 20px;\n  left: 40px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n"], ["\n  width: 100%;\n  position: absolute;\n  bottom: 20px;\n  left: 40px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n"])));
export var Footer = styled.View(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  width: 100%;\n  height: 46px;\n  background-color: ", ";\n  border-radius: 4px;\n  margin-top: 5%;\n"], ["\n  width: 100%;\n  height: 46px;\n  background-color: ", ";\n  border-radius: 4px;\n  margin-top: 5%;\n"])), theme.palette.primary[50]);
export var TextFooter = styled.Text(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  font-family: Courier;\n  font-weight: normal;\n  text-align: center;\n  color: ", ";\n  font-size: 8px;\n  margin-top: 10px;\n"], ["\n  font-family: Courier;\n  font-weight: normal;\n  text-align: center;\n  color: ", ";\n  font-size: 8px;\n  margin-top: 10px;\n"])), theme.palette.white[100]);
export var ImagesFooterView = styled.View(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  flex-direction: row;\n  align-self: center;\n  margin-bottom: 10px;\n"], ["\n  flex-direction: row;\n  align-self: center;\n  margin-bottom: 10px;\n"])));
export var ImageFooter = styled.Image(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  width: 9px;\n  height: 9px;\n  margin: 5px;\n  margin-top: 5px;\n"], ["\n  width: 9px;\n  height: 9px;\n  margin: 5px;\n  margin-top: 5px;\n"])));
export var PageNumberText = styled.Text(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  font-size: 8px;\n  text-align: center;\n  color: ", ";\n  margin-top: 1%;\n"], ["\n  font-size: 8px;\n  text-align: center;\n  color: ", ";\n  margin-top: 1%;\n"])), theme.palette.grey[30]);
export var BodyText = styled.Text(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  font-family: Courier;\n  font-weight: normal;\n  font-size: 10px;\n  line-height: 190%;\n  color: ", ";\n  text-align: center;\n  margin-top: 8%;\n  margin-bottom: 25%;\n"], ["\n  font-family: Courier;\n  font-weight: normal;\n  font-size: 10px;\n  line-height: 190%;\n  color: ", ";\n  text-align: center;\n  margin-top: 8%;\n  margin-bottom: 25%;\n"])), theme.palette.black[80]);
export var BodyImage = styled.Image(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  position: absolute;\n  bottom: 160px;\n  width: 30%;\n  margin: 0 auto;\n  max-height: 100px;\n  align-self: center;\n"], ["\n  position: absolute;\n  bottom: 160px;\n  width: 30%;\n  margin: 0 auto;\n  max-height: 100px;\n  align-self: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19;
