var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { Container } from './styles';
import { S, CREATE_ACCOUNT, CLIENT_CODE_KEY, INSTRUCTIONS_CREATE_ACCOUNT, INSTRUCTIONS_SENT, BACK_ERROR, EMAIL_ERROR, INVALID_CLIENT_CODE, } from 'src/Utils';
import { LandingTick, LoginBackground, LoginMobileBackground, } from 'src/assets/Images';
import { useWindowWidth } from 'src/useWindowWidth';
import Footer from '../Footer';
import { Header } from 'src/Layouts';
import CreateAccountForm from 'src/Components/CreateAccountForm';
import { createAccount } from './duck';
import { isValidForm } from 'src/Utils/validations';
import { ResponseForm } from 'src/Components/ResetPasswordForm';
var CreateAccount = function () {
    var windowWidth = useWindowWidth();
    var smallScreen = windowWidth > S;
    var _a = useState(false), selected = _a[0], setSelected = _a[1];
    var _b = useState(false), checkboxError = _b[0], setCheckboxError = _b[1];
    var _c = useState(''), backError = _c[0], setBackError = _c[1];
    var _d = useState({}), error = _d[0], setError = _d[1];
    var _e = useState({
        direction: '',
        email: '',
        phone: '',
        surname: '',
        name: '',
    }), data = _e[0], setData = _e[1];
    var _f = useState([
        { value: '', id: '0' },
    ]), clientCodes = _f[0], setClientCodes = _f[1];
    var _g = useMutation(createAccount, {
        onSuccess: function () {
            setBackError('');
        },
        onError: function (error) {
            var _a, _b, _c, _d;
            var errorBackend = '';
            if ((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) {
                errorBackend = error.response.data.error;
            }
            else if ((_d = (_c = error.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.errors) {
                if (error.response.data.errors.email) {
                    errorBackend = EMAIL_ERROR;
                }
                else {
                    errorBackend = INVALID_CLIENT_CODE;
                }
            }
            else {
                errorBackend = BACK_ERROR;
            }
            setBackError(errorBackend);
        },
    }), mutateCreateAccount = _g[0], _h = _g[1], isLoading = _h.isLoading, isSuccess = _h.isSuccess;
    var onSubmit = function () {
        var _a;
        setCheckboxError(!selected);
        if (isValidForm(__assign(__assign({}, data), (_a = {}, _a[CLIENT_CODE_KEY] = clientCodes, _a)), setError) &&
            selected) {
            var filteredCodes_1 = [];
            clientCodes.forEach(function (_a) {
                var value = _a.value;
                if (value)
                    return filteredCodes_1.push(value);
            });
            mutateCreateAccount({
                address: data.direction,
                email: data.email,
                mobile: data.phone,
                last_name: data.surname,
                first_name: data.name,
                client_codes: filteredCodes_1,
            });
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, null),
        React.createElement(Container, { style: {
                backgroundImage: "url(" + (smallScreen ? LoginBackground : LoginMobileBackground) + ")",
            } }, isSuccess ? (React.createElement(ResponseForm, { header: {
                title: "" + INSTRUCTIONS_SENT,
                description: "" + INSTRUCTIONS_CREATE_ACCOUNT + data.email,
            }, icon: LandingTick })) : (React.createElement(CreateAccountForm, { title: CREATE_ACCOUNT, clientCodes: clientCodes, setClientCodes: setClientCodes, checkboxError: checkboxError, setCheckboxError: setCheckboxError, selected: selected, setSelected: setSelected, error: error, backError: backError, data: data, setData: setData, onSubmit: onSubmit, isLoading: isLoading }))),
        React.createElement(Footer, null)));
};
export default CreateAccount;
