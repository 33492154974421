import React from 'react';
import moment from 'moment';
import { DownloadArrowButton } from 'src/assets/Images';
import { AMENITY, BUILDING, CLIENT_CODE, DATE, DATE_FORMAT_MOMENT, DOWNLOAD_REPORT, PRICE, RESERVATIONS, RESERVATION_DATE_FROM, RESERVATION_DATE_TO, SCHEDULE, } from 'src/Utils';
import { Container, DownloadText, StyledPDFDownloadLink } from './styles';
import useBookings from '../../hooks/useBookings';
import BasicPDF from '../../../../../Components/Common/BasicPDF/BasicPDF';
import { useAppSelector } from '../../../../../redux/hooks';
export var DownloadButton = function () {
    var _a = useAppSelector(function (state) { return state.tableFiltersSlice; }), startDate = _a.startDate, endDate = _a.endDate, selectedApartment = _a.selectedApartment, selectedBuilding = _a.selectedBuilding, selectedTower = _a.selectedTower;
    var headerRows = [
        "" + (startDate
            ? RESERVATION_DATE_FROM + ": " + moment(startDate).format(DATE_FORMAT_MOMENT)
            : ''),
        "" + (endDate
            ? RESERVATION_DATE_TO + ": " + moment(endDate).format(DATE_FORMAT_MOMENT)
            : ''),
        "" + (selectedBuilding ? "Edificio: " + (selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.label) : ''),
        "" + (selectedTower ? "Torre: " + (selectedTower === null || selectedTower === void 0 ? void 0 : selectedTower.label) : ''),
        "" + (selectedApartment ? "Apartamento: " + (selectedApartment === null || selectedApartment === void 0 ? void 0 : selectedApartment.label) : ''),
    ];
    var rows = useBookings({ startDate: startDate, endDate: endDate }).rows;
    return rows && rows.length > 0 ? (React.createElement(StyledPDFDownloadLink, { document: React.createElement(BasicPDF, { title: RESERVATIONS, rows: rows, headers: [BUILDING, AMENITY, CLIENT_CODE, DATE, SCHEDULE, PRICE], headerRows: headerRows }), fileName: 'informe_de_reservas.pdf' },
        React.createElement(Container, null,
            React.createElement("img", { src: DownloadArrowButton }),
            React.createElement(DownloadText, null, DOWNLOAD_REPORT)))) : (React.createElement(React.Fragment, null));
};
