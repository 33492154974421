import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { Collapse } from 'react-collapse';
import { useHistory } from 'react-router-dom';
import { UploadContainer, UploadButton } from './styles';
import { Filter, AdminCard } from 'src/Components/Common';
import { M } from 'src/Utils';
import { useWindowWidth } from 'src/useWindowWidth';
import { AddIcon } from 'src/assets/Images';
import { stableSort, getComparator } from 'src/Utils/utils';
import { FIRST, TYPE_CONTACT, NEW_SUMMON_TEXT, CREATE_SUMMON, } from 'src/Utils/constants';
import { TableContainer } from '../BillsPage/styles';
import Table from 'src/Components/GenericTable';
var FilterTable = function (_a) {
    var rows = _a.rows, headCells = _a.headCells, building = _a.building, buildingList = _a.buildingList, startDate = _a.startDate, setStartDate = _a.setStartDate, endDate = _a.endDate, setEndDate = _a.setEndDate, isLoading = _a.isLoading, selectedBuilding = _a.selectedBuilding, setSelectedBuilding = _a.setSelectedBuilding, setSent = _a.setSent, handleDeleteRow = _a.handleDeleteRow, handleSuspend = _a.handleSuspend;
    var history = useHistory();
    var _b = useState(''), searchedItem = _b[0], setSearchedItem = _b[1];
    var _c = useState(FIRST), orderBy = _c[0], setOrderBy = _c[1];
    var windowWidth = useWindowWidth();
    var isSmallScreen = M >= windowWidth;
    var isContact = Cookies.get('User') === TYPE_CONTACT;
    var isSuperAdmin = Cookies.get('SuperAdmin') === 'true';
    var _d = useState(true), isTableOpen = _d[0], setIsTableOpen = _d[1];
    var WIDTHS = isContact
        ? ['20%', '25%', '25%', '20%']
        : !isSuperAdmin
            ? ['20%', '25%', '25%', '20%']
            : ['10%', '10%', '10%', '20%', '5%'];
    var renderFilter = function () { return (React.createElement(Filter, { building: selectedBuilding ? selectedBuilding.label : building === null || building === void 0 ? void 0 : building.label, setBuilding: setSelectedBuilding, searchedItem: searchedItem, setSearchedItem: setSearchedItem, startDate: startDate, setStartDate: setStartDate, endDate: endDate, setEndDate: setEndDate, setOrderBy: setOrderBy, orderBy: orderBy, data: buildingList, sortData: [
            { label: 'Nombre', value: 'Nombre' },
            { label: 'Fecha', value: 'Fecha' },
        ] })); };
    var handleClick = function () {
        var name = selectedBuilding ? selectedBuilding.label : building === null || building === void 0 ? void 0 : building.label;
        var value = selectedBuilding ? selectedBuilding.value : building === null || building === void 0 ? void 0 : building.value;
        if (!isContact) {
            history.push(NEW_SUMMON_TEXT + "/" + name + "/" + value);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TableContainer, null,
            React.createElement(Collapse, { isOpened: isTableOpen, style: { width: '100%' } },
                renderFilter(),
                isSmallScreen ? (stableSort(rows, getComparator('asc', orderBy)).map(function (row, index) {
                    return row.first.includes(searchedItem) && (React.createElement(AdminCard, { key: row.first + index, item: row, isSummonsPage: true }));
                })) : (React.createElement(Table, { rows: rows.filter(function (row) { return row.first.includes(searchedItem); }), headCells: headCells, topIndex: 7, handleButtonClick: handleClick, modalButton: CREATE_SUMMON, selectedBuilding: selectedBuilding, widths: WIDTHS, isSummon: true, isLoading: isLoading, isContact: isContact, shouldShowAssembly: true, setSent: setSent, handleDeleteRow: handleDeleteRow, handleSuspend: handleSuspend })))),
        isSmallScreen && !isContact && (React.createElement(UploadContainer, null,
            React.createElement(UploadButton, { src: AddIcon, onClick: handleClick })))));
};
export default FilterTable;
