import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { DotsImg, Row } from '../../Common/Table/TableRow/styles';
import { Dots } from 'src/assets/Images';
import { Actions, Cell, DownloadTag, Img } from './style';
import { Popover } from 'src/Components/Common';
import { Print, Download, DownloadArrow, } from 'src/assets/Images';
import { DOWNLOAD, SEND, NEW_SUMMON_TEXT, VIEW, DELETE, MODIFY, SUSPEND, } from 'src/Utils';
import { sendSummon } from 'src/Views/DocumentsPages/SummonsPage/duck';
function createBuilding(label, value, rows) {
    return { label: label, value: value, rows: rows };
}
var SummonAssemblyRow = function (_a) {
    var building = _a.building, data = _a.data, widths = _a.widths, isContact = _a.isContact, setSent = _a.setSent, handleDeleteRow = _a.handleDeleteRow, handleSuspend = _a.handleSuspend;
    var _b = useState([]), rows = _b[0], setRows = _b[1];
    var first = data.first, second = data.second, third = data.third, fourth = data.fourth;
    var history = useHistory();
    var _c = useState(), selectedBuilding = _c[0], setSelectedBuilding = _c[1];
    var mutateSendSummon = useMutation(sendSummon, {
        onSuccess: function () { return setSent && setSent(true); },
    })[0];
    var _d = useState(null), anchorRef = _d[0], setAnchorRef = _d[1];
    var _e = useState({
        label: '',
        value: '',
    }), selectedOption = _e[0], setSelectedOption = _e[1];
    var handleClick = function (e) {
        return anchorRef ? setAnchorRef(null) : setAnchorRef(e.currentTarget);
    };
    var handleDocument = function (document) {
        var link = window.document.createElement('a');
        link.setAttribute('target', '_blank');
        link.href = document;
        link.click();
    };
    useEffect(function () {
        switch (selectedOption.label) {
            case DELETE:
                deleteAssembly();
                break;
            case MODIFY:
                modifyAssembly();
                break;
            case VIEW:
                handleDocument(data.preview);
                break;
        }
    }, [selectedOption]);
    var modifyAssembly = function () {
        history.push({
            pathname: NEW_SUMMON_TEXT + "/" + (building === null || building === void 0 ? void 0 : building.label) + "/" + (building === null || building === void 0 ? void 0 : building.value),
            state: { summonData: data },
        });
    };
    var deleteAssembly = function () {
        handleDeleteRow === null || handleDeleteRow === void 0 ? void 0 : handleDeleteRow(data.id);
    };
    var isSuspended = third === 'suspended';
    return (React.createElement(Row, { hover: true, tabIndex: -1, key: first },
        [first, second, third, fourth].map(function (item, index) { return (React.createElement(Cell, { key: data.id + item, style: { width: widths[index] }, className: item === third ? third === 'sent' ? 'bold' : isSuspended ? 'red bold' : '' : '' }, item === third ? third === 'sent' ? 'Enviado' : isSuspended ? 'Suspendido' : 'Creado' : item)); }),
        React.createElement(Cell, { style: {
                width: widths[6],
            } }, isContact ? (React.createElement(Actions, { className: "pointer-left", onClick: function () { return handleDocument(data.preview); } },
            React.createElement(DownloadTag, null, DOWNLOAD),
            React.createElement(Img, { src: Download, alt: "Descargar" }))) : third === 'sent' ? (React.createElement(Actions, null,
            React.createElement(Actions, { className: "pointer-left", onClick: function () { return handleDeleteRow === null || handleDeleteRow === void 0 ? void 0 : handleDeleteRow(data.id); } },
                React.createElement(DownloadTag, { className: 'delete' }, DELETE)),
            !isSuspended &&
                React.createElement(Actions, { className: "pointer-left", onClick: function () { return handleSuspend === null || handleSuspend === void 0 ? void 0 : handleSuspend(data.id); } },
                    React.createElement(DownloadTag, { className: 'delete' }, SUSPEND)),
            React.createElement(Img, { src: Print, alt: "Imprimir", onClick: function () { return handleDocument(data.printable); } }),
            React.createElement(Img, { src: isContact ? DownloadArrow : Download, alt: "Descargar", onClick: function () { return handleDocument(data.preview); } }))) : (React.createElement(React.Fragment, null,
            React.createElement(Actions, { className: "pointer-left" },
                React.createElement(DownloadTag, { onClick: function () { return mutateSendSummon(data.id); } }, SEND),
                React.createElement(DotsImg, { src: Dots, alt: "M\u00E1s", onClick: handleClick })),
            React.createElement(Popover, { data: [
                    { label: VIEW, value: VIEW },
                    { label: DELETE, value: DELETE },
                    { label: MODIFY, value: MODIFY },
                ], handleOutsideClick: function () { return setAnchorRef(null); }, anchorRef: anchorRef, setSelected: setSelectedOption, className: "filter width", isFilter: false }))))));
};
export default SummonAssemblyRow;
