var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { PDFDownloadLink } from '@react-pdf/renderer';
import styled from '@emotion/styled';
import { L, theme } from 'src/Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 12px;\n  border-radius: 7px;\n  border: 1px solid ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: fit-content;\n  gap: 12px;\n  cursor: pointer;\n  width: max-content;\n  @media (max-width: ", "px) {\n    width: auto;\n  }\n"], ["\n  padding: 12px;\n  border-radius: 7px;\n  border: 1px solid ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: fit-content;\n  gap: 12px;\n  cursor: pointer;\n  width: max-content;\n  @media (max-width: ", "px) {\n    width: auto;\n  }\n"])), theme.palette.blue[5], L);
export var Text = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n"])), theme.palette.blue[5], theme.typography.normal.raleway);
export var StyledPDFDownloadLink = styled(PDFDownloadLink)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-decoration: none;\n\n  @media (max-width: ", "px) {\n    width: 100%;\n  }\n"], ["\n  text-decoration: none;\n\n  @media (max-width: ", "px) {\n    width: 100%;\n  }\n"])), L);
var templateObject_1, templateObject_2, templateObject_3;
