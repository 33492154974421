var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { M, theme } from 'src/Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  gap: 8px;\n  height: 450px;\n  margin-top: 24px;\n"], ["\n  display: flex;\n  gap: 8px;\n  height: 450px;\n  margin-top: 24px;\n"])));
export var InnerContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 8px;\n  width: 50%;\n  height: 100%;\n"], ["\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 8px;\n  width: 50%;\n  height: 100%;\n"])));
export var PrincipalImage = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 16px 0px 0px 16px;\n  height: 100%;\n  width: 50%;\n  cursor: pointer;\n  &.full {\n    width: 100%;\n    height: 100%;\n    border-radius: 16px;\n\n    @media (max-width: ", "px) {\n      height: 242px;\n    }\n  }\n"], ["\n  background-color: ", ";\n  border-radius: 16px 0px 0px 16px;\n  height: 100%;\n  width: 50%;\n  cursor: pointer;\n  &.full {\n    width: 100%;\n    height: 100%;\n    border-radius: 16px;\n\n    @media (max-width: ", "px) {\n      height: 242px;\n    }\n  }\n"])), theme.palette.grey[45], M);
export var SecondaryImage = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: ", ";\n  width: 100%;\n  height: 100%;\n  max-width: 100%;\n  max-height: 100%;\n  cursor: pointer;\n  aspect-ratio: 2;\n  &.element-1 {\n    border-radius: 0px 16px 0px 0px;\n  }\n  &.element-3 {\n    border-radius: 0px 0px 16px 0px;\n  }\n"], ["\n  background-color: ", ";\n  width: 100%;\n  height: 100%;\n  max-width: 100%;\n  max-height: 100%;\n  cursor: pointer;\n  aspect-ratio: 2;\n  &.element-1 {\n    border-radius: 0px 16px 0px 0px;\n  }\n  &.element-3 {\n    border-radius: 0px 0px 16px 0px;\n  }\n"])), theme.palette.grey[45]);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
