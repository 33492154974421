import React from 'react';
import { View } from '@react-pdf/renderer';
import ImagesPDF from './ImagesPDF';
import { Row } from '../styles';
import { TitleContainer, TopInfoLocation, TopInfoTitle } from './styles';
export var TopInfoPDF = function (_a) {
    var info = _a.info;
    var address = info.address + ", " + info.neighborhood + ", " + info.city;
    return (React.createElement(View, null,
        React.createElement(Row, { className: "space-between" },
            React.createElement(TitleContainer, null,
                React.createElement(TopInfoTitle, null, info.name),
                React.createElement(TopInfoLocation, null, address))),
        React.createElement(ImagesPDF, { images: info.media_urls })));
};
export default TopInfoPDF;
