var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme } from '../../Utils';
export var FormContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-sizing: border-box;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  background: ", ";\n  border-radius: 21px;\n  padding: 4px 0;\n  margin-bottom: 51px;\n  &.disabled {\n    pointer-events: none;\n  }\n"], ["\n  box-sizing: border-box;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  background: ", ";\n  border-radius: 21px;\n  padding: 4px 0;\n  margin-bottom: 51px;\n  &.disabled {\n    pointer-events: none;\n  }\n"])), theme.palette.white[100]);
export var InformationContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  min-height: 44px;\n  display: flex;\n  flex-direction: row;\n  border-bottom: 1px solid rgba(214, 216, 218, 0.65);\n"], ["\n  width: 100%;\n  min-height: 44px;\n  display: flex;\n  flex-direction: row;\n  border-bottom: 1px solid rgba(214, 216, 218, 0.65);\n"])));
export var Label = styled.label(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 14px;\n  padding: 14px 24px;\n  &.file {\n    padding: 0 24px;\n    display: flex;\n    text-align: center;\n    gap: 6px;\n  }\n  &.checkBox {\n    padding: 16px 16px 16px 0px;\n  }\n"], ["\n  font-family: ", ";\n  color: ", ";\n  font-size: 14px;\n  padding: 14px 24px;\n  &.file {\n    padding: 0 24px;\n    display: flex;\n    text-align: center;\n    gap: 6px;\n  }\n  &.checkBox {\n    padding: 16px 16px 16px 0px;\n  }\n"])), theme.typography.normal.raleway, theme.palette.grey[40]);
export var Subject = styled.input(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  box-sizing: border-box;\n  width: 100%;\n  height: 100%;\n  border: none;\n  padding: 10px;\n  padding-left: 0;\n  &:focus {\n    outline: none;\n  }\n  &:disabled {\n    background: none;\n  }\n"], ["\n  box-sizing: border-box;\n  width: 100%;\n  height: 100%;\n  border: none;\n  padding: 10px;\n  padding-left: 0;\n  &:focus {\n    outline: none;\n  }\n  &:disabled {\n    background: none;\n  }\n"])));
export var MessageInput = styled.textarea(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  box-sizing: border-box;\n  width: 100%;\n  height: 100%;\n  min-height: 500px;\n  border: none;\n  padding: 30px;\n  padding-bottom: 0;\n  resize: none;\n  font-family: ", ";\n  font-size: 14px;\n  &:focus {\n    outline: none;\n  }\n  &:disabled {\n    background: none;\n  }\n"], ["\n  box-sizing: border-box;\n  width: 100%;\n  height: 100%;\n  min-height: 500px;\n  border: none;\n  padding: 30px;\n  padding-bottom: 0;\n  resize: none;\n  font-family: ", ";\n  font-size: 14px;\n  &:focus {\n    outline: none;\n  }\n  &:disabled {\n    background: none;\n  }\n"])), theme.typography.normal.raleway);
export var BottomContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  box-sizing: border-box;\n  width: 100%;\n  min-height: 52px;\n  display: flex;\n  justify-content: flex-end;\n  margin: 20px 0;\n  padding-right: 20px;\n"], ["\n  box-sizing: border-box;\n  width: 100%;\n  min-height: 52px;\n  display: flex;\n  justify-content: flex-end;\n  margin: 20px 0;\n  padding-right: 20px;\n"])));
export var Div = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 6px;\n  padding-top: 30px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 6px;\n  padding-top: 30px;\n"])));
export var ButtonsContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  height: 100%;\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  padding: 0 20px;\n  &.gap {\n    gap: 20px;\n  }\n"], ["\n  height: 100%;\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  padding: 0 20px;\n  &.gap {\n    gap: 20px;\n  }\n"])));
export var ImgContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  height: 100%;\n  display: flex;\n  align-items: center;\n  gap: 20px;\n  justify-content: center;\n  padding: 0 45px;\n  border-left: 1px solid ", ";\n  &.last {\n    border-right: 1px solid ", ";\n  }\n"], ["\n  height: 100%;\n  display: flex;\n  align-items: center;\n  gap: 20px;\n  justify-content: center;\n  padding: 0 45px;\n  border-left: 1px solid ", ";\n  &.last {\n    border-right: 1px solid ", ";\n  }\n"])), theme.palette.admin.primary[65], theme.palette.admin.primary[65]);
export var Image = styled.img(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  height: 18px;\n  cursor: pointer;\n  &.exit {\n    height: 100%;\n  }\n"], ["\n  height: 18px;\n  cursor: pointer;\n  &.exit {\n    height: 100%;\n  }\n"])));
export var Delete = styled.img(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
export var ErrorMessage = styled.label(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  box-sizing: border-box;\n  width: 100%;\n  color: ", ";\n  font-size: 12px;\n  margin-top: 10px;\n  padding-right: 40px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n"], ["\n  box-sizing: border-box;\n  width: 100%;\n  color: ", ";\n  font-size: 12px;\n  margin-top: 10px;\n  padding-right: 40px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n"])), theme.palette.error[50]);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
