var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { TableRow, TableCell } from '@material-ui/core';
import { theme } from '../../../../Utils';
export var Row = styled(TableRow)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100vw;\n  display: flex;\n  background-color: ", ";\n  align-items: center;\n  border-radius: 2px;\n  box-shadow: none !important;\n  &:nth-child(even) {\n    background-color: ", ";\n    &:hover {\n      background-color: ", " !important;\n    }\n  }\n\n  &:hover {\n    background-color: ", " !important;\n  }\n"], ["\n  width: 100vw;\n  display: flex;\n  background-color: ", ";\n  align-items: center;\n  border-radius: 2px;\n  box-shadow: none !important;\n  &:nth-child(even) {\n    background-color: ", ";\n    &:hover {\n      background-color: ", " !important;\n    }\n  }\n\n  &:hover {\n    background-color: ", " !important;\n  }\n"])), theme.palette.white[100], theme.palette.admin.primary[15], theme.palette.admin.primary[15], theme.palette.white[100]);
export var Cell = styled(TableCell)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  padding: 0px 0px 0px 62px !important;\n  height: 35px;\n  border: none !important;\n  &.MuiTableCell-root {\n    font-family: unset;\n  }\n  &.width {\n    padding: 0px 0px 0px 42px !important;\n  }\n  &:last-child {\n    text-align: end;\n    padding-right: 25px !important;\n  }\n  &.center {\n    text-align: center;\n  }\n  &.left {\n    text-align: left;\n  }\n  &.bold {\n    font-family: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  padding: 0px 0px 0px 62px !important;\n  height: 35px;\n  border: none !important;\n  &.MuiTableCell-root {\n    font-family: unset;\n  }\n  &.width {\n    padding: 0px 0px 0px 42px !important;\n  }\n  &:last-child {\n    text-align: end;\n    padding-right: 25px !important;\n  }\n  &.center {\n    text-align: center;\n  }\n  &.left {\n    text-align: left;\n  }\n  &.bold {\n    font-family: ", ";\n  }\n"])), theme.typography.bold.ralewayBold);
export var DownloadContainer = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  all: unset;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  font-size: 12px;\n  color: ", ";\n  cursor: pointer;\n  img {\n    margin-left: 6px;\n  }\n  font-family: ", ";\n  &.underline {\n    text-decoration-line: underline;\n  }\n"], ["\n  all: unset;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  font-size: 12px;\n  color: ", ";\n  cursor: pointer;\n  img {\n    margin-left: 6px;\n  }\n  font-family: ", ";\n  &.underline {\n    text-decoration-line: underline;\n  }\n"])), theme.palette.admin.primary[75], theme.typography.bold.ralewayBold);
export var DeleteContainer = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  all: unset;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  font-size: 12px;\n  cursor: pointer;\n  color: ", ";\n  font-family: ", ";\n  &.underline {\n    text-decoration-line: underline;\n  }\n"], ["\n  all: unset;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  font-size: 12px;\n  cursor: pointer;\n  color: ", ";\n  font-family: ", ";\n  &.underline {\n    text-decoration-line: underline;\n  }\n"])), theme.palette.red, theme.typography.bold.ralewayBold);
export var PendingLabel = styled.label(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  font-size: 12px;\n  font-family: ", ";\n  justify-content: flex-end;\n  width: max-content;\n  color: ", ";\n  cursor: pointer;\n  text-decoration-line: underline;\n"], ["\n  display: flex;\n  align-items: center;\n  font-size: 12px;\n  font-family: ", ";\n  justify-content: flex-end;\n  width: max-content;\n  color: ", ";\n  cursor: pointer;\n  text-decoration-line: underline;\n"])), theme.typography.bold.ralewayBold, theme.palette.admin.primary[75]);
export var Div = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  gap: 18px;\n  &.message {\n    gap: 56px;\n  }\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  gap: 18px;\n  &.message {\n    gap: 56px;\n  }\n"])));
export var DotsImg = styled.img(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-left: 10px;\n  cursor: pointer;\n"], ["\n  margin-left: 10px;\n  cursor: pointer;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
