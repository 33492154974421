var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { M, theme, XS } from '../../../Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 90%;\n  padding: 20px;\n  padding-top: 0;\n  border-radius: 7px;\n  align-items: center;\n  flex-direction: column;\n  background-color: ", ";\n  min-height: 50px;\n  max-width: 325px;\n  margin: 0px 1%;\n  margin-bottom: 20px;\n  @media (max-width: ", "px) {\n    width: 80%;\n  }\n"], ["\n  display: flex;\n  width: 90%;\n  padding: 20px;\n  padding-top: 0;\n  border-radius: 7px;\n  align-items: center;\n  flex-direction: column;\n  background-color: ", ";\n  min-height: 50px;\n  max-width: 325px;\n  margin: 0px 1%;\n  margin-bottom: 20px;\n  @media (max-width: ", "px) {\n    width: 80%;\n  }\n"])), theme.palette.white[100], XS);
export var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  height: 100%;\n  width: 100%;\n  justify-content: space-around;\n  text-align: center;\n  &.none {\n    display: none;\n  }\n"], ["\n  display: flex;\n  height: 100%;\n  width: 100%;\n  justify-content: space-around;\n  text-align: center;\n  &.none {\n    display: none;\n  }\n"])));
export var Div = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  height: 100%;\n  width: 100%;\n"], ["\n  display: flex;\n  height: 100%;\n  width: 100%;\n"])));
export var Title = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: 16px;\n  margin-bottom: 10px;\n  width: 100%;\n  padding: 10px 20px 10px 20px;\n  border-start-end-radius: 7px;\n  border-start-start-radius: 7px;\n  background-color: #f6faff;\n"], ["\n  font-family: ", ";\n  font-size: 16px;\n  margin-bottom: 10px;\n  width: 100%;\n  padding: 10px 20px 10px 20px;\n  border-start-end-radius: 7px;\n  border-start-start-radius: 7px;\n  background-color: #f6faff;\n"])), theme.typography.bold.ralewayBold);
export var ContentDiv = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  &.second {\n    margin-top: 12px;\n    width: 25%;\n    align-self: flex-start;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  &.second {\n    margin-top: 12px;\n    width: 25%;\n    align-self: flex-start;\n  }\n"])));
export var ImageContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 30%;\n  justify-content: center;\n  display: flex;\n  padding: 0px;\n  justify-content: flex-end;\n  align-self: baseline;\n  padding-top: 8px;\n  align-items: flex-end;\n  flex-direction: column;\n  &.margin {\n    padding-top: 0px;\n    margin-bottom: 10px;\n    align-self: center;\n  }\n  &.dots {\n    align-self: flex-end;\n  }\n"], ["\n  width: 30%;\n  justify-content: center;\n  display: flex;\n  padding: 0px;\n  justify-content: flex-end;\n  align-self: baseline;\n  padding-top: 8px;\n  align-items: flex-end;\n  flex-direction: column;\n  &.margin {\n    padding-top: 0px;\n    margin-bottom: 10px;\n    align-self: center;\n  }\n  &.dots {\n    align-self: flex-end;\n  }\n"])));
export var Image = styled.img(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 45%;\n  height: 20px;\n  &.description {\n    width: auto;\n    margin-right: 5px;\n    height: 16px;\n  }\n  &.dots {\n    width: auto;\n    height: 4px;\n    cursor: pointer;\n  }\n"], ["\n  width: 45%;\n  height: 20px;\n  &.description {\n    width: auto;\n    margin-right: 5px;\n    height: 16px;\n  }\n  &.dots {\n    width: auto;\n    height: 4px;\n    cursor: pointer;\n  }\n"])));
export var Description = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 100%;\n  text-align: initial;\n  font-size: 14px;\n  &.bold {\n    font-family: ", ";\n  }\n"], ["\n  width: 100%;\n  text-align: initial;\n  font-size: 14px;\n  &.bold {\n    font-family: ", ";\n  }\n"])), theme.typography.bold.ralewayBold);
export var ContentContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 70%;\n  height: 100%;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n"], ["\n  width: 70%;\n  height: 100%;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n"])));
export var ErrorMessage = styled.label(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 12px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  padding: 0px;\n  padding-right: 35px;\n  @media (max-width: ", "px) {\n    padding-right: 20px;\n  }\n"], ["\n  color: ", ";\n  font-size: 12px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  padding: 0px;\n  padding-right: 35px;\n  @media (max-width: ", "px) {\n    padding-right: 20px;\n  }\n"])), theme.palette.error[50], M);
export var DownloadContainer = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  gap: 25px;\n  padding-right: 15px;\n"], ["\n  display: flex;\n  gap: 25px;\n  padding-right: 15px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
