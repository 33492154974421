var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, S } from '../../Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  min-height: 500px;\n  margin-bottom: 80px;\n  @media (max-width: ", "px) {\n    justify-content: center;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  min-height: 500px;\n  margin-bottom: 80px;\n  @media (max-width: ", "px) {\n    justify-content: center;\n  }\n"])), S);
export var ContainerInner = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 593px;\n  height: 100%;\n  min-height: 500px;\n  @media (max-width: ", "px) {\n    justify-content: center;\n    width: 320px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 593px;\n  height: 100%;\n  min-height: 500px;\n  @media (max-width: ", "px) {\n    justify-content: center;\n    width: 320px;\n  }\n"])), S);
export var Title = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: 600;\n  font-size: 36px;\n  line-height: 42px;\n  width: 100%;\n  max-width: 220px;\n  font-family: ", ";\n  color: ", ";\n  margin-top: 10px;\n  margin-bottom: 50px;\n  text-align: center;\n  @media (max-width: ", "px) {\n    font-size: 22px;\n    max-width: 120px;\n    margin-bottom: 40px;\n  }\n"], ["\n  font-weight: 600;\n  font-size: 36px;\n  line-height: 42px;\n  width: 100%;\n  max-width: 220px;\n  font-family: ", ";\n  color: ", ";\n  margin-top: 10px;\n  margin-bottom: 50px;\n  text-align: center;\n  @media (max-width: ", "px) {\n    font-size: 22px;\n    max-width: 120px;\n    margin-bottom: 40px;\n  }\n"])), theme.typography.normal.raleway, theme.palette.white[100], S);
export var Link = styled.a(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  margin-top: 10px;\n  margin-bottom: 19px;\n  font-size: 11px;\n  cursor: pointer;\n  text-decoration-line: underline;\n  color: ", ";\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  margin-top: 10px;\n  margin-bottom: 19px;\n  font-size: 11px;\n  cursor: pointer;\n  text-decoration-line: underline;\n  color: ", ";\n"])), theme.palette.white[100]);
export var Span = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 11.5%;\n  display: flex;\n  justify-content: flex-start;\n  margin-top: 10px;\n  margin-bottom: 19px;\n  font-size: 11px;\n  color: ", ";\n  &.center {\n    width: 100%;\n    margin-top: -10px;\n    text-align: center;\n  }\n  &.error {\n    width: 100%;\n    color: ", ";\n  }\n  @media (max-width: ", "px) {\n    width: 25%;\n  }\n"], ["\n  width: 11.5%;\n  display: flex;\n  justify-content: flex-start;\n  margin-top: 10px;\n  margin-bottom: 19px;\n  font-size: 11px;\n  color: ", ";\n  &.center {\n    width: 100%;\n    margin-top: -10px;\n    text-align: center;\n  }\n  &.error {\n    width: 100%;\n    color: ", ";\n  }\n  @media (max-width: ", "px) {\n    width: 25%;\n  }\n"])), theme.palette.white[100], theme.palette.error[50], S);
export var FormContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  justify-content: space-between;\n  min-height: 100px;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  @media (max-width: ", "px) {\n    width: 100%;\n    flex-direction: column;\n  }\n"], ["\n  width: 100%;\n  justify-content: space-between;\n  min-height: 100px;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  @media (max-width: ", "px) {\n    width: 100%;\n    flex-direction: column;\n  }\n"])), S);
export var ButtonContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  justify-contet: flex-start;\n  flex-direction: column;\n  width: 100%;\n"], ["\n  display: flex;\n  justify-contet: flex-start;\n  flex-direction: column;\n  width: 100%;\n"])));
export var CheckBoxContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  justify-contet: flex-start;\n  flex-direction: row;\n  width: 100%;\n"], ["\n  display: flex;\n  justify-contet: flex-start;\n  flex-direction: row;\n  width: 100%;\n"])));
export var Error = styled.label(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n  align-self: flex-start;\n  font-size: 12px;\n  margin-top: 10px;\n  height: 20px;\n  @media (max-width: ", "px) {\n    align-self: center;\n    align-items: center;\n  }\n"], ["\n  color: ", ";\n  align-self: flex-start;\n  font-size: 12px;\n  margin-top: 10px;\n  height: 20px;\n  @media (max-width: ", "px) {\n    align-self: center;\n    align-items: center;\n  }\n"])), theme.palette.error[50], S);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
