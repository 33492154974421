var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M, XXL } from '../../Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  box-sizing: border-box;\n  width: 100%;\n  max-width: 450px;\n  padding: 22px;\n  background-color: ", ";\n  border-radius: 21px;\n  max-height: 80vh;\n\n  @media (max-width: ", "px) {\n    height: 320px;\n    max-width: 780px;\n  }\n"], ["\n  position: relative;\n  box-sizing: border-box;\n  width: 100%;\n  max-width: 450px;\n  padding: 22px;\n  background-color: ", ";\n  border-radius: 21px;\n  max-height: 80vh;\n\n  @media (max-width: ", "px) {\n    height: 320px;\n    max-width: 780px;\n  }\n"])), theme.palette.white[100], XXL);
export var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  box-sizing: border-box;\n  font-size: 24px;\n  color: ", ";\n  padding: 0 22px;\n  margin-bottom: 24px;\n  width: 100%;\n  @media (max-width: ", "px) {\n    margin-bottom: 21px;\n    font-weight: 600;\n    font-size: 16px;\n    color: ", ";\n  }\n"], ["\n  box-sizing: border-box;\n  font-size: 24px;\n  color: ", ";\n  padding: 0 22px;\n  margin-bottom: 24px;\n  width: 100%;\n  @media (max-width: ", "px) {\n    margin-bottom: 21px;\n    font-weight: 600;\n    font-size: 16px;\n    color: ", ";\n  }\n"])), theme.palette.admin.primary[75], M, theme.palette.black[100]);
export var AlertsContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  width: 90%;\n  max-height: 70vh;\n  overflow-y: scroll;\n\n  @media (max-width: ", "px) {\n    width: 96%;\n    height: 220px;\n  }\n\n  ::-webkit-scrollbar {\n    width: 10px;\n  }\n\n  ::-webkit-scrollbar-track {\n    background-color: ", ";\n    border-radius: 10px;\n  }\n\n  ::-webkit-scrollbar-thumb {\n    background-color: ", ";\n    border-radius: 10px;\n  }\n"], ["\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  width: 90%;\n  max-height: 70vh;\n  overflow-y: scroll;\n\n  @media (max-width: ", "px) {\n    width: 96%;\n    height: 220px;\n  }\n\n  ::-webkit-scrollbar {\n    width: 10px;\n  }\n\n  ::-webkit-scrollbar-track {\n    background-color: ", ";\n    border-radius: 10px;\n  }\n\n  ::-webkit-scrollbar-thumb {\n    background-color: ", ";\n    border-radius: 10px;\n  }\n"])), XXL, theme.palette.admin.primary[50], theme.palette.admin.primary[75]);
export var Circle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  top: -18px;\n  right: 0;\n  display: grid;\n  justify-content: center;\n  align-items: center;\n  width: 54px;\n  height: 54px;\n  font-weight: bold;\n  color: ", ";\n  font-size: 11px;\n  border-radius: 50%;\n  background-color: ", ";\n  box-shadow: 3px 8px 5px rgba(111, 152, 201, 0.06);\n\n  &.notifications {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    width: 16px;\n    height: 16px;\n    box-shadow: none;\n    background-color: ", ";\n  }\n"], ["\n  position: absolute;\n  top: -18px;\n  right: 0;\n  display: grid;\n  justify-content: center;\n  align-items: center;\n  width: 54px;\n  height: 54px;\n  font-weight: bold;\n  color: ", ";\n  font-size: 11px;\n  border-radius: 50%;\n  background-color: ", ";\n  box-shadow: 3px 8px 5px rgba(111, 152, 201, 0.06);\n\n  &.notifications {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    width: 16px;\n    height: 16px;\n    box-shadow: none;\n    background-color: ", ";\n  }\n"])), theme.palette.admin.primary[75], theme.palette.white[100], theme.palette.admin.primary[50]);
export var Days = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 14px;\n  margin-top: 4px;\n"], ["\n  color: ", ";\n  font-size: 14px;\n  margin-top: 4px;\n"])), theme.palette.admin.primary[75]);
export var AlertContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: list-item;\n  list-style-type: disc;\n  margin: 0 42px;\n  color: ", ";\n\n  &.new {\n    padding: 14px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 14px;\n    margin: 0;\n    border-radius: 7px;\n    margin-right: 22px;\n    background-color: ", ";\n    border: 1px solid ", ";\n\n    &.warning {\n      background-color: #f8f4e3;\n      border: 1px solid #e3d173;\n    }\n\n    &.important {\n      background-color: #f7e8e8;\n      border: 1px solid #e37373;\n    }\n  }\n"], ["\n  display: list-item;\n  list-style-type: disc;\n  margin: 0 42px;\n  color: ", ";\n\n  &.new {\n    padding: 14px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 14px;\n    margin: 0;\n    border-radius: 7px;\n    margin-right: 22px;\n    background-color: ", ";\n    border: 1px solid ", ";\n\n    &.warning {\n      background-color: #f8f4e3;\n      border: 1px solid #e3d173;\n    }\n\n    &.important {\n      background-color: #f7e8e8;\n      border: 1px solid #e37373;\n    }\n  }\n"])), theme.palette.grey[30], theme.palette.admin.primary[50], theme.palette.admin.primary[75]);
export var Line = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  border-bottom: solid ", " 1px;\n  margin: 14px 22px 14px 0;\n"], ["\n  border-bottom: solid ", " 1px;\n  margin: 14px 22px 14px 0;\n"])), theme.palette.grey[25]);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
