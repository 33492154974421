import React, { useState, useEffect } from 'react';
import { Container, RightContainer, SearchInput, SelectCont, Input, LeftContainer, Title, Img, SelectAll, ImageContainer, FilterButton, Row, } from './styles';
import { SEARCH, BUILDING, TOWER, APARTMENT, M } from 'src/Utils';
import { openedArrow, Calendar, FilterCardArrow, FilterOpenArrow, } from 'src/assets/Images';
import { Popover } from '..';
import { exit } from 'src/assets/Images';
import { useWindowWidth } from 'src/useWindowWidth';
import DatePopover from '../DatePopover/DatePopover';
import { USER } from 'src/Utils/constants';
import RenderDate from '../RenderDate';
import { DownloadButton } from '../../../Views/MaintenancePages/ReservationPage/components/PDF/DownloadButton';
var TableFilter = function (_a) {
    var building = _a.building, tower = _a.tower, apartment = _a.apartment, setBuilding = _a.setBuilding, setTower = _a.setTower, setApartment = _a.setApartment, searchedItem = _a.searchedItem, setSearchedItem = _a.setSearchedItem, startDate = _a.startDate, setStartDate = _a.setStartDate, endDate = _a.endDate, setEndDate = _a.setEndDate, setOrderBy = _a.setOrderBy, _b = _a.isCommonExpenses, isCommonExpenses = _b === void 0 ? false : _b, _c = _a.hasDateFilter, hasDateFilter = _c === void 0 ? true : _c, _d = _a.hasSearch, hasSearch = _d === void 0 ? true : _d, _e = _a.isBuilding, isBuilding = _e === void 0 ? true : _e, data = _a.data, towerList = _a.towerList, apartmentList = _a.apartmentList, expenses = _a.expenses, setExpenses = _a.setExpenses, expensesData = _a.expensesData, sortData = _a.sortData, _f = _a.isContact, isContact = _f === void 0 ? false : _f, _g = _a.className, className = _g === void 0 ? '' : _g, _h = _a.isMessage, isMessage = _h === void 0 ? false : _h, _j = _a.isPaymentHistory, isPaymentHistory = _j === void 0 ? false : _j, _k = _a.showDownloadPDF, showDownloadPDF = _k === void 0 ? false : _k;
    var _l = useState(false), focused = _l[0], setFocused = _l[1];
    var _m = useState(null), anchorRefBuilding = _m[0], setAnchorRefBuilding = _m[1];
    var _o = useState(null), anchorRefTower = _o[0], setAnchorRefTower = _o[1];
    var _p = useState(null), anchorRefApartment = _p[0], setAnchorRefApartment = _p[1];
    var _q = useState(null), anchorRefExpenses = _q[0], setAnchorRefExpenses = _q[1];
    var _r = useState(null), anchorRefFilter = _r[0], setAnchorRefFilter = _r[1];
    var _s = useState(null), anchorRefDate = _s[0], setAnchorRefDate = _s[1];
    var windowWidth = useWindowWidth();
    var isSmallScreen = M >= windowWidth;
    var handleClick = function (e, type) {
        var currentValue = e.currentTarget;
        if (anchorRefBuilding || anchorRefFilter) {
            setAnchorRefBuilding(null);
        }
        else
            switch (type) {
                case 'building':
                    setFocused(true);
                    setAnchorRefBuilding(currentValue);
                    break;
                case 'tower':
                    setFocused(true);
                    setAnchorRefTower(currentValue);
                    break;
                case 'apartment':
                    setFocused(true);
                    setAnchorRefApartment(currentValue);
                    break;
                case 'expenses':
                    setFocused(true);
                    setAnchorRefExpenses(currentValue);
                    break;
                case 'filter':
                    setAnchorRefFilter(currentValue);
                    break;
                case 'date':
                    setAnchorRefDate(currentValue);
                    break;
            }
    };
    useEffect(function () {
        setAnchorRefFilter(null);
        setAnchorRefDate(null);
        setAnchorRefExpenses(null);
    }, [isSmallScreen]);
    var handleOutsideClick = function () {
        setAnchorRefBuilding(null);
        setAnchorRefTower(null);
        setAnchorRefApartment(null);
        setAnchorRefFilter(null);
        setAnchorRefDate(null);
        setAnchorRefExpenses(null);
        setFocused(false);
    };
    var resetDates = function () {
        setStartDate && setStartDate(undefined);
        setEndDate && setEndDate(undefined);
    };
    var renderSmallScreenFilter = function () { return (React.createElement(ImageContainer, { className: isContact ? 'user' : '' },
        !isContact && isCommonExpenses && (React.createElement(SelectAll, { className: focused ? 'focused' : '', onClick: function (e) { return handleClick(e, 'expenses'); }, open: false, displayEmpty: true, renderValue: function () { return React.createElement("div", null, expenses); }, value: expenses, input: React.createElement(Input, null), IconComponent: function () { return React.createElement(Img, { src: FilterOpenArrow }); } })),
        startDate && (React.createElement(Img, { className: "filter", src: Calendar, alt: "Calendario", onClick: function (e) { return handleClick(e, 'date'); } })),
        !isContact && sortData && (React.createElement(Img, { className: "filter", src: FilterCardArrow, alt: "Flechas de filtrado", onClick: function (e) { return handleClick(e, 'filter'); } })))); };
    var renderLeftContainer = function () { return (React.createElement(LeftContainer, { className: className + " " + (isCommonExpenses ? 'left' : '') }, !isMessage && (React.createElement(React.Fragment, null,
        React.createElement(Title, null, isBuilding ? BUILDING : USER),
        React.createElement(SelectCont, { className: focused ? 'focused' : '', onClick: function (e) { return handleClick(e, 'building'); }, open: false, displayEmpty: true, renderValue: function () { return (React.createElement("div", { style: { textOverflow: 'ellipsis' } }, building)); }, value: building, input: React.createElement(Input, null), IconComponent: function () { return React.createElement(Img, { src: openedArrow }); } }))))); };
    var renderPaymentHistoryContainer = function () { return (React.createElement(Row, null, [
        { title: BUILDING, value: building, name: 'building' },
        { title: TOWER, value: tower, name: 'tower' },
        { title: APARTMENT, value: apartment, name: 'apartment' },
    ].map(function (_a) {
        var title = _a.title, value = _a.value, name = _a.name;
        return (React.createElement(LeftContainer, { className: className },
            React.createElement(Title, null, title),
            React.createElement(SelectCont, { className: title === BUILDING
                    ? 'building'
                    : title === APARTMENT
                        ? 'apartment'
                        : (focused ? 'focused' : '') + " space", onClick: function (e) { return handleClick(e, name); }, open: false, displayEmpty: true, renderValue: function () { return React.createElement("div", null, value); }, value: value, input: React.createElement(Input, null), IconComponent: function () { return React.createElement(Img, { src: openedArrow }); } })));
    }))); };
    return (React.createElement(Container, { className: className + " " + (isCommonExpenses ? 'column' : ''), isContact: isContact },
        !isContact ? (isPaymentHistory ? (renderPaymentHistoryContainer()) : (renderLeftContainer())) : (React.createElement(React.Fragment, null)),
        React.createElement(RightContainer, { className: className + " " + (hasSearch && !isContact && startDate ? 'spaced' : ''), isContact: true },
            hasDateFilter && (React.createElement(React.Fragment, null,
                React.createElement(RenderDate, { isStartDate: true, startDate: startDate, setStartDate: setStartDate, endDate: endDate, setEndDate: setEndDate, isContact: isContact, className: className }),
                React.createElement(RenderDate, { isStartDate: false, startDate: startDate, setStartDate: setStartDate, endDate: endDate, setEndDate: setEndDate, isContact: isContact, className: className }),
                React.createElement(FilterButton, { onClick: resetDates },
                    React.createElement("img", { src: exit })))),
            !isContact && hasSearch && setSearchedItem && (React.createElement(SearchInput, { placeholder: SEARCH, value: searchedItem, className: className, onChange: function (e) { return setSearchedItem(e.target.value); } })),
            isSmallScreen && renderSmallScreenFilter(),
            showDownloadPDF && React.createElement(DownloadButton, null)),
        data && (React.createElement(Popover, { data: data, handleOutsideClick: handleOutsideClick, anchorRef: anchorRefBuilding, setSelected: setBuilding, selected: building === null || building === void 0 ? void 0 : building.toString(), isFilter: false, className: "" + (isPaymentHistory ? 'spaceBuildings' : 'width') })),
        towerList && (React.createElement(Popover, { data: towerList, handleOutsideClick: handleOutsideClick, anchorRef: anchorRefTower, setSelected: setTower, isFilter: false, className: 'width' })),
        apartmentList && (React.createElement(Popover, { data: apartmentList, handleOutsideClick: handleOutsideClick, anchorRef: anchorRefApartment, setSelected: setApartment, isFilter: false, className: 'space' })),
        expensesData && (React.createElement(Popover, { data: expensesData, handleOutsideClick: handleOutsideClick, anchorRef: anchorRefExpenses, setSelected: setExpenses, isFilter: false, className: 'small', selected: expenses })),
        sortData && (React.createElement(Popover, { data: sortData, handleOutsideClick: handleOutsideClick, anchorRef: anchorRefFilter, setSelected: setOrderBy, className: "filter width", isFilter: true })),
        startDate && endDate && (React.createElement(DatePopover, { handleOutsideClick: handleOutsideClick, anchorRef: anchorRefDate, startDate: startDate, setStartDate: setStartDate, endDate: endDate, setEndDate: setEndDate, className: "width" }))));
};
export default TableFilter;
