import api from 'src/Utils/api';
export var submitDataForm = function (_a) {
    var summon_type = _a.summon_type, building_id = _a.building_id, set_date = _a.set_date, time_first_call = _a.time_first_call, time_second_call = _a.time_second_call, place = _a.place, fascioli_assists = _a.fascioli_assists, modality = _a.modality, link = _a.link, orders_of_the_day = _a.orders_of_the_day, place_specification = _a.place_specification;
    return api.post('/summons', {
        summon_type: summon_type,
        building_id: building_id,
        set_date: set_date,
        time_first_call: time_second_call,
        time_second_call: time_first_call,
        place: place,
        fascioli_assists: fascioli_assists,
        modality: modality,
        link: link,
        orders_of_the_day: orders_of_the_day,
        place_specification: place_specification,
    });
};
export var submitPreviewPDF = function (_a) {
    var file = _a.file, receiptId = _a.receiptId;
    var formData = new FormData();
    formData.append('pdf', file);
    return api.put("/summons/" + receiptId + "/preview", formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
export var submitPrintablePDF = function (_a) {
    var file = _a.file, receiptId = _a.receiptId;
    var formData = new FormData();
    formData.append('pdf', file);
    return api.put("/summons/" + receiptId + "/printable", formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
export var sendSummon = function (id) {
    return api.post("/summons/" + id + "/notify", {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
export var suspendSummon = function (id) {
    return api.put("/summons/" + id + "/suspend", {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
