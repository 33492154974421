var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { StyledButton } from './styles';
var Button = function (_a) {
    var _b = _a.onClick, onClick = _b === void 0 ? function () { } : _b, className = _a.className, ariaLabel = _a.ariaLabel, _c = _a.disabled, disabled = _c === void 0 ? false : _c, children = _a.children, rest = __rest(_a, ["onClick", "className", "ariaLabel", "disabled", "children"]);
    return (React.createElement(StyledButton, __assign({ className: className, "aria-label": ariaLabel, onClick: onClick, disabled: disabled }, rest), children));
};
export default Button;
