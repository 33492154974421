var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M } from 'src/Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  min-height: 550px;\n  height: 100%;\n  margin-top: 122px;\n  flex-direction: column;\n  background-color: ", ";\n  justify-content: center;\n  align-items: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  @media (max-width: ", "px) {\n    background-position-y: top;\n    background-position-x: left;\n    background-size: auto;\n    margin-top: 75px;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  min-height: 550px;\n  height: 100%;\n  margin-top: 122px;\n  flex-direction: column;\n  background-color: ", ";\n  justify-content: center;\n  align-items: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  @media (max-width: ", "px) {\n    background-position-y: top;\n    background-position-x: left;\n    background-size: auto;\n    margin-top: 75px;\n  }\n"])), theme.palette.primary[50], M);
export var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: 600;\n  font-size: 36px;\n  text-align: center;\n  line-height: 42px;\n  width: 100%;\n  font-family: ", ";\n  color: ", ";\n  margin-bottom: 50px;\n  margin-top: 10px;\n  @media (max-width: ", "px) {\n    font-size: 22px;\n    margin-bottom: 40px;\n    font-family: ", ";\n  }\n"], ["\n  font-weight: 600;\n  font-size: 36px;\n  text-align: center;\n  line-height: 42px;\n  width: 100%;\n  font-family: ", ";\n  color: ", ";\n  margin-bottom: 50px;\n  margin-top: 10px;\n  @media (max-width: ", "px) {\n    font-size: 22px;\n    margin-bottom: 40px;\n    font-family: ", ";\n  }\n"])), theme.typography.normal.raleway, theme.palette.white[100], M, theme.typography.bold.ralewayBold);
export var Img = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  max-height: 60vw;\n  width: 100%;\n  z-index: 2;\n"], ["\n  position: absolute;\n  max-height: 60vw;\n  width: 100%;\n  z-index: 2;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
