import React from 'react';
import { CONFIRM_PASSWORD_KEY, NEW_PASSWORD_KEY } from 'src/Utils/constants';
import { hasError } from 'src/Utils/validations';
import TextInput from '../TextInput';
import { Div, InputContainer, Error, Label, Exit } from './styles';
import { exit } from 'src/assets/Images';
var InputComponent = function (_a) {
    var type = _a.type, data = _a.data, error = _a.error, label = _a.label, placeHolder = _a.placeHolder, onChangeFunction = _a.onChangeFunction, className = _a.className, width = _a.width, disabled = _a.disabled, _b = _a.isPassword, isPassword = _b === void 0 ? false : _b, _c = _a.isLast, isLast = _c === void 0 ? false : _c, _d = _a.isOrder, isOrder = _d === void 0 ? false : _d, index = _a.index, dataSelected = _a.dataSelected, setSelected = _a.setSelected, addClientCode = _a.addClientCode;
    return (React.createElement(InputContainer, { width: width, key: type, className: className },
        React.createElement(Label, { className: className }, label),
        React.createElement(TextInput, { disabled: disabled, placeholder: placeHolder, value: data, onChange: onChangeFunction, hasError: hasError(type, error), className: "border-admin " + className, type: type === NEW_PASSWORD_KEY || type === CONFIRM_PASSWORD_KEY
                ? 'password'
                : '', isPassword: isPassword, addClientCode: addClientCode }),
        setSelected &&
            dataSelected &&
            (dataSelected === null || dataSelected === void 0 ? void 0 : dataSelected.length) != 1 &&
            (isOrder || isLast) && (React.createElement(Exit, { src: exit, onClick: function () {
                isOrder
                    ? setSelected(dataSelected.filter(function (_a) {
                        var number = _a.number;
                        return number !== index;
                    }))
                    : setSelected(dataSelected === null || dataSelected === void 0 ? void 0 : dataSelected.filter(function (_a) {
                        var number = _a.number;
                        return number != dataSelected.length;
                    }));
            } })),
        React.createElement(Div, null,
            React.createElement(Error, null, error[type]))));
};
export default InputComponent;
