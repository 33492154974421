import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import 'antd/dist/antd.css';
import moment from 'moment';
import { buildDisabledTimes, getFirstAvailableTime, getHoursErrorMessage, } from 'src/Components/TimeSelectors/utils';
import { ErrorMessage, HourPickerContainer, Text, TimePickerContainer, TimePickerStyled, } from './styles';
export var TimeSelectors = function (_a) {
    var _b;
    var otherBookedTimes = _a.otherBookedTimes;
    var _c = useFormContext(), watch = _c.watch, setValue = _c.setValue, setError = _c.setError, clearErrors = _c.clearErrors, errors = _c.formState.errors;
    var _d = watch(), schedule = _d.time, startHour = _d.startHour, endHour = _d.endHour, amenity = _d.amenity;
    var otherBookings = otherBookedTimes || schedule.occupiedTimes;
    var _e = useMemo(function () {
        if (!schedule.label)
            return { disabledMinutesMemo: {}, disabledHours: [] };
        return buildDisabledTimes(otherBookings, schedule.label);
    }, [otherBookings, schedule.label]), disabledMinutesMemo = _e.disabledMinutesMemo, disabledHours = _e.disabledHours;
    var firstAvailableTime = getFirstAvailableTime(disabledMinutesMemo);
    var errorMsg = (_b = errors.startHour) === null || _b === void 0 ? void 0 : _b.message;
    useEffect(function () {
        if (schedule.label && startHour && endHour) {
            var errorMsg_1 = getHoursErrorMessage(startHour, endHour, disabledMinutesMemo, amenity.hoursLimitPerBooking || -1, schedule.label);
            if (errorMsg_1)
                setError('startHour', { message: errorMsg_1 });
            else
                clearErrors('startHour');
        }
    }, [schedule.label, startHour, endHour]);
    var startValue = startHour
        ? moment(startHour, 'HH:mm')
        : undefined;
    var endValue = endHour ? moment(endHour, 'HH:mm') : undefined;
    return (React.createElement(React.Fragment, null,
        React.createElement(HourPickerContainer, null,
            React.createElement(TimePickerContainer, null,
                React.createElement(Text, null, "Inicio"),
                React.createElement(TimePickerStyled, { format: "HH:mm", disabledMinutes: function (hour) { return disabledMinutesMemo[hour] || []; }, disabledHours: function () { return disabledHours; }, minuteStep: 15, onChange: function (_, hour) { return setValue('startHour', hour); }, value: startValue, defaultOpenValue: firstAvailableTime })),
            React.createElement(TimePickerContainer, null,
                React.createElement(Text, null, "Fin"),
                React.createElement(TimePickerStyled, { format: "HH:mm", disabledMinutes: function (hour) { return disabledMinutesMemo[hour] || []; }, disabledHours: function () { return disabledHours; }, minuteStep: 15, onChange: function (_, hour) { return setValue('endHour', hour); }, value: endValue, defaultOpenValue: firstAvailableTime }))),
        errorMsg && React.createElement(ErrorMessage, null, errorMsg)));
};
export default TimeSelectors;
