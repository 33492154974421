import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    towerList: [],
};
export var reservationsSlice = createSlice({
    name: 'reservations',
    initialState: initialState,
    reducers: {
        setTowerList: function (state, action) {
            state.towerList = action.payload;
        },
    },
});
export var setTowerList = reservationsSlice.actions.setTowerList;
export default reservationsSlice.reducer;
