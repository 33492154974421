var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { Element } from 'react-scroll';
import { theme, M } from '../../Utils';
export var Container = styled.section(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  height: 100%;\n  width: 100%;\n  padding: 5% 0%;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  @media (max-width: ", "px) {\n    padding: 60px 0px;\n  }\n  &.no-page {\n    background-color: ", ";\n  }\n"], ["\n  display: flex;\n  height: 100%;\n  width: 100%;\n  padding: 5% 0%;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  @media (max-width: ", "px) {\n    padding: 60px 0px;\n  }\n  &.no-page {\n    background-color: ", ";\n  }\n"])), M, theme.palette.primary[50]);
export var ItemsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n    align-items: center;\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n    align-items: center;\n  }\n"])), M);
export var WhiteLine = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 2px;\n  height: 170px;\n  margin: 0px 6%;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    display: none;\n  }\n"], ["\n  width: 2px;\n  height: 170px;\n  margin: 0px 6%;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    display: none;\n  }\n"])), theme.palette.white[100], M);
export var ElementComponent = styled(Element)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
