import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import { COPYRIGHT } from 'src/Utils/constants';
import { InstagramPng, WebPng } from 'src/assets/Images';
import styles from './styles';
export var PDFFooter = function () { return (React.createElement(View, { style: styles.footer, fixed: true },
    React.createElement(Text, null, COPYRIGHT),
    React.createElement(View, { style: styles.footerLogos },
        React.createElement(Image, { src: InstagramPng, style: { height: '5', marginRight: 5 } }),
        React.createElement(Image, { src: WebPng, style: { height: '5' } })))); };
