import React from 'react';
import { M2 } from 'src/Utils';
import { pluralize } from '../../utils';
import { Container, Image, Row, Text } from './styles';
export var MoreInfoPDF = function (_a) {
    var data = _a.data;
    return (React.createElement(Container, null, data.map(function (_a) {
        var img = _a.img, number = _a.number, label = _a.label;
        return (React.createElement(Row, { key: label },
            React.createElement(Image, { fixed: true, src: img, source: img }),
            React.createElement(Text, null, label === M2 ? number + " " + M2 : pluralize(number, label))));
    })));
};
export default MoreInfoPDF;
