import React from 'react';
import { HERO_DESCRIPTION, HERO_SECOND_TITLE, HERO_TITLE } from '../../Utils';
import { HeroComponent } from '../../Components';
import { Container } from './styles';
var labels = {
    title: HERO_TITLE,
    description: HERO_DESCRIPTION,
    secondTitle: HERO_SECOND_TITLE,
};
var HeroHome = function () { return (React.createElement(Container, null,
    React.createElement(HeroComponent, { labels: labels }))); };
export default HeroHome;
