var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { Element } from 'react-scroll';
import { theme, M, S, L, XXXXL } from '../../Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  padding: 20px 0px;\n  justify-content: space-between;\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-color: ", ";\n  height: 100%;\n  min-height: 500px;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n    background-color: ", ";\n    justify-content: center;\n    background-attachment: fixed;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  padding: 20px 0px;\n  justify-content: space-between;\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-color: ", ";\n  height: 100%;\n  min-height: 500px;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n    background-color: ", ";\n    justify-content: center;\n    background-attachment: fixed;\n  }\n"])), theme.palette.primary[50], M, theme.palette.white[100]);
export var CarouselElement = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  background-image: url(", ");\n  background-size: contain;\n  background-repeat: no-repeat;\n  aspect-ratio: 2.6;\n  margin-top: 30px;\n  background-color: ", ";\n  cursor: pointer;\n  @media (max-width: ", "px) {\n    aspect-ratio: auto;\n    background-size: cover;\n    height: 273px;\n    background-position: -80px;\n  }\n"], ["\n  display: flex;\n  background-image: url(", ");\n  background-size: contain;\n  background-repeat: no-repeat;\n  aspect-ratio: 2.6;\n  margin-top: 30px;\n  background-color: ", ";\n  cursor: pointer;\n  @media (max-width: ", "px) {\n    aspect-ratio: auto;\n    background-size: cover;\n    height: 273px;\n    background-position: -80px;\n  }\n"])), function (_a) {
    var image = _a.image;
    return image;
}, theme.palette.grey[90], S);
export var Content = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 60%;\n  padding-left: 10vw;\n  max-width: ", ";\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: left;\n  gap: 24px;\n  @media (max-width: ", "px) {\n    width: 80%;\n    gap: 16px;\n    padding-left: 5vw;\n  }\n"], ["\n  width: 60%;\n  padding-left: 10vw;\n  max-width: ", ";\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: left;\n  gap: 24px;\n  @media (max-width: ", "px) {\n    width: 80%;\n    gap: 16px;\n    padding-left: 5vw;\n  }\n"])), function (_a) {
    var maxWidth = _a.maxWidth;
    return maxWidth || '900px';
}, M);
export var ContentContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding-left: 50px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  @media (max-width: ", "px) {\n    padding-left: 0px;\n  }\n"], ["\n  padding-left: 50px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  @media (max-width: ", "px) {\n    padding-left: 0px;\n  }\n"])), M);
export var Title = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-style: normal;\n  font-weight: 700;\n  font-size: 48px;\n  line-height: normal;\n  width: 100%;\n  font-family: ", ";\n  color: ", ";\n  @media (min-width: ", "px) {\n    font-size: 56px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 28px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 22px;\n  }\n"], ["\n  font-style: normal;\n  font-weight: 700;\n  font-size: 48px;\n  line-height: normal;\n  width: 100%;\n  font-family: ", ";\n  color: ", ";\n  @media (min-width: ", "px) {\n    font-size: 56px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 28px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 22px;\n  }\n"])), theme.typography.bold.ralewayBold, theme.palette.primary[50], XXXXL, L, M);
export var DescriptionContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-weight: 400;\n  font-style: normal;\n  font-size: 18px;\n  line-height: 28px; /* 155.556% */\n  color: ", ";\n  font-family: ", ";\n  @media (min-width: ", "px) {\n    font-size: 24px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 16px;\n    line-height: normal;\n  }\n  @media (max-width: ", "px) {\n    font-size: 14px;\n    line-height: normal;\n  }\n"], ["\n  font-weight: 400;\n  font-style: normal;\n  font-size: 18px;\n  line-height: 28px; /* 155.556% */\n  color: ", ";\n  font-family: ", ";\n  @media (min-width: ", "px) {\n    font-size: 24px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 16px;\n    line-height: normal;\n  }\n  @media (max-width: ", "px) {\n    font-size: 14px;\n    line-height: normal;\n  }\n"])), theme.palette.black[40], theme.typography.OpenSans, XXXXL, M, S);
export var CustomIndicator = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 16px;\n  height: 16px;\n  background-color: ", ";\n  opacity: ", ";\n  border-radius: 100px;\n  cursor: pointer;\n"], ["\n  width: 16px;\n  height: 16px;\n  background-color: ",
    ";\n  opacity: ", ";\n  border-radius: 100px;\n  cursor: pointer;\n"])), function (_a) {
    var isSelected = _a.isSelected;
    return isSelected ? theme.palette.blue[5] : theme.palette.blue[10];
}, function (_a) {
    var isSelected = _a.isSelected;
    return (isSelected ? 1 : 0.7);
});
export var Img = styled.img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 95%;\n  max-height: 650px;\n"], ["\n  width: 95%;\n  max-height: 650px;\n"])));
export var ImageContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 60%;\n  margin-top: 40px;\n"], ["\n  width: 60%;\n  margin-top: 40px;\n"])));
export var ElementComponent = styled(Element)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
