var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { S, theme } from '../../../Utils';
export var StyledButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  min-width: 180px;\n  justify-content: center;\n  color: ", ";\n  background-color: ", ";\n  border: 1px solid ", ";\n  font-family: ", ";\n  font-size: 16px;\n  text-align: center;\n  border-radius: 7px;\n  width: 220px;\n  height: 44px;\n  font-weight: 500;\n  cursor: pointer;\n  line-height: 19px;\n  &:disabled {\n    cursor: not-allowed;\n    opacity: 0.6;\n  }\n  &:focus {\n    outline: none;\n  }\n  &.add-margin {\n    margin-left: 15px;\n  }\n  &.full-width {\n    width: 100%;\n    min-width: inherit;\n  }\n  @media (max-width: ", "px) {\n    width: 100%;\n  }\n  &.red {\n    color: ", ";\n    background-color: ", ";\n    border: 1px solid ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  min-width: 180px;\n  justify-content: center;\n  color: ", ";\n  background-color: ", ";\n  border: 1px solid ", ";\n  font-family: ", ";\n  font-size: 16px;\n  text-align: center;\n  border-radius: 7px;\n  width: 220px;\n  height: 44px;\n  font-weight: 500;\n  cursor: pointer;\n  line-height: 19px;\n  &:disabled {\n    cursor: not-allowed;\n    opacity: 0.6;\n  }\n  &:focus {\n    outline: none;\n  }\n  &.add-margin {\n    margin-left: 15px;\n  }\n  &.full-width {\n    width: 100%;\n    min-width: inherit;\n  }\n  @media (max-width: ", "px) {\n    width: 100%;\n  }\n  &.red {\n    color: ", ";\n    background-color: ", ";\n    border: 1px solid ", ";\n  }\n"])), theme.palette.white[100], theme.palette.primary[25], theme.palette.primary[25], theme.typography.normal.raleway, S, theme.palette.error[50], theme.palette.white[100], theme.palette.error[50]);
export var StyledLink = styled.a(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  all: unset;\n  display: flex;\n  align-items: center;\n  min-width: 180px;\n  justify-content: center;\n  color: ", ";\n  background-color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  text-align: center;\n  border-radius: 7px;\n  width: 220px;\n  height: 44px;\n  font-weight: 500;\n  cursor: pointer;\n  line-height: 19px;\n  margin-top: 15px;\n  margin-left: 10px;\n  margin-right: 10px;\n  &:focus {\n    outline: none;\n  }\n  &.add-margin {\n    margin-left: 15px;\n  }\n  &.full-width {\n    width: 100%;\n  }\n  @media (max-width: ", "px) {\n    width: 100%;\n  }\n"], ["\n  all: unset;\n  display: flex;\n  align-items: center;\n  min-width: 180px;\n  justify-content: center;\n  color: ", ";\n  background-color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  text-align: center;\n  border-radius: 7px;\n  width: 220px;\n  height: 44px;\n  font-weight: 500;\n  cursor: pointer;\n  line-height: 19px;\n  margin-top: 15px;\n  margin-left: 10px;\n  margin-right: 10px;\n  &:focus {\n    outline: none;\n  }\n  &.add-margin {\n    margin-left: 15px;\n  }\n  &.full-width {\n    width: 100%;\n  }\n  @media (max-width: ", "px) {\n    width: 100%;\n  }\n"])), theme.palette.primary, theme.palette.white[100], theme.typography.normal.raleway, S);
export var LinkRow = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: space-evenly;\n"], ["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: space-evenly;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
