var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme } from 'src/Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  padding: 29px;\n  background-color: ", ";\n  width: ", ";\n  gap: 29px;\n  border-radius: 8px;\n  height: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  padding: 29px;\n  background-color: ", ";\n  width: ", ";\n  gap: 29px;\n  border-radius: 8px;\n  height: ", ";\n"])), theme.palette.admin.primary[45], function (_a) {
    var width = _a.width;
    return width;
}, function (_a) {
    var height = _a.height;
    return height;
});
export var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 18px;\n  font-weight: 600;\n"], ["\n  font-family: ", ";\n  color: ", ";\n  font-size: 18px;\n  font-weight: 600;\n"])), theme.typography.bold.ralewayBold, theme.palette.black[100]);
export var Value = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  font-weight: 400;\n"], ["\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  font-weight: 400;\n"])), theme.typography.normal.raleway, theme.palette.black[100]);
export var ColumnContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
