import api from 'src/Utils/api';
export var submitDataForm = function (_a) {
    var name = _a.name, summon_id = _a.summon_id, created_date = _a.created_date;
    return api.post('/assemblies', {
        name: name,
        summon_id: summon_id,
        created_date: created_date,
    });
};
export var submitPDF = function (_a) {
    var file = _a.file, id = _a.id;
    var formData = new FormData();
    formData.append('pdf', file);
    return api.put("/assemblies/" + id + "/pdf", formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
