import React from 'react';
import { Container, Title } from './styles';
import { AdminDashboardCard } from '../Common';
import { LAST_CLIENTS } from 'src/Utils';
import AdminCardSummons from '../Common/DashboardCard/AdminCardSummons';
var AdminDashboard = function (_a) {
    var buildings = _a.buildings;
    return (React.createElement(Container, null,
        React.createElement(Title, null, LAST_CLIENTS),
        React.createElement(Container, { className: "full-width" },
            buildings.map(function (building, index) { return (React.createElement(AdminDashboardCard, { key: building.name + index, data: building })); }),
            React.createElement(AdminCardSummons, null))));
};
export default AdminDashboard;
