import React from 'react';
import { Container, Description, ContentDiv, Line, Div } from './styles';
var ListItem = function (_a) {
    var _b = _a.data, first = _b.first, date = _b.date, building = _b.building, isLastItem = _a.isLastItem;
    return (React.createElement(Container, null,
        React.createElement(ContentDiv, { className: "message" },
            React.createElement(Div, null,
                React.createElement(Description, { className: "bold" }, first),
                React.createElement(Description, { className: "date" }, date)),
            React.createElement(Description, { className: "building" }, "Para: " + building)),
        !isLastItem && React.createElement(Line, { className: "message" })));
};
export default ListItem;
