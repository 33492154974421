var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { BeatLoader } from 'react-spinners';
import { AdminLayout } from 'src/Layouts';
import { Container, Title, TableContainer } from '../styles';
import { APARTMENTS, APARTMENT, M, BUILDING_API, APARTMENTS_API, theme, getData, stableSort, getComparator, CONTACTS, selectedAll, noTower, } from 'src/Utils';
import { Filter, MaintenanceCard } from 'src/Components/Common';
import Table from 'src/Components/GenericTable';
import { useWindowWidth } from 'src/useWindowWidth';
import { getInitialBuilding, storeBuilding } from './utils';
var headCells = [
    { id: 'first', numeric: true, disablePadding: false, label: APARTMENT },
    { id: 'second', numeric: true, disablePadding: false, label: CONTACTS },
];
var WIDTHS = ['50%', '50%'];
var key = APARTMENTS;
var ApartmentsPage = function () {
    var _a = useState(), data = _a[0], setData = _a[1];
    var _b = useState(), buildingList = _b[0], setBuildingList = _b[1];
    var _c = useState(), towerList = _c[0], setTowerList = _c[1];
    var _d = useState(), apartmentList = _d[0], setApartmentList = _d[1];
    var _e = useState(), selectedTower = _e[0], setSelectedTower = _e[1];
    var _f = useState(selectedAll), selectedApartment = _f[0], setSelectedApartment = _f[1];
    var _g = useState(), rows = _g[0], setRows = _g[1];
    var _h = useState(), visibleRows = _h[0], setVisibleRows = _h[1];
    var _j = useState(getInitialBuilding(key)), selectedBuilding = _j[0], setSelectedBuilding = _j[1];
    var _k = useState('first'), orderBy = _k[0], setOrderBy = _k[1];
    var windowWidth = useWindowWidth();
    var isSmallScreen = M >= windowWidth;
    var handleSetBuilding = function (building) {
        storeBuilding(building, key);
        setSelectedBuilding(building);
        apartmentList === null || apartmentList === void 0 ? void 0 : apartmentList.unshift(selectedAll);
        setSelectedApartment(apartmentList === null || apartmentList === void 0 ? void 0 : apartmentList[0]);
    };
    var handleSetTower = function (tower) {
        setSelectedTower(tower);
        setSelectedApartment(selectedAll);
    };
    var isLoading = useQuery(BUILDING_API, function () { return getData("/buildings"); }, {
        onSuccess: function (data) {
            var buildings = data.buildings.map(function (_a) {
                var name = _a.name, id = _a.id;
                return ({
                    label: name,
                    value: id,
                });
            });
            buildings.sort(function (a, b) {
                return a.label.localeCompare(b.label, 'es');
            });
            setBuildingList(buildings);
            if (!selectedBuilding)
                handleSetBuilding(buildings[0]);
        },
        refetchOnWindowFocus: false,
    }).isLoading;
    var isLoadingTowers = useQuery([selectedBuilding], function () { return getData("/buildings/" + (selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value) + "/towers"); }, {
        onSuccess: function (dataTowers) {
            var towersList = dataTowers.towers.map(function (_a) {
                var name = _a.name, id = _a.id;
                return ({
                    label: name,
                    value: id,
                });
            });
            if (towersList.length === 0) {
                towersList.push(noTower);
            }
            setTowerList(towersList);
            setSelectedTower(towersList[0]);
        },
        retry: false,
        refetchOnWindowFocus: false,
        enabled: selectedBuilding,
    }).isLoading;
    useQuery([selectedTower], function () {
        return getData("/buildings/" + (selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value) + "/apartments/dropdown?tower=" + (selectedTower === null || selectedTower === void 0 ? void 0 : selectedTower.value));
    }, {
        onSuccess: function (data) {
            var _a;
            var apartments = (_a = data.apartments) === null || _a === void 0 ? void 0 : _a.map(function (_a) {
                var id = _a.id, number = _a.number;
                return ({
                    label: number,
                    value: id,
                });
            });
            setApartmentList(__spreadArrays([selectedAll], apartments));
        },
        retry: false,
        refetchOnWindowFocus: false,
        enabled: selectedTower,
    });
    var towerIds = (selectedTower === null || selectedTower === void 0 ? void 0 : selectedTower.value) ? [selectedTower.value] : [];
    useQuery([APARTMENTS_API, selectedBuilding, selectedTower], function () { return getData("/buildings/" + (selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value) + "/apartments?tower=" + towerIds); }, {
        onSuccess: function (_a) {
            var apartments = _a.apartments;
            setRows(apartments === null || apartments === void 0 ? void 0 : apartments.map(function (_a) {
                var number = _a.number, proprietary = _a.proprietary, tenant = _a.tenant;
                return ({
                    first: number,
                    second: proprietary || '-',
                    third: tenant || '-',
                });
            }));
            setVisibleRows(rows);
            setData(apartments);
        },
        retry: false,
        enabled: selectedApartment,
        refetchOnWindowFocus: false,
    });
    useEffect(function () {
        if ((selectedApartment === null || selectedApartment === void 0 ? void 0 : selectedApartment.label) === 'Todos') {
            return setVisibleRows(rows);
        }
        rows === null || rows === void 0 ? void 0 : rows.filter(function (row) {
            if (row.first === (selectedApartment === null || selectedApartment === void 0 ? void 0 : selectedApartment.label)) {
                return setVisibleRows([row]);
            }
        });
    }, [selectedApartment, rows]);
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, null, APARTMENTS),
            React.createElement(TableContainer, null,
                buildingList && (React.createElement(Filter, { data: buildingList, building: selectedBuilding
                        ? selectedBuilding.label
                        : buildingList === null || buildingList === void 0 ? void 0 : buildingList[0].label, setBuilding: handleSetBuilding, towerList: towerList, tower: selectedTower === null || selectedTower === void 0 ? void 0 : selectedTower.label, setTower: handleSetTower, apartmentList: apartmentList, apartment: selectedApartment === null || selectedApartment === void 0 ? void 0 : selectedApartment.label, setApartment: setSelectedApartment, setOrderBy: setOrderBy, orderBy: orderBy, hasSearch: false, hasDateFilter: false, isPaymentHistory: true })),
                visibleRows &&
                    (isSmallScreen ? (stableSort(visibleRows, getComparator('asc', orderBy)).map(function (_a, index) {
                        var first = _a.first, second = _a.second, third = _a.third;
                        return (React.createElement(MaintenanceCard, { key: first.toString() + index, first: first.toString(), second: second.toString(), third: third.toString(), isApartment: true }));
                    })) : (React.createElement(Table, { rows: visibleRows, headCells: headCells, widths: WIDTHS, data: data, className: "left", showBuildingWithContact: true }))),
                (isLoading || isLoadingTowers) && (React.createElement(BeatLoader, { size: "8px", color: theme.palette.black[100] }))))));
};
export default ApartmentsPage;
