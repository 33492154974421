import React from 'react';
import { InputFilter } from './InputFilter';
import { CheckboxFilter } from './CheckboxFilter';
import { characteristicsFilter, locationFilter, otherFilter, priceFilter, typesFilter, } from './utils';
import { SelectFilter } from './SelectFilter';
import ToggleFilter from './ToggleFilter/ToggleFilter';
import { DesktopContainer, Divider, InnerDesktopContainer } from './styles';
export var FilterDesktop = function () {
    return (React.createElement(DesktopContainer, null,
        React.createElement(InnerDesktopContainer, null,
            React.createElement(ToggleFilter, null),
            React.createElement(SelectFilter, { filter: locationFilter }),
            React.createElement(Divider, null),
            React.createElement(CheckboxFilter, { filter: typesFilter }),
            React.createElement(Divider, null),
            React.createElement(SelectFilter, { filter: characteristicsFilter }),
            React.createElement(Divider, null),
            React.createElement(InputFilter, { filter: priceFilter, isPrice: true }),
            React.createElement(Divider, null),
            React.createElement(InputFilter, { filter: otherFilter }))));
};
