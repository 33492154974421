import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Cell } from 'recharts';
import { BeatLoader } from 'react-spinners';
import { ChartContainer, Title, Div, Dot, NumberContainer, Total, TotalText, ColumnContainer, } from './styles';
import { SelectComponent } from '../Common';
import { PAYMENT_NETWORK_KEY, theme } from 'src/Utils';
import { openedArrow } from 'src/assets/Images';
import { Popover } from '../Common';
var colors = ['#9BC1FF', '#BBD5FF', '#5091FF'];
var Chart = function (_a) {
    var title = _a.title, isPayments = _a.isPayments, data = _a.data, month = _a.month, setMonth = _a.setMonth, total = _a.total, selectedPaymentNetwork = _a.selectedPaymentNetwork, setSelectedPaymentNetwork = _a.setSelectedPaymentNetwork;
    var _b = useState(null), anchorRefPaymentNetwork = _b[0], setAnchorRefPaymentNetwork = _b[1];
    var renderPopOver = function (data, anchorRef, type) { return (React.createElement(Popover, { data: data, className: "space", handleOutsideClick: function () { return setAnchorRefPaymentNetwork(null); }, anchorRef: anchorRef, setSelected: function (item) { return setSelectedPaymentNetwork === null || setSelectedPaymentNetwork === void 0 ? void 0 : setSelectedPaymentNetwork(item); }, isFilter: false, type: type })); };
    var renderNumbers = function (item, index) { return (React.createElement(ColumnContainer, null,
        React.createElement(NumberContainer, null,
            React.createElement(Dot, { style: { backgroundColor: colors[index] } }),
            React.createElement(Title, { className: "number" },
                "$ ",
                item.UYU)))); };
    var renderBar = function (key) { return (React.createElement(Bar, { dataKey: key, barSize: 22 }, data === null || data === void 0 ? void 0 : data.map(function (entry, index) { return (React.createElement(Cell, { style: { cursor: 'pointer' }, onClick: function () { return entry.month && (setMonth === null || setMonth === void 0 ? void 0 : setMonth(entry.month)); }, fill: isPayments
            ? colors[index]
            : entry.month === month
                ? '#E2EDFF'
                : '#9BC1FF' })); }))); };
    return (React.createElement(Div, null,
        React.createElement(Title, null, title),
        React.createElement(ChartContainer, null,
            isPayments ? (React.createElement("div", { style: { height: '60px' } },
                React.createElement(SelectComponent, { classNames: "width", label: "", data: selectedPaymentNetwork === null || selectedPaymentNetwork === void 0 ? void 0 : selectedPaymentNetwork.label, type: PAYMENT_NETWORK_KEY, img: openedArrow, anchorRef: anchorRefPaymentNetwork, setAnchorRef: setAnchorRefPaymentNetwork }))) : (React.createElement(Total, null,
                React.createElement(TotalText, null,
                    "Total Pesos: ", total === null || total === void 0 ? void 0 :
                    total.UYU))),
            data ? (React.createElement(BarChart, { width: 510, height: isPayments ? 210 : 260, data: data },
                React.createElement(XAxis, { dataKey: "label", axisLine: false, tickLine: false, tickCount: 3 }),
                React.createElement(YAxis, { axisLine: false, tickLine: false, tickFormatter: function (tickValue) { return tickValue / 1000000 + "M"; } }),
                React.createElement(CartesianGrid, { vertical: false, stroke: "#D8D8D8" }),
                renderBar('UYU'))) : (React.createElement(BeatLoader, { size: "5px", color: theme.palette.black[100] })),
            isPayments && (React.createElement(Div, { className: "bottom-row" }, data === null || data === void 0 ? void 0 : data.map(function (item, index) {
                return renderNumbers(item, index);
            })))),
        isPayments &&
            renderPopOver([
                { label: 'Todos', value: 'Todos' },
                { label: 'Banred', value: 'Banred' },
                { label: 'Abitab', value: 'Abitab' },
                { label: 'Redpagos', value: 'RedPagos' },
                { label: 'Sistarbanc', value: 'Sistarbanc' },
            ], anchorRefPaymentNetwork, PAYMENT_NETWORK_KEY)));
};
export default Chart;
