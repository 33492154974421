import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { BeatLoader } from 'react-spinners';
import moment from 'moment';
import Cookies from 'js-cookie';
import { AdminLayout } from 'src/Layouts';
import { Container, Title, RelativeDiv } from './styles';
import { DOCUMENT, theme, BILLS, BUILDING_API, BILLS_API, DATE_FORMAT_MOMENT, UPLOAD_BILL, NAME, MODIFIED_BY, BILL_TEXT, ACTIONS, DATE_UPLOADED, } from 'src/Utils';
import { createData, createHeadCells } from 'src/Utils/utils';
import { DeleteModal, FilterTable } from 'src/Components/Common';
import api, { getData } from 'src/Utils/api';
import { submitDataForm, submitPDF } from './duck';
function createBuilding(label, value, rows) {
    return { label: label, value: value, rows: rows };
}
var isSuperAdmin = Cookies.get('SuperAdmin') === 'true';
var headCells = !isSuperAdmin
    ? [
        { id: 'first', numeric: true, disablePadding: false, label: DOCUMENT },
        {
            id: 'second',
            numeric: true,
            disablePadding: false,
            label: DATE_UPLOADED,
        },
        {
            id: 'third',
            numeric: true,
            disablePadding: false,
            label: '',
        },
        {
            id: 'fourth',
            numeric: true,
            disablePadding: false,
            label: '',
        },
    ]
    : createHeadCells(DOCUMENT, DATE_UPLOADED, MODIFIED_BY, ACTIONS);
var BillsPage = function () {
    var _a = useState(), buildingList = _a[0], setBuildingList = _a[1];
    var _b = useState(), selectedBuilding = _b[0], setSelectedBuilding = _b[1];
    var _c = useState([]), rows = _c[0], setRows = _c[1];
    var _d = useState([]), buildingRows = _d[0], setBuildingRows = _d[1];
    var _e = useState(), startDate = _e[0], setStartDate = _e[1];
    var _f = useState(), endDate = _f[0], setEndDate = _f[1];
    var _g = useState(false), isModalOpen = _g[0], setIsModalOpen = _g[1];
    var _h = useState(false), isDeleteModalOpen = _h[0], setIsDeleteModalOpen = _h[1];
    var _j = useState(), toDelete = _j[0], setToDelete = _j[1];
    var _k = useState(null), file = _k[0], setFile = _k[1];
    var _l = useState(), billId = _l[0], setBillId = _l[1];
    var mutateFormBill = useMutation(submitDataForm, {
        onSuccess: function (response) {
            var id = response.data.id;
            setBillId(id);
            setIsModalOpen(false);
        },
    })[0];
    var _m = useMutation(submitPDF), mutatePDF = _m[0], isSuccessPDF = _m[1].isSuccess;
    var isLoadingBuildings = useQuery(BUILDING_API, function () { return getData("/buildings"); }, {
        onSuccess: function (data) {
            var buildings = data.buildings.map(function (_a) {
                var name = _a.name, id = _a.id;
                return createBuilding(name, id, rows);
            });
            buildings.sort(function (a, b) {
                return a.label.localeCompare(b.label, 'es');
            });
            setBuildingList(buildings);
        },
        refetchOnWindowFocus: false,
    }).isLoading;
    var refetch = useQuery([BILLS_API, selectedBuilding, startDate, endDate, isSuccessPDF], function () {
        return getData('/bills', {
            start_date: startDate
                ? moment(startDate).format(DATE_FORMAT_MOMENT)
                : null,
            end_date: endDate ? moment(endDate).format(DATE_FORMAT_MOMENT) : null,
            building_id: selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value,
        });
    }, {
        onSuccess: function (_a) {
            var bills = _a.bills;
            if (bills.length) {
                setBuildingRows(bills);
                bills.sort(function (a, b) { return moment(b.created_at).diff(moment(a.created_at)); });
                if (!isSuperAdmin) {
                    setRows(bills.map(function (_a) {
                        var name = _a.name, date = _a.date, _b = _a.pdf, pdf = _b === void 0 ? '' : _b;
                        return createData(name, moment(date).format(DATE_FORMAT_MOMENT), '', '', pdf);
                    }));
                }
                else {
                    setRows(bills.map(function (_a) {
                        var name = _a.name, date = _a.date, modified_by = _a.modified_by, _b = _a.pdf, pdf = _b === void 0 ? '' : _b;
                        return createData(name, moment(date).format(DATE_FORMAT_MOMENT), modified_by, '', pdf);
                    }));
                }
            }
            else
                setRows([]);
        },
        refetchOnWindowFocus: false,
        enabled: selectedBuilding,
    }).refetch;
    useEffect(function () {
        if (file && billId)
            mutatePDF({ file: file, id: billId });
    }, [file, billId]);
    var handleModalSubmit = function (name, date, building, file) {
        setFile(file);
        mutateFormBill({
            name: name,
            building_id: building,
            date: date,
        });
    };
    var handleModal = function (rowDescription) {
        setIsDeleteModalOpen(true);
        setToDelete(rowDescription);
    };
    var handleDeleteRow = function () {
        var filteredRows = rows.filter(function (object) {
            return object.first !== toDelete;
        });
        var billsToDelete = buildingRows.find(function (element) { return element.name === toDelete; });
        api.delete("/bills/" + (billsToDelete === null || billsToDelete === void 0 ? void 0 : billsToDelete.id));
        setRows(filteredRows);
        setIsDeleteModalOpen(false);
    };
    var WIDTHS = isSuperAdmin
        ? ['30%', '30%', '20%', '20%', '5%']
        : ['40%', '40%', '30%', '30%'];
    return (React.createElement(AdminLayout, null,
        React.createElement(RelativeDiv, null,
            React.createElement(Container, null,
                React.createElement(Title, null, BILLS),
                isLoadingBuildings && (React.createElement(BeatLoader, { size: "8px", color: theme.palette.black[100] })),
                buildingList && (React.createElement(FilterTable, { rows: rows, building: null, headCells: headCells, buildingList: buildingList, width: WIDTHS, userWidth: WIDTHS, selectedBuilding: selectedBuilding, setSelectedBuilding: setSelectedBuilding, startDate: startDate, setStartDate: setStartDate, endDate: endDate, isInvoice: true, setEndDate: setEndDate, handleButtonClick: function () { return setIsModalOpen(function (prev) { return !prev; }); }, isModalOpen: isModalOpen, setIsModalOpen: setIsModalOpen, modalButton: UPLOAD_BILL, modalField: NAME, modalTitle: UPLOAD_BILL, handleModalSubmit: handleModalSubmit, handleDeleteRow: handleModal, hasDelete: true, hasDateFilter: false, refetch: refetch })),
                React.createElement(DeleteModal, { isOpen: isDeleteModalOpen, setIsOpen: setIsDeleteModalOpen, onDelete: handleDeleteRow, itemLabel: BILL_TEXT + " " + (toDelete || '') })))));
};
export default BillsPage;
