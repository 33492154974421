var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useState } from 'react';
import { Table, TableBody, TableContainer, TablePagination, IconButton, } from '@material-ui/core';
import { BeatLoader } from 'react-spinners';
import TableHeader from './TableHeader';
import Row from './TableRow';
import { ArrowsContainer, PaginationLabel, StyledPaper, ButtonContainer, Div, Button, } from '../Common/Table/styles';
import { ArrowRight, ArrowLeft } from '../../assets/Images';
import DotsRow from './TableRow/DotsRow';
import ContactRow from './TableRow/ContactRow';
import ProviderRow from './TableRow/ProviderRow';
import DeleteAdminsRow from './TableRow/DeleteAdminsRow';
import SummonAssemblyRow from './TableRow/SummonAssemblyRow';
import BuildingWithContactRow from './TableRow/BuildingWithContactRow';
import { theme } from 'src/Utils';
import { stableSort, getComparator, orderByState } from 'src/Utils/utils';
var ROWS_PER_PAGE = 15;
var TableComponent = function (_a) {
    var data = _a.data, rows = _a.rows, rowsEdit = _a.rowsEdit, headCells = _a.headCells, _b = _a.hasDots, hasDots = _b === void 0 ? false : _b, modalButton = _a.modalButton, handleButtonClick = _a.handleButtonClick, handleDelete = _a.handleDelete, widths = _a.widths, className = _a.className, historyText = _a.historyText, _c = _a.topIndex, topIndex = _c === void 0 ? rows.length : _c, _d = _a.shouldShowContacts, shouldShowContacts = _d === void 0 ? false : _d, _e = _a.shouldShowProviders, shouldShowProviders = _e === void 0 ? false : _e, isSummon = _a.isSummon, setContactData = _a.setContactData, setProviderData = _a.setProviderData, setSelectedAdmin = _a.setSelectedAdmin, selectedBuilding = _a.selectedBuilding, isCommonExpenses = _a.isCommonExpenses, isLoading = _a.isLoading, isMessage = _a.isMessage, isContact = _a.isContact, _f = _a.shouldDeleteAdmins, shouldDeleteAdmins = _f === void 0 ? false : _f, _g = _a.shouldShowAssembly, shouldShowAssembly = _g === void 0 ? false : _g, setSent = _a.setSent, setSelected = _a.setSelected, submit = _a.submit, setSelectedId = _a.setSelectedId, setIsOpenModal = _a.setIsOpenModal, setSelectedPDF = _a.setSelectedPDF, orderByStateIndex = _a.orderByStateIndex, showBuildingWithContact = _a.showBuildingWithContact, handleDeleteRow = _a.handleDeleteRow, _h = _a.hasButton, hasButton = _h === void 0 ? true : _h, setSearchedItem = _a.setSearchedItem, searchedItem = _a.searchedItem, selectedTower = _a.selectedTower, selectedApartment = _a.selectedApartment, deleteRow = _a.deleteRow, _j = _a.paginationDisabled, paginationDisabled = _j === void 0 ? false : _j, _k = _a.rowsPerPage, rowsPerPage = _k === void 0 ? ROWS_PER_PAGE : _k, setOpenModalContacts = _a.setOpenModalContacts, handleSuspend = _a.handleSuspend;
    var _l = useState('desc'), order = _l[0], setOrder = _l[1];
    var _m = useState(1), stateOrder = _m[0], setStateOrder = _m[1];
    var _o = useState('initial'), orderBy = _o[0], setOrderBy = _o[1];
    var _p = useState(0), page = _p[0], setPage = _p[1];
    var handleChangePage = function (event, newPage) {
        setPage(newPage);
    };
    function TablePaginationActions(props) {
        var count = props.count, page = props.page, rowsPerPage = props.rowsPerPage, onPageChange = props.onPageChange;
        var handleBackButtonClick = function (event) { return onPageChange(event, page - 1); };
        var handleNextButtonClick = function (event) { return onPageChange(event, page + 1); };
        return (React.createElement(Div, { className: handleButtonClick ? '' : 'centered' },
            React.createElement(ArrowsContainer, { className: handleButtonClick ? 'minutes' : '' },
                React.createElement(IconButton, { onClick: handleBackButtonClick, disabled: !page, "aria-label": "previous page" },
                    React.createElement("img", { src: ArrowLeft, alt: "P\u00E1gina previa" })),
                React.createElement(PaginationLabel, null, page + 1 + "/" + Math.ceil(count / rowsPerPage)),
                React.createElement(IconButton, { onClick: handleNextButtonClick, disabled: page >= Math.ceil(count / rowsPerPage) - 1, "aria-label": "next page" },
                    React.createElement("img", { src: ArrowRight, alt: "P\u00E1gina siguiente" }))),
            !isContact && handleButtonClick && hasButton && (React.createElement(ButtonContainer, null,
                React.createElement(Button, { onClick: handleButtonClick }, modalButton)))));
    }
    var getPendingFirst = function (rows) {
        var pendingRows = [];
        var notPendingRows = [];
        rows === null || rows === void 0 ? void 0 : rows.forEach(function (row) {
            if (row.status == 'Pendiente') {
                pendingRows.push(row);
            }
            else
                notPendingRows.push(row);
        });
        return __spreadArrays(pendingRows, notPendingRows);
    };
    var rowsShown;
    var initialSort = orderBy === 'initial';
    var statusSort = orderBy === 'status';
    var dateSortedRows = stableSort(rows, getComparator('desc', 'payed_at'));
    if (initialSort) {
        rowsShown = getPendingFirst(dateSortedRows);
    }
    else {
        var sortedRows = stableSort(rows, getComparator(order, orderBy));
        var rowsSortedByStatus = orderByState(dateSortedRows, stateOrder, 'status');
        rowsShown = statusSort ? rowsSortedByStatus : sortedRows;
    }
    var filterApts = function (key) {
        var foundApt = data === null || data === void 0 ? void 0 : data.find(function (apt) { return apt.number === key; });
        return foundApt;
    };
    return (React.createElement(StyledPaper, null,
        React.createElement(TableContainer, null,
            React.createElement(Table, { "aria-labelledby": "tableTitle", size: 'medium', "aria-label": "enhanced table" },
                React.createElement(TableHeader, { fields: headCells, widths: widths, order: order, orderBy: orderBy, setOrder: setOrder, setStateOrder: setStateOrder, setOrderBy: setOrderBy, className: className, orderByStateIndex: orderByStateIndex, searchedItem: searchedItem, setSearchedItem: setSearchedItem }),
                isLoading ? (React.createElement(BeatLoader, { size: "5px", color: theme.palette.black[100] })) : (React.createElement(TableBody, null, rowsShown
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(function (row, index) {
                    return shouldShowContacts ? (React.createElement(ContactRow, { key: row.id, data: row, widths: widths, handleDelete: handleDelete, setContactData: setContactData })) : shouldShowProviders ? (React.createElement(ProviderRow, { key: row.id, data: row, widths: widths, handleDelete: handleDelete, setProviderData: setProviderData, historyText: historyText })) : shouldDeleteAdmins ? (React.createElement(DeleteAdminsRow, { key: row.first.toString() + index, data: row, widths: widths, handleDelete: handleDelete, setSelectedAdmin: setSelectedAdmin })) : shouldShowAssembly ? (React.createElement(SummonAssemblyRow, { building: selectedBuilding, key: row.first.toString() + index, data: row, widths: widths, isContact: isContact, setSent: setSent, handleDeleteRow: handleDeleteRow, handleSuspend: handleSuspend })) : hasDots ? (React.createElement(DotsRow, { key: row.first.toString() + index, data: row, widths: widths, handleDelete: handleDelete, historyText: historyText, topIndex: topIndex, isCommonExpenses: isCommonExpenses, handlePaymentModal: setIsOpenModal, isMessage: isMessage, state: row.state, setSelected: setSelected, setSelectedId: setSelectedId, setSelectedPDF: setSelectedPDF, submit: submit, selectedBuilding: selectedBuilding, selectedTower: selectedTower, selectedApartment: selectedApartment, deleteRow: deleteRow, rows: rowsEdit, setOpenModalContacts: setOpenModalContacts })) : showBuildingWithContact ? (React.createElement(BuildingWithContactRow, { key: row.id, info: row, widths: widths, apartment: filterApts(row.first) })) : (React.createElement(Row, { data: row, widths: widths, isSummon: isSummon }));
                }))))),
        !paginationDisabled && (React.createElement(TablePagination, { component: "div", labelRowsPerPage: "", count: rows.length, rowsPerPage: rowsPerPage, page: page, onPageChange: handleChangePage, ActionsComponent: TablePaginationActions }))));
};
export default TableComponent;
