import React, { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { ResetPasswordComponent } from 'src/Components';
import { Header } from 'src/Layouts';
import { useWindowWidth } from 'src/useWindowWidth';
import { EMAIL, EMAIL_KEY, INSTRUCTIONS_SENT, INSTRUCTIONS_SENT_TEXT, RESET_PASSWORD, RESET_PASSWORD_TEXT, S, SEND_INSTRUCTIONS, } from 'src/Utils/constants';
import { Container } from './styles';
import Footer from '../Footer';
import { LandingTick, LoginBackground, LoginMobileBackground, } from 'src/assets/Images';
import { forgotPassword } from './duck';
import { isValidForm } from 'src/Utils/validations';
import { ResponseForm } from 'src/Components/ResetPasswordForm';
var inputs = [{ key: EMAIL_KEY, name: EMAIL }];
var header = {
    title: RESET_PASSWORD,
    description: RESET_PASSWORD_TEXT,
};
var ForgotPassword = function () {
    var _a = useState(''), backError = _a[0], setBackError = _a[1];
    var _b = useState({ email: '' }), error = _b[0], setError = _b[1];
    var _c = useState({ email: '' }), email = _c[0], setEmail = _c[1];
    var windowWidth = useWindowWidth();
    var smallScreen = windowWidth > S;
    var _d = useMutation(forgotPassword, {
        onError: function (error) {
            setBackError(error.response.data.error);
        },
    }), mutateForgotPassword = _d[0], _e = _d[1], isLoading = _e.isLoading, isSuccess = _e.isSuccess;
    var submitForm = function (email) { return mutateForgotPassword({ email: email }); };
    var handleClick = useCallback(function (e) {
        e.preventDefault();
        if (isValidForm(email, setError))
            submitForm(email[EMAIL_KEY]);
        else
            setBackError('');
    }, [email, setBackError]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, null),
        React.createElement(Container, { style: {
                backgroundImage: "url(" + (smallScreen ? LoginBackground : LoginMobileBackground) + ")",
            } }, isSuccess ? (React.createElement(ResponseForm, { header: {
                title: INSTRUCTIONS_SENT,
                description: "" + INSTRUCTIONS_SENT_TEXT + email[EMAIL_KEY],
            }, icon: LandingTick })) : (React.createElement(ResetPasswordComponent, { onSubmit: handleClick, inputs: inputs, buttonLabel: SEND_INSTRUCTIONS, header: header, backError: backError, inputError: error, formData: email, setFormData: setEmail, isLoading: isLoading }))),
        React.createElement(Footer, null)));
};
export default ForgotPassword;
