import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useWindowWidth } from 'src/useWindowWidth';
import { XL } from 'src/Utils';
import { CarruselLeft, CarruselMobileLeft, CarruselMobileRight, CarruselRight, } from 'src/assets/Images';
import GalleryItem from './GalleryItem';
import { StyledModal, Container, Nav } from './styles';
export var ModalCarrusel = function (_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, images = _a.images;
    var windowWidth = useWindowWidth();
    var isMobile = windowWidth < XL;
    var renderLeftNav = function (onClick) { return (React.createElement(Nav, { className: isMobile ? 'mobileL' : 'left', src: isMobile ? CarruselMobileLeft : CarruselLeft, onClick: onClick })); };
    var renderRightNav = function (onClick) { return (React.createElement(Nav, { className: isMobile ? 'mobileR' : 'right', src: isMobile ? CarruselMobileRight : CarruselRight, onClick: onClick })); };
    var renderItem = GalleryItem(function () { return setIsOpen(false); }, isMobile);
    return (React.createElement(StyledModal, { open: isOpen, onClose: function () { return setIsOpen(false); } },
        React.createElement(Container, null,
            React.createElement(ImageGallery, { items: images, showPlayButton: false, showFullscreenButton: false, showThumbnails: !isMobile, showBullets: isMobile, renderLeftNav: renderLeftNav, renderRightNav: renderRightNav, renderItem: renderItem }))));
};
