import React from 'react';
import { includesWord } from 'src/Utils/utils';
import { Container, Title, Div } from './styles';
import ListItem, { MessageItem } from '.';
var List = function (_a) {
    var data = _a.data, title = _a.title, searchedItem = _a.searchedItem;
    return (React.createElement(Container, null,
        React.createElement(Div, { className: "color" }, title && React.createElement(Title, null, title)),
        data.map(function (item, index) {
            return (!searchedItem || includesWord(item.first, searchedItem)) &&
                (title ? (React.createElement(ListItem, { data: item, key: item.first + index, isLastItem: index == data.length - 1 })) : (React.createElement(MessageItem, { data: item, key: item.first + index, isLastItem: index == data.length - 1 })));
        })));
};
export default List;
