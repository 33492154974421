import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import { ASSEMBLY } from 'src/Utils/constants';
import { LogoPdf } from 'src/assets/Images';
import styles from './styles';
export var PDFHeader = function (_a) {
    var type = _a.type, printable = _a.printable;
    return printable ? (React.createElement(View, { style: styles.headerPrint },
        React.createElement(Text, { style: styles.title },
            ASSEMBLY,
            " ",
            type))) : (React.createElement(View, { style: styles.header, fixed: true },
        React.createElement(Text, null,
            ASSEMBLY,
            " ",
            type),
        React.createElement(Image, { src: LogoPdf, style: styles.logo })));
};
