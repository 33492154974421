var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { getData, selectedAll } from 'src/Utils';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { setTowerList } from '../../../../redux/slices/reservationsSlice';
import { setSelectedApartment, setSelectedTower, } from '../../../../redux/slices/tableFiltersSlice';
var useTowerAndApartmentData = function (_a) {
    var selectedBuilding = _a.selectedBuilding;
    var dispatch = useAppDispatch();
    var _b = useAppSelector(function (state) { return state.tableFiltersSlice; }), selectedTower = _b.selectedTower, formSelected = _b.formSelected;
    var towerList = useAppSelector(function (state) { return state.reservationsSlice; }).towerList;
    useQuery([selectedBuilding], function () { return getData("/buildings/" + (selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value) + "/towers"); }, {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: !!selectedBuilding,
        onSuccess: function (data) {
            var _a;
            dispatch(setTowerList(((_a = data === null || data === void 0 ? void 0 : data.towers) === null || _a === void 0 ? void 0 : _a.map(function (_a) {
                var id = _a.id, name = _a.name, code = _a.code;
                return ({
                    label: name,
                    value: id,
                    code: code,
                });
            })) || []));
        },
    });
    var dataApartments = useQuery([selectedTower], function () {
        return getData("/buildings/" + (selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value) + "/apartments/dropdown?tower=" + (selectedTower === null || selectedTower === void 0 ? void 0 : selectedTower.value));
    }, {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: !!selectedTower,
    }).data;
    var apartmentData = useMemo(function () {
        var _a;
        return __spreadArrays([
            selectedAll
        ], (((_a = dataApartments === null || dataApartments === void 0 ? void 0 : dataApartments.apartments) === null || _a === void 0 ? void 0 : _a.map(function (_a) {
            var id = _a.id, number = _a.number;
            return ({
                label: number,
                value: id,
            });
        })) || []));
    }, [dataApartments]);
    useEffect(function () {
        if (!formSelected) {
            dispatch(setSelectedTower(towerList === null || towerList === void 0 ? void 0 : towerList[0]));
            dispatch(setSelectedApartment(selectedAll));
        }
    }, [towerList]);
    useEffect(function () {
        if (!formSelected)
            dispatch(setSelectedApartment(selectedAll));
    }, [apartmentData]);
    return {
        towerList: towerList,
        apartmentData: apartmentData,
        selectedTower: selectedTower,
    };
};
export default useTowerAndApartmentData;
