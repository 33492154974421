var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M } from 'src/Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  border-bottom: 1px solid ", ";\n  padding-bottom: 24px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  border-bottom: 1px solid ", ";\n  padding-bottom: 24px;\n"])), theme.palette.grey[55]);
export var Text = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  white-space: nowrap;\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  @media (max-width: ", "px) {\n    font-size: 12px;\n  }\n"], ["\n  white-space: nowrap;\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  @media (max-width: ", "px) {\n    font-size: 12px;\n  }\n"])), theme.palette.grey[50], theme.typography.normal.raleway, M);
export var Image = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  @media (max-width: ", "px) {\n    width: 20px;\n    height: 20px;\n  }\n"], ["\n  @media (max-width: ", "px) {\n    width: 20px;\n    height: 20px;\n  }\n"])), M);
var templateObject_1, templateObject_2, templateObject_3;
