import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { FASCIOLI_ASSISTS_SECOND_CALL, FASCIOLI_DOES_NOT_ASSIST, MODALITY, SUMMON_INVITE, } from 'src/Utils/constants';
import styles from './styles';
export var PDFInstructions = function (_a) {
    var fascioliAssists = _a.fascioliAssists, modality = _a.modality, link = _a.link, orders = _a.orders;
    return (React.createElement(View, { style: styles.section2 },
        React.createElement(Text, { style: styles.fontNormal }, SUMMON_INVITE),
        React.createElement("div", { style: styles.list }, orders === null || orders === void 0 ? void 0 : orders.map(function (order, iterator) { return (React.createElement(Text, { style: styles.listItem, key: iterator },
            iterator + 1,
            ".",
            ' ',
            React.createElement(Text, { style: { color: '#000', fontFamily: 'Helvetica' } }, order))); })),
        React.createElement(Text, { style: [styles.fontNormal, { marginTop: 24 }] },
            MODALITY,
            " ",
            modality),
        !!link && (React.createElement(Text, { style: [styles.fontNormal, { textDecoration: 'underline' }] }, link)),
        React.createElement(Text, { style: [styles.fontNormal, { marginTop: 16 }] }, fascioliAssists ? "* " + FASCIOLI_ASSISTS_SECOND_CALL : "* " + FASCIOLI_DOES_NOT_ASSIST)));
};
