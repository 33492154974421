import React from 'react';
import { Radio, RadioGroup } from 'react-custom-radio-buttons';
import { RadioButton, SelectedRadioButton } from 'src/assets/Images';
import { NO, YES } from 'src/Utils/constants';
import { Div, ImgRadio } from './styles';
var RadioComponent = function (_a) {
    var selectedOption = _a.selectedOption, onChange = _a.onChange;
    var options = [
        { label: YES, value: true },
        { label: NO, value: false },
    ];
    return (React.createElement(RadioGroup, { containerStyle: "radio", onChange: onChange }, options.map(function (option, index) { return (React.createElement(Radio, { "aria-checked": "true", key: option.label + index, value: option.value, render: function (_a) {
            var isSelected = _a.isSelected;
            return (React.createElement(Div, { className: "row" },
                option.value,
                React.createElement(ImgRadio, { src: isSelected || option.value === selectedOption
                        ? SelectedRadioButton
                        : RadioButton })));
        } })); })));
};
export default RadioComponent;
