import React, { useEffect, useState } from 'react';
import { usePDF } from '@react-pdf/renderer';
import { useMutation } from 'react-query';
import moment from 'moment';
import { PdfComponent } from 'src/Components/ReceiptForm';
import { DATE_FORMAT_MOMENT, RECEIPT_KEY } from 'src/Utils/constants';
import { submitPDF } from './duck';
var CreatePDF = function (data) {
    var PDFdocument = (React.createElement(PdfComponent, { clientNumber: data.second || '', location: data.address || '', receiptNumber: data.receiptNumber || '', expirationDate: data.third || '', totalAmount: data.amount || '', holder: data.building || '', issueDate: moment(new Date()).format(DATE_FORMAT_MOMENT) || '', description: data.description || '', currency: data.currency || '' }));
    var _a = usePDF({ document: PDFdocument }), instance = _a[0], updateInstance = _a[1];
    var _b = useState(), addedPDF = _b[0], setAddedPDF = _b[1];
    var mutatePDF = useMutation(submitPDF, {
        onSuccess: function (response) {
            var pdf = response.data.pdf;
            setAddedPDF(pdf);
        },
    })[0];
    useEffect(function () {
        updateInstance();
    }, []);
    useEffect(function () {
        if (instance.blob && data.ID) {
            var file = new File([instance.blob], RECEIPT_KEY + "-" + data.receiptNumber + ".pdf", {
                type: 'application/pdf',
            });
            mutatePDF({ file: file, receiptId: data.ID });
        }
    }, [instance.blob]);
    return addedPDF;
};
export default CreatePDF;
