import React from 'react';
import { Div, DownloadContainer, Row, } from '../../Common/Table/TableRow/styles';
import { formatDate } from 'src/Utils/utils';
import { Cell } from './style';
import { APARTMENTS_SECOND_TEXT, ASSEMBLY, DATE_TEXT, DOWNLOAD, FIFTH, PDF_KEY, SECOND, SENT, TOWERS_TEXT, } from 'src/Utils/constants';
import { DownloadArrow } from 'src/assets/Images';
import { downloadPDF } from 'src/Utils/utils';
import BuildingContacts from './BuildingContacts';
import BuildingCleaner from './BuildingCleaner';
var TableRow = function (_a) {
    var data = _a.data, widths = _a.widths, isSummon = _a.isSummon;
    return (React.createElement(Row, { hover: true, tabIndex: -1, key: data.first }, Object.keys(data).map(function (key, index) {
        return index < 8 &&
            key !== PDF_KEY && (React.createElement(Cell, { key: key + index, style: { width: widths[index] }, className: key === TOWERS_TEXT || key === APARTMENTS_SECOND_TEXT
                ? 'center'
                : key === FIFTH && data[key] === SENT
                    ? 'bold'
                    : '' },
            key === DATE_TEXT
                ? formatDate(data[key])
                : key !== 'id' && key !== 'buildingId' && data[key],
            key === 'id' && (React.createElement(BuildingContacts, { buildingId: data[key] })),
            key === 'buildingId' && (React.createElement(BuildingCleaner, { buildingId: data[key] })),
            React.createElement(Div, null, isSummon && index === 5 && data[PDF_KEY] && (React.createElement(DownloadContainer, { onClick: function () {
                    return downloadPDF(data[PDF_KEY], ASSEMBLY + "_" + data[SECOND]);
                } },
                DOWNLOAD,
                React.createElement("img", { src: DownloadArrow, alt: DOWNLOAD }))))));
    })));
};
export default TableRow;
