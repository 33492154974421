import React from 'react';
import { Container, Content, Image, ImageContainer, Title, Description, DescriptionContainer, } from './styles';
var Card = function (_a) {
    var _b = _a.item, title = _b.title, description = _b.description, image = _b.image;
    return (React.createElement(Container, null,
        React.createElement(ImageContainer, null,
            React.createElement(Image, { src: image })),
        React.createElement(Content, null,
            React.createElement(Title, null, title),
            React.createElement(DescriptionContainer, null,
                React.createElement(Description, null, description)))));
};
export default Card;
