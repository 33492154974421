var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useWindowWidth } from 'src/useWindowWidth';
import { M } from 'src/Utils';
import { Row, BoxContainer, Title, Label } from '../styles';
import { CheckboxButton } from './Checkbox/styles';
export var CheckboxFilter = function (_a) {
    var filter = _a.filter;
    var filters = filter.filters, title = filter.title;
    var windowWidth = useWindowWidth();
    var isMobile = windowWidth < M;
    var updatedFilters = filters.map(function (filter) { return (__assign(__assign({}, filter), { checked: false })); });
    var _b = useState(updatedFilters), newFilters = _b[0], setNewFilters = _b[1];
    var _c = useFormContext(), setValue = _c.setValue, watch = _c.watch;
    var watchedValue = watch('property_type');
    useEffect(function () {
        if (watchedValue === undefined)
            return;
        var updatedFilters = filters.map(function (filter) { return (__assign(__assign({}, filter), { checked: watchedValue.includes(filter.value) })); });
        var componentValues = newFilters.filter(function (_a) {
            var checked = _a.checked;
            return checked;
        }).map(function (_a) {
            var value = _a.value;
            return value;
        });
        var needUpdate = JSON.stringify(watchedValue) !== JSON.stringify(componentValues);
        if (needUpdate) {
            setNewFilters(updatedFilters);
        }
    }, [watchedValue]);
    var handleChange = function (newValue) {
        var updatedFilters = newFilters.map(function (filter) { return (__assign(__assign({}, filter), { checked: newValue === filter.value ? !filter.checked : filter.checked })); });
        setNewFilters(updatedFilters);
        var values = updatedFilters.reduce(function (acc, _a) {
            var value = _a.value, checked = _a.checked;
            return (checked ? __spreadArrays(acc, [value]) : acc);
        }, []);
        setValue('property_type', values);
    };
    return (React.createElement(BoxContainer, null,
        !isMobile && React.createElement(Title, null, title),
        newFilters.map(function (_a) {
            var label = _a.label, checked = _a.checked, value = _a.value;
            return (React.createElement(Row, { gap: "8px", className: "align-center", key: value },
                React.createElement(CheckboxButton, { type: "checkbox", checked: checked, onChange: function () { return handleChange(value); } }),
                React.createElement(Label, null, label)));
        })));
};
