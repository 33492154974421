import React from 'react';
import { Container } from './styles';
import { EMAIL, EMAIL_KEY, PASSWORD_KEY, PASSWORD, SUBMIT_LOGIN_TEXT, LOGIN, S, } from 'src/Utils';
import { LoginComponent } from 'src/Components';
import { LoginBackground, LoginMobileBackground } from 'src/assets/Images';
import { useWindowWidth } from 'src/useWindowWidth';
import Footer from '../Footer';
import { Header } from 'src/Layouts';
var inputs = [
    { key: EMAIL_KEY, name: EMAIL },
    { key: PASSWORD_KEY, name: PASSWORD },
];
var Login = function () {
    var windowWidth = useWindowWidth();
    var smallScreen = windowWidth > S;
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, null),
        React.createElement(Container, { style: {
                backgroundImage: "url(" + (smallScreen ? LoginBackground : LoginMobileBackground) + ")",
            } },
            React.createElement(LoginComponent, { inputs: inputs, buttonText: SUBMIT_LOGIN_TEXT, title: LOGIN })),
        React.createElement(Footer, null)));
};
export default Login;
