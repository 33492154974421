var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M } from '../../../Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  min-height: 100vh;\n  height: 100%;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 0px 5%;\n  flex-direction: column;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    padding: 0px 28px;\n  }\n"], ["\n  display: flex;\n  min-height: 100vh;\n  height: 100%;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 0px 5%;\n  flex-direction: column;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    padding: 0px 28px;\n  }\n"])), theme.palette.admin.primary[50], M);
export var TableContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 17px;\n  width: 100%;\n  background-color: ", ";\n  border-radius: 21px;\n  @media (max-width: ", "px) {\n    background-color: ", ";\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 17px;\n  width: 100%;\n  background-color: ", ";\n  border-radius: 21px;\n  @media (max-width: ", "px) {\n    background-color: ", ";\n  }\n"])), theme.palette.white[100], M, theme.palette.admin.primary[50]);
export var Title = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 24px;\n  font-family: ", ";\n  width: 100%;\n  margin-bottom: 30px;\n  margin-top: 5%;\n  margin-left: -20px;\n  background-color: ", ";\n  color: ", ";\n  @media (max-width: ", "px) {\n    margin-left: 0px;\n    font-size: 18px;\n    font-family: ", ";\n    color: ", ";\n  }\n"], ["\n  font-size: 24px;\n  font-family: ", ";\n  width: 100%;\n  margin-bottom: 30px;\n  margin-top: 5%;\n  margin-left: -20px;\n  background-color: ", ";\n  color: ", ";\n  @media (max-width: ", "px) {\n    margin-left: 0px;\n    font-size: 18px;\n    font-family: ", ";\n    color: ", ";\n  }\n"])), theme.typography.bold.ralewayBold, theme.palette.admin.primary[50], theme.palette.admin.primary[75], M, theme.typography.OpenSans, theme.palette.black[100]);
export var UploadContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  z-index: 2;\n  right: 0;\n  bottom: 0;\n  position: fixed;\n"], ["\n  z-index: 2;\n  right: 0;\n  bottom: 0;\n  position: fixed;\n"])));
export var UploadButton = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
