import React from 'react';
import { Container } from './styles';
import { HISTORY, L, OUR_HISTORY } from 'src/Utils';
import { useWindowWidth } from 'src/useWindowWidth';
import { OurHistoryImage, OurHistoryBackground } from '../../assets/Images';
import { OurHistoryComponent } from 'src/Components';
import Cookies from 'js-cookie';
import { TYPE_CONTACT } from 'src/Utils/constants';
var OurHistory = function () {
    var windowWidth = useWindowWidth();
    var items = {
        title: OUR_HISTORY,
        description: HISTORY,
        image: windowWidth < L ? OurHistoryBackground : OurHistoryImage,
    };
    var isContact = Cookies.get('User') === TYPE_CONTACT;
    return isContact ? (React.createElement(React.Fragment, null)) : (React.createElement(Container, null,
        React.createElement(OurHistoryComponent, { items: items })));
};
export default OurHistory;
