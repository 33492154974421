import React from 'react';
import { DownloadRE } from 'src/assets/Images';
import { DOWNLOAD, LOADING, PROPERTY_SHEET } from 'src/Utils';
import { PDFComponent } from './PDFComponent';
import { Container, StyledPDFDownloadLink, Text } from './styles';
export var PDFButton = function (_a) {
    var info = _a.info;
    return (React.createElement(StyledPDFDownloadLink, { document: React.createElement(PDFComponent, { info: info }), fileName: info.name }, function (_a) {
        var loading = _a.loading;
        return (React.createElement(Container, null,
            React.createElement("img", { src: DownloadRE }),
            React.createElement(Text, null, (loading ? LOADING : DOWNLOAD) + " " + PROPERTY_SHEET)));
    }));
};
