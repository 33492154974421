import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { useHistory } from 'react-router-dom';
import { CarouselMain, CarouselSecond } from '../../assets/Images';
import { ROUTES } from 'src/Utils';
import { Content, Title, DescriptionContainer, CarouselElement, CustomIndicator, } from './styles';
var Hero = function (_a) {
    var labels = _a.labels;
    var push = useHistory().push;
    var customIndicator = function (clickHandler, isSelected) {
        return React.createElement(CustomIndicator, { onClick: clickHandler, isSelected: isSelected });
    };
    return (React.createElement(Carousel, { showThumbs: false, showArrows: false, showStatus: false, autoPlay: true, infiniteLoop: true, renderIndicator: customIndicator, interval: 3000 },
        React.createElement(CarouselElement, { image: CarouselMain, onClick: function () { return push(ROUTES.login); } },
            React.createElement(Content, null,
                React.createElement(Title, null, labels.title),
                React.createElement(DescriptionContainer, null, labels.description))),
        React.createElement(CarouselElement, { image: CarouselSecond, onClick: function () { return push(ROUTES.real_estate); } },
            React.createElement(Content, { maxWidth: "800px" },
                React.createElement(Title, null, labels.secondTitle)))));
};
export default Hero;
