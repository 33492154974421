import React from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import { DATE_FORMAT } from 'src/Utils';
import { CalendarDiv, ContainerDiv, DatePickerStyled, Div } from './styles';
var CalendarComponent = function (_a) {
    var selected = _a.selected, onChange = _a.onChange, selectsStart = _a.selectsStart, selectsEnd = _a.selectsEnd, startDate = _a.startDate, endDate = _a.endDate, minDate = _a.minDate, customInput = _a.customInput, filterDate = _a.filterDate;
    var CustomContainer = function (_a) {
        var className = _a.className, children = _a.children;
        return (React.createElement(ContainerDiv, { className: !selectsStart && !selectsEnd ? 'form' : '' },
            React.createElement(CalendarDiv, { className: className },
                React.createElement(Div, null, children))));
    };
    registerLocale('es', es);
    return (React.createElement(DatePickerStyled, null,
        React.createElement(DatePicker, { selected: selected, onChange: onChange, selectsStart: selectsStart, selectsEnd: selectsEnd, startDate: startDate, dateFormat: DATE_FORMAT, endDate: endDate, minDate: minDate, customInput: customInput, calendarContainer: CustomContainer, locale: "es", filterDate: filterDate })));
};
export default CalendarComponent;
