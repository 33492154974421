var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect, useCallback } from 'react';
import { FormContainer, InputContainer, TopInputs, BottomInputs, Error, Label, Link, Div, ButtonsContainer, } from './styles';
import TextInput from '../TextInput';
import { CANCEL, REQUIRED, NEW_CLIENT_CODE_KEY } from 'src/Utils/constants';
import { AdminButton } from '../AdminButton';
import { isValidForm, hasError } from 'src/Utils/validations';
var Form = function (_a) {
    var inputs = _a.inputs, onSubmit = _a.onSubmit, buttonLabel = _a.buttonLabel, onCancel = _a.onCancel, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b, backError = _a.backError, className = _a.className, _c = _a.shouldModifyContact, shouldModifyContact = _c === void 0 ? false : _c;
    var _d = useState({}), errors = _d[0], setErrors = _d[1];
    var _e = useState({}), formData = _e[0], setFormData = _e[1];
    useEffect(function () {
        var initialFormData = {};
        var initialFormErrors = {};
        inputs.forEach(function (_a) {
            var key = _a.key, value = _a.value;
            initialFormData[key] = value;
            initialFormErrors[key] = '';
        });
        setFormData(initialFormData);
        setErrors(initialFormErrors);
    }, [inputs]);
    var handleClick = useCallback(function (e) {
        e.preventDefault();
        if (isValidForm(formData, setErrors))
            onSubmit(formData);
    }, [formData, setErrors]);
    return (React.createElement(FormContainer, null,
        React.createElement(TopInputs, null, inputs.map(function (_a) {
            var key = _a.key, label = _a.label, className = _a.className, isPassword = _a.isPassword, isEmail = _a.isEmail;
            return (React.createElement(InputContainer, { key: key },
                React.createElement(Label, { className: className }, label + " " + (isPassword || isEmail ? '' : REQUIRED)),
                React.createElement(TextInput, { placeholder: label, value: formData[key], onChange: function (e) {
                        e.persist();
                        setFormData(function (prevState) {
                            var _a;
                            return (__assign(__assign({}, prevState), (_a = {}, _a[key] = key === NEW_CLIENT_CODE_KEY
                                ? e.target.value.toUpperCase()
                                : e.target.value, _a)));
                        });
                    }, hasError: hasError(key, errors), className: "border-admin " + className, type: isPassword ? 'password' : '', isPassword: isPassword }),
                React.createElement(Div, null,
                    React.createElement(Error, null, errors[key]))));
        })),
        React.createElement(BottomInputs, { className: className },
            React.createElement(ButtonsContainer, null,
                shouldModifyContact ? (React.createElement(AdminButton, { type: "button", onClick: onCancel, className: "height white" }, CANCEL)) : (React.createElement(Link, { onClick: onCancel, className: "margin-right" }, CANCEL)),
                React.createElement(AdminButton, { type: "button", onClick: handleClick, className: "height" }, buttonLabel)),
            React.createElement(Error, null, backError))));
};
export default Form;
