var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M, L } from '../../Utils';
export var Header = styled.header(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  width: 100%;\n  top: 0px;\n  z-index: 50;\n"], ["\n  position: fixed;\n  width: 100%;\n  top: 0px;\n  z-index: 50;\n"])));
export var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  padding: 35px;\n  background-color: ", ";\n  flex-direction: ", ";\n  @media (max-width: ", "px) {\n    padding: 10px 35px;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  padding: 35px;\n  background-color: ", ";\n  flex-direction: ",
    ";\n  @media (max-width: ", "px) {\n    padding: 10px 35px;\n  }\n"])), theme.palette.primary[50], function (_a) {
    var displayMenu = _a.displayMenu;
    return displayMenu ? 'column' : 'row';
}, M);
export var StyledDiv = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 16px;\n  font-family: ", ";\n  cursor: pointer;\n  margin-top: 5px;\n  ", ";\n"], ["\n  font-size: 16px;\n  font-family: ", ";\n  cursor: pointer;\n  margin-top: 5px;\n  ",
    ";\n"])), theme.typography.normal.raleway, function (_a) {
    var shouldShowMenu = _a.shouldShowMenu;
    return shouldShowMenu
        ? "background-color: " + theme.palette.white[100] + ";\n       color: " + theme.palette.primary[50] + ";"
        : "background-color: " + theme.palette.primary[50] + ";\n        color: " + theme.palette.white[100] + ";";
});
export var LogoContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex: 0.6 1 0%;\n  @media (max-width: ", "px) {\n    margin: 10px 0px;\n    display: flex;\n    align-items: center;\n  }\n"], ["\n  display: flex;\n  flex: 0.6 1 0%;\n  @media (max-width: ", "px) {\n    margin: 10px 0px;\n    display: flex;\n    align-items: center;\n  }\n"])), L);
export var ImageContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  text-align: left;\n  width: 50%;\n  justify-content: flex-start;\n"], ["\n  display: flex;\n  flex: 1;\n  text-align: left;\n  width: 50%;\n  justify-content: flex-start;\n"])));
export var Image = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: 60px;\n  cursor: pointer;\n"], ["\n  height: 60px;\n  cursor: pointer;\n"])));
export var MenuImage = styled.img(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  height: 60%;\n  cursor: pointer;\n"], ["\n  height: 60%;\n  cursor: pointer;\n"])));
export var Menu = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  height: 20px;\n  margin-top: 3px;\n"], ["\n  height: 20px;\n  margin-top: 3px;\n"])));
export var Items = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  flex: 1.5;\n  align-items: baseline;\n  max-height: 600px;\n  overflow: hidden;\n  transition: max-height 0.4s linear;\n  justify-content: ", ";\n  &.items-column {\n    display: flex;\n    justify-content: space-between;\n    flex-direction: column;\n    align-items: center;\n  }\n  &.hideItems {\n    max-height: 0px;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  flex: 1.5;\n  align-items: baseline;\n  max-height: 600px;\n  overflow: hidden;\n  transition: max-height 0.4s linear;\n  justify-content: ",
    ";\n  &.items-column {\n    display: flex;\n    justify-content: space-between;\n    flex-direction: column;\n    align-items: center;\n  }\n  &.hideItems {\n    max-height: 0px;\n  }\n"])), function (_a) {
    var displayMenu = _a.displayMenu;
    return displayMenu ? "center" : "end";
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
