import React from 'react';
import { useHistory } from 'react-router';
import { Button as StartedButton } from '../../Common';
import { Button } from './styles';
import { LOGIN_TEXT, REAL_ESTATE_DEPARTMENT_TEXT } from '../../../Utils';
var HeaderButton = function (_a) {
    var _b = _a.item, text = _b.text, key = _b.key, setIsOpen = _a.setIsOpen, shouldShowMenu = _a.shouldShowMenu;
    var history = useHistory();
    var handleClick = function () {
        if (history.location.hash !== '') {
            history.replace("/" + key);
        }
        else {
            history.push("/" + key);
        }
        setIsOpen(function (prev) { return !prev; });
    };
    var renderLoginButton = function () { return (React.createElement(StartedButton, { className: "add-margin", onClick: handleClick }, text)); };
    var renderButton = function () { return (React.createElement(Button, { shouldShowMenu: shouldShowMenu, onClick: handleClick }, text)); };
    switch (key) {
        case LOGIN_TEXT:
            return !shouldShowMenu ? renderLoginButton() : renderButton();
        case REAL_ESTATE_DEPARTMENT_TEXT:
            return !shouldShowMenu ? renderLoginButton() : renderButton();
        default:
            return renderButton();
    }
};
export default HeaderButton;
