import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { ROUTES, TYPE_CONTACT, TYPE_ADMIN, DOCUMENT_ROUTES } from './constants';
import Cookies from 'js-cookie';
var canAccess = function (isNotAllowed) {
    return Cookies.get('Access-Token') && !isNotAllowed;
};
var PrivateRoute = function (_a) {
    var exact = _a.exact, path = _a.path, component = _a.component, _b = _a.isContactAllowed, isContactAllowed = _b === void 0 ? false : _b, _c = _a.isAdminAllowed, isAdminAllowed = _c === void 0 ? true : _c;
    var location = useLocation();
    var isNotAllowed = (Cookies.get('User') === TYPE_CONTACT && !isContactAllowed) ||
        (Cookies.get('User') === TYPE_ADMIN && !isAdminAllowed);
    var isAllowed = canAccess(isNotAllowed);
    var redirectTo = isNotAllowed ? ROUTES.dashboard : ROUTES.login;
    var documentTypeRoute = DOCUMENT_ROUTES.find(function (route) { return route === location.pathname; });
    var redirectQueryParams = "" + location.search + (documentTypeRoute ? "&document_type=" + documentTypeRoute.split('/')[1] : '');
    return isAllowed ? (React.createElement(Route, { path: path, exact: exact, component: component })) : (React.createElement(Redirect, { to: {
            pathname: redirectTo,
            search: redirectQueryParams
        }, push: true }));
};
export default PrivateRoute;
