import React from 'react';
import { Image, Title, Description, ContentContainer, ContentDiv, } from '../Common/AdminCard/styles';
import { DownloadArrow, Calendar, Store } from 'src/assets/Images';
import { ColumnContainer, Container, Content, Currency, ImageContainer, Price, PriceContainer, RightContainer, } from './styles';
var Card = function (_a) {
    var _b = _a.item, first = _b.first, date = _b.date, amount = _b.amount, currency = _b.currency, net = _b.net;
    return (React.createElement(Container, null,
        React.createElement(ColumnContainer, null,
            React.createElement(Title, null, first),
            React.createElement(Content, null,
                React.createElement(ContentContainer, null,
                    React.createElement(ContentDiv, null,
                        React.createElement(Image, { className: "description", src: Calendar }),
                        React.createElement(Description, null, date)),
                    React.createElement(ContentDiv, { className: "second" },
                        React.createElement(Image, { className: "description", src: Store }),
                        React.createElement(Description, null, net))))),
        React.createElement(RightContainer, null,
            React.createElement(PriceContainer, null,
                React.createElement(Price, null,
                    "$",
                    amount),
                React.createElement(Currency, null, currency)),
            React.createElement(ImageContainer, null,
                React.createElement("img", { src: DownloadArrow })))));
};
export default Card;
