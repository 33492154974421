import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Container, Content, Image, ImageContainer, Title, Description, ContentContainer, ContentDiv, DownloadContainer, } from './styles';
import { AdminButton } from '../AdminButton';
import { DotsImg } from '../Table/TableRow/styles';
import Popover from '../Popover';
import { DownloadArrow, Calendar, Clock, Dots, Download } from 'src/assets/Images';
import { downloadPDF, formatDate } from 'src/Utils/utils';
import { DELETE, MODIFY, PAY, ROUTES, TYPE_CONTACT } from 'src/Utils/constants';
import { Cell } from 'src/Components/GenericTable/TableRow/style';
import { deleteCommonExpensesReceipt } from 'src/Views/DocumentsPages/CommonExpensesPage/services';
var Card = function (_a) {
    var _b = _a.item, first = _b.first, second = _b.second, third = _b.third, printable = _b.printable, preview = _b.preview, _c = _a.isCommonExpenses, isCommonExpenses = _c === void 0 ? false : _c, _d = _a.isDocumentsPage, isDocumentsPage = _d === void 0 ? false : _d, _e = _a.isSettelmentPage, isSettelmentPage = _e === void 0 ? false : _e, _f = _a.isAssembly, isAssembly = _f === void 0 ? false : _f, _g = _a.isSummonsPage, isSummonsPage = _g === void 0 ? false : _g, selectedBuilding = _a.selectedBuilding, selectedTower = _a.selectedTower, selectedApartment = _a.selectedApartment, data = _a.data;
    var isContact = Cookies.get('User') === TYPE_CONTACT;
    var _h = useState(null), anchorRef = _h[0], setAnchorRef = _h[1];
    var handleClick = function (e) {
        return anchorRef ? setAnchorRef(null) : setAnchorRef(e.currentTarget);
    };
    var _j = useState({
        label: '',
        value: '',
    }), selectedOption = _j[0], setSelectedOption = _j[1];
    var history = useHistory();
    useEffect(function () {
        switch (selectedOption.label) {
            case DELETE:
                var id = data[0].ID;
                onDelete(id);
                break;
            case MODIFY:
                history.push({
                    pathname: ROUTES.edit_receipt + "/" + data[0].ID,
                    state: {
                        selectedBuilding: selectedBuilding,
                        selectedTower: selectedTower,
                        selectedApartment: selectedApartment,
                    },
                });
                break;
        }
    }, [selectedOption]);
    var onDelete = function (id) {
        mutateDeleteCommonExpensesReceipt({ id: id });
    };
    var mutateDeleteCommonExpensesReceipt = useMutation(deleteCommonExpensesReceipt, {})[0];
    var handleDownload = function (isPrintable) {
        var name = "Asamblea " + first;
        var path = isPrintable ? printable : preview;
        downloadPDF(path, name);
    };
    var renderDownloadIcon = function () { return (React.createElement(ImageContainer, { className: isDocumentsPage ? 'margin' : '' },
        React.createElement(DownloadContainer, null,
            React.createElement(Image, { src: DownloadArrow, onClick: function () { return handleDownload(true); } }),
            React.createElement(Image, { src: Download, onClick: function () { return handleDownload(false); } })),
        !isContact && isCommonExpenses && (React.createElement(Cell, null,
            React.createElement(DotsImg, { src: Dots, alt: "M\u00E1s", onClick: handleClick }),
            React.createElement(Popover, { data: [
                    { label: DELETE, value: DELETE },
                    { label: MODIFY, value: MODIFY },
                ], handleOutsideClick: function () { return setAnchorRef(null); }, anchorRef: anchorRef, setSelected: setSelectedOption, className: "filter width", isFilter: false }))),
        isCommonExpenses && isContact && (React.createElement(AdminButton, { className: "pay" }, PAY)))); };
    return (React.createElement(Container, { className: isDocumentsPage ? 'document' : '' },
        React.createElement(Title, null, first),
        isDocumentsPage && renderDownloadIcon(),
        React.createElement(Content, { className: isDocumentsPage ? 'none' : '' },
            React.createElement(ContentContainer, null,
                React.createElement(ContentDiv, null,
                    React.createElement(Image, { className: "description", src: Calendar }),
                    React.createElement(Description, null, formatDate(isAssembly ? third : isSummonsPage ? first : second))),
                React.createElement(ContentDiv, { className: "second" },
                    !isCommonExpenses && !isSettelmentPage && (React.createElement(Image, { className: "description", src: Clock })),
                    React.createElement(Description, { className: isCommonExpenses ? 'bold' : '' }, third))),
            !isDocumentsPage && renderDownloadIcon())));
};
export default Card;
