var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { PDFDownloadLink, StyleSheet } from '@react-pdf/renderer';
import styled from '@emotion/styled';
import { L, theme } from 'src/Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 12px;\n  border-radius: 7px;\n  border: 1px solid ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: fit-content;\n  gap: 12px;\n  cursor: pointer;\n  width: max-content;\n  @media (max-width: ", "px) {\n    width: auto;\n  }\n"], ["\n  padding: 12px;\n  border-radius: 7px;\n  border: 1px solid ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: fit-content;\n  gap: 12px;\n  cursor: pointer;\n  width: max-content;\n  @media (max-width: ", "px) {\n    width: auto;\n  }\n"])), theme.palette.blue[5], L);
export var Text = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n"])), theme.palette.blue[5], theme.typography.normal.raleway);
export var StyledPDFDownloadLink = styled(PDFDownloadLink)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-decoration: none;\n  @media (max-width: ", "px) {\n    width: 100%;\n  }\n"], ["\n  text-decoration: none;\n  @media (max-width: ", "px) {\n    width: 100%;\n  }\n"])), L);
var styles = StyleSheet.create({
    page: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.white[100],
        padding: '30 20 40 20',
        flexGrow: 1,
        fontFamily: 'Courier',
        position: 'relative',
    },
    header: {
        padding: 20,
        borderRadius: 5,
        backgroundColor: theme.palette.primary[50],
        color: theme.palette.white[100],
        fontWeight: 'bold',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    logo: {
        height: 30,
    },
    informationContainer: {
        padding: '20 16',
    },
    information: {
        fontSize: 12,
        paddingRight: 5,
    },
    table: {
        paddingBottom: 30,
    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: theme.palette.primary[14],
        fontWeight: 'bold',
        fontSize: 10,
        alignItems: 'center',
        textTransform: 'capitalize',
    },
    row: {
        color: theme.palette.black[80],
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 8,
        textTransform: 'capitalize',
    },
    rowDark: {
        color: theme.palette.black[80],
        backgroundColor: theme.palette.admin.primary[15],
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 8,
        textTransform: 'capitalize',
    },
    lineTitle: {
        backgroundColor: theme.palette.primary[80],
        width: '100%',
        height: 1,
    },
    watermarkLogo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        position: 'absolute',
        bottom: 120,
        left: 0,
        right: 0,
    },
    footerContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        padding: '35 20',
        marginTop: '20',
    },
    footer: {
        height: 56,
        paddingTop: 6,
        paddingBottom: 6,
        borderRadius: 5,
        backgroundColor: theme.palette.primary[50],
        color: theme.palette.white[100],
        fontSize: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    footerLogos: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 5,
    },
    image: {
        height: '8',
        marginRight: 8,
    },
    imageHeight: {
        height: '8',
    },
});
export default styles;
var templateObject_1, templateObject_2, templateObject_3;
