import React from 'react';
import { APARTMENT, BUILDING, PARTNER_APARTMENT, TOWER, } from 'src/Utils/constants';
import { Title, TableContainer, } from '../ApartmentForm/styles';
import Table from 'src/Components/GenericTable';
var headCells = [
    { id: 'first', numeric: true, disablePadding: false, label: TOWER },
    { id: 'second', numeric: true, disablePadding: false, label: BUILDING },
    { id: 'third', numeric: true, disablePadding: false, label: APARTMENT },
];
var WIDTHS = ['30%', '30%', '30%'];
var ApartmentForm = function (_a) {
    var rows = _a.rows;
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, null, PARTNER_APARTMENT),
        rows && (React.createElement(TableContainer, null,
            React.createElement(Table, { rows: rows, headCells: headCells, widths: WIDTHS, topIndex: 4 })))));
};
export default ApartmentForm;
