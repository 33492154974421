var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { InputBase, Select } from '@material-ui/core';
import { theme, S, M, XXXL } from '../../Utils';
export var FormContainer = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  width: 100%;\n  max-width: 500px;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  width: 100%;\n  max-width: 500px;\n  justify-content: space-between;\n"])));
export var BuildingFormContainer = styled.form(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  width: 100%;\n"])));
export var StyledImg = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 17px;\n  height: 17px;\n  display: flex;\n  justify-content: flex-end;\n  cursor: pointer;\n"], ["\n  width: 17px;\n  height: 17px;\n  display: flex;\n  justify-content: flex-end;\n  cursor: pointer;\n"])));
export var FormsContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n"])));
export var FormsDiv = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  max-width: 1400px;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n    max-width: inherit;\n    width: auto;\n  }\n  @media (max-width: ", "px) {\n    flex-wrap: wrap;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  max-width: 1400px;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n    max-width: inherit;\n    width: auto;\n  }\n  @media (max-width: ", "px) {\n    flex-wrap: wrap;\n  }\n"])), M, XXXL);
export var BuildingFormDiv = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n    max-width: inherit;\n    width: auto;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n    max-width: inherit;\n    width: auto;\n  }\n"])), M);
export var InputContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  height: 100px;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  &.height {\n    min-height: 50px;\n    height: auto;\n  }\n  &.radioHeight {\n    height: auto;\n  }\n  &.row {\n    flex-direction: row;\n  }\n  .radio {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    justify-content: flex-end;\n  }\n  &.margin {\n    margin-bottom: 20px;\n  }\n  &.justify {\n    justify-content: space-between;\n  }\n  &.width {\n    width: 140px;\n  }\n  @media (max-width: ", "px) {\n    width: auto;\n  }\n"], ["\n  height: 100px;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  &.height {\n    min-height: 50px;\n    height: auto;\n  }\n  &.radioHeight {\n    height: auto;\n  }\n  &.row {\n    flex-direction: row;\n  }\n  .radio {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    justify-content: flex-end;\n  }\n  &.margin {\n    margin-bottom: 20px;\n  }\n  &.justify {\n    justify-content: space-between;\n  }\n  &.width {\n    width: 140px;\n  }\n  @media (max-width: ", "px) {\n    width: auto;\n  }\n"])), S);
export var Error = styled.label(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  align-self: flex-start;\n  font-size: 12px;\n  margin-top: 10px;\n  height: 20px;\n  @media (max-width: ", "px) {\n    align-self: center;\n    align-items: center;\n  }\n"], ["\n  color: ", ";\n  align-self: flex-start;\n  font-size: 12px;\n  margin-top: 10px;\n  height: 20px;\n  @media (max-width: ", "px) {\n    align-self: center;\n    align-items: center;\n  }\n"])), theme.palette.error[50], S);
export var TopInputs = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  justify-content: space-between;\n  display: flex;\n  flex-direction: column;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n  }\n"], ["\n  justify-content: space-between;\n  display: flex;\n  flex-direction: column;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n  }\n"])), S);
export var Label = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: 14px;\n  margin-bottom: 5px;\n  color: ", ";\n  width: 100%;\n"], ["\n  font-size: 14px;\n  margin-bottom: 5px;\n  color: ", ";\n  width: 100%;\n"])), theme.palette.black[100]);
export var Link = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-size: 14px;\n  text-decoration-line: underline;\n  cursor: pointer;\n  margin-right: 50px;\n  color: ", ";\n  &.margin-right {\n    margin-right: 16px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 12px;\n  }\n"], ["\n  font-size: 14px;\n  text-decoration-line: underline;\n  cursor: pointer;\n  margin-right: 50px;\n  color: ", ";\n  &.margin-right {\n    margin-right: 16px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 12px;\n  }\n"])), theme.palette.admin.primary[75], M);
export var Div = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  font-size: 14px;\n  font-family: ", ";\n  &.row {\n    flex-direction: row;\n  }\n  &.column {\n    flex-direction: column;\n  }\n  &.height {\n    height: 20px;\n    align-self: center;\n  }\n  &.counter {\n    height: 43px;\n    background-color: white;\n    border-radius: 9px;\n    padding: 0px 8px;\n  }\n  &.spaced {\n    margin-right: 8px;\n    width: 100%;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  font-size: 14px;\n  font-family: ", ";\n  &.row {\n    flex-direction: row;\n  }\n  &.column {\n    flex-direction: column;\n  }\n  &.height {\n    height: 20px;\n    align-self: center;\n  }\n  &.counter {\n    height: 43px;\n    background-color: white;\n    border-radius: 9px;\n    padding: 0px 8px;\n  }\n  &.spaced {\n    margin-right: 8px;\n    width: 100%;\n  }\n"])), theme.typography.normal.raleway);
export var BottomInputs = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  width: 100%;\n  justify-content: flex-start;\n  margin-top: 20px;\n  max-width: 1400px;\n"], ["\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  width: 100%;\n  justify-content: flex-start;\n  margin-top: 20px;\n  max-width: 1400px;\n"])));
export var ButtonsContainer = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0px;\n  justify-content: flex-end;\n  padding: 25px 40px;\n  background-color: ", ";\n  border-radius: 8px;\n  @media (max-width: ", "px) {\n    padding-right: 20px;\n    background-color: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0px;\n  justify-content: flex-end;\n  padding: 25px 40px;\n  background-color: ", ";\n  border-radius: 8px;\n  @media (max-width: ", "px) {\n    padding-right: 20px;\n    background-color: ", ";\n  }\n"])), theme.palette.admin.primary[45], M, theme.palette.admin.primary[50]);
export var DetailsContainer = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: flex;\n  min-height: 150px;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  border-radius: 8px;\n  margin-bottom: 14px;\n  background-color: ", ";\n  max-width: 340px;\n  width: 100%;\n  padding: 10px 35px 5px 35px;\n  &.margin {\n    margin-right: 15px;\n  }\n  @media (max-width: ", "px) {\n    margin-left: 10px;\n    max-width: inherit;\n    padding: 0px;\n    padding: 5px 20px;\n    width: auto;\n    background-color: ", ";\n  }\n"], ["\n  display: flex;\n  min-height: 150px;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  border-radius: 8px;\n  margin-bottom: 14px;\n  background-color: ", ";\n  max-width: 340px;\n  width: 100%;\n  padding: 10px 35px 5px 35px;\n  &.margin {\n    margin-right: 15px;\n  }\n  @media (max-width: ", "px) {\n    margin-left: 10px;\n    max-width: inherit;\n    padding: 0px;\n    padding: 5px 20px;\n    width: auto;\n    background-color: ", ";\n  }\n"])), theme.palette.admin.primary[45], M, theme.palette.admin.primary[50]);
export var Input = styled(InputBase)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 14px !important;\n  background-color: ", ";\n  height: 43px !important;\n  width: 100%;\n  border-width: 0px;\n  font-family: ", " !important;\n"], ["\n  color: ", ";\n  font-size: 14px !important;\n  background-color: ", ";\n  height: 43px !important;\n  width: 100%;\n  border-width: 0px;\n  font-family: ", " !important;\n"])), theme.palette.black[100], theme.palette.white[100], theme.typography.normal.raleway);
export var SelectCont = styled(Select)(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  text-transform: capitalize;\n  z-index: 0;\n  min-height: 38px;\n  border-radius: 9px;\n  height: 100%;\n  width: 100%;\n  padding: 5px 8px;\n  .MuiSelect-select:focus {\n    border-radius: 9px;\n    background: none;\n  }\n  &.error {\n    border: 1px solid ", ";\n  }\n"], ["\n  text-transform: capitalize;\n  z-index: 0;\n  min-height: 38px;\n  border-radius: 9px;\n  height: 100%;\n  width: 100%;\n  padding: 5px 8px;\n  .MuiSelect-select:focus {\n    border-radius: 9px;\n    background: none;\n  }\n  &.error {\n    border: 1px solid ", ";\n  }\n"])), theme.palette.error[50]);
export var CounterInput = styled.input(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  all: inherit;\n  background: ", ";\n  width: 100%;\n"], ["\n  all: inherit;\n  background: ", ";\n  width: 100%;\n"])), theme.palette.white[100]);
export var FormTitle = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  font-weight: bold;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  line-height: 16px;\n  margin-bottom: 5px;\n  font-family: ", " !important;\n  width: 100%;\n  padding: 0px 20px 35px 0px;\n  color: ", ";\n"], ["\n  font-weight: bold;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  line-height: 16px;\n  margin-bottom: 5px;\n  font-family: ", " !important;\n  width: 100%;\n  padding: 0px 20px 35px 0px;\n  color: ", ";\n"])), theme.typography.bold.ralewayBold, theme.palette.admin.primary[75]);
export var Img = styled.img(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  cursor: pointer;\n  margin-left: 3px;\n  width: 70px;\n  margin-left: 15px;\n"], ["\n  cursor: pointer;\n  margin-left: 3px;\n  width: 70px;\n  margin-left: 15px;\n"])));
export var ButtonLabel = styled.div(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  font-family: ", " !important;\n  color: ", ";\n  font-size: 24px;\n  line-height: 28px;\n  margin-top: 20px;\n"], ["\n  font-family: ", " !important;\n  color: ", ";\n  font-size: 24px;\n  line-height: 28px;\n  margin-top: 20px;\n"])), theme.typography.bold.ralewayBold, theme.palette.white[100]);
export var PaginationContainer = styled.div(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  list-style: none;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: ", ";\n  border-radius: 8px;\n  height: fit-content;\n"], ["\n  list-style: none;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: ", ";\n  border-radius: 8px;\n  height: fit-content;\n"])), theme.palette.admin.primary[45]);
export var ButtonDiv = styled.div(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  max-width: 340px;\n  height: 560px;\n  background-color: rgba(108, 145, 201, 0.9);\n  z-index: 2 !important;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: 0;\n  align-items: center;\n  justify-content: center;\n  border-radius: 8px;\n  margin-bottom: 14px;\n  padding: 10px 35px 5px 35px;\n  @media (max-width: ", "px) {\n    width: auto;\n    flex-direction: column;\n    max-width: inherit;\n    padding: 0px;\n    top: 560px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  max-width: 340px;\n  height: 560px;\n  background-color: rgba(108, 145, 201, 0.9);\n  z-index: 2 !important;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: 0;\n  align-items: center;\n  justify-content: center;\n  border-radius: 8px;\n  margin-bottom: 14px;\n  padding: 10px 35px 5px 35px;\n  @media (max-width: ", "px) {\n    width: auto;\n    flex-direction: column;\n    max-width: inherit;\n    padding: 0px;\n    top: 560px;\n  }\n"])), M);
export var ErrorMessage = styled.label(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 12px;\n  margin-bottom: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 0px;\n  padding-left: 10px;\n"], ["\n  color: ", ";\n  font-size: 12px;\n  margin-bottom: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 0px;\n  padding-left: 10px;\n"])), theme.palette.error[50]);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24;
