var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import { Div, Input, Image } from './styles';
import { Add } from 'src/assets/Images';
var TextInput = function (_a) {
    var className = _a.className, onChange = _a.onChange, value = _a.value, disabled = _a.disabled, hasError = _a.hasError, placeholder = _a.placeholder, isPassword = _a.isPassword, type = _a.type, addClientCode = _a.addClientCode, rest = __rest(_a, ["className", "onChange", "value", "disabled", "hasError", "placeholder", "isPassword", "type", "addClientCode"]);
    var _b = useState(false), passwordShown = _b[0], setPasswordShown = _b[1];
    var togglePasswordVisiblity = function () { return setPasswordShown(!passwordShown); };
    return (React.createElement(React.Fragment, null, isPassword ? (React.createElement(Div, { className: className, hasError: hasError },
        React.createElement(Input, __assign({ className: className, value: value, onChange: onChange, disabled: disabled, hasError: hasError, placeholder: placeholder, type: passwordShown ? 'text' : type, isPassword: true }, rest)),
        isPassword && (React.createElement(IconButton, { onClick: togglePasswordVisiblity },
            React.createElement(VisibilityIcon, null))))) : addClientCode ? (React.createElement(Div, { hasError: hasError },
        React.createElement(Input, __assign({ className: className + " client-code", value: value, onChange: onChange, disabled: disabled, hasError: hasError, placeholder: placeholder, isPassword: true }, rest)),
        React.createElement("div", { onClick: addClientCode },
            React.createElement(Image, { src: Add })))) : (React.createElement(Input, __assign({ className: className, value: value, onChange: onChange, disabled: disabled, hasError: hasError, placeholder: placeholder, type: type }, rest)))));
};
export default TextInput;
