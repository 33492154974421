var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { OWNER_SIGNATURE, ATTORNEY_SIGNATURE } from 'src/Utils/constants';
import styles from './styles';
export var PDFSignatures = function (_a) {
    var printable = _a.printable;
    return (React.createElement(View, { style: printable ? styles.signaturesPrint : styles.signatures },
        React.createElement("div", { style: styles.signature },
            React.createElement("div", { style: __assign(__assign({}, styles.completeLine), { width: '150px', marginBottom: '10px' }) }),
            React.createElement(Text, { style: styles.fontNormal }, OWNER_SIGNATURE)),
        React.createElement("div", { style: styles.signature },
            React.createElement("div", { style: __assign(__assign({}, styles.completeLine), { width: '150px', marginBottom: '10px' }) }),
            React.createElement(Text, { style: styles.fontNormal }, ATTORNEY_SIGNATURE))));
};
