var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { Radio, RadioGroup } from 'react-custom-radio-buttons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useQuery } from 'react-query';
import { usePDF } from '@react-pdf/renderer';
import { Calendar, Clock, openedArrow, RadioButton, SelectedRadioButton, } from 'src/assets/Images';
import { ASSEMBLY_TYPE, ASSEMBLY_TYPES, BUILDING_API, BUILDING_API_KEY, CANCEL, CREATION_DATE, DATE, DATE_FORMAT_MOMENT, DATE_KEY, DATE_SET_KEY, FASCIOLI_ASSISTS, FASCIOLI_ASSISTS_KEY, FIRST_CALL, FIRST_CALL_KEY, HYBRID, MODALITY, MODALITY_KEY, NO, NUMBER, ONLINE, ORDERS_OF_THE_DAY_KEY, OTHER_KEY, PLACE, PLACE_KEY, ROUTES, SCHEDULE, SECOND_CALL, SECOND_CALL_KEY, SUMMON_TYPE_KEY, UNIT_KEY, YES, SAVE, SUMMONS_API, MODALITY_TYPES, } from 'src/Utils/constants';
import { isValidSummonForm } from 'src/Utils/validations';
import { Img } from '../Common/Filter/styles';
import { Popover } from '../Common';
import { Div, InputContainer, Label, DetailsContainer, CandelarContainer, ImgRadio, InputDisable, Title, FormContainer, FormsDiv, BottomInputs, ButtonsContainer, Link, FormTitle, FormsContainer, Error, ErrorMessage, } from './styles';
import { AdminButton } from '../Common/AdminButton';
import OrdersComponent from './OrdersComponent';
import SelectComponent from '../Common/SelectComponent';
import { ASSEMBLY_TYPE_DATA, MODALITY_DATA, PLACE_DATA, TIME_DATA, } from 'src/Utils/mockData';
import InputComponent from '../Common/InputComponent';
import TextInput from '../Common/TextInput';
import { increaseTime } from 'src/Utils/utils';
import CalendarComponent from '../Common/Calendar';
import { getData } from 'src/Utils/api';
import { PDFComponent as SummonPDF } from './PDFComponent';
import { handleDeleteRow } from 'src/Views/DocumentsPages/SummonsPage/services';
export var CustomInput = React.forwardRef(function (_a, ref) {
    var value = _a.value, placeholder = _a.placeholder, onClick = _a.onClick;
    return (React.createElement(CandelarContainer, null,
        React.createElement("label", { style: { width: '100%' }, onClick: onClick, ref: ref }, value || placeholder),
        React.createElement(Img, { src: Calendar, alt: "Calendar", className: "calendar", onClick: onClick })));
});
var options = [YES, NO];
var getAssemblyType = function (type) {
    var lowered = type.toLowerCase();
    switch (lowered) {
        case 'ordinaria':
            return ASSEMBLY_TYPES[0];
        case 'extraordinaria':
            return ASSEMBLY_TYPES[1];
        case 'urgente':
            return ASSEMBLY_TYPES[2];
        case 'levantamiento de 1/4 intermedio':
            return ASSEMBLY_TYPES[3];
        default:
            return lowered;
    }
};
var LinkInput = function (_a) {
    var value = _a.value, linkValue = _a.linkValue, setLink = _a.setLink;
    if (value == ONLINE || value == HYBRID) {
        return (React.createElement(TextInput, { className: value, value: linkValue, onChange: function (e) { return setLink(e.target.value); }, placeholder: 'Link', type: value }));
    }
    return null;
};
var SummonForm = function (_a) {
    var onSubmit = _a.onSubmit, backError = _a.backError, setShouldSend = _a.setShouldSend, buildingLabel = _a.buildingLabel, buildingId = _a.buildingId, rowData = _a.rowData;
    var history = useHistory();
    var currentDate = new Date();
    var _b = useState(), buildingList = _b[0], setBuildingList = _b[1];
    var _c = useState({
        label: '',
        value: '',
    }), assemblyTypeData = _c[0], setAssemblyTypeData = _c[1];
    var _d = useState({
        label: buildingLabel ? buildingLabel : '',
        value: buildingId ? buildingId : '',
    }), buildingData = _d[0], setBuildingData = _d[1];
    var _e = useState(new Date()), date = _e[0], setDate = _e[1];
    var _f = useState({
        label: '',
        value: '',
    }), placeData = _f[0], setPlaceData = _f[1];
    var _g = useState(), unitNumber = _g[0], setUnitNumber = _g[1];
    var _h = useState(''), otherPlace = _h[0], setOtherPlace = _h[1];
    var _j = useState({ label: '', value: '' }), time = _j[0], setTime = _j[1];
    var _k = useState(''), defaultTime = _k[0], setDefaultTime = _k[1];
    var _l = useState(NO), fascioliAssists = _l[0], setFascioliAssists = _l[1];
    var _m = useState({ label: '', value: '' }), modality = _m[0], setModality = _m[1];
    var _o = useState(''), link = _o[0], setLink = _o[1];
    var _p = useState([
        { number: 1, value: '' },
    ]), orders = _p[0], setOrders = _p[1];
    var _q = useState({}), errorsSubmit = _q[0], setErrorsSubmit = _q[1];
    var _r = useState(null), anchorRefBuilding = _r[0], setAnchorRefBuilding = _r[1];
    var _s = useState(null), anchorRefAssemblyType = _s[0], setAnchorRefAssemblyType = _s[1];
    var _t = useState(null), anchorRefDate = _t[0], setAnchorRefDate = _t[1];
    var _u = useState(null), anchorRefPlace = _u[0], setAnchorRefPlace = _u[1];
    var _v = useState(null), anchorRefTime = _v[0], setAnchorRefTime = _v[1];
    var _w = useState(null), anchorRefModality = _w[0], setAnchorRefModality = _w[1];
    var filterOrders = function (orders) {
        var filtered = [];
        orders.forEach(function (_a) {
            var value = _a.value;
            return value && filtered.push(value);
        });
        return filtered;
    };
    useEffect(function () {
        if (!rowData) {
            setAssemblyTypeData({ label: '', value: '' });
            setPlaceData({ label: '', value: '' });
            setDefaultTime('');
            setTime({ label: '', value: '' });
            setFascioliAssists(NO);
            setModality({ label: '', value: '' });
            setOrders([{ number: 1, value: '' }]);
        }
    }, []);
    var _x = useQuery(SUMMONS_API, function () {
        return getData("/summons/" + rowData.summonData.id);
    }), data = _x.data, isSuccess = _x.isSuccess;
    useEffect(function () {
        if (rowData && isSuccess && data) {
            setAssemblyTypeData(getAssembly(data.summon_type));
            setPlaceData({
                label: data.place.toString(),
                value: data.place.toString(),
            });
            setDefaultTime(data.time_first_call);
            setTime({
                label: data.time_second_call.slice(0, 5),
                value: data.time_second_call.slice(0, 5),
            });
            var assist = !data.fascioli_assists ? NO : YES;
            setFascioliAssists(assist);
            setModality(getModality(data.modality));
            var orders_1 = data.orders_of_the_day.map(function (_a, index) { return ({
                number: index + 1,
                value: data.orders_of_the_day[index],
            }); });
            setOrders(orders_1);
        }
    }, [isSuccess, data]);
    var getAssembly = function (type) {
        switch (type) {
            case ASSEMBLY_TYPES[0]:
                return ASSEMBLY_TYPE_DATA[0];
            case ASSEMBLY_TYPES[1]:
                return ASSEMBLY_TYPE_DATA[1];
            case ASSEMBLY_TYPES[2]:
                return ASSEMBLY_TYPE_DATA[2];
            default:
                return ASSEMBLY_TYPE_DATA[3];
        }
    };
    var getModality = function (modality) {
        switch (modality) {
            case MODALITY_TYPES[0]:
                return MODALITY_DATA[0];
            case MODALITY_TYPES[1]:
                return MODALITY_DATA[1];
            default:
                return MODALITY_DATA[2];
        }
    };
    var getPlaceLabel = function () {
        var place = String(placeData.value);
        switch (place) {
            case UNIT_KEY:
                return place + " " + unitNumber;
            case OTHER_KEY:
                return otherPlace;
            default:
                return place;
        }
    };
    var document = (React.createElement(SummonPDF, { type: String(assemblyTypeData.label), building: String(buildingData === null || buildingData === void 0 ? void 0 : buildingData.label), date: date, place: getPlaceLabel(), fascioliAssists: fascioliAssists === YES, modality: modality.label, link: link, orders: filterOrders(orders), schedules: [defaultTime, String(time.value)] }));
    var documentPrintable = (React.createElement(SummonPDF, { printable: true, type: String(assemblyTypeData.label), building: String(buildingData === null || buildingData === void 0 ? void 0 : buildingData.label), date: date, place: getPlaceLabel(), fascioliAssists: fascioliAssists === YES, modality: modality.label, link: link, orders: filterOrders(orders), schedules: [defaultTime, String(time.value)] }));
    var _y = usePDF({ document: document }), instance = _y[0], updateInstance = _y[1];
    var _z = usePDF({
        document: documentPrintable,
    }), instancePrint = _z[0], updateInstancePrint = _z[1];
    useQuery(BUILDING_API, function () { return getData("/buildings"); }, {
        onSuccess: function (data) {
            setBuildingList(data.buildings.map(function (_a) {
                var name = _a.name, id = _a.id;
                return ({
                    label: name,
                    value: id,
                });
            }));
        },
    });
    useEffect(function () {
        updateInstance();
        updateInstancePrint();
    }, [
        assemblyTypeData,
        buildingData,
        placeData,
        unitNumber,
        otherPlace,
        date,
        fascioliAssists,
        modality,
        link,
        orders,
        time,
        defaultTime,
    ]);
    useEffect(function () {
        var label = time.label;
        if (label != '')
            setDefaultTime(increaseTime(-1, label).padStart(5, '0'));
        if (label == '23:00')
            setDefaultTime('00:00');
        if (label == '23:30')
            setDefaultTime('00:30');
    }, [time]);
    var handleOutsideClick = function () {
        setAnchorRefBuilding(null);
        setAnchorRefAssemblyType(null);
        setAnchorRefDate(null);
        setAnchorRefPlace(null);
        setAnchorRefTime(null);
        setAnchorRefModality(null);
    };
    useQuery(BUILDING_API, function () { return getData("/buildings"); }, {
        onSuccess: function (data) {
            setBuildingList(data.buildings.map(function (_a) {
                var name = _a.name, id = _a.id;
                return ({
                    label: name,
                    value: id,
                });
            }));
        },
    });
    var handleSubmit = function (shouldSend) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setShouldSend && setShouldSend(shouldSend);
            errorsSubmit[SUMMON_TYPE_KEY] = getAssemblyType(String(assemblyTypeData.value));
            errorsSubmit[BUILDING_API_KEY] = buildingData === null || buildingData === void 0 ? void 0 : buildingData.value;
            errorsSubmit[DATE_SET_KEY] = date;
            errorsSubmit[FIRST_CALL_KEY] = defaultTime;
            errorsSubmit[SECOND_CALL_KEY] = time.value;
            errorsSubmit[PLACE_KEY] = placeData.label;
            errorsSubmit[FASCIOLI_ASSISTS_KEY] = fascioliAssists;
            errorsSubmit[MODALITY_KEY] = modality;
            errorsSubmit[ORDERS_OF_THE_DAY_KEY] = orders[0].value;
            if (String(placeData.value) === UNIT_KEY)
                errorsSubmit[UNIT_KEY] = unitNumber;
            if (String(placeData.value) === OTHER_KEY)
                errorsSubmit[OTHER_KEY] = otherPlace;
            if (isValidSummonForm(errorsSubmit, setErrorsSubmit)) {
                if (rowData)
                    handleDeleteRow(rowData.summonData.id);
                if (instance.url && instancePrint.url) {
                    onSubmit(getAssemblyType(String(assemblyTypeData.value)), moment(date).format(DATE_FORMAT_MOMENT), time.value.toString(), defaultTime, placeData, fascioliAssists === YES, modality.value, link, filterOrders(orders), instance, instancePrint, buildingData, placeData.value === UNIT_KEY ? unitNumber : otherPlace);
                }
            }
            return [2 /*return*/];
        });
    }); };
    var addInput = function (key) {
        if (key === UNIT_KEY || key === OTHER_KEY) {
            return (React.createElement(InputComponent, { type: key, data: key === UNIT_KEY ? unitNumber : otherPlace, error: errorsSubmit, placeHolder: key === UNIT_KEY ? NUMBER : PLACE, onChangeFunction: function (e) {
                    e.persist();
                    key === UNIT_KEY
                        ? setUnitNumber(e.target.value)
                        : setOtherPlace(e.target.value);
                } }));
        }
    };
    var onCancel = function () {
        return history.push({
            pathname: ROUTES.summons,
            state: { buildingId: buildingId },
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FormsContainer, null,
            React.createElement(FormsDiv, null,
                React.createElement(DetailsContainer, { className: "margin" },
                    React.createElement(FormContainer, null,
                        React.createElement(FormTitle, null, CREATION_DATE + ": " + moment(currentDate).format(DATE_FORMAT_MOMENT)),
                        React.createElement(SelectComponent, { label: ASSEMBLY_TYPE, type: SUMMON_TYPE_KEY, data: assemblyTypeData.label, errors: errorsSubmit, img: openedArrow, anchorRef: anchorRefAssemblyType, setAnchorRef: setAnchorRefAssemblyType }),
                        React.createElement(InputContainer, { className: "height" },
                            React.createElement(Label, null, DATE),
                            React.createElement(CalendarComponent, { selected: date, onChange: setDate, customInput: React.createElement(CustomInput, null) }),
                            React.createElement(Div, null,
                                React.createElement(Error, null, errorsSubmit[DATE_KEY]))),
                        React.createElement(SelectComponent, { label: PLACE, type: PLACE_KEY, data: placeData.label, errors: errorsSubmit, img: openedArrow, anchorRef: anchorRefPlace, setAnchorRef: setAnchorRefPlace }),
                        addInput(placeData.value),
                        React.createElement(Title, null, SCHEDULE),
                        React.createElement(Label, null, FIRST_CALL),
                        React.createElement(InputContainer, { className: "height" },
                            React.createElement(InputDisable, { disabled: true, value: !defaultTime ? '' : defaultTime })),
                        React.createElement(SelectComponent, { label: SECOND_CALL, type: SECOND_CALL_KEY, data: time.label, errors: errorsSubmit, img: Clock, anchorRef: anchorRefTime, setAnchorRef: setAnchorRefTime }),
                        React.createElement(InputContainer, { className: "row height" },
                            React.createElement(Label, null, FASCIOLI_ASSISTS),
                            React.createElement(RadioGroup, { containerStyle: "radio", onChange: function (option) { return setFascioliAssists(option); } }, options.map(function (option) { return (React.createElement(Radio, { key: option, "aria-checked": "true", value: option, render: function (_a) {
                                    var isSelected = _a.isSelected;
                                    return (React.createElement(Div, { className: "row" },
                                        option,
                                        React.createElement(ImgRadio, { src: isSelected ||
                                                (option == NO && fascioliAssists == NO)
                                                ? SelectedRadioButton
                                                : RadioButton })));
                                } })); }))),
                        React.createElement(SelectComponent, { label: MODALITY, type: MODALITY_KEY, data: modality === null || modality === void 0 ? void 0 : modality.label, errors: errorsSubmit, img: openedArrow, anchorRef: anchorRefModality, setAnchorRef: setAnchorRefModality }),
                        React.createElement(LinkInput, { value: modality.label, linkValue: link, setLink: setLink }))),
                React.createElement(FormContainer, null,
                    React.createElement(OrdersComponent, { data: orders, setSelected: setOrders, errors: errorsSubmit }))),
            React.createElement(BottomInputs, { className: "summons" },
                React.createElement(ButtonsContainer, { className: "inside-form" },
                    React.createElement(AdminButton, { type: "button", className: "height no-background", onClick: function () { return handleSubmit(false); } }, SAVE)),
                React.createElement(ButtonsContainer, { className: "inside-form" },
                    React.createElement(Link, { onClick: onCancel }, CANCEL)),
                backError && React.createElement(ErrorMessage, null, backError))),
        React.createElement(Popover, { data: buildingList, handleOutsideClick: handleOutsideClick, anchorRef: anchorRefBuilding, setSelected: setBuildingData, isFilter: false }),
        React.createElement(Popover, { data: ASSEMBLY_TYPE_DATA, handleOutsideClick: handleOutsideClick, anchorRef: anchorRefAssemblyType, setSelected: setAssemblyTypeData, isFilter: false, className: "form" }),
        React.createElement(Popover, { data: PLACE_DATA, handleOutsideClick: handleOutsideClick, anchorRef: anchorRefPlace, setSelected: setPlaceData, isFilter: false, handleOptionClick: function () { return addInput(placeData.value); }, className: "form" }),
        React.createElement(Popover, { data: TIME_DATA, handleOutsideClick: handleOutsideClick, anchorRef: anchorRefTime, setSelected: setTime, isFilter: false }),
        React.createElement(Popover, { data: MODALITY_DATA, handleOutsideClick: handleOutsideClick, anchorRef: anchorRefModality, setSelected: setModality, isFilter: false, className: "form" })));
};
export default SummonForm;
