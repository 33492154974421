import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { AdminLayout } from 'src/Layouts';
import { RESERVATION_API, getData } from 'src/Utils';
import { Header } from './Header/Header';
import { Information } from './Information/Information';
import { Container } from './styles';
import { useAppDispatch } from '../../redux/hooks';
import { setFormSelected } from '../../redux/slices/tableFiltersSlice';
var ReservationDetails = function () {
    var dispatch = useAppDispatch();
    var id = useParams().id;
    var data = useQuery([RESERVATION_API, id], function () {
        return getData("/bookings/" + id);
    }).data;
    useEffect(function () {
        dispatch(setFormSelected(true));
    }, []);
    return (React.createElement(AdminLayout, null, data && (React.createElement(Container, null,
        React.createElement(Header, { reservation: data }),
        React.createElement(Information, { reservation: data })))));
};
export default ReservationDetails;
