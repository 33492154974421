var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme } from 'src/Utils';
export var DropdownContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 80px;\n  padding: 0 24px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"], ["\n  height: 80px;\n  padding: 0 24px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"])));
export var Image = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 100%;\n  width: 100%;\n"], ["\n  height: 100%;\n  width: 100%;\n"])));
export var Text = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  fons-size: 16px;\n  font-family: ", ";\n"], ["\n  fons-size: 16px;\n  font-family: ", ";\n"])), theme.typography.bold.ralewayBold);
export var Divider = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-bottom: 1px ", " solid;\n  width: 100%;\n"], ["\n  border-bottom: 1px ", " solid;\n  width: 100%;\n"])), theme.palette.grey[10]);
export var ButtonContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 0 24px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n"], ["\n  padding: 0 24px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n"])));
export var ModalContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  min-height: 100vh;\n  min-width: 100vw;\n  height: 100%;\n  opacity: 0.5;\n"], ["\n  width: 100%;\n  min-height: 100vh;\n  min-width: 100vw;\n  height: 100%;\n  opacity: 0.5;\n"])));
export var customStyles = {
    content: {
        padding: '8px 0',
        maxHeight: '70%',
        marginTop: '80px',
        borderRadius: '8px',
        border: 'none',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
