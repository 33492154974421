import React from 'react';
import { generateGoogleMapCircle, LOCATION_X2, theme } from 'src/Utils';
import { MapContainer, Map, Column, Title, Description } from './styles';
export var LocationPDF = function (_a) {
    var description = _a.description, coords = _a.coords;
    var googleMapCircle = {
        color: theme.palette.blue[5],
        lat: coords.lat,
        lng: coords.lng,
        rad: 200,
        zoom: 15,
        size: {
            width: 508,
            height: 202,
        },
    };
    return (React.createElement(Column, null,
        React.createElement(Title, null, LOCATION_X2),
        description && React.createElement(Description, null, description),
        React.createElement(MapContainer, null,
            React.createElement(Map, { src: generateGoogleMapCircle(googleMapCircle) }))));
};
export default LocationPDF;
