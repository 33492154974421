import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getData } from 'src/Utils';
import moment from 'moment';
import { Container, ContentRow, Title, Description, SummonDiv, SummonsContainer, } from './styles';
import { BUILDING, ASSEMBLIES_SUMMONS_TITLE, DATE_FORMAT_MOMENT, SUMMONS_API, BUILDING_API, DATE, EMPTY_SUMMONS, } from 'src/Utils/constants';
var renderInformation = function (header, information) { return (React.createElement(ContentRow, null,
    React.createElement(Description, { className: "size" },
        header,
        ":"),
    React.createElement(Description, { className: "bold" }, information))); };
var AdminCardSummons = function () {
    var _a = useState([]), summons = _a[0], setSummons = _a[1];
    var _b = useState([]), buildingList = _b[0], setBuildingList = _b[1];
    var startDate = moment();
    var endDate = moment().add(30, 'days');
    var _c = useQuery([SUMMONS_API], function () {
        return getData("/summons", {
            start_date: startDate
                ? moment(startDate).format(DATE_FORMAT_MOMENT)
                : null,
            end_date: endDate ? moment(endDate).format(DATE_FORMAT_MOMENT) : null,
        });
    }, { onSuccess: function (data) { return setSummons(data.summons); } }), isLoadingSummons = _c.isLoading, data = _c.data;
    useQuery(BUILDING_API, function () { return getData("/buildings"); }, {
        onSuccess: function (data) {
            var buildings = data.buildings.map(function (_a) {
                var name = _a.name, id = _a.id;
                return ({
                    label: name,
                    value: id,
                });
            });
            buildings.sort(function (a, b) {
                return a.label.localeCompare(b.label, 'es');
            });
            setBuildingList(buildings);
        },
    });
    return (React.createElement(Container, { className: "admin" },
        React.createElement(Title, { className: "admin" }, ASSEMBLIES_SUMMONS_TITLE),
        React.createElement(SummonsContainer, { className: "admin" },
            summons.length === 0 && (React.createElement(Description, { className: "admin" }, EMPTY_SUMMONS)), summons === null || summons === void 0 ? void 0 :
            summons.map(function (summon) {
                var building = buildingList.find(function (building) { return building.value === summon.building_id; });
                return (React.createElement(SummonDiv, null,
                    renderInformation(BUILDING, building === null || building === void 0 ? void 0 : building.label),
                    renderInformation(DATE, moment(summon.set_date).format('DD/MM/YYYY')),
                    renderInformation('Segundo Llamado', summon.time_second_call.slice(0, -3))));
            }))));
};
export default AdminCardSummons;
