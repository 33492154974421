import moment from "moment";
export var timeToMinutes = function (time) {
    var _a = time.split(':').map(Number), hours = _a[0], minutes = _a[1];
    return hours * 60 + minutes;
};
export var getDisabledMinutes = function (hour, bookings) {
    var intervals = [0, 15, 30, 45];
    var disabledMinutes = [];
    bookings.forEach(function (_a) {
        var startTime = _a[0], endTime = _a[1];
        var startMinutes = timeToMinutes(startTime);
        var endMinutes = timeToMinutes(endTime);
        intervals.forEach(function (minute) {
            var currentTimeInMinutes = hour * 60 + minute;
            if (currentTimeInMinutes > startMinutes &&
                currentTimeInMinutes < endMinutes) {
                disabledMinutes.push(minute);
            }
        });
    });
    return disabledMinutes;
};
export var buildDisabledTimes = function (bookings, schedule) {
    var disabledMinutesMemo = {};
    var scheduleTimes = parseSchedule(schedule);
    var _loop_1 = function (hour) {
        var minutes = getDisabledMinutes(hour, bookings);
        if (!scheduleTimes[hour]) {
            disabledMinutesMemo[hour] = [0, 15, 30, 45];
        }
        else {
            var enabledMinutes_1 = scheduleTimes[hour];
            var allMinutes = [0, 15, 30, 45];
            var disabledMinutes = allMinutes.filter(function (min) {
                return !enabledMinutes_1.includes(min) || minutes.includes(min);
            });
            disabledMinutesMemo[hour] = disabledMinutes;
        }
    };
    for (var hour = 0; hour < 24; hour++) {
        _loop_1(hour);
    }
    var disabledHours = Object.entries(disabledMinutesMemo)
        .filter(function (_a) {
        var minutes = _a[1];
        return minutes.length === 4;
    })
        .map(function (_a) {
        var hour = _a[0];
        return parseInt(hour);
    });
    return { disabledMinutesMemo: disabledMinutesMemo, disabledHours: disabledHours };
};
var parseSchedule = function (schedule) {
    var parsedSchedule = {};
    var _a = schedule.split('-'), startTime = _a[0], endTime = _a[1];
    var startMinutes = timeToMinutes(startTime);
    var endMinutes = timeToMinutes(endTime);
    var currentMinutes = startMinutes;
    if (startMinutes > endMinutes)
        endMinutes += 24 * 60; // Add 24 hours to end time if it crosses midnight
    while (currentMinutes <= endMinutes) {
        var hour = Math.floor(currentMinutes / 60) % 24;
        var minute = currentMinutes % 60;
        if (!parsedSchedule[hour]) {
            parsedSchedule[hour] = [];
        }
        parsedSchedule[hour].push(minute);
        currentMinutes += 15;
    }
    return parsedSchedule;
};
export var getFirstAvailableTime = function (disabledMinutes) {
    var firstHour = Object.keys(disabledMinutes).find(function (hour) { return disabledMinutes[hour].length < 4; });
    if (firstHour === undefined)
        return undefined;
    var firstMin = [0, 15, 30, 45].find(function (min) { var _a; return !((_a = disabledMinutes[firstHour]) === null || _a === void 0 ? void 0 : _a.includes(min)); });
    if (firstMin === undefined)
        return undefined;
    return moment(firstHour + ":" + firstMin, 'HH:mm');
};
export var getHoursErrorMessage = function (start_hour, end_hour, disabledMinutes, hoursLimitPerBooking, scheduleLabel) {
    var _a = scheduleLabel.split('-'), scheduleStart = _a[0], scheduleEnd = _a[1];
    var startMinutes = timeToMinutes(start_hour);
    var endMinutes = timeToMinutes(end_hour);
    var scheduleStartMinutes = timeToMinutes(scheduleStart);
    var scheduleEndMinutes = timeToMinutes(scheduleEnd);
    var crossesMidnight = scheduleStartMinutes > scheduleEndMinutes;
    if ((!crossesMidnight &&
        (startMinutes < scheduleStartMinutes || endMinutes > scheduleEndMinutes)) ||
        (crossesMidnight &&
            ((startMinutes < scheduleStartMinutes &&
                startMinutes > scheduleEndMinutes) ||
                (endMinutes < scheduleStartMinutes && endMinutes > scheduleEndMinutes)))) {
        return 'La reserva está fuera del horario permitido.';
    }
    if (!crossesMidnight && startMinutes > endMinutes) {
        return 'La hora de inicio no puede ser mayor que la hora de fin.';
    }
    var durationInMinutes = startMinutes > endMinutes
        ? 24 * 60 - startMinutes + endMinutes
        : endMinutes - startMinutes;
    if (durationInMinutes < 60) {
        return 'La reserva debe tener mínimo 1 hora de duración.';
    }
    var hoursNotAvailable = checkAvailability(startMinutes, endMinutes, disabledMinutes);
    if (hoursNotAvailable) {
        return 'La reserva contiene horas no disponibles.';
    }
    if (hoursLimitPerBooking >= 0 && durationInMinutes > hoursLimitPerBooking * 60) {
        return "La reserva supera el l\u00EDmite de tiempo disponible.";
    }
};
var checkAvailability = function (startMinutes, endMinutes, disabledMinutes) {
    for (var currentMinutes = startMinutes; currentMinutes < endMinutes; currentMinutes += 15) {
        var currentHour = Math.floor(currentMinutes / 60);
        var currentMinute = currentMinutes % 60;
        var disabledMinutesForHour = disabledMinutes[currentHour] || [];
        if (disabledMinutesForHour.includes(currentMinute)) {
            return true;
        }
    }
    return false;
};
