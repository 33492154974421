var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { useWindowWidth } from 'src/useWindowWidth';
import Contact from '../Contact';
import { Header } from 'src/Layouts';
import Footer from '../Footer';
import styled from '@emotion/styled';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px;\n  margin-top: 100px;\n"], ["\n  padding: 40px;\n  margin-top: 100px;\n"])));
var PrivacyPolicy = function () {
    var windowWidth = useWindowWidth();
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, null),
        React.createElement(Container, null,
            React.createElement("h1", null, "Pol\u00EDtica de Privacidad"),
            React.createElement("p", null, "Fascioli Administraciones S.A.S. cre\u00F3 la aplicaci\u00F3n \u201CFascioli APP\u201D . Este SERVICIO es proporcionado por Fascioli Administraciones S.A.S. para sus clientes sin costo alguno y est\u00E1 destinado a ser utilizado tal cual."),
            React.createElement("p", null, "Esta p\u00E1gina se utiliza para informar a los usuarios del software sobre nuestras pol\u00EDticas con respecto a la recopilaci\u00F3n, el uso y la divulgaci\u00F3n de informaci\u00F3n personal solicitada."),
            React.createElement("p", null, "Si elige utilizar nuestro software, entonces acepta la recopilaci\u00F3n y el uso de informaci\u00F3n en relaci\u00F3n con esta pol\u00EDtica. La informaci\u00F3n personal que nosotros recopilamos se utiliza para proporcionar y mejorar el Servicio. Nosotros no utilizaremos ni compartiremos su informaci\u00F3n con nadie excepto como se describe en esta Pol\u00EDtica de Privacidad."),
            React.createElement("h2", null, "Recopilaci\u00F3n y uso de informaci\u00F3n"),
            React.createElement("p", null, "Para un adecuado uso de nuestro software, podremos solicitarle que nos proporcione cierta informaci\u00F3n de identificaci\u00F3n personal, que incluye, entre otros, nombre, apellido, tel\u00E9fono, celular, correo electr\u00F3nico. La informaci\u00F3n que solicitamos ser\u00E1 retenida por nosotros y utilizada como se describe en esta pol\u00EDtica de privacidad."),
            React.createElement("p", null, "La aplicaci\u00F3n utiliza servicios de terceros que pueden recopilar informaci\u00F3n utilizada para identificarlo."),
            React.createElement("h2", null, "Datos de registro"),
            React.createElement("p", null, "Cada vez que utiliza nuestro software recopilamos datos e informaci\u00F3n (a trav\u00E9s de productos de terceros) en su tel\u00E9fono llamado Datos de registro. Estos Datos de registro pueden incluir informaci\u00F3n como la direcci\u00F3n del Protocolo de Internet (\"IP\") de sus dispositivos, el nombre del dispositivo, la versi\u00F3n del sistema operativo, la configuraci\u00F3n de la aplicaci\u00F3n cuando utiliza nuestro software, la hora y la fecha de su uso del Servicio, y otras estad\u00EDsticas."),
            React.createElement("h2", null, "Cookies"),
            React.createElement("p", null, "Las cookies son archivos con una peque\u00F1a cantidad de datos que se utiliza com\u00FAnmente como un identificador \u00FAnico an\u00F3nimo. Estos se env\u00EDan a su navegador desde el sitio web que visita y se almacenan en la memoria interna de sus dispositivos."),
            React.createElement("p", null, "Este servicio no usa estas \"cookies\" expl\u00EDcitamente. Sin embargo, la aplicaci\u00F3n puede usar c\u00F3digo y bibliotecas de terceros que usan \"cookies\" para recopilar informaci\u00F3n y mejorar sus servicios. Tiene la opci\u00F3n de aceptar o rechazar estas cookies y saber cu\u00E1ndo se env\u00EDa una cookie a su dispositivo. Si elige rechazar nuestras cookies, es posible que no pueda utilizar algunas partes de este Servicio."),
            React.createElement("h2", null, "Proveedores de servicio"),
            React.createElement("p", null, "Podemos emplear empresas e individuos de terceros debido a las siguientes razones:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Para facilitar nuestro Servicio;"),
                React.createElement("li", null, "Para proporcionar el software en nuestro nombre;"),
                React.createElement("li", null, "Para realizar servicios relacionados con el software; o"),
                React.createElement("li", null, "Para ayudarnos a analizar c\u00F3mo se utiliza nuestro software.")),
            React.createElement("p", null, "Estos terceros tienen acceso a su Informaci\u00F3n personal. El motivo es realizar las tareas que se les asignan en nuestro nombre. Sin embargo, est\u00E1n obligados a no divulgar ni utilizar la informaci\u00F3n para ning\u00FAn otro fin."),
            React.createElement("h2", null, "Seguridad"),
            React.createElement("p", null, "Valoramos su confianza al proporcionarnos su Informaci\u00F3n personal, por lo que nos esforzamos por utilizar medios comercialmente aceptables para protegerla. Pero recuerde que ning\u00FAn m\u00E9todo de transmisi\u00F3n por Internet, o m\u00E9todo de almacenamiento electr\u00F3nico es 100% seguro y confiable, y nosotros no podemos garantizar su seguridad absoluta."),
            React.createElement("h2", null, "Enlaces a otros sitios"),
            React.createElement("p", null, "Este Servicio puede contener enlaces a otros sitios. Si hace clic en un enlace de un tercero, ser\u00E1 dirigido a ese sitio. Tenga en cuenta que estos sitios externos no son operados por nosotros. Por lo tanto, le recomendamos encarecidamente que revise la Pol\u00EDtica de privacidad de estos sitios web. No tenemos control ni asumimos ninguna responsabilidad por el contenido, las pol\u00EDticas de privacidad o las pr\u00E1cticas de los sitios o servicios de terceros."),
            React.createElement("h2", null, "Privacidad de los ni\u00F1os"),
            React.createElement("p", null, "Este software no est\u00E1 dirigido a personas menores de 18 a\u00F1os. Nosotros no recopilamos a sabiendas informaci\u00F3n personal identificable de ni\u00F1os menores de 18 a\u00F1os. En el caso de que descubramos que un ni\u00F1o menor de 18 a\u00F1os nos ha proporcionado informaci\u00F3n personal, la borramos inmediatamente esto de nuestros servidores. Si usted es un padre o tutor y sabe que su hijo nos ha proporcionado informaci\u00F3n personal, comun\u00EDquese con nosotros para que podamos tomar las medidas necesarias."),
            React.createElement("h2", null, "Cambios a esta Pol\u00EDtica de privacidad"),
            React.createElement("p", null, "Podremos actualizar nuestra Pol\u00EDtica de privacidad de vez en cuando. Por lo tanto, se le recomienda revisar esta p\u00E1gina peri\u00F3dicamente para ver si hay cambios. Le notificaremos de cualquier cambio publicando la nueva Pol\u00EDtica de Privacidad en esta p\u00E1gina. Estos cambios entran en vigencia inmediatamente despu\u00E9s de que se publiquen en esta p\u00E1gina."),
            React.createElement("h2", null, "Cont\u00E1ctenos"),
            React.createElement("p", null, "Si tiene alguna pregunta o sugerencia sobre nuestra Pol\u00EDtica de privacidad, no dude en ponerse en contacto con nosotros."),
            React.createElement("ul", null,
                React.createElement("li", null, "Telefono: (+598) 2613 1183"),
                React.createElement("li", null, "Direcci\u00F3n: Colombes 1399, Montevideo"),
                React.createElement("li", null, "E-mail: fascioli@fascioli.com.uy"))),
        React.createElement(Contact, { isContactPage: false }),
        React.createElement(Footer, null)));
};
export default PrivacyPolicy;
var templateObject_1;
