var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { AdminLayout } from 'src/Layouts';
import { BACK_ERROR, BUILDINGS_KEY, getData, MODIFY_PROVIDER, PROVIDER_API, ROUTES, theme, } from 'src/Utils';
import { Container, Title } from '../styles';
import { submitEditedProviderForm } from './duck';
import { ProviderForm } from 'src/Components/ProviderForm';
var EditProvider = function () {
    var history = useHistory();
    var _a = useState(''), backError = _a[0], setBackError = _a[1];
    var _b = useState(), providerData = _b[0], setProviderData = _b[1];
    var id = useParams().id;
    var mutateProviderForm = useMutation(submitEditedProviderForm, {
        onSuccess: function () {
            setBackError('');
            history.push(ROUTES.providers);
        },
        onError: function (error) {
            var _a;
            setBackError(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.errors) || BACK_ERROR);
        },
    })[0];
    var submitForm = function (name, type_of_service, phone, buildingsArray) {
        return mutateProviderForm({
            name: name,
            type_of_service: type_of_service,
            phone: phone,
            buildingsArray: buildingsArray,
            id: id,
        });
    };
    var _c = useQuery(PROVIDER_API, function () { return getData("/providers/" + id); }, {
        onSuccess: function (data) {
            setProviderData(__assign(__assign({}, data), { buildings: data[BUILDINGS_KEY].map(function (_a) {
                    var building_id = _a.building_id, building_name = _a.building_name;
                    return ({
                        label: building_name,
                        value: building_id,
                    });
                }) }));
        },
        retry: false,
    }), isLoading = _c.isLoading, isSuccess = _c.isSuccess;
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, { className: "non-margin" }, MODIFY_PROVIDER),
            isLoading && (React.createElement(BeatLoader, { size: "8px", color: theme.palette.black[100] })),
            providerData && (React.createElement(ProviderForm, { onSubmit: submitForm, backError: backError, setBackError: setBackError, initialValues: providerData })))));
};
export default EditProvider;
