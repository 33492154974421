var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme } from 'src/Utils';
export var StyledInput = styled.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  all: unset;\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  border-radius: 7px;\n  padding: 10px 16px;\n  box-shadow: 0px 0px 8px 0px rgba(173, 173, 173, 0.25);\n  width: ", ";\n"], ["\n  all: unset;\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  border-radius: 7px;\n  padding: 10px 16px;\n  box-shadow: 0px 0px 8px 0px rgba(173, 173, 173, 0.25);\n  width: ", ";\n"])), theme.palette.grey[50], theme.typography.normal.raleway, function (_a) {
    var width = _a.width;
    return width;
});
var templateObject_1;
