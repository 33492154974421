import React from 'react';
import { BeatLoader } from 'react-spinners';
import moment from 'moment';
import { Container, Title, AlertsContainer, Circle, Line } from './styles';
import Alert from './Alert';
import { ALERTS, theme } from 'src/Utils';
import { Notifications } from 'src/assets/Images';
var AlertsCard = function (_a) {
    var newAlerts = _a.newAlerts, oldAlerts = _a.oldAlerts, isLoading = _a.isLoading;
    var activeAlerts = newAlerts.concat(oldAlerts.filter(function (alert) {
        return alert.expiration_date > moment().format('YYYY-MM-DD');
    }));
    var inactiveAlerts = oldAlerts.filter(function (alert) {
        return alert.expiration_date <= moment().format('YYYY-MM-DD') ||
            !alert.expiration_date;
    });
    return (React.createElement(Container, null,
        React.createElement(Circle, null,
            React.createElement("img", { src: Notifications }),
            React.createElement(Circle, { className: "notifications" }, activeAlerts.length)),
        React.createElement(Title, null, ALERTS),
        isLoading ? (React.createElement(BeatLoader, { size: "5px", color: theme.palette.black[100] })) : (React.createElement(AlertsContainer, null,
            activeAlerts.map(function (_a, index) {
                var message = _a.message, source = _a.source, time_in_words = _a.time_in_words;
                return (React.createElement(Alert, { key: index + message, message: message, source: source, timeAgo: time_in_words, newAlert: true }));
            }),
            React.createElement(Line, null),
            inactiveAlerts.map(function (_a, index) {
                var message = _a.message, source = _a.source, time_in_words = _a.time_in_words;
                return (React.createElement(Alert, { key: index + message, message: message, source: source, timeAgo: time_in_words }));
            })))));
};
export default AlertsCard;
