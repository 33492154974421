import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { AdminLayout } from 'src/Layouts';
import { Container, Title } from '../styles';
import { ADD_PROVIDER, BACK_ERROR, ROUTES } from 'src/Utils';
import { ProviderForm } from 'src/Components/ProviderForm';
import { submitProviderForm } from './duck';
var EditProvider = function () {
    var history = useHistory();
    var _a = useState(''), backError = _a[0], setBackError = _a[1];
    var mutateProviderForm = useMutation(submitProviderForm, {
        onSuccess: function () {
            setBackError('');
            history.push(ROUTES.providers);
        },
        onError: function (error) {
            var _a, _b;
            setBackError(((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.errors) ? Object.values(error.response.data.errors)[0][0]
                : BACK_ERROR);
        },
    })[0];
    var submitForm = function (name, type_of_service, phone, buildingsArray) {
        return mutateProviderForm({
            name: name,
            type_of_service: type_of_service,
            phone: phone,
            buildingsArray: buildingsArray,
        });
    };
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, { className: "non-margin" }, ADD_PROVIDER),
            React.createElement(ProviderForm, { onSubmit: submitForm, backError: backError, setBackError: setBackError }))));
};
export default EditProvider;
