import React from 'react';
import { EMAIL, NAME, PHONE, RELATIONSHIP } from 'src/Utils/constants';
import { Head, Row, Element } from './styles';
export var TableHeader = function () {
    return (React.createElement(Head, null,
        React.createElement(Row, null,
            React.createElement(Element, null, NAME),
            React.createElement(Element, null, EMAIL),
            React.createElement(Element, null, PHONE),
            React.createElement(Element, null, RELATIONSHIP))));
};
export default TableHeader;
