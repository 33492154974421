import React from 'react';
import { Calendar } from 'src/assets/Images';
import { CALENDAR, CALENDAR_TEXT } from 'src/Utils/constants';
import { CandelarContainer, Img } from './styles';
export var CustomInput = React.forwardRef(function (_a, ref) {
    var value = _a.value, placeholder = _a.placeholder, onClick = _a.onClick;
    return (React.createElement(CandelarContainer, null,
        React.createElement("label", { onClick: onClick, ref: ref }, value || placeholder),
        React.createElement(Img, { src: Calendar, alt: CALENDAR, className: CALENDAR_TEXT, onClick: onClick })));
});
