import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reservationsSlice from './slices/reservationsSlice';
import commonExpensesReceiptSlice from './slices/commonExpensesReceiptSlice';
import tableFiltersSlice from './slices/tableFiltersSlice';
var persistConfig = {
    key: 'root',
    storage: storage,
};
var persistedReducer = persistReducer(persistConfig, combineReducers({
    reservationsSlice: reservationsSlice,
    commonExpensesReceiptSlice: commonExpensesReceiptSlice,
    tableFiltersSlice: tableFiltersSlice,
}));
export var store = configureStore({
    reducer: persistedReducer,
});
export var persistor = persistStore(store);
export default store;
