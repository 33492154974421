var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import { components } from 'react-select';
import { useFormContext } from 'react-hook-form';
import { areEqual, customStyles, getFilteredOptions } from './utils';
import useFilter from 'src/context/FilterContext';
import { RealEstateMultiSelect } from 'src/Components/Common/RealEstateMultiSelect';
import { Column, Label, Row } from '../../styles';
export var Select = function (_a) {
    var title = _a.title, options = _a.options, placeholder = _a.placeholder, isMulti = _a.isMulti, img = _a.img, property = _a.property, hasStaticOptions = _a.hasStaticOptions;
    var _b = useState([]), selectedOptions = _b[0], setSelectedOptions = _b[1];
    var _c = useFormContext(), setValue = _c.setValue, getValues = _c.getValues, watch = _c.watch;
    var _d = useState(false), isUserChange = _d[0], setIsUserChange = _d[1];
    var watchedValue = watch(property);
    var filterValues = useFilter();
    var filterDataLoading = filterValues.isLoading;
    var filterData = hasStaticOptions
        ? options
        : filterValues.defaultValues[property] || [];
    useEffect(function () {
        if (getValues().hasOwnProperty(property)) {
            var val = handleValue();
            if (val === undefined)
                return;
            if (isUserChange && !areEqual(val, watchedValue, isMulti)) {
                setValue(property, val);
            }
        }
    }, [selectedOptions]);
    useEffect(function () {
        if (!isUserChange && !filterDataLoading) {
            if (!areEqual(watchedValue, handleValue(), isMulti)) {
                setSelectedOptions(getFilteredOptions(filterData, watchedValue, isMulti));
            }
        }
        else
            setIsUserChange(false);
    }, [watchedValue, filterDataLoading]);
    var handleValue = function () {
        var values;
        if (isMulti)
            values = selectedOptions.map(function (op) { return op.value; });
        else
            values = selectedOptions.value;
        return values;
    };
    var isArr = Array.isArray(selectedOptions);
    var localStateEmpty = isArr
        ? selectedOptions.length === 0
        : selectedOptions.value === '';
    var globalStateEmpty = isMulti
        ? watchedValue.length === 0
        : watchedValue === '';
    var isLoading = localStateEmpty !== globalStateEmpty;
    if (isLoading) {
        return null;
    }
    var handleSelectChange = function (selectedOption) {
        setIsUserChange(true);
        setSelectedOptions(selectedOption);
    };
    var ValueContainer = function (_a) {
        var children = _a.children, props = __rest(_a, ["children"]);
        return (React.createElement(components.ValueContainer, __assign({}, props), children));
    };
    return (React.createElement(Column, { gap: "12px" },
        React.createElement(Row, { className: "align-center", gap: "8px" },
            img && React.createElement("img", { src: img }),
            React.createElement(Label, null, title)),
        React.createElement(RealEstateMultiSelect, { initialValues: selectedOptions, options: filterData, setSelectedOption: handleSelectChange, type: "housing", inputLabel: placeholder, customStyles: customStyles(img), isMulti: isMulti, classNames: "height-auto", valueContainer: ValueContainer })));
};
