import React from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { StyledList, StyledPopover, Label, DateContainer, Div } from './styles';
import { FROM, UNTIL } from 'src/Utils';
import CalendarComponent from '../Calendar';
import { CustomInput } from '../RenderDate';
var DatePopover = function (_a) {
    var anchorRef = _a.anchorRef, handleOutsideClick = _a.handleOutsideClick, setStartDate = _a.setStartDate, setEndDate = _a.setEndDate, startDate = _a.startDate, endDate = _a.endDate, className = _a.className;
    var handleClick = function (item) {
        handleOutsideClick();
    };
    var renderDate = function (isStartDate) { return (React.createElement(DateContainer, null,
        React.createElement(Label, null, (isStartDate ? FROM : UNTIL) + ": "),
        React.createElement(Div, null, isStartDate ? (React.createElement(CalendarComponent, { selected: startDate, onChange: setStartDate, selectsStart: true, startDate: startDate, endDate: endDate, customInput: React.createElement(CustomInput, null) })) : (React.createElement(CalendarComponent, { selected: endDate, onChange: setEndDate, selectsEnd: true, startDate: startDate, endDate: endDate, minDate: startDate, customInput: React.createElement(CustomInput, null) }))))); };
    return (React.createElement(StyledPopover, { className: className, anchorEl: anchorRef, open: Boolean(anchorRef), anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        } },
        React.createElement(ClickAwayListener, { onClickAway: handleOutsideClick },
            React.createElement(StyledList, { className: className },
                renderDate(true),
                renderDate(false)))));
};
export default DatePopover;
