var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme } from 'src/Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  gap: 12px;\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  gap: 12px;\n"])));
export var Row = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  gap: 12px;\n  width: 50%;\n"], ["\n  display: flex;\n  gap: 12px;\n  width: 50%;\n"])));
export var Column = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n\n  &.width {\n    width: 50%;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n\n  &.width {\n    width: 50%;\n  }\n"])));
export var Link = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-style: normal;\n  font-size: 13px;\n  text-decoration-line: underline;\n  font-family: ", ";\n  width: 100%;\n  cursor: pointer;\n  background-color: ", ";\n  color: ", ";\n  margin-top: 12px;\n"], ["\n  font-style: normal;\n  font-size: 13px;\n  text-decoration-line: underline;\n  font-family: ", ";\n  width: 100%;\n  cursor: pointer;\n  background-color: ", ";\n  color: ", ";\n  margin-top: 12px;\n"])), theme.typography.bold.ralewayBold, theme.palette.admin.primary[50], theme.palette.admin.primary[75]);
export var MainRow = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  gap: 12px;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: row;\n  gap: 12px;\n  width: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
