var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, S } from '../../../Utils';
export var FormContainer = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  width: 350px;\n  @media (max-width: ", "px) {\n    width: 80%;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  width: 350px;\n  @media (max-width: ", "px) {\n    width: 80%;\n  }\n"])), S);
export var InputContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 100px;\n  min-height: 100px;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  @media (max-width: ", "px) {\n    width: auto;\n  }\n"], ["\n  height: 100px;\n  min-height: 100px;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  @media (max-width: ", "px) {\n    width: auto;\n  }\n"])), S);
export var Error = styled.label(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: red;\n  align-self: flex-start;\n  font-size: 12px;\n  margin-top: 10px;\n  height: 20px;\n  @media (max-width: ", "px) {\n    align-self: center;\n    align-items: center;\n  }\n"], ["\n  color: red;\n  align-self: flex-start;\n  font-size: 12px;\n  margin-top: 10px;\n  height: 20px;\n  @media (max-width: ", "px) {\n    align-self: center;\n    align-items: center;\n  }\n"])), S);
export var TopInputs = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  justify-content: space-between;\n  display: flex;\n  flex-direction: column;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n  }\n"], ["\n  justify-content: space-between;\n  display: flex;\n  flex-direction: column;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n  }\n"])), S);
export var Label = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 18px;\n  margin-bottom: 5px;\n  color: ", ";\n  @media (max-width: ", "px) {\n    font-size: 14px;\n  }\n"], ["\n  font-size: 18px;\n  margin-bottom: 5px;\n  color: ", ";\n  @media (max-width: ", "px) {\n    font-size: 14px;\n  }\n"])), theme.palette.white[100], S);
export var Link = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-top: 10px;\n  font-size: 11px;\n  cursor: pointer;\n  text-decoration-line: underline;\n  color: ", ";\n  &.center {\n    widt: 100%;\n    margin-top: -10px;\n    text-align: center;\n  }\n"], ["\n  margin-top: 10px;\n  font-size: 11px;\n  cursor: pointer;\n  text-decoration-line: underline;\n  color: ", ";\n  &.center {\n    widt: 100%;\n    margin-top: -10px;\n    text-align: center;\n  }\n"])), theme.palette.white[100]);
export var Div = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  &.center {\n    justify-content: center;\n    align-items: baseline;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  &.center {\n    justify-content: center;\n    align-items: baseline;\n  }\n"])));
export var BottomInputs = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  margin-top: 30px;\n  height: 100px;\n  flex-direction: column;\n  width: 100%;\n  justify-content: flex-start;\n  @media (max-width: ", "px) {\n    margin-top: 40px;\n    align-items: center;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  margin-top: 30px;\n  height: 100px;\n  flex-direction: column;\n  width: 100%;\n  justify-content: flex-start;\n  @media (max-width: ", "px) {\n    margin-top: 40px;\n    align-items: center;\n  }\n"])), S);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
