export var defaultValues = {
    email: '',
    phone: '',
    name: '',
    query: "\u00A1Hola! Quiero que se comuniquen conmigo por esta propiedad.",
};
export var inputs = [
    {
        type: 'text',
        id: 'name',
        label: 'Nombre',
        placeholder: 'Ingresá tu nombre',
    },
    {
        type: 'tel',
        id: 'phone',
        label: 'Teléfono',
        placeholder: 'Ingresá tu teléfono',
    },
    {
        type: 'email',
        id: 'email',
        label: 'Email',
        placeholder: 'Ingresá tu email',
        options: {
            required: true,
            errorMessage: '*Email inválido',
            emptyMessage: '*Ingrese su email',
        },
    },
    {
        type: 'text',
        id: 'query',
        label: 'Consulta',
        placeholder: '¡Hola! Quiero que se comuniquen conmigo por esta propiedad.',
    },
];
export var handleCurrency = function (currency) {
    return currency === 'UYU' ? '$' : 'U$S';
};
export var handlePropertyType = function (propertyType) {
    switch (propertyType) {
        case 'house':
            return 'Casa';
        case 'apartment':
            return 'Apartamento';
        case 'land':
            return 'Terreno';
        case 'office':
            return 'Oficina';
        case 'deposit':
            return 'Depósito';
        case 'retail':
            return 'Local comercial';
        default:
            return 'Terreno';
    }
};
export var handleOperationType = function (operationType) {
    return operationType === 'rent' ? 'alquiler' : 'venta';
};
