var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { StyledInput } from './styles';
export var Input = function (_a) {
    var placeholder = _a.placeholder, _b = _a.width, width = _b === void 0 ? 'auto' : _b, _c = _a.type, type = _c === void 0 ? 'text' : _c, property = _a.property, inputValues = _a.inputValues, setInputValues = _a.setInputValues, _d = _a.setOnBlur, setOnBlur = _d === void 0 ? true : _d, _e = _a.isPrice, isPrice = _e === void 0 ? false : _e;
    var _f = useFormContext(), setValue = _f.setValue, watch = _f.watch;
    var inputValue = inputValues[property];
    var addDots = function (num) { return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'); };
    var removeNonNumeric = function (num) { return num.toString().replace(/[^0-9]/g, ''); };
    var setValueInput = function (value) {
        var _a;
        if (isPrice)
            value = addDots(removeNonNumeric(value));
        setInputValues(__assign(__assign({}, inputValues), (_a = {}, _a[property] = value, _a)));
    };
    var watchedFields = watch();
    var watchedValue = watchedFields[property];
    useEffect(function () {
        if (watchedValue !== inputValue) {
            setInputValues(function (prev) {
                var _a;
                return (__assign(__assign({}, prev), (_a = {}, _a[property] = watchedValue, _a)));
            });
        }
    }, [watchedValue]);
    return (React.createElement(StyledInput, { value: inputValue.toUpperCase(), placeholder: placeholder, width: width, type: type, onChange: function (e) { return setValueInput(e.target.value); }, onBlur: function () { return setOnBlur && setValue(property, inputValue); } }));
};
