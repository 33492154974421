import React from 'react';
import { Container, Content, Title, DescriptionContainer, ImageContainer, Img, } from './styles';
import { M } from 'src/Utils/constants';
import { useWindowWidth } from 'src/useWindowWidth';
import { HeroBackground, HeroImg } from 'src/assets/Images';
import { LinkRow, StyledLink } from '../Button/styles';
var Hero = function () {
    var windowWidth = useWindowWidth();
    return (React.createElement(Container, { style: windowWidth <= M ? { backgroundImage: "url(" + HeroBackground + ")" } : {} },
        React.createElement(Content, null,
            React.createElement(Title, { className: "maintenance" }, "Nuestra p\u00E1gina est\u00E1 en mantenimiento."),
            React.createElement(DescriptionContainer, { className: "maintenance" }, "Por el momento, puede mirar nuestras propiedades en:"),
            React.createElement(LinkRow, null,
                React.createElement(StyledLink, { target: "_blank", href: "https://listado.mercadolibre.com.uy/inmuebles/_CustId_394817518" }, "Mercado Libre"),
                React.createElement(StyledLink, { target: "_blank", href: "https://www.infocasas.com.uy/inmobiliarias/416819-fascioli-administraciones/propiedades" }, "InfoCasas"))),
        windowWidth > M && (React.createElement(ImageContainer, null,
            React.createElement(Img, { src: HeroImg })))));
};
export default Hero;
