import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import moment from 'moment';
import { AdminButton } from 'src/Components/Common/AdminButton';
import { BackIcon } from 'src/assets/Images';
import { CANCEL_RESERVATION, DATE_FORMAT_MOMENT, RESERVATION_DETAILS, ROUTES, } from '../../../Utils';
import { cancelReservation } from '../services';
import { Title, StyledArrow, TitleContainer, TopContainer } from './styles';
import Modal from '../Modal/Modal';
export var Header = function (_a) {
    var reservation = _a.reservation;
    var history = useHistory();
    var id = useParams().id;
    var _b = useState(false), isModalOpen = _b[0], setIsModalOpen = _b[1];
    var goToReservations = function () { return history.push(ROUTES.reservations); };
    var mutateCancelReservation = useMutation(cancelReservation, {
        onSuccess: goToReservations,
    })[0];
    var isCancellable = reservation.status === 'active';
    return (React.createElement(TopContainer, null,
        React.createElement(TitleContainer, { onClick: goToReservations },
            React.createElement(StyledArrow, { src: BackIcon }),
            React.createElement(Title, { className: "go-back" }, RESERVATION_DETAILS)),
        isCancellable && (React.createElement(AdminButton, { type: "button", onClick: function () { return setIsModalOpen(true); }, className: "no-background no-margin padding-h" }, CANCEL_RESERVATION)),
        React.createElement(Modal, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, handleCancel: function () { return mutateCancelReservation(id); }, description: reservation.amenity_name + ": " + moment(reservation.day).format(DATE_FORMAT_MOMENT) + ", " + reservation.schedule })));
};
