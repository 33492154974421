import React, { useState } from 'react';
import { useWindowWidth } from 'src/useWindowWidth';
import { M } from 'src/Utils';
import { Input } from './Input';
import { Currency } from './Currency';
import { Column, BoxContainer, Title, Label, Wrapper } from '../styles';
export var InputFilter = function (_a) {
    var filter = _a.filter, isPrice = _a.isPrice;
    var filters = filter.filters, title = filter.title;
    var windowWidth = useWindowWidth();
    var isMobile = windowWidth < M;
    var initial = isPrice ? { price_start: '', price_end: '' } : { code: '' };
    var _b = useState(initial), inputValues = _b[0], setInputValues = _b[1];
    return (React.createElement(BoxContainer, null,
        !isMobile && React.createElement(Title, null, title),
        isPrice && React.createElement(Currency, { inputValues: inputValues }),
        React.createElement(Wrapper, { gap: "16px", className: isPrice ? '' : 'column' }, filters.map(function (_a) {
            var title = _a.title, placeholder = _a.placeholder, property = _a.property;
            return (React.createElement(Column, { gap: "12px", key: property },
                React.createElement(Label, null, title),
                React.createElement(Input, { placeholder: placeholder, width: isPrice ? '64px' : 'auto', type: "text", property: property, inputValues: inputValues, setInputValues: setInputValues, setOnBlur: !isPrice, isPrice: isPrice })));
        }))));
};
