import React from 'react';
import { US, HOME, LOGIN_TEXT, LOGIN, US_TEXT, HOME_TEXT, REAL_ESTATE_DEPARTMENT, REAL_ESTATE_DEPARTMENT_TEXT } from '../../Utils';
import { HeaderComponent } from '../../Components';
var items = [
    { text: HOME, key: HOME_TEXT },
    { text: US, key: US_TEXT },
    { text: LOGIN, key: LOGIN_TEXT },
    { text: REAL_ESTATE_DEPARTMENT, key: REAL_ESTATE_DEPARTMENT_TEXT },
];
var Header = function () { return React.createElement(HeaderComponent, { items: items }); };
export default Header;
