import React from 'react';
import { BuildingReservation } from 'src/assets/Images';
import { APARTMENT, BUILDING, DESCRIPTION, SPACE } from 'src/Utils';
import { Container, Row, Column, Value, Label, LabelDesc, ValueDesc, } from './styles';
export var BuildingCard = function (_a) {
    var values = _a.values;
    return (React.createElement(Container, null,
        React.createElement(Row, { className: "align-center", gap: "12px" },
            React.createElement("img", { src: BuildingReservation }),
            React.createElement(Column, null,
                React.createElement(Row, { gap: "6px" },
                    React.createElement(Label, null, BUILDING),
                    React.createElement(Value, null, values.building)),
                React.createElement(Row, { gap: "6px" },
                    React.createElement(Label, null, APARTMENT),
                    React.createElement(Value, null, values.apartment)),
                React.createElement(Row, { gap: "6px" },
                    React.createElement(Label, null, SPACE),
                    React.createElement(Value, null, values.amenity)))),
        React.createElement("div", null,
            React.createElement(LabelDesc, null,
                DESCRIPTION,
                ":"),
            React.createElement(ValueDesc, null, values.description))));
};
