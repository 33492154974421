var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from 'react';
import { BeatLoader } from 'react-spinners';
import { Button, InputComponent } from '../Common';
import { Container, Title, FormContainer, ContainerInner, Link, ButtonContainer, CheckBoxContainer, Span, } from './styles';
import { DIRECTION, NAME, SURNAME, EMAIL_KEY, PHONE, ACCEPT_TERMS, CREATE, CLIENT_CODE, TERMS_CONDITIONS, DIRECTION_KEY, CLIENT_CODE_KEY, EMAIL, PHONE_KEY, SURNAME_KEY, NAME_KEY, theme, ROUTES, } from 'src/Utils';
import CheckBox from '../Common/CheckBox';
var inputs = [
    { key: NAME_KEY, name: NAME, width: '45%' },
    { key: SURNAME_KEY, name: SURNAME, width: '45%' },
    { key: PHONE_KEY, name: PHONE, width: '45%' },
    { key: EMAIL_KEY, name: EMAIL, width: '45%' },
    { key: DIRECTION_KEY, name: DIRECTION, width: '100%' },
];
var CreateAccountForm = function (_a) {
    var title = _a.title, clientCodes = _a.clientCodes, setClientCodes = _a.setClientCodes, checkboxError = _a.checkboxError, setCheckboxError = _a.setCheckboxError, selected = _a.selected, setSelected = _a.setSelected, error = _a.error, backError = _a.backError, data = _a.data, setData = _a.setData, onSubmit = _a.onSubmit, isLoading = _a.isLoading;
    var typedData = data;
    var addClientCode = function () {
        setClientCodes(function (prevState) { return __spreadArrays(prevState, [
            { value: '', id: new Date().toString() },
        ]); });
    };
    return (React.createElement(Container, null,
        React.createElement(ContainerInner, null,
            React.createElement(Title, null, title),
            React.createElement(FormContainer, null,
                inputs.map(function (_a) {
                    var key = _a.key, name = _a.name, width = _a.width;
                    return (React.createElement(InputComponent, { key: key, label: name + "*", type: key, data: typedData[key], onChangeFunction: function (e) {
                            e.persist();
                            setData(function (prevState) {
                                var _a;
                                return (__assign(__assign({}, prevState), (_a = {}, _a[key] = e.target.value, _a)));
                            });
                        }, error: error, width: width, className: "white" }));
                }), clientCodes === null || clientCodes === void 0 ? void 0 :
                clientCodes.map(function (_a, index) {
                    var value = _a.value, id = _a.id;
                    return (React.createElement(InputComponent, { label: !index ? CLIENT_CODE + "*" : '', type: CLIENT_CODE_KEY, data: value, key: id + index, onChangeFunction: function (e) {
                            e.persist();
                            setClientCodes(clientCodes.map(function (code, i) {
                                return index === i
                                    ? __assign(__assign({}, code), { value: e.target.value.toUpperCase() }) : code;
                            }));
                        }, width: "100%", error: !index ? error : '', className: "white code " + (index != 0 ? 'new-code' : 'client-code'), addClientCode: !index ? addClientCode : undefined }));
                })),
            React.createElement(ButtonContainer, null,
                React.createElement(CheckBoxContainer, null,
                    React.createElement(CheckBox, { error: checkboxError, selected: selected, onClick: function () {
                            setSelected(!selected);
                            setCheckboxError(false);
                        } }),
                    React.createElement(Span, null, ACCEPT_TERMS),
                    React.createElement(Link, { href: ROUTES.privacy, target: "_blank", rel: "noopener noreferrer" }, TERMS_CONDITIONS)),
                React.createElement(Button, { onClick: onSubmit }, isLoading ? (React.createElement(BeatLoader, { size: "5px", color: theme.palette.white[100] })) : (CREATE)),
                backError && React.createElement(Span, { className: "error" }, backError)))));
};
export default CreateAccountForm;
