var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CreateReservation from '../CreateReservation';
export var defaultValues = {
    clientCode: '',
    date: new Date(),
    time: {
        label: '',
        value: 0,
        price: 0,
        occupiedTimes: [],
        blocksAmenity: false,
        pricePerUse: true,
    },
    amenity: {
        label: '',
        value: 0,
        hoursLimitPerBooking: -1,
    },
    extras: [],
    selectedExtras: [],
    startHour: '',
    endHour: '',
    hasInitialSchedule: false,
};
var CreateReservationPage = function () {
    var methods = useForm({ defaultValues: defaultValues });
    var register = methods.register;
    useEffect(function () {
        Object.keys(defaultValues).forEach(function (field) { return register(field); });
    }, [register]);
    return (React.createElement(FormProvider, __assign({}, methods),
        React.createElement(CreateReservation, null)));
};
export default CreateReservationPage;
