var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { BeatLoader } from 'react-spinners';
import { CONFIRM_PASSWORD_KEY, NEW_PASSWORD_KEY, theme } from 'src/Utils';
import { Button, InputComponent } from '../Common';
import { BottomInputs, Container, FormContainer, Label, Title, TopInputs, Error, } from './styles';
var ResetPassword = function (_a) {
    var inputs = _a.inputs, onSubmit = _a.onSubmit, buttonLabel = _a.buttonLabel, _b = _a.header, title = _b.title, description = _b.description, backError = _a.backError, isLoading = _a.isLoading, formData = _a.formData, setFormData = _a.setFormData, inputError = _a.inputError;
    return (React.createElement(Container, null,
        React.createElement(Title, null, title),
        React.createElement(Label, null, description),
        React.createElement(FormContainer, null,
            React.createElement(TopInputs, null, inputs.map(function (_a) {
                var key = _a.key, name = _a.name;
                return (React.createElement(InputComponent, { key: key, type: key, data: formData[key], error: inputError, onChangeFunction: function (e) {
                        e.persist();
                        setFormData(function (prevState) {
                            var _a;
                            return (__assign(__assign({}, prevState), (_a = {}, _a[key] = e.target.value, _a)));
                        });
                    }, label: name, placeHolder: key === 'email' ? 'user@gmail.com' : '', className: "white", isPassword: key === NEW_PASSWORD_KEY || key === CONFIRM_PASSWORD_KEY }));
            })),
            React.createElement(BottomInputs, null,
                React.createElement(Button, { className: "full-width", type: "submit", onClick: onSubmit }, isLoading ? (React.createElement(BeatLoader, { size: "5px", color: theme.palette.white[100] })) : (buttonLabel)),
                React.createElement(Error, null, backError)))));
};
export default ResetPassword;
