var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/Utils';
import { defaultValues } from './utils';
var useResetFilters = function () {
    var history = useHistory();
    var _a = useFormContext(), watch = _a.watch, reset = _a.reset;
    var formValues = watch();
    var comparableItem = __assign(__assign({}, formValues), { sort: 'recently', operation_type: 'rent', currency: 'UYU' });
    var resetIsActive = JSON.stringify(comparableItem) !== JSON.stringify(defaultValues);
    var newValues = __assign(__assign({}, defaultValues), { sort: 'recently', operation_type: formValues.operation_type, currency: formValues.currency });
    var resetFilters = function () {
        if (resetIsActive) {
            history.push(ROUTES.real_estate_properties);
            reset(newValues);
        }
    };
    return { resetFilters: resetFilters, resetIsActive: resetIsActive };
};
export default useResetFilters;
