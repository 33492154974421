import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
var useBookingInitialValues = function (_a) {
    var data = _a.data, amenities = _a.amenities, schedules = _a.schedules;
    var setValue = useFormContext().setValue;
    useEffect(function () {
        if (amenities.length && data) {
            var amenityToEdit = amenities.find(function (_a) {
                var label = _a.label;
                return label === (data === null || data === void 0 ? void 0 : data.amenity_name);
            });
            amenityToEdit && setValue('amenity', amenityToEdit);
            var dateArray = data === null || data === void 0 ? void 0 : data.day.split('-');
            dateArray &&
                setValue('date', new Date(parseInt(dateArray[0]), parseInt(dateArray[1]) - 1, parseInt(dateArray[2])));
        }
    }, [amenities]);
    useEffect(function () {
        if (schedules.length && data) {
            var scheduleToEdit = schedules.find(function (_a) {
                var value = _a.value;
                return value === data.schedule_id;
            });
            if (scheduleToEdit) {
                setValue('time', scheduleToEdit);
                var startHour = data === null || data === void 0 ? void 0 : data.schedule.split('-')[0];
                var endHour = data === null || data === void 0 ? void 0 : data.schedule.split('-')[1];
                setValue('startHour', startHour);
                setValue('endHour', endHour);
                setValue('hasInitialSchedule', true);
            }
        }
    }, [schedules]);
};
export default useBookingInitialValues;
