import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import { LogoPdf } from 'src/assets/Images';
import styles from './styles';
export var Header = function (_a) {
    var title = _a.title;
    return (React.createElement(View, { style: styles.header },
        React.createElement(Text, null, title),
        React.createElement(Image, { src: LogoPdf, style: styles.logo })));
};
export default Header;
