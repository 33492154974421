var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M } from '../../Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-sizing: border-box;\n  display: flex;\n  min-height: 100vh;\n  height: 100%;\n  justify-content: flex-start;\n  padding: 5%;\n  flex-direction: column;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    padding: 25px;\n  }\n"], ["\n  box-sizing: border-box;\n  display: flex;\n  min-height: 100vh;\n  height: 100%;\n  justify-content: flex-start;\n  padding: 5%;\n  flex-direction: column;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    padding: 25px;\n  }\n"])), theme.palette.admin.primary[50], M);
export var Card = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n  align-items: center;\n  max-width: 462px;\n  width: 100%;\n  height: 544px;\n  padding: 48px 0;\n  border-radius: 7px;\n  background-color: #eafff3;\n  border: 1px solid #92dc9a;\n\n  &.error {\n    padding-top: 96px;\n    justify-content: start;\n    background: rgba(227, 115, 115, 0.2);\n    border: 1px solid #e37373;\n  }\n"], ["\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n  align-items: center;\n  max-width: 462px;\n  width: 100%;\n  height: 544px;\n  padding: 48px 0;\n  border-radius: 7px;\n  background-color: #eafff3;\n  border: 1px solid #92dc9a;\n\n  &.error {\n    padding-top: 96px;\n    justify-content: start;\n    background: rgba(227, 115, 115, 0.2);\n    border: 1px solid #e37373;\n  }\n"])));
export var Img = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  box-sizing: border-box;\n  width: 73px;\n  height: 73px;\n  padding: 7px;\n  border: 3px solid #92dc9a;\n  border-radius: 50%;\n\n  &.error {\n    border: 3px solid #e37373;\n    margin-bottom: 28px;\n    padding: 16px;\n  }\n"], ["\n  box-sizing: border-box;\n  width: 73px;\n  height: 73px;\n  padding: 7px;\n  border: 3px solid #92dc9a;\n  border-radius: 50%;\n\n  &.error {\n    border: 3px solid #e37373;\n    margin-bottom: 28px;\n    padding: 16px;\n  }\n"])));
export var Information = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n  font-size: 14px;\n\n  &.title {\n    font-size: 24px;\n    font-family: ", ";\n  }\n\n  &.bold {\n    font-family: ", ";\n  }\n\n  &.row {\n    flex-direction: row;\n    gap: 6px;\n  }\n\n  &.error {\n    margin-top: 7px;\n    margin-bottom: 20px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n  font-size: 14px;\n\n  &.title {\n    font-size: 24px;\n    font-family: ", ";\n  }\n\n  &.bold {\n    font-family: ", ";\n  }\n\n  &.row {\n    flex-direction: row;\n    gap: 6px;\n  }\n\n  &.error {\n    margin-top: 7px;\n    margin-bottom: 20px;\n  }\n"])), theme.typography.bold.ralewayBold, theme.typography.bold.ralewayBold);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
