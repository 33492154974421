var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { openedArrow } from 'src/assets/Images';
import { ADDRESS, ADDRESS_KEY, CANCEL, CREATE, EMAIL, EMAIL_KEY, LAST_NAME, LAST_NAME_KEY, NAME, NAME_KEY, PHONE, PHONE_KEY, REQUIRED, ROUTES, TYPE_KEY, TYPE_TEXT, } from 'src/Utils';
import { ADMIN_TYPE_DATA } from 'src/Utils/mockData';
import { isValidForm } from 'src/Utils/validations';
import { InputComponent, SelectComponent, Popover } from '../Common';
import { AdminButton } from '../Common/AdminButton';
import { FormsContainer, FormContainer, ButtonsContainer, Link, ErrorMessage, BottomContainer, } from './styles';
var AdminForm = function (_a) {
    var onSubmit = _a.onSubmit, backError = _a.backError;
    var history = useHistory();
    var _b = useState({}), formErrors = _b[0], setFormErrors = _b[1];
    var _c = useState(ADMIN_TYPE_DATA[0]), selectedType = _c[0], setSelectedType = _c[1];
    var _d = useState({
        name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
    }), formData = _d[0], setFormData = _d[1];
    var _e = useState(null), anchorRefAdminType = _e[0], setAnchorRefAdminType = _e[1];
    var handleSubmit = function () {
        formErrors[NAME_KEY] = formData.name;
        formErrors[LAST_NAME_KEY] = formData.last_name;
        formErrors[EMAIL_KEY] = formData.email;
        formErrors[PHONE_KEY] = formData.phone;
        formErrors[ADDRESS_KEY] = formData.address;
        if (isValidForm(formErrors, setFormErrors)) {
            onSubmit(formData.email, formData.address, formData.name, formData.last_name, formData.phone, selectedType.value);
        }
    };
    var renderPopOver = function (data, anchorRef, type) { return (React.createElement(Popover, { data: data, handleOutsideClick: function () { return setAnchorRefAdminType(null); }, anchorRef: anchorRef, setSelected: function (item) { return setSelectedType(item); }, isFilter: false, type: type, className: "form" })); };
    var onCancel = function () { return history.push(ROUTES.admins); };
    return (React.createElement(FormsContainer, null,
        React.createElement(FormContainer, null,
            React.createElement(InputComponent, { type: NAME_KEY, error: formErrors, label: NAME + ' ' + REQUIRED, onChangeFunction: function (e) {
                    e.persist();
                    setFormData(function (prevState) {
                        var _a;
                        return (__assign(__assign({}, prevState), (_a = {}, _a[NAME_KEY] = e.target.value, _a)));
                    });
                }, data: formData[NAME_KEY] }),
            React.createElement(InputComponent, { type: LAST_NAME_KEY, error: formErrors, label: LAST_NAME + ' ' + REQUIRED, onChangeFunction: function (e) {
                    e.persist();
                    setFormData(function (prevState) {
                        var _a;
                        return (__assign(__assign({}, prevState), (_a = {}, _a[LAST_NAME_KEY] = e.target.value, _a)));
                    });
                }, data: formData[LAST_NAME_KEY] }),
            React.createElement(InputComponent, { type: EMAIL_KEY, error: formErrors, label: EMAIL + ' ' + REQUIRED, onChangeFunction: function (e) {
                    e.persist();
                    setFormData(function (prevState) {
                        var _a;
                        return (__assign(__assign({}, prevState), (_a = {}, _a[EMAIL_KEY] = e.target.value, _a)));
                    });
                }, data: formData[EMAIL_KEY] }),
            React.createElement(InputComponent, { type: PHONE_KEY, error: formErrors, label: PHONE + ' ' + REQUIRED, onChangeFunction: function (e) {
                    e.persist();
                    setFormData(function (prevState) {
                        var _a;
                        return (__assign(__assign({}, prevState), (_a = {}, _a[PHONE_KEY] = e.target.value, _a)));
                    });
                }, data: formData[PHONE_KEY] }),
            React.createElement(InputComponent, { type: ADDRESS_KEY, error: formErrors, label: ADDRESS + ' ' + REQUIRED, onChangeFunction: function (e) {
                    e.persist();
                    setFormData(function (prevState) {
                        var _a;
                        return (__assign(__assign({}, prevState), (_a = {}, _a[ADDRESS_KEY] = e.target.value, _a)));
                    });
                }, data: formData[ADDRESS_KEY] }),
            React.createElement(SelectComponent, { label: TYPE_TEXT + ' ' + REQUIRED, type: TYPE_KEY, errors: formErrors, data: selectedType.label, img: openedArrow, anchorRef: anchorRefAdminType, setAnchorRef: setAnchorRefAdminType }),
            React.createElement(BottomContainer, null,
                React.createElement(ButtonsContainer, null,
                    React.createElement(Link, { onClick: onCancel }, CANCEL),
                    React.createElement(AdminButton, { type: "button", onClick: handleSubmit, className: "height" }, CREATE)),
                backError && React.createElement(ErrorMessage, null, backError))),
        renderPopOver(ADMIN_TYPE_DATA, anchorRefAdminType, TYPE_KEY)));
};
export default AdminForm;
