import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, DotsImg } from '../../Common/Table/TableRow/styles';
import { Cell } from './style';
import { Dots } from 'src/assets/Images';
import { Popover } from 'src/Components/Common';
import { MODIFY } from 'src/Utils';
import { DELETE } from 'src/Utils/constants';
var ProviderRow = function (_a) {
    var data = _a.data, widths = _a.widths, setProviderData = _a.setProviderData, handleDelete = _a.handleDelete, historyText = _a.historyText;
    var history = useHistory();
    var _b = useState(null), anchorRef = _b[0], setAnchorRef = _b[1];
    var _c = useState({
        label: '',
        value: '',
    }), selectedOption = _c[0], setSelectedOption = _c[1];
    var first = data.first, second = data.second, third = data.third, fourth = data.fourth, id = data.id;
    var handleClick = function (e) {
        return anchorRef ? setAnchorRef(null) : setAnchorRef(e.currentTarget);
    };
    useEffect(function () {
        switch (selectedOption.label) {
            case DELETE:
                setProviderData && setProviderData(data);
                handleDelete && handleDelete();
                break;
            case MODIFY:
                historyText && history.push(historyText + "/" + id);
                break;
        }
    }, [selectedOption]);
    return (React.createElement(Row, { hover: true, tabIndex: -1, key: id },
        React.createElement(Cell, { style: { width: widths[0] } }, first),
        React.createElement(Cell, { style: { width: widths[1] } }, second),
        React.createElement(Cell, { style: { width: widths[2] } }, third),
        React.createElement(Cell, { style: { width: widths[3] } }, fourth),
        React.createElement(Cell, { style: { width: widths[4] } },
            React.createElement(DotsImg, { src: Dots, alt: "M\u00E1s", onClick: handleClick })),
        React.createElement(Popover, { data: [
                { label: MODIFY, value: MODIFY },
                { label: DELETE, value: DELETE },
            ], handleOutsideClick: function () { return setAnchorRef(null); }, anchorRef: anchorRef, setSelected: setSelectedOption, className: "filter width", isFilter: false })));
};
export default ProviderRow;
