var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme } from '../../../Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  background-color: ", ";\n  align-items: center;\n"], ["\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  background-color: ", ";\n  align-items: center;\n"])), theme.palette.admin.primary[50]);
export var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: ", ";\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  height: 26px;\n  font-weight: 600;\n  font-size: 11px;\n  padding-left: 28px;\n  align-self: baseline;\n  width: auto;\n"], ["\n  font-family: ", ";\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  height: 26px;\n  font-weight: 600;\n  font-size: 11px;\n  padding-left: 28px;\n  align-self: baseline;\n  width: auto;\n"])), theme.typography.normal.raleway, theme.palette.admin.primary[45]);
export var Div = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  &.color {\n    background-color: ", ";\n  }\n"], ["\n  width: 100%;\n  &.color {\n    background-color: ", ";\n  }\n"])), theme.palette.admin.primary[45]);
export var ContentDiv = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  padding-left: 28px;\n  align-self: baseline;\n  width: auto;\n  display: flex;\n  align-items: center;\n  height: 52px;\n"], ["\n  display: flex;\n  padding-left: 28px;\n  align-self: baseline;\n  width: auto;\n  display: flex;\n  align-items: center;\n  height: 52px;\n"])));
export var Line = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  width: 100%;\n"], ["\n  border: 1px solid ", ";\n  width: 100%;\n"])), theme.palette.grey[20]);
export var Image = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 45%;\n  height: 20px;\n  &.description {\n    width: auto;\n    margin-right: 5px;\n    height: 16px;\n  }\n"], ["\n  width: 45%;\n  height: 20px;\n  &.description {\n    width: auto;\n    margin-right: 5px;\n    height: 16px;\n  }\n"])));
export var Description = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  text-align: initial;\n  font-size: 14px;\n  &.bold {\n    font-family: ", ";\n  }\n"], ["\n  width: 100%;\n  text-align: initial;\n  font-size: 14px;\n  &.bold {\n    font-family: ", ";\n  }\n"])), theme.typography.bold.ralewayBold);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
