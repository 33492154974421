var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { Element } from 'react-scroll';
import { L, M, S, theme } from 'src/Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  padding: 20px 0px;\n  justify-content: space-between;\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-color: ", ";\n  height: 100%;\n  min-height: 500px;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n    background-color: ", ";\n    justify-content: center;\n    background-attachment: fixed;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  padding: 20px 0px;\n  justify-content: space-between;\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-color: ", ";\n  height: 100%;\n  min-height: 500px;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n    background-color: ", ";\n    justify-content: center;\n    background-attachment: fixed;\n  }\n"])), theme.palette.primary[50], M, theme.palette.white[100]);
export var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 40%;\n  padding-left: 10vw;\n  max-width: 600px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  @media (max-width: ", "px) {\n    width: 80%;\n  }\n"], ["\n  width: 40%;\n  padding-left: 10vw;\n  max-width: 600px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  @media (max-width: ", "px) {\n    width: 80%;\n  }\n"])), M);
export var ContentContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding-left: 50px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  @media (max-width: ", "px) {\n    padding-left: 0px;\n  }\n"], ["\n  padding-left: 50px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  @media (max-width: ", "px) {\n    padding-left: 0px;\n  }\n"])), M);
export var Title = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: 600;\n  font-size: 36px;\n  line-height: 42px;\n  width: 100%;\n  font-family: ", ";\n  color: ", ";\n  margin-bottom: 50px;\n  margin-top: 10px;\n  @media (max-width: ", "px) {\n    font-size: 28px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 22px;\n    margin-bottom: 40px;\n  }\n  &.maintenance {\n    text-align: center;\n  }\n"], ["\n  font-weight: 600;\n  font-size: 36px;\n  line-height: 42px;\n  width: 100%;\n  font-family: ", ";\n  color: ", ";\n  margin-bottom: 50px;\n  margin-top: 10px;\n  @media (max-width: ", "px) {\n    font-size: 28px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 22px;\n    margin-bottom: 40px;\n  }\n  &.maintenance {\n    text-align: center;\n  }\n"])), theme.typography.normal.raleway, theme.palette.white[100], L, M);
export var DescriptionContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 18px;\n  line-height: 23px;\n  color: ", ";\n  font-family: ", ";\n  @media (max-width: ", "px) {\n    font-size: 16px;\n  }\n  &.maintenance {\n    text-align: center;\n  }\n"], ["\n  font-size: 18px;\n  line-height: 23px;\n  color: ", ";\n  font-family: ", ";\n  @media (max-width: ", "px) {\n    font-size: 16px;\n  }\n  &.maintenance {\n    text-align: center;\n  }\n"])), theme.palette.white[100], theme.typography.OpenSans, S);
export var Img = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 95%;\n  max-height: 650px;\n"], ["\n  width: 95%;\n  max-height: 650px;\n"])));
export var ImageContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 60%;\n  margin-top: 40px;\n"], ["\n  width: 60%;\n  margin-top: 40px;\n"])));
export var ElementComponent = styled(Element)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
