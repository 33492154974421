var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import moment from 'moment';
import { openedArrow } from 'src/assets/Images';
import { AMOUNT_KEY, AMOUNT_TEXT, APARTMENT, APARTMENT_KEY, BUILDING, BUILDING_KEY, CANCEL, CLIENT_CODE, CLIENT_KEY, CLIENT_API, DATE_KEY, DESCRIPTION, DESCRIPTION_KEY, EXPIRATION_DATE, INVALID_CLIENT, ROUTES, TOWER, TOWER_KEY, APT, APT_KEY, ALL, CURRENCY_KEY, } from 'src/Utils/constants';
import { getData } from 'src/Utils/api';
import { CURRENCY_DATA, API_DATA } from 'src/Utils/mockData';
import { hasError, isValidForm } from 'src/Utils/validations';
import InputComponent from '../Common/InputComponent';
import { DetailsContainer, Div, FormContainer, FormsContainer, FormsDiv, InputContainer, Label, Error, CandelarContainer, BottomInputs, ButtonsContainer, Link, Input, Img, DivImg, ErrorMessage, } from './styles';
import { Popover, SelectComponent, TextInput } from '../Common';
import { AdminButton } from '../Common/AdminButton';
import { CustomInput } from './CustomInput';
import CalendarComponent from '../Common/Calendar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { resetValues, setAmount, setApt, setClientCode, setCurrency, setDescription, setExpirationDate, } from '../../redux/slices/commonExpensesReceiptSlice';
import { setSelectedApartment, setSelectedTower, } from '../../redux/slices/tableFiltersSlice';
var fixedDate = new Date();
fixedDate.setDate(25);
var NewReceiptForm = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var onSubmit = _a.onSubmit, backError = _a.backError, buttonLabel = _a.buttonLabel;
    var dispatch = useAppDispatch();
    var receiptInfo = useAppSelector(function (state) { return state.commonExpensesReceiptSlice; });
    var _k = useAppSelector(function (state) { return state.tableFiltersSlice; }), selectedBuilding = _k.selectedBuilding, selectedApartment = _k.selectedApartment, selectedTower = _k.selectedTower;
    var history = useHistory();
    var _l = useState({}), errorsSubmit = _l[0], setErrorsSubmit = _l[1];
    var _m = useState(null), anchorRefCurrency = _m[0], setAnchorRefCurrency = _m[1];
    var _o = useState(null), anchorRefApt = _o[0], setAnchorRefApt = _o[1];
    var _p = useState(null), anchorRefTower = _p[0], setAnchorRefTower = _p[1];
    var _q = useState(null), anchorRefApartment = _q[0], setAnchorRefApartment = _q[1];
    var onCancel = function () {
        history.push({
            pathname: ROUTES.receipt,
        });
        resetSlice();
    };
    var resetSlice = function () { return dispatch(resetValues()); };
    useQuery([CLIENT_API, receiptInfo.clientCode], function () {
        return getData("/common_expenses_receipts/validate_client_code", {
            client_code: receiptInfo.clientCode,
        });
    }, {
        retry: false,
        onSuccess: function () {
            var _a;
            if (errorsSubmit[CLIENT_KEY] === INVALID_CLIENT) {
                setErrorsSubmit(__assign(__assign({}, errorsSubmit), (_a = {}, _a[CLIENT_KEY] = '', _a)));
            }
        },
        onError: function () {
            var _a;
            setErrorsSubmit(__assign(__assign({}, errorsSubmit), (_a = {}, _a[CLIENT_KEY] = INVALID_CLIENT, _a)));
        },
        refetchOnWindowFocus: false,
        enabled: ((_b = receiptInfo.clientCode) === null || _b === void 0 ? void 0 : _b.length) >= 12,
    });
    var _r = useQuery([selectedTower], function () {
        return getData("/buildings/" + (selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value) + "/apartments/dropdown?tower=" + (selectedTower === null || selectedTower === void 0 ? void 0 : selectedTower.value));
    }, {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: (selectedTower === null || selectedTower === void 0 ? void 0 : selectedTower.label) !== '' && (selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value) !== '',
    }), dataApartments = _r.data, isLoadingApartments = _r.isLoading;
    var apartmentData = useMemo(function () { var _a; return (_a = dataApartments === null || dataApartments === void 0 ? void 0 : dataApartments.apartments) === null || _a === void 0 ? void 0 : _a.map(function (_a) {
        var id = _a.id, number = _a.number;
        return ({
            label: number,
            value: id,
        });
    }); }, [dataApartments]);
    var handleClick = function (e) {
        var currentValue = e.currentTarget;
        if (anchorRefCurrency)
            handleOutsideClick();
        setAnchorRefCurrency(currentValue);
        if (anchorRefApt)
            handleOutsideClick();
        setAnchorRefCurrency(currentValue);
        if (anchorRefTower)
            handleOutsideClick();
        setAnchorRefTower(currentValue);
    };
    var getTowerCode = function (code) {
        return code === '_' ? '00' : letterToNumber(code);
    };
    useEffect(function () {
        var _a, _b, _c;
        var buildingNumberCode = letterToNumber(((_a = selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.code) === null || _a === void 0 ? void 0 : _a.slice(0, 1)) || '');
        var towerNumberCode = getTowerCode(selectedTower === null || selectedTower === void 0 ? void 0 : selectedTower.code);
        var apartmentNumber = getApartmentNumber(((selectedApartment === null || selectedApartment === void 0 ? void 0 : selectedApartment.label) || '').toString());
        var apartmentApt = getAptCode(((_b = receiptInfo.apt) === null || _b === void 0 ? void 0 : _b.value) || '');
        dispatch(setClientCode(buildingNumberCode + ((_c = selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.code) === null || _c === void 0 ? void 0 : _c.substring(1)) +
            towerNumberCode +
            (apartmentNumber !== ALL ? apartmentNumber : '') +
            apartmentApt));
    }, [receiptInfo.apt, selectedTower, selectedApartment]);
    var getAptCode = function (apt) {
        return apt === 'Propietario' || apt === 'P'
            ? '16'
            : apt === 'Ambos' || apt === 'A'
                ? '01'
                : apt === 'Inquilino' || apt === 'I'
                    ? '09'
                    : '0';
    };
    var letterToNumber = function (letter) {
        var res = ((letter === null || letter === void 0 ? void 0 : letter.charCodeAt(0)) - 64).toString();
        return res.length === 1 ? '0' + res : res;
    };
    var getApartmentNumber = function (apartment) {
        for (var i = 0; i < 4; i++) {
            if (apartment.length < 4)
                apartment = '0' + apartment;
        }
        return apartment;
    };
    var handleOutsideClick = function () {
        setAnchorRefCurrency(null);
        setAnchorRefApt(null);
        setAnchorRefTower(null);
        setAnchorRefApartment(null);
    };
    var handleSubmit = function () {
        var _a, _b;
        if (((_a = receiptInfo.clientCode) === null || _a === void 0 ? void 0 : _a.length) != 12)
            errorsSubmit[CLIENT_KEY] = INVALID_CLIENT;
        else if (!errorsSubmit[CLIENT_KEY])
            errorsSubmit[CLIENT_KEY] = receiptInfo.clientCode;
        errorsSubmit[DATE_KEY] = receiptInfo.expirationDate;
        errorsSubmit[AMOUNT_KEY] = receiptInfo.amount;
        if (isValidForm(errorsSubmit, setErrorsSubmit)) {
            onSubmit(receiptInfo.clientCode, receiptInfo.address, selectedApartment === null || selectedApartment === void 0 ? void 0 : selectedApartment.value, selectedTower, receiptInfo.receiptNumber, receiptInfo.expirationDate, receiptInfo.amount, (_b = receiptInfo.currency) === null || _b === void 0 ? void 0 : _b.value, receiptInfo.description, selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.label);
            resetSlice();
        }
    };
    var handleCurrency = function (item) { return dispatch(setCurrency(item)); };
    var handleApartment = function (apartmentToSelect) {
        return dispatch(setSelectedApartment(apartmentToSelect));
    };
    var handleTower = function (towerToSelect) {
        dispatch(setSelectedTower(towerToSelect));
        dispatch(setSelectedApartment({
            label: '',
            value: '',
        }));
    };
    var handleApt = function (aptToSelect) { return dispatch(setApt(aptToSelect)); };
    var renderPopOver = function (data, anchorRef, setSelected, key) { return (React.createElement(Popover, { data: data, handleOutsideClick: handleOutsideClick, anchorRef: anchorRef, setSelected: setSelected, isFilter: false, className: CURRENCY_KEY !== key ? 'form' : '' })); };
    return (React.createElement(React.Fragment, null,
        React.createElement(FormsContainer, null,
            React.createElement(FormsDiv, null,
                React.createElement(DetailsContainer, null,
                    React.createElement(FormContainer, null,
                        React.createElement(InputComponent, { placeHolder: "A01L0001P", type: CLIENT_KEY, data: receiptInfo.clientCode, error: errorsSubmit, label: CLIENT_CODE, onChangeFunction: function (e) {
                                e.persist();
                                dispatch(setClientCode(e.target.value.toUpperCase()));
                            } }),
                        React.createElement(SelectComponent, { label: APT, type: APT_KEY, data: (_c = receiptInfo.apt) === null || _c === void 0 ? void 0 : _c.label, errors: errorsSubmit, img: openedArrow, anchorRef: anchorRefApt, setAnchorRef: setAnchorRefApt }),
                        renderPopOver(API_DATA, anchorRefApt, handleApt),
                        React.createElement(InputComponent, { disabled: true, type: BUILDING_KEY, data: (_d = selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.label) === null || _d === void 0 ? void 0 : _d.toString(), error: errorsSubmit, label: BUILDING, onChangeFunction: null }),
                        ((_e = receiptInfo.towerList) === null || _e === void 0 ? void 0 : _e.length) === 1 && (React.createElement(InputComponent, { disabled: true, type: TOWER_KEY, data: (_f = selectedTower === null || selectedTower === void 0 ? void 0 : selectedTower.label) === null || _f === void 0 ? void 0 : _f.toString(), error: errorsSubmit, label: TOWER, onChangeFunction: null })),
                        receiptInfo.towerList && ((_g = receiptInfo.towerList) === null || _g === void 0 ? void 0 : _g.length) > 1 && (React.createElement(React.Fragment, null,
                            React.createElement(SelectComponent, { label: TOWER, type: TOWER_KEY, data: selectedTower === null || selectedTower === void 0 ? void 0 : selectedTower.label, errors: errorsSubmit, img: openedArrow, anchorRef: anchorRefTower, setAnchorRef: setAnchorRefTower }),
                            renderPopOver(receiptInfo.towerList, anchorRefTower, handleTower))),
                        React.createElement(SelectComponent, { label: APARTMENT, type: APARTMENT_KEY, data: selectedApartment === null || selectedApartment === void 0 ? void 0 : selectedApartment.label, errors: errorsSubmit, img: openedArrow, anchorRef: anchorRefApartment, setAnchorRef: setAnchorRefApartment }),
                        renderPopOver(apartmentData, anchorRefApartment, handleApartment))),
                React.createElement(DetailsContainer, null,
                    React.createElement(FormContainer, null,
                        React.createElement(InputContainer, { className: "height" },
                            React.createElement(Label, null, EXPIRATION_DATE),
                            React.createElement(CalendarComponent, { selected: moment(receiptInfo.expirationDate || undefined).toDate(), onChange: function (e) { return dispatch(setExpirationDate(e)); }, customInput: React.createElement(CustomInput, null) }),
                            React.createElement(Div, null,
                                React.createElement(Error, null, errorsSubmit[DATE_KEY]))),
                        React.createElement(InputContainer, { className: "height" },
                            React.createElement(Label, null, AMOUNT_TEXT),
                            React.createElement(CandelarContainer, null,
                                React.createElement(TextInput, { onChange: function (e) { return dispatch(setAmount(e.target.value)); }, value: (_h = receiptInfo.amount) === null || _h === void 0 ? void 0 : _h.toString(), hasError: hasError(AMOUNT_KEY, errorsSubmit), className: 'border-admin', style: { width: '100%' } }),
                                React.createElement(Input, null, (_j = receiptInfo.currency) === null || _j === void 0 ? void 0 : _j.label),
                                React.createElement(DivImg, { onClick: function (e) { return handleClick(e); } },
                                    React.createElement(Img, { src: openedArrow, className: "receipt" }))),
                            React.createElement(Div, null,
                                React.createElement(Error, null, errorsSubmit[AMOUNT_KEY]))),
                        React.createElement(InputComponent, { type: DESCRIPTION_KEY, data: receiptInfo.description, error: errorsSubmit, label: DESCRIPTION, onChangeFunction: function (e) {
                                return dispatch(setDescription(e.target.value));
                            } })))),
            React.createElement(BottomInputs, null,
                React.createElement(ButtonsContainer, null,
                    React.createElement(AdminButton, { type: "button", className: "height", onClick: handleSubmit }, buttonLabel),
                    React.createElement(Link, { onClick: onCancel }, CANCEL)),
                backError && React.createElement(ErrorMessage, null, backError))),
        renderPopOver(CURRENCY_DATA, anchorRefCurrency, handleCurrency, CURRENCY_KEY)));
};
export default NewReceiptForm;
