var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { Element } from 'react-scroll';
import { theme, L, XL } from '../../Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 85%;\n  padding: 20px 0px;\n  justify-content: center;\n  background-color: ", ";\n  height: 100%;\n  min-height: 500px;\n  @media (max-width: ", "px) {\n    width: 90%;\n  }\n  @media (max-width: ", "px) {\n    flex-direction: column-reverse;\n    justify-content: center;\n    width: 100%;\n    padding-bottom: 0px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 85%;\n  padding: 20px 0px;\n  justify-content: center;\n  background-color: ", ";\n  height: 100%;\n  min-height: 500px;\n  @media (max-width: ", "px) {\n    width: 90%;\n  }\n  @media (max-width: ", "px) {\n    flex-direction: column-reverse;\n    justify-content: center;\n    width: 100%;\n    padding-bottom: 0px;\n  }\n"])), theme.palette.grey[15], XL, L);
export var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 60%;\n  padding-left: 50px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  @media (max-width: ", "px) {\n    width: 80%;\n    min-height: 260px;\n    padding-left: 0px;\n  }\n"], ["\n  width: 60%;\n  padding-left: 50px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  @media (max-width: ", "px) {\n    width: 80%;\n    min-height: 260px;\n    padding-left: 0px;\n  }\n"])), L);
export var ContentContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding-left: 50px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  @media (max-width: ", "px) {\n    padding-left: 0px;\n  }\n"], ["\n  padding-left: 50px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  @media (max-width: ", "px) {\n    padding-left: 0px;\n  }\n"])), L);
export var Title = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 24px;\n  width: 100%;\n  font-family: ", ";\n  color: ", ";\n  margin-bottom: 15px;\n  margin-top: 20px;\n  @media (max-width: ", "px) {\n    font-size: 18px;\n    text-align: center;\n  }\n"], ["\n  font-size: 24px;\n  width: 100%;\n  font-family: ", ";\n  color: ", ";\n  margin-bottom: 15px;\n  margin-top: 20px;\n  @media (max-width: ", "px) {\n    font-size: 18px;\n    text-align: center;\n  }\n"])), theme.typography.bold.ralewayBold, theme.palette.black[100], L);
export var DescriptionContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 18px;\n  line-height: 23px;\n  color: ", ";\n  font-family: ", ";\n  @media (max-width: ", "px) {\n    font-size: 14px;\n    max-width: 80%;\n  }\n"], ["\n  font-size: 18px;\n  line-height: 23px;\n  color: ", ";\n  font-family: ", ";\n  @media (max-width: ", "px) {\n    font-size: 14px;\n    max-width: 80%;\n  }\n"])), theme.palette.black[100], theme.typography.OpenSans, L);
export var Image = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  min-width: 400px;\n  max-width: 500px;\n  @media (max-width: ", "px) {\n    max-width: inherit;\n  }\n"], ["\n  width: 100%;\n  height: 100%;\n  min-width: 400px;\n  max-width: 500px;\n  @media (max-width: ", "px) {\n    max-width: inherit;\n  }\n"])), L);
export var ImageContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: fit-content;\n  margin-top: 10px;\n  @media (max-width: ", "px) {\n    margin-top: 40px;\n    width: 100%;\n    height: 200px;\n    background-repeat: no-repeat;\n    background-size: cover;\n  }\n"], ["\n  width: fit-content;\n  margin-top: 10px;\n  @media (max-width: ", "px) {\n    margin-top: 40px;\n    width: 100%;\n    height: 200px;\n    background-repeat: no-repeat;\n    background-size: cover;\n  }\n"])), L);
export var ElementComponent = styled(Element)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
