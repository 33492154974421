import React from 'react';
import { useWindowWidth } from 'src/useWindowWidth';
import { L, M } from 'src/Utils';
import { handleCurrency } from '../Form/utils';
import { Column, Title, Text } from '../../styles';
import { Cod, Price } from './styles';
export var DetailInfo = function (_a) {
    var cod = _a.cod, description = _a.description, price = _a.price, currency = _a.currency;
    var windowWidth = useWindowWidth();
    return (React.createElement(Column, { gap: "12px" },
        windowWidth < L && (React.createElement(Price, null,
            handleCurrency(currency),
            " ",
            price.toLocaleString().replace(',', '.'))),
        windowWidth > M && React.createElement(Cod, null,
            "Cod: ",
            cod),
        React.createElement(Title, null, "Caracter\u00EDsticas"),
        React.createElement(Text, { dangerouslySetInnerHTML: { __html: description } })));
};
