import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { SwipeableList, SwipeableListItem, } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import { TableContainer, UploadContainer, UploadButton, DeleteDiv, TrashImg, } from './styles';
import { deleteCommonExpenses } from './duck';
import { Filter, AdminCard, DeleteModal } from 'src/Components/Common';
import Table from 'src/Components/GenericTable';
import { M } from 'src/Utils';
import { useWindowWidth } from 'src/useWindowWidth';
import { AddIcon, Trash } from 'src/assets/Images';
import { stableSort, getComparator } from 'src/Utils/utils';
import { TYPE_CONTACT, NEW_RECEIPT_TEXT, ADD_RECEIPT, RECEIPT, ROUTES, } from 'src/Utils/constants';
var WIDTHS_SUPER = ['15%', '15%', '15%', '15%', '15%', '10%', '10%'];
var WIDTHS_ADMIN = ['15%', '15%', '15%', '15%', '15%', '10%'];
var WIDTHS_CONTACT = ['20%', '30%', '15%', '20%', '20%'];
var FilterTable = function (_a) {
    var rows = _a.rows, headCells = _a.headCells, buildingList = _a.buildingList, towerList = _a.towerList, apartmentList = _a.apartmentList, selectedBuilding = _a.selectedBuilding, setSelectedBuilding = _a.setSelectedBuilding, selectedTower = _a.selectedTower, setSelectedTower = _a.setSelectedTower, selectedApartment = _a.selectedApartment, setSelectedApartment = _a.setSelectedApartment, startDate = _a.startDate, setStartDate = _a.setStartDate, endDate = _a.endDate, setEndDate = _a.setEndDate, addPDF = _a.addPDF, isLoading = _a.isLoading, setRefetch = _a.setRefetch, _b = _a.hasSearch, hasSearch = _b === void 0 ? true : _b, setSelectedId = _a.setSelectedId, setSelectedPDF = _a.setSelectedPDF, setIsOpenModal = _a.setIsOpenModal, orderByStateIndex = _a.orderByStateIndex, _c = _a.isPaymentHistory, isPaymentHistory = _c === void 0 ? false : _c, hasButton = _a.hasButton, deleteRow = _a.deleteRow, setOpenModalContacts = _a.setOpenModalContacts;
    var edit_receipt = ROUTES.edit_receipt;
    var history = useHistory();
    var _d = useState(''), selectedExpenses = _d[0], setSelectedExpenses = _d[1];
    var _e = useState([]), rowsData = _e[0], setRowsData = _e[1];
    var _f = useState(''), searchedItem = _f[0], setSearchedItem = _f[1];
    var _g = useState(false), isDeleteModalOpen = _g[0], setIsDeleteModalOpen = _g[1];
    var _h = useState('second'), orderBy = _h[0], setOrderBy = _h[1];
    var windowWidth = useWindowWidth();
    var isSmallScreen = M >= windowWidth;
    var isContact = Cookies.get('User') === TYPE_CONTACT;
    var isSuperAdmin = Cookies.get('User') === 'super_admin';
    var mutateDeleteCommonExpenses = useMutation(deleteCommonExpenses, {
        onSuccess: function () {
            setRefetch && setRefetch(function (prev) { return !prev; });
            setIsDeleteModalOpen(function (prev) { return !prev; });
        },
    })[0];
    useEffect(function () {
        setRowsData(rows);
    }, [rows]);
    var handlePaymentModal = function () {
        //TODO: add amoutn and receipt ando open modal
    };
    var onDelete = function () {
        mutateDeleteCommonExpenses(selectedExpenses);
    };
    var renderDelete = function () { return (React.createElement(DeleteDiv, null,
        React.createElement(TrashImg, { src: Trash }))); };
    var renderFilter = function () { return (React.createElement(Filter, { building: selectedBuilding && selectedBuilding.label, tower: selectedTower && selectedTower.label, apartment: selectedApartment && selectedApartment.label, setBuilding: setSelectedBuilding, setTower: setSelectedTower, setApartment: setSelectedApartment, searchedItem: searchedItem, setSearchedItem: setSearchedItem, startDate: startDate, setStartDate: setStartDate, endDate: endDate, setEndDate: setEndDate, setOrderBy: setOrderBy, orderBy: orderBy, isCommonExpenses: true, data: buildingList, towerList: towerList, apartmentList: apartmentList, expenses: selectedExpenses, setExpenses: setSelectedExpenses, expensesData: [
            { label: 'Todos', value: 'Todos' },
            { label: 'Pagados', value: 'Pagados' },
            { label: 'Informados', value: 'Informados' },
            { label: 'Opción 1', value: 'Opcion1 ' },
            { label: 'Opción 2', value: 'Opcion 2' },
        ], sortData: [
            { label: 'Apartamento', value: 'Apartamento' },
            { label: 'Fecha', value: 'Fecha' },
        ], hasSearch: hasSearch, isPaymentHistory: isPaymentHistory })); };
    return (React.createElement(React.Fragment, null,
        React.createElement(TableContainer, null,
            renderFilter(),
            isSmallScreen && rowsData ? (React.createElement(SwipeableList, null, stableSort(rowsData, getComparator('asc', orderBy)).map(function (row, index) {
                return row.first.includes(searchedItem) &&
                    (isContact ? (React.createElement(AdminCard, { item: row, isCommonExpenses: true, key: row.first + index })) : (React.createElement(SwipeableListItem, { swipeLeft: {
                            content: renderDelete(),
                            action: function () { return onDelete(); },
                        }, key: row.first + index },
                        React.createElement(AdminCard, { item: row, isCommonExpenses: true, selectedBuilding: selectedBuilding, selectedTower: selectedTower, selectedApartment: selectedApartment, data: rowsData.filter(function (row) {
                                return row.first.includes(searchedItem);
                            }) }))));
            }))) : (React.createElement(Table, { rows: rowsData.filter(function (row) { return row.first.includes(searchedItem); }), rowsEdit: rowsData, headCells: headCells, hasDots: true, handleDelete: function () { return setIsDeleteModalOpen(function (prev) { return !prev; }); }, isCommonExpenses: true, handleButtonClick: !isContact && (selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.label)
                    ? function () {
                        return history.push({
                            pathname: NEW_RECEIPT_TEXT,
                        });
                    }
                    : undefined, modalButton: ADD_RECEIPT, className: "width", widths: !isContact
                    ? !isSuperAdmin
                        ? WIDTHS_ADMIN
                        : WIDTHS_SUPER
                    : WIDTHS_CONTACT, historyText: edit_receipt, topIndex: 7, handlePaymentModal: handlePaymentModal, addPDF: addPDF, isLoading: isLoading, setSelected: setSelectedExpenses, setSelectedId: setSelectedId, setSelectedPDF: setSelectedPDF, setIsOpenModal: setIsOpenModal, orderByStateIndex: orderByStateIndex, hasButton: hasButton, selectedBuilding: selectedBuilding, selectedTower: selectedTower, selectedApartment: selectedApartment, deleteRow: deleteRow, setOpenModalContacts: setOpenModalContacts }))),
        React.createElement(DeleteModal, { isOpen: isDeleteModalOpen, setIsOpen: setIsDeleteModalOpen, onDelete: onDelete, itemLabel: RECEIPT }),
        isSmallScreen && !isContact && (React.createElement(UploadContainer, null,
            React.createElement(UploadButton, { src: AddIcon, onClick: function () {
                    return history.push({
                        pathname: NEW_RECEIPT_TEXT,
                        state: {
                            selectedBuilding: selectedBuilding,
                            selectedTower: selectedTower,
                            selectedApartment: selectedApartment,
                            towerList: towerList,
                        },
                    });
                } })))));
};
export default FilterTable;
