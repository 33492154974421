var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { M, theme } from 'src/Utils';
export var Column = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: ", ";\n"])), function (_a) {
    var gap = _a.gap;
    return gap;
});
export var Row = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  gap: ", ";\n  &.align-center {\n    align-items: center;\n  }\n  &.space-between {\n    justify-content: space-between;\n  }\n  &.full-width {\n    width: 100%;\n  }\n  &.height-48 {\n    height: 48px;\n  }\n  &.m-4 {\n    margin: 4px 0;\n  }\n"], ["\n  display: flex;\n  gap: ", ";\n  &.align-center {\n    align-items: center;\n  }\n  &.space-between {\n    justify-content: space-between;\n  }\n  &.full-width {\n    width: 100%;\n  }\n  &.height-48 {\n    height: 48px;\n  }\n  &.m-4 {\n    margin: 4px 0;\n  }\n"])), function (_a) {
    var gap = _a.gap;
    return gap;
});
export var DesktopContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n"], ["\n  display: flex;\n  justify-content: center;\n"])));
export var InnerDesktopContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 260px;\n  height: fit-content;\n  border-radius: 10px;\n  box-shadow: 0px 0px 30px 0px rgba(111, 152, 201, 0.17);\n"], ["\n  width: 260px;\n  height: fit-content;\n  border-radius: 10px;\n  box-shadow: 0px 0px 30px 0px rgba(111, 152, 201, 0.17);\n"])));
export var Divider = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  border-bottom: 1px ", " solid;\n"], ["\n  border-bottom: 1px ", " solid;\n"])), theme.palette.grey[10]);
export var BoxContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  padding: 24px;\n  gap: 24px;\n  @media (max-width: ", "px) {\n    padding: 0 24px 24px;\n    margin-top: -8px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  padding: 24px;\n  gap: 24px;\n  @media (max-width: ", "px) {\n    padding: 0 24px 24px;\n    margin-top: -8px;\n  }\n"])), M);
export var Title = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 700;\n  &.big {\n    font-size: 20px;\n  }\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 700;\n  &.big {\n    font-size: 20px;\n  }\n"])), theme.palette.black[85], theme.typography.bold.ralewayBold);
export var Label = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n"])), theme.palette.grey[60], theme.typography.normal.raleway);
export var Wrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  gap: ", ";\n  &.column {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  gap: ", ";\n  &.column {\n    flex-direction: column;\n  }\n"])), function (_a) {
    var gap = _a.gap;
    return gap;
});
export var ToggleButton = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  background-color: ", ";\n  padding: 8px 0px;\n  cursor: pointer;\n  min-width: 52px;\n  &.full-width {\n    width: 100%;\n  }\n  &.isActive {\n    font-family: ", ";\n    color: ", ";\n    font-weight: 700;\n    background-color: ", ";\n  }\n  &.left {\n    border-radius: 7px 0px 0px 7px;\n  }\n  &.right {\n    border-radius: 0px 7px 7px 0px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  background-color: ", ";\n  padding: 8px 0px;\n  cursor: pointer;\n  min-width: 52px;\n  &.full-width {\n    width: 100%;\n  }\n  &.isActive {\n    font-family: ", ";\n    color: ", ";\n    font-weight: 700;\n    background-color: ", ";\n  }\n  &.left {\n    border-radius: 7px 0px 0px 7px;\n  }\n  &.right {\n    border-radius: 0px 7px 7px 0px;\n  }\n"])), theme.palette.grey[50], theme.typography.normal.raleway, theme.palette.admin.primary[25], theme.typography.bold.ralewayBold, theme.palette.white[100], theme.palette.blue[5]);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
