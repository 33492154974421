import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableContainer, TablePagination, IconButton, } from '@material-ui/core';
import { BeatLoader } from 'react-spinners';
import TableHeader from './TableHeader';
import Row from './TableRow';
import { stableSort, getComparator, theme, ID } from 'src/Utils';
import { ArrowsContainer, PaginationLabel, StyledPaper, Div, ButtonContainer, Button, } from './styles';
import { ArrowRight, ArrowLeft } from '../../../assets/Images';
import Modal from '../Modal';
var ROWS_PER_PAGE = 15;
var TableComponent = function (_a) {
    var rows = _a.rows, headCells = _a.headCells, hasModal = _a.hasModal, isModalOpen = _a.isModalOpen, modalButton = _a.modalButton, modalField = _a.modalField, modalTitle = _a.modalTitle, hasButton = _a.hasButton, handleButtonClick = _a.handleButtonClick, handleModalSubmit = _a.handleModalSubmit, handleDeleteRow = _a.handleDeleteRow, setIsModalOpen = _a.setIsModalOpen, className = _a.className, handleClick = _a.handleClick, handleDelete = _a.handleDelete, handleEdit = _a.handleEdit, _b = _a.isCommonExpenses, isCommonExpenses = _b === void 0 ? false : _b, widths = _a.widths, handlePaymentModal = _a.handlePaymentModal, isAssembly = _a.isAssembly, isInvoice = _a.isInvoice, checkSelect = _a.checkSelect, hasDownloadButton = _a.hasDownloadButton, _c = _a.isLoading, isLoading = _c === void 0 ? false : _c, _d = _a.isPaymentHistory, isPaymentHistory = _d === void 0 ? false : _d, setSelectedId = _a.setSelectedId, building = _a.building, hasDelete = _a.hasDelete, isSettlement = _a.isSettlement, onPageChanged = _a.onPageChange, totalPages = _a.totalPages, _e = _a.paginated, paginated = _e === void 0 ? false : _e, currentPage = _a.page, isDocument = _a.isDocument, buttonDisabled = _a.buttonDisabled, handleRowClick = _a.handleRowClick, _f = _a.compareDateWithoutHours, compareDateWithoutHours = _f === void 0 ? false : _f, _g = _a.isBookings, isBookings = _g === void 0 ? false : _g, _h = _a.hasDots, hasDots = _h === void 0 ? false : _h, handleView = _a.handleView;
    var _j = useState('asc'), order = _j[0], setOrder = _j[1];
    var _k = useState(ID), orderBy = _k[0], setOrderBy = _k[1];
    var _l = useState(0), page = _l[0], setPage = _l[1];
    useEffect(function () {
        if (!paginated) {
            setPage(0);
        }
    }, [rows]);
    var handleChangePage = function (event, newPage) {
        if (onPageChanged)
            onPageChanged(newPage);
        setPage(newPage);
    };
    if (currentPage == 1 && page != 0) {
        setPage(0);
    }
    function TablePaginationActions(props) {
        var count = props.count, page = props.page, rowsPerPage = props.rowsPerPage, onPageChange = props.onPageChange;
        var handleBackButtonClick = function (event) {
            onPageChange(event, page - 1);
        };
        var handleNextButtonClick = function (event) {
            onPageChange(event, page + 1);
        };
        var numberPage = paginated ? currentPage : page + 1;
        var maxPage = rows.length == 0 ? 0 : totalPages || Math.ceil(count / rowsPerPage);
        return (React.createElement(Div, { className: hasButton ? '' : 'centered' },
            React.createElement(ArrowsContainer, { className: hasButton ? 'minutes' : '' },
                React.createElement(IconButton, { onClick: handleBackButtonClick, disabled: page === 0, "aria-label": "previous page" },
                    React.createElement("img", { src: ArrowLeft, alt: "P\u00E1gina previa" })),
                React.createElement(PaginationLabel, null, numberPage + "/" + maxPage),
                React.createElement(IconButton, { onClick: handleNextButtonClick, disabled: numberPage >= maxPage, "aria-label": "next page" },
                    React.createElement("img", { src: ArrowRight, alt: "P\u00E1gina siguiente" }))),
            hasButton && handleButtonClick && (React.createElement(ButtonContainer, null,
                React.createElement(Button, { onClick: handleButtonClick, disabled: buttonDisabled }, modalButton),
                isModalOpen &&
                    hasModal &&
                    setIsModalOpen !== undefined &&
                    isModalOpen != undefined && (React.createElement(Modal, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, title: modalTitle, textInputLabel: modalField, onSubmit: handleModalSubmit, isAssembly: isAssembly, isInvoice: isInvoice, checkSelect: checkSelect, building: building }))))));
    }
    var rowsSorted = stableSort(rows, getComparator(order, orderBy, compareDateWithoutHours));
    var pageRows = rowsSorted.slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE);
    var rowsShown = paginated ? rowsSorted : pageRows;
    return (React.createElement(StyledPaper, null,
        React.createElement(TableContainer, null,
            React.createElement(Table, { "aria-labelledby": "tableTitle", size: 'medium', "aria-label": "enhanced table" },
                React.createElement(TableHeader, { fields: headCells, widths: widths, order: order, orderBy: orderBy, setOrder: setOrder, setOrderBy: setOrderBy, className: className }),
                isLoading ? (React.createElement(BeatLoader, { size: "8px", color: theme.palette.black[100] })) : (React.createElement(TableBody, null, rowsShown.map(function (row, index) { return (React.createElement(Row, { key: row.first + index, data: row, widths: widths, isCommonExpenses: isCommonExpenses, className: className, handleClick: handleClick, handleDelete: handleDelete, handleDeleteRow: handleDeleteRow, handleEdit: handleEdit, handlePaymentModal: handlePaymentModal, path: row.fifth, pdfName: row.first, hasDownloadButton: hasDownloadButton, isPaymentHistory: isPaymentHistory, setSelectedId: setSelectedId, isAssembly: isAssembly, hasDelete: hasDelete, isSettlement: isSettlement, isDocument: isDocument, handleRowClick: handleRowClick, isBookings: isBookings, hasDots: hasDots, handleView: handleView })); }))))),
        React.createElement(TablePagination, { component: "div", labelRowsPerPage: "", count: rows.length, rowsPerPage: ROWS_PER_PAGE, page: page, onPageChange: handleChangePage, ActionsComponent: TablePaginationActions })));
};
export default TableComponent;
