import api from 'src/Utils/api';
export var submitFile = function (file) {
    var formData = new FormData();
    formData.append('file', file);
    formData.append('name', file.name);
    return api.post('/sistarbanc_conciliations', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
