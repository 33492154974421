var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import styles from './styles';
export var PDFTable = function (_a) {
    var header = _a.header, rows = _a.rows, footer = _a.footer, name = _a.name;
    var cellStyle = function (index, showBold) {
        var width = (header === null || header === void 0 ? void 0 : header.length) ? header[0].length
            : (rows === null || rows === void 0 ? void 0 : rows.length) ? rows.length
                : (footer === null || footer === void 0 ? void 0 : footer.length) ? footer.length
                    : 1;
        return __assign({ width: 100 / width + "%", padding: "8 " + (index === width - 1 ? 16 : 0) + " 8 16" }, (showBold ? styles.bold : styles.normal));
    };
    var renderCell = function (index, value, showBold) {
        var regex = /[0-9]+[a-zA-Z]+/i;
        return (React.createElement(Text, { style: cellStyle(index, showBold), key: value }, value
            ? regex.test(value)
                ? value === null || value === void 0 ? void 0 : value.trim() : value === null || value === void 0 ? void 0 : value.toLowerCase()
            : ''));
    };
    var isOnlyOneNonEmptyString = function (arr) {
        var nonEmptyStrings = arr.filter(function (item) { return item !== ''; });
        return nonEmptyStrings.length === 1;
    };
    return (React.createElement(View, { style: styles.table },
        name && React.createElement(View, { style: styles.specialName }, renderCell(0, name)), header === null || header === void 0 ? void 0 :
        header.map(function (row, index) { return (React.createElement(React.Fragment, null,
            React.createElement(View, { style: styles.tableHeader, wrap: false }, Array.isArray(row[1]) &&
                row[1].map(function (value) { return renderCell(index, value); })),
            header.length >= 2 && index === 0 && (React.createElement(View, { style: styles.lineTitle })))); }), rows === null || rows === void 0 ? void 0 :
        rows.map(function (row, index) { return (React.createElement(View, { key: index, style: !(index % 2) ? styles.row : styles.rowDark, wrap: false }, Array.isArray(row[1]) &&
            row[1].map(function (value) {
                var showBold = isOnlyOneNonEmptyString(row[1]);
                return renderCell(index, value, showBold);
            }))); }),
        React.createElement(View, { style: styles.line }), footer === null || footer === void 0 ? void 0 :
        footer.map(function (row, index) { return (React.createElement(View, { key: index + row[1][0], style: !(index % 2) ? styles.footerRow : styles.footerRowDark, wrap: false }, Array.isArray(row[1]) &&
            row[1].map(function (value) { return renderCell(index, value); }))); })));
};
