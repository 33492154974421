var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme } from 'src/Utils';
export var FormContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  border-radius: 8px;\n  border: 1px solid ", ";\n  min-width: 270px;\n  height: fit-content;\n  padding: 24px;\n  gap: 24px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  border-radius: 8px;\n  border: 1px solid ", ";\n  min-width: 270px;\n  height: fit-content;\n  padding: 24px;\n  gap: 24px;\n"])), theme.palette.grey[55]);
export var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 700;\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 700;\n"])), theme.palette.black[60], theme.typography.bold.ralewayBold);
export var Price = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 30px;\n  font-style: normal;\n  font-weight: 700;\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 30px;\n  font-style: normal;\n  font-weight: 700;\n"])), theme.palette.blue[5], theme.typography.bold.ralewayBold);
export var Text = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  &.extra {\n    color: ", ";\n    font-size: 14px;\n  }\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  &.extra {\n    color: ", ";\n    font-size: 14px;\n  }\n"])), theme.palette.grey[60], theme.typography.normal.raleway, theme.palette.grey[50]);
export var Column = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: ", ";\n  &.align-items-center {\n    align-items: center;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: ", ";\n  &.align-items-center {\n    align-items: center;\n  }\n"])), function (_a) {
    var gap = _a.gap;
    return gap;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
