var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M } from '../../Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 80%;\n  max-width: 450px;\n  background-color: ", ";\n  border-radius: 16px;\n  padding: 35px;\n  display: flex;\n  flex-direction: column;\n  @media (max-width: ", "px) {\n    width: 100%;\n    height: 100%;\n    padding: 30px;\n    max-width: inherit;\n  }\n"], ["\n  width: 80%;\n  max-width: 450px;\n  background-color: ", ";\n  border-radius: 16px;\n  padding: 35px;\n  display: flex;\n  flex-direction: column;\n  @media (max-width: ", "px) {\n    width: 100%;\n    height: 100%;\n    padding: 30px;\n    max-width: inherit;\n  }\n"])), theme.palette.admin.primary[50], M);
export var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 18px;\n  font-weight: 600;\n  font-family: ", ";\n  margin-bottom: 25px;\n"], ["\n  font-size: 18px;\n  font-weight: 600;\n  font-family: ", ";\n  margin-bottom: 25px;\n"])), theme.typography.OpenSans);
export var Label = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 14px;\n  font-family: ", ";\n  &.bold {\n    font-weight: 600;\n  }\n  &.small {\n    font-size: 12px;\n    font-family: ", ";\n  }\n"], ["\n  font-size: 14px;\n  font-family: ", ";\n  &.bold {\n    font-weight: 600;\n  }\n  &.small {\n    font-size: 12px;\n    font-family: ", ";\n  }\n"])), theme.typography.OpenSans, theme.typography.normal.raleway);
export var LabelContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: 8px;\n  display: flex;\n"], ["\n  margin-bottom: 8px;\n  display: flex;\n"])));
export var Center = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n"], ["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n"])));
export var RowContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  &.right {\n    justify-content: flex-end;\n    flex-direction: row;\n    @media (max-width: ", "px) {\n      margin: 20px;\n    }\n  }\n  &.close {\n    .MuiSvgIcon-root {\n      width: 15px;\n      cursor: pointer;\n    }\n  }\n  &.margin {\n    margin-top: 20px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  &.right {\n    justify-content: flex-end;\n    flex-direction: row;\n    @media (max-width: ", "px) {\n      margin: 20px;\n    }\n  }\n  &.close {\n    .MuiSvgIcon-root {\n      width: 15px;\n      cursor: pointer;\n    }\n  }\n  &.margin {\n    margin-top: 20px;\n  }\n"])), M);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
