import React from 'react';
import { useHistory } from 'react-router';
import { Container, ButtonsContainer, SocialMediaContainer, ImageContainer, Image, Button, Div, } from './styles';
var Footer = function (_a) {
    var items = _a.items, icons = _a.icons, text = _a.text;
    var history = useHistory();
    var handleClick = function (key) {
        window.scrollTo({
            top: 0,
        });
        if (history.location.hash !== '') {
            history.replace("/" + key);
        }
        else {
            history.push("/" + key);
        }
    };
    return (React.createElement(Container, null,
        React.createElement(ButtonsContainer, null, items.map(function (_a) {
            var key = _a.key, buttonText = _a.text;
            return (React.createElement(Button, { key: key, onClick: function () { return handleClick(key); } }, buttonText));
        })),
        React.createElement(Div, null, text),
        React.createElement(SocialMediaContainer, null, icons.map(function (_a) {
            var icon = _a.icon, url = _a.url;
            return (React.createElement(ImageContainer, { key: url, onClick: function () { return window.open("" + url); } },
                React.createElement(Image, { src: icon })));
        }))));
};
export default Footer;
