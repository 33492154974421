var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { Baths, Garages, Rooms } from 'src/assets/Images';
import { ROUTES } from 'src/Utils';
export var buildURL = function (globalState, page) {
    var queryString = buildParams(globalState, page);
    var baseUrl = ROUTES.real_estate_properties;
    var finalURL = baseUrl + "?" + queryString;
    return finalURL;
};
export var buildParams = function (globalState, page, isFetch) {
    if (isFetch === void 0) { isFetch = false; }
    var parameters = Object.entries(globalState).flatMap(function (_a) {
        var key = _a[0], value = _a[1];
        if (isFetch &&
            key === 'property_type' &&
            Array.isArray(value) &&
            value.includes('all')) {
            return [];
        }
        if (Array.isArray(value) && value.length > 0) {
            return key + "=" + value.map(encodeURIComponent).join(',');
        }
        else if ((value && typeof value !== 'object') ||
            typeof value === 'number') {
            return key + "=" + encodeURIComponent(String(value));
        }
        else {
            return [];
        }
    });
    var updatedParameters = parameters.map(function (el) {
        if (el.includes('price'))
            el = el.replace('.', '');
        return el;
    });
    if (page > 1)
        updatedParameters = __spreadArrays(updatedParameters, ["page=" + page]);
    return updatedParameters.join('&');
};
export var getURLValues = function (query, defaultValues) {
    var values = __assign({}, defaultValues);
    Object.keys(defaultValues).forEach(function (key) {
        var valueFromURL = query.get(key);
        if (valueFromURL !== null) {
            if (Array.isArray(values[key])) {
                values[key] = valueFromURL.split(',');
            }
            else {
                values[key] = valueFromURL;
            }
        }
    });
    var page = Number(query.get('page')) || 1;
    return { values: values, page: page };
};
export var defaultValues = {
    code: '',
    price_start: '',
    price_end: '',
    currency: 'UYU',
    operation_type: 'rent',
    property_type: [],
    bedrooms: [],
    bathrooms: [],
    garages: [],
    neighborhood: [],
    city: '',
    sort: 'recently',
};
export var getFilterNumbers = function (state) {
    var locationNumber = state.neighborhood.length + (state.city !== '' ? 1 : 0);
    var characteristicsNumber = ['bedrooms', 'bathrooms', 'garages'].reduce(function (count, prop) { return count + (state[prop] ? 1 : 0); }, 0);
    var priceNumber = ['price_start', 'price_end'].reduce(function (count, prop) { return count + (state[prop] ? 1 : 0); }, 0);
    return {
        property_type: state.property_type.length > 0 ? " (" + state.property_type.length + ")" : '',
        characteristics: characteristicsNumber > 0 ? " (" + characteristicsNumber + ")" : '',
        location: locationNumber > 0 ? " (" + locationNumber + ")" : '',
        price: priceNumber > 0 ? " (" + priceNumber + ")" : '',
        code: state.code ? " (1)" : '',
    };
};
export var NEIGHBORHOODS = [
    { value: 'centro', label: 'Centro' },
    { value: 'parque_rodo', label: 'Parque Rodó' },
    { value: 'la_blanqueada', label: 'La Blanqueada' },
    { value: 'Pocitos', label: 'Pocitos' },
    { value: 'palermo', label: 'Palermo' },
    { value: 'punta_carretas', label: 'Punta carretas' },
    { value: 'la_union', label: 'La Unión' },
    { value: 'sur', label: 'Sur' },
];
export var PROPERTY_TYPES = [
    { value: 'house', label: 'Casa' },
    { value: 'apartment', label: 'Apartamento' },
    { value: 'land', label: 'Terreno' },
    { value: 'office', label: 'Oficina' },
    { value: 'deposit', label: 'Depósito' },
    { value: 'retail', label: 'Local Comercial' },
];
export var CITIES = [
    { label: 'Montevideo', value: 'Montevideo' },
    { label: 'Canelones', value: 'canelones' },
    { label: 'Punta Del Este', value: 'pde' },
    { label: 'Salto', value: 'salto' },
];
export var SORT_OPTIONS = [
    { label: 'Publicados recientemente', value: 'recently' },
    { label: 'Menor precio', value: 'lower_price' },
    { label: 'Mayor precio', value: 'higher_price' },
];
export var priceFilter = {
    title: 'Precio',
    filters: [
        { property: 'price_start', title: 'Desde', placeholder: '-' },
        { property: 'price_end', title: 'Hasta', placeholder: '-' },
    ],
};
export var otherFilter = {
    title: 'Otros filtros',
    filters: [{ property: 'code', title: 'Código', placeholder: '-' }],
};
var propertyTypeFilters = PROPERTY_TYPES.map(function (op) { return (__assign(__assign({}, op), { property: 'property_type' })); });
export var typesFilter = {
    title: 'Otros filtros',
    filters: propertyTypeFilters,
};
export var locationFilter = {
    title: 'Filtrar por:',
    filters: [
        {
            property: 'city',
            title: 'Ubicación',
            placeholder: 'Ingresá una ubicación',
            options: CITIES,
        },
        {
            property: 'neighborhood',
            title: 'Barrio',
            placeholder: '-Seleccionar-',
            isMulti: true,
            options: NEIGHBORHOODS,
        },
    ],
};
export var characteristicsFilter = {
    title: 'Características',
    filters: [
        {
            property: 'bedrooms',
            title: 'Dormitorios',
            placeholder: '-Seleccionar-',
            hasStaticOptions: true,
            isMulti: true,
            options: [
                { value: '0', label: 'Monoambiente' },
                { value: '1', label: '1 dormitorio' },
                { value: '2', label: '2 dormitorios' },
                { value: '3', label: '3 dormitorios' },
                { value: '4', label: 'Más de 3 dormitorios' },
            ],
            img: Rooms,
        },
        {
            property: 'bathrooms',
            title: 'Baños',
            placeholder: '-Seleccionar-',
            hasStaticOptions: true,
            isMulti: true,
            options: [
                { value: '1', label: '1 baño' },
                { value: '2', label: '2 baños' },
                { value: '3', label: '3 baños' },
                { value: '4', label: 'Más de 3 baños' },
            ],
            img: Baths,
        },
        {
            property: 'garages',
            title: 'Garages',
            placeholder: '-Seleccionar-',
            hasStaticOptions: true,
            isMulti: true,
            options: [
                { value: '1', label: '1 garage' },
                { value: '2', label: '2 garages' },
                { value: '3', label: 'Más de 2 garages' },
            ],
            img: Garages,
        },
    ],
};
