import React from 'react';
import { Container, Card, Img, Information } from './styles';
import { useWindowWidth } from 'src/useWindowWidth';
import { SuccessGreenTick } from 'src/assets/Images';
import { CLOSE_WINDOW, PAYMENT_COMPLETED } from 'src/Utils/constants';
var Banred = function () {
    var windowWidth = useWindowWidth();
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            React.createElement(Card, { className: "" },
                React.createElement(Img, { src: SuccessGreenTick, className: "" }),
                React.createElement(Information, { className: "title" }, PAYMENT_COMPLETED),
                React.createElement(Information, null, CLOSE_WINDOW)))));
};
export default Banred;
