import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { ALL, BUILDINGS_KEY, BUILDING_API, CONTACT_KEY, getData } from 'src/Utils';
import MultiSelect from '../Common/MultiSelect';
import { InformationContainer, Label, Subject } from './styles';
import { Checkbox } from '@material-ui/core';
import Modal from '../Common/Modal/Modal';
var MessageInformation = function (_a) {
    var addressee = _a.addressee, input = _a.input, setInput = _a.setInput, label = _a.label, setAddressees = _a.setAddressees, addressees = _a.addressees, addresseesList = _a.addresseesList, _b = _a.isView, isView = _b === void 0 ? false : _b, setBuildings = _a.setBuildings, buildings = _a.buildings, checkAll = _a.checkAll, setCheckAll = _a.setCheckAll;
    var _c = useState([]), buildingsList = _c[0], setBuildingsList = _c[1];
    var _d = useState(false), modalOpen = _d[0], setModalOpen = _d[1];
    useQuery([BUILDING_API], function () { return getData("/buildings"); }, {
        onSuccess: function (data) {
            setBuildingsList(data.buildings.map(function (_a) {
                var name = _a.name, id = _a.id;
                return {
                    label: name,
                    value: id,
                };
            }));
        },
        retry: false,
        refetchOnWindowFocus: false,
        enabled: !isView,
    });
    var handleClick = function () {
        if (!checkAll) {
            setModalOpen(true);
        }
        setCheckAll(!checkAll);
    };
    return (React.createElement(InformationContainer, null,
        React.createElement(Label, null, label),
        addressee &&
            setAddressees &&
            buildingsList &&
            setBuildings &&
            addresseesList ? (React.createElement(React.Fragment, null,
            React.createElement(MultiSelect, { options: buildingsList, type: BUILDINGS_KEY, setSelectedOption: setBuildings, classNames: "message", inputLabel: "seleccionar edificios", initialValues: buildings, disabled: checkAll || isView, isMulti: false }),
            React.createElement(MultiSelect, { options: addresseesList, type: CONTACT_KEY, setSelectedOption: setAddressees, classNames: "message", inputLabel: "seleccionar contactos", initialValues: addressees, disabled: checkAll || isView }),
            React.createElement(Checkbox, { color: 'default', checked: checkAll, onClick: handleClick, disabled: isView }),
            React.createElement(Label, { className: 'checkBox' }, ALL))) : (React.createElement(Subject, { value: input, onChange: function (e) { return setInput && setInput(e.target.value); }, disabled: isView })),
        React.createElement(Modal, { isOpen: modalOpen, setIsOpen: setModalOpen, isMessageAll: true })));
};
export default MessageInformation;
