import React from 'react';
import { Modal as ModalComponent } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Container, Label, Center, RowContainer, Text, ContactText, ButtonContainer, } from './styles';
import { EMAIL, PHONE, CONTACTS, EDIT } from 'src/Utils';
import { BackArrow } from 'src/assets/Images';
import { AdminButton } from '../AdminButton';
import { MODIFY_CONTACT_TEXT } from 'src/Utils/constants';
var Modal = function (_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, _b = _a.data, first = _b.first, cell = _b.cell, mail = _b.mail;
    var history = useHistory();
    return (React.createElement(ModalComponent, { open: isOpen },
        React.createElement(Center, null,
            React.createElement(Container, null,
                React.createElement(RowContainer, { onClick: function () { return setIsOpen(false); }, className: "right close" },
                    React.createElement("img", { src: BackArrow }),
                    React.createElement(ContactText, null, CONTACTS)),
                React.createElement(Label, null, first),
                React.createElement(RowContainer, { className: "card" },
                    React.createElement(Text, { className: "title" }, EMAIL),
                    React.createElement(Text, null, mail)),
                React.createElement(RowContainer, { className: "card" },
                    React.createElement(Text, { className: "title" }, PHONE),
                    React.createElement(Text, null, cell)),
                React.createElement(ButtonContainer, null,
                    React.createElement(AdminButton, { type: "button", onClick: function () { return history.push(MODIFY_CONTACT_TEXT); }, className: "height" }, EDIT))))));
};
export default Modal;
