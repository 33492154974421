import React from 'react';
import { Container, Title } from '../styles';
import { AdminLayout } from 'src/Layouts';
import { NEW_MESSAGE } from 'src/Utils';
import { NewMessageForm } from 'src/Components';
var NewMessage = function () {
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, { className: "non-margin" }, NEW_MESSAGE),
            React.createElement(NewMessageForm, null))));
};
export default NewMessage;
