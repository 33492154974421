import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import { COPYRIGHT } from 'src/Utils/constants';
import { BackgroundLogo, InstagramPng, WebPng } from 'src/assets/Images';
import styles from './styles';
export var PDFFooter = function () { return (React.createElement(View, { style: styles.footerContainer },
    React.createElement(View, { style: styles.watermarkLogo, fixed: true },
        React.createElement(Image, { src: BackgroundLogo, style: { height: 70 } })),
    React.createElement(View, { style: styles.footer },
        React.createElement(Text, null, COPYRIGHT),
        React.createElement(View, { style: styles.footerLogos },
            React.createElement(Image, { src: InstagramPng, style: { height: '8', marginRight: 8 } }),
            React.createElement(Image, { src: WebPng, style: { height: '8' } }))))); };
