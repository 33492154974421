import React from 'react';
import { Container, Title, Description, Image, ImageContainer } from './styles';
var ContactItem = function (_a) {
    var _b = _a.details, title = _b.title, first = _b.first, second = _b.second, icon = _b.icon, isContactPage = _a.isContactPage;
    return (React.createElement(Container, { className: isContactPage ? 'page' : '' },
        React.createElement(ImageContainer, null,
            React.createElement(Image, { src: icon })),
        React.createElement(Title, null, title),
        React.createElement(Description, null, first),
        React.createElement(Description, null, second)));
};
export default ContactItem;
