import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import moment from 'moment';
import { AdminLayout } from 'src/Layouts';
import { Container, Title, TableContainer, UploadButton, MessageContainer, } from '../styles';
import { includesWord } from 'src/Utils/utils';
import Table from 'src/Components/GenericTable';
import { M, FIRST, SUBJECT, STATE, NEW_MESSAGE_TEXT, NEW_MESSAGE, MESSAGES, DATE_FORMAT_MOMENT, DRAFT, SENT, MESSAGE_TEXT, } from 'src/Utils/constants';
import { Filter, DeleteModal } from 'src/Components/Common';
import { useWindowWidth } from 'src/useWindowWidth';
import List from 'src/Components/Common/List/List';
import { NewMessage } from 'src/assets/Images';
import { getData } from 'src/Utils/api';
import { deleteMessage, sendMessage } from './services';
function createData(first, state, dots, id) {
    return {
        first: first,
        state: state === 'created' ? (state = DRAFT) : (state = SENT),
        dots: dots,
        id: id,
    };
}
var headCells = [
    { id: 'first', numeric: true, disablePadding: false, label: SUBJECT },
    { id: 'state', numeric: true, disablePadding: false, label: STATE },
    { id: 'dots', numeric: true, disablePadding: false, label: '' },
];
var WIDTHS = ['40%', '40%', '20%'];
var MessagesPage = function () {
    var _a = useState(''), searchedItem = _a[0], setSearchedItem = _a[1];
    var _b = useState(), selected = _b[0], setSelected = _b[1];
    var _c = useState(false), isDeleteModalOpen = _c[0], setIsDeleteModalOpen = _c[1];
    var _d = useState(false), refetch = _d[0], setRefetch = _d[1];
    var _e = useState(FIRST), orderBy = _e[0], setOrderBy = _e[1];
    var _f = useState(), startDate = _f[0], setStartDate = _f[1];
    var _g = useState(), endDate = _g[0], setEndDate = _g[1];
    var _h = useState([]), rows = _h[0], setRows = _h[1];
    var windowWidth = useWindowWidth();
    var isSmallScreen = M >= windowWidth;
    var history = useHistory();
    var mutateDeleteMessage = useMutation(deleteMessage, {
        onSuccess: function () {
            setIsDeleteModalOpen(false);
            setRefetch(!refetch);
        },
    })[0];
    useQuery(['getMessages', startDate, endDate, refetch], function () {
        return getData("/messages", {
            start_date: startDate
                ? moment(startDate).format(DATE_FORMAT_MOMENT)
                : null,
            end_date: endDate ? moment(endDate).format(DATE_FORMAT_MOMENT) : null,
        });
    }, {
        onSuccess: function (data) {
            setRows(data.messages.map(function (_a) {
                var subject = _a.subject, status = _a.status, id = _a.id;
                return createData(subject, status, '', id);
            }));
        },
        refetchOnWindowFocus: false,
    });
    var mutateSendMessage = useMutation(sendMessage, {
        onSuccess: function () { return setRefetch(!refetch); },
    })[0];
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, null, MESSAGES + " " + (!isSmallScreen ? "(" + rows.length + ")" : '')),
            React.createElement(TableContainer, { className: "contact" },
                React.createElement(Filter, { searchedItem: searchedItem, setSearchedItem: setSearchedItem, setOrderBy: setOrderBy, startDate: startDate, setStartDate: setStartDate, endDate: endDate, setEndDate: setEndDate, orderBy: orderBy, className: "calendar contact", hasSearch: true, isMessage: true }),
                isSmallScreen ? (React.createElement(List, { data: rows, searchedItem: searchedItem })) : (React.createElement(Table, { rows: rows.filter(function (row) { return includesWord(row.first, searchedItem); }), headCells: headCells, handleButtonClick: function () { return history.push(NEW_MESSAGE_TEXT); }, modalButton: NEW_MESSAGE, handleDelete: function () { return setIsDeleteModalOpen(true); }, hasDots: true, widths: WIDTHS, className: "small", topIndex: 5, isMessage: true, submit: mutateSendMessage, setSelected: setSelected }))),
            React.createElement(DeleteModal, { isOpen: isDeleteModalOpen, setIsOpen: setIsDeleteModalOpen, onDelete: function () { return selected && mutateDeleteMessage(selected.id); }, itemLabel: MESSAGE_TEXT + " " + (selected ? selected.title : '') }),
            isSmallScreen && (React.createElement(MessageContainer, null,
                React.createElement(UploadButton, { src: NewMessage, onClick: function () { return history.push(NEW_MESSAGE_TEXT); } }))))));
};
export default MessagesPage;
