var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme } from 'src/Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  gap: 80px;\n  .row {\n    flex-direction: row;\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  gap: 80px;\n  .row {\n    flex-direction: row;\n  }\n"])));
export var Div = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin-top: 10px;\n  .bottom-row {\n    flex-direction: row;\n    justify-content: center;\n    gap: 34px;\n  }\n  .bottom-arrows {\n    flex-direction: row;\n    justify-content: space-between;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin-top: 10px;\n  .bottom-row {\n    flex-direction: row;\n    justify-content: center;\n    gap: 34px;\n  }\n  .bottom-arrows {\n    flex-direction: row;\n    justify-content: space-between;\n  }\n"])));
export var Title = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 24px;\n  color: ", ";\n  padding-bottom: 20px;\n  &.number {\n    font-size: 12px;\n    color: ", ";\n    padding: 0;\n  }\n"], ["\n  font-size: 24px;\n  color: ", ";\n  padding-bottom: 20px;\n  &.number {\n    font-size: 12px;\n    color: ", ";\n    padding: 0;\n  }\n"])), theme.palette.admin.primary[75], theme.palette.black[80]);
export var Total = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  box-sizing: border-box;\n  height: 60px;\n  padding-top: 18px;\n"], ["\n  box-sizing: border-box;\n  height: 60px;\n  padding-top: 18px;\n"])));
export var TotalText = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 0;\n  font-size: 14px;\n"], ["\n  margin: 0;\n  font-size: 14px;\n"])));
export var ChartContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  box-sizing: border-box;\n  width: 550px;\n  height: 350px;\n  padding: 23px;\n  padding-top: 10px;\n  background: ", ";\n  border-radius: 21px;\n"], ["\n  box-sizing: border-box;\n  width: 550px;\n  height: 350px;\n  padding: 23px;\n  padding-top: 10px;\n  background: ", ";\n  border-radius: 21px;\n"])), theme.palette.white[100]);
export var ApartmentsContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 550px;\n  display: grid;\n  grid-template-rows: 1fr 1fr;\n  grid-template-columns: 1fr 1fr;\n  gap: 40px;\n"], ["\n  width: 550px;\n  display: grid;\n  grid-template-rows: 1fr 1fr;\n  grid-template-columns: 1fr 1fr;\n  gap: 40px;\n"])));
export var InformationContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  height: 72px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 9px;\n\n  background: ", ";\n  border-radius: 21px;\n"], ["\n  height: 72px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 9px;\n\n  background: ", ";\n  border-radius: 21px;\n"])), theme.palette.white[100]);
export var Number = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-weight: 600;\n  font-size: 36px;\n  line-height: 42px;\n  color: ", ";\n"], ["\n  font-weight: 600;\n  font-size: 36px;\n  line-height: 42px;\n  color: ", ";\n"])), theme.palette.admin.primary[80]);
export var ColumnContainer = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 6px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 6px;\n"])));
export var NumberContainer = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"])));
export var Dot = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  height: 11px;\n  width: 11px;\n  border-radius: 50%;\n  margin-right: 7px;\n"], ["\n  height: 11px;\n  width: 11px;\n  border-radius: 50%;\n  margin-right: 7px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
