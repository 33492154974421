var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M } from '../../../Utils';
export var DateContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 33%;\n  align-items: center;\n  width: 180px;\n  &.right {\n    justify-content: flex-end;\n  }\n  @media (max-width: ", "px) {\n    display: none;\n  }\n"], ["\n  display: flex;\n  width: 33%;\n  align-items: center;\n  width: 180px;\n  &.right {\n    justify-content: flex-end;\n  }\n  @media (max-width: ", "px) {\n    display: none;\n  }\n"])), M);
export var Label = styled.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 14px;\n  margin-right: 8px;\n  font-family: ", ";\n"], ["\n  font-size: 14px;\n  margin-right: 8px;\n  font-family: ", ";\n"])), theme.typography.bold.ralewayBold);
export var Div = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  width: 110px;\n"], ["\n  position: relative;\n  width: 110px;\n"])));
export var CalendarContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: white;\n  border: 0.5px solid #c4c4c4;\n  box-sizing: border-box;\n  border-radius: 3px;\n  padding: 5px;\n  font-size: 14px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n"], ["\n  background-color: white;\n  border: 0.5px solid #c4c4c4;\n  box-sizing: border-box;\n  border-radius: 3px;\n  padding: 5px;\n  font-size: 14px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n"])));
export var Img = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  cursor: pointer;\n  &.calendar {\n    margin-left: 3px;\n    width: 14px;\n  }\n  &.filter {\n    width: 15px;\n  }\n"], ["\n  cursor: pointer;\n  &.calendar {\n    margin-left: 3px;\n    width: 14px;\n  }\n  &.filter {\n    width: 15px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
