import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableContainer, TablePagination, IconButton, } from '@material-ui/core';
import { BeatLoader } from 'react-spinners';
import PaymentsTableHeader from './PaymentsTableHeader';
import PaymentsTableRow from './PaymentsTableRow';
import { theme } from 'src/Utils';
import { ArrowsContainer, PaginationLabel, StyledPaper, Div } from 'src/Components/Common/Table/styles';
import { ArrowRight, ArrowLeft } from '../../../assets/Images';
var ROWS_PER_PAGE = 15;
var PaymentsTableComponent = function (_a) {
    var rows = _a.rows, headCells = _a.headCells, widths = _a.widths, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b, onPageChanged = _a.onPageChange, totalPages = _a.totalPages, paginated = _a.paginated, currentPage = _a.page;
    var _c = useState(0), page = _c[0], setPage = _c[1];
    useEffect(function () {
        if (!paginated) {
            setPage(0);
        }
    }, [rows]);
    var handleChangePage = function (event, newPage) {
        if (onPageChanged)
            onPageChanged(newPage);
        setPage(newPage);
    };
    if (currentPage == 1 && page != 0) {
        setPage(0);
    }
    function TablePaginationActions(props) {
        var count = props.count, page = props.page, rowsPerPage = props.rowsPerPage, onPageChange = props.onPageChange;
        var handleBackButtonClick = function (event) {
            onPageChange(event, page - 1);
        };
        var handleNextButtonClick = function (event) {
            onPageChange(event, page + 1);
        };
        var numberPage = paginated ? currentPage : page + 1;
        var maxPage = rows.length == 0 ? 0 : totalPages || Math.ceil(count / rowsPerPage);
        return (React.createElement(Div, { className: 'centered' },
            React.createElement(ArrowsContainer, null,
                React.createElement(IconButton, { onClick: handleBackButtonClick, disabled: page === 0, "aria-label": "previous page" },
                    React.createElement("img", { src: ArrowLeft, alt: "P\u00E1gina previa" })),
                React.createElement(PaginationLabel, null, numberPage + "/" + maxPage),
                React.createElement(IconButton, { onClick: handleNextButtonClick, disabled: numberPage >= maxPage, "aria-label": "next page" },
                    React.createElement("img", { src: ArrowRight, alt: "P\u00E1gina siguiente" })))));
    }
    return (React.createElement(StyledPaper, null,
        React.createElement(TableContainer, null,
            React.createElement(Table, { "aria-labelledby": "tableTitle", size: 'medium', "aria-label": "enhanced table" },
                React.createElement(PaymentsTableHeader, { fields: headCells, widths: widths }),
                isLoading ? (React.createElement(BeatLoader, { size: "8px", color: theme.palette.black[100] })) : (React.createElement(TableBody, null, rows.map(function (row) { return (React.createElement(PaymentsTableRow, { data: row, key: row.receipt_number, widths: widths })); }))))),
        React.createElement(TablePagination, { component: "div", labelRowsPerPage: "", count: rows.length, rowsPerPage: ROWS_PER_PAGE, page: page, onPageChange: handleChangePage, ActionsComponent: TablePaginationActions })));
};
export default PaymentsTableComponent;
