var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { List, Popover } from '@material-ui/core';
import { theme } from '../../../Utils';
export var StyledPopover = styled(Popover)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-family: ", ";\n  margin-top: 5px;\n"], ["\n  font-family: ", ";\n  margin-top: 5px;\n"])), theme.typography.normal.raleway);
export var StyledList = styled(List)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 270px;\n  min-width: 200px;\n  border-radius: 3px;\n  &.width {\n    min-width: 200px;\n    width: 100%;\n  }\n"], ["\n  width: 270px;\n  min-width: 200px;\n  border-radius: 3px;\n  &.width {\n    min-width: 200px;\n    width: 100%;\n  }\n"])));
export var Label = styled.label(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: 13px;\n  width: 90%;\n  max-width: 90%;\n  padding: 0px 10px;\n"], ["\n  font-family: ", ";\n  font-size: 13px;\n  width: 90%;\n  max-width: 90%;\n  padding: 0px 10px;\n"])), theme.typography.bold.ralewayBold);
export var DateContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  width: 90%;\n  align-items: center;\n  margin: 5px;\n  align-items: center;\n"], ["\n  display: flex;\n  width: 90%;\n  align-items: center;\n  margin: 5px;\n  align-items: center;\n"])));
export var Div = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n  .react-datepicker__tab-loop {\n    position: fixed;\n    margin: -30px;\n    z-index: 10;\n  }\n  .react-datepicker {\n    font-size: 0.75em;\n  }\n  .react-datepicker__header {\n    padding-top: 0.75em;\n  }\n  .react-datepicker__month {\n    margin: 0.4em 0.75em;\n  }\n  .react-datepicker__day-name,\n  .react-datepicker__day {\n    width: 1.9em;\n    line-height: 1.9em;\n    margin: 0.166em;\n  }\n  .react-datepicker__current-month {\n    font-size: 0.75em;\n  }\n  .react-datepicker__navigation {\n    top: 0.75em;\n    line-height: 1.7em;\n  }\n  .react-datepicker__navigation--previous {\n    left: 0.75em;\n  }\n  .react-datepicker__navigation--next {\n    right: 0.5em;\n  }\n"], ["\n  position: relative;\n  .react-datepicker__tab-loop {\n    position: fixed;\n    margin: -30px;\n    z-index: 10;\n  }\n  .react-datepicker {\n    font-size: 0.75em;\n  }\n  .react-datepicker__header {\n    padding-top: 0.75em;\n  }\n  .react-datepicker__month {\n    margin: 0.4em 0.75em;\n  }\n  .react-datepicker__day-name,\n  .react-datepicker__day {\n    width: 1.9em;\n    line-height: 1.9em;\n    margin: 0.166em;\n  }\n  .react-datepicker__current-month {\n    font-size: 0.75em;\n  }\n  .react-datepicker__navigation {\n    top: 0.75em;\n    line-height: 1.7em;\n  }\n  .react-datepicker__navigation--previous {\n    left: 0.75em;\n  }\n  .react-datepicker__navigation--next {\n    right: 0.5em;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
