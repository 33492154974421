var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, S } from 'src/Utils';
export var InputContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  height: 100px;\n  display: flex;\n  flex-direction: column;\n  width: ", ";\n  &.height {\n    min-height: 50px;\n  }\n  &.width {\n    width: 140px;\n  }\n  &.row {\n    flex-direction: row;\n  }\n  .radio {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    justify-content: flex-end;\n  }\n  &.margin {\n    margin-bottom: 20px;\n  }\n  &.code {\n    height: 100%;\n  }\n  @media (max-width: ", "px) {\n    width: 100%;\n  }\n"], ["\n  position: relative;\n  height: 100px;\n  display: flex;\n  flex-direction: column;\n  width: ", ";\n  &.height {\n    min-height: 50px;\n  }\n  &.width {\n    width: 140px;\n  }\n  &.row {\n    flex-direction: row;\n  }\n  .radio {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    justify-content: flex-end;\n  }\n  &.margin {\n    margin-bottom: 20px;\n  }\n  &.code {\n    height: 100%;\n  }\n  @media (max-width: ", "px) {\n    width: 100%;\n  }\n"])), function (_a) {
    var width = _a.width;
    return width || '100%';
}, S);
export var Label = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 14px;\n  margin-bottom: 5px;\n  color: ", ";\n  width: 100%;\n  &.white {\n    font-size: 18px;\n    color: ", ";\n    @media (max-width: ", "px) {\n      font-size: 14px;\n    }\n  }\n  &.new-code {\n    margin: 0;\n  }\n"], ["\n  font-size: 14px;\n  margin-bottom: 5px;\n  color: ", ";\n  width: 100%;\n  &.white {\n    font-size: 18px;\n    color: ", ";\n    @media (max-width: ", "px) {\n      font-size: 14px;\n    }\n  }\n  &.new-code {\n    margin: 0;\n  }\n"])), theme.palette.black[100], theme.palette.white[100], S);
export var Div = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  font-size: 14px;\n  font-family: ", ";\n  &.row {\n    flex-direction: row;\n  }\n  &.column {\n    flex-direction: column;\n  }\n  &.height {\n    height: 20px;\n    align-self: center;\n  }\n  &.counter {\n    height: 43px;\n    background-color: white;\n    border-radius: 9px;\n    padding: 0px 8px;\n  }\n  &.spaced {\n    margin-right: 8px;\n    width: 100%;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  font-size: 14px;\n  font-family: ", ";\n  &.row {\n    flex-direction: row;\n  }\n  &.column {\n    flex-direction: column;\n  }\n  &.height {\n    height: 20px;\n    align-self: center;\n  }\n  &.counter {\n    height: 43px;\n    background-color: white;\n    border-radius: 9px;\n    padding: 0px 8px;\n  }\n  &.spaced {\n    margin-right: 8px;\n    width: 100%;\n  }\n"])), theme.typography.normal.raleway);
export var Error = styled.label(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  align-self: flex-start;\n  font-size: 12px;\n  margin-top: 10px;\n  height: 20px;\n  @media (max-width: ", "px) {\n    align-self: center;\n    align-items: center;\n  }\n"], ["\n  color: ", ";\n  align-self: flex-start;\n  font-size: 12px;\n  margin-top: 10px;\n  height: 20px;\n  @media (max-width: ", "px) {\n    align-self: center;\n    align-items: center;\n  }\n"])), theme.palette.error[50], S);
export var Exit = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  right: -16px;\n  top: 4px;\n  cursor: pointer;\n"], ["\n  position: absolute;\n  right: -16px;\n  top: 4px;\n  cursor: pointer;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
