import React from 'react';
import { Container, Label, RowContainer, Title, Value, TitleContainer, Span, } from './styles';
export var Card = function (_a) {
    var values = _a.values, title = _a.title, span = _a.span, classname = _a.classname;
    return (React.createElement(Container, { className: classname },
        React.createElement(TitleContainer, null,
            React.createElement(Title, null, title),
            !!span && React.createElement(Span, null, span)),
        values.map(function (value, index) { return (React.createElement(RowContainer, { key: value.label + " - " + index },
            React.createElement(Label, null,
                value.label,
                ":"),
            React.createElement(Value, null, value.value))); })));
};
