var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect, useState } from 'react';
import { DownloadContainer } from '../../Common/Table/TableRow/styles';
import { CONTACTS, DOWNLOAD } from 'src/Utils/constants';
import { DownloadArrow } from 'src/assets/Images';
import { downloadCSV } from 'src/Utils/utils';
import useBuildingContacts from 'src/Utils/useBuildingContacts';
import styled from '@emotion/styled';
var DownloadButton = styled(DownloadContainer)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  justify-content: flex-start\n"], ["\n  justify-content: flex-start\n"])));
var BuildingContacts = function (_a) {
    var buildingId = _a.buildingId;
    var _b = useState(false), enabled = _b[0], setEnabled = _b[1];
    var data = useBuildingContacts(buildingId, enabled).data;
    var url = data === null || data === void 0 ? void 0 : data.csv;
    useEffect(function () {
        if (url) {
            downloadCSV(url, "" + CONTACTS);
        }
    }, [url]);
    return (React.createElement(DownloadButton, { onClick: function () { return setEnabled(true); } },
        DOWNLOAD,
        React.createElement("img", { src: DownloadArrow, alt: DOWNLOAD })));
};
export default BuildingContacts;
var templateObject_1;
