var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from 'react';
import { PlusIcon } from 'src/assets/Images';
import { ORDERS_OF_THE_DAY, ORDERS_OF_THE_DAY_KEY } from 'src/Utils/constants';
import InputComponent from '../Common/InputComponent';
import { OrdersContainer, Title, TitleDiv, DivOrders, DivGradient, PlusImg, } from './styles';
var OrdersComponent = function (_a) {
    var data = _a.data, setSelected = _a.setSelected, errors = _a.errors;
    return (React.createElement(React.Fragment, null,
        React.createElement(OrdersContainer, null,
            React.createElement(TitleDiv, null,
                React.createElement(Title, null, ORDERS_OF_THE_DAY),
                React.createElement(PlusImg, { src: PlusIcon, onClick: function () {
                        setSelected(__spreadArrays(data, [{ number: data.length + 1, value: '' }]));
                    } })),
            React.createElement(DivOrders, { className: data.length > 5 ? 'scrollbar' : '' }, data.map(function (_a) {
                var number = _a.number, value = _a.value;
                return (React.createElement(InputComponent, { setSelected: setSelected, dataSelected: data, isOrder: true, index: number, key: number, type: ORDERS_OF_THE_DAY_KEY, data: value, error: number === 1 ? errors : '', label: "Orden " + number, onChangeFunction: function (e) {
                        e.persist();
                        setSelected(__spreadArrays(data).map(function (object) {
                            if (object.number === number) {
                                return __assign(__assign({}, object), { value: e.target.value });
                            }
                            else
                                return object;
                        }));
                    }, width: '90%' }));
            }))),
        data.length > 5 && React.createElement(DivGradient, null)));
};
export default OrdersComponent;
