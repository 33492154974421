import React from 'react';
import Html from 'react-pdf-html';
import { CHARACTERISTICS, htmlEntities } from 'src/Utils';
import { Column, Title, HtmlDescriptionStylesheet } from './styles';
export var DetailInfoPDF = function (_a) {
    var description = _a.description;
    return (React.createElement(Column, null,
        React.createElement(Title, null, CHARACTERISTICS),
        React.createElement(Html, { stylesheet: HtmlDescriptionStylesheet }, htmlEntities(description))));
};
export default DetailInfoPDF;
