var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M } from '../../../Utils';
export var TitleContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
export var TopContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n"])));
export var Title = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 15px;\n  cursor: pointer;\n  font-family: ", ";\n  color: ", ";\n  @media (max-width: ", "px) {\n    font-size: 16px;\n    font-family: ", ";\n    color: ", ";\n  }\n"], ["\n  font-size: 15px;\n  cursor: pointer;\n  font-family: ", ";\n  color: ", ";\n  @media (max-width: ", "px) {\n    font-size: 16px;\n    font-family: ", ";\n    color: ", ";\n  }\n"])), theme.typography.bold.ralewayBold, theme.palette.admin.primary[75], M, theme.typography.OpenSans, theme.palette.black[100]);
export var StyledArrow = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 28px;\n  height: 14px;\n  cursor: pointer;\n"], ["\n  width: 28px;\n  height: 14px;\n  cursor: pointer;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
