import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { AdminLayout } from 'src/Layouts';
import { Container, Title } from '../styles';
import { NAME, EMAIL, PHONE_KEY, NAME_KEY } from 'src/Utils';
import { ROUTES, EMAIL_KEY, CREATE_CONTACT, BUILDING, TOWER, TOWER_KEY, APARTMENT, APARTMENT_KEY, RELATIONSHIP, RELATIONSHIP_KEY, OWNER_TEXT, TENANT, BOTH, CREATE, BUILDING_KEY, LAST_NAME_KEY, LAST_NAME, PHONE, TENAT_KEY, BOTH_KEY, BACK_ERROR, EMAIL_ERROR, APARTMENT_ERROR, ADDRESS_KEY, ADDRESS, PROPRIETARY_KEY, } from 'src/Utils/constants';
import { NewContactForm } from 'src/Components';
import { deleteApartment, submitDataForm } from './duck';
import { getData } from 'src/Utils/api';
var personalData = [
    { key: NAME_KEY, label: NAME, value: '' },
    { key: LAST_NAME_KEY, label: LAST_NAME, value: '' },
    { key: EMAIL_KEY, label: EMAIL, value: '' },
    { key: PHONE_KEY, label: PHONE, value: '' },
    { key: ADDRESS_KEY, label: ADDRESS, value: '' },
];
var EditInfo = function () {
    var history = useHistory();
    var _a = useState(''), backError = _a[0], setBackError = _a[1];
    var id = useParams().id;
    var _b = useState(), buildingList = _b[0], setBuildingList = _b[1];
    useQuery("getBuildingsList", function () { return getData("/buildings"); }, {
        onSuccess: function (data) {
            var buildings = data.buildings.map(function (_a) {
                var name = _a.name, id = _a.id;
                return ({
                    label: name,
                    value: id,
                });
            });
            buildings = buildings.sort(function (a, b) {
                return a.label.localeCompare(b.label, 'es');
            });
            setBuildingList(buildings);
        },
        refetchOnWindowFocus: false,
    });
    var mutateDeleteApartment = useMutation(deleteApartment)[0];
    var deleteApartmentBuilding = function (idApartment) {
        mutateDeleteApartment({ idContact: id, idApartment: idApartment });
    };
    var buildingData = [
        {
            key: BUILDING_KEY,
            label: BUILDING,
            data: buildingList,
            value: {
                label: '',
                value: '',
            },
        },
        {
            key: TOWER_KEY,
            label: TOWER,
            data: [
                { label: 1, value: 1 },
                { label: 2, value: 2 },
            ],
            value: {
                label: '',
                value: '',
            },
        },
        {
            key: APARTMENT_KEY,
            label: APARTMENT,
            value: {
                label: '',
                value: '',
            },
        },
        {
            key: RELATIONSHIP_KEY,
            label: RELATIONSHIP,
            data: [
                { label: OWNER_TEXT, value: PROPRIETARY_KEY },
                { label: TENANT, value: TENAT_KEY },
                { label: BOTH, value: BOTH_KEY },
            ],
            value: {
                label: '',
                value: '',
            },
        },
    ];
    var onCancel = function () { return history.push(ROUTES.contacts); };
    var mutateFormReceipt = useMutation(submitDataForm, {
        onSuccess: function () {
            history.push(ROUTES.contacts);
        },
        onError: function (error) {
            var _a, _b;
            setBackError(((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.errors) ? error.response.data.errors.email
                ? EMAIL_ERROR
                : error.response.data.errors.apartment
                    ? APARTMENT_ERROR
                    : BACK_ERROR
                : BACK_ERROR);
        },
    })[0];
    var submitForm = function (email, first_name, last_name, mobile, apartments, address) {
        return mutateFormReceipt({
            email: email,
            first_name: first_name,
            last_name: last_name,
            mobile: mobile,
            apartments: apartments,
            address: address,
        });
    };
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, { className: "non-margin" }, CREATE_CONTACT),
            buildingList && (React.createElement(NewContactForm, { inputs: personalData, buildingInputs: buildingData, onSubmit: submitForm, buttonLabel: CREATE, isLoading: false, backError: backError, onCancel: onCancel, deleteApartmentBuilding: deleteApartmentBuilding })))));
};
export default EditInfo;
