var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { defaultValues } from '../CreateReservation/ReservationForm/CreateReservationPage';
import EditReservation from './EditReservation';
var EditReservationPage = function () {
    var methods = useForm({ defaultValues: defaultValues });
    var register = methods.register;
    useEffect(function () {
        Object.keys(defaultValues).forEach(function (field) { return register(field); });
    }, [register]);
    return (React.createElement(FormProvider, __assign({}, methods),
        React.createElement(EditReservation, null)));
};
export default EditReservationPage;
