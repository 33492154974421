import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { RealEstateMultiSelect } from "src/Components/Common/RealEstateMultiSelect";
import { ORDER_BY_TEXT_ONLY } from "src/Utils";
import { SORT_OPTIONS } from "../utils";
import { SelectContainer, optionsCustomStyles } from './styles';
import Option from "./Option";
var PROPERTY = 'sort';
var SortSelect = function () {
    var _a = useState(), selectedOption = _a[0], setSelectedOption = _a[1];
    var _b = useFormContext(), setValue = _b.setValue, watch = _b.watch;
    var watchedValue = watch(PROPERTY);
    useEffect(function () {
        if (watchedValue !== selectedOption) {
            var newSelectedOption = SORT_OPTIONS.find(function (option) { return option.value === watchedValue; });
            setSelectedOption(newSelectedOption);
        }
    }, [watchedValue]);
    useEffect(function () {
        if (selectedOption == undefined || selectedOption == null)
            return;
        if (selectedOption !== watchedValue) {
            setValue(PROPERTY, selectedOption.value, { shouldDirty: true });
        }
    }, [selectedOption]);
    var isLoading = watchedValue !== (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.value);
    if (isLoading)
        return null;
    return (React.createElement(SelectContainer, null,
        React.createElement(RealEstateMultiSelect, { initialValues: selectedOption, options: SORT_OPTIONS, type: PROPERTY, setSelectedOption: setSelectedOption, isMulti: false, inputLabel: ORDER_BY_TEXT_ONLY, customStyles: optionsCustomStyles, defaultValueContainer: true, optionField: Option })));
};
export default SortSelect;
