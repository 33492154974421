import React from 'react';
import { Input } from '../Input';
import { inputs } from '../utils';
import { Title, Column } from '../styles';
export var BottomForm = function () { return (React.createElement(Column, { gap: "16px" },
    React.createElement(Title, null, "Contactanos"),
    React.createElement(Column, { gap: "24px" }, inputs.map(function (_a) {
        var label = _a.label, placeholder = _a.placeholder, type = _a.type, options = _a.options, id = _a.id;
        return (React.createElement(Input, { label: label, placeholder: placeholder, type: type, id: id, key: type + "-" + label, options: options }));
    })))); };
