import React from 'react';
import { AlertContainer, Days } from './styles';
import { NotificationYellow, NotificationRed, NotificationBlue, } from 'src/assets/Images';
var Alert = function (_a) {
    var source = _a.source, message = _a.message, _b = _a.newAlert, newAlert = _b === void 0 ? false : _b, timeAgo = _a.timeAgo;
    var type;
    var Icon;
    if (source === 'common_expenses_receipt_expired') {
        type = 'important';
        Icon = NotificationRed;
    }
    else if (source === 'common_expenses_receipt_pending') {
        type = 'warning';
        Icon = NotificationYellow;
    }
    else {
        type = '';
        Icon = NotificationBlue;
    }
    return (React.createElement(AlertContainer, { className: type + " " + (newAlert ? 'new' : '') },
        newAlert && React.createElement("img", { src: Icon }),
        message,
        React.createElement(Days, null,
            React.createElement("span", null, timeAgo))));
};
export default Alert;
