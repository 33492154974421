var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    selectedBuilding: undefined,
    selectedTower: undefined,
    selectedApartment: undefined,
    startDate: undefined,
    endDate: undefined,
    formSelected: false,
};
export var tableFiltersSlice = createSlice({
    name: 'tableFilters',
    initialState: initialState,
    reducers: {
        setSelectedBuilding: function (state, action) {
            state.selectedBuilding = action.payload;
        },
        setSelectedTower: function (state, action) {
            state.selectedTower = action.payload;
        },
        setSelectedApartment: function (state, action) {
            state.selectedApartment = action.payload;
        },
        setSelectedStartDate: function (state, action) {
            state.startDate = action.payload;
        },
        setSelectedEndDate: function (state, action) {
            state.endDate = action.payload;
        },
        setFormSelected: function (state, action) {
            state.formSelected = action.payload;
        },
    },
});
export var setSelectedBuilding = (_a = tableFiltersSlice.actions, _a.setSelectedBuilding), setSelectedTower = _a.setSelectedTower, setSelectedApartment = _a.setSelectedApartment, setSelectedStartDate = _a.setSelectedStartDate, setSelectedEndDate = _a.setSelectedEndDate, setFormSelected = _a.setFormSelected;
export default tableFiltersSlice.reducer;
