import React from 'react';
import { BeatLoader } from 'react-spinners';
import { InputContainer, SelectCont, Label, Input, Div, Error } from './styles';
import { Img } from '../Filter/styles';
import { hasError } from 'src/Utils/validations';
import { theme } from 'src/Utils';
import { SELECT } from 'src/Utils/constants';
var SelectComponent = function (_a) {
    var label = _a.label, type = _a.type, data = _a.data, _b = _a.errors, errors = _b === void 0 ? '' : _b, img = _a.img, anchorRef = _a.anchorRef, setAnchorRef = _a.setAnchorRef, classNames = _a.classNames, isLoading = _a.isLoading, _c = _a.disabled, disabled = _c === void 0 ? false : _c;
    var handleClick = function (e, type) {
        var currentValue = e.currentTarget;
        if (anchorRef)
            handleOutsideClick();
        setAnchorRef(currentValue);
    };
    var handleOutsideClick = function () {
        setAnchorRef(null);
    };
    return (React.createElement(InputContainer, { className: classNames, key: type },
        React.createElement(Label, null, label),
        React.createElement(SelectCont, { onClick: function (e) { return handleClick(e, type); }, open: false, displayEmpty: true, renderValue: function () { return (React.createElement("div", null, isLoading ? (React.createElement(BeatLoader, { size: "5px", color: theme.palette.black[100] })) : !data ? (SELECT) : (data))); }, value: data, input: React.createElement(Input, null), IconComponent: function () { return React.createElement(Img, { src: img }); }, className: hasError(type, errors) ? 'error' : '' }),
        React.createElement(Div, null,
            React.createElement(Error, null, errors[type]))));
};
export default SelectComponent;
