var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ADDRESS_KEY, AMOUNT_KEY, APARTMENT_KEY, APARTMENT_DATA_KEY, BUILDING_KEY, CLIENT_KEY, CURRENCY_KEY, DESCRIPTION_KEY, ERRORS_KEY, DATE_KEY, RECEIPTS_KEY, TOWER_KEY, TOWER_DATA_KEY, BUILDING_DATA_KEY, } from 'src/Utils/constants';
export var init = function (initialValues) {
    return {
        address: initialValues.address,
        apartment: {
            label: '',
            value: '',
        },
        amount: initialValues.amount,
        building: {
            label: '',
            value: '',
        },
        currency: {
            label: initialValues.currency,
            value: initialValues.currency,
        },
        clientCode: initialValues.client_code,
        description: initialValues.description,
        expirationDate: initialValues.expiration_date,
        receiptNumber: initialValues.receipt_number,
        tower: initialValues.tower,
    };
};
export var reducer = function (state, action) {
    switch (action.type) {
        case ADDRESS_KEY:
            return __assign(__assign({}, state), { address: action.payload });
        case AMOUNT_KEY:
            return __assign(__assign({}, state), { amount: action.payload });
        case APARTMENT_KEY:
            return __assign(__assign({}, state), { apartment: action.payload });
        case APARTMENT_DATA_KEY:
            return __assign(__assign({}, state), { apartmentData: action.payload });
        case BUILDING_KEY:
            return __assign(__assign({}, state), { building: action.payload });
        case BUILDING_DATA_KEY:
            return __assign(__assign({}, state), { buildingData: action.payload });
        case CLIENT_KEY:
            return __assign(__assign({}, state), { clientCode: action.payload });
        case CURRENCY_KEY:
            return __assign(__assign({}, state), { currency: action.payload });
        case DESCRIPTION_KEY:
            return __assign(__assign({}, state), { description: action.payload });
        case ERRORS_KEY:
            return __assign(__assign({}, state), { errorSubmit: action.payload });
        case DATE_KEY:
            return __assign(__assign({}, state), { expirationDate: action.payload });
        case RECEIPTS_KEY:
            return __assign(__assign({}, state), { receiptNumber: action.payload });
        case TOWER_KEY:
            return __assign(__assign({}, state), { tower: action.payload });
        case TOWER_DATA_KEY:
            return __assign(__assign({}, state), { towerData: action.payload });
    }
};
