var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';
import { AdminLayout } from 'src/Layouts';
import { Container, Title } from './styles';
import { createData, getData, PAYMENT_HISTORY, PAYMENT_HISTORY_API, BUILDING_API, DATE_FORMAT_MOMENT, capitalizeFirstLetter, createHeadCells, selectedAll, } from 'src/Utils';
import Cookies from 'js-cookie';
import { TYPE_CONTACT } from 'src/Utils/constants';
import { FilterTable } from 'src/Components/Common';
import { RelativeDiv } from '../BillsPage/styles';
var WIDTHS = ['20%', '20%', '20%', '20%', '20%'];
var headCells = createHeadCells('Fecha de Pago', 'Moneda', 'Importe', 'Red', 'Apartamentos');
var PaymentHistory = function () {
    var isContact = Cookies.get('User') === TYPE_CONTACT;
    var _a = useState(), selectedBuilding = _a[0], setSelectedBuilding = _a[1];
    var _b = useState(), selectedTower = _b[0], setSelectedTower = _b[1];
    var _c = useState(), selectedApartment = _c[0], setSelectedApartment = _c[1];
    var _d = useState(), startDate = _d[0], setStartDate = _d[1];
    var _e = useState(), endDate = _e[0], setEndDate = _e[1];
    var _f = useState([]), buildingList = _f[0], setBuildingList = _f[1];
    var _g = useState([]), apartmentData = _g[0], setApartmentData = _g[1];
    var _h = useState([]), towerData = _h[0], setTowerData = _h[1];
    var _j = useState([]), rows = _j[0], setRows = _j[1];
    var _k = useState(1), page = _k[0], setPage = _k[1];
    var isLoadingBuildings = useQuery([BUILDING_API], function () { return getData("/buildings"); }, {
        onSuccess: function (data) {
            var buildings = data.buildings.map(function (_a) {
                var name = _a.name, id = _a.id;
                return {
                    label: name,
                    value: id,
                };
            });
            buildings.sort(function (a, b) {
                return a.label.localeCompare(b.label, 'es');
            });
            setBuildingList(buildings);
        },
        refetchOnWindowFocus: false,
        retry: false,
    }).isLoading;
    var isLoadingTowers = useQuery([selectedBuilding], function () { return getData("/buildings/" + (selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value) + "/towers"); }, {
        onSuccess: function (data) {
            var towers = data.towers.map(function (_a) {
                var id = _a.id, name = _a.name;
                return ({
                    label: name,
                    value: id,
                });
            });
            setTowerData(towers);
            towers.length === 1 && setSelectedTower(towers[0]);
        },
        retry: false,
        refetchOnWindowFocus: false,
        enabled: selectedBuilding,
    }).isLoading;
    var isLoadingApartments = useQuery([selectedTower], function () {
        return getData("/buildings/" + (selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value) + "/apartments/dropdown?tower=" + (selectedTower === null || selectedTower === void 0 ? void 0 : selectedTower.value));
    }, {
        onSuccess: function (data) {
            var apartments = data.apartments.map(function (_a) {
                var id = _a.id, number = _a.number;
                return ({
                    label: number,
                    value: id,
                });
            });
            setApartmentData(__spreadArrays([selectedAll], apartments));
        },
        retry: false,
        refetchOnWindowFocus: false,
        enabled: selectedTower,
    }).isLoading;
    var handlePageChange = function (pageIndex) {
        var page = pageIndex + 1;
        setPage(page);
    };
    useEffect(function () {
        setPage(1);
    }, [selectedBuilding, selectedTower, selectedApartment, startDate, endDate]);
    var _l = useQuery([
        PAYMENT_HISTORY_API,
        startDate,
        endDate,
        selectedBuilding,
        selectedTower,
        selectedApartment,
        page,
    ], function () {
        return getData("common_expenses_receipts/payed?page=" + page, {
            building_id: selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value,
            tower_id: selectedTower === null || selectedTower === void 0 ? void 0 : selectedTower.value,
            apartment_number: (selectedApartment === null || selectedApartment === void 0 ? void 0 : selectedApartment.label) === 'Todos'
                ? null
                : selectedApartment === null || selectedApartment === void 0 ? void 0 : selectedApartment.label,
            start_date: startDate
                ? moment(startDate).format(DATE_FORMAT_MOMENT)
                : null,
            end_date: endDate ? moment(endDate).format(DATE_FORMAT_MOMENT) : null,
        });
    }, {
        onSuccess: function (_a) {
            var common_expenses_receipts = _a.common_expenses_receipts;
            if (!common_expenses_receipts[0]) {
                setRows([]);
            }
            else {
                common_expenses_receipts.sort(function (a, b) {
                    return moment(b.created_at).diff(moment(a.created_at));
                });
                setRows(common_expenses_receipts.map(function (_a) {
                    var payed_at = _a.payed_at, currency = _a.currency, amount = _a.amount, payment_method = _a.payment_method, apartment_number = _a.apartment_number;
                    return createData(moment(payed_at).format(DATE_FORMAT_MOMENT), currency === 'pesos' ? 'UYU' : 'DOL', amount.toString(), capitalizeFirstLetter(payment_method), apartment_number !== null && apartment_number !== void 0 ? apartment_number : '');
                }));
            }
        },
        enabled: selectedBuilding,
        refetchOnWindowFocus: false,
        retry: false,
    }), isLoadingPayments = _l.isLoading, refetch = _l.refetch, data = _l.data;
    useEffect(function () {
        if (!isContact) {
            setSelectedTower(undefined);
            setSelectedApartment(undefined);
        }
    }, [selectedBuilding]);
    useEffect(function () {
        if (!isContact) {
            setSelectedApartment(undefined);
        }
    }, [selectedTower]);
    return (React.createElement(AdminLayout, null,
        React.createElement(RelativeDiv, null,
            React.createElement(Container, null,
                React.createElement(Title, null, PAYMENT_HISTORY),
                React.createElement(FilterTable, { rows: rows, building: selectedBuilding, tower: selectedTower, apartment: selectedApartment, headCells: headCells, buildingList: buildingList, towerList: towerData, apartmentList: apartmentData, width: WIDTHS, userWidth: WIDTHS, hasButton: !isContact, startDate: startDate, setStartDate: setStartDate, endDate: endDate, setEndDate: setEndDate, setSelectedBuilding: setSelectedBuilding, setSelectedTower: setSelectedTower, setSelectedApartment: setSelectedApartment, isPaymentHistory: true, isLoading: isLoadingBuildings ||
                        isLoadingTowers ||
                        isLoadingApartments ||
                        isLoadingPayments, refetch: refetch, onPageChange: handlePageChange, totalPages: data === null || data === void 0 ? void 0 : data.total_pages, paginated: true, page: page })))));
};
export default PaymentHistory;
