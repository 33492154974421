import { AMENITY, APARTMENT, DATE, PRICE, SCHEDULE, STATE, createHeadCells, } from 'src/Utils';
export var WIDTHS = ['16%', '16%', '16%', '16%', '16%', '16%', '5%'];
export var createBuilding = function (label, value, rows, code) {
    return { label: label, value: value, rows: rows, code: code };
};
export var handleCells = function () {
    return createHeadCells(AMENITY, APARTMENT, DATE, STATE, SCHEDULE, PRICE, '');
};
export var handleAmenityCurrency = function (currency) {
    switch (currency) {
        case 'pesos':
            return '$';
        case 'dolares':
            return 'U$S';
        case 'ur':
            return 'UR';
        default:
            return '';
    }
};
export var handleState = function (status) {
    var stateValues = {
        active: 'Activa',
        past: 'Completada',
        cancelled: 'Cancelada',
    };
    return stateValues[status] || '-';
};
