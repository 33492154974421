var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { ADDRESS, ADDRESS_KEY, REQUIRED } from 'src/Utils/constants';
import InputComponent from '../Common/InputComponent';
import { FormContainer, FormTitle, TopInputs } from './styles';
var AddressForm = function (_a) {
    var formData = _a.formData, setFormData = _a.setFormData, errors = _a.errors, disabled = _a.disabled;
    return (React.createElement(FormContainer, null,
        React.createElement(TopInputs, null,
            React.createElement(FormTitle, null, ADDRESS),
            React.createElement(InputComponent, { disabled: disabled ? true : false, type: ADDRESS_KEY, data: formData[ADDRESS_KEY], error: errors, label: ADDRESS + " " + REQUIRED, placeHolder: ADDRESS, onChangeFunction: function (e) {
                    e.persist();
                    setFormData(function (prevState) {
                        var _a;
                        return (__assign(__assign({}, prevState), (_a = {}, _a[ADDRESS_KEY] = e.target.value, _a)));
                    });
                } }))));
};
export default AddressForm;
