import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { ClickAwayListener } from '@material-ui/core';
import Cookies from 'js-cookie';
import { useMutation } from 'react-query';
import { exit, openedArrow, closedArrow, Hamburger, LogoFascioli, } from '../../../assets/Images';
import { SideMenu, IconsContainer, Button, ItemsContainer, LogoContainer, Label, Icon, Div, SideOptions, StyledPopper, OptionsDiv, ArrowOptionsContainer, Img, ContainerDiv, ImageLogoContainer, } from './styles';
import { ONLINE_COLLECTION, DOCUMENTS, MAINTENANCE, LOGIN_TEXT, DASHBOARD_TEXT, } from 'src/Utils';
import { removeCookies } from 'src/Utils/utils';
import { logoutAdmins, logoutContacts } from './duck';
import { HOME_ADMIN_TEXT, ROUTES } from 'src/Utils/constants';
var MenuBar = function (_a) {
    var handleOpen = _a.handleOpen, topItems = _a.topItems, bottomItems = _a.bottomItems, selectedIcon = _a.selectedIcon, isOpen = _a.isOpen, isLargeScreen = _a.isLargeScreen, className = _a.className;
    var history = useHistory();
    var canShowItems = isOpen || isLargeScreen;
    var _b = useState(false), expand = _b[0], setExpand = _b[1];
    var _c = useState(false), isMaintenanceOptionOpen = _c[0], setIsMaintenanceOptionOpen = _c[1];
    var _d = useState(null), selectedOption = _d[0], setSelectedOption = _d[1];
    var anchorRef = useRef(null);
    var mutateLogoutAdmin = useMutation(logoutAdmins, {
        onSuccess: function () {
            removeCookies();
            history.push(ROUTES.landing);
        },
        onError: function () {
            removeCookies();
            history.push(ROUTES.landing);
        },
    })[0];
    var mutateLogoutContact = useMutation(logoutContacts, {
        onSuccess: function () {
            removeCookies();
            history.push(ROUTES.landing);
        },
        onError: function () {
            removeCookies();
            history.push(ROUTES.landing);
        },
    })[0];
    var handleLogout = function () {
        if (Cookies.get('User') === 'Admin')
            mutateLogoutAdmin();
        else if (Cookies.get('User') === 'Contact')
            mutateLogoutContact();
    };
    useEffect(function () {
        setExpand(false);
        setSelectedOption(null);
    }, [isLargeScreen]);
    var getClassName = function (key, text, isBottomItem) {
        var result = key;
        result += isBottomItem ? ' bottom' : '';
        if (((selectedIcon === key || selectedIcon === text) && !selectedOption) ||
            selectedOption === text) {
            result += ' selected';
        }
        return result;
    };
    var handleIconClick = function (text, key) {
        setSelectedOption(selectedOption == text ? null : text);
        if (key == LOGIN_TEXT) {
            handleLogout();
        }
        else if (key === HOME_ADMIN_TEXT) {
            var url = location.protocol + "//" + location.host + "/admin";
            window.open(url, '_blank');
        }
        else if (isLargeScreen) {
            key != '' ? history.push(key) : setExpand(selectedOption != text);
        }
        else {
            key != '' && history.push(key);
        }
    };
    var renderSmallOptions = function (text, key) {
        var _a;
        var optionToRender = getOptionToRender();
        return (React.createElement(ContainerDiv, { className: !isLargeScreen && key != LOGIN_TEXT ? '' : 'hide' },
            React.createElement(SideOptions, { className: text == selectedOption && key != (DASHBOARD_TEXT || HOME_ADMIN_TEXT)
                    ? ''
                    : 'hide' }, (_a = optionToRender === null || optionToRender === void 0 ? void 0 : optionToRender.options) === null || _a === void 0 ? void 0 : _a.map(function (option, index) { return (React.createElement("div", { key: option.key + index }, renderOption(option))); }))));
    };
    var renderIcon = function (_a, isBottomItem, index) {
        var text = _a.text, key = _a.key, icon = _a.icon;
        return (React.createElement(React.Fragment, null,
            React.createElement(LogoContainer, { className: getClassName(key, text, isBottomItem), onClick: function () { return handleIconClick(text, key); }, ref: anchorRef, key: text + index },
                React.createElement(Icon, { className: getClassName(key), src: icon, alt: text }),
                React.createElement(Label, { className: getClassName(key, text) + " " + (canShowItems ? '' : 'closed') }, text),
                React.createElement(Img, { className: text == DOCUMENTS || text == MAINTENANCE ? 'arrow' : 'hide', src: selectedOption == text ? openedArrow : closedArrow })),
            renderSmallOptions(text, key)));
    };
    var getOptionClassName = function (option) {
        var ret = option;
        ret += option == ONLINE_COLLECTION ? ' option' : ' hide';
        ret +=
            isMaintenanceOptionOpen && option == ONLINE_COLLECTION
                ? ' isOpen'
                : ' hide-options';
        return ret;
    };
    var renderOption = function (_a) {
        var _b;
        var name = _a.name, key = _a.key;
        var maintenanceOption = topItems[2];
        return (React.createElement("div", { key: key + name },
            React.createElement(OptionsDiv, { onClick: function () { return handleOptionClick(name, key); }, className: getOptionClassName(name) },
                name,
                React.createElement(Img, { className: getOptionClassName(name), src: isMaintenanceOptionOpen ? openedArrow : closedArrow, onClick: function () { return setIsMaintenanceOptionOpen(!isMaintenanceOptionOpen); } })),
            React.createElement(ArrowOptionsContainer, { className: getOptionClassName(name) }, (_b = maintenanceOption === null || maintenanceOption === void 0 ? void 0 : maintenanceOption.subOptions) === null || _b === void 0 ? void 0 : _b.map(function (subOption, index) { return (React.createElement(OptionsDiv, { key: subOption.name + index, onClick: function () { return handleOptionClick(subOption.name, subOption.key); }, className: "arrowOptions" }, subOption.name)); }))));
    };
    var handleOptionClick = function (option, key) {
        return option != ONLINE_COLLECTION
            ? history.push(key)
            : setIsMaintenanceOptionOpen(!isMaintenanceOptionOpen);
    };
    var getOptionToRender = function () {
        var documentsOption = topItems[1];
        var maintenanceOption = topItems[2];
        return selectedOption == documentsOption.text
            ? documentsOption
            : maintenanceOption;
    };
    var renderOptions = function () {
        var _a;
        var optionToRender = getOptionToRender();
        return (React.createElement(SideOptions, null,
            React.createElement(OptionsDiv, { className: "title" }, selectedOption), (_a = optionToRender === null || optionToRender === void 0 ? void 0 : optionToRender.options) === null || _a === void 0 ? void 0 :
            _a.map(function (option, index) { return (React.createElement("div", { key: option.key + index }, renderOption(option))); })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SideMenu, { className: !isOpen ? 'width' : '' },
            isOpen && !isLargeScreen && (React.createElement(Button, { className: isOpen ? '' : 'closed', onClick: handleOpen },
                React.createElement(Icon, { className: "exit", src: exit, alt: "exit" }))),
            canShowItems ? (React.createElement(Div, null, isLargeScreen ? (React.createElement(ImageLogoContainer, { className: "cuenta bottom" },
                React.createElement(Img, { src: LogoFascioli, onClick: function () { return history.push(ROUTES.dashboard); }, style: { width: '100%', height: '100%' } }))) : ('Fascioli'))) : (React.createElement(Img, { src: Hamburger, onClick: function () { return !isOpen && handleOpen(); } })),
            React.createElement(ItemsContainer, { className: canShowItems ? 'open' : 'hideItems' },
                React.createElement(IconsContainer, { className: className }, topItems.map(function (icon, index) { return (React.createElement("div", { key: icon.text + index }, renderIcon(icon, false, index))); })),
                React.createElement(ItemsContainer, { className: "bottom" }, canShowItems &&
                    bottomItems.map(function (icon, index) { return (React.createElement("div", { key: icon.text + index }, renderIcon(icon, true, index))); })))),
        React.createElement(StyledPopper, { open: expand, anchorEl: anchorRef.current },
            React.createElement(ClickAwayListener, { onClickAway: function () {
                    setExpand(false);
                    setSelectedOption(null);
                } }, renderOptions()))));
};
export default MenuBar;
