import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useWindowWidth } from 'src/useWindowWidth';
import { M } from 'src/Utils';
import { ImagesLoadingSkeleton } from './Images/ImagesLoadingSkeleton';
import { Column, Row } from '../styles';
export var TopInfoLoadingSkeleton = function () {
    var windowWidth = useWindowWidth();
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { className: "space-between" },
            React.createElement(Column, null,
                React.createElement(Skeleton, { width: windowWidth > M ? 600 : 274, height: windowWidth > M ? 42 : 19 }),
                React.createElement(Skeleton, { width: windowWidth > M ? 400 : 211, height: windowWidth > M ? 30 : 14 }))),
        windowWidth > M ? (React.createElement(ImagesLoadingSkeleton, null)) : (React.createElement(Skeleton, { width: windowWidth > M ? '50%' : '100%', height: windowWidth > M ? '100%' : 242 }))));
};
