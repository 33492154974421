import React from 'react';
import { RealEstateMultiSelect } from 'src/Components/Common/RealEstateMultiSelect';
import { FILTERS } from 'src/Utils';
import { MobileSelectContainer, optionsCustomStyles } from './styles';
var MobileFilterButton = function (_a) {
    var setIsOpen = _a.setIsOpen;
    return (React.createElement(MobileSelectContainer, { onClick: function () { return setIsOpen(true); } },
        React.createElement(RealEstateMultiSelect, { options: [], type: "", setSelectedOption: setIsOpen, inputLabel: FILTERS, customStyles: optionsCustomStyles, defaultValueContainer: true, disabled: true })));
};
export default MobileFilterButton;
