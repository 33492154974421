var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { FormContainer, InputContainer, TopInputs, FormTitle, StyledImg, } from './styles';
import { openedArrow, TrashDelete } from 'src/assets/Images';
import { InputComponent, Popover, SelectComponent } from '../Common';
import { BUILDING_KEY, BUILDING, RELATIONSHIP_KEY, RELATIONSHIP, TOWER, TOWER_KEY, APARTMENT_KEY, APARTMENT, DPTO, } from 'src/Utils/constants';
import { getData } from 'src/Utils/api';
var Form = function (_a) {
    var inputs = _a.inputs, formData = _a.formData, setFormData = _a.setFormData, errors = _a.errors, index = _a.index, setFormErrors = _a.setFormErrors, deleteForm = _a.deleteForm, editRelation = _a.editRelation;
    var _b = useState([]), apartmentData = _b[0], setApartmentData = _b[1];
    var _c = useState([]), towerData = _c[0], setTowerData = _c[1];
    var _d = useState(null), anchorRefBuilding = _d[0], setAnchorRefBuilding = _d[1];
    var _e = useState(null), anchorRefRelation = _e[0], setAnchorRefRelation = _e[1];
    var _f = useState(null), anchorRefApartment = _f[0], setAnchorRefApartment = _f[1];
    var _g = useState(null), anchorRefTower = _g[0], setAnchorRefTower = _g[1];
    var _h = useQuery("getTowers" + index + formData[index].building.value, function () { return getData("/buildings/" + formData[index].building.value + "/towers"); }, {
        onSuccess: function (data) {
            setTowerData(data.towers.map(function (_a) {
                var id = _a.id, name = _a.name;
                return ({
                    label: name,
                    value: id,
                });
            }));
        },
        retry: false,
        refetchOnWindowFocus: false,
        enabled: formData[index].building.value,
    }), dataTower = _h.data, isLoadingTower = _h.isLoading;
    var _j = useQuery("getApartments" + index + formData[index].building.value, function () {
        return getData("/buildings/" + formData[index].building.value + "/apartments?tower=" + formData[index].tower.value);
    }, {
        onSuccess: function (data) {
            setApartmentData(data.apartments.map(function (_a) {
                var id = _a.id, number = _a.number;
                return ({
                    label: number,
                    value: id,
                });
            }));
        },
        retry: false,
        refetchOnWindowFocus: false,
        enabled: formData[index].tower.value,
    }), dataApartment = _j.data, isLoadingApartment = _j.isLoading;
    var handleOutsideClick = function () {
        setAnchorRefBuilding(null);
        setAnchorRefRelation(null);
        setAnchorRefApartment(null);
        setAnchorRefTower(null);
    };
    var handleSelected = function (item, type) {
        var forms = __assign({}, formData);
        var selectedData = forms[index];
        selectedData[type] = item;
        forms[index] = selectedData;
        setFormData(forms);
        type === BUILDING_KEY && resetTowerApartment();
    };
    var resetTowerApartment = function () {
        var forms = __assign({}, formData);
        var selectedData = forms[index];
        selectedData[TOWER_KEY] = {
            label: '',
            value: '',
        };
        selectedData[APARTMENT_KEY] = {
            label: '',
            value: '',
        };
        forms[index] = selectedData;
        setFormData(forms);
    };
    var renderInput = function (type, label, data) { return (React.createElement(InputComponent, { disabled: true, type: type, data: data, error: errors, label: label, className: "height " + (label === BUILDING ? '' : 'width') })); };
    var renderForm = function (_a, formErrors) {
        var building = _a.building, relation = _a.relation, apartment = _a.apartment, tower = _a.tower;
        return (React.createElement(FormContainer, null,
            React.createElement(TopInputs, null,
                React.createElement(FormTitle, null, BUILDING + "/" + DPTO,
                    ' ',
                    React.createElement(StyledImg, { src: TrashDelete, onClick: function () { return deleteForm(index, apartment.apartment); } })),
                editRelation ? (React.createElement(React.Fragment, null,
                    renderInput(BUILDING_KEY, BUILDING, building.label.toString()),
                    React.createElement(InputContainer, { className: "row height justify" },
                        renderInput(TOWER_KEY, TOWER, tower.label.toString()),
                        renderInput(APARTMENT_KEY, APARTMENT, apartment.label.toString())))) : (React.createElement(React.Fragment, null,
                    React.createElement(SelectComponent, { label: BUILDING, type: BUILDING_KEY, data: building.label, errors: errors[index], img: openedArrow, anchorRef: anchorRefBuilding, setAnchorRef: setAnchorRefBuilding, classNames: "height" }),
                    React.createElement(InputContainer, { className: "row height justify" },
                        React.createElement(SelectComponent, { label: TOWER, type: TOWER_KEY, data: tower.label, errors: errors[index], img: openedArrow, anchorRef: anchorRefTower, setAnchorRef: setAnchorRefTower, classNames: "height width", isLoading: building.label ? isLoadingTower : undefined }),
                        React.createElement(SelectComponent, { label: APARTMENT, type: APARTMENT_KEY, data: apartment.label, errors: errors[index], img: openedArrow, anchorRef: anchorRefApartment, setAnchorRef: setAnchorRefApartment, classNames: "height width", isLoading: building.label ? isLoadingApartment : undefined })))),
                React.createElement(SelectComponent, { label: RELATIONSHIP, type: RELATIONSHIP_KEY, data: relation.label, errors: errors[index], img: openedArrow, anchorRef: anchorRefRelation, setAnchorRef: setAnchorRefRelation, classNames: "height" }))));
    };
    var renderPopOver = function (data, anchorRef, type, className) { return (React.createElement(Popover, { data: data, handleOutsideClick: handleOutsideClick, anchorRef: anchorRef, setSelected: handleSelected, isFilter: false, type: type, className: className || '' })); };
    return (React.createElement(React.Fragment, null,
        renderForm(formData[index], errors[index]),
        renderPopOver(inputs[0].data, anchorRefBuilding, BUILDING_KEY, 'spaceBuildings'),
        renderPopOver(towerData, anchorRefTower, TOWER_KEY, 'space'),
        renderPopOver(apartmentData, anchorRefApartment, APARTMENT_KEY, 'space'),
        renderPopOver(inputs[3].data, anchorRefRelation, RELATIONSHIP_KEY)));
};
export default Form;
