import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { BeatLoader } from 'react-spinners';
import { USEFUL_PHONES, PROVIDERS_API, BUILDING_API, } from 'src/Utils/constants';
import { Title, Container, CarouselsContainer } from './styles';
import BuildingPhones from './BuildingPhones';
import { getData, theme } from 'src/Utils';
var UsefulPhones = function () {
    var _a = useState([]), buildings = _a[0], setBuildings = _a[1];
    var _b = useState([]), buildingsNames = _b[0], setBuildingsNames = _b[1];
    var isLoadingBuildings = useQuery(BUILDING_API, function () { return getData('/buildings'); }, {
        onSuccess: function (_a) {
            var buildings = _a.buildings;
            setBuildingsNames(buildings.map(function (_a) {
                var name = _a.name;
                return name;
            }));
        },
    }).isLoading;
    var isLoading = useQuery(PROVIDERS_API, function () { return getData('/providers'); }, {
        onSuccess: function (dataProvider) {
            setBuildings(buildingsNames.map(function (buildingName) { return ({
                name: buildingName,
                data: dataProvider.providers.filter(function (provider) {
                    if (provider.buildings.some(function (building) { return building.building_name === buildingName; }))
                        return provider;
                }),
            }); }));
        },
    }).isLoading;
    return (React.createElement(Container, null,
        React.createElement(Title, null, USEFUL_PHONES),
        (isLoading || isLoadingBuildings) && (React.createElement(BeatLoader, { size: "8px", color: theme.palette.black[100] })),
        React.createElement(CarouselsContainer, null, buildings &&
            buildings.map(function (_a) {
                var name = _a.name, data = _a.data;
                return (React.createElement(BuildingPhones, { data: data, name: name, key: name }));
            }))));
};
export default UsefulPhones;
