import { StyleSheet, Font } from '@react-pdf/renderer';
import { theme } from 'src/Utils';
import RalewayBold from '../../ReceiptForm/PDFComponent/fonts/Raleway-Bold.ttf';
import Raleway from '../../ReceiptForm/PDFComponent/fonts/Raleway.ttf';
import RalewayNormal from '../../ReceiptForm/PDFComponent/fonts/Raleway-Regular.ttf';
Font.register({
    family: 'Raleway',
    format: 'truetype',
    fonts: [
        { src: RalewayBold, fontWeight: 'bold' },
        { src: Raleway, fontWeight: 'light' },
        { src: RalewayNormal, fontWeight: 'normal' },
    ],
});
var styles = StyleSheet.create({
    page: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.white[100],
        padding: '30 20',
        flexGrow: 1,
        fontFamily: 'Raleway',
        position: 'relative',
    },
    print: {
        padding: '90 20 0 20',
    },
    headerPrint: {
        width: '100%',
        alignContent: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    header: {
        padding: 20,
        borderRadius: 5,
        backgroundColor: theme.palette.primary[50],
        color: theme.palette.white[100],
        fontWeight: 'bold',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    title: {
        padding: 20,
        color: theme.palette.primary[50],
        fontWeight: 'bold',
    },
    logo: {
        height: '30px',
    },
    section1: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: 20,
        marginRight: 20,
        paddingTop: 20,
        paddingBottom: 20,
        borderBottom: "2px solid " + theme.palette.primary[15],
    },
    section2: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 20,
        marginRight: 20,
        paddingTop: 20,
        paddingBottom: 20,
        borderBottom: "2px solid " + theme.palette.primary[15],
    },
    section3: {
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
    },
    signatures: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: 'auto',
        marginBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
    },
    signaturesPrint: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        margin: 'auto',
        padding: '40 0',
    },
    signature: {
        width: '200px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    watermarkLogo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        position: 'absolute',
        bottom: 80,
        left: 0,
        right: 0,
    },
    defaultSuspendedWatermark: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '110%',
        position: 'absolute',
        bottom: 450,
        left: 0,
        right: 0,
        transform: 'rotate(-50deg)',
        textTransform: 'uppercase',
        margin: 0,
    },
    suspendedNotPrintable: {
        backgroundColor: 'rgba(255, 80, 80)',
        color: 'white',
        fontSize: '40px',
        letterSpacing: '2px',
    },
    suspendedPrintable: {
        color: 'grey',
        fontSize: '80px',
        letterSpacing: '4px',
    },
    footer: {
        marginTop: 'auto',
        paddingTop: 6,
        paddingBottom: 6,
        borderRadius: 5,
        backgroundColor: theme.palette.primary[50],
        color: theme.palette.white[100],
        fontSize: 6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    footerLogos: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 5,
    },
    fontTitle: {
        fontSize: 14,
        marginBottom: 6,
        fontWeight: 'bold',
    },
    fontNormal: {
        fontSize: 10,
    },
    date: {
        fontSize: 10,
        textTransform: 'capitalize',
    },
    list: {
        marginTop: 24,
    },
    listItem: {
        color: theme.palette.primary[50],
        fontSize: 10,
        marginBottom: 2,
        fontWeight: 'bold',
    },
    text: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginBottom: 7,
    },
    completeLine: {
        borderBottom: "1px solid " + theme.palette.grey[30],
        width: '100%',
    },
    backgroundCircle1: {
        position: 'absolute',
        right: -54,
        top: '37%',
        width: '200px',
        height: '200px',
        border: "4px solid " + theme.palette.primary[15],
        borderRadius: '50%',
        boxSizing: 'border-box',
    },
    backgroundCircle2: {
        position: 'absolute',
        right: -36,
        top: '39%',
        width: '160px',
        height: '160px',
        border: "4px solid " + theme.palette.primary[15],
        borderRadius: '50%',
        boxSizing: 'border-box',
    },
    backgroundCircle3: {
        position: 'absolute',
        right: -100,
        top: '55%',
        width: '270px',
        height: '270px',
        border: "4px solid " + theme.palette.primary[15],
        borderRadius: '50%',
        boxSizing: 'border-box',
    },
    pageNumber: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.grey[30],
        fontSize: 8,
        marginTop: 5,
        textAlign: 'center',
    },
});
export default styles;
