import React from 'react';
import { Page, Text, Document, View } from '@react-pdf/renderer';
import styles from './styles';
import { PDFHeader } from './PDFHeader';
import { PDFTable } from './PDFTable';
import { PDFFooter } from './PDFFooter';
import { BUILDING, capitalizeFirstLetter, PAGE, PERIOD_DETAIL, } from 'src/Utils';
export var SettlementPDF = function (_a) {
    var building = _a.building, end_date = _a.end_date, start_date = _a.start_date, information = _a.information;
    return (React.createElement(Document, null,
        React.createElement(Page, { size: "A4", style: styles.page, orientation: "landscape" },
            React.createElement(PDFHeader, null),
            React.createElement(View, { style: styles.informationContainer },
                React.createElement(Text, { style: styles.informationBold }, (building === null || building === void 0 ? void 0 : building.code) + " " + BUILDING + " " + (building === null || building === void 0 ? void 0 : building.name) + " - " + (building === null || building === void 0 ? void 0 : building.address)),
                React.createElement(View, { style: { display: 'flex', flexDirection: 'row' } },
                    React.createElement(Text, { style: styles.information }, PERIOD_DETAIL),
                    React.createElement(Text, { style: styles.informationBold }, start_date + " - " + end_date))), information === null || information === void 0 ? void 0 :
            information.map(function (data) {
                var _a;
                if (data.Titulos || data.Cuerpo || data.Pie)
                    return (React.createElement(PDFTable, { header: data.Titulos, rows: data.Cuerpo, footer: data.Pie, name: data.Nombre }));
                if (data.Nombres)
                    return (_a = data.Nombres) === null || _a === void 0 ? void 0 : _a.map(function (row) { return (React.createElement(View, { style: styles.tableName, wrap: false }, Array.isArray(row[1]) &&
                        row[1].map(function (value) { return (React.createElement(Text, null, capitalizeFirstLetter(value))); }))); });
                return (React.createElement(Text, { style: styles.note }, data.Notas
                    ? capitalizeFirstLetter(data.Notas)
                    : data.Mensaje
                        ? capitalizeFirstLetter(data.Mensaje)
                        : ''));
            }),
            React.createElement(Text, { style: styles.pageNumber, render: function (_a) {
                    var pageNumber = _a.pageNumber;
                    return PAGE + " " + pageNumber;
                }, fixed: true })),
        React.createElement(PDFFooter, null)));
};
export default SettlementPDF;
