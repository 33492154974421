var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M } from '../../Utils';
export var FormsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n"])));
export var FormContainer = styled.form(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  min-height: 150px;\n  max-width: 340px;\n  width: 100%;\n  border-radius: 8px;\n  padding: 30px 35px 5px 35px;\n  margin-bottom: 14px;\n  background-color: ", ";\n  &.margin {\n    margin-right: 15px;\n  }\n  @media (max-width: ", "px) {\n    margin-left: 10px;\n    max-width: inherit;\n    padding: 0px;\n    padding: 5px 20px;\n    width: auto;\n    background-color: ", ";\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  min-height: 150px;\n  max-width: 340px;\n  width: 100%;\n  border-radius: 8px;\n  padding: 30px 35px 5px 35px;\n  margin-bottom: 14px;\n  background-color: ", ";\n  &.margin {\n    margin-right: 15px;\n  }\n  @media (max-width: ", "px) {\n    margin-left: 10px;\n    max-width: inherit;\n    padding: 0px;\n    padding: 5px 20px;\n    width: auto;\n    background-color: ", ";\n  }\n"])), theme.palette.admin.primary[45], M, theme.palette.admin.primary[50]);
export var ButtonsContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0px;\n  justify-content: flex-end;\n  border-radius: 8px;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0px;\n  justify-content: flex-end;\n  border-radius: 8px;\n"])));
export var Link = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 14px;\n  text-decoration-line: underline;\n  cursor: pointer;\n  margin-right: 50px;\n  color: ", ";\n  &.margin-right {\n    margin-right: 16px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 12px;\n  }\n"], ["\n  font-size: 14px;\n  text-decoration-line: underline;\n  cursor: pointer;\n  margin-right: 50px;\n  color: ", ";\n  &.margin-right {\n    margin-right: 16px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 12px;\n  }\n"])), theme.palette.admin.primary[75], M);
export var BottomContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  padding: 25px 0;\n  @media (max-width: ", "px) {\n    padding-right: 20px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  padding: 25px 0;\n  @media (max-width: ", "px) {\n    padding-right: 20px;\n  }\n"])), M);
export var ErrorMessage = styled.label(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 12px;\n  margin-top: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  padding: 0px;\n"], ["\n  color: ", ";\n  font-size: 12px;\n  margin-top: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  padding: 0px;\n"])), theme.palette.error[50]);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
