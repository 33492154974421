import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { AdminLayout } from 'src/Layouts';
import { Container, Title, DetailsContainer } from './styles';
import { ADD_CLIENT_CODE, ADD, NEW_CLIENT_CODE_KEY, NEW_CLIENT_CODE, BACK_ERROR, } from 'src/Utils';
import { ROUTES } from 'src/Utils/constants';
import { AdminForm } from 'src/Components/Common';
import { addClientCode } from './duck';
var input = [
    {
        key: NEW_CLIENT_CODE_KEY,
        label: NEW_CLIENT_CODE,
        value: '',
        className: 'white',
    },
];
var ChangePassword = function () {
    var history = useHistory();
    var _a = useState(''), backError = _a[0], setBackError = _a[1];
    var account = ROUTES.account;
    var onCancel = function () { return history.push(account); };
    var mutateAddCode = useMutation(addClientCode, {
        onSuccess: function () {
            history.push(account);
        },
        onError: function (error) {
            var _a, _b;
            setBackError(((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) || BACK_ERROR);
        },
    })[0];
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, null, ADD_CLIENT_CODE),
            React.createElement(DetailsContainer, { className: "form" },
                React.createElement(AdminForm, { inputs: input, onSubmit: function (_a) {
                        var new_client_code = _a.new_client_code;
                        mutateAddCode(new_client_code);
                    }, buttonLabel: ADD, onCancel: onCancel, backError: backError, isPassword: true })))));
};
export default ChangePassword;
