import React from 'react';
import Cookies from 'js-cookie';
import { Container, Div } from './styles';
import { HomeIcon, MaintenanceIcon, DocumentsIcon, PersonIcon, LogoutIcon, } from '../../assets/Images';
import { HOME, DOCUMENTS, MAINTENANCE, ACCOUNT_TEXT, LOGIN_TEXT, APARTMENTS, ASSEMBLIES_MINUTES, COLLECTION_MANAGEMENT, COMMON_EXPENSES_RECEIPTS, CONTACTS, ONLINE_COLLECTION, BILLS, BUILDINGS, MESSAGES, PAYMENT_HISTORY, SETTELMENTS, ADMINS, ACCOUNT, ASSEMBLIES_SUMMONS_TITLE, } from 'src/Utils';
import { MenuComponent } from 'src/Components';
import { TYPE_CONTACT, OTHER_DOCUMENTS, ROUTES, SISTARBANC, HOME_ADMIN_TEXT, TYPE_ADMIN, RESERVATIONS, } from 'src/Utils/constants';
var minutes = ROUTES.minutes, settlement = ROUTES.settlement, bill = ROUTES.bill, summons = ROUTES.summons, payment = ROUTES.payment, receipt = ROUTES.receipt, otherDocuments = ROUTES.otherDocuments, apartments = ROUTES.apartments, buildings = ROUTES.buildings, contacts = ROUTES.contacts, messages = ROUTES.messages, admins = ROUTES.admins, providers = ROUTES.providers, collection_manager = ROUTES.collection_manager, sistarbanc = ROUTES.sistarbanc, reservations = ROUTES.reservations;
var bottomItems = [
    { text: '', key: ROUTES.account, icon: PersonIcon },
    { text: '', key: LOGIN_TEXT, icon: LogoutIcon },
];
var AdminLayout = function (_a) {
    var children = _a.children;
    var isContact = Cookies.get('User') === TYPE_CONTACT;
    var isAdmin = Cookies.get('User') === TYPE_ADMIN;
    var isSuperAdmin = Cookies.get('SuperAdmin') === 'true';
    var topItems = ([
        { text: HOME, key: ROUTES.dashboard, icon: HomeIcon },
        {
            text: DOCUMENTS,
            key: '',
            icon: DocumentsIcon,
            options: [
                { name: ASSEMBLIES_MINUTES, key: minutes },
                { name: ASSEMBLIES_SUMMONS_TITLE, key: summons },
                { name: BILLS, key: bill },
                { name: PAYMENT_HISTORY, key: payment },
                { name: SETTELMENTS, key: settlement },
                { name: COMMON_EXPENSES_RECEIPTS, key: receipt },
                { name: OTHER_DOCUMENTS, key: otherDocuments },
            ],
        },
        {
            text: MAINTENANCE,
            key: '',
            icon: MaintenanceIcon,
            options: isSuperAdmin
                ? [
                    { name: APARTMENTS, key: apartments },
                    { name: ADMINS, key: admins },
                    { name: ONLINE_COLLECTION, key: '' },
                    { name: CONTACTS, key: contacts },
                    { name: BUILDINGS, key: buildings },
                    { name: RESERVATIONS, key: reservations },
                    { name: MESSAGES, key: messages },
                    // Commented code is by client request
                    // { name: PROVIDERS, key: providers },
                    { name: SISTARBANC, key: sistarbanc },
                ]
                : [
                    { name: APARTMENTS, key: apartments },
                    { name: ONLINE_COLLECTION, key: '' },
                    { name: CONTACTS, key: contacts },
                    { name: BUILDINGS, key: buildings },
                    { name: RESERVATIONS, key: reservations },
                    { name: MESSAGES, key: messages },
                ],
            subOptions: [{ name: COLLECTION_MANAGEMENT, key: collection_manager }],
        },
        { text: TYPE_ADMIN, key: HOME_ADMIN_TEXT, icon: HomeIcon },
        { text: ACCOUNT, key: ACCOUNT_TEXT, icon: PersonIcon },
    ]);
    var getTopItems = function () {
        if (isContact)
            return topItems.filter(function (item) { return item.text !== MAINTENANCE && item.text !== TYPE_ADMIN; });
        else if (isAdmin && !isSuperAdmin)
            return topItems.filter(function (item) { return item.text !== TYPE_ADMIN; });
        else
            return topItems;
    };
    return (React.createElement(Container, null,
        React.createElement(MenuComponent, { topItems: getTopItems(), bottomItems: bottomItems, className: isContact ? 'smaller' : '' }),
        React.createElement(Div, null, children)));
};
export default AdminLayout;
