import React from 'react';
import { Document, Page } from '@react-pdf/renderer';
import { COMMON_EXPENSES_RECEIPTS, COPYRIGHT } from 'src/Utils';
import { BackgroundLogo, InstagramPng, LogoPdf, WebPng, } from 'src/assets/Images';
import * as styles from './styles';
import { CLIENT_NUMBER, COMMON_EXPENSES_DETAILS, EXPIRATION_DATE_TEXT, HOLDER, ISSUE_DATE, LOCATION, PAGE, PAYMENT_DOESNT_CANCEL_DEBTS, RECEIPT_NUMBER, TOTAL_AMOUNT, UYU, USD, } from 'src/Utils/constants';
var PdfComponent = function (_a) {
    var holder = _a.holder, clientNumber = _a.clientNumber, receiptNumber = _a.receiptNumber, location = _a.location, issueDate = _a.issueDate, expirationDate = _a.expirationDate, totalAmount = _a.totalAmount, description = _a.description, currency = _a.currency;
    var RowComponentBlue = function (first, second, third) { return (React.createElement(styles.BlueRow, null,
        React.createElement(styles.BoldText, null, first),
        React.createElement(styles.BoldText, null, second),
        React.createElement(styles.BoldTextRight, null, third))); };
    var RowComponentLightBlue = function (first, second, third) { return (React.createElement(styles.LightBlueRow, null,
        React.createElement(styles.BoldText, null, first),
        React.createElement(styles.BoldText, null, second),
        React.createElement(styles.BoldTextRight, null, third))); };
    var RowComponentLightBlueSecond = function (first, second) { return (React.createElement(styles.LightBlueRow, null,
        React.createElement(styles.BoldText, null, first),
        React.createElement(styles.BoldTextRight, null, second))); };
    var RowComponentBlueSecond = function (first, second) { return (React.createElement(styles.BlueRow, null,
        React.createElement(styles.BoldText, null, first),
        React.createElement(styles.BoldTextRight, null, second))); };
    var RowComponentWhite = function (first, second, third) { return (React.createElement(styles.WhiteRow, null,
        React.createElement(styles.Text, null, first),
        React.createElement(styles.Text, null, second),
        React.createElement(styles.TextRight, null, third))); };
    var RowComponentWhiteSecond = function (first, second) { return (React.createElement(styles.WhiteRow, null,
        React.createElement(styles.Text, null, first),
        React.createElement(styles.TextRight, null, second))); };
    return (React.createElement(Document, null,
        React.createElement(Page, { size: "A4", wrap: false },
            React.createElement(styles.PageView, null,
                React.createElement(styles.Header, { fixed: true },
                    React.createElement(styles.TextHeader, null, COMMON_EXPENSES_RECEIPTS),
                    React.createElement(styles.ImageHeader, { src: LogoPdf })),
                RowComponentBlue(HOLDER, CLIENT_NUMBER, RECEIPT_NUMBER),
                RowComponentWhite(holder, clientNumber, receiptNumber),
                RowComponentLightBlue(LOCATION, ISSUE_DATE, EXPIRATION_DATE_TEXT),
                RowComponentWhite(location, issueDate, expirationDate),
                RowComponentLightBlueSecond(COMMON_EXPENSES_DETAILS, currency === 'USD' || currency === 'dolares' ? USD : UYU),
                RowComponentWhiteSecond(description, totalAmount),
                RowComponentBlueSecond(TOTAL_AMOUNT, totalAmount),
                React.createElement(styles.BodyText, null, PAYMENT_DOESNT_CANCEL_DEBTS),
                React.createElement(styles.BodyImage, { src: BackgroundLogo }),
                React.createElement(styles.FooterContainer, null,
                    React.createElement(styles.Footer, { fixed: true },
                        React.createElement(styles.TextFooter, null, COPYRIGHT),
                        React.createElement(styles.ImagesFooterView, null,
                            React.createElement(styles.ImageFooter, { src: InstagramPng }),
                            React.createElement(styles.ImageFooter, { src: WebPng }))),
                    React.createElement(styles.PageNumberText, { render: function (_a) {
                            var pageNumber = _a.pageNumber;
                            return PAGE + " " + pageNumber;
                        } }))))));
};
export default PdfComponent;
