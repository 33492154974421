var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ALL_ENG, BACK_ERROR_KEY, BUILDINGS_KEY, FILES_KEY, MESSAGE_ID_KEY, MESSAGE_KEY, OLD_FILES_KEY, RECEIVER_KEY, SHOULD_SEND_KEY, SUBJECT_KEY, SUCCESS_FILE_KEY, TITLE_KEY, } from 'src/Utils';
export var init = function (initialValues) {
    return {
        receiver: initialValues.receiver,
        buildings: initialValues.building,
        subject: initialValues.subject,
        title: initialValues.title,
        message: initialValues.message,
        oldFiles: initialValues.oldFiles,
        files: initialValues.files,
        messageId: initialValues.messageId,
        shouldSend: initialValues.shouldSend,
        backError: initialValues.backError,
        successFiles: initialValues.successFiles,
        all: initialValues.all,
    };
};
export var reducer = function (state, action) {
    switch (action.type) {
        case RECEIVER_KEY:
            return __assign(__assign({}, state), { receiver: action.payload });
        case BUILDINGS_KEY:
            return __assign(__assign({}, state), { buildings: action.payload });
        case SUBJECT_KEY:
            return __assign(__assign({}, state), { subject: action.payload });
        case TITLE_KEY:
            return __assign(__assign({}, state), { title: action.payload });
        case MESSAGE_KEY:
            return __assign(__assign({}, state), { message: action.payload });
        case OLD_FILES_KEY:
            return __assign(__assign({}, state), { oldFiles: action.payload });
        case FILES_KEY:
            return __assign(__assign({}, state), { files: action.payload });
        case MESSAGE_ID_KEY:
            return __assign(__assign({}, state), { messageId: action.payload });
        case SHOULD_SEND_KEY:
            return __assign(__assign({}, state), { shouldSend: action.payload });
        case BACK_ERROR_KEY:
            return __assign(__assign({}, state), { backError: action.payload });
        case SUCCESS_FILE_KEY:
            return __assign(__assign({}, state), { successFiles: action.payload });
        case ALL_ENG:
            return __assign(__assign({}, state), { all: action.payload });
    }
};
