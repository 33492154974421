var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme } from 'src/Utils';
import { CheckExtra } from 'src/assets/Images';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  min-height: 45px;\n  justify-content: space-between;\n  padding: 0px 12px;\n  background-color: ", ";\n  border-radius: 7px;\n  align-items: center;\n"], ["\n  display: flex;\n  min-height: 45px;\n  justify-content: space-between;\n  padding: 0px 12px;\n  background-color: ", ";\n  border-radius: 7px;\n  align-items: center;\n"])), theme.palette.white[100]);
export var CheckboxContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  gap: 8px;\n  align-items: center;\n"], ["\n  display: flex;\n  gap: 8px;\n  align-items: center;\n"])));
export var Label = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  color: ", ";\n  font-family: ", ";\n  font-size: 12px;\n  font-weight: 400;\n  text-transform: lowercase;\n"], ["\n  display: flex;\n  color: ", ";\n  font-family: ", ";\n  font-size: 12px;\n  font-weight: 400;\n  text-transform: lowercase;\n"])), theme.palette.black[60], theme.typography.normal.raleway);
export var Price = styled(Label)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: 700;\n  text-transform: uppercase;\n"], ["\n  font-weight: 700;\n  text-transform: uppercase;\n"])));
export var CheckboxButton = styled.input(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  appearance: none;\n  text-align: center;\n  height: fit-content;\n\n  &:before {\n    box-sizing: border-box;\n    content: '';\n    display: block;\n    width: 21px;\n    height: 21px;\n    border-radius: 4px;\n\n    color: ", ";\n    border: 2px solid ", ";\n  }\n\n  :checked {\n    &:before {\n      box-sizing: border-box;\n      content: url(", ");\n      display: block;\n      width: 21px;\n      height: 21px;\n      border-radius: 4px;\n      cursor: pointer;\n      background-color: $ ", ";\n      color: ", ";\n      border: 2px solid ", ";\n\n      display: flex;\n      flex-direction: row;\n      justify-content: center;\n      align-items: center;\n    }\n  }\n"], ["\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  appearance: none;\n  text-align: center;\n  height: fit-content;\n\n  &:before {\n    box-sizing: border-box;\n    content: '';\n    display: block;\n    width: 21px;\n    height: 21px;\n    border-radius: 4px;\n\n    color: ", ";\n    border: 2px solid ", ";\n  }\n\n  :checked {\n    &:before {\n      box-sizing: border-box;\n      content: url(", ");\n      display: block;\n      width: 21px;\n      height: 21px;\n      border-radius: 4px;\n      cursor: pointer;\n      background-color: $ ", ";\n      color: ", ";\n      border: 2px solid ", ";\n\n      display: flex;\n      flex-direction: row;\n      justify-content: center;\n      align-items: center;\n    }\n  }\n"])), theme.palette.white[100], theme.palette.grey[10], CheckExtra, theme.palette.white[100], theme.palette.black[80], theme.palette.black[80]);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
