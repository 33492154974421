var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import moment from 'moment';
import { Text, View } from '@react-pdf/renderer';
import { DATE_FORMAT_MOMENT, BUILDING, TENANT_TEXT, OWNER_OF_UNIT, BUILDING_MENTIONED, AUTHORIZES, IDENTITY_CARD, TO_REPRESENT, ABOUT_ORDERS, DIRECTION_2, } from 'src/Utils/constants';
import styles from './styles';
export var PDFAuth = function (_a) {
    var date = _a.date, building = _a.building;
    return (React.createElement(View, { style: styles.section3 },
        React.createElement(Text, { style: [styles.fontTitle, { marginBottom: 16 }] },
            BUILDING,
            ": ",
            building),
        React.createElement(View, { style: styles.text },
            React.createElement("div", null,
                React.createElement(Text, { style: styles.fontNormal }, TENANT_TEXT)),
            React.createElement("div", { style: __assign(__assign({}, styles.completeLine), { width: '350px' }) })),
        React.createElement(View, { style: styles.text },
            React.createElement("div", null,
                React.createElement(Text, { style: styles.fontNormal }, OWNER_OF_UNIT)),
            React.createElement("div", { style: __assign(__assign({}, styles.completeLine), { width: '260px' }) }),
            React.createElement("div", null,
                React.createElement(Text, { style: styles.fontNormal }, BUILDING_MENTIONED))),
        React.createElement(View, { style: styles.text },
            React.createElement("div", null,
                React.createElement(Text, { style: styles.fontNormal }, AUTHORIZES)),
            React.createElement("div", { style: __assign(__assign({}, styles.completeLine), { width: '200px' }) }),
            React.createElement("div", null,
                React.createElement(Text, { style: styles.fontNormal }, IDENTITY_CARD)),
            React.createElement("div", { style: __assign(__assign({}, styles.completeLine), { width: '200px' }) })),
        React.createElement(View, { style: styles.text },
            React.createElement(Text, { style: styles.fontNormal }, TO_REPRESENT)),
        React.createElement(View, { style: styles.text },
            React.createElement(Text, { style: styles.fontNormal },
                ABOUT_ORDERS,
                React.createElement(Text, { style: { fontWeight: 'bold' } }, moment(date).format(DATE_FORMAT_MOMENT)),
                ". ",
                DIRECTION_2,
                ",",
                ' ',
                React.createElement(Text, { style: { fontWeight: 'bold' } }, moment().format(DATE_FORMAT_MOMENT))))));
};
