import React, { useState } from 'react';
import { Container, Description, ContentDiv, Line, Div } from './styles';
import { ContactModal } from '..';
var ListItem = function (_a) {
    var data = _a.data, isLastItem = _a.isLastItem;
    var _b = useState(false), isModalOpen = _b[0], setIsModalOpen = _b[1];
    return (React.createElement(Container, null,
        React.createElement(ContentDiv, { onClick: function () { return setIsModalOpen(function (prev) { return !prev; }); } },
            React.createElement(Div, null,
                React.createElement(Description, null, data.first))),
        !isLastItem && React.createElement(Line, null),
        React.createElement(ContactModal, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, data: data })));
};
export default ListItem;
