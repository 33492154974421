import React from 'react';
import { ADMINISTRATION, INSURANCE, OUR_SERVICES, LEGAL_DEPARTMENT, REAL_ESTATE, ADMIN_DESCRIPTION, INSURANCE_DESCRIPTION, REAL_ESTATE_DESCRIPTION, LEGAL_DEPARTMENT_DESCRIPTION, OUR_SERVICES_DESCRITPION, } from '../../Utils';
import { OurServicesComponent } from '../../Components';
import { Container } from './styles';
import { LegalDepartment, Administration, RealEstate, Insurance, } from '../../assets/Images';
var items = {
    title: OUR_SERVICES,
    description: OUR_SERVICES_DESCRITPION,
    items: [
        {
            title: ADMINISTRATION,
            description: ADMIN_DESCRIPTION,
            image: Administration,
        },
        {
            title: REAL_ESTATE,
            description: REAL_ESTATE_DESCRIPTION,
            image: RealEstate,
        },
        {
            title: LEGAL_DEPARTMENT,
            description: LEGAL_DEPARTMENT_DESCRIPTION,
            image: LegalDepartment,
        },
        { title: INSURANCE, description: INSURANCE_DESCRIPTION, image: Insurance },
    ],
};
var OurServices = function () { return (React.createElement(Container, null,
    React.createElement(OurServicesComponent, { items: items }))); };
export default OurServices;
