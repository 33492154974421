var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M, XXL } from '../../Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-sizing: border-box;\n  width: 100%;\n  min-height: 100vh;\n  padding: 40px;\n  display: flex;\n  flex-direction: row-reverse;\n  justify-content: flex-end;\n  gap: 40px;\n  @media (max-width: ", "px) {\n    height: auto;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n"], ["\n  box-sizing: border-box;\n  width: 100%;\n  min-height: 100vh;\n  padding: 40px;\n  display: flex;\n  flex-direction: row-reverse;\n  justify-content: flex-end;\n  gap: 40px;\n  @media (max-width: ", "px) {\n    height: auto;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n"])), XXL);
export var Column = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 58%;\n  max-width: 780px;\n  min-width: 670px;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  gap: 40px;\n  @media (max-width: ", "px) {\n    width: 100%;\n  }\n"], ["\n  width: 58%;\n  max-width: 780px;\n  min-width: 670px;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  gap: 40px;\n  @media (max-width: ", "px) {\n    width: 100%;\n  }\n"])), XXL);
export var TopContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  gap: 40px;\n  width: 100%;\n  height: 100%;\n  align-items: flex-start;\n  padding-left: 8px;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  gap: 40px;\n  width: 100%;\n  height: 100%;\n  align-items: flex-start;\n  padding-left: 8px;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    flex-direction: column;\n  }\n"])), theme.palette.admin.primary[50], M);
export var Title = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 24px;\n  font-family: ", ";\n  width: 100%;\n  margin-top: 40px;\n  margin-left: 40px;\n  background-color: ", ";\n  color: ", ";\n  @media (max-width: ", "px) {\n    margin-left: 40px;\n    font-size: 18px;\n    font-family: ", ";\n    color: ", ";\n  }\n"], ["\n  font-size: 24px;\n  font-family: ", ";\n  width: 100%;\n  margin-top: 40px;\n  margin-left: 40px;\n  background-color: ", ";\n  color: ", ";\n  @media (max-width: ", "px) {\n    margin-left: 40px;\n    font-size: 18px;\n    font-family: ", ";\n    color: ", ";\n  }\n"])), theme.typography.bold.ralewayBold, theme.palette.admin.primary[50], theme.palette.admin.primary[75], M, theme.typography.OpenSans, theme.palette.black[100]);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
