import React, { useState } from 'react';
import RealEstateMultiSelect from 'src/Components/Common/RealEstateMultiSelect/RealEstateMultiSelect';
import useFilter from 'src/context/FilterContext';
import { LOCATION_X2, NEIGHBORHOOD, PROPERTY_TYPE, REAL_ESTATE_SEARCH_PLACEHOLDERS, RENT, SALE, SEARCH, } from 'src/Utils';
import { Button as CommonButton } from 'src/Components/Common';
import { SearchContainer, SearchFields, OptionButton, SearchWrapper, ButtonGroup, InputContainer, Label, ContentContainer, ButtonContainer, } from './styles';
var RealEstateSearch = function (_a) {
    var onSearch = _a.onSearch;
    var _b = useState(false), isSale = _b[0], setIsSale = _b[1];
    var _c = useState(null), selectedCity = _c[0], setSelectedCity = _c[1];
    var _d = useState([]), selectedPropertyTypes = _d[0], setSelectedPropertyTypes = _d[1];
    var _e = useState([]), selectedNeighborhoods = _e[0], setSelectedNeighborhoods = _e[1];
    var _f = useFilter(), defaultValues = _f.defaultValues, isLoading = _f.isLoading;
    var neighborhood = defaultValues.neighborhood, city = defaultValues.city, property_type = defaultValues.property_type;
    var onClick = function () {
        onSearch(isSale, selectedCity, selectedNeighborhoods, selectedPropertyTypes);
    };
    var ButtonOption = function (_a) {
        var isSaleOption = _a.isSaleOption, label = _a.label;
        return (React.createElement(OptionButton, { onClick: function () { return setIsSale(isSaleOption); }, className: isSale === isSaleOption ? 'selected' : '' }, label));
    };
    if (isLoading)
        return null;
    return (React.createElement(SearchWrapper, null,
        React.createElement(SearchContainer, null,
            React.createElement(ContentContainer, null,
                React.createElement(ButtonGroup, null,
                    React.createElement(ButtonOption, { isSaleOption: false, label: RENT }),
                    React.createElement(ButtonOption, { isSaleOption: true, label: SALE })),
                React.createElement(SearchFields, null,
                    React.createElement(InputContainer, null,
                        React.createElement(Label, null, LOCATION_X2),
                        React.createElement(RealEstateMultiSelect, { options: city, setSelectedOption: setSelectedCity, type: "city", inputLabel: REAL_ESTATE_SEARCH_PLACEHOLDERS.city, isMulti: false })),
                    React.createElement(InputContainer, null,
                        React.createElement(Label, null, NEIGHBORHOOD),
                        React.createElement(RealEstateMultiSelect, { options: neighborhood, setSelectedOption: setSelectedNeighborhoods, type: "neighborhood", inputLabel: REAL_ESTATE_SEARCH_PLACEHOLDERS.neighborhood })),
                    React.createElement(InputContainer, null,
                        React.createElement(Label, null, PROPERTY_TYPE),
                        React.createElement(RealEstateMultiSelect, { options: property_type, setSelectedOption: setSelectedPropertyTypes, type: "propertyType", inputLabel: REAL_ESTATE_SEARCH_PLACEHOLDERS.propertyType })),
                    React.createElement(ButtonContainer, null,
                        React.createElement(CommonButton, { onClick: onClick, className: "full-width" }, SEARCH)))))));
};
export default RealEstateSearch;
