import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { AdminLayout } from 'src/Layouts';
import { Container, Title, DetailsContainer } from './styles';
import { CONFIRM_NEW_PASSWORD, ACTUAL_PASSWORD, NEW_PASSWORD, CHANGE_PASSWORD, } from 'src/Utils';
import { ROUTES, CHANGE, CURRENT_PASSWORD_KEY, PASSWORD_CONFIRMATION_KEY, PASSWORD_KEY2, BACK_ERROR, } from 'src/Utils/constants';
import { AdminForm } from 'src/Components/Common';
import { changePassword } from './duck';
var personalData = [
    {
        key: CURRENT_PASSWORD_KEY,
        label: ACTUAL_PASSWORD,
        value: '',
        className: 'white',
        isPassword: true,
    },
    {
        key: PASSWORD_KEY2,
        label: NEW_PASSWORD,
        value: '',
        className: 'white',
        isPassword: true,
    },
    {
        key: PASSWORD_CONFIRMATION_KEY,
        label: CONFIRM_NEW_PASSWORD,
        value: '',
        className: 'white',
        isPassword: true,
    },
];
var ChangePassword = function () {
    var history = useHistory();
    var _a = useState(''), backError = _a[0], setBackError = _a[1];
    var account = ROUTES.account;
    var onCancel = function () { return history.push(account); };
    var _b = useMutation(changePassword, {
        onSuccess: function () { return history.push(account); },
        onError: function () { return setBackError(BACK_ERROR); },
    }), mutateChangePassword = _b[0], isDataLoading = _b[1].isLoading;
    var onSubmit = function (data) {
        return mutateChangePassword({
            current_password: data[CURRENT_PASSWORD_KEY],
            password: data[PASSWORD_KEY2],
            password_confirmation: data[PASSWORD_CONFIRMATION_KEY],
        });
    };
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, null, CHANGE_PASSWORD),
            React.createElement(DetailsContainer, { className: "form" },
                React.createElement(AdminForm, { inputs: personalData, onSubmit: onSubmit, buttonLabel: CHANGE, onCancel: onCancel, isLoading: isDataLoading, backError: backError, isPassword: true })))));
};
export default ChangePassword;
