import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { downloadPDF, RECEIPT_KEY, SETTLEMENT_KEY } from 'src/Utils';
import { usePDF } from '@react-pdf/renderer';
import { submitPDFMobile } from '../DocumentsPages/CommonExpensesPage/duck/services';
var useGeneratePDF = function (document, data, receipt, id) {
    var _a = usePDF({
        document: document,
    }), instance = _a[0], updateSettlement = _a[1];
    var _b = useMutation(submitPDFMobile, {
        onSuccess: function () {
            (instance === null || instance === void 0 ? void 0 : instance.url) &&
                downloadPDF(instance.url, receipt
                    ? RECEIPT_KEY + "-" + (data === null || data === void 0 ? void 0 : data.receipt_number) + ".pdf"
                    : SETTLEMENT_KEY + "-" + (data === null || data === void 0 ? void 0 : data.settlementNumber) + ".pdf");
        },
    }), mutatePDF = _b[0], _c = _b[1], uploadPDFResponse = _c.data, error = _c.error;
    useEffect(function () {
        var _a;
        var pdf = (_a = uploadPDFResponse === null || uploadPDFResponse === void 0 ? void 0 : uploadPDFResponse.data) === null || _a === void 0 ? void 0 : _a.pdf;
        if (pdf && receipt) {
            setTimeout(function () { window.location = pdf; }, 250);
        }
    }, [uploadPDFResponse]);
    var _d = useState(false), updatedDocument = _d[0], setUpdatedDocument = _d[1];
    useEffect(function () {
        data && updateSettlement();
    }, [data]);
    useEffect(function () {
        if (data && !instance.loading && instance.blob && id) {
            var file = new File([instance.blob], receipt
                ? RECEIPT_KEY + "-" + (data === null || data === void 0 ? void 0 : data.receipt_number) + ".pdf"
                : SETTLEMENT_KEY + "-" + (data === null || data === void 0 ? void 0 : data.settlementNumber) + ".pdf", {
                type: 'application/pdf',
            });
            if (updatedDocument && receipt)
                mutatePDF({
                    file: file,
                    url: "/mobile/common_expenses_receipts/" + id + "/pdf",
                });
            else if (updatedDocument && !receipt)
                mutatePDF({
                    file: file,
                    url: "/mobile/settlements/" + id + "/pdf",
                });
            setUpdatedDocument(true);
        }
    }, [instance.loading, instance.blob, data]);
    var loading = !uploadPDFResponse && !error;
    return { loading: loading, error: error };
};
export default useGeneratePDF;
