import React from 'react';
import { Head, Row, Element, SortLabel, Img, SearchInput, } from '../../Common/Table/TableHeader/styles';
import { FilterArrow } from 'src/assets/Images';
import { TOWERS_TEXT, APARTMENTS_SECOND_TEXT, SEARCH, } from 'src/Utils/constants';
var TableHeader = function (_a) {
    var fields = _a.fields, widths = _a.widths, setOrder = _a.setOrder, setOrderBy = _a.setOrderBy, orderBy = _a.orderBy, order = _a.order, setStateOrder = _a.setStateOrder, orderByStateIndex = _a.orderByStateIndex, className = _a.className, setSearchedItem = _a.setSearchedItem, searchedItem = _a.searchedItem;
    var handleRequestSort = function (property) {
        setStateOrder === null || setStateOrder === void 0 ? void 0 : setStateOrder(function (prev) { return (prev === 3 ? 0 : prev + 1); });
        var isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    return (React.createElement(Head, null,
        React.createElement(Row, null,
            fields.map(function (_a, index) {
                var id = _a.id, label = _a.label, dontSort = _a.dontSort;
                return (React.createElement(Element, { padding: "none", align: "left", style: { width: widths[index] }, sortDirection: orderBy === id ? order : false, key: id, className: id === TOWERS_TEXT ||
                        id === APARTMENTS_SECOND_TEXT
                        ? 'small center'
                        : 'small' },
                    React.createElement(SortLabel, { active: orderBy === id, direction: orderBy === id ? order : 'asc', onClick: function () { return handleRequestSort(id); } },
                        label,
                        label && !dontSort && React.createElement(Img, { src: FilterArrow }))));
            }),
            setSearchedItem && (React.createElement(Element, { padding: "none", style: { width: widths[5] } },
                React.createElement(SearchInput, { placeholder: SEARCH, value: searchedItem, className: className, onChange: function (e) { return setSearchedItem(e.target.value); } }))))));
};
export default TableHeader;
