var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { components } from 'react-select';
import { ValueContainerDiv } from './styles';
var ValueContainer = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    var text = '';
    var isSomeSelected = props.selectProps.value && props.selectProps.value.length > 0;
    if (isSomeSelected) {
        text = props.selectProps.value.map(function (option) { return option.label; }).join(', ');
        if (text.length > 22) {
            text = text.slice(0, 21) + '...';
        }
    }
    return (React.createElement(components.ValueContainer, __assign({}, props),
        React.createElement(ValueContainerDiv, null, isSomeSelected ? text : children)));
};
export default ValueContainer;
