import React, { useState, useContext, createContext } from 'react';
var initialState = {
    totalPages: 1,
    currentPage: 1,
    setCurrentPage: function (p) { },
    setTotalPages: function (p) { },
    incrementPage: function () { },
    decrementPage: function () { }
};
var PaginatorContext = createContext(initialState);
var usePaginator = function () { return useContext(PaginatorContext); };
export default usePaginator;
export var PaginatorProvider = function (_a) {
    var children = _a.children;
    var _b = useState(1), currentPage = _b[0], setCurrentPage = _b[1];
    var _c = useState(1), totalPages = _c[0], setTotalPages = _c[1];
    var incrementPage = function () {
        if (currentPage < totalPages)
            setCurrentPage(currentPage + 1);
    };
    var decrementPage = function () {
        if (currentPage > 1)
            setCurrentPage(currentPage - 1);
    };
    return (React.createElement(PaginatorContext.Provider, { value: { currentPage: currentPage, setCurrentPage: setCurrentPage, totalPages: totalPages, setTotalPages: setTotalPages, incrementPage: incrementPage, decrementPage: decrementPage } }, children));
};
