import React from 'react';
import { ImageSkeleton, ImagesContainer, InnerContainer, PrincipalImage, SecondaryImage, SecondaryImagesRow, } from './styles';
export var ImagesPDF = function (_a) {
    var images = _a.images;
    return (React.createElement(ImagesContainer, null,
        images.length > 0 ? (React.createElement(PrincipalImage, { src: images[0].original || undefined })) : (React.createElement(ImageSkeleton, null)),
        images.length > 1 && (React.createElement(InnerContainer, null,
            React.createElement(SecondaryImagesRow, { style: { marginBottom: 2 } }, images === null || images === void 0 ? void 0 : images.slice(1, 3).map(function (image) { return (React.createElement(SecondaryImage, { src: image.original || undefined, key: image.original, style: { marginHorizontal: 2 } })); })),
            React.createElement(SecondaryImagesRow, { style: { marginTop: 2 } }, images.slice(3, 5).map(function (image) { return (React.createElement(SecondaryImage, { src: image.original || undefined, key: image.original, style: {
                    marginHorizontal: 2,
                } })); }))))));
};
export default ImagesPDF;
