import React from 'react';
import { LinkedIn, Instagram, Facebook } from '../../assets/Images';
import { US, HOME, US_TEXT, HOME_TEXT, COPYRIGHT, CONTACT, CONTACT_TEXT, LINKEDIN_URL, FACEBOOK_URL, INSTAGRAM_URL, } from '../../Utils';
import { FooterComponent } from '../../Components';
var items = [
    { text: HOME, key: HOME_TEXT },
    { text: US, key: US_TEXT },
    { text: CONTACT, key: CONTACT_TEXT },
];
var icons = [
    { icon: LinkedIn, url: LINKEDIN_URL },
    { icon: Facebook, url: FACEBOOK_URL },
    { icon: Instagram, url: INSTAGRAM_URL },
];
var Footer = function () { return (React.createElement(FooterComponent, { items: items, icons: icons, text: COPYRIGHT })); };
export default Footer;
