var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import { L, M } from "src/Utils";
import { RealEstateHome, RealEstateHomeMobile } from 'src/assets/Images';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-top: 100px;\n  min-height: 80vh;\n  @media (max-width: ", "px) {\n    min-height: 80vh;\n  }\n  @media (max-width: ", "px) {\n    min-height: 70vh;\n  }\n"], ["\n  padding-top: 100px;\n  min-height: 80vh;\n  @media (max-width: ", "px) {\n    min-height: 80vh;\n  }\n  @media (max-width: ", "px) {\n    min-height: 70vh;\n  }\n"])), L, M);
export var ImageWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 50vh;\n  background-image: url(", ");\n  background-size: cover;\n  @media (max-width: ", "px) {\n    height: 32vh;\n    background-image: url(", ");\n  }\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 50vh;\n  background-image: url(", ");\n  background-size: cover;\n  @media (max-width: ", "px) {\n    height: 32vh;\n    background-image: url(", ");\n  }\n"])), RealEstateHome, M, RealEstateHomeMobile);
export var SearchContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 34vh;\n  @media (max-width: ", "px) {\n    margin-top: 16vh;\n  }\n"], ["\n  margin-top: 34vh;\n  @media (max-width: ", "px) {\n    margin-top: 16vh;\n  }\n"])), M);
var templateObject_1, templateObject_2, templateObject_3;
