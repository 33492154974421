var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import styled from "@emotion/styled";
import { M, theme } from "src/Utils";
export var SelectContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 230px;\n  @media (max-width: ", "px) {\n    width: 140px;\n  }\n"], ["\n  width: 230px;\n  @media (max-width: ", "px) {\n    width: 140px;\n  }\n"])), M);
export var MobileSelectContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 120px;\n  &:hover {\n    cursor: pointer;\n  }\n"], ["\n  width: 120px;\n  &:hover {\n    cursor: pointer;\n  }\n"])));
export var OptionContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n"])));
export var Hr = styled.hr(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  margin: 16px 0 -8px;\n"], ["\n  border: 1px solid ", ";\n  margin: 16px 0 -8px;\n"])), theme.palette.grey[18]);
export var optionsCustomStyles = {
    placeholder: function (defaultStyles) { return (__assign(__assign({}, defaultStyles), { textTransform: 'none', color: theme.palette.primary[100], fontWeight: 700, fontFamily: theme.typography.bold.ralewayBold, paddingLeft: '4px' })); },
    singleValue: function (defaultStyles) { return (__assign(__assign({}, defaultStyles), { textTransform: 'none', color: theme.palette.primary[100], fontWeight: 700, fontFamily: theme.typography.bold.ralewayBold, paddingLeft: '4px' })); },
    control: function (base) { return (__assign(__assign({}, base), { boxShadow: '0px 0px 8px 0px rgba(173, 173, 173, 0.25)', borderRadius: '8px', backgroundColor: theme.palette.white[100], border: 'none', '&:hover': {
            cursor: 'pointer',
        } })); },
    menu: function (base) { return (__assign(__assign({}, base), { boxShadow: '0px 0px 8px 0px rgba(173, 173, 173, 0.25)', borderRadius: '8px' })); },
    option: function (base, _a) {
        var isSelected = _a.isSelected, isFocused = _a.isFocused;
        var palette = theme.palette, typography = theme.typography;
        var color = isSelected || isFocused ? palette.blue[5] : palette.black[100];
        var fontFamily = isSelected
            ? typography.bold.ralewayBold
            : typography.normal.raleway;
        var cursor = isFocused ? 'pointer' : 'default';
        return {
            textTransform: 'none !important',
            alignItems: 'center',
            display: 'flex',
            fontSize: '14px',
            padding: '16px 16px 8px',
            backgroundColor: palette.white[100],
            color: color,
            fontFamily: fontFamily,
            cursor: cursor,
        };
    },
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
