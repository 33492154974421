import React from 'react';
import { Head, Row, Element, SortLabel, Img } from './styles';
import { FilterArrow } from 'src/assets/Images';
var TableHeader = function (_a) {
    var fields = _a.fields, widths = _a.widths, setOrder = _a.setOrder, setOrderBy = _a.setOrderBy, orderBy = _a.orderBy, order = _a.order, className = _a.className;
    var handleRequestSort = function (property) {
        var isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    return (React.createElement(Head, null,
        React.createElement(Row, null, fields.map(function (_a, index) {
            var id = _a.id, label = _a.label;
            return (React.createElement(Element, { padding: "none", align: "left", style: { width: widths[index] }, sortDirection: orderBy === id ? order : false, key: id, className: className },
                React.createElement(SortLabel, { active: orderBy === id, direction: orderBy === id ? order : 'asc', onClick: function () { return handleRequestSort(id); } },
                    label,
                    label != '' && React.createElement(Img, { src: FilterArrow }))));
        }))));
};
export default TableHeader;
