import React from 'react';
import { XXL } from 'src/Utils';
import { useWindowWidth } from 'src/useWindowWidth';
import Paginator from 'src/Components/Paginator';
import { PropertyCard } from '../PropertyCard';
import SortSelect from '../Filter/SortSelect/SortSelect';
import { FilterMobile } from '../Filter/FilterMobile/FilterMobile';
import { FilterProvider } from 'src/context/FilterContext';
import EmptyProperties from './EmptyProperties/EmptyProperties';
import { LoadingSkeleton } from '../PropertyCard/LoadingSkeleton';
import { buildPropertyNumberMessage } from './utils';
import { Column, Container, TopContainer, Text } from './styles';
import { Row } from '../Filter/styles';
export var Properties = function (_a) {
    var _b = _a.properties, properties = _b === void 0 ? [] : _b, total = _a.total, isLoading = _a.isLoading;
    var windowWidth = useWindowWidth();
    var hasProperties = !isLoading && properties.length > 0;
    var isMobile = windowWidth < XXL;
    var propertiesNumberMsg = buildPropertyNumberMessage(total, isMobile);
    return (React.createElement(Container, null,
        React.createElement(Column, { gap: "16px" },
            React.createElement(TopContainer, null,
                hasProperties && (React.createElement(Text, { disable: isLoading }, propertiesNumberMsg)),
                React.createElement(Row, { gap: "12px" },
                    React.createElement(FilterProvider, null, isMobile && React.createElement(FilterMobile, null)),
                    hasProperties && React.createElement(SortSelect, null))),
            isLoading
                ? Array.from(Array(3).keys()).map(function () { return React.createElement(LoadingSkeleton, null); })
                : properties.map(function (property) { return (React.createElement(PropertyCard, { property: property, key: property.id })); }),
            hasProperties && React.createElement(Paginator, null),
            !hasProperties && React.createElement(EmptyProperties, null))));
};
