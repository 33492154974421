var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { TableRow, TableCell, TableHead, TableContainer as MuTableContainer, } from '@material-ui/core';
import { M, theme } from 'src/Utils';
export var Head = styled(TableHead)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 35px;\n  border-radius: 2px;\n  background-color: ", ";\n  font-size: 18px;\n  border-bottom: 2px solid black;\n"], ["\n  width: 100%;\n  height: 35px;\n  border-radius: 2px;\n  background-color: ", ";\n  font-size: 18px;\n  border-bottom: 2px solid black;\n"])), theme.palette.admin.primary[55]);
export var MainContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  width: 100vw;\n  height: 100vh;\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  width: 100vw;\n  height: 100vh;\n  justify-content: center;\n  align-items: center;\n"])));
export var Cell = styled(TableCell)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  padding: 0px 0px 0px 22px !important;\n  height: 35px;\n  border: none !important;\n  width: 25%;\n  &.MuiTableCell-root {\n    font-family: unset;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  padding: 0px 0px 0px 22px !important;\n  height: 35px;\n  border: none !important;\n  width: 25%;\n  &.MuiTableCell-root {\n    font-family: unset;\n  }\n"])));
export var Row = styled(TableRow)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100vw;\n  display: flex;\n  background-color: ", ";\n  align-items: center;\n  border-radius: 2px;\n  box-shadow: none !important;\n  &:nth-child(even) {\n    background-color: ", ";\n    &:hover {\n      background-color: ", " !important;\n    }\n  }\n  &:hover {\n    background-color: ", " !important;\n  }\n"], ["\n  width: 100vw;\n  display: flex;\n  background-color: ", ";\n  align-items: center;\n  border-radius: 2px;\n  box-shadow: none !important;\n  &:nth-child(even) {\n    background-color: ", ";\n    &:hover {\n      background-color: ", " !important;\n    }\n  }\n  &:hover {\n    background-color: ", " !important;\n  }\n"])), theme.palette.white[100], theme.palette.admin.primary[15], theme.palette.admin.primary[15], theme.palette.white[100]);
export var Container = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  min-height: 100vh;\n  height: 100%;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 0px 5%;\n  flex-direction: column;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    padding: 0px;\n  }\n"], ["\n  display: flex;\n  min-height: 100vh;\n  height: 100%;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 0px 5%;\n  flex-direction: column;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    padding: 0px;\n  }\n"])), theme.palette.admin.primary[50], M);
export var BackContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
export var Title = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 24px;\n  display: flex;\n  gap: 20px;\n  font-family: ", ";\n  width: 100%;\n  margin-bottom: 30px;\n  margin-top: 5%;\n  background-color: ", ";\n  color: ", ";\n  @media (max-width: ", "px) {\n    margin-left: 0px;\n    font-size: 18px;\n    font-family: ", ";\n    color: ", ";\n    padding-left: 28px;\n    align-self: baseline;\n    width: auto;\n  }\n"], ["\n  font-size: 24px;\n  display: flex;\n  gap: 20px;\n  font-family: ", ";\n  width: 100%;\n  margin-bottom: 30px;\n  margin-top: 5%;\n  background-color: ", ";\n  color: ", ";\n  @media (max-width: ", "px) {\n    margin-left: 0px;\n    font-size: 18px;\n    font-family: ", ";\n    color: ", ";\n    padding-left: 28px;\n    align-self: baseline;\n    width: auto;\n  }\n"])), theme.typography.bold.ralewayBold, theme.palette.admin.primary[50], theme.palette.admin.primary[75], M, theme.typography.OpenSans, theme.palette.black[100]);
export var TableContainer = styled(MuTableContainer)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  border-radius: 4px;\n  @media (max-width: ", "px) {\n    width: 80% !important;\n  }\n"], ["\n  border-radius: 4px;\n  @media (max-width: ", "px) {\n    width: 80% !important;\n  }\n"])), M);
export var Element = styled(TableCell)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  align-self: center;\n  height: 44px;\n  max-height: 44px;\n  padding: 0px 0px 0px 20px !important;\n  font-size: 18px !important;\n  border: none !important;\n  font-family: ", " !important;\n"], ["\n  align-self: center;\n  height: 44px;\n  max-height: 44px;\n  padding: 0px 0px 0px 20px !important;\n  font-size: 18px !important;\n  border: none !important;\n  font-family: ", " !important;\n"])), theme.typography.bold.ralewayBold);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
