import React, { useState } from 'react';
import { TextInput, Popover } from 'src/Components/Common';
import CalendarComponent from 'src/Components/Common/Calendar';
import { DownloadButton } from './Components/PDF/DownloadButton';
import { FiltersContainer, FilterItem, FilterItemButton } from './styles';
import { CLIENT_CODE, STATE, PAYMENT_DATE_FROM, PAYMENT_DATE_TO, PAYMENT_PROVIDER } from 'src/Utils/constants';
var Filters = function (_a) {
    var startDate = _a.startDate, endDate = _a.endDate, setStartDate = _a.setStartDate, setEndDate = _a.setEndDate, customerCode = _a.customerCode, setCustomerCode = _a.setCustomerCode, paymentProvider = _a.paymentProvider, setPaymentProvider = _a.setPaymentProvider, paymentStatus = _a.paymentStatus, setPaymentStatus = _a.setPaymentStatus;
    var _b = useState(null), anchorRefPaymentStatus = _b[0], setAnchorRefPaymentStatus = _b[1];
    var _c = useState(null), anchorRefPaymentProvider = _c[0], setAnchorRefPaymentProvider = _c[1];
    var paymentProviderOptions = [
        { label: 'Todos', value: 'all' },
        { label: 'Banred', value: 'banred' },
        { label: 'Abitab', value: 'abitab' },
        { label: 'RedPagos', value: 'redpagos' },
        { label: 'Sistarbanc', value: 'sistarbanc' }
    ];
    var paymentStatusOptions = [
        { label: 'Todos', value: 'all' },
        { label: 'Pendiente', value: 'pending' },
        { label: 'En progreso', value: 'in_progress' },
        { label: 'Informado', value: 'informed' }
    ];
    return (React.createElement(FiltersContainer, null,
        React.createElement(FilterItem, null,
            React.createElement("label", null, CLIENT_CODE),
            React.createElement(TextInput, { value: customerCode, onChange: function (_a) {
                    var target = _a.target;
                    return setCustomerCode(target.value);
                } })),
        React.createElement(FilterItem, null,
            React.createElement("label", null, PAYMENT_PROVIDER),
            React.createElement(TextInput, { onClick: function (e) { return setAnchorRefPaymentProvider(e.currentTarget); }, onChange: function () { return null; }, value: paymentProvider === null || paymentProvider === void 0 ? void 0 : paymentProvider.label }),
            React.createElement(Popover, { data: paymentProviderOptions, handleOutsideClick: function () { return setAnchorRefPaymentProvider(null); }, anchorRef: anchorRefPaymentProvider, setSelected: setPaymentProvider, isFilter: false, className: 'width' })),
        React.createElement(FilterItem, null,
            React.createElement("label", null, STATE),
            React.createElement(TextInput, { onClick: function (e) { return setAnchorRefPaymentStatus(e.currentTarget); }, value: paymentStatus === null || paymentStatus === void 0 ? void 0 : paymentStatus.label }),
            React.createElement(Popover, { data: paymentStatusOptions, handleOutsideClick: function () { return setAnchorRefPaymentStatus(null); }, anchorRef: anchorRefPaymentStatus, setSelected: setPaymentStatus, isFilter: false, className: 'width' })),
        React.createElement(FilterItem, null,
            React.createElement("label", null, PAYMENT_DATE_FROM),
            React.createElement(CalendarComponent, { selected: startDate, onChange: setStartDate, selectsStart: false, startDate: startDate, customInput: React.createElement(TextInput, null) })),
        React.createElement(FilterItem, null,
            React.createElement("label", null, PAYMENT_DATE_TO),
            React.createElement(CalendarComponent, { selected: endDate, onChange: setEndDate, selectsStart: false, startDate: endDate, customInput: React.createElement(TextInput, null) })),
        React.createElement(FilterItemButton, null,
            React.createElement(DownloadButton, { customerCode: customerCode, paymentProvider: paymentProvider, paymentStatus: paymentStatus, startDate: startDate, endDate: endDate }))));
};
export default Filters;
