import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import { DATE, BUILDING, CALL, PLACE, LONG_DATE_FORMAT, } from 'src/Utils/constants';
import styles from './styles';
export var PDFFirstSection = function (_a) {
    var building = _a.building, date = _a.date, schedules = _a.schedules, place = _a.place;
    return (React.createElement(View, { style: styles.section1 },
        React.createElement("div", null,
            React.createElement(Text, { style: styles.fontTitle },
                BUILDING,
                ":"),
            React.createElement(Text, { style: styles.fontNormal }, building)),
        React.createElement("div", null,
            React.createElement(Text, { style: styles.fontTitle },
                DATE,
                ":"),
            React.createElement(Text, { style: styles.date }, moment(date).locale('es').format(LONG_DATE_FORMAT))),
        React.createElement("div", null,
            React.createElement(Text, { style: styles.fontTitle },
                CALL,
                ":"), schedules === null || schedules === void 0 ? void 0 :
            schedules.map(function (hour, iterator) { return (React.createElement(Text, { style: styles.fontNormal, key: iterator },
                iterator + 1,
                " ",
                CALL.substr(0, 4),
                ". ",
                hour,
                "hs. ",
                iterator === 1 && "*")); })),
        React.createElement("div", null,
            React.createElement(Text, { style: styles.fontTitle },
                PLACE,
                ":"),
            React.createElement(Text, { style: styles.fontNormal }, place))));
};
