var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, L } from '../../../Utils';
export var Button = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 16px;\n  white-space: nowrap;\n  cursor: pointer;\n  ", ";\n  border: none;\n  transition: 0.3s ease;\n  width: 100%;\n  max-width: 140px;\n  margin-top: 20px;\n  font-size: 18px;\n  line-height: 21px;\n  font-family: ", ";\n  &:focus {\n    border: none;\n    outline: none;\n    text-decoration: underline;\n  }\n  &.sections-button {\n    font-weight: 100;\n  }\n  &.add-margin {\n    margin-bottom: 20px;\n  }\n  @media (max-width: ", "px) {\n    max-width: 500px;\n  }\n"], ["\n  font-size: 16px;\n  white-space: nowrap;\n  cursor: pointer;\n  ",
    ";\n  border: none;\n  transition: 0.3s ease;\n  width: 100%;\n  max-width: 140px;\n  margin-top: 20px;\n  font-size: 18px;\n  line-height: 21px;\n  font-family: ", ";\n  &:focus {\n    border: none;\n    outline: none;\n    text-decoration: underline;\n  }\n  &.sections-button {\n    font-weight: 100;\n  }\n  &.add-margin {\n    margin-bottom: 20px;\n  }\n  @media (max-width: ", "px) {\n    max-width: 500px;\n  }\n"])), function (_a) {
    var shouldShowMenu = _a.shouldShowMenu;
    return shouldShowMenu
        ? "background-color: " + theme.palette.primary[50] + ";\n         color: " + theme.palette.white[100] + ";\n         &:hover {\n          background-color: " + theme.palette.primary[15] + ";\n        }"
        : "background-color: " + theme.palette.primary[50] + ";;\n          color: " + theme.palette.white[100] + ";";
}, theme.typography.normal.raleway, L);
export var SectionDiv = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: center;\n"])));
var templateObject_1, templateObject_2;
