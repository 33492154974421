var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M } from '../../../Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  padding: 20px;\n  flex-direction: column;\n  height: 45px;\n  width: 160px;\n  margin-bottom: 10px;\n  background-color: ", ";\n  border-radius: 10px;\n  &.center {\n    margin-left: auto;\n    margin-right: auto;\n  }\n  @media (max-width: ", "px) {\n    &:first-child {\n      margin-top: 40px;\n    }\n    background-color: inherit;\n    width: 100%;\n    max-width: inherit;\n    align-items: center;\n    padding: 20px 0px;\n  }\n"], ["\n  display: flex;\n  padding: 20px;\n  flex-direction: column;\n  height: 45px;\n  width: 160px;\n  margin-bottom: 10px;\n  background-color: ", ";\n  border-radius: 10px;\n  &.center {\n    margin-left: auto;\n    margin-right: auto;\n  }\n  @media (max-width: ", "px) {\n    &:first-child {\n      margin-top: 40px;\n    }\n    background-color: inherit;\n    width: 100%;\n    max-width: inherit;\n    align-items: center;\n    padding: 20px 0px;\n  }\n"])), theme.palette.admin.primary[15], M);
export var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: 13px;\n  margin-bottom: 10px;\n  text-transform: uppercase;\n  width: 90%;\n"], ["\n  font-family: ", ";\n  font-size: 13px;\n  margin-bottom: 10px;\n  text-transform: uppercase;\n  width: 90%;\n"])), theme.typography.bold.ralewayBold);
export var ContentDiv = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  width: 90%;\n  &.second {\n    margin-top: 12px;\n  }\n"], ["\n  display: flex;\n  width: 90%;\n  &.second {\n    margin-top: 12px;\n  }\n"])));
export var Line = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  width: 100%;\n  margin-top: 25px;\n  @media (min-width: ", "px) {\n    display: none;\n  }\n"], ["\n  border: 1px solid ", ";\n  width: 100%;\n  margin-top: 25px;\n  @media (min-width: ", "px) {\n    display: none;\n  }\n"])), theme.palette.blueShadow[50], M);
export var Image = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 45%;\n  height: 20px;\n  &.description {\n    width: auto;\n    margin-right: 5px;\n    height: 20px;\n  }\n"], ["\n  width: 45%;\n  height: 20px;\n  &.description {\n    width: auto;\n    margin-right: 5px;\n    height: 20px;\n  }\n"])));
export var Description = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  text-align: initial;\n  font-size: 16px;\n  &.bold {\n    font-family: ", ";\n  }\n"], ["\n  width: 100%;\n  text-align: initial;\n  font-size: 16px;\n  &.bold {\n    font-family: ", ";\n  }\n"])), theme.typography.bold.ralewayBold);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
