var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { AdminLayout } from 'src/Layouts';
import { EDIT, EDIT_RECEIPT, BACK_ERROR, RECEIPT_NUMBER_ERROR, RECEIPT_MODIFIED_SUCCESSFUL, } from 'src/Utils/constants';
import { getData } from 'src/Utils/api';
import { Container, Title } from './styles';
import { submitEditedDataForm } from './duck';
import EditReceiptForm from 'src/Components/ReceiptForm/EditReceiptForm';
import { setFormSelected } from '../../../redux/slices/tableFiltersSlice';
import { useAppDispatch } from '../../../redux/hooks';
var EditReceipt = function () {
    var dispatch = useAppDispatch();
    var _a = useState(''), backError = _a[0], setBackError = _a[1];
    var _b = useState(undefined), receiptData = _b[0], setReceiptData = _b[1];
    var id = useParams().id;
    var location = useLocation();
    var _c = useState(''), success = _c[0], setSuccess = _c[1];
    var _d = useMutation(submitEditedDataForm, {
        onError: function (error) {
            var _a, _b;
            setBackError(((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.errors) === null || _b === void 0 ? void 0 : _b.receipt_number) ? RECEIPT_NUMBER_ERROR
                : BACK_ERROR);
            setSuccess('');
        },
        onSuccess: function () {
            setSuccess(RECEIPT_MODIFIED_SUCCESSFUL);
        },
    }), mutateFormReceipt = _d[0], _e = _d[1], isLoading = _e.isLoading, isSuccess = _e.isSuccess;
    var submitForm = function (numeric_client_code, address, apartment_id, tower, receipt_number, expiration_date, amount, currency, description) {
        return mutateFormReceipt({
            numeric_client_code: numeric_client_code,
            address: address,
            apartment_id: apartment_id,
            tower: tower,
            receipt_number: receipt_number,
            expiration_date: expiration_date,
            amount: amount,
            currency: currency,
            description: description,
            receiptId: id,
        });
    };
    var fixedDate = new Date();
    fixedDate.setDate(25);
    var _f = useQuery(['getReceipt', id], function () { return getData("/common_expenses_receipts/" + id); }, {
        onSuccess: function (data) {
            var currency = data.currency === 'dolares' ? 'USD' : 'UYU';
            setReceiptData(__assign(__assign({}, data), { currency: currency, expiration_date: new Date(data.expiration_date.replace(/-/g, '/')) || fixedDate }));
        },
    }), data = _f.data, refetch = _f.refetch;
    useEffect(function () {
        dispatch(setFormSelected(true));
    }, []);
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, { className: "non-margin" }, EDIT_RECEIPT),
            receiptData && (React.createElement(EditReceiptForm, { onSubmit: submitForm, backError: backError, initialValues: receiptData, buttonLabel: EDIT, receiptInfo: location.state, success: success, setSuccess: setSuccess })))));
};
export default EditReceipt;
