var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useMutation, useQuery } from 'react-query';
import moment from 'moment';
import { BeatLoader } from 'react-spinners';
import { AdminLayout } from 'src/Layouts';
import { ButtonText, Container, TextContainer, Title } from './styles';
import { DATE, theme } from 'src/Utils';
import { PDFComponent as SummonPDF } from '../../../Components/NewSummonForm/PDFComponent';
import useIdParam from 'src/hooks/useIdParam';
import { ASSEMBLIES_SUMMONS_TITLE, TYPE_CONTACT, BUILDING_API, SECOND_CALL, STATE, DATE_FORMAT_MOMENT, SUMMONS_API, ACTIONS, MODIFIED_BY, ASSEMBLY_TYPES, ASSEMBLY, SUSPEND, SUSPEND_CONFIRMATION, } from 'src/Utils/constants';
import { DeleteModal } from 'src/Components/Common';
import { RelativeDiv } from '../BillsPage/styles';
import { getData } from 'src/Utils/api';
import { FilterTable } from '.';
import { handleDeleteRow as deleteRow } from './services';
import { submitPreviewPDF, submitPrintablePDF, suspendSummon } from './duck';
import usePdfInstances from 'src/Utils/usePdfInstances';
function createData(first, second, third, fourth, preview, printable, id, fifth) {
    return { first: first, second: second, third: third, fourth: fourth, preview: preview, printable: printable, id: id, fifth: fifth };
}
function createBuilding(label, value, rows) {
    return { label: label, value: value, rows: rows };
}
var SummonsPage = function (props) {
    var _a;
    var _b, _c;
    var _d = useState(), buildingList = _d[0], setBuildingList = _d[1];
    var _e = useState([]), rows = _e[0], setRows = _e[1];
    var _f = useState(), selectedBuilding = _f[0], setSelectedBuildingNative = _f[1];
    var _g = useState(), startDate = _g[0], setStartDate = _g[1];
    var _h = useState(), endDate = _h[0], setEndDate = _h[1];
    var _j = useState(false), summonSent = _j[0], setSummonSent = _j[1];
    var _k = useState(false), isSuspendModalActive = _k[0], setIsSuspendModalActive = _k[1];
    var _l = useState(0), summonId = _l[0], setSummonId = _l[1];
    var isContact = Cookies.get('User') === TYPE_CONTACT;
    var isSuperAdmin = Cookies.get('SuperAdmin') === 'true';
    var _m = useState(null), activeSummon = _m[0], setActiveSummon = _m[1];
    var mutatePreviewPDF = useMutation(submitPreviewPDF)[0];
    var mutatePrintablePDF = useMutation(submitPrintablePDF)[0];
    var mutateSuspendSummon = useMutation(suspendSummon, {
        onSuccess: function () { return setSummonSent(true); },
    })[0];
    var buildingIdParam = (_c = (_b = props === null || props === void 0 ? void 0 : props.location) === null || _b === void 0 ? void 0 : _b.state) === null || _c === void 0 ? void 0 : _c.buildingId;
    useEffect(function () {
        if (isContact &&
            buildingList &&
            buildingList[0] &&
            buildingList.length === 1) {
            setSelectedBuilding(buildingList[0]);
        }
        else if (buildingList && !selectedBuilding && buildingIdParam) {
            var buildingFromParam = buildingList.find(function (building) { return building.value == buildingIdParam; });
            setSelectedBuilding(buildingFromParam);
        }
    }, [buildingList, isContact]);
    var headCells = [
        { id: 'first', numeric: true, disablePadding: false, label: DATE },
        {
            id: 'second',
            numeric: true,
            disablePadding: false,
            label: SECOND_CALL,
        },
        {
            id: 'third',
            numeric: true,
            disablePadding: false,
            label: STATE,
        },
        {
            id: 'fourth',
            numeric: true,
            disablePadding: false,
            label: isSuperAdmin ? MODIFIED_BY : '',
            dontSort: true,
        },
        {
            id: 'fifth',
            numeric: true,
            disablePadding: false,
            label: ACTIONS,
            dontSort: true,
        },
    ];
    var isLoadingBuildings = useQuery(BUILDING_API, function () { return getData("/buildings"); }, {
        onSuccess: function (data) {
            var buildings = data.buildings.map(function (_a) {
                var name = _a.name, id = _a.id;
                return createBuilding(name, id, rows);
            });
            buildings.sort(function (a, b) {
                return a.label.localeCompare(b.label, 'es');
            });
            setBuildingList(buildings);
        },
        refetchOnWindowFocus: false,
    }).isLoading;
    var _o = useQuery([SUMMONS_API, startDate, endDate, selectedBuilding, summonSent], function () {
        return getData("/summons", {
            start_date: startDate
                ? moment(startDate).format(DATE_FORMAT_MOMENT)
                : null,
            end_date: endDate ? moment(endDate).format(DATE_FORMAT_MOMENT) : null,
            building_id: selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value,
        });
    }, {
        onSuccess: function () { return setSummonSent(false); },
        enabled: selectedBuilding,
        refetchOnWindowFocus: false,
    }), isLoadingSummons = _o.isLoading, data = _o.data, refetch = _o.refetch;
    var _p = useIdParam({
        buildingList: buildingList,
        setSelectedBuilding: setSelectedBuildingNative,
        rows: rows,
        setRows: setRows,
        refetch: refetch,
    }), setSelectedBuilding = _p.handleBuildingChange, isLoading = _p.isLoading;
    var handleSuspend = function (summonId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setIsSuspendModalActive(true);
            setSummonId(summonId);
            return [2 /*return*/];
        });
    }); };
    var handleSuspendConfirmation = function () { return __awaiter(void 0, void 0, void 0, function () {
        var summon;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getData("/summons/" + summonId)];
                case 1:
                    summon = _a.sent();
                    setActiveSummon(summon);
                    setIsSuspendModalActive(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var toDate = function (str) { return str === null || str === void 0 ? void 0 : str.slice(0, str.length - 3); };
    var toType = (_a = {},
        _a[ASSEMBLY_TYPES[0]] = 'ordinaria',
        _a[ASSEMBLY_TYPES[1]] = 'extraordinaria',
        _a[ASSEMBLY_TYPES[2]] = 'urgente',
        _a[ASSEMBLY_TYPES[3]] = 'levantamiento de 1/4 intermedio',
        _a);
    var getPdf = function (printable) {
        return activeSummon !== null ? (React.createElement(SummonPDF, { type: String(selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.label), building: String(selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.label), date: activeSummon === null || activeSummon === void 0 ? void 0 : activeSummon.set_date, place: activeSummon === null || activeSummon === void 0 ? void 0 : activeSummon.place, fascioliAssists: activeSummon === null || activeSummon === void 0 ? void 0 : activeSummon.fascioli_assists, modality: toType[activeSummon === null || activeSummon === void 0 ? void 0 : activeSummon.summon_type], link: activeSummon === null || activeSummon === void 0 ? void 0 : activeSummon.link, orders: activeSummon === null || activeSummon === void 0 ? void 0 : activeSummon.orders_of_the_day, schedules: [
                toDate(activeSummon === null || activeSummon === void 0 ? void 0 : activeSummon.time_first_call),
                toDate(activeSummon === null || activeSummon === void 0 ? void 0 : activeSummon.time_second_call),
            ], suspended: true, printable: printable })) : (React.createElement(React.Fragment, null));
    };
    var deps = [
        activeSummon === null || activeSummon === void 0 ? void 0 : activeSummon.summon_type,
        activeSummon === null || activeSummon === void 0 ? void 0 : activeSummon.set_date,
        activeSummon === null || activeSummon === void 0 ? void 0 : activeSummon.time_first_call,
        activeSummon === null || activeSummon === void 0 ? void 0 : activeSummon.time_second_call,
        activeSummon === null || activeSummon === void 0 ? void 0 : activeSummon.place,
        activeSummon === null || activeSummon === void 0 ? void 0 : activeSummon.fascioli_assists,
        activeSummon === null || activeSummon === void 0 ? void 0 : activeSummon.modality,
        activeSummon === null || activeSummon === void 0 ? void 0 : activeSummon.link,
        activeSummon === null || activeSummon === void 0 ? void 0 : activeSummon.orders_of_the_day,
        activeSummon === null || activeSummon === void 0 ? void 0 : activeSummon.instance,
        activeSummon === null || activeSummon === void 0 ? void 0 : activeSummon.building_id,
        activeSummon === null || activeSummon === void 0 ? void 0 : activeSummon.id,
    ];
    var handleFiles = function (file, filePrint) {
        var receiptId = (activeSummon === null || activeSummon === void 0 ? void 0 : activeSummon.id) || 0;
        mutatePreviewPDF({ file: file, receiptId: receiptId });
        mutatePrintablePDF({ file: filePrint, receiptId: receiptId });
        mutateSuspendSummon(activeSummon.id);
    };
    usePdfInstances({
        document: getPdf(false),
        documentPrint: getPdf(true),
        dependencies: deps,
        pdfName: ASSEMBLY + "_" + (activeSummon === null || activeSummon === void 0 ? void 0 : activeSummon.set_date),
        handleFiles: handleFiles,
    });
    useEffect(function () {
        if (data) {
            data.summons.sort(function (a, b) {
                return moment(b.created_at).diff(moment(a.created_at));
            });
            if (isSuperAdmin) {
                setRows(data.summons.map(function (_a) {
                    var set_date = _a.set_date, time_second_call = _a.time_second_call, status = _a.status, modified_by = _a.modified_by, preview = _a.preview, printable = _a.printable, id = _a.id, time_first_call = _a.time_first_call;
                    return createData(moment(set_date).format(DATE_FORMAT_MOMENT), time_second_call.slice(0, -3), status, modified_by, preview, printable, id, time_first_call);
                }));
            }
            else {
                setRows(data.summons.map(function (_a) {
                    var set_date = _a.set_date, time_second_call = _a.time_second_call, status = _a.status, preview = _a.preview, printable = _a.printable, id = _a.id;
                    return createData(moment(set_date).format(DATE_FORMAT_MOMENT), time_second_call.slice(0, -3), status, '', preview, printable, id);
                }));
            }
        }
    }, [data]);
    var handleDeleteRow = function (rowId) {
        deleteRow(rowId).then(function (res) {
            if (res.status === 204) {
                var filteredRows = rows.filter(function (_a) {
                    var id = _a.id;
                    return id !== rowId;
                });
                setRows(filteredRows);
            }
        });
    };
    return (React.createElement(AdminLayout, null,
        React.createElement(RelativeDiv, null,
            React.createElement(Container, null,
                React.createElement(DeleteModal, { isOpen: isSuspendModalActive, customButtonLabel: SUSPEND, customLabel: true, onDelete: handleSuspendConfirmation, setIsOpen: setIsSuspendModalActive, itemLabel: SUSPEND_CONFIRMATION }),
                React.createElement(Title, null, ASSEMBLIES_SUMMONS_TITLE),
                isLoadingBuildings && (React.createElement(BeatLoader, { size: "8px", color: theme.palette.black[100] })),
                buildingList && (React.createElement(FilterTable, { rows: rows, building: null, headCells: headCells, buildingList: buildingList, startDate: startDate, setStartDate: setStartDate, endDate: endDate, setEndDate: setEndDate, isLoading: isLoadingSummons || isLoading, selectedBuilding: selectedBuilding, setSelectedBuilding: setSelectedBuilding, setSent: setSummonSent, handleDeleteRow: handleDeleteRow, handleSuspend: handleSuspend })),
                isSuperAdmin && (React.createElement(TextContainer, null,
                    React.createElement(ButtonText, null, "En caso de concurrir a las asambleas:"),
                    React.createElement(ButtonText, null, "Fascioli se hace presente en segunda convocatoria")))))));
};
export default SummonsPage;
