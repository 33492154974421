var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import Select from 'react-select';
import { S, theme } from 'src/Utils';
export var InputContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100px;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n\n  &.height-auto {\n    height: auto;\n  }\n  &.height {\n    min-height: 50px;\n  }\n  &.width {\n    width: 140px;\n  }\n  @media (max-width: ", "px) {\n    width: auto;\n  }\n  &.message {\n    padding-right: 10px;\n    height: 100%;\n    padding-bottom: 30px;\n  }\n"], ["\n  height: 100px;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n\n  &.height-auto {\n    height: auto;\n  }\n  &.height {\n    min-height: 50px;\n  }\n  &.width {\n    width: 140px;\n  }\n  @media (max-width: ", "px) {\n    width: auto;\n  }\n  &.message {\n    padding-right: 10px;\n    height: 100%;\n    padding-bottom: 30px;\n  }\n"])), S);
export var Label = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 14px;\n  color: ", ";\n  width: 100%;\n  padding-bottom: 5px;\n"], ["\n  font-size: 14px;\n  color: ", ";\n  width: 100%;\n  padding-bottom: 5px;\n"])), theme.palette.black[100]);
export var SelectMul = styled(Select)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-transform: capitalize;\n  min-height: 43px;\n  border-radius: 9px;\n  height: 100%;\n  width: 100%;\n  .css-yk16xz-control {\n    border: 1px solid #dcdcdc;\n    border-radius: 7px;\n  }\n  .css-1pahdxg-control {\n    box-shadow: none;\n    border-color: #dcdcdc;\n  }\n  .css-1pahdxg-control:hover {\n    border-color: #dcdcdc;\n  }\n  .css-g1d714-ValueContainer {\n    padding-top: 8px;\n    padding-bottom: 8px;\n    height: 100%;\n    overflow: auto;\n  }\n  &.error {\n    border: 1px solid ", ";\n  }\n  &.message {\n    font-size: 12px;\n    width: 100%;\n\n    .css-yk16xz-control {\n      border: none;\n      height: 100%;\n    }\n    .css-1pahdxg-control {\n      border: none;\n      height: 100%;\n    }\n    .css-1fhf3k1-control {\n      border: none;\n      background: none;\n      height: 100%;\n    }\n    .css-26lrqy-menu {\n      color: ", "\n    }\n  }\n"], ["\n  text-transform: capitalize;\n  min-height: 43px;\n  border-radius: 9px;\n  height: 100%;\n  width: 100%;\n  .css-yk16xz-control {\n    border: 1px solid #dcdcdc;\n    border-radius: 7px;\n  }\n  .css-1pahdxg-control {\n    box-shadow: none;\n    border-color: #dcdcdc;\n  }\n  .css-1pahdxg-control:hover {\n    border-color: #dcdcdc;\n  }\n  .css-g1d714-ValueContainer {\n    padding-top: 8px;\n    padding-bottom: 8px;\n    height: 100%;\n    overflow: auto;\n  }\n  &.error {\n    border: 1px solid ", ";\n  }\n  &.message {\n    font-size: 12px;\n    width: 100%;\n\n    .css-yk16xz-control {\n      border: none;\n      height: 100%;\n    }\n    .css-1pahdxg-control {\n      border: none;\n      height: 100%;\n    }\n    .css-1fhf3k1-control {\n      border: none;\n      background: none;\n      height: 100%;\n    }\n    .css-26lrqy-menu {\n      color: ", "\n    }\n  }\n"])), theme.palette.error[50], theme.palette.black[100]);
export var Div = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  font-size: 14px;\n  font-family: ", ";\n  &.row {\n    flex-direction: row;\n  }\n  &.column {\n    flex-direction: column;\n  }\n  &.height {\n    height: 20px;\n    align-self: center;\n  }\n  &.counter {\n    height: 43px;\n    background-color: ", ";\n    border-radius: 9px;\n    padding: 0px 8px;\n  }\n  &.spaced {\n    margin-right: 8px;\n    width: 100%;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  font-size: 14px;\n  font-family: ", ";\n  &.row {\n    flex-direction: row;\n  }\n  &.column {\n    flex-direction: column;\n  }\n  &.height {\n    height: 20px;\n    align-self: center;\n  }\n  &.counter {\n    height: 43px;\n    background-color: ", ";\n    border-radius: 9px;\n    padding: 0px 8px;\n  }\n  &.spaced {\n    margin-right: 8px;\n    width: 100%;\n  }\n"])), theme.typography.normal.raleway, theme.palette.white[100]);
export var Error = styled.label(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  align-self: flex-start;\n  font-size: 12px;\n  margin-top: 10px;\n  height: 20px;\n  @media (max-width: ", "px) {\n    align-self: center;\n    align-items: center;\n  }\n"], ["\n  color: ", ";\n  align-self: flex-start;\n  font-size: 12px;\n  margin-top: 10px;\n  height: 20px;\n  @media (max-width: ", "px) {\n    align-self: center;\n    align-items: center;\n  }\n"])), theme.palette.error[50], S);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
