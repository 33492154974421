import api from 'src/Utils/api';
export var addPDF = function (_a) {
    var id = _a.id, file = _a.file;
    var formData = new FormData();
    formData.append('pdf', file);
    return api.put("/settlements/" + id + "/pdf", formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
