import React from 'react';
import { Head, Row, Element } from 'src/Components/Common/Table/TableHeader/styles';
var PaymentsTableHeader = function (_a) {
    var fields = _a.fields, widths = _a.widths, className = _a.className;
    return (React.createElement(Head, null,
        React.createElement(Row, null, fields.map(function (_a, index) {
            var id = _a.id, label = _a.label;
            return (React.createElement(Element, { padding: "none", align: "left", style: { width: widths[index] }, key: id, className: className }, label));
        }))));
};
export default PaymentsTableHeader;
