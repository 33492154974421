import React from 'react';
import { WhatsappLogo } from 'src/assets/Images';
import { Image } from './styles';
export var Whatsapp = function (_a) {
    var title = _a.title;
    var handleQuery = function () {
        var query = '';
        if (!!title)
            query = "Hola! Estoy interesado en la propiedad " + title + ". %0A " + window.location.href;
        return query;
    };
    return (React.createElement("a", { href: "https://wa.me/" + process.env.REACT_APP_FASCIOLI_MOBILE + "?text=" + handleQuery(), target: "_blank" },
        React.createElement(Image, { src: WhatsappLogo })));
};
