var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    address: '',
    amount: 0,
    currency: {
        label: 'UYU',
        value: 'UYU',
    },
    clientCode: '',
    description: '',
    expirationDate: '',
    receiptNumber: 0,
    towerList: undefined,
    valuesSetFromTablePage: false,
    apt: {
        label: '',
        value: '',
    },
};
export var commonExpensesReceiptSlice = createSlice({
    name: 'commonExpensesReceipt',
    initialState: initialState,
    reducers: {
        setAddress: function (state, action) {
            state.address = action.payload;
        },
        setAmount: function (state, action) {
            state.amount = action.payload;
        },
        setCurrency: function (state, action) {
            state.currency = action.payload;
        },
        setClientCode: function (state, action) {
            state.clientCode = action.payload;
        },
        setDescription: function (state, action) {
            state.description = action.payload;
        },
        setExpirationDate: function (state, action) {
            state.expirationDate = action.payload;
        },
        setReceiptNumber: function (state, action) {
            state.receiptNumber = action.payload;
        },
        setTowerList: function (state, action) {
            state.towerList = action.payload;
        },
        setValuesFromTablePage: function (state, action) {
            state.valuesSetFromTablePage = action.payload;
        },
        setApt: function (state, action) {
            state.apt = action.payload;
        },
        resetValues: function (state) {
            state.address = '';
            state.amount = 0;
            state.currency = {
                label: 'UYU',
                value: 'UYU',
            };
            state.clientCode = '';
            state.description = '';
            state.expirationDate = '';
            state.receiptNumber = 0;
            state.towerList = [];
            state.valuesSetFromTablePage = false;
            state.apt = {
                label: '',
                value: '',
            };
        },
    },
});
export var setAddress = (_a = commonExpensesReceiptSlice.actions, _a.setAddress), setAmount = _a.setAmount, setCurrency = _a.setCurrency, setClientCode = _a.setClientCode, setDescription = _a.setDescription, setExpirationDate = _a.setExpirationDate, setReceiptNumber = _a.setReceiptNumber, setTowerList = _a.setTowerList, setValuesFromTablePage = _a.setValuesFromTablePage, setApt = _a.setApt, resetValues = _a.resetValues;
export default commonExpensesReceiptSlice.reducer;
