import api from 'src/Utils/api';
export var submitEditedDataForm = function (_a) {
    var first_name = _a.first_name, last_name = _a.last_name, mobile = _a.mobile, address = _a.address, email = _a.email;
    return api.patch('/users', {
        first_name: first_name,
        last_name: last_name,
        mobile: mobile,
        address: address,
        email: email,
    });
};
export var changePassword = function (_a) {
    var current_password = _a.current_password, password = _a.password, password_confirmation = _a.password_confirmation;
    return api.patch('/users/update_password', {
        current_password: current_password,
        password: password,
        password_confirmation: password_confirmation,
    });
};
export var addClientCode = function (client_code) {
    return api.post('/apartment_contacts', {
        client_code: client_code,
    });
};
