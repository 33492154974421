var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect, useCallback } from 'react';
import { BeatLoader } from 'react-spinners';
import { useHistory } from 'react-router-dom';
import { FormContainer, InputContainer, TopInputs, BottomInputs, Error, Label, Link, Div, } from './styles';
import Button from '../Button';
import { PASSWORD_KEY, FORGOT_PASSWORD, CREATE_ACCOUNT, EMAIL_KEY, theme, } from 'src/Utils';
import TextInput from '../TextInput';
import { isValidForm, hasError } from 'src/Utils/validations';
import { ROUTES } from 'src/Utils/constants';
var Form = function (_a) {
    var inputs = _a.inputs, onSubmit = _a.onSubmit, buttonLabel = _a.buttonLabel, isLoginForm = _a.isLoginForm, backError = _a.backError, isLoading = _a.isLoading;
    var _b = useState({}), errors = _b[0], setErrors = _b[1];
    var _c = useState({}), formData = _c[0], setFormData = _c[1];
    var history = useHistory();
    useEffect(function () {
        var initialFormData = {};
        inputs.forEach(function (input) {
            initialFormData[input.key] = '';
        });
        setFormData(initialFormData);
        setErrors(initialFormData);
    }, []);
    var handleClick = useCallback(function (e) {
        e.preventDefault();
        if (isValidForm(formData, setErrors)) {
            onSubmit(formData[EMAIL_KEY], formData[PASSWORD_KEY]);
        }
    }, [formData, setErrors]);
    return (React.createElement(FormContainer, null,
        React.createElement(TopInputs, null, inputs.map(function (_a) {
            var key = _a.key, name = _a.name;
            return (React.createElement(InputContainer, { key: key },
                React.createElement(Label, null, name),
                React.createElement(TextInput, { placeholder: name, value: formData[key], onChange: function (e) {
                        e.persist();
                        setFormData(function (prevState) {
                            var _a;
                            return (__assign(__assign({}, prevState), (_a = {}, _a[key] = e.target.value, _a)));
                        });
                    }, hasError: hasError(key, errors), type: key == PASSWORD_KEY ? 'password' : '', isPassword: key == PASSWORD_KEY, className: key == PASSWORD_KEY ? 'login' : '' }),
                React.createElement(Div, null,
                    React.createElement(Error, null, errors[key]),
                    isLoginForm && key == PASSWORD_KEY && (React.createElement(Link, { onClick: function () { return history.push(ROUTES.reset_password); } }, FORGOT_PASSWORD)))));
        })),
        React.createElement(BottomInputs, null,
            React.createElement(Button, { className: "full-width", type: "submit", onClick: handleClick }, isLoading ? (React.createElement(BeatLoader, { size: "5px", color: theme.palette.white[100] })) : (buttonLabel)),
            React.createElement(Error, null, backError),
            isLoginForm && (React.createElement(Link, { onClick: function () { return history.push(ROUTES.create_account); }, className: "center" }, CREATE_ACCOUNT)))));
};
export default Form;
