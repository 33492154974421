var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { TableCell } from '@material-ui/core';
import { M, theme } from 'src/Utils';
export var Cell = styled(TableCell)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  padding: 0px 0px 0px 22px !important;\n  height: 35px;\n  border: none !important;\n  &.MuiTableCell-root {\n    font-family: unset;\n  }\n  &:last-child {\n    padding-right: 15px !important;\n  }\n  &.center {\n    text-align: center;\n  }\n  &.dots {\n    text-align: end;\n  }\n  &.small {\n    padding: 0px 0px 0px 12px !important;\n  }\n  &.bold {\n    font-family: ", ";\n  }\n  &.yellow {\n    color: ", ";\n  }\n  &.red {\n    color: ", ";\n  }\n  &.green {\n    color: ", ";\n  }\n  &.message {\n    justify-content: space-between;\n    flex-direction: row;\n  }\n  &.pointer {\n    cursor: pointer;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  padding: 0px 0px 0px 22px !important;\n  height: 35px;\n  border: none !important;\n  &.MuiTableCell-root {\n    font-family: unset;\n  }\n  &:last-child {\n    padding-right: 15px !important;\n  }\n  &.center {\n    text-align: center;\n  }\n  &.dots {\n    text-align: end;\n  }\n  &.small {\n    padding: 0px 0px 0px 12px !important;\n  }\n  &.bold {\n    font-family: ", ";\n  }\n  &.yellow {\n    color: ", ";\n  }\n  &.red {\n    color: ", ";\n  }\n  &.green {\n    color: ", ";\n  }\n  &.message {\n    justify-content: space-between;\n    flex-direction: row;\n  }\n  &.pointer {\n    cursor: pointer;\n  }\n"])), theme.typography.bold.ralewayBold, theme.palette.yellow, theme.palette.red, theme.palette.green);
export var ErrorMessage = styled.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 12px;\n  margin-top: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  padding: 0px;\n  padding-right: 35px;\n  @media (max-width: ", "px) {\n    padding-right: 20px;\n  }\n"], ["\n  color: ", ";\n  font-size: 12px;\n  margin-top: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  padding: 0px;\n  padding-right: 35px;\n  @media (max-width: ", "px) {\n    padding-right: 20px;\n  }\n"])), theme.palette.error[50], M);
export var DotsDiv = styled(TableCell)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  padding: 0px 0px 0px 22px !important;\n  height: 35px;\n  border: none !important;\n\n  &.MuiTableCell-root {\n    font-family: unset;\n  }\n  &:last-child {\n    padding-right: 15px !important;\n  }\n  &.center {\n    text-align: center;\n  }\n  &.dots {\n    text-align: end;\n  }\n  &.small {\n    padding: 0px 0px 0px 12px !important;\n  }\n  &.bold {\n    font-family: ", ";\n  }\n  &.message {\n    justify-content: space-between;\n    flex-direction: row;\n  }\n  &.pointer {\n    cursor: pointer;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  padding: 0px 0px 0px 22px !important;\n  height: 35px;\n  border: none !important;\n\n  &.MuiTableCell-root {\n    font-family: unset;\n  }\n  &:last-child {\n    padding-right: 15px !important;\n  }\n  &.center {\n    text-align: center;\n  }\n  &.dots {\n    text-align: end;\n  }\n  &.small {\n    padding: 0px 0px 0px 12px !important;\n  }\n  &.bold {\n    font-family: ", ";\n  }\n  &.message {\n    justify-content: space-between;\n    flex-direction: row;\n  }\n  &.pointer {\n    cursor: pointer;\n  }\n"])), theme.typography.bold.ralewayBold);
export var Actions = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  gap: 14px;\n  justify-content: center;\n  align-items: center;\n  &.pointer {\n    cursor: pointer;\n  }\n  &.pointer-left {\n    justify-content: flex-start;\n    cursor: pointer;\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  gap: 14px;\n  justify-content: center;\n  align-items: center;\n  &.pointer {\n    cursor: pointer;\n  }\n  &.pointer-left {\n    justify-content: flex-start;\n    cursor: pointer;\n  }\n"])));
export var Div = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n"])));
export var DownloadContainer = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  font-size: 12px;\n  color: ", ";\n  cursor: pointer;\n  img {\n    margin-left: 6px;\n    margin-bottom: -2px;\n  }\n  &.underline {\n    text-decoration-line: underline;\n    font-family: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  font-size: 12px;\n  color: ", ";\n  cursor: pointer;\n  img {\n    margin-left: 6px;\n    margin-bottom: -2px;\n  }\n  &.underline {\n    text-decoration-line: underline;\n    font-family: ", ";\n  }\n"])), theme.palette.admin.primary[75], theme.typography.bold.ralewayBold);
export var DownloadTag = styled.a(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  text-decoration: none !important;\n  color: ", ";\n  font-family: ", ";\n  &.delete {\n    color: ", ";\n  }\n"], ["\n  text-decoration: none !important;\n  color: ", ";\n  font-family: ", ";\n  &.delete {\n    color: ", ";\n  }\n"])), theme.palette.admin.primary[75], theme.typography.bold.ralewayBold, theme.palette.red);
export var Img = styled.img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
