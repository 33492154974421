var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { theme } from 'src/Utils';
export var customStyles = function (image) { return ({
    dropdownIndicator: function (base) { return (__assign(__assign({}, base), { color: theme.palette.primary[25] })); },
    indicatorSeparator: function () { return ({
        display: 'none',
    }); },
    placeholder: function (defaultStyles) { return (__assign(__assign({}, defaultStyles), { textTransform: 'none', marginLeft: image && 28 })); },
    singleValue: function (base) { return (__assign(__assign({}, base), { marginLeft: image && 28 })); },
}); };
export var areEqual = function (a1, a2, isMulti) {
    if (!isMulti)
        return a1 === a2;
    if (a1.length !== a2.length)
        return false;
    var sortedA1 = a1.slice().sort();
    var sortedA2 = a2.slice().sort();
    return sortedA1.every(function (value, index) { return value == sortedA2[index]; });
};
export var getFilteredOptions = function (options, watchedValue, isMulti) {
    return options.filter(function (op) {
        return isMulti
            ? watchedValue.some(function (value) { return value == op.value; })
            : watchedValue === op.value;
    });
};
