import React from "react";
import { Row, Cell } from "./styles";
var ApartmentContactsRow = function (_a) {
    var data = _a.data;
    var contact_name = data.contact_name, relationship = data.relationship, contact_phone = data.contact_phone, contact_email = data.contact_email;
    return (React.createElement(Row, { hover: true, tabIndex: -1 },
        React.createElement(Cell, null, contact_name),
        React.createElement(Cell, null, contact_email),
        React.createElement(Cell, null, contact_phone),
        React.createElement(Cell, null, relationship)));
};
export default ApartmentContactsRow;
