import React from 'react';
import { Link } from 'react-router-dom';
import { Modal } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Center, Container, RowContainer, Header, Button } from './styles';
import { SELECT_PAYMENT_METHOD, ONLINE_PAYMENT, IN_PERSON_PAYMENT, ROUTES, } from 'src/Utils';
var PaymentModal = function (_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, id = _a.id;
    return (React.createElement(Modal, { open: isOpen },
        React.createElement(Center, null,
            React.createElement(Container, null,
                React.createElement(RowContainer, { onClick: function () { return setIsOpen(false); }, className: "close" },
                    React.createElement(Close, { style: { cursor: 'pointer' } })),
                React.createElement(Header, null, SELECT_PAYMENT_METHOD),
                React.createElement(RowContainer, { className: "buttons" },
                    React.createElement(Link, { to: ROUTES.online_payment + "/" + id },
                        React.createElement(Button, null, ONLINE_PAYMENT)),
                    React.createElement(Link, { to: ROUTES.in_person_payment + "/" + id },
                        React.createElement(Button, null, IN_PERSON_PAYMENT)))))));
};
export default PaymentModal;
