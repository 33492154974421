var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { FileUploader } from 'react-drag-drop-files';
import { AdminLayout } from 'src/Layouts';
import { SETTLEMENT_UPLOAD, UPLOAD, REQUIRED_FIELD } from 'src/Utils/constants';
import { Container, Title, FileDiv, ImportButton, Label } from './styles';
import { submitFile } from './services';
import { AdminButton } from 'src/Components/Common/AdminButton';
var SettlementUploadPage = function () {
    var _a = useState(null), selectedFile = _a[0], setSelectedFile = _a[1];
    var _b = useState(''), error = _b[0], setError = _b[1];
    var _c = useMutation(submitFile), mutateSubmitFile = _c[0], isSuccess = _c[1].isSuccess;
    var handleFileChange = function (file) {
        setSelectedFile(file);
        isValidFile(file.name);
    };
    var isValidFile = function (file) {
        var isValid = true;
        if (file === null) {
            setError(REQUIRED_FIELD);
            setSelectedFile(null);
            isValid = false;
        }
        else {
            setError('');
        }
        return isValid;
    };
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (selectedFile) {
                mutateSubmitFile(selectedFile);
                setSelectedFile(null);
            }
            return [2 /*return*/];
        });
    }); };
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, null, SETTLEMENT_UPLOAD),
            React.createElement(FileDiv, null,
                React.createElement(FileUploader, { handleChange: handleFileChange, name: "file", types: ['xml'], onSizeError: function () {
                        setError('Error: el archivo seleccionado es muy grande');
                        setSelectedFile(null);
                    }, onTypeError: function () {
                        setError('Error: el archivo seleccionado no es del tipo correcto');
                        setSelectedFile(null);
                    }, classes: true },
                    React.createElement(ImportButton, null, "Importar documento")),
                selectedFile && (selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.name),
                isSuccess && React.createElement(Label, null, 'Liquidación creada correctamente'),
                React.createElement(AdminButton, { className: "margin", onClick: handleSubmit }, UPLOAD)))));
};
export default SettlementUploadPage;
