// This utils is meant to be accessed by the CreateReservation & EditReservation components.
import { timeToMinutes } from "src/Components/TimeSelectors/utils";
export var handleClientCodeChange = function (previousCode, setValue) { return function (newClientCode) {
    if (newClientCode !== previousCode) {
        setValue('clientCode', newClientCode);
    }
}; };
// returns true if time has format: 17:30-20:15
export var timeHasHourFormat = function (time) { return time.label && time.label.match(/\d{2}:\d{2}-\d{2}:\d{2}/); };
export var scheduleNeedsTimeInputs = function (time, amenity) {
    if (!amenity.hoursLimitPerBooking)
        return false;
    return amenity.hoursLimitPerBooking >= 0 && timeHasHourFormat(time);
};
export var timePerHour = function (time) { return time.label && !time.pricePerUse; };
export var getTimeLimitText = function (amenity) {
    return "La reserva puede tener un m\u00E1ximo de " + amenity.hoursLimitPerBooking + " horas";
};
export var getTimePrice = function (amenity, time, startHour, endHour) {
    if (!(time === null || time === void 0 ? void 0 : time.label) || !(amenity === null || amenity === void 0 ? void 0 : amenity.label))
        return 0;
    if (time.pricePerUse)
        return time.price;
    var _a = time.label.split('-'), scheduleStart = _a[0], scheduleEnd = _a[1];
    var scheduleStartMinutes = timeToMinutes(scheduleStart);
    var scheduleEndMinutes = timeToMinutes(scheduleEnd);
    var crossesMidnight = scheduleStartMinutes > scheduleEndMinutes;
    var amenityHasHourLimit = amenity.hoursLimitPerBooking !== -1;
    var startMinutes = timeToMinutes(amenityHasHourLimit ? startHour : scheduleStart);
    var endMinutes = timeToMinutes(amenityHasHourLimit ? endHour : scheduleEnd);
    var minutesUsed = crossesMidnight && startMinutes > endMinutes ? 24 * 60 - startMinutes + endMinutes : endMinutes - startMinutes;
    var hoursUsed = Math.ceil(minutesUsed / 60);
    return time.price * hoursUsed;
};
export var getSubmitedEnabled = function (watch, errors) {
    var _a = watch(), amenity = _a.amenity, time = _a.time, startHour = _a.startHour, endHour = _a.endHour;
    if (!(time === null || time === void 0 ? void 0 : time.label))
        return false;
    var noErrors = !errors.startHour && !errors.time;
    var hoursCorrect = (startHour && endHour) || !scheduleNeedsTimeInputs(time, amenity);
    return !!amenity.value && !!time.value && hoursCorrect && noErrors;
};
export var getOtherBookedTimes = function (time, data) { var _a; return (_a = time.occupiedTimes) === null || _a === void 0 ? void 0 : _a.filter(function (bookedTime) { return bookedTime.join('-') !== (data === null || data === void 0 ? void 0 : data.schedule); }); };
export var formatDate = function (date) { return date.toLocaleDateString('en-CA'); };
