import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Row, DotsImg } from '../../Common/Table/TableRow/styles';
import { Cell } from './style';
import { Dots } from 'src/assets/Images';
import { Popover } from 'src/Components/Common';
import { MODIFY } from 'src/Utils';
import { DELETE, VIEW, ROUTES, MODIFY_PASSWORD, MODIFY_EMAIL, } from 'src/Utils/constants';
var isSuperAdmin = Cookies.get('SuperAdmin') === 'true';
var ContactRow = function (_a) {
    var data = _a.data, widths = _a.widths, handleDelete = _a.handleDelete, setContactData = _a.setContactData;
    var _b = useState(null), anchorRef = _b[0], setAnchorRef = _b[1];
    var _c = useState({
        label: '',
        value: '',
    }), selectedOption = _c[0], setSelectedOption = _c[1];
    var history = useHistory();
    var mail = data.mail, first = data.first, lastName = data.lastName, cell = data.cell, id = data.id;
    var handleClick = function (e) {
        return anchorRef ? setAnchorRef(null) : setAnchorRef(e.currentTarget);
    };
    useEffect(function () {
        switch (selectedOption.label) {
            case DELETE:
                setContactData && setContactData(data);
                handleDelete && handleDelete();
                break;
            case MODIFY:
                history.push(ROUTES.modify_contact + "/" + id);
                break;
            case VIEW:
                history.push(ROUTES.contact_detail + "/" + id);
                break;
            case MODIFY_PASSWORD:
                history.push(ROUTES.modify_password + "/" + id);
                break;
            case MODIFY_EMAIL:
                history.push(ROUTES.modify_email + "/" + id);
                break;
        }
    }, [selectedOption]);
    var options = isSuperAdmin
        ? [
            { label: VIEW, value: VIEW },
            { label: MODIFY, value: MODIFY },
            { label: MODIFY_PASSWORD, value: MODIFY_PASSWORD },
            { label: DELETE, value: DELETE },
        ]
        : [
            { label: VIEW, value: VIEW },
            { label: MODIFY, value: MODIFY },
            { label: MODIFY_PASSWORD, value: MODIFY_PASSWORD },
            { label: DELETE, value: DELETE },
        ];
    return (React.createElement(Row, { hover: true, tabIndex: -1, key: first },
        React.createElement(Cell, { style: { width: widths[0] } }, first),
        React.createElement(Cell, { style: { width: widths[1] } }, lastName),
        React.createElement(Cell, { style: { width: widths[2] } }, mail),
        React.createElement(Cell, { style: { width: widths[3] } }, cell),
        React.createElement(Cell, { style: { width: widths[4], textAlign: 'right' } },
            React.createElement(DotsImg, { src: Dots, alt: "M\u00E1s", onClick: handleClick })),
        React.createElement(Popover, { data: options, handleOutsideClick: function () { return setAnchorRef(null); }, anchorRef: anchorRef, setSelected: setSelectedOption, className: "filter width", isFilter: false })));
};
export default ContactRow;
