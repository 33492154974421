import { useEffect, useCallback, useState } from 'react';
export function useWindowWidth() {
    var _a = useState(window.innerWidth), windowWidth = _a[0], setWindowWidth = _a[1];
    var onWindowResize = useCallback(function () {
        setWindowWidth(window.innerWidth);
    }, []);
    useEffect(function () {
        window.addEventListener('resize', onWindowResize);
        return function () { return window.removeEventListener('resize', onWindowResize); };
    }, []);
    return windowWidth;
}
