import React from 'react';
import { PaginatorContainer, PageButton, ArrowContainer } from './styles';
import { ArrowLeftBig, ArrowRightBig } from 'src/assets/Images';
import usePaginator from 'src/context/PaginatorContext';
import { NEXT_PAGE, PREVIOUS_PAGE } from 'src/Utils';
var Paginator = function () {
    var _a = usePaginator(), totalPages = _a.totalPages, currentPage = _a.currentPage, setCurrentPage = _a.setCurrentPage, incrementPage = _a.incrementPage, decrementPage = _a.decrementPage;
    var getPages = function () {
        if (totalPages <= 5)
            return Array.from({ length: totalPages }, function (_, i) { return i + 1; });
        if (currentPage === 1 || currentPage === 2)
            return [1, 2, 3, 4, 5];
        if (currentPage === totalPages || currentPage === totalPages - 1)
            return [totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
        return [currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2];
    };
    var pagesToShow = getPages();
    return (React.createElement(PaginatorContainer, null,
        React.createElement(ArrowContainer, { onClick: decrementPage, disabled: currentPage === 1 },
            React.createElement("img", { src: ArrowLeftBig, alt: PREVIOUS_PAGE })),
        pagesToShow.map(function (page) { return (React.createElement(PageButton, { key: page, onClick: function () { return typeof page === 'number' && setCurrentPage(page); }, isActive: currentPage === page }, page)); }),
        React.createElement(ArrowContainer, { onClick: incrementPage, disabled: currentPage === totalPages },
            React.createElement("img", { src: ArrowRightBig, alt: NEXT_PAGE }))));
};
export default Paginator;
