import React from 'react';
import { Modal as ModalComponent } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Container, Label, Center, DeleteButton, RowContainer, Text, ErrorMessage, } from './styles';
import { CANCEL, DELETE_WARNING, DELETE, QUESTION_MARK, } from 'src/Utils/constants';
import { Warning } from 'src/assets/Images';
var Modal = function (_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, onDelete = _a.onDelete, itemLabel = _a.itemLabel, toDelete = _a.toDelete, error = _a.error, _b = _a.customLabel, customLabel = _b === void 0 ? false : _b, _c = _a.customButtonLabel, customButtonLabel = _c === void 0 ? DELETE : _c, setError = _a.setError;
    return (React.createElement(ModalComponent, { open: isOpen },
        React.createElement(Center, null,
            React.createElement(Container, null,
                React.createElement(RowContainer, { className: "right close" },
                    React.createElement(Close, { onClick: function () {
                            setError && setError('');
                            setIsOpen(false);
                        } })),
                React.createElement(RowContainer, null,
                    React.createElement("img", { src: Warning }),
                    React.createElement(Text, null,
                        !customLabel && React.createElement(Label, null, DELETE_WARNING),
                        React.createElement(Label, null, itemLabel),
                        React.createElement(Label, { className: "bold" }, toDelete),
                        React.createElement(Label, { className: "question" }, QUESTION_MARK))),
                React.createElement(RowContainer, null,
                    React.createElement(DeleteButton, { onClick: onDelete }, customButtonLabel),
                    React.createElement(Label, { className: "cancel", onClick: function () {
                            setError && setError('');
                            setIsOpen(false);
                        } }, CANCEL)),
                error && React.createElement(ErrorMessage, null, error)))));
};
export default Modal;
