import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { useFormContext } from 'react-hook-form';
import { LOCATION_X2 } from 'src/Utils';
import ToggleFilter from '../ToggleFilter/ToggleFilter';
import { SelectFilter } from '../SelectFilter';
import { CheckboxFilter } from '../CheckboxFilter';
import { InputFilter } from '../InputFilter';
import { characteristicsFilter, getFilterNumbers, locationFilter, otherFilter, priceFilter, typesFilter } from '../utils';
import FilterDropdown from './FilterDropdown';
import ActionButtons from './ActionButtons';
import { Divider, customStyles } from './styles';
export var ModalFilter = function (_a) {
    var setIsOpen = _a.setIsOpen;
    var watch = useFormContext().watch;
    useEffect(function () {
        document.body.style.overflow = 'hidden';
        return function () { return (document.body.style.overflow = 'unset'); };
    }, []);
    var _b = getFilterNumbers(watch()), property_type = _b.property_type, characteristics = _b.characteristics, code = _b.code, location = _b.location, price = _b.price;
    return (React.createElement(Modal, { isOpen: true, onRequestClose: function () { return setIsOpen(false); }, style: customStyles, contentLabel: "Filtros de Propiedades" },
        React.createElement(ToggleFilter, { setIsOpen: setIsOpen }),
        React.createElement(Divider, null),
        React.createElement(FilterDropdown, { title: LOCATION_X2 + location },
            React.createElement(SelectFilter, { filter: locationFilter })),
        React.createElement(Divider, null),
        React.createElement(FilterDropdown, { title: typesFilter.title + property_type },
            React.createElement(CheckboxFilter, { filter: typesFilter })),
        React.createElement(Divider, null),
        React.createElement(FilterDropdown, { title: characteristicsFilter.title + characteristics },
            React.createElement(SelectFilter, { filter: characteristicsFilter })),
        React.createElement(Divider, null),
        React.createElement(FilterDropdown, { title: priceFilter.title + price },
            React.createElement(InputFilter, { filter: priceFilter, isPrice: true })),
        React.createElement(Divider, null),
        React.createElement(FilterDropdown, { title: otherFilter.title + code },
            React.createElement(InputFilter, { filter: otherFilter })),
        React.createElement(ActionButtons, { setIsOpen: setIsOpen })));
};
