import api from 'src/Utils/api';
export var createMessage = function (_a) {
    var subject = _a.subject, body = _a.body, contacts = _a.contacts, building = _a.building, building_name = _a.building_name, title = _a.title, checkAll = _a.checkAll;
    return api.post('/messages', {
        message: {
            subject: subject,
            body: body,
            contacts: contacts,
            building_id: building,
            building_name: building_name,
            title: title,
            all: checkAll,
        },
    });
};
export var sendMessage = function (id) {
    return api.post("/messages/" + id + "/notify", {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
export var deleteMessage = function (id) { return api.delete("/messages/" + id); };
export var addFile = function (_a) {
    var id = _a.id, file = _a.file, fileType = _a.fileType;
    var formData = new FormData();
    formData.append(fileType === 'image' ? 'image' : 'pdf', file);
    return api.put("/messages/" + id + "/" + fileType, formData);
};
export var deleteFile = function (_a) {
    var id = _a.id, fileId = _a.fileId, fileType = _a.fileType;
    return fileType === 'image'
        ? api.delete("/messages/" + id + "/delete_image", {
            data: {
                image_id: fileId,
            },
        })
        : api.delete("/messages/" + id + "/delete_pdf", {
            data: { pdf_id: fileId },
        });
};
export var updateMessage = function (_a) {
    var id = _a.id, title = _a.title, subject = _a.subject, body = _a.body, contacts = _a.contacts, building = _a.building, building_name = _a.building_name, checkAll = _a.checkAll;
    return api.put("/messages/" + id, {
        message: {
            subject: subject,
            body: body,
            contacts: contacts,
            building_id: building,
            building_name: building_name,
            title: title,
            all: checkAll,
        },
    });
};
