import React from 'react';
import { Container, Content, Title, DescriptionContainer, ImageContainer, Image, } from './styles';
import { useWindowWidth } from 'src/useWindowWidth';
import { L } from 'src/Utils';
var OurHistory = function (_a) {
    var _b = _a.items, image = _b.image, title = _b.title, description = _b.description;
    var windowWidth = useWindowWidth();
    return (React.createElement(Container, null,
        React.createElement(ImageContainer, { style: windowWidth <= L ? { backgroundImage: "url(" + image + ")" } : {} }, windowWidth > L && React.createElement(Image, { src: image })),
        React.createElement(Content, null,
            React.createElement(Title, null, title),
            React.createElement(DescriptionContainer, null, description))));
};
export default OurHistory;
