import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { AdminLayout } from 'src/Layouts';
import { Container, Title, DetailsContainer, ItemContainer, Label, Link, LinkRed, } from './styles';
import { PERSONAL_DETAILS, NAME, PHONE, LOCATION, EDIT_DETAILS, CHANGE_PASSWORD, theme, SURNAME, ADDRESS_KEY, EMAIL, EMAIL_KEY, LAST_NAME_KEY, NAME_KEY, PHONE_KEY, ROUTES, ADD_CLIENT_CODE, TYPE_CONTACT, DELETE_ACCOUNT, ID_KEY, removeCookies, USER_WITH_EMAIL, } from 'src/Utils';
import usePersonalInfo from './usePersonalInfo';
import Modal from 'src/Components/Common/DeleteModal';
import api from 'src/Utils/api';
import { useMutation } from 'react-query';
import { logoutContacts } from 'src/Components/Menu/MenuBar/duck';
var PersonalInfo = function () {
    var history = useHistory();
    var _a = usePersonalInfo(), data = _a.data, isSuccess = _a.isSuccess, isLoading = _a.isLoading;
    var editAccount = ROUTES.editAccount, changePassword = ROUTES.changePassword, newClientCode = ROUTES.newClientCode;
    var _b = useState([]), personalData = _b[0], setPersonalData = _b[1];
    var _c = useState(''), email = _c[0], setEmail = _c[1];
    var _d = useState(), id = _d[0], setId = _d[1];
    var _e = useState(false), deleteAccountModal = _e[0], setDeleteAccountModal = _e[1];
    var _f = useState(''), error = _f[0], setError = _f[1];
    var isContact = Cookies.get('User') === TYPE_CONTACT;
    var mutateLogoutContact = useMutation(logoutContacts, {
        onSuccess: function () {
            removeCookies();
            history.push(ROUTES.landing);
        },
        onError: function () {
            removeCookies();
            history.push(ROUTES.landing);
        },
    })[0];
    var deleteAccount = function () {
        api.delete("/contacts/" + id);
        mutateLogoutContact();
    };
    useEffect(function () {
        isSuccess &&
            setPersonalData([
                { label: NAME, info: data[NAME_KEY] },
                { label: SURNAME, info: data[LAST_NAME_KEY] },
                { label: PHONE, info: data[PHONE_KEY] },
                { label: EMAIL, info: data[EMAIL_KEY] },
                { label: LOCATION, info: data[ADDRESS_KEY] },
            ]);
        isSuccess && setEmail(data[EMAIL_KEY]);
        isSuccess && setId(data[ID_KEY]);
    }, [data, isSuccess]);
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, null, PERSONAL_DETAILS),
            isLoading && (React.createElement(BeatLoader, { size: "8px", color: theme.palette.black[100] })),
            isSuccess && (React.createElement(DetailsContainer, null, personalData.map(function (_a) {
                var label = _a.label, info = _a.info;
                return (React.createElement(ItemContainer, { key: label },
                    React.createElement(Label, null, label + ": "),
                    React.createElement(Label, { className: "bold " + (label === 'Email' ? 'text-wrap' : '') }, info)));
            }))),
            React.createElement(Link, { onClick: function () { return history.push(editAccount); } }, EDIT_DETAILS),
            React.createElement(Link, { onClick: function () { return history.push(changePassword); } }, CHANGE_PASSWORD),
            isContact && (React.createElement(Link, { onClick: function () { return history.push(newClientCode); } }, ADD_CLIENT_CODE)),
            isContact && (React.createElement(LinkRed, { onClick: function () { return setDeleteAccountModal(true); } }, DELETE_ACCOUNT)),
            React.createElement(Modal, { isOpen: deleteAccountModal, setIsOpen: setDeleteAccountModal, itemLabel: USER_WITH_EMAIL + " " + email, error: error, setError: setError, onDelete: function () {
                    setError('');
                    setDeleteAccountModal(false);
                    deleteAccount();
                } }))));
};
export default PersonalInfo;
