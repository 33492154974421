import React, { useState, useEffect } from 'react';
import { Container } from './styles';
import { useWindowWidth } from 'src/useWindowWidth';
import { M, ASSEMBLIES_MINUTES_TEXT, DOCUMENTS, ASSEMBLIES_SUMMONS_TEXT, BILL_TEXT, PAYMENT_HISTORY_TEXT, SETTELMENTS_TEXT, COMMON_EXPENSES_RECEIPTS_TEXT, } from 'src/Utils';
import MenuBar from './MenuBar';
import { CONTACTS_TEXT, MAINTENANCE, MESSAGES_TEXT } from 'src/Utils/constants';
var Menu = function (_a) {
    var topItems = _a.topItems, bottomItems = _a.bottomItems, className = _a.className;
    var _b = useState(true), isOpen = _b[0], setOpen = _b[1];
    var _c = useState("" + window.location.pathname.split('/')[1]), selectedIcon = _c[0], setSelectedIcon = _c[1];
    var windowWidth = useWindowWidth();
    var isLargeScreen = windowWidth > M;
    var toggleSideBar = function () {
        setOpen(function (prevIsOpen) { return !prevIsOpen; });
    };
    useEffect(function () {
        !isLargeScreen && setOpen(false);
    }, [isLargeScreen]);
    var setIcon = function () {
        switch (selectedIcon) {
            case ASSEMBLIES_MINUTES_TEXT:
            case ASSEMBLIES_SUMMONS_TEXT:
            case BILL_TEXT:
            case PAYMENT_HISTORY_TEXT:
            case SETTELMENTS_TEXT:
            case COMMON_EXPENSES_RECEIPTS_TEXT:
                setSelectedIcon(DOCUMENTS);
                return;
            case CONTACTS_TEXT:
            case MESSAGES_TEXT:
                setSelectedIcon(MAINTENANCE);
                return;
            default:
                return;
        }
    };
    useEffect(function () {
        setIcon();
    }, [selectedIcon]);
    return (React.createElement(Container, { className: isOpen || isLargeScreen ? 'fullHeight' : 'fullWidth' },
        React.createElement(MenuBar, { topItems: topItems || [], bottomItems: bottomItems, selectedIcon: selectedIcon, handleOpen: toggleSideBar, isOpen: isOpen, isLargeScreen: isLargeScreen, className: className })));
};
export default Menu;
