var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { InputComponent } from '../Common';
import { BUILDINGS, BUILDING_API, BUILDINGS_KEY, CANCEL, ADD, NAME, NAME_KEY, PHONE, PHONE_KEY, TYPE_OF_SERVICE, TYPE_OF_SERVICE_KEY, ROUTES, NEW_SERVICE, PROVIDERS_API, NEW_SERVICE_KEY, ALL_FIELDS_ARE_REQUIRED, } from 'src/Utils/constants';
import { FormsContainer, FormContainer, ButtonsContainer, Link, ErrorMessage, BottomContainer, NewServiceText, TextContainer, } from './styles';
import { AdminButton } from '../Common/AdminButton';
import { getData } from 'src/Utils';
import { isValidProviderForm } from 'src/Utils/validations';
import MultiSelect from '../Common/MultiSelect';
import api from 'src/Utils/api';
var ProviderForm = function (_a) {
    var onSubmit = _a.onSubmit, backError = _a.backError, setBackError = _a.setBackError, _b = _a.initialValues, initialValues = _b === void 0 ? {
        name: '',
        type_of_service: '',
        phone: '',
        buildings: [],
        new_service: '',
    } : _b;
    var history = useHistory();
    var _c = useState({}), formErrors = _c[0], setFormErrors = _c[1];
    var _d = useState([]), buildingList = _d[0], setBuildingList = _d[1];
    var _e = useState([]), providerTypes = _e[0], setProviderTypes = _e[1];
    var _f = useState([]), selectedType = _f[0], setSelectedType = _f[1];
    var _g = useState([]), selectedOption = _g[0], setSelectedOption = _g[1];
    var _h = useState(false), inputEnable = _h[0], setInputEnable = _h[1];
    var _j = useState([]), selectedProviderOption = _j[0], setSelectedProviderOption = _j[1];
    var _k = useState([]), providerList = _k[0], setProviderList = _k[1];
    var _l = useState({
        name: '',
        type_of_service: '',
        phone: '',
        buildings: [],
        new_service: '',
    }), formData = _l[0], setFormData = _l[1];
    useEffect(function () {
        setFormData(initialValues);
        setSelectedOption(initialValues[BUILDINGS_KEY]);
        setSelectedProviderOption(initialValues[TYPE_OF_SERVICE_KEY]);
    }, []);
    useEffect(function () {
        setFormData(function (prevState) {
            var _a;
            var _b;
            return (__assign(__assign({}, prevState), (_a = {}, _a[TYPE_OF_SERVICE_KEY] = (_b = selectedType[0]) === null || _b === void 0 ? void 0 : _b.label, _a)));
        });
    }, [selectedType]);
    useQuery(BUILDING_API, function () { return getData("/buildings"); }, {
        onSuccess: function (data) {
            setBuildingList(data.buildings.map(function (_a) {
                var name = _a.name, id = _a.id;
                return ({
                    label: name,
                    value: id,
                });
            }));
            setProviderList(data.buildings.map(function (_a) {
                var name = _a.name, id = _a.id;
                return ({
                    label: name,
                    value: id,
                });
            }));
        },
        retry: false,
    });
    var isLoading = useQuery([PROVIDERS_API], function () { return getData("/providers"); }, {
        onSuccess: function (_a) {
            var providers = _a.providers;
            var filteredList = providers.map(function (providers) { return ({
                label: providers.type_of_service,
            }); });
            setProviderTypes(filteredList);
        },
        retry: false,
    }).isLoading;
    var handleSubmit = function () {
        formErrors[NAME_KEY] = formData.name;
        formErrors[TYPE_OF_SERVICE_KEY] = formData.type_of_service;
        formErrors[PHONE_KEY] = formData.phone;
        formErrors[BUILDINGS_KEY] =
            selectedOption.length > 0 ? selectedOption : null;
        if (isValidProviderForm(formErrors, setFormErrors)) {
            onSubmit(formData[NAME_KEY], formData[TYPE_OF_SERVICE_KEY], formData[PHONE_KEY], selectedOption);
        }
        setBackError('');
    };
    var handleSubmitNewService = function () {
        formErrors[NAME_KEY] = formData.name;
        formErrors[NEW_SERVICE_KEY] = formData.new_service;
        formErrors[PHONE_KEY] = formData.phone;
        formErrors[BUILDINGS_KEY] =
            selectedOption.length > 0 ? selectedOption : null;
        var buildingNumber = [];
        if (selectedOption.length > 0) {
            buildingNumber[0] = parseInt(selectedOption[0].value.toString(), 10);
        }
        var newServiceData = {
            provider: {
                name: formData.name,
                type_of_service: formData.new_service,
                phone: formData.phone,
                buildings: buildingNumber,
            },
        };
        if (isValidProviderForm(formErrors, setFormErrors)) {
            setSelectedOption([]);
            setFormData(initialValues);
            return api.post('/providers', newServiceData, {});
        }
        setBackError('');
    };
    var handleOpenInput = function () {
        setInputEnable(!inputEnable);
    };
    var onCancel = function () { return history.push(ROUTES.providers); };
    return (React.createElement(FormsContainer, null,
        React.createElement(FormContainer, null,
            React.createElement(TextContainer, null, ALL_FIELDS_ARE_REQUIRED),
            React.createElement(InputComponent, { type: NAME_KEY, error: formErrors, label: NAME, onChangeFunction: function (e) {
                    e.persist();
                    setFormData(function (prevState) {
                        var _a;
                        return (__assign(__assign({}, prevState), (_a = {}, _a[NAME_KEY] = e.target.value, _a)));
                    });
                }, data: formData[NAME_KEY] }),
            inputEnable ? (React.createElement(InputComponent, { type: NEW_SERVICE_KEY, error: formErrors, label: NEW_SERVICE, onChangeFunction: function (e) {
                    e.persist();
                    setFormData(function (prevState) {
                        var _a;
                        return (__assign(__assign({}, prevState), (_a = {}, _a[NEW_SERVICE_KEY] = e.target.value, _a)));
                    });
                }, data: formData[NEW_SERVICE_KEY] })) : (React.createElement(MultiSelect, { options: providerTypes, label: TYPE_OF_SERVICE, type: TYPE_OF_SERVICE_KEY, setSelectedOption: setSelectedType, errors: formErrors, initialValues: selectedType })),
            React.createElement(NewServiceText, { onClick: handleOpenInput }, inputEnable ? TYPE_OF_SERVICE : '+  ' + NEW_SERVICE),
            React.createElement(InputComponent, { type: PHONE_KEY, error: formErrors, label: PHONE, onChangeFunction: function (e) {
                    e.persist();
                    setFormData(function (prevState) {
                        var _a;
                        return (__assign(__assign({}, prevState), (_a = {}, _a[PHONE_KEY] = e.target.value, _a)));
                    });
                }, data: formData[PHONE_KEY] }),
            React.createElement(MultiSelect, { options: buildingList, label: BUILDINGS, type: BUILDINGS_KEY, setSelectedOption: setSelectedOption, errors: formErrors, initialValues: selectedOption }),
            React.createElement(BottomContainer, null,
                React.createElement(ButtonsContainer, null,
                    React.createElement(Link, { onClick: onCancel }, CANCEL),
                    React.createElement(AdminButton, { type: "button", onClick: inputEnable ? handleSubmitNewService : handleSubmit, className: "height" }, ADD)),
                true && React.createElement(ErrorMessage, null, backError)))));
};
export default ProviderForm;
