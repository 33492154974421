import React, { useState } from 'react';
import { Title } from './Title';
import { PDFButton } from '../PDFButton';
import { Images } from './Images';
import { useWindowWidth } from 'src/useWindowWidth';
import { L, M } from 'src/Utils';
import { ModalCarrusel } from './ModalCarrusel/ModalCarrusel';
import { PrincipalImage } from './Images/styles';
import { Row } from '../styles';
export var TopInfo = function (_a) {
    var _b;
    var info = _a.info;
    var _c = useState(false), isCarruselOpen = _c[0], setIsCarruselOpen = _c[1];
    var windowWidth = useWindowWidth();
    var address = info.address + ", " + info.neighborhood + ", " + info.city;
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { className: "space-between" },
            React.createElement(Title, { title: info.name, location: address, cod: info.code }),
            windowWidth > L && React.createElement(PDFButton, { info: info })),
        windowWidth > M ? (React.createElement(Images, { images: info.media_urls, setIsOpen: setIsCarruselOpen })) : (React.createElement(PrincipalImage, { className: "full", src: ((_b = info.media_urls[0]) === null || _b === void 0 ? void 0 : _b.original) || undefined, onClick: function () { return setIsCarruselOpen(true); } })),
        isCarruselOpen && (React.createElement(ModalCarrusel, { isOpen: isCarruselOpen, setIsOpen: setIsCarruselOpen, images: info.media_urls }))));
};
