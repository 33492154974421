import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Container, ContentContainer, ContentRow, Title, Description, ColumnDescription, } from './styles';
import { Building } from 'src/assets/Images';
import { BUILDING, ADDRESS, APARTMENTS_QUANTITY, SERVICES, } from 'src/Utils/constants';
import { getData } from 'src/Utils';
var renderInformation = function (header, information) { return (React.createElement(ContentRow, null,
    React.createElement(Description, { className: "size" },
        header,
        ":"),
    React.createElement(Description, { className: "bold" }, information))); };
var renderServices = function (header, information) { return (React.createElement(ContentRow, null,
    React.createElement(Description, { className: "start" },
        header,
        ":"),
    React.createElement(ColumnDescription, null, information === null || information === void 0 ? void 0 : information.map(function (service, index) { return (React.createElement(Description, { className: "bold", key: service.name + index },
        service.name,
        " - ",
        service.phone,
        " - ",
        service.type_of_service)); })))); };
var AdminCard = function (_a) {
    var _b = _a.data, name = _b.name, id = _b.id, address = _b.address, quantity_of_apartments = _b.quantity_of_apartments;
    var _c = useState([]), providers = _c[0], setProviders = _c[1];
    var providersData = useQuery(['providers', id], function () { return getData("/providers?building_id=" + id); }, {
        onSuccess: function (data) {
            var _a;
            var services = (_a = data === null || data === void 0 ? void 0 : data.providers) === null || _a === void 0 ? void 0 : _a.map(function (provider) { return ({
                name: provider.name,
                phone: provider.phone,
                type_of_service: provider.type_of_service,
            }); });
            setProviders(services);
        },
    }).data;
    return (React.createElement(Container, { className: "admin" },
        React.createElement(Title, { className: "admin" }, BUILDING),
        React.createElement(ContentContainer, { className: "admin" },
            React.createElement(ContentRow, { className: "header" },
                React.createElement("img", { src: Building }),
                React.createElement(Title, { className: "card-title" }, name)),
            renderInformation(ADDRESS, address),
            renderInformation(APARTMENTS_QUANTITY, quantity_of_apartments),
            providers && renderServices(SERVICES, providers))));
};
export default AdminCard;
