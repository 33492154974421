import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import styles from './styles';
export var Table = function (_a) {
    var headers = _a.headers, rows = _a.rows;
    var cellStyle = function (index) {
        var width = (headers === null || headers === void 0 ? void 0 : headers.length) || 1;
        return {
            width: 100 / width + "%",
            padding: "8 " + (index === width - 1 ? 16 : 0) + " 8 16",
        };
    };
    var renderCell = function (index, value) {
        return (React.createElement(Text, { key: value, style: cellStyle(index) }, value));
    };
    return (React.createElement(View, { style: styles.table },
        React.createElement(View, { style: styles.tableHeader }, headers === null || headers === void 0 ? void 0 : headers.map(function (label, index) { return renderCell(index, label); })),
        React.createElement(View, { style: styles.lineTitle }), rows === null || rows === void 0 ? void 0 :
        rows.map(function (row, index) { return (React.createElement(View, { key: row.ID, style: !(index % 2) ? styles.row : styles.rowDark, wrap: false }, Object.keys(row).map(function (key) { return key != 'ID' && !!row[key] && renderCell(index, row[key]); }))); })));
};
