import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { LogoFascioliNew, menu } from '../../assets/Images';
import { HOME, L } from '../../Utils';
import HeaderButton from './HeaderButton';
import { useWindowWidth } from '../../useWindowWidth';
import { Header as StyledHeader, Container, ImageContainer, LogoContainer, MenuImage, Items, Menu, StyledDiv, Image, } from './styles';
var Header = function (_a) {
    var items = _a.items;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var windowWidth = useWindowWidth();
    var history = useHistory();
    var shouldShowMenu = windowWidth <= L;
    var handleClick = function () {
        if (history.location.hash !== '') {
            history.replace("/" + HOME);
        }
        else {
            history.push("/" + HOME);
        }
    };
    return (React.createElement(StyledHeader, null,
        React.createElement(Container, { displayMenu: shouldShowMenu },
            React.createElement(LogoContainer, null,
                React.createElement(ImageContainer, { onClick: handleClick },
                    React.createElement(StyledDiv, null,
                        React.createElement(Image, { src: LogoFascioliNew }))),
                shouldShowMenu && (React.createElement(Menu, { onClick: function () { return setIsOpen(function (prev) { return !prev; }); } },
                    React.createElement(MenuImage, { src: menu, alt: "menu" })))),
            React.createElement(Items, { displayMenu: shouldShowMenu, className: shouldShowMenu && !isOpen ? 'hideItems' : '' },
                React.createElement(Items, { className: shouldShowMenu ? 'items-column' : '' }, items.map(function (item) { return (React.createElement(HeaderButton, { setIsOpen: setIsOpen, item: item, key: item.key, shouldShowMenu: shouldShowMenu })); }))))));
};
export default Header;
