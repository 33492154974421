import React from 'react';
import { PHONE, PHONE_1, PHONE_2, DIRECTION, DIRECTION_1, DIRECTION_2, EMAIL, EMAIL_1, EMAIL_2, AFTERNOON_HOURS, MORNING_HOURS, MONDAY_FRIDAY, } from '../../Utils';
import { ContactComponent } from '../../Components';
import { Container } from './styles';
import { Email, Direction, Phone, Schedule } from '../../assets/Images';
var items = [
    {
        title: PHONE,
        first: PHONE_1,
        second: PHONE_2,
        icon: Phone,
    },
    {
        title: DIRECTION,
        first: DIRECTION_1,
        second: DIRECTION_2,
        icon: Direction,
    },
    {
        title: MONDAY_FRIDAY,
        first: MORNING_HOURS,
        second: AFTERNOON_HOURS,
        icon: Schedule,
    },
    {
        title: EMAIL,
        first: EMAIL_1,
        second: EMAIL_2,
        icon: Email,
    },
];
var Contact = function (_a) {
    var isContactPage = _a.isContactPage, className = _a.className;
    return (React.createElement(Container, { className: className + " " + (isContactPage ? '' : 'no-page') },
        React.createElement(ContactComponent, { isContactPage: isContactPage, items: items })));
};
export default Contact;
