import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Row, ToggleButton as Button } from '../styles';
import { Text } from './styles';
export var Currency = function (_a) {
    var inputValues = _a.inputValues;
    var _b = useState(true), isPesos = _b[0], setIsPesos = _b[1];
    var _c = useFormContext(), setValue = _c.setValue, watch = _c.watch, trigger = _c.trigger;
    var watchedFields = watch();
    var watchedValue = watchedFields['currency'];
    useEffect(function () {
        var currentCurrency = isPesos ? 'UYU' : 'USD';
        if (watchedValue !== undefined && currentCurrency !== watchedValue) {
            setIsPesos(watchedValue === 'UYU');
        }
    }, []);
    var handleCurrency = function (currency) {
        setIsPesos(currency === 'UYU');
        setValue('currency', currency);
    };
    var price_start = inputValues.price_start, price_end = inputValues.price_end;
    var handleSendInputValues = function () {
        setValue('price_start', price_start, { shouldValidate: false });
        setValue('price_end', price_end, { shouldValidate: false });
        trigger(['price_start', 'price_end']);
    };
    var isActive = watchedFields.price_start !== price_start || watchedFields.price_end !== price_end;
    return (React.createElement(Row, { className: "space-between align-center" },
        React.createElement(Row, null,
            React.createElement(Button, { className: "left " + (isPesos ? 'isActive' : ''), onClick: function () { return handleCurrency('UYU'); } }, "$"),
            React.createElement(Button, { className: "right " + (isPesos ? '' : 'isActive'), onClick: function () { return handleCurrency('USD'); } }, "U$S")),
        React.createElement(Text, { onClick: handleSendInputValues, className: isActive ? 'isActive' : '' }, "Aplicar")));
};
