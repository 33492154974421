import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { AdminLayout } from 'src/Layouts';
import { Container, Title } from '../styles';
import { BACK_ERROR, CREATE_ADMIN, ROUTES } from 'src/Utils';
import { AdminForm } from 'src/Components/AdminForm';
import { createAdmin } from './services';
var NewAdmin = function () {
    var history = useHistory();
    var _a = useState(''), backError = _a[0], setBackError = _a[1];
    var mutateSubmit = useMutation(createAdmin, {
        onSuccess: function () {
            setBackError('');
            history.push(ROUTES.admins);
        },
        onError: function (error) { var _a; return setBackError(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.errors) || BACK_ERROR); },
    })[0];
    var submitForm = function (email, address, first_name, last_name, mobile, superadmin) {
        return mutateSubmit({ email: email, address: address, first_name: first_name, last_name: last_name, mobile: mobile, superadmin: superadmin });
    };
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, { className: "non-margin" }, CREATE_ADMIN),
            React.createElement(AdminForm, { onSubmit: submitForm, backError: backError }))));
};
export default NewAdmin;
