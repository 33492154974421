var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { M, XS, theme } from '../../../Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: 350px;\n  background-color: ", ";\n  border-radius: 16px;\n  padding: 32px;\n  @media (max-width: ", "px) {\n    width: auto;\n  }\n"], ["\n  max-width: 350px;\n  background-color: ", ";\n  border-radius: 16px;\n  padding: 32px;\n  @media (max-width: ", "px) {\n    width: auto;\n  }\n"])), theme.palette.white[100], XS);
export var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 18px;\n  font-weight: 600;\n  font-family: ", ";\n  text-align: center;\n  margin-top: 8px;\n"], ["\n  font-size: 18px;\n  font-weight: 600;\n  font-family: ", ";\n  text-align: center;\n  margin-top: 8px;\n"])), theme.typography.OpenSans);
export var Description = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 14px;\n  font-weight: 500;\n  font-family: ", ";\n  text-align: center;\n  margin-top: 8px;\n  color: ", ";\n"], ["\n  font-size: 14px;\n  font-weight: 500;\n  font-family: ", ";\n  text-align: center;\n  margin-top: 8px;\n  color: ", ";\n"])), theme.typography.OpenSans, theme.palette.grey[60]);
export var Center = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n"], ["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n"])));
export var RowContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  &.right {\n    justify-content: flex-end;\n  }\n  &.close {\n    .MuiSvgIcon-root {\n      width: 15px;\n      cursor: pointer;\n    }\n  }\n  &.center {\n    justify-content: center;\n    gap: 16px;\n  }\n  @media (max-width: ", "px) {\n    align-items: flex-end;\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  &.right {\n    justify-content: flex-end;\n  }\n  &.close {\n    .MuiSvgIcon-root {\n      width: 15px;\n      cursor: pointer;\n    }\n  }\n  &.center {\n    justify-content: center;\n    gap: 16px;\n  }\n  @media (max-width: ", "px) {\n    align-items: flex-end;\n    flex-direction: column;\n  }\n"])), M);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
