import React from 'react';
import { pluralize } from '../../utils';
import { M2 } from 'src/Utils';
import { Row } from '../../styles';
import { Container, Text, Image } from './styles';
export var MoreInfo = function (_a) {
    var data = _a.data;
    return (React.createElement(Container, null, data.map(function (_a) {
        var img = _a.img, number = _a.number, label = _a.label;
        return (React.createElement(Row, { className: "align-center", gap: "6px", key: label },
            React.createElement(Image, { src: img }),
            React.createElement(Text, null, label === M2 ? number + " " + M2 : pluralize(number, label))));
    })));
};
