import React from 'react';
import { SERIOUSNESS_TEXT, TRANSPARENCY_TEXT, TRUST_TEXT, ABOUT_US, L, TRUST_DESCRITPION, TRANSPARENCY_DESCRITPION, SERIOUSNESS_DESCRITPION, ABOUT_US_DESCRIPTION, } from '../../Utils';
import { AboutUsComponent } from '../../Components';
import { Container } from './styles';
import { Transparency, Trust, Seriousness, AboutUsBackground, AboutUsMobileBackground, } from '../../assets/Images';
import { useWindowWidth } from 'src/useWindowWidth';
import OurHistory from '../OurHistory';
import Contact from '../Contact';
import { Header } from 'src/Layouts';
import Footer from '../Footer';
var items = {
    title: ABOUT_US,
    description: ABOUT_US_DESCRIPTION,
    items: [
        {
            title: SERIOUSNESS_TEXT,
            description: SERIOUSNESS_DESCRITPION,
            image: Seriousness,
        },
        {
            title: TRANSPARENCY_TEXT,
            description: TRANSPARENCY_DESCRITPION,
            image: Transparency,
        },
        {
            title: TRUST_TEXT,
            description: TRUST_DESCRITPION,
            image: Trust,
        },
    ],
};
var AboutUs = function () {
    var windowWidth = useWindowWidth();
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, null),
        React.createElement(Container, { style: {
                backgroundImage: "url(" + (windowWidth > L ? AboutUsBackground : AboutUsMobileBackground) + ")",
            } },
            React.createElement(AboutUsComponent, { items: items })),
        React.createElement(OurHistory, null),
        React.createElement(Contact, { isContactPage: false }),
        React.createElement(Footer, null)));
};
export default AboutUs;
