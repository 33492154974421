var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { S, theme } from 'src/Utils';
export var Container = styled.section(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  min-height: 600px;\n  width: 100%;\n  padding: 4%;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n"], ["\n  display: flex;\n  min-height: 600px;\n  width: 100%;\n  padding: 4%;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n"])));
export var FileDiv = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 20px;\n  z-index: 0;\n  width: 80%;\n"], ["\n  margin-top: 20px;\n  z-index: 0;\n  width: 80%;\n"])));
export var Error = styled.label(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: red;\n  align-self: flex-start;\n  font-size: 12px;\n  margin-top: 10px;\n  height: 15px;\n\n  &.select {\n    margin-top: -26px;\n  }\n\n  @media (max-width: ", "px) {\n    align-self: center;\n    align-items: center;\n  }\n"], ["\n  color: red;\n  align-self: flex-start;\n  font-size: 12px;\n  margin-top: 10px;\n  height: 15px;\n\n  &.select {\n    margin-top: -26px;\n  }\n\n  @media (max-width: ", "px) {\n    align-self: center;\n    align-items: center;\n  }\n"])), S);
export var FileErrorContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: 25px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: 25px;\n"])));
export var ImportButton = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background: ", ";\n  border: 1px dashed ", ";\n  box-sizing: border-box;\n  color: ", ";\n  display: flex;\n  align-items: center;\n  border-radius: 8px;\n  width: 100%;\n  margin-top: 35px;\n  height: 49px;\n  cursor: pointer;\n  justify-content: center;\n"], ["\n  background: ", ";\n  border: 1px dashed ", ";\n  box-sizing: border-box;\n  color: ", ";\n  display: flex;\n  align-items: center;\n  border-radius: 8px;\n  width: 100%;\n  margin-top: 35px;\n  height: 49px;\n  cursor: pointer;\n  justify-content: center;\n"])), theme.palette.admin.primary[35], theme.palette.admin.primary[75], theme.palette.admin.primary[75]);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
