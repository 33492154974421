import React, { useState } from 'react';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import { AMENITIES_NOT_AVAILABLE, AMENITY, AMENITY_KEY, BUILDING, BUILDING_KEY, CLIENT_CODE, CLIENT_KEY, RESERVATION_DATE, SCHEDULE, SCHEDULES_NOT_AVAILABLE, TIME_KEY, } from 'src/Utils';
import { InputComponent, Popover, SelectComponent, } from 'src/Components/Common';
import { openedArrow } from 'src/assets/Images';
import CalendarComponent from 'src/Components/Common/Calendar/CalendarComponent';
import { CustomInput } from 'src/Components/ReceiptForm';
import { BoxContainer, InputContainer, Label, ScheduleInfo } from '../styles';
import useAmenitiesAndSchedules from '../hooks/useAmenitiesAndSchedules';
import useBookingInitialValues from '../hooks/useBookingInitialValues';
import { scheduleNeedsTimeInputs, getOtherBookedTimes, timePerHour, getTimeLimitText, } from 'src/Views/CreateReservation/utils';
import TimeSelectors from 'src/Components/TimeSelectors';
export var ReservationForm = function (_a) {
    var data = _a.data;
    var _b = useState(null), anchorRefAmenity = _b[0], setAnchorRefAmenity = _b[1];
    var _c = useState(null), anchorRefSchedule = _c[0], setAnchorRefSchedule = _c[1];
    var _d = useFormContext(), setValue = _d.setValue, watch = _d.watch;
    var _e = watch(), amenity = _e.amenity, date = _e.date, time = _e.time;
    var _f = useAmenitiesAndSchedules({
        buildingId: data === null || data === void 0 ? void 0 : data.building_id,
        amenityId: amenity.value,
        date: date,
        scheduleId: data === null || data === void 0 ? void 0 : data.schedule_id,
        bookingId: data === null || data === void 0 ? void 0 : data.id,
    }), amenities = _f.amenities, schedules = _f.schedules;
    useBookingInitialValues({
        data: data,
        amenities: amenities,
        schedules: schedules,
    });
    var handleOutsideClick = function () {
        setAnchorRefAmenity(null);
        setAnchorRefSchedule(null);
    };
    var isAvailable = function (date) {
        var _a;
        return !!((_a = amenity === null || amenity === void 0 ? void 0 : amenity.availability) === null || _a === void 0 ? void 0 : _a.length)
            ? amenity === null || amenity === void 0 ? void 0 : amenity.availability[date.getDay() === 0 ? 6 : date.getDay() - 1] : true;
    };
    return (React.createElement(BoxContainer, null,
        React.createElement(InputComponent, { disabled: true, placeHolder: "A01L0001P", type: CLIENT_KEY, data: (data === null || data === void 0 ? void 0 : data.numeric_client_code) || '', error: {}, label: CLIENT_CODE, onChangeFunction: null }),
        React.createElement(InputComponent, { type: BUILDING_KEY, data: (data === null || data === void 0 ? void 0 : data.building_name) || '', error: {}, label: BUILDING, onChangeFunction: function () { return null; }, disabled: true }),
        React.createElement(SelectComponent, { label: AMENITY, type: AMENITY_KEY, data: !amenities.length ? AMENITIES_NOT_AVAILABLE : amenity.label, errors: {}, img: openedArrow, anchorRef: anchorRefAmenity, setAnchorRef: setAnchorRefAmenity }),
        !!amenities.length && (React.createElement(Popover, { data: amenities, handleOutsideClick: handleOutsideClick, anchorRef: anchorRefAmenity, setSelected: function (amenity) { return setValue('amenity', amenity); }, isFilter: false })),
        React.createElement(InputContainer, { className: "height" },
            React.createElement(Label, null, RESERVATION_DATE),
            React.createElement(CalendarComponent, { selected: date, onChange: function (e) { return setValue('date', e); }, customInput: React.createElement(CustomInput, null), minDate: moment().toDate(), filterDate: isAvailable })),
        React.createElement(SelectComponent, { label: SCHEDULE, type: TIME_KEY, data: amenity.label && !schedules.length
                ? SCHEDULES_NOT_AVAILABLE
                : time.label, errors: {}, img: openedArrow, anchorRef: anchorRefSchedule, setAnchorRef: setAnchorRefSchedule }),
        !!schedules.length && (React.createElement(Popover, { data: schedules, handleOutsideClick: handleOutsideClick, anchorRef: anchorRefSchedule, setSelected: function (schedule) { return setValue('time', schedule); }, isFilter: false })),
        timePerHour(time) && (React.createElement(ScheduleInfo, null, "La reserva en este horario tiene un precio de " + time.price + " por hora.")),
        scheduleNeedsTimeInputs(time, amenity) && (React.createElement(React.Fragment, null,
            React.createElement(ScheduleInfo, null, getTimeLimitText(amenity)),
            React.createElement(TimeSelectors, { otherBookedTimes: getOtherBookedTimes(time, data) })))));
};
