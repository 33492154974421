var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { MoonLoader } from 'react-spinners';
import { defaultValues, handleOperationType } from './utils';
import { Button } from 'src/Components/Common';
import { useWindowWidth } from 'src/useWindowWidth';
import { L, theme } from 'src/Utils';
import { TopForm } from './TopForm.tsx/TopForm';
import { BottomForm } from './BottomForm/BottomForm';
import { Success } from './Success';
import { SendForm } from './services';
import { FormContainer } from './styles';
import { Divider } from '../../styles';
export var Form = function (_a) {
    var price = _a.price, currency = _a.currency, propertyType = _a.propertyType, size = _a.size, operationType = _a.operationType, propertyId = _a.propertyId;
    var _b = useState(false), success = _b[0], setSuccess = _b[1];
    var windowWidth = useWindowWidth();
    var formMethods = useForm({
        defaultValues: defaultValues,
        shouldUnregister: false,
    });
    var isFormValid = Object.values(formMethods.watch()).every(function (value) { return value !== '' && formMethods.formState.isValid; });
    var _c = useMutation(SendForm, {
        onSuccess: function () { return setSuccess(true); },
    }), mutateSendForm = _c[0], isLoading = _c[1].isLoading;
    var onSubmit = function () {
        if (!success)
            mutateSendForm({ values: formMethods.getValues(), id: propertyId });
        else {
            formMethods.reset();
            setSuccess(false);
        }
    };
    useEffect(function () {
        formMethods.setValue('query', formMethods
            .getValues('query')
            .replace('.', " en " + handleOperationType(operationType) + "."));
    }, []);
    return (React.createElement(FormProvider, __assign({}, formMethods),
        React.createElement(FormContainer, null,
            windowWidth > L && (React.createElement(React.Fragment, null,
                React.createElement(TopForm, { price: price, currency: currency, propertyType: propertyType, size: size, operationType: operationType }),
                React.createElement(Divider, null))),
            success ? React.createElement(Success, null) : React.createElement(BottomForm, null),
            React.createElement(Button, { className: "full-width", disabled: !isFormValid, type: "button", onClick: onSubmit }, isLoading ? (React.createElement(MoonLoader, { color: theme.palette.white[100], size: "20px" })) : ("Enviar " + (success ? 'otra consulta' : ''))))));
};
