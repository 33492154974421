var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { StyledList, StyledPopover, Body, ListRow, ItemText, Label, } from './styles';
import { ORDER_BY } from 'src/Utils';
import { FIRST, SECOND, DATE } from 'src/Utils/constants';
import { Tick } from '../../../assets/Images';
var PopoverList = function (_a) {
    var anchorRef = _a.anchorRef, handleOutsideClick = _a.handleOutsideClick, data = _a.data, setSelected = _a.setSelected, className = _a.className, isFilter = _a.isFilter, selected = _a.selected, labelKey = _a.labelKey, type = _a.type, handleOptionClick = _a.handleOptionClick, isContact = _a.isContact;
    var handleClick = function (item) {
        if (isFilter) {
            item.value == DATE ? setSelected(SECOND) : setSelected(FIRST);
        }
        else if (labelKey) {
            setSelected(function (prevState) {
                var _a;
                return (__assign(__assign({}, prevState), (_a = {}, _a[labelKey] = item, _a)));
            });
        }
        else if (type)
            setSelected(item, type);
        else {
            setSelected(item);
            handleOptionClick && handleOptionClick();
        }
        handleOutsideClick();
    };
    var getClassName = function (item) {
        return item === selected ? className + ' bold' : className;
    };
    var isSpaced = function (item) {
        return item === selected ? 'spaced' : '';
    };
    return (React.createElement(StyledPopover, { className: className, anchorEl: anchorRef, open: Boolean(anchorRef), anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        } },
        React.createElement(ClickAwayListener, { onClickAway: handleOutsideClick },
            React.createElement(StyledList, { className: className },
                isFilter && (React.createElement(ListRow, { button: true },
                    React.createElement(Label, null, ORDER_BY))),
                data &&
                    data.map(function (item) { return (React.createElement(ListRow, { button: true, key: item.value, onClick: function () { return handleClick(item); }, className: item.label === selected ? 'selected' : '', autoFocus: item.label === selected },
                        React.createElement(ItemText, { className: isSpaced(item.value) },
                            React.createElement(Body, { className: getClassName(item.value) }, item.label),
                            isSpaced(item.value) && React.createElement("img", { src: Tick })))); })))));
};
export default PopoverList;
