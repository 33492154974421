import React, { useEffect, useState } from 'react';
import { AdminLayout } from 'src/Layouts';
import { Container, Title } from '../styles';
import { TableContainer, BlackLabel } from './styles';
import PaymentsTable from './PaymentsTable';
import { COLLECTION_MANAGEMENT, RECEIPT_NUMBER, CLIENT_CODE, AMOUNT, STATE, PAYED_AT, PAYMENT_METHOD } from 'src/Utils/constants';
import { Filters } from '.';
import useReceipts from './useReceipts';
function createData(receipt_number, numeric_client_code, amount, payed_at, status, payment_method) {
    return {
        receipt_number: receipt_number,
        numeric_client_code: numeric_client_code,
        amount: amount,
        payed_at: payed_at,
        status: status,
        payment_method: payment_method
    };
}
var headCells = [
    { id: 'receipt_number', disablePadding: false, label: RECEIPT_NUMBER },
    { id: 'numeric_client_code', disablePadding: false, label: CLIENT_CODE },
    { id: 'amount', disablePadding: false, label: AMOUNT },
    { id: 'payed_at', disablePadding: false, label: PAYED_AT },
    { id: 'status', disablePadding: false, label: STATE },
    { id: 'payment_method', disablePadding: false, label: PAYMENT_METHOD },
];
var WIDTHS = ['12.5%', '12.5%', '12.5%', '12.5%', '12.5%', '12.5%', '12.5%', '12.5%'];
var CollectionManagement = function () {
    var _a;
    var _b = useState(), customerCode = _b[0], setCustomerCode = _b[1];
    var _c = useState(), paymentProvider = _c[0], setPaymentProvider = _c[1];
    var _d = useState(), paymentStatus = _d[0], setPaymentStatus = _d[1];
    var _e = useState(), startDate = _e[0], setStartDate = _e[1];
    var _f = useState(), endDate = _f[0], setEndDate = _f[1];
    var _g = useState(1), page = _g[0], setPage = _g[1];
    var _h = useReceipts(customerCode, paymentProvider, paymentStatus, startDate, endDate, page, true), isLoadingReceipts = _h.isLoading, data = _h.data;
    var totalAmount = (data === null || data === void 0 ? void 0 : data.total_amount) || 0;
    var rows = ((_a = data === null || data === void 0 ? void 0 : data.receipts) === null || _a === void 0 ? void 0 : _a.map(function (_a) {
        var receipt_number = _a.receipt_number, numeric_client_code = _a.numeric_client_code, amount = _a.amount, payed_at = _a.payed_at, status = _a.status, payment_method = _a.payment_method;
        return createData(receipt_number, numeric_client_code, amount, payed_at, status, payment_method);
    })) || [];
    var handlePageChange = function (pageIndex) {
        var page = pageIndex + 1;
        setPage(page);
    };
    useEffect(function () {
        setPage(1);
    }, [customerCode, paymentProvider, paymentStatus, startDate, endDate]);
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, null, COLLECTION_MANAGEMENT),
            React.createElement(TableContainer, null,
                React.createElement(Filters, { customerCode: customerCode, setCustomerCode: setCustomerCode, paymentProvider: paymentProvider, setPaymentProvider: setPaymentProvider, paymentStatus: paymentStatus, setPaymentStatus: setPaymentStatus, startDate: startDate, setStartDate: setStartDate, endDate: endDate, setEndDate: setEndDate }),
                React.createElement(PaymentsTable, { rows: rows, headCells: headCells, widths: WIDTHS, isLoading: isLoadingReceipts, onPageChange: handlePageChange, totalPages: data === null || data === void 0 ? void 0 : data.total_pages, paginated: true, page: page }),
                React.createElement(BlackLabel, null,
                    "Total: ",
                    totalAmount)))));
};
export default CollectionManagement;
