var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M, XS, S } from '../../../Utils';
import { TextField } from '@material-ui/core';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 80%;\n  max-width: 450px;\n  background-color: ", ";\n  border-radius: 16px;\n  padding: 35px;\n  @media (max-width: ", "px) {\n    width: auto;\n  }\n"], ["\n  width: 80%;\n  max-width: 450px;\n  background-color: ", ";\n  border-radius: 16px;\n  padding: 35px;\n  @media (max-width: ", "px) {\n    width: auto;\n  }\n"])), theme.palette.admin.primary[50], XS);
export var FileDiv = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 20px;\n  z-index: 0;\n"], ["\n  margin-top: 20px;\n  z-index: 0;\n"])));
export var StyledTextField = styled(TextField)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  input {\n    height: 43px;\n    box-shadow: none;\n    cursor:pointer;\n    border-radius: 7px;\n    font-size: 18px;\n    font-family: Raleway;\n    border: 1px solid ", ";\n    background:", ";\n    padding: 10px 20px;\n    box-sizing: border-box;\n    font-size: 14px;\n    font-family:", "\n  }\n  .MuiInputBase-root{\n    &:before{\n    border-bottom: none;\n    }\n    &:after{\n      border-bottom: none;\n      }\n  }\n  .MuiInput-underline:hover:not(.Mui-disabled):before{\n      border-bottom: none;\n  }\n}\n"], ["\n  input {\n    height: 43px;\n    box-shadow: none;\n    cursor:pointer;\n    border-radius: 7px;\n    font-size: 18px;\n    font-family: Raleway;\n    border: 1px solid ", ";\n    background:", ";\n    padding: 10px 20px;\n    box-sizing: border-box;\n    font-size: 14px;\n    font-family:", "\n  }\n  .MuiInputBase-root{\n    &:before{\n    border-bottom: none;\n    }\n    &:after{\n      border-bottom: none;\n      }\n  }\n  .MuiInput-underline:hover:not(.Mui-disabled):before{\n      border-bottom: none;\n  }\n}\n"])), theme.palette.grey[25], theme.palette.white[100], theme.typography.normal.raleway);
export var Title = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 18px;\n  font-weight: 600;\n  font-family: ", ";\n"], ["\n  font-size: 18px;\n  font-weight: 600;\n  font-family: ", ";\n"])), theme.typography.OpenSans);
export var Label = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 8px;\n  font-size: 14px;\n  font-family: ", ";\n  &.error {\n    margin-top: 5px;\n    height: 10px;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 8px;\n  font-size: 14px;\n  font-family: ", ";\n  &.error {\n    margin-top: 5px;\n    height: 10px;\n  }\n"])), theme.typography.normal.raleway);
export var InputContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-top: 20px;\n  display: flex;\n  flex-direction: column;\n  &.width {\n    width: 45%;\n    margin-top: 5px;\n\n    @media (max-width: ", "px) {\n      width: 100%;\n      margin-top: 25px;\n      &:first-child {\n        margin-top: 5px;\n      }\n    }\n  }\n"], ["\n  margin-top: 20px;\n  display: flex;\n  flex-direction: column;\n  &.width {\n    width: 45%;\n    margin-top: 5px;\n\n    @media (max-width: ", "px) {\n      width: 100%;\n      margin-top: 25px;\n      &:first-child {\n        margin-top: 5px;\n      }\n    }\n  }\n"])), M);
export var Center = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n"], ["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n"])));
export var RowContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  &.right {\n    justify-content: flex-end;\n  }\n  &.close {\n    .MuiSvgIcon-root {\n      width: 15px;\n      cursor: pointer;\n    }\n  }\n  @media (max-width: ", "px) {\n    align-items: flex-end;\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  &.right {\n    justify-content: flex-end;\n  }\n  &.close {\n    .MuiSvgIcon-root {\n      width: 15px;\n      cursor: pointer;\n    }\n  }\n  @media (max-width: ", "px) {\n    align-items: flex-end;\n    flex-direction: column;\n  }\n"])), M);
export var FileInput = styled.input(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  border: none;\n  font-family: ", ";\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 16px;\n"], ["\n  border: none;\n  font-family: ", ";\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 16px;\n"])), theme.typography.normal.raleway);
export var ImportButton = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  background: ", ";\n  border: 1px dashed ", ";\n  box-sizing: border-box;\n  color: ", ";\n  display: flex;\n  align-items: center;\n  border-radius: 8px;\n  width: 100%;\n  margin-top: 35px;\n  height: 49px;\n  cursor: pointer;\n  justify-content: center;\n"], ["\n  background: ", ";\n  border: 1px dashed ", ";\n  box-sizing: border-box;\n  color: ", ";\n  display: flex;\n  align-items: center;\n  border-radius: 8px;\n  width: 100%;\n  margin-top: 35px;\n  height: 49px;\n  cursor: pointer;\n  justify-content: center;\n"])), theme.palette.admin.primary[35], theme.palette.admin.primary[75], theme.palette.admin.primary[75]);
export var Error = styled.label(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  color: red;\n  align-self: flex-start;\n  font-size: 12px;\n  margin-top: 10px;\n  height: 15px;\n\n  &.select {\n    margin-top: -26px;\n  }\n\n  @media (max-width: ", "px) {\n    align-self: center;\n    align-items: center;\n  }\n"], ["\n  color: red;\n  align-self: flex-start;\n  font-size: 12px;\n  margin-top: 10px;\n  height: 15px;\n\n  &.select {\n    margin-top: -26px;\n  }\n\n  @media (max-width: ", "px) {\n    align-self: center;\n    align-items: center;\n  }\n"])), S);
export var FileErrorContainer = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: 25px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: 25px;\n"])));
export var CandelarContainer = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  height: 43px;\n  box-shadow: none;\n  border-radius: 7px;\n  font-size: 14px;\n  font-family: Raleway;\n  background: ", ";\n  border: 1px solid ", ";\n  padding: 10px 20px;\n  box-sizing: border-box;\n  display: flex;\n  justify-content: space-between;\n"], ["\n  height: 43px;\n  box-shadow: none;\n  border-radius: 7px;\n  font-size: 14px;\n  font-family: Raleway;\n  background: ", ";\n  border: 1px solid ", ";\n  padding: 10px 20px;\n  box-sizing: border-box;\n  display: flex;\n  justify-content: space-between;\n"])), theme.palette.white[100], theme.palette.grey[25]);
export var Img = styled.img(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  cursor: pointer;\n  &.calendar {\n    margin-left: 3px;\n    width: 14px;\n  }\n"], ["\n  cursor: pointer;\n  &.calendar {\n    margin-left: 3px;\n    width: 14px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
