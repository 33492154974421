var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { Element } from 'react-scroll';
import { theme, M, L } from '../../Utils';
export var Container = styled.section(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  min-height: 600px;\n  width: 100%;\n  padding: 4%;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  @media (max-width: ", "px) {\n    padding: 50px;\n  }\n"], ["\n  display: flex;\n  min-height: 600px;\n  width: 100%;\n  padding: 4%;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  @media (max-width: ", "px) {\n    padding: 50px;\n  }\n"])), M);
export var HeaderContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  margin-bottom: 50px;\n"], ["\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  margin-bottom: 50px;\n"])));
export var Title = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  color: ", ";\n  font-family: ", ";\n  color: ", ";\n  font-size: 36px;\n  font-weight: 600;\n  margin-bottom: 20px;\n  @media (max-width: ", "px) {\n    font-size: 18px;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  color: ", ";\n  font-family: ", ";\n  color: ", ";\n  font-size: 36px;\n  font-weight: 600;\n  margin-bottom: 20px;\n  @media (max-width: ", "px) {\n    font-size: 18px;\n  }\n"])), theme.palette.white[100], theme.typography.normal.raleway, theme.palette.white[100], M);
export var CardsContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n  }\n"])), L);
export var Description = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  flex-direction: column;\n  color: ", ";\n  font-family: ", ";\n  font-size: 18px;\n  max-width: 900px;\n  @media (max-width: ", "px) {\n    font-size: 14px;\n    max-width: 300px;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  flex-direction: column;\n  color: ", ";\n  font-family: ", ";\n  font-size: 18px;\n  max-width: 900px;\n  @media (max-width: ", "px) {\n    font-size: 14px;\n    max-width: 300px;\n  }\n"])), theme.palette.white[100], theme.typography.OpenSans, L);
export var ElementComponent = styled(Element)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
