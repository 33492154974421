var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme } from 'src/Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  gap: 12px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  gap: 12px;\n"])));
export var Label = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n"])), theme.palette.grey[60], theme.typography.normal.raleway);
export var StyledInput = styled.input(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  all: unset;\n  border: ", ";\n  border-radius: 9px;\n  background: ", ";\n  box-shadow: 0px 0px 8px 0px rgba(173, 173, 173, 0.25);\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  padding: 12px;\n  ::placeholder {\n    color: ", ";\n  }\n"], ["\n  all: unset;\n  border: ",
    ";\n  border-radius: 9px;\n  background: ", ";\n  box-shadow: 0px 0px 8px 0px rgba(173, 173, 173, 0.25);\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  padding: 12px;\n  ::placeholder {\n    color: ", ";\n  }\n"])), function (_a) {
    var error = _a.error;
    return error ? "1px solid " + theme.palette.error[60] : 'unset';
}, theme.palette.white[100], theme.palette.black[80], theme.typography.normal.raleway, theme.palette.grey[50]);
export var StyledTextArea = styled(StyledInput.withComponent('textarea'))(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  min-height: 96px;\n"], ["\n  min-height: 96px;\n"])));
export var ErrorMessage = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 14px;\n  letter-spacing: 0em;\n  text-align: left;\n  color: ", ";\n  margin: 0px;\n"], ["\n  font-family: ", ";\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 14px;\n  letter-spacing: 0em;\n  text-align: left;\n  color: ", ";\n  margin: 0px;\n"])), theme.typography.normal.raleway, theme.palette.error[60]);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
