var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { InputBase, Select } from '@material-ui/core';
import { theme, M } from '../../Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  min-height: 100vh;\n  height: 100%;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 0px 5%;\n  flex-direction: column;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    padding: 0px;\n  }\n"], ["\n  display: flex;\n  min-height: 100vh;\n  height: 100%;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 0px 5%;\n  flex-direction: column;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    padding: 0px;\n  }\n"])), theme.palette.admin.primary[50], M);
export var TableContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 17px;\n  width: 100%;\n  background-color: ", ";\n  border-radius: 21px;\n  @media (max-width: ", "px) {\n    background-color: ", ";\n    &.contact {\n      padding: 0px;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 17px;\n  width: 100%;\n  background-color: ", ";\n  border-radius: 21px;\n  @media (max-width: ", "px) {\n    background-color: ", ";\n    &.contact {\n      padding: 0px;\n    }\n  }\n"])), theme.palette.white[100], M, theme.palette.admin.primary[50]);
export var Title = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 24px;\n  font-family: ", ";\n  width: 100%;\n  margin-bottom: 30px;\n  margin-top: 5%;\n  margin-left: -20px;\n  background-color: ", ";\n  color: ", ";\n  &.non-margin {\n    margin-left: 0;\n  }\n  &.go-back {\n    font-size: 15px;\n    cursor: pointer;\n  }\n  @media (max-width: ", "px) {\n    margin-left: 0px;\n    font-size: 18px;\n    font-family: ", ";\n    color: ", ";\n    padding-left: 28px;\n    align-self: baseline;\n    width: auto;\n  }\n"], ["\n  font-size: 24px;\n  font-family: ", ";\n  width: 100%;\n  margin-bottom: 30px;\n  margin-top: 5%;\n  margin-left: -20px;\n  background-color: ", ";\n  color: ", ";\n  &.non-margin {\n    margin-left: 0;\n  }\n  &.go-back {\n    font-size: 15px;\n    cursor: pointer;\n  }\n  @media (max-width: ", "px) {\n    margin-left: 0px;\n    font-size: 18px;\n    font-family: ", ";\n    color: ", ";\n    padding-left: 28px;\n    align-self: baseline;\n    width: auto;\n  }\n"])), theme.typography.bold.ralewayBold, theme.palette.admin.primary[50], theme.palette.admin.primary[75], M, theme.typography.OpenSans, theme.palette.black[100]);
export var UploadContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  z-index: 2;\n  right: 0;\n  bottom: 0;\n  position: fixed;\n"], ["\n  z-index: 2;\n  right: 0;\n  bottom: 0;\n  position: fixed;\n"])));
export var MessageContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  z-index: 11;\n  right: 18px;\n  top: 18px;\n  position: fixed;\n"], ["\n  z-index: 11;\n  right: 18px;\n  top: 18px;\n  position: fixed;\n"])));
export var UploadButton = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
export var StyledArrow = styled.img(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 28px;\n  height: 14px;\n  cursor: pointer;\n"], ["\n  width: 28px;\n  height: 14px;\n  cursor: pointer;\n"])));
export var ContactContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n  width: 100%;\n  flex-wrap: wrap;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n  width: 100%;\n  flex-wrap: wrap;\n"])));
export var Link = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-style: normal;\n  font-size: 13px;\n  text-decoration-line: underline;\n  font-family: ", ";\n  width: 100%;\n  margin-bottom: 30px;\n  cursor: pointer;\n  margin-top: 5%;\n  margin-left: 10px;\n  background-color: ", ";\n  color: ", ";\n"], ["\n  font-style: normal;\n  font-size: 13px;\n  text-decoration-line: underline;\n  font-family: ", ";\n  width: 100%;\n  margin-bottom: 30px;\n  cursor: pointer;\n  margin-top: 5%;\n  margin-left: 10px;\n  background-color: ", ";\n  color: ", ";\n"])), theme.typography.bold.ralewayBold, theme.palette.admin.primary[50], theme.palette.admin.primary[75]);
export var DetailsContainer = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  min-height: 150px;\n  max-width: 195px;\n  align-items: center;\n  justify-content: center;\n  padding: 25px 35px;\n  flex-direction: column;\n  border-radius: 8px;\n  margin-bottom: 14px;\n  background-color: ", ";\n  &.form {\n    max-width: 280px;\n    padding: 35px 35px 5px 35px;\n    @media (max-width: ", "px) {\n      margin-left: 10px;\n      max-width: inherit;\n      padding: 0px;\n      background-color: ", ";\n    }\n  }\n"], ["\n  display: flex;\n  min-height: 150px;\n  max-width: 195px;\n  align-items: center;\n  justify-content: center;\n  padding: 25px 35px;\n  flex-direction: column;\n  border-radius: 8px;\n  margin-bottom: 14px;\n  background-color: ", ";\n  &.form {\n    max-width: 280px;\n    padding: 35px 35px 5px 35px;\n    @media (max-width: ", "px) {\n      margin-left: 10px;\n      max-width: inherit;\n      padding: 0px;\n      background-color: ", ";\n    }\n  }\n"])), theme.palette.admin.primary[45], M, theme.palette.admin.primary[50]);
export var Image = styled.img(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  position: absolute;\n  top: 80px;\n  right: 35px;\n  height: 38px;\n  width: 38px;\n"], ["\n  position: absolute;\n  top: 80px;\n  right: 35px;\n  height: 38px;\n  width: 38px;\n"])));
export var TitleFilter = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  height: 100%;\n  font-family: ", ";\n  width: 100%;\n  font-size: 10px;\n  align-items: left;\n"], ["\n  display: flex;\n  height: 100%;\n  font-family: ", ";\n  width: 100%;\n  font-size: 10px;\n  align-items: left;\n"])), theme.typography.bold.ralewayBold);
export var LeftContainer = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 100%;\n  margin: 10px;\n  margin-bottom: 15px;\n  align-items: flex-start;\n  justify-content: center;\n\n  .width {\n    width: 100%;\n  }\n  .row {\n    flex-direction: row;\n  }\n  @media (max-width: ", "px) {\n    width: 70%;\n    &.left {\n      align-items: flex-start;\n      width: 100%;\n    }\n    &.contact {\n      display: none;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 100%;\n  margin: 10px;\n  margin-bottom: 15px;\n  align-items: flex-start;\n  justify-content: center;\n\n  .width {\n    width: 100%;\n  }\n  .row {\n    flex-direction: row;\n  }\n  @media (max-width: ", "px) {\n    width: 70%;\n    &.left {\n      align-items: flex-start;\n      width: 100%;\n    }\n    &.contact {\n      display: none;\n    }\n  }\n"])), M);
export var SelectCont = styled(Select)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  text-transform: capitalize;\n  z-index: 0;\n  width: fit-content;\n  min-height: 38px;\n  border-radius: 0px;\n  height: 100%;\n  padding: 5px 8px;\n  max-width: 30vw;\n  border-bottom: 1px solid ", ";\n\n  .building {\n    width: 15vw;\n    max-width: 30vw;\n  }\n\n  .MuiSelect-select:focus {\n    border-radius: 0;\n    background: none;\n  }\n"], ["\n  text-transform: capitalize;\n  z-index: 0;\n  width: fit-content;\n  min-height: 38px;\n  border-radius: 0px;\n  height: 100%;\n  padding: 5px 8px;\n  max-width: 30vw;\n  border-bottom: 1px solid ", ";\n\n  .building {\n    width: 15vw;\n    max-width: 30vw;\n  }\n\n  .MuiSelect-select:focus {\n    border-radius: 0;\n    background: none;\n  }\n"])), theme.palette.primary[75]);
export var Input = styled(InputBase)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 14px !important;\n  background-color: ", ";\n  height: 35px !important;\n  width: 100%;\n  max-width: 178px;\n  border-width: 0px;\n  font-family: ", " !important;\n  @media (max-width: ", "px) {\n    background-color: ", ";\n  }\n"], ["\n  color: ", ";\n  font-size: 14px !important;\n  background-color: ", ";\n  height: 35px !important;\n  width: 100%;\n  max-width: 178px;\n  border-width: 0px;\n  font-family: ", " !important;\n  @media (max-width: ", "px) {\n    background-color: ", ";\n  }\n"])), theme.palette.black[100], theme.palette.white[100], theme.typography.normal.raleway, M, theme.palette.admin.primary[50]);
export var Img = styled.img(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  cursor: pointer;\n  &.calendar {\n    margin-left: 3px;\n    width: 14px;\n  }\n  &.filter {\n    width: 15px;\n  }\n"], ["\n  cursor: pointer;\n  &.calendar {\n    margin-left: 3px;\n    width: 14px;\n  }\n  &.filter {\n    width: 15px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;
