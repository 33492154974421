var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M } from '../../Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  min-height: 100vh;\n  height: 100%;\n  justify-content: flex-start;\n  padding: 0px 5%;\n  flex-direction: column;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    padding-left: 25px;\n  }\n"], ["\n  display: flex;\n  min-height: 100vh;\n  height: 100%;\n  justify-content: flex-start;\n  padding: 0px 5%;\n  flex-direction: column;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    padding-left: 25px;\n  }\n"])), theme.palette.admin.primary[50], M);
export var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 24px;\n  font-family: ", ";\n  width: 100%;\n  margin-bottom: 26px;\n  margin-top: 5%;\n  background-color: ", ";\n  color: ", ";\n  @media (max-width: ", "px) {\n    margin-left: 10px;\n    font-size: 16px;\n    font-family: ", ";\n    color: ", ";\n  }\n"], ["\n  font-size: 24px;\n  font-family: ", ";\n  width: 100%;\n  margin-bottom: 26px;\n  margin-top: 5%;\n  background-color: ", ";\n  color: ", ";\n  @media (max-width: ", "px) {\n    margin-left: 10px;\n    font-size: 16px;\n    font-family: ", ";\n    color: ", ";\n  }\n"])), theme.typography.bold.ralewayBold, theme.palette.admin.primary[50], theme.palette.admin.primary[75], M, theme.typography.OpenSans, theme.palette.black[100]);
export var DetailsContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  box-sizing: border-box;\n  min-height: 265px;\n  max-width: 367px;\n  align-items: center;\n  justify-content: center;\n  padding: 25px 35px;\n  flex-direction: column;\n  border-radius: 8px;\n  margin-bottom: 14px;\n  background-color: ", ";\n  &.form {\n    padding: 35px 35px 5px 35px;\n    @media (max-width: ", "px) {\n      margin-left: 10px;\n      max-width: inherit;\n      padding: 0px;\n      background-color: ", ";\n    }\n  }\n"], ["\n  display: flex;\n  box-sizing: border-box;\n  min-height: 265px;\n  max-width: 367px;\n  align-items: center;\n  justify-content: center;\n  padding: 25px 35px;\n  flex-direction: column;\n  border-radius: 8px;\n  margin-bottom: 14px;\n  background-color: ", ";\n  &.form {\n    padding: 35px 35px 5px 35px;\n    @media (max-width: ", "px) {\n      margin-left: 10px;\n      max-width: inherit;\n      padding: 0px;\n      background-color: ", ";\n    }\n  }\n"])), theme.palette.admin.primary[45], M, theme.palette.admin.primary[50]);
export var ItemContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: flex-start;\n  margin-bottom: 25px;\n  background-color: ", ";\n  &:last-child {\n    margin-bottom: 0px;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: flex-start;\n  margin-bottom: 25px;\n  background-color: ", ";\n  &:last-child {\n    margin-bottom: 0px;\n  }\n"])), theme.palette.admin.primary[45]);
export var Label = styled.label(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 16px;\n  width: 30%;\n  background-color: ", ";\n  &.bold {\n    width: 70%;\n    font-family: ", ";\n    font-weight: 600;\n    margin-right: 0px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 14px;\n  }\n  &.text-wrap {\n    overflow-wrap: break-word;\n    word-wrap: break-word;\n  }\n"], ["\n  font-size: 16px;\n  width: 30%;\n  background-color: ", ";\n  &.bold {\n    width: 70%;\n    font-family: ", ";\n    font-weight: 600;\n    margin-right: 0px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 14px;\n  }\n  &.text-wrap {\n    overflow-wrap: break-word;\n    word-wrap: break-word;\n  }\n"])), theme.palette.admin.primary[45], theme.typography.bold.ralewayBold, M);
export var Link = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: fit-content;\n  font-size: 14px;\n  text-decoration-line: underline;\n  margin-bottom: 14px;\n  cursor: pointer;\n  color: ", ";\n  &.margin-right {\n    margin-right: 16px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 12px;\n  }\n"], ["\n  width: fit-content;\n  font-size: 14px;\n  text-decoration-line: underline;\n  margin-bottom: 14px;\n  cursor: pointer;\n  color: ", ";\n  &.margin-right {\n    margin-right: 16px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 12px;\n  }\n"])), theme.palette.admin.primary[75], M);
export var LinkRed = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: fit-content;\n  font-size: 14px;\n  text-decoration-line: underline;\n  margin-bottom: 14px;\n  cursor: pointer;\n  color: ", ";\n  &.margin-right {\n    margin-right: 16px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 12px;\n  }\n"], ["\n  width: fit-content;\n  font-size: 14px;\n  text-decoration-line: underline;\n  margin-bottom: 14px;\n  cursor: pointer;\n  color: ", ";\n  &.margin-right {\n    margin-right: 16px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 12px;\n  }\n"])), theme.palette.admin.primary[85], M);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
