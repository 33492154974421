import React from 'react';
import { StyledCheckBox } from './styles';
import { InputCheck, InputChecked, ErrorCheck } from '../../../assets/Images';
var CheckBox = function (_a) {
    var selected = _a.selected, onClick = _a.onClick, error = _a.error;
    var getImg = function () {
        if (error)
            return ErrorCheck;
        return selected ? InputChecked : InputCheck;
    };
    return React.createElement(StyledCheckBox, { src: getImg(), onClick: onClick });
};
export default CheckBox;
