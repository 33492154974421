import React from 'react';
import { ContactSection, Footer } from '../../Views';
import { Container, Title, Img } from './styles';
import { CONTACT, M, CLOSED_DAYS, AFTERNOON_HOURS, BUSINESS_HOURS, MORNING_HOURS, VACATION_HOURS, MARCH_DECEMBER, JANUARY_FEBRUARY, } from 'src/Utils';
import { ContactBackground, MobileContactBackground, } from '../../assets/Images';
import { useWindowWidth } from 'src/useWindowWidth';
import { ContactMap } from 'src/Components';
import { MONDAY_FRIDAY } from 'src/Utils/constants';
import { Header } from 'src/Layouts';
var ContactPage = function () {
    var windowWidth = useWindowWidth();
    var locationInfo = {
        title: BUSINESS_HOURS,
        hours: [
            {
                months_title: MARCH_DECEMBER,
                days_title: MONDAY_FRIDAY,
                hours: [MORNING_HOURS, AFTERNOON_HOURS],
            },
            {
                months_title: JANUARY_FEBRUARY,
                days_title: MONDAY_FRIDAY,
                hours: [VACATION_HOURS],
            },
        ],
        box_text: CLOSED_DAYS,
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, null),
        windowWidth > M && React.createElement(Img, { src: ContactBackground }),
        React.createElement(Container, { style: {
                backgroundImage: "url(" + (windowWidth > M ? '' : MobileContactBackground) + ")",
            } },
            React.createElement(Title, null, CONTACT),
            React.createElement(ContactSection, { isContactPage: true })),
        React.createElement(ContactMap, { locationInfo: locationInfo }),
        React.createElement(Footer, null)));
};
export default ContactPage;
