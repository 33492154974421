var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M } from 'src/Utils';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  gap: 20px;\n  margin-top: 138px;\n  justify-content: center;\n  min-height: 100vh;\n  @media (max-width: ", "px) {\n    margin-top: 120px;\n  }\n"], ["\n  display: flex;\n  gap: 20px;\n  margin-top: 138px;\n  justify-content: center;\n  min-height: 100vh;\n  @media (max-width: ", "px) {\n    margin-top: 120px;\n  }\n"])), M);
export var InnerWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 1322px;\n  padding: 60px;\n  display: flex;\n  flex-direction: column;\n  box-sizing: border-box;\n  @media (max-width: ", "px) {\n    padding: 20px;\n  }\n"], ["\n  width: 100%;\n  max-width: 1322px;\n  padding: 60px;\n  display: flex;\n  flex-direction: column;\n  box-sizing: border-box;\n  @media (max-width: ", "px) {\n    padding: 20px;\n  }\n"])), M);
export var Row = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  gap: ", ";\n  &.space-between {\n    justify-content: space-between;\n  }\n  &.align-center {\n    align-items: center;\n  }\n"], ["\n  display: flex;\n  gap: ", ";\n  &.space-between {\n    justify-content: space-between;\n  }\n  &.align-center {\n    align-items: center;\n  }\n"])), function (_a) {
    var gap = _a.gap;
    return gap;
});
export var Column = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: ", ";\n"])), function (_a) {
    var gap = _a.gap;
    return gap;
});
export var Divider = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  border-bottom: 1px solid ", ";\n"], ["\n  width: 100%;\n  border-bottom: 1px solid ", ";\n"])), theme.palette.grey[55]);
export var Title = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 600;\n  @media (max-width: ", "px) {\n    font-size: 14px;\n    font-family: ", ";\n  }\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 600;\n  @media (max-width: ", "px) {\n    font-size: 14px;\n    font-family: ", ";\n  }\n"])), theme.palette.black[60], theme.typography.normal.raleway, M, theme.typography.bold.ralewayBold);
export var Text = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 164.9%;\n\n  ul {\n    padding-left: 20px;\n  }\n\n  @media (max-width: ", "px) {\n    font-size: 14px;\n  }\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 164.9%;\n\n  ul {\n    padding-left: 20px;\n  }\n\n  @media (max-width: ", "px) {\n    font-size: 14px;\n  }\n"])), theme.palette.black[80], theme.typography.normal.raleway, M);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
