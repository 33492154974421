import api from 'src/Utils/api';
export var editReservation = function (_a) {
    var id = _a.id, amenityId = _a.amenityId, date = _a.date, scheduleId = _a.scheduleId, clientCode = _a.clientCode, extras = _a.extras, startHour = _a.startHour, endHour = _a.endHour;
    var body = {
        booking: {
            amenity_id: amenityId,
            day: date,
            schedule_id: scheduleId,
            numeric_client_code: clientCode,
            extras: extras,
        },
    };
    if (startHour && endHour) {
        body.booking['start_hour'] = startHour;
        body.booking['end_hour'] = endHour;
    }
    return api.put("/bookings/" + id, body);
};
