var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme } from 'src/Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  padding: 29px;\n  background-color: ", ";\n  width: 50%;\n  gap: 29px;\n  border-radius: 8px;\n  height: auto;\n  min-width: 195px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  padding: 29px;\n  background-color: ", ";\n  width: 50%;\n  gap: 29px;\n  border-radius: 8px;\n  height: auto;\n  min-width: 195px;\n"])), theme.palette.admin.primary[45]);
export var Row = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  gap: ", ";\n  &.align-center {\n    align-items: center;\n  }\n"], ["\n  display: flex;\n  gap: ", ";\n  &.align-center {\n    align-items: center;\n  }\n"])), function (_a) {
    var gap = _a.gap;
    return gap;
});
export var Column = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n"])));
export var Label = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 21px;\n  font-weight: 600;\n  text-transform: capitalize;\n"], ["\n  font-family: ", ";\n  color: ", ";\n  font-size: 21px;\n  font-weight: 600;\n  text-transform: capitalize;\n"])), theme.typography.bold.ralewayBold, theme.palette.black[100]);
export var Value = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 21px;\n  font-weight: 400;\n"], ["\n  font-family: ", ";\n  color: ", ";\n  font-size: 21px;\n  font-weight: 400;\n"])), theme.typography.normal.raleway, theme.palette.black[100]);
export var LabelDesc = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  font-weight: 400;\n  text-transform: capitalize;\n"], ["\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  font-weight: 400;\n  text-transform: capitalize;\n"])), theme.typography.normal.raleway, theme.palette.black[100]);
export var ValueDesc = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  font-weight: 600;\n  margin-left: 4px;\n  line-height: 1.5;\n"], ["\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  font-weight: 600;\n  margin-left: 4px;\n  line-height: 1.5;\n"])), theme.typography.bold.ralewayBold, theme.palette.black[100]);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
