import api from 'src/Utils/api';
export var submitDataForm = function (_a) {
    var name = _a.name, building_id = _a.building_id, date = _a.date;
    return api.post('/bills', {
        name: name,
        building_id: building_id,
        date: date,
    });
};
export var submitPDF = function (_a) {
    var file = _a.file, id = _a.id;
    var formData = new FormData();
    formData.append('pdf', file);
    return api.put("/bills/" + id + "/pdf", formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
