import React from 'react';
import { useQuery } from 'react-query';
import { BeatLoader } from 'react-spinners';
import { Table, TableBody } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';
import { AdminLayout } from 'src/Layouts';
import { getData } from 'src/Utils/api';
import { APARTMENT_CONTACTS, APARTMENT_NOT_FOUND, NO_ASSOCIATED_CONTACTS, theme } from 'src/Utils';
import TableHeader from './TableHeader';
import ApartmentContactsRow from './ApartmentContactRow';
import { MainContainer, Title, Container, TableContainer, BackContainer, } from './styles';
var ApartmentContactsPage = function () {
    var _a, _b;
    var apartment_id = window.location.pathname.split('/')[2];
    var goBack = useHistory().goBack;
    var _c = useQuery([], function () {
        return getData("/apartments/" + apartment_id + "/contacts");
    }), data = _c.data, isLoading = _c.isLoading;
    var apartment_number = (_a = data === null || data === void 0 ? void 0 : data.apartment_contacts[0]) === null || _a === void 0 ? void 0 : _a.apartment_number;
    var title = APARTMENT_CONTACTS + apartment_number;
    var RenderLoaderOrError = function () {
        var element;
        if (isLoading)
            element = React.createElement(BeatLoader, { size: "16px", color: theme.palette.primary[50] });
        else if (!data)
            element = React.createElement("p", null, APARTMENT_NOT_FOUND);
        else if (!data.apartment_contacts[0])
            element = React.createElement("p", null, NO_ASSOCIATED_CONTACTS);
        else
            return null;
        return React.createElement(MainContainer, null, element);
    };
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null, RenderLoaderOrError() || (React.createElement(React.Fragment, null,
            React.createElement(Title, null,
                React.createElement(BackContainer, { onClick: goBack },
                    React.createElement(ArrowBack, null)),
                title),
            React.createElement(TableContainer, null,
                React.createElement(Table, null,
                    React.createElement(TableHeader, null),
                    React.createElement(TableBody, null, (_b = data === null || data === void 0 ? void 0 : data.apartment_contacts) === null || _b === void 0 ? void 0 : _b.map(function (row) { return (React.createElement(ApartmentContactsRow, { key: row.id, data: row })); })))))))));
};
export default ApartmentContactsPage;
