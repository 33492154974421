var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme } from 'src/Utils';
export var PriceContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n"])));
export var Price = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: 600;\n  font-size: 16px;\n  margin-bottom: 8px;\n"], ["\n  font-weight: 600;\n  font-size: 16px;\n  margin-bottom: 8px;\n"])));
export var Currency = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 11px;\n"], ["\n  font-size: 11px;\n"])));
export var RightContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: space-between;\n"])));
export var Container = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  width: 90%;\n  padding: 20px;\n  border-radius: 7px;\n  align-items: center;\n  background-color: ", ";\n  height: 90px;\n  max-width: 325px;\n  margin: 0px 1%;\n  margin-bottom: 20px;\n"], ["\n  display: flex;\n  width: 90%;\n  padding: 20px;\n  border-radius: 7px;\n  align-items: center;\n  background-color: ", ";\n  height: 90px;\n  max-width: 325px;\n  margin: 0px 1%;\n  margin-bottom: 20px;\n"])), theme.palette.white[100]);
export var ColumnContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n"])));
export var Content = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  height: 100%;\n  width: 100%;\n  justify-content: flex-start;\n  align-items: space-between;\n"], ["\n  display: flex;\n  height: 100%;\n  width: 100%;\n  justify-content: flex-start;\n  align-items: space-between;\n"])));
export var ImageContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 30%;\n  justify-content: center;\n  display: flex;\n  padding: 0px;\n  justify-content: flex-end;\n  align-self: flex-end;\n  cursor: pointer;\n  margin-bottom: 5px;\n"], ["\n  width: 30%;\n  justify-content: center;\n  display: flex;\n  padding: 0px;\n  justify-content: flex-end;\n  align-self: flex-end;\n  cursor: pointer;\n  margin-bottom: 5px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
