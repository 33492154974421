var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { components } from 'react-select';
import { theme } from 'src/Utils';
import { CheckboxButton } from 'src/Components/RealEstateProperties/Filter/CheckboxFilter/Checkbox/styles';
var Option = function (_a) {
    var getStyles = _a.getStyles, isDisabled = _a.isDisabled, isFocused = _a.isFocused, isSelected = _a.isSelected, children = _a.children, innerProps = _a.innerProps, rest = __rest(_a, ["getStyles", "isDisabled", "isFocused", "isSelected", "children", "innerProps"]);
    var color = isSelected || isFocused ? theme.palette.blue[5] : theme.palette.black[100];
    var fontFamily = isSelected
        ? theme.typography.bold.ralewayBold
        : theme.typography.normal.raleway;
    var cursor = isFocused ? 'pointer' : 'default';
    var style = {
        alignItems: 'center',
        display: 'flex',
        gap: '14px',
        fontSize: '14px',
        backgroundColor: theme.palette.white[100],
        color: color,
        fontFamily: fontFamily,
        cursor: cursor,
    };
    var props = __assign(__assign({}, innerProps), { style: style });
    return (React.createElement(components.Option, __assign({}, rest, { isDisabled: isDisabled, isFocused: isFocused, isSelected: isSelected, getStyles: getStyles, innerProps: props }),
        React.createElement(CheckboxButton, { type: "checkbox", checked: isSelected }),
        children));
};
export default Option;
