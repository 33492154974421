var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { Modal } from '@material-ui/core';
export var StyledModal = styled(Modal)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  outline: none;\n  &:focus-visible {\n    outline: none;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  outline: none;\n  &:focus-visible {\n    outline: none;\n  }\n"])));
export var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  &:focus-visible {\n    outline: none;\n  }\n"], ["\n  &:focus-visible {\n    outline: none;\n  }\n"])));
export var Nav = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  z-index: 99;\n  border-radius: 30px;\n  padding: 8px;\n  position: absolute;\n  top: 275px;\n  cursor: pointer;\n  &.left {\n    background: #fff;\n    left: -50px;\n  }\n  &.right {\n    background: #fff;\n    right: -50px;\n  }\n  &.mobileL {\n    top: 50%;\n    left: 5px;\n  }\n  &.mobileR {\n    top: 50%;\n    right: 5px;\n  }\n"], ["\n  z-index: 99;\n  border-radius: 30px;\n  padding: 8px;\n  position: absolute;\n  top: 275px;\n  cursor: pointer;\n  &.left {\n    background: #fff;\n    left: -50px;\n  }\n  &.right {\n    background: #fff;\n    right: -50px;\n  }\n  &.mobileL {\n    top: 50%;\n    left: 5px;\n  }\n  &.mobileR {\n    top: 50%;\n    right: 5px;\n  }\n"])));
export var ItemContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: inline-block;\n  position: relative;\n  object-fit: contain;\n  ", "\n"], ["\n  display: inline-block;\n  position: relative;\n  object-fit: contain;\n  ", "\n"])), function (props) { return props.isMobile && 'width: 100%'; });
export var ItemImage = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: inherit;\n  ", "\n"], ["\n  width: inherit;\n  ", "\n"])), function (props) { return props.isMobile && 'height: inherit'; });
export var ButtonContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  top: 32px;\n  right: 32px;\n  transition: background 300ms ease-out, border-radius 300ms ease-out;\n  &:hover {\n    cursor: pointer;\n    background: #d0d0d0;\n    border-radius: 5px;\n  }\n"], ["\n  position: absolute;\n  top: 32px;\n  right: 32px;\n  transition: background 300ms ease-out, border-radius 300ms ease-out;\n  &:hover {\n    cursor: pointer;\n    background: #d0d0d0;\n    border-radius: 5px;\n  }\n"])));
export var Icon = styled.img(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 4px;\n  transition: filter 300ms ease-out;\n  transform: scale(1.2);\n  filter: brightness(0);\n  &:hover {\n    filter: brightness(0) invert(1);\n  }\n"], ["\n  padding: 4px;\n  transition: filter 300ms ease-out;\n  transform: scale(1.2);\n  filter: brightness(0);\n  &:hover {\n    filter: brightness(0) invert(1);\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
