import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FILTERS, FILTER_BY, M, RENT, SALE } from "src/Utils";
import { useWindowWidth } from "src/useWindowWidth";
import { CloseCross } from "src/assets/Images";
import { Row, ToggleButton as Button, Title } from "../styles";
import ResetFilter from "./ResetFilter";
import { Container } from './styles';
var PROPERTY = 'operation_type';
var ToggleFilter = function (_a) {
    var setIsOpen = _a.setIsOpen;
    var _b = useState(false), isSale = _b[0], setIsSale = _b[1];
    var _c = useFormContext(), watch = _c.watch, setValue = _c.setValue;
    var currentValue = isSale ? 'purchase' : 'rent';
    var watchedValue = watch(PROPERTY);
    useEffect(function () {
        if (watchedValue !== undefined && currentValue !== watchedValue) {
            setIsSale(watchedValue === 'purchase');
        }
    }, []);
    var handleOpChange = function (op) {
        if (op !== isSale) {
            setIsSale(op);
            setValue(PROPERTY, op ? 'purchase' : 'rent');
            setValue("currency", op ? "USD" : "UYU");
        }
    };
    var mobile = useWindowWidth() < M;
    var isLoading = watchedValue !== currentValue;
    return (React.createElement(Container, { className: isLoading ? 'hidden' : '' },
        React.createElement(Row, { className: "space-between align-center" },
            React.createElement(Title, { className: mobile ? 'big' : '' }, mobile ? FILTERS : FILTER_BY),
            setIsOpen ? React.createElement("img", { onClick: function () { return setIsOpen(false); }, src: CloseCross }) : React.createElement(ResetFilter, null)),
        React.createElement(Row, { className: "full-width " + (mobile ? 'height-48 m-4' : '') },
            React.createElement(Button, { className: "left full-width " + (!isSale ? 'isActive' : ''), onClick: function () { return handleOpChange(false); } }, RENT),
            React.createElement(Button, { className: "right full-width " + (!isSale ? '' : 'isActive'), onClick: function () { return handleOpChange(true); } }, SALE))));
};
export default ToggleFilter;
