import React, { useState, useEffect } from 'react';
import { Row, DotsImg } from '../../Common/Table/TableRow/styles';
import { Cell } from './style';
import { Dots } from 'src/assets/Images';
import { Popover } from 'src/Components/Common';
import { DELETE } from 'src/Utils/constants';
var DeleteAdminsRow = function (_a) {
    var data = _a.data, widths = _a.widths, handleDelete = _a.handleDelete, setSelectedAdmin = _a.setSelectedAdmin;
    var _b = useState(null), anchorRef = _b[0], setAnchorRef = _b[1];
    var _c = useState({
        label: '',
        value: '',
    }), selectedOption = _c[0], setSelectedOption = _c[1];
    var first = data.first, second = data.second, third = data.third, fourth = data.fourth;
    var handleClick = function (e) {
        return anchorRef ? setAnchorRef(null) : setAnchorRef(e.currentTarget);
    };
    useEffect(function () {
        if (selectedOption.label === DELETE) {
            setSelectedAdmin && setSelectedAdmin(data);
            handleDelete && handleDelete();
        }
    }, [selectedOption]);
    return (React.createElement(Row, { hover: true, tabIndex: -1, key: first },
        [first, second, third, fourth].map(function (item, index) { return (React.createElement(Cell, { key: item + index, style: { width: widths[index] } }, item)); }),
        React.createElement(Cell, { style: { width: widths[4] } },
            React.createElement(DotsImg, { src: Dots, alt: "M\u00E1s", onClick: handleClick })),
        React.createElement(Popover, { data: [{ label: DELETE, value: DELETE }], handleOutsideClick: function () { return setAnchorRef(null); }, anchorRef: anchorRef, setSelected: setSelectedOption, className: "filter width", isFilter: false })));
};
export default DeleteAdminsRow;
