var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { InputBase, Select, TextField } from '@material-ui/core';
import { theme, S, M } from '../../Utils';
export var FormContainer = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  width: 100%;\n"])));
export var FormsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-self: flex-start;\n  max-width: 1000px;\n  width: 100%;\n  background-color: ", ";\n  border-radius: 8px;\n  @media (max-width: ", "px) {\n    margin-left: 10px;\n    max-width: inherit;\n    padding: 0px;\n    padding: 5px 20px;\n    width: auto;\n    background-color: ", ";\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-self: flex-start;\n  max-width: 1000px;\n  width: 100%;\n  background-color: ", ";\n  border-radius: 8px;\n  @media (max-width: ", "px) {\n    margin-left: 10px;\n    max-width: inherit;\n    padding: 0px;\n    padding: 5px 20px;\n    width: auto;\n    background-color: ", ";\n  }\n"])), theme.palette.admin.primary[45], M, theme.palette.admin.primary[50]);
export var FormsDiv = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  max-width: 900px;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n    max-width: inherit;\n    width: auto;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  max-width: 900px;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n    max-width: inherit;\n    width: auto;\n  }\n"])), M);
export var InputContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 100px;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  &.height {\n    min-height: 50px;\n  }\n  &.row {\n    flex-direction: row;\n  }\n  .radio {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    justify-content: flex-end;\n  }\n  &.margin {\n    margin-bottom: 20px;\n  }\n  @media (max-width: ", "px) {\n    width: auto;\n  }\n"], ["\n  height: 100px;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  &.height {\n    min-height: 50px;\n  }\n  &.row {\n    flex-direction: row;\n  }\n  .radio {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    justify-content: flex-end;\n  }\n  &.margin {\n    margin-bottom: 20px;\n  }\n  @media (max-width: ", "px) {\n    width: auto;\n  }\n"])), S);
export var Error = styled.label(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  align-self: flex-start;\n  font-size: 12px;\n  margin-top: 10px;\n  height: 20px;\n  @media (max-width: ", "px) {\n    align-self: center;\n    align-items: center;\n  }\n"], ["\n  color: ", ";\n  align-self: flex-start;\n  font-size: 12px;\n  margin-top: 10px;\n  height: 20px;\n  @media (max-width: ", "px) {\n    align-self: center;\n    align-items: center;\n  }\n"])), theme.palette.error[50], S);
export var TopInputs = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  justify-content: space-between;\n  display: flex;\n  flex-direction: column;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n  }\n"], ["\n  justify-content: space-between;\n  display: flex;\n  flex-direction: column;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n  }\n"])), S);
export var Label = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 14px;\n  margin-bottom: 5px;\n  color: ", ";\n  width: 100%;\n"], ["\n  font-size: 14px;\n  margin-bottom: 5px;\n  color: ", ";\n  width: 100%;\n"])), theme.palette.black[100]);
export var FormTitle = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-weight: 500;\n  line-height: 16px;\n  margin-bottom: 5px;\n  font-family: ", " !important;\n  width: 100%;\n  padding: 0px 20px 35px 0px;\n"], ["\n  font-weight: 500;\n  line-height: 16px;\n  margin-bottom: 5px;\n  font-family: ", " !important;\n  width: 100%;\n  padding: 0px 20px 35px 0px;\n"])), theme.typography.bold.ralewayBold);
export var Link = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-size: 14px;\n  text-decoration-line: underline;\n  cursor: pointer;\n  color: ", ";\n  margin-right: 60px;\n  &.margin-right {\n    margin-right: 16px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 12px;\n  }\n  @media (max-width: ", "px) {\n    margin-left: 18px;\n  }\n"], ["\n  font-size: 14px;\n  text-decoration-line: underline;\n  cursor: pointer;\n  color: ", ";\n  margin-right: 60px;\n  &.margin-right {\n    margin-right: 16px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 12px;\n  }\n  @media (max-width: ", "px) {\n    margin-left: 18px;\n  }\n"])), theme.palette.admin.primary[75], M, S);
export var Div = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  font-size: 14px;\n  font-family: ", ";\n  &.row {\n    flex-direction: row;\n  }\n  &.column {\n    flex-direction: column;\n  }\n  &.height {\n    height: 20px;\n    align-self: center;\n  }\n  &.counter {\n    height: 43px;\n    background-color: white;\n    border-radius: 9px;\n    padding: 0px 8px;\n  }\n  &.spaced {\n    margin-right: 8px;\n    width: 100%;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  font-size: 14px;\n  font-family: ", ";\n  &.row {\n    flex-direction: row;\n  }\n  &.column {\n    flex-direction: column;\n  }\n  &.height {\n    height: 20px;\n    align-self: center;\n  }\n  &.counter {\n    height: 43px;\n    background-color: white;\n    border-radius: 9px;\n    padding: 0px 8px;\n  }\n  &.spaced {\n    margin-right: 8px;\n    width: 100%;\n  }\n"])), theme.typography.normal.raleway);
export var TitleDiv = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n"])));
export var BottomInputs = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-self: flex-start;\n  max-width: 1000px;\n  width: 100%;\n  background-color: ", ";\n  border-radius: 8px;\n  &.summons {\n    box-sizing: border-box;\n    padding-left: 30px;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n  }\n  @media (max-width: ", "px) {\n    margin-left: 10px;\n    max-width: inherit;\n    padding: 0px;\n    padding: 5px 20px;\n    width: auto;\n    background-color: ", ";\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-self: flex-start;\n  max-width: 1000px;\n  width: 100%;\n  background-color: ", ";\n  border-radius: 8px;\n  &.summons {\n    box-sizing: border-box;\n    padding-left: 30px;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n  }\n  @media (max-width: ", "px) {\n    margin-left: 10px;\n    max-width: inherit;\n    padding: 0px;\n    padding: 5px 20px;\n    width: auto;\n    background-color: ", ";\n  }\n"])), theme.palette.admin.primary[45], M, theme.palette.admin.primary[50]);
export var ButtonsContainer = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-right: 30px;\n  justify-content: flex-end;\n  border-top: solid 30px ", ";\n  border-bottom: solid 30px ", ";\n  border-radius: 8px;\n  &.inside-form {\n    border: none;\n    margin-bottom: 30px;\n  }\n  @media (max-width: ", "px) {\n    padding-right: 20px;\n    background-color: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-right: 30px;\n  justify-content: flex-end;\n  border-top: solid 30px ", ";\n  border-bottom: solid 30px ", ";\n  border-radius: 8px;\n  &.inside-form {\n    border: none;\n    margin-bottom: 30px;\n  }\n  @media (max-width: ", "px) {\n    padding-right: 20px;\n    background-color: ", ";\n  }\n"])), theme.palette.primary[40], theme.palette.primary[40], M, theme.palette.admin.primary[50]);
export var DetailsContainer = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  min-height: 150px;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  border-radius: 8px;\n  margin-bottom: 14px;\n  background-color: ", ";\n  max-width: 340px;\n  width: 100%;\n  padding: 35px 35px 5px 35px;\n  &.margin {\n    margin-right: 15px;\n  }\n  @media (max-width: ", "px) {\n    margin-left: 10px;\n    max-width: inherit;\n    padding: 0px;\n    padding: 5px 20px;\n    width: auto;\n    background-color: ", ";\n  }\n"], ["\n  display: flex;\n  min-height: 150px;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  border-radius: 8px;\n  margin-bottom: 14px;\n  background-color: ", ";\n  max-width: 340px;\n  width: 100%;\n  padding: 35px 35px 5px 35px;\n  &.margin {\n    margin-right: 15px;\n  }\n  @media (max-width: ", "px) {\n    margin-left: 10px;\n    max-width: inherit;\n    padding: 0px;\n    padding: 5px 20px;\n    width: auto;\n    background-color: ", ";\n  }\n"])), theme.palette.admin.primary[45], M, theme.palette.admin.primary[50]);
export var Input = styled(InputBase)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 14px !important;\n  background-color: ", ";\n  height: 43px !important;\n  width: 100%;\n  border-width: 0px;\n  font-family: ", " !important;\n"], ["\n  color: ", ";\n  font-size: 14px !important;\n  background-color: ", ";\n  height: 43px !important;\n  width: 100%;\n  border-width: 0px;\n  font-family: ", " !important;\n"])), theme.palette.black[100], theme.palette.white[100], theme.typography.normal.raleway);
export var SelectCont = styled(Select)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  text-transform: capitalize;\n  z-index: 0;\n  min-height: 38px;\n  border-radius: 9px;\n  height: 100%;\n  width: 100%;\n  padding: 5px 8px;\n  .MuiSelect-select:focus {\n    border-radius: 9px;\n    background: none;\n  }\n  &.error {\n    border: 1px solid ", ";\n  }\n"], ["\n  text-transform: capitalize;\n  z-index: 0;\n  min-height: 38px;\n  border-radius: 9px;\n  height: 100%;\n  width: 100%;\n  padding: 5px 8px;\n  .MuiSelect-select:focus {\n    border-radius: 9px;\n    background: none;\n  }\n  &.error {\n    border: 1px solid ", ";\n  }\n"])), theme.palette.error[50]);
export var ImgRadio = styled.img(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  cursor: pointer;\n  margin-right: 15px;\n  margin-left: 5px;\n"], ["\n  cursor: pointer;\n  margin-right: 15px;\n  margin-left: 5px;\n"])));
export var PlusImg = styled.img(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  cursor: pointer;\n  padding: 15px;\n"], ["\n  cursor: pointer;\n  padding: 15px;\n"])));
export var CounterInput = styled.input(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  all: inherit;\n  background: ", ";\n  width: 100%;\n"], ["\n  all: inherit;\n  background: ", ";\n  width: 100%;\n"])), theme.palette.white[100]);
export var CandelarContainer = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  background-color: ", ";\n  text-transform: capitalize;\n  z-index: 0;\n  min-height: 38px;\n  border-radius: 9px;\n  padding: 5px 8px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  font-size: 14px;\n  font-family: ", ";\n"], ["\n  background-color: ", ";\n  text-transform: capitalize;\n  z-index: 0;\n  min-height: 38px;\n  border-radius: 9px;\n  padding: 5px 8px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  font-size: 14px;\n  font-family: ", ";\n"])), theme.palette.white[100], theme.typography.normal.raleway);
export var StyledTextField = styled(TextField)(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  input {\n    height: 43px;\n    box-shadow: none;\n    cursor:pointer;\n    border-radius: 9px;\n    font-size: 18px;\n    font-family: Raleway;\n    \n    background:", ";\n    padding: 10px 20px;\n    box-sizing: border-box;\n    font-size: 14px;\n    font-family:", "\n  }\n  .MuiInputBase-root{\n    &:before{\n    border-bottom: none;\n    }\n    &:after{\n      border-bottom: none;\n      }\n  }\n  .MuiInput-underline:hover:not(.Mui-disabled):before{\n      border-bottom: none;\n  }\n}\n"], ["\n  input {\n    height: 43px;\n    box-shadow: none;\n    cursor:pointer;\n    border-radius: 9px;\n    font-size: 18px;\n    font-family: Raleway;\n    \n    background:", ";\n    padding: 10px 20px;\n    box-sizing: border-box;\n    font-size: 14px;\n    font-family:", "\n  }\n  .MuiInputBase-root{\n    &:before{\n    border-bottom: none;\n    }\n    &:after{\n      border-bottom: none;\n      }\n  }\n  .MuiInput-underline:hover:not(.Mui-disabled):before{\n      border-bottom: none;\n  }\n}\n"])), theme.palette.white[100], theme.typography.normal.raleway);
export var InputDisable = styled(InputBase)(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  input {\n    height: 43px;\n    box-shadow: none;\n    cursor: pointer;\n    border-radius: 9px;\n    font-size: 18px;\n    font-family: Raleway;\n    color: ", ";\n    background: ", ";\n    padding: 10px 20px;\n    box-sizing: border-box;\n    font-size: 14px;\n    font-family: ", ";\n  }\n  font-size: 14px !important;\n  background-color: ", ";\n  height: 43px !important;\n  width: 100%;\n  border-width: 0px;\n  border-radius: 9px;\n  font-family: ", " !important;\n"], ["\n  input {\n    height: 43px;\n    box-shadow: none;\n    cursor: pointer;\n    border-radius: 9px;\n    font-size: 18px;\n    font-family: Raleway;\n    color: ", ";\n    background: ", ";\n    padding: 10px 20px;\n    box-sizing: border-box;\n    font-size: 14px;\n    font-family: ", ";\n  }\n  font-size: 14px !important;\n  background-color: ", ";\n  height: 43px !important;\n  width: 100%;\n  border-width: 0px;\n  border-radius: 9px;\n  font-family: ", " !important;\n"])), theme.palette.black[100], theme.palette.admin.primary[30], theme.typography.normal.raleway, theme.palette.admin.primary[30], theme.typography.normal.raleway);
export var Title = styled.div(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  font-size: 14px;\n  font-family: ", " !important;\n  width: 100%;\n  padding-bottom: 25px;\n"], ["\n  font-size: 14px;\n  font-family: ", " !important;\n  width: 100%;\n  padding-bottom: 25px;\n"])), theme.typography.bold.ralewayBold);
export var OrdersContainer = styled.div(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  display: flex;\n  height: fit-content;\n  min-height: 150px;\n  align-items: center;\n  justify-content: flex-start;\n  flex-direction: column;\n  border-radius: 8px;\n  margin-bottom: 14px;\n  margin-right: 20px;\n  background-color: ", ";\n  max-width: 440px;\n  padding: 35px;\n  margin-top: 60px;\n  &.margin {\n    margin-right: 15px;\n  }\n  @media (max-width: ", "px) {\n    margin-left: 10px;\n    max-width: inherit;\n    padding: 0px;\n    padding: 5px 20px;\n    width: auto;\n    background-color: ", ";\n  }\n  max-height: 500px;\n"], ["\n  display: flex;\n  height: fit-content;\n  min-height: 150px;\n  align-items: center;\n  justify-content: flex-start;\n  flex-direction: column;\n  border-radius: 8px;\n  margin-bottom: 14px;\n  margin-right: 20px;\n  background-color: ", ";\n  max-width: 440px;\n  padding: 35px;\n  margin-top: 60px;\n  &.margin {\n    margin-right: 15px;\n  }\n  @media (max-width: ", "px) {\n    margin-left: 10px;\n    max-width: inherit;\n    padding: 0px;\n    padding: 5px 20px;\n    width: auto;\n    background-color: ", ";\n  }\n  max-height: 500px;\n"])), theme.palette.admin.primary[35], M, theme.palette.admin.primary[50]);
export var DivOrders = styled.div(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  min-height: 150;\n  width: 100%;\n  &.scrollbar {\n    overflow-y: scroll;\n  }\n  &::-webkit-scrollbar {\n    width: 10px;\n    margin-left: 30px;\n  }\n\n  &::-webkit-scrollbar-track {\n    background-color: ", ";\n    border-radius: 10px;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: ", ";\n    border-radius: 10px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  min-height: 150;\n  width: 100%;\n  &.scrollbar {\n    overflow-y: scroll;\n  }\n  &::-webkit-scrollbar {\n    width: 10px;\n    margin-left: 30px;\n  }\n\n  &::-webkit-scrollbar-track {\n    background-color: ", ";\n    border-radius: 10px;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: ", ";\n    border-radius: 10px;\n  }\n"])), theme.palette.white[100], theme.palette.admin.primary[75]);
export var DivInputs = styled.div(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  min-height: 150;\n  max-height: 60vh;\n  width: 100%;\n  padding: 10px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  min-height: 150;\n  max-height: 60vh;\n  width: 100%;\n  padding: 10px;\n"])));
export var DivGradient = styled.div(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n  height: 120px;\n  background: ", ";\n  position: relative;\n  bottom: 166px;\n  width: 86%;\n  pointer-events: none;\n  @media (max-width: ", "px) {\n    margin-left: 10px;\n    max-width: inherit;\n    padding: 0px;\n    padding: 5px 20px;\n    width: auto;\n    opacity: 0;\n  }\n"], ["\n  height: 120px;\n  background: ", ";\n  position: relative;\n  bottom: 166px;\n  width: 86%;\n  pointer-events: none;\n  @media (max-width: ", "px) {\n    margin-left: 10px;\n    max-width: inherit;\n    padding: 0px;\n    padding: 5px 20px;\n    width: auto;\n    opacity: 0;\n  }\n"])), theme.palette.blueLinearGradient[50], M);
export var ErrorMessage = styled.label(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 12px;\n  margin-top: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  padding: 0px;\n  padding-right: 35px;\n  @media (max-width: ", "px) {\n    padding-right: 20px;\n  }\n"], ["\n  color: ", ";\n  font-size: 12px;\n  margin-top: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  padding: 0px;\n  padding-right: 35px;\n  @media (max-width: ", "px) {\n    padding-right: 20px;\n  }\n"])), theme.palette.error[50], M);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28;
