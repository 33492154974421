import React from 'react';
import { Modal as ModalComponent } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Container, Title, Center, RowContainer, Description } from './styles';
import { CANCEL_BOOKING_MESSAGE, NO, YES } from '../../../Utils';
import { AdminButton } from '../../../Components/Common/AdminButton';
import { Warning } from '../../../assets/Images';
var Modal = function (_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, handleCancel = _a.handleCancel, description = _a.description;
    return (React.createElement(ModalComponent, { open: isOpen },
        React.createElement(Center, null,
            React.createElement(Container, null,
                React.createElement(RowContainer, { className: "right close" },
                    React.createElement(Close, { onClick: function () { return setIsOpen(function (prev) { return !prev; }); } })),
                React.createElement(RowContainer, { className: "center" },
                    React.createElement("img", { src: Warning })),
                React.createElement(Title, null, CANCEL_BOOKING_MESSAGE),
                React.createElement(Description, null, description),
                React.createElement(RowContainer, { className: "center" },
                    React.createElement(AdminButton, { className: "margin no-background-delete", onClick: function () { return setIsOpen(false); } }, NO),
                    React.createElement(AdminButton, { className: "margin delete", onClick: function () {
                            handleCancel();
                            setIsOpen(false);
                        } }, YES))))));
};
export default Modal;
