var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { AdminLayout } from 'src/Layouts';
import { Container, Title } from '../styles';
import { ROUTES, EDIT_DETAILS, NAME, EMAIL, PHONE_KEY, NAME_KEY, EMAIL_KEY, BUILDING, TOWER, TOWER_KEY, APARTMENT, APARTMENT_KEY, RELATIONSHIP, RELATIONSHIP_KEY, OWNER_TEXT, TENANT, BOTH, BUILDING_KEY, LAST_NAME_KEY, LAST_NAME, PHONE, TENAT_KEY, BOTH_KEY, ADDRESS_KEY, ADDRESS, PROPRIETARY_KEY, SAVE, APARTMENT_TEXT, BACK_ERROR, } from 'src/Utils/constants';
import { getData } from 'src/Utils/api';
import { updateContactApartment, addContactApartment, deleteApartment, updateContact, } from './duck';
import { NewContactForm } from 'src/Components';
import { DeleteModal } from 'src/Components/Common';
var ModifyContact = function () {
    var history = useHistory();
    var _a = useState(''), backError = _a[0], setBackError = _a[1];
    var _b = useState(''), deleteError = _b[0], setDeleteError = _b[1];
    var id = useParams().id;
    var _c = useState(), buildingList = _c[0], setBuildingList = _c[1];
    var _d = useState(), personalData = _d[0], setPersonalData = _d[1];
    var _e = useState(), successUpdate = _e[0], setSuccessUpdate = _e[1];
    var _f = useState(), successDelete = _f[0], setSuccessDelete = _f[1];
    var _g = useState(), successAdd = _g[0], setSuccessAdd = _g[1];
    var _h = useState(false), isDeleteModalOpen = _h[0], setIsDeleteModalOpen = _h[1];
    var _j = useState(), selectedApartment = _j[0], setSelectedApartment = _j[1];
    var _k = useState([]), deleteApartments = _k[0], setDeleteApartments = _k[1];
    var data = useQuery("getContactDetail", function () { return getData("/contacts/" + id); }, {
        onSuccess: function (data) {
            setPersonalData([
                { key: NAME_KEY, label: NAME, value: data.contact.first_name },
                {
                    key: LAST_NAME_KEY,
                    label: LAST_NAME,
                    value: data.contact.last_name,
                },
                { key: EMAIL_KEY, label: EMAIL, value: data.contact.email },
                { key: PHONE_KEY, label: PHONE, value: data.contact.mobile },
                { key: ADDRESS_KEY, label: ADDRESS, value: data.contact.address },
            ]);
        },
    }).data;
    useQuery("getBuildingsList", function () { return getData("/buildings"); }, {
        onSuccess: function (data) {
            var buildings = data.buildings.map(function (_a) {
                var name = _a.name, id = _a.id;
                return ({
                    label: name,
                    value: id,
                });
            });
            buildings = buildings.sort(function (a, b) {
                return a.label.localeCompare(b.label, 'es');
            });
            setBuildingList(buildings);
        },
        refetchOnWindowFocus: false,
    });
    var mutateDeleteApartment = useMutation(deleteApartment, {
        onSuccess: function () {
            setDeleteError('');
        },
        onError: function (error) {
            var _a;
            setDeleteError(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.result.wrong_deleted[0]) || BACK_ERROR);
        },
    })[0];
    var deleteApartmentBuilding = function (idApartment) {
        idApartment && setDeleteApartments(__spreadArrays(deleteApartments, [idApartment]));
        setIsDeleteModalOpen(function (prev) { return !prev; });
        setSuccessDelete(true);
    };
    var buildingData = [
        {
            key: BUILDING_KEY,
            label: BUILDING,
            data: buildingList,
            value: {
                label: '',
                value: '',
            },
        },
        {
            key: TOWER_KEY,
            label: TOWER,
            data: [
                { label: 1, value: 1 },
                { label: 2, value: 2 },
            ],
            value: {
                label: '',
                value: '',
            },
        },
        {
            key: APARTMENT_KEY,
            label: APARTMENT,
            value: {
                label: '',
                value: '',
            },
        },
        {
            key: RELATIONSHIP_KEY,
            label: RELATIONSHIP,
            data: [
                { label: OWNER_TEXT, value: PROPRIETARY_KEY },
                { label: TENANT, value: TENAT_KEY },
                { label: BOTH, value: BOTH_KEY },
            ],
            value: {
                label: '',
                value: '',
            },
        },
    ];
    var relation = function (apartment) {
        switch (apartment) {
            case 'proprietary':
                return OWNER_TEXT;
            case 'tenant':
                return TENANT;
            case 'both':
                return BOTH;
        }
    };
    var createApartmentData = function (apartment) { return ({
        building: {
            label: apartment.building_name,
            value: apartment.building,
        },
        tower: {
            label: apartment.tower_name,
            value: apartment.tower,
        },
        apartment: {
            label: apartment.apartment_number,
            value: apartment.apartment,
            apartment: apartment.contact_apartment_id,
        },
        relation: {
            label: relation(apartment.relationship),
            value: apartment.relationship,
        },
    }); };
    var onCancel = function () { return history.push(ROUTES.contacts); };
    var mutateUpdateContactBuilding = useMutation(updateContactApartment, {
        onSuccess: function () { return setSuccessUpdate(true); },
        onError: function (error) {
            var _a;
            setBackError(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.result.wrong_edited[0].errors) || BACK_ERROR);
        },
    })[0];
    var mutateAddContactBuilding = useMutation(addContactApartment, {
        onSuccess: function () { return setSuccessAdd(true); },
        onError: function (error) {
            var _a;
            setBackError(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.result.wrong_added[0]) || BACK_ERROR);
        },
    })[0];
    useEffect(function () {
        if (successUpdate && successAdd) {
            history.push(ROUTES.contacts);
        }
        else {
            setSuccessUpdate(false);
            setSuccessAdd(false);
        }
    }, [successUpdate && successAdd]);
    var updateApartmentsSubmit = function (idContact, oldApartments, newApartments) {
        deleteApartments.forEach(function (idApartment) {
            mutateDeleteApartment({ idContact: id, idApartment: idApartment });
        });
        if (newApartments.length !== 0)
            mutateAddContactBuilding({
                id: idContact,
                apartments: newApartments,
            });
        else
            setSuccessAdd(true);
        if (oldApartments.length !== 0)
            mutateUpdateContactBuilding({
                idContact: idContact,
                apartments: oldApartments,
            });
        else
            setSuccessUpdate(true);
    };
    var mutateUpdateContact = useMutation(updateContact)[0];
    var handleClick = function (idContact, oldApartments, newApartments, contact_data) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            updateApartmentsSubmit(idContact, oldApartments, newApartments);
            data = __assign(__assign({}, contact_data), { first_name: contact_data.name, mobile: contact_data.phone });
            mutateUpdateContact({ id: idContact, data: data });
            return [2 /*return*/];
        });
    }); };
    var apartmentsInput = data &&
        data.contact.apartments.map(function (apartment) {
            return createApartmentData(apartment);
        });
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, { className: "non-margin" }, EDIT_DETAILS),
            data && buildingList && apartmentsInput && personalData && (React.createElement(NewContactForm, { id: id, inputs: personalData, buildingInputs: buildingData, onSubmit: handleClick, buttonLabel: SAVE, isLoading: false, backError: backError, onCancel: onCancel, isEdit: true, apartmentsInput: apartmentsInput, deleteApartmentBuilding: deleteApartmentBuilding, setSelectedApartment: setSelectedApartment, handleDelete: function () { return setIsDeleteModalOpen(function (prev) { return !prev; }); }, successDelete: successDelete, setSuccessDelete: setSuccessDelete })),
            React.createElement(DeleteModal, { isOpen: isDeleteModalOpen, setIsOpen: setIsDeleteModalOpen, onDelete: function () { return deleteApartmentBuilding(Number(selectedApartment)); }, itemLabel: APARTMENT_TEXT, error: deleteError, setError: setDeleteError }))));
};
export default ModifyContact;
