var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import { theme } from "src/Utils";
export var PaginatorContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n  margin: 32px 0;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n  margin: 32px 0;\n"])));
export var PageButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: transparent;\n  color: ", ";\n  width: 32px;\n  height: 32px;\n  border: ", " solid\n    ", ";\n  border-radius: 4px;\n  cursor: pointer;\n"], ["\n  background-color: transparent;\n  color: ",
    ";\n  width: 32px;\n  height: 32px;\n  border: ", " solid\n    ", ";\n  border-radius: 4px;\n  cursor: pointer;\n"])), function (props) {
    return props.isActive ? theme.palette.blue[5] : theme.palette.black[100];
}, function (props) { return (props.isActive ? '2px' : '1px'); }, function (props) { return props.isActive ? theme.palette.blue[5] : theme.palette.black[100]; });
export var ArrowContainer = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 32px;\n  height: 32px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: 1px solid transparent;\n  color: ", ";\n  background-color: ", ";\n  cursor: pointer;\n  &:disabled {\n    opacity: 0.5;\n  }\n"], ["\n  width: 32px;\n  height: 32px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: 1px solid transparent;\n  color: ", ";\n  background-color: ", ";\n  cursor: pointer;\n  &:disabled {\n    opacity: 0.5;\n  }\n"])), theme.palette.grey[50], theme.palette.white[100]);
var templateObject_1, templateObject_2, templateObject_3;
