import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { Row, Cell, DownloadContainer, Div, DotsImg, DeleteContainer, } from './styles';
import { CANCEL, DOWNLOAD, EDIT, VIEW, downloadPDF, theme } from 'src/Utils';
import { DownloadArrow, Dots } from '../../../../assets/Images';
import { PENDING, PAY_ONLINE, TYPE_CONTACT, MODIFY, DELETE } from 'src/Utils';
import { ROW_DOTS_DATA, bookingsRowsData } from 'src/Utils/mockData';
import { Popover } from '../../../Common/';
var TableRow = function (_a) {
    var _b = _a.data, first = _b.first, second = _b.second, third = _b.third, fourth = _b.fourth, fifth = _b.fifth, sixth = _b.sixth, ID = _b.ID, widths = _a.widths, className = _a.className, _c = _a.isCommonExpenses, isCommonExpenses = _c === void 0 ? false : _c, handleDelete = _a.handleDelete, handleDeleteRow = _a.handleDeleteRow, handleEdit = _a.handleEdit, handlePaymentModal = _a.handlePaymentModal, _d = _a.hasDownloadButton, hasDownloadButton = _d === void 0 ? true : _d, _e = _a.hasDeleteButton, hasDeleteButton = _e === void 0 ? false : _e, path = _a.path, pdfName = _a.pdfName, _f = _a.isPaymentHistory, isPaymentHistory = _f === void 0 ? false : _f, setSelectedId = _a.setSelectedId, hasDelete = _a.hasDelete, isAssembly = _a.isAssembly, _g = _a.isSettlement, isSettlement = _g === void 0 ? false : _g, isDocument = _a.isDocument, handleRowClick = _a.handleRowClick, _h = _a.isBookings, isBookings = _h === void 0 ? false : _h, _j = _a.hasDots, hasDots = _j === void 0 ? false : _j, handleView = _a.handleView;
    var _k = useState(null), anchorRefDots = _k[0], setAnchorRefDots = _k[1];
    var handleDotsClick = function (e) {
        var currentValue = e.currentTarget;
        if (anchorRefDots)
            setAnchorRefDots(null);
        setAnchorRefDots(currentValue);
    };
    var isSuperAdmin = Cookies.get('SuperAdmin');
    var renderCommonExpensesButton = function () {
        var isContact = Cookies.get('User') === TYPE_CONTACT;
        return (React.createElement(React.Fragment, null,
            React.createElement(DownloadContainer, { onClick: handlePaymentModal, className: "underline" }, PAY_ONLINE),
            !isContact && (React.createElement(DotsImg, { src: Dots, alt: "M\u00E1s", onClick: handleDotsClick }))));
    };
    var handleDotsOption = function (_a) {
        var label = _a.label;
        switch (label) {
            case MODIFY:
                handleEdit && handleEdit();
                break;
            case DELETE:
                handleDelete && handleDelete();
                break;
        }
    };
    var handleBookingsDotsOption = function (_a) {
        var label = _a.label;
        switch (label) {
            case EDIT:
                handleEdit && handleEdit(ID);
                break;
            case CANCEL:
                handleDelete && handleDelete(ID);
                break;
            case VIEW:
                handleView && handleView(ID);
                break;
        }
    };
    var handleTextColor = function (status) {
        var stateValues = {
            Activa: theme.palette.black[0],
            Completada: theme.palette.green,
            Cancelada: theme.palette.red,
        };
        return stateValues[status] || theme.palette.black[0];
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { hover: true, tabIndex: -1, key: first, onClick: function () { return handleRowClick && handleRowClick(ID); }, style: { cursor: handleRowClick ? 'pointer' : 'default' } },
            React.createElement(Cell, { className: className, style: { width: widths[0] } }, first),
            React.createElement(Cell, { className: className, style: { width: widths[1] } }, second),
            React.createElement(Cell, { className: className, style: { width: widths[2] } }, third),
            (widths[3] && isPaymentHistory) || isAssembly ? (React.createElement(Cell, { className: className + " " + (isBookings ? 'bold' : ''), style: {
                    width: widths[3],
                    textAlign: 'start',
                    color: isBookings && handleTextColor(fourth),
                } }, fourth)) : (React.createElement(Cell, { className: className, style: { width: widths[3] } },
                hasDownloadButton && (!isCommonExpenses || third !== PENDING) && (React.createElement(Div, null,
                    isSuperAdmin === 'true' && hasDelete && (React.createElement(DeleteContainer, { className: className, style: { width: widths[4] }, onClick: function () {
                            handleDeleteRow === null || handleDeleteRow === void 0 ? void 0 : handleDeleteRow(isSettlement || isDocument ? fourth : pdfName);
                        } }, DELETE)),
                    React.createElement(DownloadContainer, { onClick: function () {
                            if (path && pdfName) {
                                setSelectedId && setSelectedId(parseInt(fourth));
                                if (fifth.charAt(0) !== 'b')
                                    downloadPDF(path, pdfName);
                            }
                        } },
                        DOWNLOAD,
                        React.createElement("img", { src: DownloadArrow, alt: DOWNLOAD })))),
                (handleDelete || handlePaymentModal) &&
                    renderCommonExpensesButton())),
            widths[4] && isPaymentHistory ? (React.createElement(Cell, { className: className, style: { width: widths[4], textAlign: 'start' } }, fifth)) : (isAssembly && (React.createElement(Cell, { className: className, style: { width: widths[4] } }, React.createElement(Div, null,
                React.createElement(DownloadContainer, { onClick: function () {
                        if (path && pdfName) {
                            setSelectedId && setSelectedId(parseInt(fourth));
                            if (fifth.charAt(0) !== 'b')
                                downloadPDF(path, pdfName);
                        }
                    } },
                    DOWNLOAD,
                    React.createElement("img", { src: DownloadArrow, alt: DOWNLOAD })),
                isSuperAdmin === 'true' && (React.createElement(DeleteContainer, { className: className, style: { width: widths[4], margin: '0 6px' }, onClick: function () {
                        handleDeleteRow === null || handleDeleteRow === void 0 ? void 0 : handleDeleteRow(ID.toString());
                    } }, DELETE)))))),
            sixth && (React.createElement(Cell, { className: className, style: { width: widths[5], textAlign: 'start' } }, sixth)),
            hasDots && React.createElement(DotsImg, { src: Dots, alt: "M\u00E1s", onClick: handleDotsClick })),
        React.createElement(Popover, { data: isBookings ? bookingsRowsData(fourth) : ROW_DOTS_DATA, handleOutsideClick: function () { return setAnchorRefDots(null); }, anchorRef: anchorRefDots, setSelected: isBookings ? handleBookingsDotsOption : handleDotsOption, isFilter: false })));
};
export default TableRow;
