var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme } from 'src/Utils';
export var Center = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n"], ["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n"])));
export var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 446px;\n  background-color: ", ";\n  padding: 25px;\n  border-radius: 16px;\n  display: flex;\n  flex-direction: column;\n"], ["\n  width: 446px;\n  background-color: ", ";\n  padding: 25px;\n  border-radius: 16px;\n  display: flex;\n  flex-direction: column;\n"])), theme.palette.admin.primary[50]);
export var RowContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n\n  &.buttons {\n    justify-content: space-evenly;\n    margin: 0 10px 20px 10px;\n  }\n\n  &.close {\n    justify-content: flex-end;\n  }\n"], ["\n  display: flex;\n\n  &.buttons {\n    justify-content: space-evenly;\n    margin: 0 10px 20px 10px;\n  }\n\n  &.close {\n    justify-content: flex-end;\n  }\n"])));
export var Header = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  margin-bottom: 26px;\n  margin-top: 20px;\n  text-align: center;\n  font-size: 18px;\n  font-family: ", ";\n"], ["\n  width: 100%;\n  margin-bottom: 26px;\n  margin-top: 20px;\n  text-align: center;\n  font-size: 18px;\n  font-family: ", ";\n"])), theme.typography.bold.ralewayBold);
export var Button = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 188px;\n  padding: 10px;\n  border-radius: 7px;\n  border: none;\n  background-color: ", ";\n  font-size: 14px;\n  color: ", ";\n  cursor: pointer;\n"], ["\n  width: 188px;\n  padding: 10px;\n  border-radius: 7px;\n  border: none;\n  background-color: ", ";\n  font-size: 14px;\n  color: ", ";\n  cursor: pointer;\n"])), theme.palette.admin.primary[75], theme.palette.white[100]);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
