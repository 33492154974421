import React from 'react';
import { Container, Content, Image, Title, Description, ContentContainer, ContentDiv, ImageContainer, Div, } from './styles';
import { ContactIcon, Door, WhiteLocation, BlackDots, BlackBuilding, } from 'src/assets/Images';
import { CONTACTS_TEXT } from 'src/Utils';
import { Dots } from '..';
var Card = function (_a) {
    var first = _a.first, second = _a.second, third = _a.third, _b = _a.isApartment, isApartment = _b === void 0 ? false : _b, fourth = _a.fourth, handleDelete = _a.handleDelete, historyText = _a.historyText, hasDots = _a.hasDots;
    return (React.createElement(Container, null,
        React.createElement(Title, null, first),
        React.createElement(Content, null,
            React.createElement(ContentContainer, null,
                React.createElement(ContentDiv, null,
                    React.createElement(Image, { className: "description", src: isApartment ? ContactIcon : WhiteLocation }),
                    React.createElement(Description, null, second + " " + (isApartment ? CONTACTS_TEXT : ''))),
                !isApartment && (React.createElement(Div, null,
                    React.createElement(ContentDiv, { className: "second" },
                        React.createElement(Image, { className: "description", src: BlackBuilding }),
                        React.createElement(Description, null, fourth)),
                    React.createElement(ContentDiv, { className: "second" },
                        React.createElement(Image, { className: "description", src: Door }),
                        React.createElement(Description, null, third))))),
            hasDots && (React.createElement(ImageContainer, { className: "dots" }, isApartment ? (React.createElement(Image, { src: BlackDots, className: "dots", onClick: handleDelete })) : (React.createElement(Dots, { handleDelete: handleDelete, historyText: historyText })))))));
};
export default Card;
