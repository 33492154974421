import React from 'react';
import { M } from 'src/Utils';
import { useWindowWidth } from 'src/useWindowWidth';
import { BoxContainer, Title } from '../styles';
import { Select } from './Select';
export var SelectFilter = function (_a) {
    var filter = _a.filter;
    var windowWidth = useWindowWidth();
    var isMobile = windowWidth < M;
    var filters = filter.filters;
    if (isMobile)
        filters[0].title = '';
    return (React.createElement(BoxContainer, null,
        !isMobile && React.createElement(Title, null, filter.title),
        filters.map(function (_a) {
            var property = _a.property, title = _a.title, placeholder = _a.placeholder, options = _a.options, img = _a.img, _b = _a.isMulti, isMulti = _b === void 0 ? false : _b, _c = _a.hasStaticOptions, hasStaticOptions = _c === void 0 ? false : _c;
            return (React.createElement(Select, { title: title, placeholder: placeholder, hasStaticOptions: hasStaticOptions, options: options, isMulti: isMulti, img: img, property: property, key: property }));
        })));
};
