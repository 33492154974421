var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M, L } from 'src/Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  width: 80%;\n  @media (max-width: ", "px) {\n    width: 100%;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  width: 80%;\n  @media (max-width: ", "px) {\n    width: 100%;\n  }\n"])), L);
export var StyledTitle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  text-align: left;\n  font-size: 36px;\n  font-style: normal;\n  font-weight: 700;\n  white-space: normal;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  max-width: 900px;\n  @media (max-width: ", "px) {\n    font-size: 16px;\n    text-align: start;\n  }\n"], ["\n  color: ", ";\n  font-family: ", ";\n  text-align: left;\n  font-size: 36px;\n  font-style: normal;\n  font-weight: 700;\n  white-space: normal;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  max-width: 900px;\n  @media (max-width: ", "px) {\n    font-size: 16px;\n    text-align: start;\n  }\n"])), theme.palette.black[60], theme.typography.bold.ralewayBold, M);
export var Location = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  text-align: center;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 400;\n  @media (max-width: ", "px) {\n    font-size: 12px;\n    margin-bottom: 16px;\n  }\n"], ["\n  color: ", ";\n  font-family: ", ";\n  text-align: center;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 400;\n  @media (max-width: ", "px) {\n    font-size: 12px;\n    margin-bottom: 16px;\n  }\n"])), theme.palette.grey[50], theme.typography.normal.raleway, M);
export var Image = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  @media (max-width: ", "px) {\n    width: 14px;\n    height: 14px;\n  }\n"], ["\n  @media (max-width: ", "px) {\n    width: 14px;\n    height: 14px;\n  }\n"])), M);
export var Cod = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 500;\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 500;\n"])), theme.palette.grey[50], theme.typography.normal.raleway);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
