import React from 'react';
import { WhiteLine, Container, ItemsContainer } from './styles';
import ContactItem from './ContactItem';
var Contact = function (_a) {
    var items = _a.items, isContactPage = _a.isContactPage;
    return (React.createElement(Container, { className: isContactPage ? '' : 'no-page' },
        React.createElement(ItemsContainer, null, items.map(function (contactItem, index) { return (React.createElement(React.Fragment, null,
            React.createElement(ContactItem, { key: contactItem.title, isContactPage: isContactPage, details: contactItem }),
            index < 3 && React.createElement(WhiteLine, null))); }))));
};
export default Contact;
