var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { M, theme } from 'src/Utils';
import { TableContainer } from '../styles';
export var DetailsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-self: flex-start;\n  min-height: 455px;\n  width: 600px;\n  padding: 25px 35px;\n  flex-direction: column;\n  border-radius: 8px;\n  margin-bottom: 14px;\n  background-color: ", ";\n  font-family: ", ";\n  font-size: 18px !important;\n  @media (max-width: ", "px) {\n    margin-left: 10px;\n    max-width: inherit;\n    padding: 0px;\n    padding: 5px 20px;\n    width: auto;\n    background-color: ", ";\n  }\n"], ["\n  display: flex;\n  align-self: flex-start;\n  min-height: 455px;\n  width: 600px;\n  padding: 25px 35px;\n  flex-direction: column;\n  border-radius: 8px;\n  margin-bottom: 14px;\n  background-color: ", ";\n  font-family: ", ";\n  font-size: 18px !important;\n  @media (max-width: ", "px) {\n    margin-left: 10px;\n    max-width: inherit;\n    padding: 0px;\n    padding: 5px 20px;\n    width: auto;\n    background-color: ", ";\n  }\n"])), theme.palette.white[100], theme.typography.bold.ralewayBold, M, theme.palette.admin.primary[50]);
export var ContactsTableContainer = styled(TableContainer)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  .MuiTablePagination-selectRoot {\n    display: none;\n  }\n  .MuiTablePagination-toolbar {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px 0px !important;\n    justify-content: center;\n\n    .MuiTablePagination-spacer {\n      display: none;\n    }\n\n    .MuiTablePagination-caption {\n      display: none;\n    }\n  }\n"], ["\n  .MuiTablePagination-selectRoot {\n    display: none;\n  }\n  .MuiTablePagination-toolbar {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px 0px !important;\n    justify-content: center;\n\n    .MuiTablePagination-spacer {\n      display: none;\n    }\n\n    .MuiTablePagination-caption {\n      display: none;\n    }\n  }\n"])));
export var FooterContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n"])));
export var TablePaginationContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-grow: 2;\n  justify-content: center;\n"], ["\n  display: flex;\n  flex-grow: 2;\n  justify-content: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
