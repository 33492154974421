import api from 'src/Utils/api';
export var submitDataForm = function (_a) {
    var email = _a.email, first_name = _a.first_name, last_name = _a.last_name, mobile = _a.mobile, apartments = _a.apartments, address = _a.address;
    return api.post('/users', {
        contact: {
            email: email,
            first_name: first_name,
            last_name: last_name,
            mobile: mobile,
            apartments: apartments,
            address: address,
        },
    });
};
export var deleteContact = function (_a) {
    var id = _a.id;
    return api.delete("/contacts/" + id);
};
export var addContactApartment = function (_a) {
    var id = _a.id, apartments = _a.apartments;
    return api.put("/contacts/" + id + "/apartment_contacts/add", {
        apartments: apartments,
    });
};
export var updateContactApartment = function (_a) {
    var idContact = _a.idContact, apartments = _a.apartments;
    return api.put("/contacts/" + idContact + "/apartment_contacts/edit", {
        apartments: apartments,
    });
};
export var deleteApartment = function (_a) {
    var idContact = _a.idContact, idApartment = _a.idApartment;
    return api.put("/contacts/" + idContact + "/apartment_contacts/delete", {
        apartments: [{ id: Number(idApartment) }],
    });
};
export var updatePassword = function (_a) {
    var password = _a.password, password_confirmation = _a.password_confirmation, idContact = _a.idContact;
    return api.put("/contacts/" + idContact, {
        password: password,
        password_confirmation: password_confirmation,
    });
};
export var updateEmail = function (_a) {
    var idContact = _a.idContact, email = _a.email;
    return api.patch("/contacts/" + idContact + "/update_email", { email: email });
};
export var updateContact = function (_a) {
    var id = _a.id, data = _a.data;
    return api.put("/contacts/" + id, data);
};
