var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, XS } from '../../../Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 90%;\n  padding: 0 20px;\n  border-radius: 7px;\n  align-items: center;\n  flex-direction: column;\n  background-color: ", ";\n  min-height: 50px;\n  max-width: 325px;\n  margin: 0px 1%;\n  margin-bottom: 20px;\n  @media (max-width: ", "px) {\n    width: 80%;\n  }\n"], ["\n  display: flex;\n  width: 90%;\n  padding: 0 20px;\n  border-radius: 7px;\n  align-items: center;\n  flex-direction: column;\n  background-color: ", ";\n  min-height: 50px;\n  max-width: 325px;\n  margin: 0px 1%;\n  margin-bottom: 20px;\n  @media (max-width: ", "px) {\n    width: 80%;\n  }\n"])), theme.palette.white[100], XS);
export var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: 16px;\n  margin-bottom: 20px;\n  width: 100%;\n  padding: 10px 20px 10px 20px;\n  border-start-end-radius: 7px;\n  border-start-start-radius: 7px;\n  background-color: #F7FAFF;\n"], ["\n  font-family: ", ";\n  font-size: 16px;\n  margin-bottom: 20px;\n  width: 100%;\n  padding: 10px 20px 10px 20px;\n  border-start-end-radius: 7px;\n  border-start-start-radius: 7px;\n  background-color: #F7FAFF;\n"])), theme.typography.bold.ralewayBold);
export var Content = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 100%;\n  &.none {\n    display: none;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 100%;\n  &.none {\n    display: none;\n  }\n"])));
export var ContentContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 20px;\n  width: 100%;\n  height: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 20px;\n  width: 100%;\n  height: 100%;\n"])));
export var ContentDiv = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  height: 100%;\n"], ["\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  height: 100%;\n"])));
export var Description = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 50%;\n  text-align: initial;\n  font-size: 14px;\n  &.bold {\n    font-family: ", ";\n  }\n"], ["\n  width: 50%;\n  text-align: initial;\n  font-size: 14px;\n  &.bold {\n    font-family: ", ";\n  }\n"])), theme.typography.bold.ralewayBold);
export var ButtonContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  height: 100%;\n  padding: 12px 0;\n  margin-top: 20px;\n  border-top: 1.5px solid ", ";\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  height: 100%;\n  padding: 12px 0;\n  margin-top: 20px;\n  border-top: 1.5px solid ", ";\n"])), theme.palette.grey[20]);
export var DeleteButton = styled.label(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-family: ", ";\n  font-weight: bold;\n  font-size: 13px;\n  color: ", ";\n  cursor: pointer;\n"], ["\n  font-family: ", ";\n  font-weight: bold;\n  font-size: 13px;\n  color: ", ";\n  cursor: pointer;\n"])), theme.typography.bold.ralewayBold, theme.palette.error[50]);
export var Button = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  gap: 7px;\n  justify-content: center;\n  align-items: center;\n  border: 1.5px solid ", ";\n  box-sizing: border-box;\n  border-radius: 9px;\n  width: 98px;\n  height: 29px;\n  cursor: pointer;\n"], ["\n  display: flex;\n  gap: 7px;\n  justify-content: center;\n  align-items: center;\n  border: 1.5px solid ", ";\n  box-sizing: border-box;\n  border-radius: 9px;\n  width: 98px;\n  height: 29px;\n  cursor: pointer;\n"])), theme.palette.admin.primary[75]);
export var Label = styled.label(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: 10px;\n  color: ", ";\n  cursor: pointer;\n"], ["\n  font-size: 10px;\n  color: ", ";\n  cursor: pointer;\n"])), theme.palette.admin.primary[75]);
export var Image = styled.img(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 9px;\n  height: 9px;\n"], ["\n  width: 9px;\n  height: 9px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
