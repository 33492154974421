var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { DownloadContainer } from '../../Common/Table/TableRow/styles';
import { DeleteModal } from 'src/Components/Common';
import { CLEAR_DATA, CLEAR_DATA_MODAL } from 'src/Utils/constants';
import { useCleanBuildingData } from 'src/Utils/useCleanBuildingData';
import styled from '@emotion/styled';
var DownloadButton = styled(DownloadContainer)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  justify-content: flex-start\n"], ["\n  justify-content: flex-start\n"])));
var BuildingCleaner = function (_a) {
    var buildingId = _a.buildingId;
    var _b = useState(false), isDeleteModalOpen = _b[0], setIsDeleteModalOpen = _b[1];
    var mutateClearData = useMutation(useCleanBuildingData)[0];
    var handleDelete = function () {
        mutateClearData(buildingId);
        setIsDeleteModalOpen(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(DownloadButton, { onClick: function () { return setIsDeleteModalOpen(true); } }, CLEAR_DATA),
        React.createElement(DeleteModal, { isOpen: isDeleteModalOpen, setIsOpen: setIsDeleteModalOpen, onDelete: handleDelete, itemLabel: CLEAR_DATA_MODAL })));
};
export default BuildingCleaner;
var templateObject_1;
