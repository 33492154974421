import React, { useState, useEffect } from 'react';
import { Container, Title } from './styles';
import { Form } from '../Common';
import { useMutation } from 'react-query';
import { setCookies } from 'src/Utils/utils';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { ROUTES, INVALID_CREDENTIALS } from 'src/Utils/constants';
import { singIn } from './duck';
var Login = function (_a) {
    var inputs = _a.inputs, title = _a.title, buttonText = _a.buttonText;
    var _b = useState(''), backError = _b[0], setBackError = _b[1];
    var history = useHistory();
    var _c = useMutation(singIn, {
        onSuccess: function (response) {
            var _a = response.data, contact = _a.contact, type = _a.type;
            var _b = response.headers, client = _b.client, accessToken = _b["access-token"];
            window.localStorage.setItem('hasSeenModal', 'false');
            setCookies(accessToken, client, contact.uid, contact.id, type, contact.superadmin ? 'true' : 'false');
        },
        onError: function () {
            setBackError(INVALID_CREDENTIALS);
        },
    }), mutateLogin = _c[0], _d = _c[1], isLoading = _d.isLoading, isSuccess = _d.isSuccess;
    useEffect(function () {
        if (isSuccess && Cookies.get('Access-Token'))
            history.push(ROUTES.dashboard);
    }, [isSuccess]);
    var submitForm = function (email, password) {
        return mutateLogin({ email: email, password: password });
    };
    return (React.createElement(Container, null,
        React.createElement(Title, null, title),
        React.createElement(Form, { inputs: inputs, buttonLabel: buttonText, isLoginForm: true, backError: backError, onSubmit: submitForm, isLoading: isLoading })));
};
export default Login;
