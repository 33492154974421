var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ContactSection, Footer } from '../../Views';
import { Header } from 'src/Layouts';
import { TopInfo } from './TopInfo';
import { BottomInfo } from './BottomInfo';
import { getData } from 'src/Utils';
import { Whatsapp } from '../Whatsapp';
import { TopInfoLoadingSkeleton } from './TopInfo/TopInfoLoadingSkeleton';
import { BottomInfoLoadingSkeleton } from './BottomInfo/BottomInfoLoadingSkeleton';
import { Wrapper, InnerWrapper } from './styles';
export var RealEstateDetail = function () {
    var _a = useState(), property = _a[0], setProperty = _a[1];
    var id = useParams().property_id;
    var _b = useQuery({
        queryKey: ['property', id],
        queryFn: function () { return getData("real_estate/properties/" + id); },
    }), data = _b.data, isLoading = _b.isLoading;
    useEffect(function () {
        if (data)
            setProperty(__assign(__assign({}, data), { media_urls: data.media_urls.map(function (image) { return ({
                    original: image,
                    thumbnail: image,
                }); }) }));
    }, [data]);
    useEffect(function () {
        window.scrollTo(0, 0);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, null),
        React.createElement(Wrapper, null,
            React.createElement(InnerWrapper, null, !isLoading && !!property ? (React.createElement(React.Fragment, null,
                React.createElement(TopInfo, { info: property }),
                React.createElement(BottomInfo, { info: property }))) : (React.createElement(React.Fragment, null,
                React.createElement(TopInfoLoadingSkeleton, null),
                React.createElement(BottomInfoLoadingSkeleton, null))))),
        React.createElement(ContactSection, { className: "padding-top", isContactPage: false }),
        !!property && !isLoading && React.createElement(Whatsapp, { title: property.name }),
        React.createElement(Footer, null)));
};
