import api from 'src/Utils/api';
export var deleteAdmin = function (_a) {
    var id = _a.id;
    return api.delete("/admin_users/" + id);
};
export var createAdmin = function (admin) {
    return api.post('/users', {
        admin_user: admin,
    });
};
