import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { usePDF } from '@react-pdf/renderer';
import { downloadPDF } from 'src/Utils';
import { Download } from 'src/assets/Images';
import { DOWNLOAD } from 'src/Utils/constants';
import SettlementPDF from 'src/Components/SettlementPDF/SettlementPDF';
import { addPDF } from 'src/Views/DocumentsPages/SettlementPage/services';
import { DownloadButton, Description, Image } from '../styles';
var SettlementDocument = function (pdfData) { return (React.createElement(SettlementPDF, { building: pdfData === null || pdfData === void 0 ? void 0 : pdfData.building, end_date: pdfData === null || pdfData === void 0 ? void 0 : pdfData.end_date, start_date: pdfData === null || pdfData === void 0 ? void 0 : pdfData.start_date, information: pdfData === null || pdfData === void 0 ? void 0 : pdfData.information })); };
var DownloadContainer = function (pdfData) {
    var handleDownload = function () {
        if (pdfData.pdf && pdfData.pdfName) {
            downloadPDF(pdfData.pdf, pdfData.pdfName);
        }
        else {
            setNeedsCreation(true);
        }
    };
    var mutatePDF = useMutation(addPDF)[0];
    var _a = useState(false), needsCreation = _a[0], setNeedsCreation = _a[1];
    var document = needsCreation && pdfData ? SettlementDocument(pdfData) : React.createElement(React.Fragment, null);
    var _b = usePDF({ document: document }), instance = _b[0], updateInstance = _b[1];
    useEffect(function () {
        if (needsCreation && pdfData) {
            updateInstance();
        }
    }, [pdfData, needsCreation]);
    useEffect(function () {
        if (needsCreation && instance.url && (pdfData === null || pdfData === void 0 ? void 0 : pdfData.id) && (pdfData === null || pdfData === void 0 ? void 0 : pdfData.pdfName)) {
            var file = new File([instance.blob], pdfData.pdfName + ".pdf", {
                type: 'application/pdf',
            });
            mutatePDF({ file: file, id: pdfData.id });
            downloadPDF(instance.url, pdfData === null || pdfData === void 0 ? void 0 : pdfData.pdfName);
            setNeedsCreation(false);
        }
    }, [instance.loading]);
    return (React.createElement(DownloadButton, { onClick: handleDownload },
        React.createElement(Description, { className: "button-title" }, DOWNLOAD),
        React.createElement(Image, { className: "pointer", src: Download })));
};
export default DownloadContainer;
