import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage, Text } from "./styles";
var errorMsg = 'No se puede reservar en este horario. Este horario bloquea el espacio por el dia, y ya existen reservas para este día.';
var infoMsg = 'Este horario bloquea el espacio por el día';
var BlocksAmenityMessage = function (_a) {
    var bookedTimes = _a.bookedTimes;
    var _b = useFormContext(), watch = _b.watch, setError = _b.setError, clearErrors = _b.clearErrors, errors = _b.formState.errors;
    var time = watch('time');
    var bookedTimesArr = bookedTimes || time.occupiedTimes;
    useEffect(function () {
        if (time.label && bookedTimesArr.length > 0 && time.blocksAmenity) {
            setError('time', {
                type: 'manual',
                message: 'El horario seleccionado no está disponible'
            });
        }
        else
            clearErrors('time');
    }, [time.value]);
    if (!time.blocksAmenity)
        return React.createElement(React.Fragment, null);
    if (errors.time)
        return React.createElement(ErrorMessage, null, errorMsg);
    else
        return React.createElement(Text, null, infoMsg);
};
export default BlocksAmenityMessage;
