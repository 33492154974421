import React from 'react';
import { handleCurrency, handleOperationType, handlePropertyType, } from '../utils';
import { Price, Text, Column } from '../styles';
export var TopForm = function (_a) {
    var price = _a.price, currency = _a.currency, propertyType = _a.propertyType, size = _a.size, operationType = _a.operationType;
    return (React.createElement(Column, { gap: "4px" },
        React.createElement(Text, null,
            "Precio de ",
            handleOperationType(operationType),
            " de la propiedad"),
        React.createElement(Column, { gap: "12px" },
            React.createElement(Price, null,
                handleCurrency(currency),
                " ",
                price.toLocaleString().replace(',', '.')),
            React.createElement(Text, { className: "extra" },
                handlePropertyType(propertyType),
                " | ",
                size,
                "m\u00B2"))));
};
