var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { XL, theme } from 'src/Utils';
export var SearchWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: 1050px;\n  max-width: 85%;\n  margin: 0 auto 100px;\n  display: flex;\n  z-index: 40;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n"], ["\n  max-width: 1050px;\n  max-width: 85%;\n  margin: 0 auto 100px;\n  display: flex;\n  z-index: 40;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n"])));
export var SearchContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 29px 24px;\n  box-shadow: 0px 0px 16px 0px rgba(148, 148, 148, 0.25);\n  background-color: white !important;\n  border-radius: 0 12px 12px 12px;\n  position: relative;\n"], ["\n  padding: 29px 24px;\n  box-shadow: 0px 0px 16px 0px rgba(148, 148, 148, 0.25);\n  background-color: white !important;\n  border-radius: 0 12px 12px 12px;\n  position: relative;\n"])));
export var ContentContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between !important;\n  align-content: center !important;\n  width: 100%;\n  height: 100%;\n  gap: 24px !important;\n"], ["\n  display: flex;\n  justify-content: space-between !important;\n  align-content: center !important;\n  width: 100%;\n  height: 100%;\n  gap: 24px !important;\n"])));
export var ButtonGroup = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 200px;\n  border-radius: 12px 12px 0 0;\n  height: 46px;\n  flex-shrink: 0;\n  transform: translateY(-100%);\n  background-color: white;\n  box-sizing: border-box;\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 200px;\n  border-radius: 12px 12px 0 0;\n  height: 46px;\n  flex-shrink: 0;\n  transform: translateY(-100%);\n  background-color: white;\n  box-sizing: border-box;\n"])));
export var SearchFields = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  gap: 24px;\n  justify-content: center;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  gap: 24px;\n  justify-content: center;\n  width: 100%;\n"])));
export var OptionButton = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 10px 15px;\n  border: none;\n  cursor: pointer;\n  background-color: transparent;\n  width: 50%;\n  height: 100%;\n  &.selected {\n    font-family: ", ";\n    border-bottom: 2px solid ", ";\n    color: ", ";\n  }\n"], ["\n  padding: 10px 15px;\n  border: none;\n  cursor: pointer;\n  background-color: transparent;\n  width: 50%;\n  height: 100%;\n  &.selected {\n    font-family: ", ";\n    border-bottom: 2px solid ", ";\n    color: ", ";\n  }\n"])), theme.typography.bold.ralewayBold, theme.palette.primary[25], theme.palette.primary[25]);
export var DivContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject([""], [""])));
export var Input = styled.input(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding: 10px;\n  border: 1px solid #ccc;\n  margin: 0 5px;\n"], ["\n  padding: 10px;\n  border: 1px solid #ccc;\n  margin: 0 5px;\n"])));
export var Select = styled.select(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  padding: 10px;\n  border: 1px solid #ccc;\n  margin: 0 5px;\n"], ["\n  padding: 10px;\n  border: 1px solid #ccc;\n  margin: 0 5px;\n"])));
export var SearchButton = styled.button(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  padding: 10px 20px;\n  width: 100%;\n  border: none;\n  background-color: #0056b3;\n  color: white;\n  cursor: pointer;\n  &:hover {\n    background-color: #004494;\n  }\n"], ["\n  padding: 10px 20px;\n  width: 100%;\n  border: none;\n  background-color: #0056b3;\n  color: white;\n  cursor: pointer;\n  &:hover {\n    background-color: #004494;\n  }\n"])));
export var InputContainer = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  width: 220px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  width: 220px;\n"])));
export var Label = styled.p(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  font-size: 14px;\n  color: #6d6d6d;\n  margin: 0;\n  padding: 0;\n"], ["\n  font-size: 14px;\n  color: #6d6d6d;\n  margin: 0;\n  padding: 0;\n"])));
export var ButtonContainer = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  width: 170px;\n  display: flex;\n  align-items: end;\n  margin-left: 44px;\n  @media (max-width: ", "px) {\n    margin-left: 0;\n  }\n"], ["\n  width: 170px;\n  display: flex;\n  align-items: end;\n  margin-left: 44px;\n  @media (max-width: ", "px) {\n    margin-left: 0;\n  }\n"])), XL);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;
