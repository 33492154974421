var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@react-pdf/styled-components';
import { theme } from 'src/Utils';
// TopInfo
export var TitleContainer = styled.View(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n"])));
export var TopInfoTitle = styled.Text(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  text-align: center;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 700;\n"], ["\n  color: ", ";\n  font-family: ", ";\n  text-align: center;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 700;\n"])), theme.palette.black[60], theme.typography.normal.raleway);
export var TopInfoLocation = styled.Text(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  text-align: center;\n  font-family: ", ";\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n"], ["\n  color: ", ";\n  text-align: center;\n  font-family: ", ";\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n"])), theme.palette.grey[50], theme.typography.normal.raleway);
// Images
export var ImagesContainer = styled.View(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  padding: 3px;\n  height: 176px;\n  margin-top: 21px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  padding: 3px;\n  height: 176px;\n  margin-top: 21px;\n"])));
export var PrincipalImage = styled.Image(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: ", ";\n  height: 100%;\n  width: 50%;\n"], ["\n  background-color: ", ";\n  height: 100%;\n  width: 50%;\n"])), theme.palette.grey[50]);
export var InnerContainer = styled.View(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 50%;\n"], ["\n  width: 50%;\n"])));
export var SecondaryImagesRow = styled.View(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  height: 50%;\n"], ["\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  height: 50%;\n"])));
export var SecondaryImage = styled.Image(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background-color: ", ";\n  width: 50%;\n  height: 100%;\n"], ["\n  background-color: ", ";\n  width: 50%;\n  height: 100%;\n"])), theme.palette.grey[50]);
export var ImageSkeleton = styled.View(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 16px;\n  height: 100%;\n  width: 100%;\n"], ["\n  background-color: ", ";\n  border-radius: 16px;\n  height: 100%;\n  width: 100%;\n"])), theme.palette.grey[50]);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
