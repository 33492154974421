import { ADMINISTRATOR, CANCEL, EDIT, SUPERADMIN, VIEW } from './constants';
export var BUILDING_DATA = [
    { label: 'Altilus', value: 1 },
    { label: 'Copabala', value: 2 },
];
export var ASSEMBLY_TYPE_DATA = [
    { label: 'Ordinaria', value: 'Ordinaria' },
    { label: 'Extraordinaria', value: 'Extraordinaria' },
    { label: 'Urgente', value: 'Urgente' },
    {
        label: 'Levantamiento de 1/4 intermedio',
        value: 'Levantamiento de 1/4 intermedio',
    },
];
export var MODALITY_DATA = [
    { label: 'Presencial', value: 'In Person' },
    { label: 'Virtual', value: 'Online' },
    { label: 'Presencial y virtual', value: 'Hybrid' },
];
export var PLACE_DATA = [
    { label: 'Hall del edificio', value: 'Hall del edificio' },
    { label: 'Garage', value: 'Garage' },
    { label: 'Salón Comunal', value: 'Salón Comunal' },
    { label: 'Barbacoa', value: 'Barbacoa' },
    { label: 'Salón de Usos múltiples', value: 'Salón de Usos múltiples' },
    { label: 'Unidad', value: 'Unidad' },
    { label: 'Otro', value: 'Otro' },
];
export var TIME_DATA = [
    { label: '00:00', value: '00:00' },
    { label: '00:30', value: '00:30' },
    { label: '01:00', value: '01:00' },
    { label: '01:30', value: '01:30' },
    { label: '02:00', value: '02:00' },
    { label: '02:30', value: '02:30' },
    { label: '03:00', value: '03:00' },
    { label: '03:30', value: '03:30' },
    { label: '04:00', value: '04:00' },
    { label: '04:30', value: '04:30' },
    { label: '05:00', value: '05:00' },
    { label: '05:30', value: '05:30' },
    { label: '06:00', value: '06:00' },
    { label: '06:30', value: '06:30' },
    { label: '07:00', value: '07:00' },
    { label: '07:30', value: '07:30' },
    { label: '08:00', value: '08:00' },
    { label: '08:30', value: '08:30' },
    { label: '09:00', value: '09:00' },
    { label: '09:30', value: '09:30' },
    { label: '10:00', value: '10:00' },
    { label: '10:30', value: '10:30' },
    { label: '11:00', value: '11:00' },
    { label: '11:30', value: '11:30' },
    { label: '12:00', value: '12:00' },
    { label: '12:30', value: '12:30' },
    { label: '13:00', value: '13:00' },
    { label: '13:30', value: '13:30' },
    { label: '14:00', value: '14:00' },
    { label: '14:30', value: '14:30' },
    { label: '15:00', value: '15:00' },
    { label: '15:30', value: '15:30' },
    { label: '16:00', value: '16:00' },
    { label: '16:30', value: '16:30' },
    { label: '17:00', value: '17:00' },
    { label: '17:30', value: '17:30' },
    { label: '18:00', value: '18:00' },
    { label: '18:30', value: '18:30' },
    { label: '19:00', value: '19:00' },
    { label: '19:30', value: '19:30' },
    { label: '20:00', value: '20:00' },
    { label: '20:30', value: '20:30' },
    { label: '21:00', value: '21:00' },
    { label: '21:30', value: '21:30' },
    { label: '22:00', value: '22:00' },
    { label: '22:30', value: '22:30' },
    { label: '23:00', value: '23:00' },
    { label: '23:30', value: '23:30' },
];
export var CURRENCY_DATA = [
    { label: 'UYU', value: 'UYU' },
    { label: 'USD', value: 'USD' },
];
export var ROW_DOTS_DATA = [
    { label: 'Ver', value: 'Ver' },
    { label: 'Modificar', value: 'Modificar' },
    { label: 'Eliminar', value: 'Eliminar' },
];
export var bookingsRowsData = function (status) {
    return status === 'Activa'
        ? [
            { label: VIEW, value: VIEW },
            { label: EDIT, value: EDIT },
            { label: CANCEL, value: CANCEL },
        ]
        : [{ label: VIEW, value: VIEW }];
};
export var ADMIN_TYPE_DATA = [
    { label: ADMINISTRATOR, value: 'false' },
    { label: SUPERADMIN, value: 'true' },
];
export var API_DATA = [
    { label: 'Ambos', value: 'A' },
    { label: 'Propietario', value: 'P' },
    { label: 'Inquilino', value: 'I' },
];
