import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { AdminLayout } from 'src/Layouts';
import { AdminForm } from 'src/Components/Common';
import { BACK_ERROR, CONFIRM_NEW_PASSWORD_TEXT, EMAIL_KEY, ENTER_NEW_EMAIL, ENTER_NEW_PASSWORD, MODIFY_EMAIL, MODIFY_PASSWORD, PASSWORD_CONFIRMATION_KEY, PASSWORD_KEY2, ROUTES, SAVE, } from 'src/Utils';
import { Container, Title } from '../styles';
import { DetailsContainer } from './styles';
import { updatePassword, updateEmail } from './duck';
import { isValidForm } from 'src/Utils/validations';
var isSuperAdmin = Cookies.get('SuperAdmin') === 'true';
var EditPassword = function (_a) {
    var _b = _a.isEmail, isEmail = _b === void 0 ? false : _b;
    var history = useHistory();
    var id = useParams().id;
    var _c = useState(''), backError = _c[0], setBackError = _c[1];
    var _d = useState({
        email: '',
        password: '',
    }), error = _d[0], setError = _d[1];
    var _e = useState(false), isSuccessEmail = _e[0], setIsSuccessEmail = _e[1];
    var _f = useState(false), isSuccessPassword = _f[0], setIsSuccessPassword = _f[1];
    var inputs = isEmail
        ? [
            {
                key: EMAIL_KEY,
                label: ENTER_NEW_EMAIL,
                className: 'blue-background',
                isEmail: true,
            },
        ]
        : [
            {
                key: PASSWORD_KEY2,
                label: ENTER_NEW_PASSWORD,
                className: 'blue-background',
                isPassword: true,
            },
            {
                key: PASSWORD_CONFIRMATION_KEY,
                label: CONFIRM_NEW_PASSWORD_TEXT,
                className: 'blue-background',
                isPassword: true,
            },
        ];
    var mutateUpdateEmail = useMutation(updateEmail, {
        onSuccess: function () {
            setBackError('');
            setIsSuccessEmail(true);
        },
        onError: function (error) {
            var _a;
            setBackError(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.errors) || BACK_ERROR);
        },
    })[0];
    var mutateUpdatePassword = useMutation(updatePassword, {
        onSuccess: function () {
            setBackError('');
            setIsSuccessPassword(true);
        },
        onError: function (error) {
            var _a;
            setBackError(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.errors) || BACK_ERROR);
        },
    })[0];
    var onSubmit = function (data) {
        if (isValidForm(data, setError)) {
            if (isSuperAdmin && data.email) {
                mutateUpdateEmail({ idContact: id, email: data.email });
            }
            else
                setIsSuccessEmail(true);
            if (data.password) {
                mutateUpdatePassword({
                    password: data[PASSWORD_KEY2],
                    password_confirmation: data[PASSWORD_CONFIRMATION_KEY],
                    idContact: id,
                });
            }
            else
                setIsSuccessPassword(true);
        }
    };
    useEffect(function () {
        isSuccessEmail && isSuccessPassword && history.push(ROUTES.contacts);
    }, [isSuccessEmail, isSuccessPassword]);
    var onCancel = function () { return history.push(ROUTES.contacts); };
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, { className: "non-margin" }, isEmail ? MODIFY_EMAIL : MODIFY_PASSWORD),
            React.createElement(DetailsContainer, null,
                React.createElement(AdminForm, { inputs: inputs, onSubmit: onSubmit, buttonLabel: SAVE, onCancel: onCancel, backError: backError || error.email || error.password, className: "modify-password", shouldModifyContact: true })))));
};
export default EditPassword;
