var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M } from 'src/Utils';
export var ContactContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-self: flex-start;\n  justify-content: center;\n  height: 55px;\n  background-color: ", ";\n  border-radius: 8px;\n  @media (max-width: ", "px) {\n    margin-left: 10px;\n    max-width: inherit;\n    padding: 0px;\n    padding: 5px 20px;\n    width: auto;\n    background-color: ", ";\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-self: flex-start;\n  justify-content: center;\n  height: 55px;\n  background-color: ", ";\n  border-radius: 8px;\n  @media (max-width: ", "px) {\n    margin-left: 10px;\n    max-width: inherit;\n    padding: 0px;\n    padding: 5px 20px;\n    width: auto;\n    background-color: ", ";\n  }\n"])), theme.palette.admin.primary[45], M, theme.palette.admin.primary[50]);
export var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  font-family: ", ";\n  font-style: normal;\n  font-weight: 600;\n  font-size: 29px;\n  margin: 0px 20px 0px 20px;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  font-family: ", ";\n  font-style: normal;\n  font-weight: 600;\n  font-size: 29px;\n  margin: 0px 20px 0px 20px;\n  align-items: center;\n"])), theme.typography.normal.raleway);
export var Label = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  font-family: ", ";\n  font-style: normal;\n  font-weight: normal;\n  font-size: 16px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  font-family: ", ";\n  font-style: normal;\n  font-weight: normal;\n  font-size: 16px;\n"])), theme.typography.normal.raleway);
export var Value = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  font-family: ", ";\n  font-style: normal;\n  font-weight: 900;\n  font-size: 16px;\n  margin-left: 10px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  font-family: ", ";\n  font-style: normal;\n  font-weight: 900;\n  font-size: 16px;\n  margin-left: 10px;\n"])), theme.typography.normal.raleway);
export var Container = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  margin: 0px 20px 0px 20px;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  margin: 0px 20px 0px 20px;\n  align-items: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
