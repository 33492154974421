import React from "react";
import { Button } from "src/Components/Common";
import { CLEAR_FILTERS, NO_PROPERTIES } from "src/Utils";
import { RealEstateSearchIcon } from "src/assets/Images";
import useResetFilters from "../../Filter/useResetFilter";
import { Container, Image, BoldText, Text, ButtonContainer, TextContainer, } from './styles';
var EmptyProperties = function () {
    var resetFilters = useResetFilters().resetFilters;
    return (React.createElement(Container, null,
        React.createElement(Image, { src: RealEstateSearchIcon, alt: "Search Icon" }),
        React.createElement(TextContainer, null,
            React.createElement(BoldText, null, NO_PROPERTIES.title),
            React.createElement(Text, null, NO_PROPERTIES.subtitle)),
        React.createElement(ButtonContainer, null,
            React.createElement(Button, { onClick: resetFilters, className: "full-width" }, CLEAR_FILTERS))));
};
export default EmptyProperties;
