import api from 'src/Utils/api';
export var forgotPassword = function (_a) {
    var email = _a.email;
    return api.post('/password/forgot', {
        email: email,
    });
};
export var resetPassword = function (_a) {
    var token = _a.token, password = _a.password, passwordConfirmation = _a.passwordConfirmation;
    return api.post('/password/reset', {
        reset_password_token: token,
        password: password,
        confirm_password: passwordConfirmation,
    });
};
export var createAccount = function (_a) {
    var email = _a.email, first_name = _a.first_name, last_name = _a.last_name, mobile = _a.mobile, address = _a.address, client_codes = _a.client_codes;
    return api.post('/contacts', {
        contact: {
            email: email,
            first_name: first_name,
            last_name: last_name,
            mobile: mobile,
            address: address,
            client_codes: client_codes,
        },
    });
};
