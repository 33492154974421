import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useQuery } from 'react-query';
import { AdminLayout } from 'src/Layouts';
import { Container, TableContainer, Title, UploadContainer, UploadButton, } from './styles';
import Modal from 'src/Components/Common/Modal';
import { AdminCard, FilterTable } from 'src/Components/Common';
import { M } from 'src/Utils';
import { useWindowWidth } from 'src/useWindowWidth';
import { createData, createHeadCells } from 'src/Utils/utils';
import { OTHER_DOCUMENTS, DOCUMENT, TYPE_CONTACT, NAME, UPLOAD_DOCUMENT, DOCUMENTS_API, BUILDING_API, UPLOAD_DATE, DATE_FORMAT_MOMENT, MODIFIED_BY, } from 'src/Utils/constants';
import { UploadPhoto } from 'src/assets/Images';
import api, { getData } from 'src/Utils/api';
import moment from 'moment';
var isSuperAdmin = Cookies.get('SuperAdmin') === 'true';
var headCells = !isSuperAdmin
    ? createHeadCells(DOCUMENT, UPLOAD_DATE, '', '')
    : createHeadCells(DOCUMENT, UPLOAD_DATE, MODIFIED_BY, '', '');
var handleSubmit = function () {
    //TODO: handle modal Submit
};
var USER_WIDTHS = !isSuperAdmin
    ? ['35%', '35%', '0%', '30%']
    : ['30%', '30%', '20%', '5%'];
function createBuilding(label, value, rows) {
    return { label: label, value: value, rows: rows };
}
var OtherDocuments = function () {
    var _a = useState(false), isModalOpen = _a[0], setIsModalOpen = _a[1];
    var isContact = Cookies.get('User') === TYPE_CONTACT;
    var windowWidth = useWindowWidth();
    var isSmallScreen = M >= windowWidth;
    var _b = useState(), rows = _b[0], setRows = _b[1];
    var _c = useState(), buildingList = _c[0], setBuildingList = _c[1];
    var _d = useState(), selectedBuilding = _d[0], setSelectedBuilding = _d[1];
    var _e = useState(), startDate = _e[0], setStartDate = _e[1];
    var _f = useState(), endDate = _f[0], setEndDate = _f[1];
    var isLoadingBuildings = useQuery(BUILDING_API, function () { return getData("/buildings"); }, {
        refetchOnWindowFocus: false,
        onSuccess: function (data) {
            var buildings = data.buildings.map(function (_a) {
                var name = _a.name, id = _a.id;
                return createBuilding(name, id, rows);
            });
            buildings.sort(function (a, b) {
                return a.label.localeCompare(b.label, 'es');
            });
            setBuildingList(buildings);
        },
    }).isLoading;
    var handleDeleteRow = function (id) {
        var filteredRows = rows === null || rows === void 0 ? void 0 : rows.filter(function (row) { return row.fourth !== id; });
        api.delete("/documents/" + id)
            .then(function () { return setRows(filteredRows); });
    };
    var _g = useQuery([DOCUMENTS_API, selectedBuilding, isModalOpen], function () { return getData("/documents?building_id=" + (selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value)); }, {
        refetchOnWindowFocus: false,
        onSuccess: function (data) {
            data.documents.sort(function (a, b) { return moment(b.created_at).diff(moment(a.created_at)); });
            if (!isSuperAdmin) {
                setRows(data.documents.map(function (_a) {
                    var name = _a.name, created_at = _a.created_at, pdf = _a.pdf;
                    return createData(name, moment(created_at).format(DATE_FORMAT_MOMENT), '', '', pdf);
                }));
            }
            else {
                setRows(data.documents.map(function (_a) {
                    var name = _a.name, created_at = _a.created_at, modified_by = _a.modified_by, pdf = _a.pdf, id = _a.id;
                    return createData(name, moment(created_at).format(DATE_FORMAT_MOMENT), modified_by, "" + id, pdf);
                }));
            }
        },
    }), data = _g.data, refetch = _g.refetch;
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, null, OTHER_DOCUMENTS),
            rows && (React.createElement(TableContainer, null, isSmallScreen ? (rows.map(function (row) { return (React.createElement(AdminCard, { item: row, isDocumentsPage: true, key: row.first })); })) : (React.createElement(FilterTable, { rows: rows, headCells: headCells, buildingList: buildingList, selectedBuilding: selectedBuilding, setSelectedBuilding: setSelectedBuilding, width: USER_WIDTHS, userWidth: USER_WIDTHS, handleButtonClick: function () { return setIsModalOpen(function (prev) { return !prev; }); }, hasButton: !isContact, isModalOpen: isModalOpen, setIsModalOpen: setIsModalOpen, startDate: startDate, setStartDate: setStartDate, endDate: endDate, setEndDate: setEndDate, isLoading: isLoadingBuildings, hasDateFilter: false, modalButton: UPLOAD_DOCUMENT, handleModalSubmit: handleSubmit, modalField: NAME, modalTitle: UPLOAD_DOCUMENT, refetch: refetch, handleDeleteRow: handleDeleteRow, isDocument: true, hasDelete: true })))),
            isSmallScreen && !isContact && (React.createElement(React.Fragment, null,
                React.createElement(UploadContainer, null,
                    React.createElement(UploadButton, { src: UploadPhoto, onClick: function () { return setIsModalOpen(function (prev) { return !prev; }); } })),
                React.createElement(Modal, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, title: UPLOAD_DOCUMENT, textInputLabel: NAME, onSubmit: handleSubmit }))))));
};
export default OtherDocuments;
