var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M } from '../../../Utils';
export var Div = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  &.contact-dashboard {\n    width: 90%;\n  }\n  @media (max-width: ", "px) {\n    display: flex;\n    justify-content: space-between;\n    position: absolute;\n    background-color: ", ";\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  &.contact-dashboard {\n    width: 90%;\n  }\n  @media (max-width: ", "px) {\n    display: flex;\n    justify-content: space-between;\n    position: absolute;\n    background-color: ", ";\n  }\n"])), M, theme.palette.admin.primary[45]);
export var Label = styled.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 24px;\n  font-weight: 500;\n  color: ", ";\n  @media (max-width: ", "px) {\n    background-color: ", ";\n    color: ", ";\n    font-size: 16px;\n    padding: 5px 0px;\n    font-family: ", ";\n    margin-left: 28px;\n  }\n"], ["\n  font-size: 24px;\n  font-weight: 500;\n  color: ", ";\n  @media (max-width: ", "px) {\n    background-color: ", ";\n    color: ", ";\n    font-size: 16px;\n    padding: 5px 0px;\n    font-family: ", ";\n    margin-left: 28px;\n  }\n"])), theme.palette.admin.primary[75], M, theme.palette.admin.primary[45], theme.palette.black[100], theme.typography.OpenSans);
export var Img = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: pointer;\n  @media (max-width: ", "px) {\n    margin-right: 28px;\n  }\n"], ["\n  cursor: pointer;\n  @media (max-width: ", "px) {\n    margin-right: 28px;\n  }\n"])), M);
var templateObject_1, templateObject_2, templateObject_3;
