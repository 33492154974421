import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { ResetPasswordComponent } from 'src/Components';
import { Header } from 'src/Layouts';
import { useWindowWidth } from 'src/useWindowWidth';
import { CONFIRM, EXCELLENT, NEW_PASSWORD_DESCRIPTION, NEW_PASSWORD_KEY, NEW_PASSWORD_TEXT, NEW_PASSWORD_TITLE, REPEAT_PASSWORD, CONFIRM_PASSWORD_KEY, RESET_PASSWORD_SUCCESSFUL, S, ROUTES, GO_TO_ACCOUNT, } from 'src/Utils/constants';
import { Container } from './styles';
import Footer from '../Footer';
import { ApprovedIcon, LoginBackground, LoginMobileBackground, } from 'src/assets/Images';
import { isValidForm } from 'src/Utils/validations';
import { ResponseForm } from 'src/Components/ResetPasswordForm';
import { resetPassword } from './duck/services';
var inputs = [
    { key: NEW_PASSWORD_KEY, name: NEW_PASSWORD_TEXT },
    { key: CONFIRM_PASSWORD_KEY, name: REPEAT_PASSWORD },
];
var header = {
    title: NEW_PASSWORD_TITLE,
    description: NEW_PASSWORD_DESCRIPTION,
};
var ResetPassword = function () {
    var _a = useState(''), backError = _a[0], setBackError = _a[1];
    var _b = useState({
        newPassword: '',
        passwordConfirmation: '',
    }), error = _b[0], setError = _b[1];
    var _c = useState({
        newPassword: '',
        passwordConfirmation: '',
    }), data = _c[0], setData = _c[1];
    var token = useParams().token;
    var windowWidth = useWindowWidth();
    var smallScreen = windowWidth > S;
    var history = useHistory();
    var _d = useMutation(resetPassword, {
        onError: function (error) {
            setBackError(error.response.data.error);
        },
    }), mutateResetPassword = _d[0], _e = _d[1], isLoading = _e.isLoading, isSuccess = _e.isSuccess;
    var submitForm = function (token, password, passwordConfirmation) { return mutateResetPassword({ token: token, password: password, passwordConfirmation: passwordConfirmation }); };
    var handleClick = useCallback(function (e) {
        e.preventDefault();
        if (isValidForm(data, setError))
            submitForm(token, data[NEW_PASSWORD_KEY], data[CONFIRM_PASSWORD_KEY]);
    }, [data, setBackError]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, null),
        React.createElement(Container, { style: {
                backgroundImage: "url(" + (smallScreen ? LoginBackground : LoginMobileBackground) + ")",
            } }, isSuccess ? (React.createElement(ResponseForm, { header: {
                title: EXCELLENT,
                description: RESET_PASSWORD_SUCCESSFUL,
            }, icon: ApprovedIcon, hasButton: true, handleClick: function () { return history.push(ROUTES.login); }, buttonLabel: GO_TO_ACCOUNT })) : (React.createElement(ResetPasswordComponent, { onSubmit: handleClick, inputs: inputs, buttonLabel: CONFIRM, header: header, backError: backError, inputError: error, formData: data, setFormData: setData, isLoading: isLoading }))),
        React.createElement(Footer, null)));
};
export default ResetPassword;
