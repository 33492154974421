var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import { M, theme } from "src/Utils";
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 988px;\n  height: 560px;\n  background-color: ", ";\n  border-radius: 10px;\n  max-width: 90vw;\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  @media (max-width: ", "px) {\n    width: 350px;\n    height: 448px;\n    padding: 0 32px;\n  }\n"], ["\n  width: 988px;\n  height: 560px;\n  background-color: ", ";\n  border-radius: 10px;\n  max-width: 90vw;\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  @media (max-width: ", "px) {\n    width: 350px;\n    height: 448px;\n    padding: 0 32px;\n  }\n"])), theme.palette.primary[10], M);
export var Image = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n"], ["\n"])));
export var BoldText = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: 18px;\n  text-align: center;\n  color: ", ";\n  @media (max-width: ", "px) {\n    font-size: 16px;\n  }\n"], ["\n  font-family: ", ";\n  font-size: 18px;\n  text-align: center;\n  color: ", ";\n  @media (max-width: ", "px) {\n    font-size: 16px;\n  }\n"])), theme.typography.bold.ralewayBold, theme.palette.black[100], M);
export var Text = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 16px;\n  text-align: center;\n  color: ", ";\n  @media (max-width: ", "px) {\n    font-size: 14px;\n  }\n"], ["\n  font-size: 16px;\n  text-align: center;\n  color: ", ";\n  @media (max-width: ", "px) {\n    font-size: 14px;\n  }\n"])), theme.palette.black[100], M);
export var TextContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  padding: 24px 0;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  padding: 24px 0;\n"])));
export var ButtonContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 168px;\n  height: 33px;\n"], ["\n  width: 168px;\n  height: 33px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
