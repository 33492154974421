var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AdminLayout } from 'src/Layouts';
import { CANCEL, getData, IN_PERSON_PAYMENT, IN_PERSON_PAYMENT_TEXT, ONLINE_PAYMENT, UNPAID_COMMON_EXPENSES_API, } from 'src/Utils';
import { Container, Card, Cancel, MethodCard, Text, MethodsContainer, Img, Row, Bold, } from './styles';
import { Abitab, Banred, Redpagos, Bandes, BancoRepublica, HSBC, BBVA, Midinero, BanqueHeritage, Deanda, Santander, ScotiaBank, Itau, Fucac, Sistarbanc, } from 'src/assets/Images';
import { BankID } from 'src/Utils';
var createInput = function (name, value) {
    var input = window.document.createElement('input');
    if (name && value) {
        input.setAttribute('type', 'text');
        input.setAttribute('name', name);
        input.setAttribute('value', value);
    }
    else {
        input.setAttribute('type', 'submit');
    }
    return input;
};
var PaymentMethods = function (_a) {
    var _b = _a.online, online = _b === void 0 ? false : _b;
    var history = useHistory();
    var id = useParams().id;
    var _c = useState(''), payBy = _c[0], setPayBy = _c[1];
    var _d = React.useState(''), clientCode = _d[0], setClientCode = _d[1];
    var methods = online
        ? [
            { logo: Banred, value: 'Banred' },
            // { logo: HSBC, value: BankID.HSBC },
            { logo: HSBC, value: 'hsbc' },
            { logo: BBVA, value: BankID.BBVA },
            { logo: Deanda, value: 'deanda' },
            { logo: Santander, value: BankID.Santander },
            { logo: ScotiaBank, value: BankID.Scotiabank },
            { logo: Itau, value: BankID.Itau },
            { logo: Midinero, value: 'midinero' },
            { logo: BanqueHeritage, value: BankID.BanqueHeritage },
            { logo: Fucac, value: 'fucac' },
            { logo: BancoRepublica, value: BankID.BROU },
            { logo: Bandes, value: BankID.BANDES },
        ]
        : [Banred, Abitab, Redpagos];
    if (process.env.REACT_APP_ENVIRONMENT == 'development') {
        methods = __spreadArrays(methods, [{ logo: Sistarbanc, value: '009' }]);
    }
    var payBanred = useQuery('getBanredPayment', function () { return getData("/common_expenses_receipts/" + id + "/pay_online_banred"); }, {
        onSuccess: function (_a) {
            var information = _a.information;
            if (information) {
                var form = window.document.createElement('form');
                var button = createInput();
                form.appendChild(button);
                form.setAttribute('action', process.env.BANRED_PAYMENT_API ||
                    'https://www.banred.com.uy/bancomatdesa/indexext.php');
                form.setAttribute('method', 'post');
                for (var _i = 0, _b = Object.entries(information); _i < _b.length; _i++) {
                    var _c = _b[_i], key = _c[0], value = _c[1];
                    form.appendChild(createInput(key, value));
                }
                form.style.display = 'none';
                document.body.appendChild(form);
                button.click();
            }
        },
        enabled: payBy === 'banred',
        retry: false,
        refetchOnWindowFocus: false,
    }).refetch;
    var payBank = useQuery('getBankPayment', function () { return getData("/payments/" + id + "/sistarbanc?bank_id=" + payBy); }, {
        onSuccess: function (_a) {
            var payload = _a.payload;
            if (payload) {
                var form = window.document.createElement('form');
                var button = createInput();
                form.appendChild(button);
                form.setAttribute('action', process.env.SISTARBANC_PAYMENT_API ||
                    'https://spf.sistarbanc.com.uy/spfe/servlet/PagoEmpresa');
                form.setAttribute('method', 'post');
                for (var _i = 0, _b = Object.entries(payload); _i < _b.length; _i++) {
                    var _c = _b[_i], key = _c[0], value = _c[1];
                    form.appendChild(createInput(key, value));
                }
                form.style.display = 'none';
                document.body.appendChild(form);
                button.click();
            }
        },
        enabled: /[0-9]/.test(payBy),
        retry: false,
        refetchOnWindowFocus: false,
    }).refetch;
    useQuery(UNPAID_COMMON_EXPENSES_API, function () { return getData("/common_expenses_receipts/" + id); }, {
        onSuccess: function (response) {
            setClientCode(response.numeric_client_code);
        },
        refetchOnWindowFocus: false,
    });
    useEffect(function () {
        if (payBy === 'Banred') {
            payBanred();
        }
        if (/[0-9]/.test(payBy)) {
            payBank();
        }
    }, [payBy]);
    var shouldDisable = function (online, value) {
        return !online ? 'pointer' :
            /[0-9]/.test(value) || value === 'Banred'
                ? 'banred' : 'disabled';
    };
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Card, null,
                React.createElement("div", null,
                    React.createElement(Text, { className: "title" }, online ? ONLINE_PAYMENT : IN_PERSON_PAYMENT),
                    !online && (React.createElement(Row, null,
                        React.createElement(Text, null, IN_PERSON_PAYMENT_TEXT),
                        React.createElement(Bold, null, clientCode))),
                    !online && (React.createElement(MethodsContainer, null,
                        React.createElement(Row, null, methods.map(function (method) { return (React.createElement(MethodCard, { className: 'row', onClick: function () { return online && setPayBy(method.value); } },
                            React.createElement(Img, { src: online ? method.logo : method }))); })))),
                    online && (React.createElement(MethodsContainer, null,
                        React.createElement(Row, null, methods.slice(0, 5).map(function (method) { return (React.createElement(MethodCard, { key: method.value, className: shouldDisable(online, method.value), onClick: function () { return online && setPayBy(method.value); } },
                            React.createElement(Img, { src: online ? method.logo : method }))); })),
                        React.createElement(Row, null, methods.slice(5, 10).map(function (method) { return (React.createElement(MethodCard, { key: method.value, className: shouldDisable(online, method.value), onClick: function () { return online && setPayBy(method.value); } },
                            React.createElement(Img, { src: online ? method.logo : method }))); })),
                        React.createElement(Row, null, methods.slice(10, 13).map(function (method) { return (React.createElement(MethodCard, { key: method.value, className: shouldDisable(online, method.value), onClick: function () { return online && setPayBy(method.value); } },
                            React.createElement(Img, { src: online ? method.logo : method }))); }))))),
                React.createElement(Cancel, { onClick: function () { return history.goBack(); } }, CANCEL)))));
};
export default PaymentMethods;
