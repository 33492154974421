import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { usePDF } from '@react-pdf/renderer';
import moment from 'moment';
import { PdfComponent } from 'src/Components/ReceiptForm';
import { AdminLayout } from 'src/Layouts';
import { ADD, DATE_FORMAT_MOMENT, NEW_RECEIPT, RECEIPT_KEY, ROUTES, BACK_ERROR, RECEIPT_NUMBER_ERROR, } from 'src/Utils/constants';
import { Container, Title } from './styles';
import { submitDataForm, submitPDF } from './duck';
import NewReceiptForm from 'src/Components/ReceiptForm';
import { useAppDispatch } from '../../../redux/hooks';
import { setFormSelected } from '../../../redux/slices/tableFiltersSlice';
var NewRecepit = function () {
    var dispatch = useAppDispatch();
    var history = useHistory();
    var _a = useState(''), backError = _a[0], setBackError = _a[1];
    var _b = useState(), pdfData = _b[0], setPdfData = _b[1];
    var _c = useState(), receiptId = _c[0], setReceiptId = _c[1];
    var PDFdocument = (React.createElement(PdfComponent, { clientNumber: (pdfData === null || pdfData === void 0 ? void 0 : pdfData.clientNumber) || '', location: (pdfData === null || pdfData === void 0 ? void 0 : pdfData.location) || '', receiptNumber: (pdfData === null || pdfData === void 0 ? void 0 : pdfData.receiptNumber) || '', expirationDate: (pdfData === null || pdfData === void 0 ? void 0 : pdfData.expirationDate) || '', totalAmount: (pdfData === null || pdfData === void 0 ? void 0 : pdfData.totalAmount) || '', holder: (pdfData === null || pdfData === void 0 ? void 0 : pdfData.holder) || '', issueDate: (pdfData === null || pdfData === void 0 ? void 0 : pdfData.issueDate) || '', description: (pdfData === null || pdfData === void 0 ? void 0 : pdfData.description) || '', currency: (pdfData === null || pdfData === void 0 ? void 0 : pdfData.currency) || '' }));
    var _d = usePDF({ document: PDFdocument }), instance = _d[0], updateInstance = _d[1];
    var mutateFormReceipt = useMutation(submitDataForm, {
        onSuccess: function (response) {
            setBackError('');
            var id = response.data.id;
            setReceiptId(id);
            history.push({
                pathname: ROUTES.receipt,
            });
        },
        onError: function (error) {
            var _a, _b, _c, _d, _e;
            setBackError(((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) ? error.response.data.error
                : ((_e = (_d = (_c = error.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.errors) === null || _e === void 0 ? void 0 : _e.receipt_number) ? RECEIPT_NUMBER_ERROR
                    : BACK_ERROR);
        },
    })[0];
    var mutatePDF = useMutation(submitPDF)[0];
    useEffect(function () {
        pdfData && updateInstance();
    }, [pdfData]);
    useEffect(function () {
        if (!instance.loading && receiptId) {
            var file = new File([instance.blob], RECEIPT_KEY + "-" + (pdfData === null || pdfData === void 0 ? void 0 : pdfData.receiptNumber) + ".pdf", {
                type: 'application/pdf',
            });
            mutatePDF({ file: file, receiptId: receiptId });
        }
    }, [receiptId, instance.loading]);
    var submitForm = function (numeric_client_code, address, apartment_id, tower, receipt_number, expiration_date, amount, currency, description, building) {
        mutateFormReceipt({
            numeric_client_code: numeric_client_code,
            address: address,
            apartment_id: apartment_id,
            tower: tower,
            receipt_number: receipt_number,
            expiration_date: expiration_date,
            amount: amount,
            currency: currency,
            description: description,
        });
        setPdfData({
            clientNumber: numeric_client_code,
            location: address,
            receiptNumber: receipt_number,
            expirationDate: moment(expiration_date).format(DATE_FORMAT_MOMENT),
            totalAmount: amount,
            holder: building,
            issueDate: moment(new Date()).format(DATE_FORMAT_MOMENT),
            description: description,
            currency: currency,
        });
    };
    useEffect(function () {
        dispatch(setFormSelected(true));
    }, []);
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, { className: "non-margin" }, NEW_RECEIPT),
            React.createElement(NewReceiptForm, { onSubmit: submitForm, backError: backError, buttonLabel: ADD }))));
};
export default NewRecepit;
