import React from 'react';
import { Modal as ModalComponent } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Container, Title, LabelContainer, Label, Center, RowContainer, } from './styles';
import { RECEIPT, AMOUNT, PAY, PAYMENT_BY } from 'src/Utils/constants';
import { AdminButton } from '../Common/AdminButton';
var Modal = function (_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, title = _a.title, receipt = _a.receipt, amount = _a.amount, onSubmit = _a.onSubmit;
    var handleSubmit = function () { };
    var renderLabel = function (name, value) { return (React.createElement(LabelContainer, null,
        React.createElement(Label, { className: "bold" }, name),
        React.createElement(Label, null, ": " + value))); };
    return (React.createElement(ModalComponent, { open: isOpen },
        React.createElement(Center, null,
            React.createElement(Container, null,
                React.createElement(RowContainer, { className: "right close" },
                    React.createElement(Close, { onClick: function () { return setIsOpen(false); } })),
                React.createElement(Title, null, title),
                React.createElement(RowContainer, null,
                    renderLabel(RECEIPT, receipt),
                    renderLabel(AMOUNT, "$ " + amount + " UYU")),
                React.createElement(RowContainer, { className: "margin" },
                    React.createElement(Label, { className: "small" }, PAYMENT_BY)),
                React.createElement(RowContainer, { className: "right" },
                    React.createElement(AdminButton, { className: "margin", onClick: handleSubmit }, PAY))))));
};
export default Modal;
