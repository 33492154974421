import React from 'react';
import { Container, InnerContainer, PrincipalImage, SecondaryImage, } from './styles';
export var Images = function (_a) {
    var _b;
    var images = _a.images, setIsOpen = _a.setIsOpen;
    var handleOpen = function () { return setIsOpen(true); };
    return (React.createElement(Container, null,
        React.createElement(PrincipalImage, { className: images.length <= 1 ? 'full' : '', src: ((_b = images[0]) === null || _b === void 0 ? void 0 : _b.original) || undefined, onClick: handleOpen, crossOrigin: "anonymous" }),
        images.length > 1 && (React.createElement(InnerContainer, null, images.slice(1, 5).map(function (_a, index) {
            var original = _a.original;
            return (React.createElement(SecondaryImage, { src: original || undefined, onClick: handleOpen, className: "element-" + index, key: "images-" + index, crossOrigin: "anonymous" }));
        })))));
};
