import api from 'src/Utils/api';
export var submitDataForm = function (_a) {
    var numeric_client_code = _a.numeric_client_code, address = _a.address, apartment_id = _a.apartment_id, tower = _a.tower, receipt_number = _a.receipt_number, expiration_date = _a.expiration_date, amount = _a.amount, currency = _a.currency, description = _a.description;
    return api.post('/common_expenses_receipts', {
        common_expenses_receipt: {
            numeric_client_code: numeric_client_code,
            address: address,
            apartment_id: apartment_id,
            tower: tower,
            receipt_number: receipt_number,
            expiration_date: expiration_date,
            amount: amount,
            currency: currency,
            description: description,
        },
    });
};
export var submitEditedDataForm = function (_a) {
    var numeric_client_code = _a.numeric_client_code, address = _a.address, apartment_id = _a.apartment_id, tower = _a.tower, receipt_number = _a.receipt_number, expiration_date = _a.expiration_date, amount = _a.amount, currency = _a.currency, description = _a.description, receiptId = _a.receiptId;
    return api.patch("/common_expenses_receipts/" + receiptId, {
        numeric_client_code: numeric_client_code,
        address: address,
        apartment_id: apartment_id,
        tower: tower,
        receipt_number: receipt_number,
        expiration_date: expiration_date,
        amount: amount,
        currency: currency,
        description: description,
    });
};
export var submitPDF = function (_a) {
    var file = _a.file, receiptId = _a.receiptId;
    var formData = new FormData();
    formData.append('pdf', file);
    return api.put("/common_expenses_receipts/" + receiptId + "/pdf", formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
export var deleteCommonExpenses = function (receiptId) {
    return api.delete("/common_expenses_receipts/" + receiptId);
};
export var submitPDFMobile = function (_a) {
    var file = _a.file, url = _a.url;
    var formData = new FormData();
    formData.append('pdf', file);
    return api.put(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
