var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useState, useEffect, useCallback } from 'react';
import { BottomInputs, Error, ButtonsContainer, FormsDiv, FormsContainer, DetailsContainer, FormContainer, Link, } from './styles';
import { AdminButton } from '../Common/AdminButton';
import { isValidBuildingForms, isValidForm } from 'src/Utils/validations';
import { PersonalForm, DirectionForm, BuildingsComponent } from '.';
import { ADDRESS_KEY, APARTMENT_KEY, BUILDING_KEY, CANCEL, EMAIL_KEY, LAST_NAME_KEY, NAME_KEY, PHONE_KEY, RELATIONSHIP_KEY, TOWER_KEY, } from 'src/Utils/constants';
var Form = function (_a) {
    var id = _a.id, inputs = _a.inputs, onSubmit = _a.onSubmit, buttonLabel = _a.buttonLabel, backError = _a.backError, buildingInputs = _a.buildingInputs, onCancel = _a.onCancel, disabled = _a.disabled, isEdit = _a.isEdit, apartmentsInput = _a.apartmentsInput, setSelectedApartment = _a.setSelectedApartment, handleDelete = _a.handleDelete, successDelete = _a.successDelete, setSuccessDelete = _a.setSuccessDelete;
    var _b = useState({}), formErrors = _b[0], setFormErrors = _b[1];
    var _c = useState({}), buildingErrors = _c[0], setBuildingErrors = _c[1];
    var _d = useState({}), oldBuildingErrors = _d[0], setOldBuildingErrors = _d[1];
    var _e = useState({
        email: '',
        last_name: '',
        address: '',
        name: '',
        phone: '',
    }), formData = _e[0], setFormData = _e[1];
    var _f = useState({}), buildingData = _f[0], setBuildingData = _f[1];
    var _g = useState([]), formsBuilding = _g[0], setFormsBuilding = _g[1];
    var _h = useState({}), oldBuildingData = _h[0], setOldBuildingData = _h[1];
    var _j = useState([]), oldFormsBuilding = _j[0], setOldFormsBuilding = _j[1];
    var _k = useState(false), firstRender = _k[0], setFirstRender = _k[1];
    var handleClick = useCallback(function (e) {
        Object.keys(formData).forEach(function (key) {
            formErrors[key] = formData[key];
        });
        Object.keys(buildingData).forEach(function (key) {
            var selectedData = buildingData[key];
            buildingErrors[key] = __assign(__assign({}, buildingErrors[key]), { building: selectedData[BUILDING_KEY].label, tower: selectedData[TOWER_KEY].label, apartment: selectedData[APARTMENT_KEY].label, relation: selectedData[RELATIONSHIP_KEY].label });
        });
        Object.keys(oldBuildingData).forEach(function (key) {
            var selectedData = oldBuildingData[key];
            oldBuildingErrors[key] = __assign(__assign({}, oldBuildingErrors[key]), { building: selectedData[BUILDING_KEY].label, tower: selectedData[TOWER_KEY].label, apartment: selectedData[APARTMENT_KEY].label, relation: selectedData[RELATIONSHIP_KEY].label });
        });
        e.preventDefault();
        var validBuildingsForms = function () {
            var newApartments = isValidBuildingForms(buildingErrors, setBuildingErrors);
            var oldApartments = isValidBuildingForms(oldBuildingErrors, setOldBuildingErrors);
            return oldApartments && newApartments;
        };
        var validForms = isValidForm(formErrors, setFormErrors);
        if (validBuildingsForms() && validForms) {
            var newApartments_1 = [];
            Object.keys(buildingData).forEach(function (key) {
                var selectedData = buildingData[key];
                newApartments_1.push({
                    id: selectedData[APARTMENT_KEY].value,
                    relationship: selectedData[RELATIONSHIP_KEY].value,
                });
            });
            var oldApartments_1 = [];
            Object.keys(oldBuildingData).forEach(function (key) {
                var selectedData = oldBuildingData[key];
                oldApartments_1.push({
                    id: selectedData[APARTMENT_KEY].apartment,
                    relationship: selectedData[RELATIONSHIP_KEY].value,
                });
            });
            if (isEdit) {
                onSubmit(id, oldApartments_1, newApartments_1, formData);
            }
            else {
                onSubmit(formData[EMAIL_KEY], formData[NAME_KEY], formData[LAST_NAME_KEY], formData[PHONE_KEY], newApartments_1, formData[ADDRESS_KEY]);
            }
        }
    }, [
        formData,
        buildingErrors,
        oldBuildingErrors,
        formErrors,
        setBuildingErrors,
        setOldBuildingErrors,
        setFormErrors,
        oldBuildingData,
        buildingData,
    ]);
    useEffect(function () {
        if (!isEdit) {
            var initialFormData_1 = {};
            var initialBuildingData_1 = {};
            var initialFormErrors_1 = {};
            var initialBuildingErrors_1 = {};
            inputs.forEach(function (_a) {
                var key = _a.key, value = _a.value;
                initialFormData_1[key] = value;
                initialFormErrors_1[key] = '';
            });
            setFormData(initialFormData_1);
            setFormErrors(initialFormErrors_1);
            buildingInputs.forEach(function (_a) {
                var key = _a.key, value = _a.value;
                initialBuildingData_1[key] = value;
                initialBuildingErrors_1[key] = '';
            });
            var buildingsFormsData = {};
            var buildingsFormErrors = {};
            buildingsFormsData[0] = __assign({}, initialBuildingData_1);
            buildingsFormErrors[0] = __assign({}, initialBuildingErrors_1);
            setBuildingData(buildingsFormsData);
            setBuildingErrors(buildingsFormErrors);
        }
        else {
            var initialFormData_2 = {};
            var initialFormErrors_2 = {};
            var initialBuildingErrors_2 = [];
            inputs.forEach(function (_a) {
                var key = _a.key, value = _a.value;
                initialFormData_2[key] = value;
                initialFormErrors_2[key] = '';
            });
            setFormData(initialFormData_2);
            setFormErrors(initialFormErrors_2);
            var buildingsFormsData_1 = [];
            var buildingsFormErrors_1 = {};
            apartmentsInput.forEach(function (apartment, index) {
                buildingsFormsData_1[index] = __assign({}, apartment);
                buildingsFormErrors_1[index] = __assign({}, initialBuildingErrors_2);
            });
            setOldBuildingData(buildingsFormsData_1);
            setOldBuildingErrors(buildingsFormErrors_1);
        }
        setFirstRender(true);
    }, []);
    useEffect(function () {
        if (firstRender) {
            if (isEdit) {
                var _loop_1 = function (i) {
                    setOldFormsBuilding(function (prevState) { return __spreadArrays(prevState, [
                        {
                            setBuildingData: setOldBuildingData,
                            buildingData: oldBuildingData,
                            buildingInputs: buildingInputs,
                            index: i,
                        },
                    ]); });
                };
                for (var i = 0; i < apartmentsInput.length; i++) {
                    _loop_1(i);
                }
            }
            else {
                var forms = [
                    {
                        setBuildingData: setBuildingData,
                        buildingData: buildingData,
                        buildingInputs: buildingInputs,
                        index: 0,
                    },
                ];
                setFormsBuilding(forms);
            }
            setFirstRender(false);
        }
    }, [oldBuildingData, buildingErrors]);
    return (React.createElement(FormsContainer, null,
        React.createElement(FormsDiv, null,
            React.createElement(FormContainer, null,
                React.createElement(DetailsContainer, { className: "margin" },
                    React.createElement(PersonalForm, { setFormData: setFormData, formData: formData, errors: formErrors, inputs: inputs, disabled: disabled ? true : false })),
                React.createElement(DetailsContainer, null,
                    React.createElement(DirectionForm, { disabled: disabled ? true : false, setFormData: setFormData, formData: formData, errors: formErrors }))),
            buildingData && (React.createElement(BuildingsComponent, { buildingInputs: buildingInputs, setBuildingData: setBuildingData, setBuildingErrors: setBuildingErrors, buildingData: buildingData, buildingErrors: buildingErrors, formsBuilding: formsBuilding, setFormsBuilding: setFormsBuilding, setSelectedApartment: setSelectedApartment, setOldBuildingErrors: setOldBuildingErrors, oldBuildingErrors: oldBuildingErrors, oldFormsBuilding: oldFormsBuilding, setOldFormsBuilding: setOldFormsBuilding, handleDelete: handleDelete, successDelete: successDelete, setSuccessDelete: setSuccessDelete, setOldBuildingData: setOldBuildingData, oldBuildingData: oldBuildingData }))),
        React.createElement(BottomInputs, null,
            backError && React.createElement(Error, null, backError),
            React.createElement(ButtonsContainer, null,
                React.createElement(Link, { onClick: onCancel }, CANCEL),
                React.createElement(AdminButton, { type: "button", onClick: handleClick, className: "height" }, buttonLabel)))));
};
export default Form;
