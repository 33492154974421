var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ADDRESS_KEY, AMOUNT_KEY, APARTMENT_KEY, ASSEMBLY_KEY, BUILDING_KEY, CLIENT_KEY, CONFIRM_PASSWORD_KEY, DATE_KEY, EMAIL_KEY, INVALID_CLIENT, INVALID_DATE, INVALID_EMAIL, INVALID_PASSWORD, INVALID_PASSWORD_CONFIRMATION, INVALID_PHONE, LOCATION, LOCATION_KEY, NEW_PASSWORD_KEY, OTHER_KEY, PASSWORD, PASSWORD_CONFIRMATION_KEY, PASSWORD_KEY2, PHONE_KEY, PLACE_KEY, RECEIPTS_KEY, REQUIRED_FIELD, TIME_KEY, UNIT_ERROR, UNIT_KEY, VALIDATE_EMAIL, VALIDATE_PHONE, CLIENT_CODE_KEY, NEW_CLIENT_CODE_KEY, NEW_SERVICE_KEY, ALL_ENG, } from './constants';
export var hasError = function (key, errors) {
    return errors[key] && errors[key].length;
};
export var isValidForm = function (formData, setErrors) {
    var errorMessage = {};
    var isValid = true;
    Object.keys(formData).forEach(function (key) {
        if (!formData[key] && key != LOCATION && key != PHONE_KEY) {
            errorMessage[key] = REQUIRED_FIELD;
            isValid = false;
        }
        else if (key.toLowerCase() === EMAIL_KEY &&
            !VALIDATE_EMAIL.test(formData[key])) {
            errorMessage[key] = INVALID_EMAIL;
            isValid = false;
        }
        else if (key === PHONE_KEY && !formData[key]) {
            errorMessage[key] = '';
        }
        else if (key === PHONE_KEY && !VALIDATE_PHONE.test(formData[key])) {
            errorMessage[key] = INVALID_PHONE;
            isValid = false;
        }
        else if ((key === PASSWORD || key === PASSWORD_KEY2 || key === NEW_PASSWORD_KEY) &&
            formData[key].length < 8) {
            errorMessage[key] = INVALID_PASSWORD;
            isValid = false;
        }
        else if (key === UNIT_KEY && isNaN(formData[key])) {
            errorMessage[key] = UNIT_ERROR;
            isValid = false;
        }
        else if (key === OTHER_KEY && !formData[key]) {
            errorMessage[key] = REQUIRED_FIELD;
            isValid = false;
        }
        else if (isNaN(parseInt(key)) && !formData[key]) {
            errorMessage[key] = REQUIRED_FIELD;
            isValid = false;
        }
        else if (key === ASSEMBLY_KEY && !formData[key]) {
            errorMessage[key] = REQUIRED_FIELD;
            isValid = false;
        }
        else if (key === BUILDING_KEY && !formData[key]) {
            errorMessage[key] = REQUIRED_FIELD;
            isValid = false;
        }
        else if (key === NEW_SERVICE_KEY && !formData[key]) {
            errorMessage[key] = REQUIRED_FIELD;
            isValid = false;
        }
        else if (key === PLACE_KEY && !formData[key]) {
            errorMessage[key] = REQUIRED_FIELD;
            isValid = false;
        }
        else if (key === TIME_KEY && !formData[key]) {
            errorMessage[key] = REQUIRED_FIELD;
            isValid = false;
        }
        else if (key === LOCATION_KEY && !formData[key]) {
            errorMessage[key] = REQUIRED_FIELD;
            isValid = false;
        }
        else if (key === DATE_KEY) {
            var currentDate = new Date().setHours(0, 0, 0, 0);
            var selectedDate = new Date(formData[key]).setHours(0, 0, 0, 0);
            if (currentDate > selectedDate) {
                errorMessage[key] = INVALID_DATE;
                isValid = false;
            }
        }
        else if (key === ADDRESS_KEY && !formData[key]) {
            errorMessage[key] = REQUIRED_FIELD;
            isValid = false;
        }
        else if (key === AMOUNT_KEY && isNaN(parseInt(formData[key]))) {
            errorMessage[key] = REQUIRED_FIELD;
            isValid = false;
        }
        else if (key === APARTMENT_KEY && !formData[key].label) {
            errorMessage[key] = REQUIRED_FIELD;
            isValid = false;
        }
        else if (key === RECEIPTS_KEY && isNaN(parseInt(formData[key]))) {
            errorMessage[key] = REQUIRED_FIELD;
            isValid = false;
        }
        else if (key === CLIENT_KEY && formData[key] === INVALID_CLIENT) {
            errorMessage[key] = INVALID_CLIENT;
            isValid = false;
        }
        else if (key === CONFIRM_PASSWORD_KEY &&
            formData[key] !== formData[NEW_PASSWORD_KEY]) {
            errorMessage[key] = INVALID_PASSWORD_CONFIRMATION;
            isValid = false;
        }
        else if (key === PASSWORD_CONFIRMATION_KEY &&
            formData[key] !== formData[PASSWORD_KEY2]) {
            errorMessage[key] = INVALID_PASSWORD_CONFIRMATION;
            isValid = false;
        }
        else if (key === CLIENT_CODE_KEY && !formData[key][0].value) {
            errorMessage[key] = REQUIRED_FIELD;
            isValid = false;
        }
        else if (key === NEW_CLIENT_CODE_KEY && !formData[key]) {
            errorMessage[key] = REQUIRED_FIELD;
            isValid = false;
        }
        else
            errorMessage[key] = '';
    });
    setErrors(errorMessage);
    return isValid;
};
export var isValidProviderForm = function (formData, setErrors) {
    var errorMessage = {};
    var isValid = true;
    Object.keys(formData).forEach(function (key) {
        if (!formData[key]) {
            errorMessage[key] = REQUIRED_FIELD;
            isValid = false;
        }
        else if (key === PHONE_KEY && !VALIDATE_PHONE.test(formData[key])) {
            errorMessage[key] = INVALID_PHONE;
            isValid = false;
        }
        else if (isNaN(parseInt(key)) && !formData[key]) {
            errorMessage[key] = REQUIRED_FIELD;
            isValid = false;
        }
    });
    setErrors(errorMessage);
    return isValid;
};
export var isValidBuildingForms = function (formData, setBuildingErrors) {
    var isValid = true;
    Object.keys(formData).forEach(function (index) {
        var errorMessage = {};
        var data = formData[index];
        Object.keys(data).forEach(function (key) {
            if (!data[key]) {
                errorMessage[key] = REQUIRED_FIELD;
                isValid = false;
            }
            else
                errorMessage[key] = '';
        });
        setBuildingErrors(function (prevState) {
            var _a;
            return __assign(__assign({}, prevState), (_a = {}, _a[index] = errorMessage, _a));
        });
    });
    return isValid;
};
export var isValidSummonForm = function (summon, setErrors) {
    var isValid = true;
    Object.keys(summon).forEach(function (key) {
        var message = '';
        var typedKey = key;
        var data = summon[typedKey];
        if (!data) {
            message = REQUIRED_FIELD;
            isValid = false;
        }
        else {
            message = '';
        }
        setErrors(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[key] = message, _a)));
        });
    });
    return isValid;
};
export var isValidMessage = function (_a) {
    var message = _a.message, building = _a.building, receiver = _a.receiver, subject = _a.subject, title = _a.title, setFormError = _a.setFormError, checkAll = _a.checkAll;
    if ((!building && (!(receiver === null || receiver === void 0 ? void 0 : receiver.length) || receiver !== ALL_ENG)) && (!building && !checkAll)) {
        setFormError('El mensaje debe tener algún destinatario.');
        return false;
    }
    else if (!subject) {
        setFormError('El mensaje debe tener un asunto.');
        return false;
    }
    else if (!title) {
        setFormError('El mensaje debe tener un título.');
        return false;
    }
    else if (!message) {
        setFormError('El mensaje no puede estar vacío.');
        return false;
    }
    return true;
};
