var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { AdminLayout } from 'src/Layouts';
import { FilterTable } from 'src/Components/Common';
import { BUILDING_API, CREATE_RESERVATION, RESERVATIONS, createData, getData, } from 'src/Utils';
import { WIDTHS, createBuilding, handleCells, handleAmenityCurrency, handleState, } from './utils';
import { DATE_FORMAT_MOMENT, RESERVATION_API, ROUTES, } from '../../../Utils/constants';
import useTowerAndApartmentData from './hooks/useTowerAndApartmentData';
import { Container, Title } from './styles';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setFormSelected, setSelectedApartment, setSelectedBuilding, setSelectedEndDate, setSelectedStartDate, setSelectedTower, } from '../../../redux/slices/tableFiltersSlice';
import Modal from '../../ReservationDetails/Modal/Modal';
import { cancelReservation } from '../../ReservationDetails/services';
var ReservationPage = function () {
    var dispatch = useAppDispatch();
    var history = useHistory();
    var _a = useAppSelector(function (state) { return state.tableFiltersSlice; }), selectedBuilding = _a.selectedBuilding, selectedApartment = _a.selectedApartment, startDate = _a.startDate, endDate = _a.endDate;
    var _b = useState([]), rows = _b[0], setRows = _b[1];
    var _c = useState(), buildingList = _c[0], setBuildingList = _c[1];
    var _d = useState(false), isModalOpen = _d[0], setIsModalOpen = _d[1];
    var _e = useState(), bookingIdToDelete = _e[0], setBookingIdToDelete = _e[1];
    var _f = useState(), bookingInfoToDelete = _f[0], setBookingInfoToDelete = _f[1];
    var _g = useState(false), reloadBookings = _g[0], setReloadBookings = _g[1];
    var _h = useTowerAndApartmentData({
        selectedBuilding: selectedBuilding,
    }), towerList = _h.towerList, apartmentData = _h.apartmentData, selectedTower = _h.selectedTower;
    var mutateCancelReservation = useMutation(cancelReservation, {
        onSuccess: function () { return setReloadBookings(!reloadBookings); },
    })[0];
    useQuery(BUILDING_API, function () { return getData('/buildings'); }, {
        refetchOnWindowFocus: false,
        onSuccess: function (data) {
            var buildings = data.buildings
                .map(function (_a) {
                var name = _a.name, id = _a.id, code = _a.code;
                return createBuilding(name, id, rows, code);
            })
                .sort(function (a, b) { return a.label.localeCompare(b.label, 'es'); });
            setBuildingList(buildings);
        },
    });
    var _j = useQuery([
        RESERVATION_API,
        selectedBuilding,
        startDate,
        endDate,
        selectedApartment,
        selectedTower,
        reloadBookings,
    ], function () {
        return getData("/bookings", {
            building_id: selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value,
            start_date: startDate
                ? moment(startDate).format(DATE_FORMAT_MOMENT)
                : null,
            end_date: endDate ? moment(endDate).format(DATE_FORMAT_MOMENT) : null,
            apartment_id: (selectedApartment === null || selectedApartment === void 0 ? void 0 : selectedApartment.value) || undefined,
            tower_id: (selectedTower === null || selectedTower === void 0 ? void 0 : selectedTower.label) !== 'Ninguna' ? selectedTower === null || selectedTower === void 0 ? void 0 : selectedTower.value : undefined,
        });
    }, {
        onSuccess: function (data) {
            if (!data.bookings[0])
                setRows([]);
            else
                data.bookings.sort(function (a, b) {
                    return moment(b.created_at).diff(moment(a.created_at));
                });
            setRows(data.bookings.map(function (_a) {
                var day = _a.day, schedule = _a.schedule, price = _a.price, amenity_name = _a.amenity_name, apartment_number = _a.apartment_number, currency = _a.currency, id = _a.id, status = _a.status;
                return (__assign(__assign({}, createData(amenity_name, apartment_number, moment(day).format(DATE_FORMAT_MOMENT), handleState(status), schedule, handleAmenityCurrency(currency) + " " + price)), { ID: id }));
            }));
        },
        enabled: selectedBuilding,
        refetchOnWindowFocus: false,
    }), isLoadingReservations = _j.isLoading, refetch = _j.refetch;
    var buttonDisabled = !(selectedBuilding &&
        selectedTower &&
        selectedApartment &&
        selectedApartment.value !== '');
    var selectBuilding = function (building) {
        dispatch(setSelectedBuilding(building));
        dispatch(setFormSelected(false));
    };
    var selectTower = function (tower) {
        dispatch(setSelectedTower(tower));
        dispatch(setFormSelected(false));
    };
    var selectApartment = function (apartment) {
        dispatch(setSelectedApartment(apartment));
        dispatch(setFormSelected(false));
    };
    var selectStartDate = function (startDate) {
        return dispatch(setSelectedStartDate(startDate));
    };
    var selectEndDate = function (endDate) {
        return dispatch(setSelectedEndDate(endDate));
    };
    var handleCancel = function (id) {
        var booking = rows.find(function (_a) {
            var ID = _a.ID;
            return ID === id;
        });
        setBookingInfoToDelete((booking === null || booking === void 0 ? void 0 : booking.first) + ": " + (booking === null || booking === void 0 ? void 0 : booking.third) + ", " + (booking === null || booking === void 0 ? void 0 : booking.fifth));
        setBookingIdToDelete(String(id));
        setIsModalOpen(true);
    };
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, null, RESERVATIONS),
            React.createElement(FilterTable, { rows: rows, building: null, headCells: handleCells(), buildingList: buildingList, towerList: towerList, apartmentList: apartmentData, width: WIDTHS, userWidth: WIDTHS, handleButtonClick: function () {
                    return history.push({
                        pathname: ROUTES.create_reservation,
                    });
                }, hasButton: true, startDate: startDate, setStartDate: selectStartDate, endDate: endDate, setEndDate: selectEndDate, hasDateFilter: true, isLoading: isLoadingReservations, selectedBuilding: selectedBuilding, setSelectedBuilding: selectBuilding, modalButton: CREATE_RESERVATION, refetch: refetch, selectedTower: selectedTower, setSelectedTower: selectTower, selectedApartment: selectedApartment, setSelectedApartment: selectApartment, isPaymentHistory: true, buttonDisabled: buttonDisabled, handleView: function (id) {
                    return history.push(ROUTES.reservation_details.replace(':id', String(id)));
                }, handleEdit: function (id) {
                    return history.push(ROUTES.edit_reservation.replace(':id', String(id)));
                }, handleDelete: handleCancel, showDownloadPDF: true, compareDateWithoutHours: true, isBookings: true, hasDots: true })),
        React.createElement(Modal, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, handleCancel: function () { return mutateCancelReservation(bookingIdToDelete); }, description: bookingInfoToDelete })));
};
export default ReservationPage;
