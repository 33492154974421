import React from 'react';
import { useHistory } from 'react-router-dom';
import { useWindowWidth } from 'src/useWindowWidth';
import { M, ROUTES } from 'src/Utils';
import { getLocationText, getPriceText, info } from './utils';
import { Container, Img, Row, InfoContainer, Price, Code, Location, Description, InfoImg, Info, Column, ImageContainer, } from './styles';
export var PropertyCard = function (_a) {
    var property = _a.property;
    var push = useHistory().push;
    var windowWidth = useWindowWidth();
    var handleExtraInfo = function (img, info, id) { return (React.createElement(Row, { className: "align-center", gap: "6px", key: id },
        React.createElement(InfoImg, { src: img }),
        React.createElement(Info, null, info))); };
    var image = property.media_urls.length > 0 ? property.media_urls[0] : '';
    var price = getPriceText(property.price, property.currency);
    var location = getLocationText(property);
    return (React.createElement(Container, { onClick: function () {
            return push(ROUTES.real_estate_detail.replace(':property_id', String(property.id)));
        } },
        React.createElement(ImageContainer, null,
            React.createElement(Img, { src: image })),
        React.createElement(InfoContainer, null,
            React.createElement(Row, { className: "space-between" },
                React.createElement(Price, null, price),
                React.createElement(Code, null, property.code)),
            React.createElement(Column, { gap: "14px" },
                React.createElement(Location, null, location),
                React.createElement(Description, null, property.short_description)),
            React.createElement(Row, { gap: windowWidth > M ? '47px' : '12px' }, info(property).map(function (_a) {
                var img = _a.img, name = _a.name, id = _a.id;
                return handleExtraInfo(img, name, id);
            })))));
};
