import React from 'react';
import { useFormContext } from 'react-hook-form';
import { validateMail } from 'src/Utils';
import { Container, StyledInput, Label, StyledTextArea, ErrorMessage, } from './styles';
export var Input = function (_a) {
    var label = _a.label, placeholder = _a.placeholder, type = _a.type, options = _a.options, id = _a.id;
    var _b = useFormContext(), setValue = _b.setValue, watch = _b.watch, setError = _b.setError, clearErrors = _b.clearErrors, errors = _b.formState.errors;
    var validateEmailInput = function (email) {
        setValue('email', email);
        if (validateMail(email))
            clearErrors(id);
        else
            setError(id, {
                message: email.length === 0 ? options === null || options === void 0 ? void 0 : options.emptyMessage : options === null || options === void 0 ? void 0 : options.errorMessage,
            });
    };
    var validatePhoneInput = function (phone) {
        var onlyTenNumbers = phone.replace(/[^\d]/g, '').slice(0, 10);
        setValue('phone', onlyTenNumbers);
    };
    var handleOnChange = function (e) {
        if (id === 'email')
            validateEmailInput(e.target.value);
        else if (id === 'phone')
            validatePhoneInput(e.target.value);
        else
            setValue(id, e.target.value);
    };
    return (React.createElement(Container, null,
        React.createElement(Label, null,
            label,
            (options === null || options === void 0 ? void 0 : options.required) && '*'),
        id === 'query' ? (React.createElement(StyledTextArea, { placeholder: placeholder, value: watch(id), onChange: function (e) { return setValue(id, e.target.value); } })) : (React.createElement(StyledInput, { placeholder: placeholder, value: watch(id), onChange: handleOnChange, required: options === null || options === void 0 ? void 0 : options.required, error: errors[id], type: type, pattern: "/^-?\\d+\\.?\\d*$/" })),
        errors[id] && React.createElement(ErrorMessage, null, errors[id].message)));
};
