import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { AdminLayout } from 'src/Layouts';
import { Container, Title, UploadContainer, UploadButton } from '../styles';
import { includesWord } from 'src/Utils/utils';
import Table from 'src/Components/GenericTable';
import { M, NAME, LAST_NAME, CELLPHONE, MAIL, CONTACTS, NEW_CONTACT_TEXT, CREATE_CONTACT, MODIFY_CONTACT_TEXT, CONTACT_TEXT, CONTACTS_API, } from 'src/Utils/constants';
import { DeleteModal } from 'src/Components/Common';
import { useWindowWidth } from 'src/useWindowWidth';
import List from 'src/Components/Common/List/List';
import { AddIcon, ArrowRight, ArrowLeft } from 'src/assets/Images';
import { getData } from 'src/Utils/api';
import { deleteContact } from './duck';
import { IconButton, TablePagination } from '@material-ui/core';
import { ArrowsContainer, PaginationLabel, Div, Button, } from 'src/Components/Common/Table/styles';
import { ContactsTableContainer, FooterContainer, TablePaginationContainer, } from './styles';
var headCells = [
    { id: 'first', numeric: true, disablePadding: false, label: NAME },
    { id: 'lastName', numeric: true, disablePadding: false, label: LAST_NAME },
    { id: 'mail', numeric: true, disablePadding: false, label: MAIL },
    { id: 'cell', numeric: true, disablePadding: false, label: CELLPHONE },
];
var WIDTHS = ['20%', '20%', '30%', '15%'];
var ROWS_PER_PAGE = 15;
var ContactsPage = function () {
    var _a = useState(''), searchedItem = _a[0], setSearchedItem = _a[1];
    var _b = useState(false), isDeleteModalOpen = _b[0], setIsDeleteModalOpen = _b[1];
    var _c = useState([]), rows = _c[0], setRows = _c[1];
    var _d = useState(), selectedContact = _d[0], setSelectedContact = _d[1];
    var windowWidth = useWindowWidth();
    var isSmallScreen = M >= windowWidth;
    var history = useHistory();
    var _e = useState(1), page = _e[0], setPage = _e[1];
    var handleChangePage = function (event, newPage) {
        setPage(newPage + 1);
    };
    var _f = useQuery([CONTACTS_API, isDeleteModalOpen, page, searchedItem], function () { return getData("/contacts?page=" + page + "&name=" + searchedItem); }), isLoadingContacts = _f.isLoading, data = _f.data;
    useEffect(function () {
        if (data) {
            setRows(data.contacts.map(function (_a) {
                var id = _a.id, first_name = _a.first_name, last_name = _a.last_name, email = _a.email, mobile = _a.mobile;
                return ({
                    first: first_name,
                    lastName: last_name,
                    mail: email,
                    cell: mobile,
                    dots: '',
                    id: id,
                });
            }));
        }
    }, [data]);
    var mutateDeleteContact = useMutation(deleteContact, {
        onSuccess: function () { return setIsDeleteModalOpen(function (prev) { return !prev; }); },
    })[0];
    var onDelete = function (id) {
        id && mutateDeleteContact({ id: id });
    };
    function TablePaginationActions(props) {
        var count = props.count, page = props.page, rowsPerPage = props.rowsPerPage, onPageChange = props.onPageChange;
        var handleBackButtonClick = function (event) { return onPageChange(event, page - 1); };
        var handleNextButtonClick = function (event) { return onPageChange(event, page + 1); };
        return (React.createElement(Div, null,
            React.createElement(ArrowsContainer, null,
                React.createElement(IconButton, { onClick: handleBackButtonClick, disabled: !page, "aria-label": "previous page" },
                    React.createElement("img", { src: ArrowLeft, alt: "P\u00E1gina previa" })),
                React.createElement(PaginationLabel, null, page + 1 + "/" + Math.ceil(count / rowsPerPage)),
                React.createElement(IconButton, { onClick: handleNextButtonClick, disabled: page >= Math.ceil(count / rowsPerPage) - 1, "aria-label": "next page" },
                    React.createElement("img", { src: ArrowRight, alt: "P\u00E1gina siguiente" })))));
    }
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, null, CONTACTS),
            React.createElement(ContactsTableContainer, { className: "contact" },
                isSmallScreen ? (rows.map(function (row, index) {
                    return (!searchedItem ||
                        includesWord(row.first[0], searchedItem[0])) && (React.createElement(List, { data: rows, title: row.first[0], searchedItem: searchedItem, key: row.first + index }));
                })) : (React.createElement(Table, { rows: rows, headCells: headCells, handleDelete: function () { return setIsDeleteModalOpen(function (prev) { return !prev; }); }, hasDots: true, widths: WIDTHS, className: "xsmall", historyText: MODIFY_CONTACT_TEXT, shouldShowContacts: true, setContactData: setSelectedContact, isLoading: isLoadingContacts, setSearchedItem: setSearchedItem, searchedItem: searchedItem, paginationDisabled: true, rowsPerPage: ROWS_PER_PAGE })),
                React.createElement(FooterContainer, null,
                    React.createElement(TablePaginationContainer, null,
                        React.createElement(TablePagination, { component: "div", labelRowsPerPage: "", count: (data === null || data === void 0 ? void 0 : data.total_pages) * ROWS_PER_PAGE || 0, rowsPerPage: ROWS_PER_PAGE, page: page - 1, onPageChange: handleChangePage, ActionsComponent: TablePaginationActions })),
                    !isSmallScreen && (React.createElement(Button, { onClick: function () { return history.push(NEW_CONTACT_TEXT); } }, CREATE_CONTACT)))),
            React.createElement(DeleteModal, { isOpen: isDeleteModalOpen, setIsOpen: setIsDeleteModalOpen, onDelete: function () { return onDelete(selectedContact === null || selectedContact === void 0 ? void 0 : selectedContact.id); }, itemLabel: CONTACT_TEXT, toDelete: (selectedContact === null || selectedContact === void 0 ? void 0 : selectedContact.first) + " " + (selectedContact === null || selectedContact === void 0 ? void 0 : selectedContact.lastName) }),
            isSmallScreen && (React.createElement(UploadContainer, null,
                React.createElement(UploadButton, { src: AddIcon, onClick: function () { return history.push(NEW_CONTACT_TEXT); } }))))));
};
export default ContactsPage;
