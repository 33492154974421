var theme = {
    palette: {
        primary: {
            10: '#f7faff',
            14: '#e8f2ff',
            15: '#E1F4FF',
            20: '#2D5068',
            25: '#36B4FF',
            40: '#EDF4FF',
            50: '#1B435F',
            75: '#262B47',
            80: '#6C91C9',
        },
        white: {
            100: '#ffffff',
        },
        grey: {
            5: 'rgba(214, 216, 218, 0.65)',
            10: '#C4C4C4',
            15: '#F4F4FA',
            18: '#E2E6EA',
            20: '#D6D8DA',
            25: '#DCDCDC',
            30: '#5f5e5e',
            35: '#A2A2A2',
            40: '#898989',
            45: '#B1A7A7',
            50: '#a1a1a1',
            55: '#E2E2E2',
            60: '#6D6D6D',
            90: '#f4f4fb',
        },
        blue: {
            5: '#5eb2f9',
            10: '#3B5168',
        },
        yellow: '#FBD065',
        red: '#DE564C',
        green: '#15B269',
        success: {
            5: '#4FCC79',
        },
        black: {
            40: '#000100',
            60: '#141414',
            80: '#252525',
            85: '#141414',
            100: '#000000',
        },
        error: {
            50: '#E37373',
            60: '#EB5757',
        },
        admin: {
            primary: {
                10: '#FAFAFA',
                15: '#F7FAFF',
                25: '#F4F9FF',
                30: '#EFF4FF',
                35: '#DFEAFF',
                45: '#E7EFFF',
                50: '#EDF4FF',
                55: '#E8F2FF',
                65: '#CADBEF',
                75: '#6F98C9',
                80: '#5091FF',
                85: '#ff0000',
            },
        },
        shadow: {
            15: 'rgba(0, 0, 0, 0.1)',
            20: 'rgba(0, 0, 0, 0.15)',
        },
        blueShadow: {
            10: 'rgba(111, 152, 201, 0.06)',
            15: '0px 10px 30px 10px rgba(111, 152, 201, 0.06)',
            50: 'rgba(111, 152, 201, 0.19)',
        },
        blueLinearGradient: {
            50: 'linear-gradient(180deg, rgba(223, 234, 255, 0) 0%, #DFEAFF 100%)',
        },
    },
    typography: {
        normal: {
            raleway: 'Raleway',
        },
        bold: {
            ralewayBold: 'Raleway-Bold',
        },
        lusitana: 'Lusitana,serif',
        OpenSans: 'OpenSans',
        OpenSansLight: 'OpenSans-Light',
    },
};
export default theme;
