var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M } from '../../Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  min-height: 650px;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  color: ", ";\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    min-height: 800px;\n  }\n"], ["\n  display: flex;\n  min-height: 650px;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  color: ", ";\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    min-height: 800px;\n  }\n"])), theme.palette.white[100], theme.palette.primary[50], M);
export var MapContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  background-color: ", ";\n  width: 60%;\n  right: 35%;\n  height: 40vw;\n  max-height: 600px;\n  min-height: 500px;\n  border-radius: 7px;\n  z-index: 3;\n  div {\n    border-radius: 7px !important;\n  }\n  @media (max-width: ", "px) {\n    bottom: 0;\n    width: 100%;\n    right: auto;\n    max-height: 500px;\n    div {\n      border-radius: 0px;\n    }\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  background-color: ", ";\n  width: 60%;\n  right: 35%;\n  height: 40vw;\n  max-height: 600px;\n  min-height: 500px;\n  border-radius: 7px;\n  z-index: 3;\n  div {\n    border-radius: 7px !important;\n  }\n  @media (max-width: ", "px) {\n    bottom: 0;\n    width: 100%;\n    right: auto;\n    max-height: 500px;\n    div {\n      border-radius: 0px;\n    }\n  }\n"])), theme.palette.white[100], M);
export var ContentContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  justify-content: center;\n  position: absolute;\n  background-color: ", ";\n  width: 45%;\n  right: 0;\n  align-items: baseline;\n  padding: 30px;\n  height: 35vw;\n  min-height: 350px;\n  z-index: 4;\n  max-height: 400px;\n  border-radius: 7px 0px 0px 7px;\n  @media (max-width: ", "px) {\n    align-items: center;\n    min-height: 300px;\n    max-height: 300px;\n    width: 80%;\n    top: 0;\n    right: auto;\n    border-radius: 7px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  justify-content: center;\n  position: absolute;\n  background-color: ", ";\n  width: 45%;\n  right: 0;\n  align-items: baseline;\n  padding: 30px;\n  height: 35vw;\n  min-height: 350px;\n  z-index: 4;\n  max-height: 400px;\n  border-radius: 7px 0px 0px 7px;\n  @media (max-width: ", "px) {\n    align-items: center;\n    min-height: 300px;\n    max-height: 300px;\n    width: 80%;\n    top: 0;\n    right: auto;\n    border-radius: 7px;\n  }\n"])), theme.palette.primary[75], M);
export var Title = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-style: normal;\n  font-weight: 600;\n  font-size: 36px;\n  margin-bottom: 10px;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    font-family: ", ";\n    font-size: 18px;\n  }\n"], ["\n  font-style: normal;\n  font-weight: 600;\n  font-size: 36px;\n  margin-bottom: 10px;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    font-family: ", ";\n    font-size: 18px;\n  }\n"])), theme.palette.primary[75], M, theme.typography.bold.ralewayBold);
export var Sub_Title = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-style: normal;\n  font-family: ", ";\n  margin-bottom: 15px;\n  font-size: 18px;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    font-size: 16px;\n  }\n"], ["\n  font-style: normal;\n  font-family: ", ";\n  margin-bottom: 15px;\n  font-size: 18px;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    font-size: 16px;\n  }\n"])), theme.typography.bold.ralewayBold, theme.palette.primary[75], M);
export var Description = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-style: normal;\n  font-size: 16px;\n  font-weight: normal;\n  margin-bottom: 5px;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    font-size: 14px;\n    text-align: center;\n  }\n"], ["\n  font-style: normal;\n  font-size: 16px;\n  font-weight: normal;\n  margin-bottom: 5px;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    font-size: 14px;\n    text-align: center;\n  }\n"])), theme.palette.primary[75], M);
export var Content = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin-top: 20px;\n  font-weight: normal;\n  background-color: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin-top: 20px;\n  font-weight: normal;\n  background-color: ", ";\n"])), theme.palette.primary[75]);
export var StyledDiv = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-weight: normal;\n  max-width: 270px;\n  font-size: 18px;\n  align-items: center;\n  flex-wrap: wrap;\n  text-align: center;\n  border-radius: 7px;\n  color: ", ";\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    font-size: 14px;\n  }\n"], ["\n  font-weight: normal;\n  max-width: 270px;\n  font-size: 18px;\n  align-items: center;\n  flex-wrap: wrap;\n  text-align: center;\n  border-radius: 7px;\n  color: ", ";\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    font-size: 14px;\n  }\n"])), theme.palette.primary[50], theme.palette.white[100], M);
export var DivContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-top: 20px;\n  font-weight: normal;\n  width: 100%;\n  flex-wrap: wrap;\n  padding: 15px 0px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 7px;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    position: absolute;\n    bottom: -30px;\n    padding: 20px 0px;\n    width: 80%;\n  }\n"], ["\n  margin-top: 20px;\n  font-weight: normal;\n  width: 100%;\n  flex-wrap: wrap;\n  padding: 15px 0px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 7px;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    position: absolute;\n    bottom: -30px;\n    padding: 20px 0px;\n    width: 80%;\n  }\n"])), theme.palette.white[100], M);
export var StyledSpan = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin: 0;\n  &.bold {\n    font-family: ", ";\n  }\n"], ["\n  margin: 0;\n  &.bold {\n    font-family: ", ";\n  }\n"])), theme.typography.bold.ralewayBold);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
