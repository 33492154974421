import React from 'react';
import { useHistory } from 'react-router-dom';
import { Header } from 'src/Layouts';
import { RealEstateSearch } from '../RealEstateSearch';
import { Footer } from '..';
import { buildURL } from 'src/Components/RealEstateProperties/Filter/utils';
import { FilterProvider } from 'src/context/FilterContext';
import { Whatsapp } from 'src/Components/Whatsapp';
import { Container, ImageWrapper, SearchContainer } from './styles';
function Root() {
    var history = useHistory();
    var handleSearch = function (isSale, city, neighborhoods, property_type) {
        if (neighborhoods === void 0) { neighborhoods = []; }
        if (property_type === void 0) { property_type = []; }
        var stateObj = {
            operation_type: isSale ? 'purchase' : 'rent',
            currency: isSale ? 'USD' : 'UYU',
            city: city === null || city === void 0 ? void 0 : city.value,
            neighborhood: neighborhoods.map(function (neighborhood) { return neighborhood.value; }),
            property_type: property_type.map(function (property) { return property.value; }),
        };
        var url = buildURL(stateObj, 1);
        history.push(url);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, null),
        React.createElement(Container, null,
            React.createElement(ImageWrapper, null),
            React.createElement(SearchContainer, null,
                React.createElement(FilterProvider, null,
                    React.createElement(RealEstateSearch, { onSearch: handleSearch })))),
        React.createElement(Whatsapp, null),
        React.createElement(Footer, null)));
}
export default Root;
