import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { BUILDINGS, getData, NETWORK_PAYMENTS, BUILDINGS_TEXT, USERS_TEXT, PROVIDERS_TEXT, CONTACTS_TEXT, MONTHS, getMonthNumber, ALL, } from 'src/Utils';
import Chart from './Chart';
import { ApartmentsContainer, Container, InformationContainer, Number, } from './styles';
var renderData = function (title, value) { return (React.createElement(InformationContainer, null,
    React.createElement(Number, null, value),
    title)); };
var SuperAdminDashboard = function () {
    var _a = useState(), statusReport = _a[0], setStatusReport = _a[1];
    var _b = useState(), paymentsReport = _b[0], setPaymentsReport = _b[1];
    var _c = useState(), total = _c[0], setTotal = _c[1];
    var _d = useState({ label: 'Todos', value: 'Todos' }), selectedPaymentNetwork = _d[0], setSelectedPaymentNetwork = _d[1];
    var _e = useState(new Date().getMonth() + 1), month = _e[0], setMonth = _e[1];
    var systemData = useQuery(['getSystemData'], function () { return getData("/reports/system"); }, {
        refetchOnWindowFocus: false,
    }).data;
    var searchResults = function (selectedPaymentNetwork, month) {
        if (selectedPaymentNetwork.label === ALL) {
            return getData("/reports/payment_status_amount", {
                month: month,
            });
        }
        else {
            return getData("/reports/payment_status_amount", {
                payment_method: selectedPaymentNetwork.value,
                month: month,
            });
        }
    };
    useQuery(['getStatusReport', month, selectedPaymentNetwork], function () { return searchResults(selectedPaymentNetwork, month); }, {
        onSuccess: function (_a) {
            var common_expenses_receipts_pending_amount = _a.common_expenses_receipts_pending_amount, common_expenses_receipt_in_progress_amount = _a.common_expenses_receipt_in_progress_amount, common_expenses_receipt_informed_amount = _a.common_expenses_receipt_informed_amount;
            setStatusReport([
                {
                    label: 'Pendiente',
                    UYU: common_expenses_receipts_pending_amount.pesos,
                },
                {
                    label: 'En proceso',
                    UYU: common_expenses_receipt_in_progress_amount.pesos,
                },
                {
                    label: 'Informado',
                    UYU: common_expenses_receipt_informed_amount.pesos,
                },
            ]);
        },
        refetchOnWindowFocus: false,
    });
    useQuery(['getPaymentsReport'], function () { return getData("/reports/payment_building"); }, {
        onSuccess: function (_a) {
            var quantity_per_month = _a.quantity_per_month;
            var report = [];
            var periodTotalUYU = 0;
            var periodTotalDOL = 0;
            for (var key in quantity_per_month) {
                periodTotalUYU += quantity_per_month[key].pesos;
                periodTotalDOL += quantity_per_month[key].dólares;
                report.push({
                    label: MONTHS[getMonthNumber(key, month) - 1],
                    month: getMonthNumber(key, month),
                    UYU: quantity_per_month[key].pesos,
                    DOL: quantity_per_month[key].dólares,
                });
            }
            setPaymentsReport(report.reverse());
            setTotal({ UYU: periodTotalUYU });
        },
        refetchOnWindowFocus: false,
    });
    return (React.createElement(Container, null,
        React.createElement(Container, { className: "row" },
            React.createElement(Chart, { title: NETWORK_PAYMENTS, isPayments: true, month: month, data: statusReport, selectedPaymentNetwork: selectedPaymentNetwork, setSelectedPaymentNetwork: setSelectedPaymentNetwork }),
            React.createElement(Chart, { data: paymentsReport, total: total, title: BUILDINGS, month: month, setMonth: setMonth })),
        systemData && (React.createElement(ApartmentsContainer, null,
            renderData(USERS_TEXT, systemData.report.admin_count),
            renderData(BUILDINGS_TEXT, systemData.report.buildings_count),
            renderData(PROVIDERS_TEXT, systemData.report.providers_count),
            renderData(CONTACTS_TEXT, systemData.report.contacts_count)))));
};
export default SuperAdminDashboard;
