import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Popover } from '..';
import { DotsImg } from '../../Common/Table/TableRow/styles';
import { MODIFY, DELETE, M } from 'src/Utils/constants';
import { BlackDots, Dots } from 'src/assets/Images';
import { useWindowWidth } from 'src/useWindowWidth';
var DotsComponent = function (_a) {
    var handleDelete = _a.handleDelete, historyText = _a.historyText;
    var _b = useState(null), anchorRef = _b[0], setAnchorRef = _b[1];
    var history = useHistory();
    var windowWidth = useWindowWidth();
    var isSmallScreen = M >= windowWidth;
    var handleClick = function (e) {
        return anchorRef ? setAnchorRef(null) : setAnchorRef(e.currentTarget);
    };
    var handleOptionClick = function (_a) {
        var label = _a.label;
        switch (label) {
            case DELETE:
                handleDelete && handleDelete();
                break;
            case MODIFY:
                historyText && history.push(historyText);
                break;
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(DotsImg, { src: isSmallScreen ? BlackDots : Dots, alt: "M\u00E1s", onClick: handleClick }),
        React.createElement(Popover, { data: historyText
                ? [
                    { label: MODIFY, value: MODIFY },
                    { label: DELETE, value: DELETE },
                ]
                : [{ label: DELETE, value: DELETE }], handleOutsideClick: function () { return setAnchorRef(null); }, anchorRef: anchorRef, setSelected: handleOptionClick, isFilter: false })));
};
export default DotsComponent;
