import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { AdminLayout } from 'src/Layouts';
import { Container, Title, DetailsContainer } from './styles';
import { NAME, PHONE, EDIT_PERSONAL_DETAILS, MODIFY, LAST_NAME, PHONE_KEY, LAST_NAME_KEY, NAME_KEY, } from 'src/Utils';
import usePersonalInfo from './usePersonalInfo';
import { ROUTES, ADDRESS_KEY, LOCATION, EMAIL_KEY, EMAIL, } from 'src/Utils/constants';
import { AdminForm } from 'src/Components/Common';
import { submitEditedDataForm } from './duck';
var EditInfo = function () {
    var history = useHistory();
    var _a = usePersonalInfo(), data = _a.data, isLoading = _a.isLoading, isSuccess = _a.isSuccess;
    var _b = useState(''), backError = _b[0], setBackError = _b[1];
    var _c = useState([]), personalData = _c[0], setPersonalData = _c[1];
    var mutateEditPersonalInfo = useMutation(submitEditedDataForm, {
        onSuccess: function () { return history.push(ROUTES.account); },
    })[0];
    var onSubmit = function (data) {
        return mutateEditPersonalInfo({
            first_name: data[NAME_KEY],
            last_name: data[LAST_NAME_KEY],
            mobile: data[PHONE_KEY],
            address: data[ADDRESS_KEY],
            email: data[EMAIL_KEY],
        });
    };
    useEffect(function () {
        isSuccess &&
            setPersonalData([
                { key: NAME_KEY, label: NAME, value: data[NAME_KEY] },
                { key: LAST_NAME_KEY, label: LAST_NAME, value: data[LAST_NAME_KEY] },
                { key: EMAIL_KEY, label: EMAIL, value: data[EMAIL_KEY] },
                { key: PHONE_KEY, label: PHONE, value: data[PHONE_KEY] },
                { key: ADDRESS_KEY, label: LOCATION, value: data[ADDRESS_KEY] },
            ]);
    }, [data, isSuccess]);
    var onCancel = function () { return history.push(ROUTES.account); };
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, null, EDIT_PERSONAL_DETAILS),
            React.createElement(DetailsContainer, { className: "form" },
                React.createElement(AdminForm, { inputs: personalData, onSubmit: onSubmit, buttonLabel: MODIFY, onCancel: onCancel, isLoading: isLoading, backError: backError })))));
};
export default EditInfo;
