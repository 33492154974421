import api from 'src/Utils/api';
export var submitFile = function (_a) {
    var file = _a.file, name = _a.name, buildings = _a.buildings;
    var formData = new FormData();
    formData.append('pdf', file);
    formData.append('name', name);
    formData.append('buildings', JSON.stringify(buildings));
    return api.post('/documents', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
