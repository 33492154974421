var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { TimePicker } from 'antd';
export var HourPickerContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-self: center;\n  gap: 40px;\n"], ["\n  width: 100%;\n  display: flex;\n  justify-self: center;\n  gap: 40px;\n"])));
export var TimePickerContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-self: center;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-self: center;\n  align-items: center;\n"])));
export var Text = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 14px;\n  color: #000;\n  margin-bottom: 4px;\n"], ["\n  font-size: 14px;\n  color: #000;\n  margin-bottom: 4px;\n"])));
export var ErrorMessage = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 10px;\n  color: red;\n  font-size: 12px;\n"], ["\n  margin-top: 10px;\n  color: red;\n  font-size: 12px;\n"])));
export var TimePickerStyled = styled(TimePicker)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  .ant-time-picker-icon {\n    z-index: 0;\n  }\n"], ["\n  .ant-time-picker-icon {\n    z-index: 0;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
