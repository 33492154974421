import React, { useState } from "react";
import { RealEstateClosedArrow, RealEstateOpenArrow } from "src/assets/Images";
import { DropdownContainer, Text, Image } from './styles';
var FilterDropdown = function (_a) {
    var children = _a.children, title = _a.title;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(DropdownContainer, { onClick: function () { return setIsOpen(!isOpen); } },
            React.createElement(Text, null, title),
            React.createElement("div", null,
                React.createElement(Image, { src: !isOpen ? RealEstateOpenArrow : RealEstateClosedArrow, alt: "open icon" }))),
        isOpen && children));
};
export default FilterDropdown;
