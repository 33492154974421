import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Content, Title, ContentDiv, Description, ContentContainer, ButtonContainer, Button, Label, DeleteButton, Image } from './styles';
import { BUILDINGS, DELETE, MODIFY, PHONE, TYPE_OF_SERVICE } from 'src/Utils';
import { Edit } from 'src/assets/Images';
var ProviderCard = function (_a) {
    var first = _a.first, second = _a.second, third = _a.third, fourth = _a.fourth, handleDelete = _a.handleDelete, historyText = _a.historyText;
    var history = useHistory();
    return (React.createElement(Container, null,
        React.createElement(Title, null, first),
        React.createElement(Content, null,
            React.createElement(ContentContainer, null,
                React.createElement(ContentDiv, null,
                    React.createElement(Description, null, TYPE_OF_SERVICE),
                    React.createElement(Description, { className: "bold" }, second)),
                React.createElement(ContentDiv, null,
                    React.createElement(Description, null, BUILDINGS),
                    React.createElement(Description, { className: "bold" }, third)),
                React.createElement(ContentDiv, null,
                    React.createElement(Description, null, PHONE),
                    React.createElement(Description, { className: "bold" }, fourth))),
            React.createElement(ButtonContainer, null,
                React.createElement(DeleteButton, { onClick: handleDelete }, DELETE),
                React.createElement(Button, { onClick: function () { return history.push(historyText); } },
                    React.createElement(Label, null, MODIFY),
                    React.createElement(Image, { src: Edit }))))));
};
export default ProviderCard;
