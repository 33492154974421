import React, { useState, useReducer, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { openedArrow } from 'src/assets/Images';
import { AMOUNT_KEY, AMOUNT_TEXT, APARTMENT, APARTMENT_KEY, BUILDING, BUILDING_KEY, CANCEL, CLIENT_CODE, CLIENT_KEY, CURRENCY_KEY, DATE_KEY, DESCRIPTION, DESCRIPTION_KEY, EXPIRATION_DATE, INVALID_CLIENT, ROUTES, TOWER, TOWER_KEY, APT, APT_KEY, RECEIPTS_KEY, } from 'src/Utils/constants';
import { CURRENCY_DATA, API_DATA } from 'src/Utils/mockData';
import { hasError, isValidForm } from 'src/Utils/validations';
import InputComponent from '../Common/InputComponent';
import { DetailsContainer, Div, FormContainer, FormsContainer, FormsDiv, InputContainer, Label, Error, CandelarContainer, BottomInputs, ButtonsContainer, Link, Input, Img, DivImg, Index, ButtonContainer, Success, RowContainer, } from './styles';
import { Popover, TextInput } from '../Common';
import { AdminButton } from '../Common/AdminButton';
import { CustomInput } from './CustomInput';
import { init, reducer } from './Reducer';
import CalendarComponent from '../Common/Calendar';
import { useAppSelector } from '../../redux/hooks';
var fixedDate = new Date();
fixedDate.setDate(25);
var EditReceiptForm = function (_a) {
    var _b, _c, _d;
    var onSubmit = _a.onSubmit, backError = _a.backError, _e = _a.initialValues, initialValues = _e === void 0 ? {
        address: '1',
        apartment_number: 1,
        building: {
            label: '',
            value: '',
        },
        client_code: '',
        currency: 'UYU',
        description: '',
        expiration_date: fixedDate,
        receipt_number: 0,
        tower: {},
        numeric_client_code: '',
        id: 1,
        amount: 0,
    } : _e, buttonLabel = _a.buttonLabel, receiptInfo = _a.receiptInfo, success = _a.success, setSuccess = _a.setSuccess;
    var selectedBuilding = useAppSelector(function (state) { return state.tableFiltersSlice; }).selectedBuilding;
    var history = useHistory();
    var _f = useState({}), errorsSubmit = _f[0], setErrorsSubmit = _f[1];
    var _g = useReducer(reducer, initialValues, init), _h = _g[0], address = _h.address, _j = _h.amount, amount = _j === void 0 ? initialValues.amount : _j, apartment = _h.apartment, _k = _h.currency, currency = _k === void 0 ? initialValues.currency : _k, _l = _h.description, description = _l === void 0 ? initialValues.description : _l, _m = _h.expirationDate, expirationDate = _m === void 0 ? initialValues.expiration_date : _m, _o = _h.receiptNumber, receiptNumber = _o === void 0 ? initialValues.receipt_number : _o, tower = _h.tower, dispatch = _g[1];
    var _p = useState(null), anchorRefCurrency = _p[0], setAnchorRefCurrency = _p[1];
    var _q = useState(initialValues.numeric_client_code), clientCode = _q[0], setClientCode = _q[1];
    var _r = useState(null), anchorRefApt = _r[0], setAnchorRefApt = _r[1];
    var _s = useState((_b = receiptInfo === null || receiptInfo === void 0 ? void 0 : receiptInfo.rows) === null || _b === void 0 ? void 0 : _b.map(function (receipt) { return receipt.ID; })), receipts = _s[0], setReceipts = _s[1];
    var onCancel = function () { return history.push(ROUTES.receipt); };
    var handleClick = function (e) {
        var currentValue = e.currentTarget;
        if (anchorRefCurrency)
            handleOutsideClick();
        setAnchorRefCurrency(currentValue);
        if (anchorRefApt)
            handleOutsideClick();
        setAnchorRefCurrency(currentValue);
    };
    var handleOutsideClick = function () {
        setAnchorRefCurrency(null);
        setAnchorRefApt(null);
    };
    useEffect(function () {
        dispatch({ type: AMOUNT_KEY, payload: initialValues.amount });
        dispatch({ type: DATE_KEY, payload: initialValues.expiration_date });
        setClientCode(initialValues.numeric_client_code);
        dispatch({ type: RECEIPTS_KEY, payload: initialValues.receipt_number });
        dispatch({ type: CURRENCY_KEY, payload: initialValues.currency });
        dispatch({ type: DESCRIPTION_KEY, payload: initialValues.description });
        setSuccess('');
    }, [initialValues]);
    var handleNextReceipt = function () {
        var index = receipts === null || receipts === void 0 ? void 0 : receipts.indexOf(initialValues.id);
        index++;
        if (index === receipts.length - 1) {
            index = 0;
        }
        history.push({
            pathname: ROUTES.edit_receipt + "/" + receipts[index],
            state: {
                rows: receiptInfo === null || receiptInfo === void 0 ? void 0 : receiptInfo.rows,
            },
        });
    };
    var handlePreviousReceipt = function () {
        var index = receipts === null || receipts === void 0 ? void 0 : receipts.indexOf(initialValues.id);
        if (index === 0) {
            index = (receipts === null || receipts === void 0 ? void 0 : receipts.length) - 1;
        }
        if (index > 0) {
            history.push({
                pathname: ROUTES.edit_receipt + "/" + receipts[index - 1],
                state: {
                    rows: receiptInfo === null || receiptInfo === void 0 ? void 0 : receiptInfo.rows,
                },
            });
        }
    };
    var handleSubmit = function () {
        if ((clientCode === null || clientCode === void 0 ? void 0 : clientCode.length) !== 12)
            errorsSubmit[CLIENT_KEY] = INVALID_CLIENT;
        else if (!errorsSubmit[CLIENT_KEY])
            errorsSubmit[CLIENT_KEY] = clientCode;
        errorsSubmit[DATE_KEY] = expirationDate;
        errorsSubmit[AMOUNT_KEY] = amount;
        if (isValidForm(errorsSubmit, setErrorsSubmit)) {
            onSubmit(clientCode, address, apartment.value, tower, receiptNumber, expirationDate, amount, currency.value, description, selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.label);
        }
    };
    var handleOnChange = function (key) { return function (e) {
        e.persist();
        if (key === CLIENT_KEY) {
            dispatch({ type: key, payload: e.target.value.toUpperCase() });
        }
        else
            dispatch({ type: key, payload: e.target.value });
    }; };
    var handleDispatch = function (item, key) {
        dispatch({ type: key, payload: item });
    };
    var getAptDescription = function (value) { var _a; return (_a = API_DATA.find(function (x) { return x.value == value; })) === null || _a === void 0 ? void 0 : _a.label; };
    var renderPopOver = function (data, anchorRef, type) { return (React.createElement(Popover, { data: data, handleOutsideClick: handleOutsideClick, anchorRef: anchorRef, setSelected: handleDispatch, isFilter: false, type: type })); };
    return (React.createElement(React.Fragment, null,
        React.createElement(RowContainer, null,
            React.createElement(FormsContainer, null,
                React.createElement(FormsDiv, null,
                    React.createElement(DetailsContainer, null,
                        React.createElement(FormContainer, null,
                            React.createElement(InputComponent, { disabled: true, type: CLIENT_KEY, data: clientCode, error: errorsSubmit, label: CLIENT_CODE, onChangeFunction: handleOnChange(CLIENT_KEY) }),
                            React.createElement(InputComponent, { disabled: true, label: APT, type: APT_KEY, data: getAptDescription(initialValues.client_code.slice(8)), error: errorsSubmit }),
                            React.createElement(InputComponent, { disabled: true, type: BUILDING_KEY, data: (_c = selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.label) === null || _c === void 0 ? void 0 : _c.toString(), error: errorsSubmit, label: BUILDING, onChangeFunction: null }),
                            React.createElement(InputComponent, { disabled: true, type: TOWER_KEY, data: initialValues.tower.name, error: errorsSubmit, label: TOWER, onChangeFunction: null }),
                            React.createElement(InputComponent, { disabled: true, type: APARTMENT_KEY, data: (_d = initialValues.apartment_number) === null || _d === void 0 ? void 0 : _d.toString(), error: errorsSubmit, label: APARTMENT, onChangeFunction: null }))),
                    React.createElement(DetailsContainer, null,
                        React.createElement(FormContainer, null,
                            React.createElement(InputContainer, { className: "height" },
                                React.createElement(Label, null, EXPIRATION_DATE),
                                React.createElement(CalendarComponent, { selected: expirationDate, onChange: function (e) {
                                        return dispatch({
                                            type: DATE_KEY,
                                            payload: e,
                                        });
                                    }, customInput: React.createElement(CustomInput, null) }),
                                React.createElement(Div, null,
                                    React.createElement(Error, null, errorsSubmit[DATE_KEY]))),
                            React.createElement(InputContainer, { className: "height" },
                                React.createElement(Label, null, AMOUNT_TEXT),
                                React.createElement(CandelarContainer, null,
                                    React.createElement(TextInput, { onChange: handleOnChange(AMOUNT_KEY), value: amount === null || amount === void 0 ? void 0 : amount.toString(), hasError: hasError(AMOUNT_KEY, errorsSubmit), className: 'border-admin', style: { width: '100%' } }),
                                    React.createElement(Input, null, initialValues.currency),
                                    React.createElement(DivImg, { onClick: function (e) { return handleClick(e); } },
                                        React.createElement(Img, { src: openedArrow, className: "receipt" }))),
                                React.createElement(Div, null,
                                    React.createElement(Error, null, errorsSubmit[AMOUNT_KEY]))),
                            React.createElement(InputComponent, { type: DESCRIPTION_KEY, data: description, error: errorsSubmit, label: DESCRIPTION, onChangeFunction: handleOnChange(DESCRIPTION_KEY) })),
                        success && React.createElement(Success, null, success))),
                React.createElement(BottomInputs, null,
                    React.createElement(ButtonsContainer, null,
                        React.createElement(AdminButton, { type: "button", className: "height", onClick: handleSubmit }, buttonLabel),
                        React.createElement(Link, { onClick: onCancel }, CANCEL)))),
            React.createElement(Index, null,
                React.createElement(AdminButton, { type: "button", className: "height", onClick: handleNextReceipt }, 'Siguiente'),
                React.createElement(ButtonContainer, null,
                    React.createElement(AdminButton, { type: "button", className: "height", onClick: handlePreviousReceipt }, 'Anterior')))),
        renderPopOver(CURRENCY_DATA, anchorRefCurrency, CURRENCY_KEY)));
};
export default EditReceiptForm;
