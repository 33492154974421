var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useFormContext } from 'react-hook-form';
import { AMENITIES_API, AVAILABILITY_API, getData } from 'src/Utils';
import { formatDate } from 'src/Views/CreateReservation/utils';
var useAmenitiesAndSchedules = function (_a) {
    var buildingId = _a.buildingId, amenityId = _a.amenityId, date = _a.date, scheduleId = _a.scheduleId, bookingId = _a.bookingId;
    var _b = useState([]), amenities = _b[0], setAmenities = _b[1];
    var _c = useState([]), schedules = _c[0], setSchedules = _c[1];
    var _d = useFormContext(), setValue = _d.setValue, watch = _d.watch;
    useQuery([AMENITIES_API, buildingId], function () {
        return getData('/amenities', {
            building_id: buildingId,
        });
    }, {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: !!buildingId,
        onSuccess: function (_a) {
            var amenities = _a.amenities;
            setAmenities(amenities.map(function (_a) {
                var name = _a.name, id = _a.id, currency = _a.currency, price = _a.price, availability = _a.availability;
                return ({
                    label: name,
                    value: id,
                    currency: currency,
                    price: price,
                    availability: availability,
                });
            }));
            setValue('extras', amenities.map(function (_a) {
                var name = _a.name, extras = _a.extras, currency = _a.currency;
                return ({
                    name: name,
                    extras: extras.map(function (extra) { return (__assign(__assign({}, extra), { selected: false, currency: currency })); }),
                });
            }));
        },
    });
    useQuery([AVAILABILITY_API, amenityId, date, scheduleId], function () {
        return getData("/amenities/" + amenityId + "/availability?booking_id=" + bookingId, {
            date: formatDate(date),
        });
    }, {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: !!amenityId && !!date && !!scheduleId,
        onSuccess: function (_a) {
            var schedules = _a.schedules, hours_limit = _a.hours_limit;
            setSchedules(schedules.reduce(function (acc, _a) {
                var time = _a.time, available = _a.available, id = _a.id, price = _a.price, occupied_times = _a.occupied_times, blocks_amenity = _a.blocks_amenity, price_per_use = _a.price_per_use;
                if (available || id === scheduleId)
                    acc.push({
                        label: time,
                        value: id,
                        price: price,
                        occupiedTimes: occupied_times,
                        blocksAmenity: blocks_amenity,
                        pricePerUse: price_per_use,
                    });
                return acc;
            }, []));
            var hoursLimit = hours_limit || -1;
            var lastAmenity = watch('amenity');
            setValue('amenity', __assign(__assign({}, lastAmenity), { hoursLimitPerBooking: hoursLimit }));
        },
    });
    return { amenities: amenities, schedules: schedules };
};
export default useAmenitiesAndSchedules;
