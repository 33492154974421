var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme } from '../../../Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  background-color: ", ";\n  padding: 35px;\n  height: 100%;\n"], ["\n  width: 100%;\n  background-color: ", ";\n  padding: 35px;\n  height: 100%;\n"])), theme.palette.admin.primary[50]);
export var RowContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n  width: fit-content;\n  &.card {\n    border-radius: 9px;\n    background-color: ", ";\n    width: 100%;\n    padding: 15px 0px;\n    align-items: baseline;\n    margin-top: 15px;\n  }\n  &.close {\n    align-items: center;\n    flex-direction: row;\n    margin: 35px 0px;\n    cursor: pointer;\n  }\n  &.contact {\n    margin-left: 28px;\n    margin-top: 20px;\n    margin-bottom: 0px;\n    cursor: pointer;\n    align-self: baseline;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n  width: fit-content;\n  &.card {\n    border-radius: 9px;\n    background-color: ", ";\n    width: 100%;\n    padding: 15px 0px;\n    align-items: baseline;\n    margin-top: 15px;\n  }\n  &.close {\n    align-items: center;\n    flex-direction: row;\n    margin: 35px 0px;\n    cursor: pointer;\n  }\n  &.contact {\n    margin-left: 28px;\n    margin-top: 20px;\n    margin-bottom: 0px;\n    cursor: pointer;\n    align-self: baseline;\n  }\n"])), theme.palette.white[100]);
export var Text = styled.label(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 14px;\n  margin-top: 5px;\n  text-align: left;\n  margin-left: 15px;\n  font-family: ", ";\n  &.title {\n    font-size: 11px;\n    margin-top: 0px;\n    font-family: ", ";\n  }\n"], ["\n  font-size: 14px;\n  margin-top: 5px;\n  text-align: left;\n  margin-left: 15px;\n  font-family: ", ";\n  &.title {\n    font-size: 11px;\n    margin-top: 0px;\n    font-family: ", ";\n  }\n"])), theme.typography.normal.raleway, theme.typography.bold.ralewayBold);
export var ContactText = styled.label(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 12px;\n  text-align: left;\n  margin-left: 5px;\n  cursor: pointer;\n  font-family: ", ";\n"], ["\n  font-size: 12px;\n  text-align: left;\n  margin-left: 5px;\n  cursor: pointer;\n  font-family: ", ";\n"])), theme.typography.normal.raleway);
export var Label = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 16px;\n  margin-top: 14px;\n  margin-bottom: 35px;\n  text-align: left;\n  font-family: ", ";\n  font-weight: 600;\n"], ["\n  font-size: 16px;\n  margin-top: 14px;\n  margin-bottom: 35px;\n  text-align: left;\n  font-family: ", ";\n  font-weight: 600;\n"])), theme.typography.OpenSans);
export var Center = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n"], ["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n"])));
export var ButtonContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: 30px;\n  display: flex;\n  justify-content: flex-end;\n"], ["\n  margin-top: 30px;\n  display: flex;\n  justify-content: flex-end;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
