import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { Button } from 'src/Components/Common';
import { APPLY, CLEAR_FILTERS } from 'src/Utils';
import usePaginator from 'src/context/PaginatorContext';
import { buildURL } from '../utils';
import useResetFilters from '../useResetFilter';
import { ButtonContainer } from './styles';
var ActionButtons = function (_a) {
    var setIsOpen = _a.setIsOpen;
    var watch = useFormContext().watch;
    var currentPage = usePaginator().currentPage;
    var history = useHistory();
    var resetFilters = useResetFilters().resetFilters;
    var watchedFields = watch();
    var redirect = function () {
        var newURL = buildURL(watchedFields, currentPage);
        history.push(newURL);
        setIsOpen(false);
    };
    return (React.createElement(ButtonContainer, null,
        React.createElement(Button, { onClick: redirect }, APPLY),
        React.createElement(Button, { onClick: resetFilters, className: "red" }, CLEAR_FILTERS)));
};
export default ActionButtons;
