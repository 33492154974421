import React, { useContext, createContext } from 'react';
import { useQuery } from 'react-query';
import { getData } from 'src/Utils';
var initialState = {
    defaultValues: {
        neighborhood: [],
        city: [],
        property_type: [],
    },
    isLoading: false,
};
var FilterContext = createContext(initialState);
var useFilter = function () { return useContext(FilterContext); };
export default useFilter;
export var FilterProvider = function (_a) {
    var children = _a.children;
    var _b = useQuery({
        queryKey: ['configuration'],
        queryFn: function () { return getData('real_estate/configuration'); },
    }), config = _b.data, isLoading = _b.isLoading;
    var neighborhood = (config === null || config === void 0 ? void 0 : config.neighborhoods) || [];
    var city = (config === null || config === void 0 ? void 0 : config.cities) || [];
    var property_type = (config === null || config === void 0 ? void 0 : config.property_types) || [];
    var defaultValues = {
        neighborhood: neighborhood,
        city: city,
        property_type: property_type,
    };
    return (React.createElement(FilterContext.Provider, { value: {
            defaultValues: defaultValues,
            isLoading: isLoading,
        } }, children));
};
