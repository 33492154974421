var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, L, M, XS } from '../../../Utils';
import { SearchIcon } from 'src/assets/Images';
import { Select, InputBase } from '@material-ui/core';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  margin-bottom: 35px;\n  align-items: flex-end;\n  justify-content: space-between;\n  @media (max-width: ", "px) {\n    max-width: 365px;\n    width: 90%;\n    &.column {\n      flex-direction: column;\n    }\n  }\n  &.contact {\n    margin-bottom: 25px;\n    @media (max-width: ", "px) {\n      width: 95%;\n      margin-bottom: 35px;\n      max-width: inherit;\n      @media (max-width: ", "px) {\n        width: 90%;\n      }\n    }\n  }\n  ", ";\n"], ["\n  display: flex;\n  width: 100%;\n  margin-bottom: 35px;\n  align-items: flex-end;\n  justify-content: space-between;\n  @media (max-width: ", "px) {\n    max-width: 365px;\n    width: 90%;\n    &.column {\n      flex-direction: column;\n    }\n  }\n  &.contact {\n    margin-bottom: 25px;\n    @media (max-width: ", "px) {\n      width: 95%;\n      margin-bottom: 35px;\n      max-width: inherit;\n      @media (max-width: ", "px) {\n        width: 90%;\n      }\n    }\n  }\n  ",
    ";\n"])), M, M, XS, function (_a) {
    var isContact = _a.isContact;
    return isContact
        ? "justify-content: flex-end;\n         margin-top:15px;\n         @media (max-width: " + M + "px) {\n           margin-top:55px;\n          }"
        : "justify-content: space-between";
});
export var FilterButton = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 5px 0;\n  margin-right: 20px;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n"], ["\n  padding: 5px 0;\n  margin-right: 20px;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n"])));
export var CandelarContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: white;\n  border: 0.5px solid #c4c4c4;\n  box-sizing: border-box;\n  border-radius: 3px;\n  padding: 5px;\n  font-size: 14px;\n  display: flex;\n  align-items: center;\n"], ["\n  background-color: white;\n  border: 0.5px solid #c4c4c4;\n  box-sizing: border-box;\n  border-radius: 3px;\n  padding: 5px;\n  font-size: 14px;\n  display: flex;\n  align-items: center;\n"])));
export var RightContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  height: 100%;\n  width: 75%;\n  align-items: flex-end;\n  gap: 20px;\n  max-width: 550px;\n  justify-content: flex-end;\n  @media (max-width: ", "px) {\n    width: 80%;\n  }\n  @media (max-width: ", "px) {\n    width: 30%;\n  }\n  &.calendar {\n    justify-content: space-around;\n    margin-right: 5px;\n    width: 100%;\n    max-width: inherit;\n  }\n  &.spaced {\n    justify-content: space-between;\n    @media (max-width: ", "px) {\n      justify-content: flex-end;\n    }\n  }\n  ", ";\n"], ["\n  display: flex;\n  height: 100%;\n  width: 75%;\n  align-items: flex-end;\n  gap: 20px;\n  max-width: 550px;\n  justify-content: flex-end;\n  @media (max-width: ", "px) {\n    width: 80%;\n  }\n  @media (max-width: ", "px) {\n    width: 30%;\n  }\n  &.calendar {\n    justify-content: space-around;\n    margin-right: 5px;\n    width: 100%;\n    max-width: inherit;\n  }\n  &.spaced {\n    justify-content: space-between;\n    @media (max-width: ", "px) {\n      justify-content: flex-end;\n    }\n  }\n  ",
    ";\n"])), L, M, M, function (_a) {
    var isContact = _a.isContact;
    return isContact ? "justify-content: flex-end;" : "";
});
export var Title = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  height: 100%;\n  font-family: ", ";\n  width: 100%;\n  font-size: 10px;\n  align-items: left;\n"], ["\n  display: flex;\n  height: 100%;\n  font-family: ", ";\n  width: 100%;\n  font-size: 10px;\n  align-items: left;\n"])), theme.typography.bold.ralewayBold);
export var LeftContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 75%;\n  margin-right: 10px;\n  align-items: flex-start;\n  justify-content: center;\n\n  .width {\n    width: 100%;\n  }\n  .row {\n    flex-direction: row;\n  }\n  @media (max-width: ", "px) {\n    width: 70%;\n    &.left {\n      align-items: flex-start;\n      width: 100%;\n    }\n    &.contact {\n      display: none;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 75%;\n  margin-right: 10px;\n  align-items: flex-start;\n  justify-content: center;\n\n  .width {\n    width: 100%;\n  }\n  .row {\n    flex-direction: row;\n  }\n  @media (max-width: ", "px) {\n    width: 70%;\n    &.left {\n      align-items: flex-start;\n      width: 100%;\n    }\n    &.contact {\n      display: none;\n    }\n  }\n"])), M);
export var DateContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  width: 33%;\n  align-items: center;\n  &.right {\n    justify-content: flex-end;\n  }\n  @media (max-width: ", "px) {\n    display: none;\n  }\n"], ["\n  display: flex;\n  width: 33%;\n  align-items: center;\n  &.right {\n    justify-content: flex-end;\n  }\n  @media (max-width: ", "px) {\n    display: none;\n  }\n"])), M);
export var Img = styled.img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  cursor: pointer;\n  &.calendar {\n    margin-left: 3px;\n    width: 14px;\n  }\n  &.filter {\n    width: 15px;\n  }\n"], ["\n  cursor: pointer;\n  &.calendar {\n    margin-left: 3px;\n    width: 14px;\n  }\n  &.filter {\n    width: 15px;\n  }\n"])));
export var Div = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: relative;\n  width: 100px;\n"], ["\n  position: relative;\n  width: 100px;\n"])));
export var Label = styled.label(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: 14px;\n  margin-right: 5px;\n  font-family: ", ";\n"], ["\n  font-size: 14px;\n  margin-right: 5px;\n  font-family: ", ";\n"])), theme.typography.bold.ralewayBold);
export var SearchInput = styled.input(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  height: 20px;\n  all: unset;\n  border-bottom: 1px solid ", ";\n  ::placeholder {\n    color: ", ";\n  }\n  padding: 5px 20px;\n  font-size: 13px;\n  width: 30%;\n  max-width: 138px;\n  background-position: left;\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  @media (max-width: ", "px) {\n    width: 19%;\n  }\n  @media (max-width: ", "px) {\n    display: none;\n  }\n  &.contact {\n    display: inherit;\n    width: 100%;\n    margin-left: 10px;\n  }\n"], ["\n  height: 20px;\n  all: unset;\n  border-bottom: 1px solid ", ";\n  ::placeholder {\n    color: ", ";\n  }\n  padding: 5px 20px;\n  font-size: 13px;\n  width: 30%;\n  max-width: 138px;\n  background-position: left;\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  @media (max-width: ", "px) {\n    width: 19%;\n  }\n  @media (max-width: ", "px) {\n    display: none;\n  }\n  &.contact {\n    display: inherit;\n    width: 100%;\n    margin-left: 10px;\n  }\n"])), theme.palette.black[100], theme.palette.grey[35], SearchIcon, L, M);
export var SelectCont = styled(Select)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  text-transform: capitalize;\n  z-index: 0;\n  width: 220px;\n  min-height: 38px;\n  border-radius: 0px;\n  height: 100%;\n  padding: 5px 8px;\n  max-width: 30vw;\n  min-width: 120px !important;\n  border-bottom: 1px solid ", ";\n\n  .building {\n    width: 15vw;\n    max-width: 30vw;\n  }\n\n  .MuiSelect-select:focus {\n    border-radius: 0;\n    background: none;\n  }\n\n  &.apartment {\n    width: 150px;\n  }\n"], ["\n  text-transform: capitalize;\n  z-index: 0;\n  width: 220px;\n  min-height: 38px;\n  border-radius: 0px;\n  height: 100%;\n  padding: 5px 8px;\n  max-width: 30vw;\n  min-width: 120px !important;\n  border-bottom: 1px solid ", ";\n\n  .building {\n    width: 15vw;\n    max-width: 30vw;\n  }\n\n  .MuiSelect-select:focus {\n    border-radius: 0;\n    background: none;\n  }\n\n  &.apartment {\n    width: 150px;\n  }\n"])), theme.palette.primary[75]);
export var SelectAll = styled(Select)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  text-transform: capitalize;\n  z-index: 0;\n  min-height: 22px;\n  height: 100%;\n  width: 100%;\n  padding: 5px 8px;\n  border: 0.5px solid ", ";\n  box-sizing: border-box;\n  border-radius: 5px;\n  background-color: ", " !important;\n  .MuiSelect-select:focus {\n    border-radius: 0;\n    background: none;\n  }\n  margin-right: 6px;\n"], ["\n  text-transform: capitalize;\n  z-index: 0;\n  min-height: 22px;\n  height: 100%;\n  width: 100%;\n  padding: 5px 8px;\n  border: 0.5px solid ", ";\n  box-sizing: border-box;\n  border-radius: 5px;\n  background-color: ", " !important;\n  .MuiSelect-select:focus {\n    border-radius: 0;\n    background: none;\n  }\n  margin-right: 6px;\n"])), theme.palette.grey[25], theme.palette.white[100]);
export var Input = styled(InputBase)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 14px !important;\n  background-color: ", ";\n  height: 35px !important;\n  width: 100%;\n  max-width: 178px;\n  border-width: 0px;\n  font-family: ", " !important;\n  @media (max-width: ", "px) {\n    background-color: ", ";\n  }\n"], ["\n  color: ", ";\n  font-size: 14px !important;\n  background-color: ", ";\n  height: 35px !important;\n  width: 100%;\n  max-width: 178px;\n  border-width: 0px;\n  font-family: ", " !important;\n  @media (max-width: ", "px) {\n    background-color: ", ";\n  }\n"])), theme.palette.black[100], theme.palette.white[100], theme.typography.normal.raleway, M, theme.palette.admin.primary[50]);
export var ImageContainer = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  text-align: left;\n  width: 50%;\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  flex: 1;\n  text-align: left;\n  width: 50%;\n  justify-content: flex-end;\n"])));
export var Row = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;
