import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Column, Container, InfoContainer, Row } from './styles';
export var LoadingSkeleton = function () { return (React.createElement(Container, null,
    React.createElement(Skeleton, { height: 270, width: 380 }),
    React.createElement(InfoContainer, null,
        React.createElement(Row, { className: "space-between" },
            React.createElement(Skeleton, { width: 200 }),
            React.createElement(Skeleton, { width: 50 })),
        React.createElement(Column, { gap: "14px" },
            React.createElement(Skeleton, { width: "50%" }),
            React.createElement(Skeleton, null),
            React.createElement(Skeleton, null)),
        React.createElement(Column, null,
            React.createElement(Skeleton, null))))); };
