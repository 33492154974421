var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M } from 'src/Utils';
export var ApartmentContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-self: flex-start;\n  height: 150px;\n  padding: 17px 51px 0px 26px;\n  margin: 0px 10px 10px 0px;\n  background-color: ", ";\n  border-radius: 8px;\n  @media (max-width: ", "px) {\n    margin-left: 10px;\n    max-width: inherit;\n    padding: 0px;\n    padding: 5px 20px;\n    width: auto;\n    background-color: ", ";\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-self: flex-start;\n  height: 150px;\n  padding: 17px 51px 0px 26px;\n  margin: 0px 10px 10px 0px;\n  background-color: ", ";\n  border-radius: 8px;\n  @media (max-width: ", "px) {\n    margin-left: 10px;\n    max-width: inherit;\n    padding: 0px;\n    padding: 5px 20px;\n    width: auto;\n    background-color: ", ";\n  }\n"])), theme.palette.admin.primary[45], M, theme.palette.admin.primary[50]);
export var TableContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 17px;\n  width: 100%;\n  background-color: ", ";\n  border-radius: 21px;\n  @media (max-width: ", "px) {\n    background-color: ", ";\n    &.contact {\n      padding: 0px;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 17px;\n  width: 100%;\n  background-color: ", ";\n  border-radius: 21px;\n  @media (max-width: ", "px) {\n    background-color: ", ";\n    &.contact {\n      padding: 0px;\n    }\n  }\n"])), theme.palette.white[100], M, theme.palette.admin.primary[50]);
export var Title = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  font-family: ", ";\n  font-style: normal;\n  font-weight: bold;\n  font-size: 20px;\n  margin: 30px 0px 20px 0px;\n  padding: 0px 0px 0px 40px;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  font-family: ", ";\n  font-style: normal;\n  font-weight: bold;\n  font-size: 20px;\n  margin: 30px 0px 20px 0px;\n  padding: 0px 0px 0px 40px;\n  width: 100%;\n"])), theme.typography.normal.raleway);
export var Label = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  width: 120px;\n  font-family: ", ";\n  font-style: normal;\n  font-weight: normal;\n  font-size: 16px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  width: 120px;\n  font-family: ", ";\n  font-style: normal;\n  font-weight: normal;\n  font-size: 16px;\n"])), theme.typography.normal.raleway);
export var Value = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  font-family: ", ";\n  font-style: normal;\n  font-weight: 900;\n  font-size: 16px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  font-family: ", ";\n  font-style: normal;\n  font-weight: 900;\n  font-size: 16px;\n"])), theme.typography.normal.raleway);
export var Container = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  width: 100%;\n  margin-bottom: 15px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  width: 100%;\n  margin-bottom: 15px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
