import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { BeatLoader } from 'react-spinners';
import moment from 'moment';
import { usePDF } from '@react-pdf/renderer';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import { AdminLayout } from 'src/Layouts';
import { Container, Title, ButtonContainer, Button, Label } from './styles';
import { GENERAL_SETTLEMENTS, DOCUMENT, theme } from 'src/Utils';
import { createData } from 'src/Utils/utils';
import useIdParam from 'src/hooks/useIdParam';
import { BILLING_PERIOD_START, BILLING_PERIOD_END, BUILDING_API, DATE_FORMAT_MOMENT, SETTLEMENTS_API, SETTLEMENT, ROUTES, SETTLEMENT_UPLOAD, TYPE_CONTACT, ACTIONS, } from 'src/Utils/constants';
import { DeleteModal, FilterTable } from 'src/Components/Common';
import { RelativeDiv } from '../BillsPage/styles';
import { getData } from 'src/Utils/api';
import SettlementPDF from 'src/Components/SettlementPDF/SettlementPDF';
import { addPDF } from './services';
import api from 'src/Utils/api';
function createBuilding(label, value, rows) {
    return { label: label, value: value, rows: rows };
}
var SettelmentPage = function () {
    var history = useHistory();
    var _a = useState(), buildingList = _a[0], setBuildingList = _a[1];
    var _b = useState(), selectedBuilding = _b[0], setSelectedBuildingNative = _b[1];
    var _c = useState(), startDate = _c[0], setStartDate = _c[1];
    var _d = useState(), endDate = _d[0], setEndDate = _d[1];
    var _e = useState(false), isDeleteModalOpen = _e[0], setIsDeleteModalOpen = _e[1];
    var _f = useState(), toDelete = _f[0], setToDelete = _f[1];
    var _g = useState([]), rows = _g[0], setRows = _g[1];
    var isContact = Cookies.get('User') === TYPE_CONTACT;
    var headCells = [
        { id: 'first', numeric: true, disablePadding: false, label: DOCUMENT },
        {
            id: 'second',
            numeric: true,
            disablePadding: false,
            label: BILLING_PERIOD_START,
        },
        {
            id: 'third',
            numeric: true,
            disablePadding: false,
            label: BILLING_PERIOD_END,
        },
        {
            id: 'fourth',
            numeric: true,
            disablePadding: false,
            label: ACTIONS,
        },
    ];
    var _h = useState(), selected = _h[0], setSelected = _h[1];
    var pdfData = useQuery(['getSettlement', selected], function () { return getData("/settlements/" + selected); }, {
        enabled: selected,
    }).data;
    var document = (React.createElement(SettlementPDF, { building: pdfData === null || pdfData === void 0 ? void 0 : pdfData.building, end_date: pdfData === null || pdfData === void 0 ? void 0 : pdfData.end_date, start_date: pdfData === null || pdfData === void 0 ? void 0 : pdfData.start_date, information: pdfData === null || pdfData === void 0 ? void 0 : pdfData.information }));
    var mutatePDF = useMutation(addPDF, {
        onSuccess: function () {
            if ((instance === null || instance === void 0 ? void 0 : instance.url) && pdfData) {
                var tempLink = window.document.createElement('a');
                var filename = (pdfData === null || pdfData === void 0 ? void 0 : pdfData.document_name) + ".pdf";
                tempLink.setAttribute('download', filename);
                tempLink.setAttribute('target', '_blank');
                tempLink.href = instance.url;
                tempLink.click();
                setSelected(undefined);
            }
        },
    })[0];
    var _j = usePDF({ document: document }), instance = _j[0], updateInstance = _j[1];
    useEffect(function () {
        pdfData && updateInstance();
    }, [pdfData]);
    useEffect(function () {
        if (!instance.loading && selected && pdfData && pdfData.information) {
            var file = new File([instance.blob], pdfData.document_name + ".pdf", {
                type: 'application/pdf',
            });
            mutatePDF({ file: file, id: selected });
        }
    }, [instance.loading]);
    var isLoadingBuildings = useQuery(BUILDING_API, function () { return getData("/buildings"); }, {
        onSuccess: function (data) {
            var buildings = data.buildings.map(function (_a) {
                var name = _a.name, id = _a.id;
                return createBuilding(name, id, rows);
            });
            buildings.sort(function (a, b) {
                return a.label.localeCompare(b.label, 'es');
            });
            setBuildingList(buildings);
        },
        refetchOnWindowFocus: false,
    }).isLoading;
    var _k = useQuery([SETTLEMENTS_API, selectedBuilding, startDate, endDate], function () {
        return getData("/settlements", {
            building_id: selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value,
            start_date: startDate
                ? moment(startDate).format(DATE_FORMAT_MOMENT)
                : null,
            end_date: endDate ? moment(endDate).format(DATE_FORMAT_MOMENT) : null,
        });
    }, {
        onSuccess: function (data) {
            if (!data.settlements[0])
                setRows([]);
            else
                data.settlements.sort(function (a, b) {
                    return moment(b.created_at).diff(moment(a.created_at));
                });
            setRows(data.settlements.map(function (_a) {
                var document_name = _a.document_name, start_date = _a.start_date, end_date = _a.end_date, pdf = _a.pdf, id = _a.id;
                return createData(document_name, moment(start_date).format(DATE_FORMAT_MOMENT), moment(end_date).format(DATE_FORMAT_MOMENT), id.toString(), pdf ? pdf : instance === null || instance === void 0 ? void 0 : instance.url);
            }));
        },
        enabled: selectedBuilding,
        refetchOnWindowFocus: false,
    }), isLoadingSettlements = _k.isLoading, refetch = _k.refetch;
    var _l = useIdParam({
        rows: rows,
        setRows: setRows,
        idRowAttribute: 'fourth',
        buildingList: buildingList,
        setSelectedBuilding: setSelectedBuildingNative,
        refetch: refetch,
    }), setSelectedBuilding = _l.handleBuildingChange, isLoading = _l.isLoading;
    var handleModal = function (settlementId) {
        var filteredRow = rows.filter(function (object) {
            return object.fourth === settlementId;
        });
        setToDelete(filteredRow[0]);
        setIsDeleteModalOpen(true);
    };
    var handleDeleteRow = function () {
        if (!toDelete)
            return;
        var filteredRows = rows.filter(function (object) {
            return object.fourth !== toDelete.fourth;
        });
        api.delete("/settlements/" + toDelete.fourth);
        setRows(filteredRows);
        setIsDeleteModalOpen(false);
    };
    var WIDTHS = ['25%', '30%', '30%', '15%'];
    return (React.createElement(AdminLayout, null,
        React.createElement(RelativeDiv, null,
            React.createElement(Container, null,
                React.createElement(Title, null, GENERAL_SETTLEMENTS),
                !isContact && (React.createElement(ButtonContainer, null,
                    React.createElement(Button, { onClick: function () { return history.push(ROUTES.settlement_upload); } },
                        React.createElement(Label, null, SETTLEMENT_UPLOAD)))),
                (isLoadingBuildings || isLoadingSettlements) && (React.createElement(BeatLoader, { size: "8px", color: theme.palette.black[100] })),
                buildingList && (React.createElement(FilterTable, { rows: rows, building: null, headCells: headCells, buildingList: buildingList, width: WIDTHS, userWidth: WIDTHS, startDate: startDate, setStartDate: setStartDate, endDate: endDate, setEndDate: setEndDate, selectedBuilding: selectedBuilding, setSelectedBuilding: setSelectedBuilding, isLoading: isLoadingSettlements || isLoading, handleDeleteRow: handleModal, setSelectedId: setSelected, hasDelete: true, isSettlement: true, refetch: refetch })),
                React.createElement(DeleteModal, { isOpen: isDeleteModalOpen, setIsOpen: setIsDeleteModalOpen, onDelete: handleDeleteRow, itemLabel: SETTLEMENT + " " + ((toDelete === null || toDelete === void 0 ? void 0 : toDelete.first) || '') })))));
};
export default SettelmentPage;
