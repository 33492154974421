import { Bath, Garage, Room, Size } from 'src/assets/Images';
import { pluralize } from 'src/Components/RealEstateDetail/utils';
export var info = function (property) { return [
    {
        img: Room,
        name: pluralize(property.bedrooms, 'dormitorio'),
        id: 'bedrooms',
    },
    { img: Bath, name: pluralize(property.bathrooms, 'baño'), id: 'bathrooms' },
    { img: Size, name: property.square_meters + ' m²', id: 'square_meters' },
    { img: Garage, name: pluralize(property.garages, 'garage'), id: 'garages' },
]; };
export var getPriceText = function (price, currency) {
    var formattedPrice = price.toLocaleString().replace(',', '.');
    return (currency === 'UYU' ? '$' : 'U$S') + " " + formattedPrice;
};
export var getLocationText = function (property) {
    var neighborhood = property.neighborhood, city = property.city;
    return neighborhood + ", " + city + ", Uruguay";
};
