var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { Paper } from '@material-ui/core';
import { theme } from 'src/Utils';
export var StyledPaper = styled(Paper)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: fit-content;\n  box-shadow: none !important;\n  border: none !important;\n  .MuiTablePagination-selectRoot {\n    display: none;\n  }\n  .MuiTablePagination-toolbar {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px 0px !important;\n    justify-content: center;\n\n    .MuiTablePagination-spacer {\n      display: none;\n    }\n\n    .MuiTablePagination-caption {\n      display: none;\n    }\n  }\n"], ["\n  width: 100%;\n  height: fit-content;\n  box-shadow: none !important;\n  border: none !important;\n  .MuiTablePagination-selectRoot {\n    display: none;\n  }\n  .MuiTablePagination-toolbar {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px 0px !important;\n    justify-content: center;\n\n    .MuiTablePagination-spacer {\n      display: none;\n    }\n\n    .MuiTablePagination-caption {\n      display: none;\n    }\n  }\n"])));
export var ArrowsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  &.minutes {\n    width: 54%;\n    justify-content: flex-end;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  &.minutes {\n    width: 54%;\n    justify-content: flex-end;\n  }\n"])));
export var PaginationLabel = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding-bottom: 3px;\n"], ["\n  padding-bottom: 3px;\n"])));
export var Div = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  &.centered {\n    justify-content: center;\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  &.centered {\n    justify-content: center;\n  }\n"])));
export var ButtonContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 40%;\n  display: flex;\n  justify-content: flex-end;\n"], ["\n  width: 40%;\n  display: flex;\n  justify-content: flex-end;\n"])));
export var Button = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  border: 1.5px solid ", ";\n  box-sizing: border-box;\n  border-radius: 9px;\n  width: fit-content;\n  padding: 0px 20px;\n  height: 37px;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  display: flex;\n  background-color: ", ";\n  font-size: 14px;\n  color: ", ";\n  :disabled {\n    opacity: 0.5;\n    cursor: not-allowed;\n  }\n"], ["\n  border: 1.5px solid ", ";\n  box-sizing: border-box;\n  border-radius: 9px;\n  width: fit-content;\n  padding: 0px 20px;\n  height: 37px;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  display: flex;\n  background-color: ", ";\n  font-size: 14px;\n  color: ", ";\n  :disabled {\n    opacity: 0.5;\n    cursor: not-allowed;\n  }\n"])), theme.palette.admin.primary[75], theme.palette.white[100], theme.palette.admin.primary[75]);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
