var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { TableHead, TableRow, TableCell, TableSortLabel, } from '@material-ui/core';
import { SearchIcon } from 'src/assets/Images';
import { theme } from '../../../../Utils';
export var Head = styled(TableHead)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 35px;\n  border-radius: 2px;\n  background-color: ", ";\n  font-size: 18px;\n"], ["\n  width: 100%;\n  height: 35px;\n  border-radius: 2px;\n  background-color: ", ";\n  font-size: 18px;\n"])), theme.palette.admin.primary[55]);
export var Row = styled(TableRow)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  border-radius: 2px;\n  background-color: ", ";\n  font-size: 18px;\n  align-items: center;\n"], ["\n  display: flex;\n  border-radius: 2px;\n  background-color: ", ";\n  font-size: 18px;\n  align-items: center;\n"])), theme.palette.admin.primary[55]);
export var Element = styled(TableCell)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-self: center;\n  height: 35px;\n  max-height: 35px;\n  padding: 0px 0px 0px 62px !important;\n  font-size: 18px !important;\n  border: none !important;\n  font-family: ", " !important;\n  &.width {\n    padding: 0px 0px 0px 42px !important;\n  }\n  &.small {\n    padding: 0px 0px 0px 22px !important;\n  }\n  &.xsmall {\n    padding: 0px 0px 0px 12px !important;\n  }\n  &.center {\n    text-align: center;\n  }\n"], ["\n  align-self: center;\n  height: 35px;\n  max-height: 35px;\n  padding: 0px 0px 0px 62px !important;\n  font-size: 18px !important;\n  border: none !important;\n  font-family: ", " !important;\n  &.width {\n    padding: 0px 0px 0px 42px !important;\n  }\n  &.small {\n    padding: 0px 0px 0px 22px !important;\n  }\n  &.xsmall {\n    padding: 0px 0px 0px 12px !important;\n  }\n  &.center {\n    text-align: center;\n  }\n"])), theme.typography.bold.ralewayBold);
export var SortLabel = styled(TableSortLabel)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  svg {\n    display: none;\n  }\n"], ["\n  svg {\n    display: none;\n  }\n"])));
export var SearchLabel = styled(TableSortLabel)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: ", ";\n"], ["\n  background-color: ", ";\n"])), theme.palette.white);
export var SearchInput = styled.input(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: 20px;\n  all: unset;\n  border-bottom: 1px solid ", ";\n  ::placeholder {\n    color: ", ";\n  }\n  background-color: ", ";\n  padding: 5px 20px;\n  font-size: 12px;\n  max-width: 45px;\n  background-position: left;\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  margin-right: 10px;\n  margin-bottom: 10px;\n"], ["\n  height: 20px;\n  all: unset;\n  border-bottom: 1px solid ", ";\n  ::placeholder {\n    color: ", ";\n  }\n  background-color: ", ";\n  padding: 5px 20px;\n  font-size: 12px;\n  max-width: 45px;\n  background-position: left;\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  margin-right: 10px;\n  margin-bottom: 10px;\n"])), theme.palette.black[100], theme.palette.grey[35], theme.palette.white, SearchIcon);
export var Img = styled.img(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-left: 8px;\n"], ["\n  margin-left: 8px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
