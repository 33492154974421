var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import styled from '@react-pdf/styled-components';
import { theme } from 'src/Utils';
export var Container = styled.View(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  border-bottom: 1px solid ", ";\n  margin-top: 22px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  border-bottom: 1px solid ", ";\n  margin-top: 22px;\n"])), theme.palette.grey[55]);
export var Row = styled.View(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  &.space-between {\n    justify-content: space-between;\n  }\n  &.align-center {\n    align-items: center;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  &.space-between {\n    justify-content: space-between;\n  }\n  &.align-center {\n    align-items: center;\n  }\n"])));
export var Text = styled.Text(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  white-space: nowrap;\n  color: ", ";\n  font-family: ", ";\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 500;\n"], ["\n  white-space: nowrap;\n  color: ", ";\n  font-family: ", ";\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 500;\n"])), theme.palette.grey[50], theme.typography.normal.raleway);
export var Price = styled.Text(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 700;\n  margin-top: 12px;\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 700;\n  margin-top: 12px;\n"])), theme.palette.blue[5], theme.typography.normal.raleway);
export var Image = styled.Image(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 18px;\n  height: 18px;\n  color: ", ";\n"], ["\n  width: 18px;\n  height: 18px;\n  color: ", ";\n"])), theme.palette.blue[5]);
// DetailInfo
var fontStyles = {
    color: theme.palette.black[80],
    fontFamily: theme.typography.normal.raleway,
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
};
export var HtmlDescriptionStylesheet = {
    p: __assign({}, fontStyles),
    strong: __assign(__assign({}, fontStyles), { fontWeight: 600 }),
    span: __assign({}, fontStyles),
    li: __assign({}, fontStyles),
    ul: __assign({}, fontStyles),
};
export var Column = styled.View(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  margin-top: 12px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  margin-top: 12px;\n"])));
export var Title = styled.Text(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 600;\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 600;\n"])), theme.palette.black[60], theme.typography.normal.raleway);
export var Description = styled.Text(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  margin-top: 12px;\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  margin-top: 12px;\n"])), theme.palette.black[80], theme.typography.normal.raleway);
// Location
export var MapContainer = styled.View(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: relative;\n  height: 260px;\n"], ["\n  position: relative;\n  height: 260px;\n"])));
export var Map = styled.Image(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 100%;\n  border-radius: 16px;\n  border: 1px solid ", ";\n  margin-top: 30px;\n  position: absolute;\n"], ["\n  width: 100%;\n  border-radius: 16px;\n  border: 1px solid ", ";\n  margin-top: 30px;\n  position: absolute;\n"])), theme.palette.grey[55]);
export var Header = styled.View(templateObject_11 || (templateObject_11 = __makeTemplateObject([""], [""])));
export var HeaderLogo = styled.Image(templateObject_12 || (templateObject_12 = __makeTemplateObject([""], [""])));
export var Footer = styled.View(templateObject_13 || (templateObject_13 = __makeTemplateObject([""], [""])));
export var FooterLogo = styled.Image(templateObject_14 || (templateObject_14 = __makeTemplateObject([""], [""])));
// Page
export var PageLayoutContainer = styled.View(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  padding: 35px;\n  padding-bottom: 64px;\n  height: 769px;\n"], ["\n  padding: 35px;\n  padding-bottom: 64px;\n  height: 769px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;
