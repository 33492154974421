import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useQuery } from 'react-query';
import { BeatLoader } from 'react-spinners';
import moment from 'moment';
import { AdminLayout } from 'src/Layouts';
import { Container, Title } from './styles';
import { ASSEMBLIES_MINUTES, DATE, theme, createHeadCells, getData, BUILDING_API, DATE_FORMAT_MOMENT, TYPE_CONTACT, ASSEMBLIES_API, DOCUMENT, SUMMON, UPLOAD_ASSEMBLY, NAME, MODIFIED_BY, ACTIONS, } from 'src/Utils';
import { FilterTable } from 'src/Components/Common';
import { RelativeDiv } from '../BillsPage/styles';
import api from 'src/Utils/api';
function createBuilding(label, value, rows) {
    return { label: label, value: value, rows: rows };
}
var AssemblyDocuments = function () {
    var _a = useState(), buildingList = _a[0], setBuildingList = _a[1];
    var _b = useState([]), rows = _b[0], setRows = _b[1];
    var _c = useState(), selectedBuilding = _c[0], setSelectedBuilding = _c[1];
    var _d = useState(false), isModalOpen = _d[0], setIsModalOpen = _d[1];
    var _e = useState(), startDate = _e[0], setStartDate = _e[1];
    var _f = useState(), endDate = _f[0], setEndDate = _f[1];
    var isContact = Cookies.get('User') === TYPE_CONTACT;
    var isSuperAdmin = Cookies.get('SuperAdmin') === 'true';
    var headCells = isSuperAdmin
        ? createHeadCells(DOCUMENT, SUMMON, DATE, MODIFIED_BY, ACTIONS)
        : createHeadCells(DOCUMENT, SUMMON, DATE);
    var isLoadingBuildings = useQuery(BUILDING_API, function () { return getData("/buildings"); }, {
        refetchOnWindowFocus: false,
        onSuccess: function (data) {
            var buildings = data.buildings.map(function (_a) {
                var name = _a.name, id = _a.id;
                return createBuilding(name, id, rows);
            });
            buildings.sort(function (a, b) {
                return a.label.localeCompare(b.label, 'es');
            });
            setBuildingList(buildings);
        },
    }).isLoading;
    var handleDeleteRow = function (assemblyId) {
        var filteredRows = rows.filter(function (object) {
            return object.ID.toString() !== assemblyId;
        });
        api.delete("/assemblies/" + assemblyId);
        setRows(filteredRows);
    };
    var _g = useQuery([ASSEMBLIES_API, selectedBuilding, startDate, endDate, isModalOpen], function () {
        return getData('/assemblies', {
            start_date: startDate
                ? moment(startDate).format(DATE_FORMAT_MOMENT)
                : null,
            end_date: endDate ? moment(endDate).format(DATE_FORMAT_MOMENT) : null,
            building_id: selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value,
        });
    }, {
        onSuccess: function (_a) {
            var assemblies = _a.assemblies;
            if (assemblies.length) {
                var selectedBuildingAssemblies = assemblies.find(function (_a) {
                    var building = _a.building;
                    return building === (selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value);
                });
                selectedBuildingAssemblies.assembly.sort(function (a, b) {
                    return moment(b.created_at).diff(moment(a.created_at));
                });
                if (isSuperAdmin) {
                    setRows(selectedBuildingAssemblies.assembly.map(function (_a) {
                        var name = _a.name, _b = _a.summon_name, summon_name = _b === void 0 ? '' : _b, date = _a.date, modified_by = _a.modified_by, _c = _a.pdf, pdf = _c === void 0 ? '' : _c, assembly_id = _a.assembly_id;
                        return ({
                            first: name,
                            second: summon_name,
                            third: date,
                            fourth: modified_by,
                            fifth: pdf,
                            ID: assembly_id,
                        });
                    }));
                }
                else {
                    setRows(selectedBuildingAssemblies.assembly.map(function (_a) {
                        var name = _a.name, _b = _a.summon_name, summon_name = _b === void 0 ? '' : _b, date = _a.date, _c = _a.pdf, pdf = _c === void 0 ? '' : _c;
                        return ({
                            first: name,
                            second: summon_name,
                            third: date,
                            fourth: '',
                            fifth: pdf,
                        });
                    }));
                }
            }
            else
                setRows([]);
        },
        refetchOnWindowFocus: false,
        enabled: selectedBuilding,
        retry: false,
    }), isLoadingRows = _g.isLoading, refetch = _g.refetch;
    var WIDTHS = !isSuperAdmin
        ? ['30%', '30%', '30%']
        : ['20%', '20%', '20%', '20%', '20%'];
    return (React.createElement(AdminLayout, null,
        React.createElement(RelativeDiv, null,
            React.createElement(Container, null,
                React.createElement(Title, null, ASSEMBLIES_MINUTES),
                isLoadingBuildings && (React.createElement(BeatLoader, { size: "8px", color: theme.palette.black[100] })),
                buildingList && (React.createElement(FilterTable, { rows: rows, building: null, headCells: headCells, buildingList: buildingList, width: WIDTHS, userWidth: WIDTHS, handleButtonClick: function () { return setIsModalOpen(function (prev) { return !prev; }); }, hasButton: !isContact, hasDelete: true, handleDeleteRow: handleDeleteRow, isModalOpen: isModalOpen, setIsModalOpen: setIsModalOpen, isAssembly: true, startDate: startDate, setStartDate: setStartDate, endDate: endDate, setEndDate: setEndDate, hasDateFilter: true, isLoading: isLoadingRows, selectedBuilding: selectedBuilding, setSelectedBuilding: setSelectedBuilding, modalButton: UPLOAD_ASSEMBLY, modalField: NAME, modalTitle: UPLOAD_ASSEMBLY, refetch: refetch }))))));
};
export default AssemblyDocuments;
