import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { L, M } from 'src/Utils';
import { useWindowWidth } from 'src/useWindowWidth';
import { FormLoadingSkeleton } from './Form/FormLoadingSkeleton';
import { Container, InfoContainer } from './styles';
import { Column, Divider, Row } from '../styles';
export var BottomInfoLoadingSkeleton = function () {
    var windowWidth = useWindowWidth();
    return (React.createElement(Container, null,
        React.createElement(InfoContainer, null,
            React.createElement(Row, { className: "space-between" }, Array.from(Array(4).keys()).map(function (e, index) { return (React.createElement(Skeleton, { width: windowWidth > M ? 100 : 60, height: 18, key: "skeleton-" + index })); })),
            React.createElement(Divider, null),
            React.createElement(Column, { gap: "12px" },
                windowWidth < L && React.createElement(Skeleton, { width: 112, height: 28 }),
                windowWidth > M && React.createElement(Skeleton, { width: 58, height: 28 }),
                React.createElement(Skeleton, { width: 140, height: 23 }),
                React.createElement(Skeleton, { width: "100%", height: 28 }),
                React.createElement(Skeleton, { width: "100%", height: 28 }),
                React.createElement(Skeleton, { width: "100%", height: 28 })),
            React.createElement(Divider, null),
            React.createElement(Column, { gap: "12px" },
                React.createElement(Skeleton, { width: 140, height: 23 }),
                React.createElement(Skeleton, { width: "100%", height: 28 }),
                React.createElement(Skeleton, { width: "100%", height: 28 })),
            React.createElement(Divider, null),
            windowWidth < L && React.createElement(FormLoadingSkeleton, null)),
        windowWidth > L && React.createElement(FormLoadingSkeleton, null)));
};
