var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useState } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';
import { DATE_FORMAT_MOMENT, RESERVATION_API, } from '../../../../Utils/constants';
import { createData, getData } from '../../../../Utils';
import { handleAmenityCurrency } from '../utils';
import { useAppSelector } from '../../../../redux/hooks';
var useBookings = function (_a) {
    var startDate = _a.startDate, endDate = _a.endDate;
    var _b = useAppSelector(function (state) { return state.tableFiltersSlice; }), selectedApartment = _b.selectedApartment, selectedTower = _b.selectedTower, selectedBuilding = _b.selectedBuilding;
    var _c = useState([]), rows = _c[0], setRows = _c[1];
    var _d = useQuery([
        RESERVATION_API,
        selectedBuilding,
        startDate,
        endDate,
        selectedApartment,
        selectedTower,
    ], function () {
        return getData("/bookings", {
            building_id: selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value,
            start_date: startDate
                ? moment(startDate).format(DATE_FORMAT_MOMENT)
                : null,
            end_date: endDate ? moment(endDate).format(DATE_FORMAT_MOMENT) : null,
            apartment_id: (selectedApartment === null || selectedApartment === void 0 ? void 0 : selectedApartment.value) || undefined,
            tower_id: (selectedTower === null || selectedTower === void 0 ? void 0 : selectedTower.label) !== 'Ninguna' ? selectedTower === null || selectedTower === void 0 ? void 0 : selectedTower.value : undefined,
        });
    }, {
        onSuccess: function (data) {
            if (!data.bookings[0])
                setRows([]);
            else {
                data.bookings.sort(function (a, b) { return moment(b.day).diff(moment(a.day)); });
                setRows(data.bookings.map(function (_a) {
                    var day = _a.day, schedule = _a.schedule, price = _a.price, amenity_name = _a.amenity_name, numeric_client_code = _a.numeric_client_code, currency = _a.currency, building_name = _a.building_name, id = _a.id;
                    var currencyToShow = handleAmenityCurrency(currency);
                    return __assign(__assign({}, createData(building_name, amenity_name, numeric_client_code, moment(day).format(DATE_FORMAT_MOMENT), schedule, currencyToShow === 'UR'
                        ? price + " " + currencyToShow
                        : currencyToShow + " " + price)), { ID: id });
                }));
            }
        },
        enabled: selectedBuilding,
        refetchOnWindowFocus: false,
    }), isLoading = _d.isLoading, refetch = _d.refetch;
    return { rows: rows, isLoading: isLoading, refetch: refetch };
};
export default useBookings;
