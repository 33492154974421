var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useEffect, useState } from 'react';
import { Modal as ModalComponent } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useMutation, useQuery } from 'react-query';
import moment from 'moment';
import { FileUploader } from 'react-drag-drop-files';
import { Container, Title, InputContainer, Label, Center, RowContainer, ImportButton, Error, FileErrorContainer, FileDiv, } from './styles';
import TextInput from '../TextInput';
import { ALL, UPLOAD, IMPORT_DOCUMENT, IMPORT_IMAGE, REQUIRED_FIELD, FILE_ERROR, IMAGE_FILE_ERROR, FILE_EXTENSIONS, IMAGE_EXTENSIONS, BUILDING, BUILDING_KEY, DATE, ASSOCIATED_CITATION, CITATION, BUILDING_API, DATE_FORMAT_MOMENT, UPLOAD_DATE, ACCEPT, NO_ASSOCIATED_CONTACTS, MESSAGE_TO_ALL, } from 'src/Utils/constants';
import { AdminButton } from '../AdminButton';
import { getData } from 'src/Utils/api';
import { submitFile } from './services';
import MultiSelect from '../MultiSelect';
import { Popover, SelectComponent } from '..';
import { openedArrow, exit } from 'src/assets/Images';
import { submitDataForm, submitPDF, } from 'src/Views/DocumentsPages/AssemblyDocumentsPage/duck/services';
var Modal = function (_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, title = _a.title, textInputLabel = _a.textInputLabel, onSubmit = _a.onSubmit, isAssembly = _a.isAssembly, isInvoice = _a.isInvoice, checkSelect = _a.checkSelect, uploadImage = _a.uploadImage, uploadDocument = _a.uploadDocument, building = _a.building, _b = _a.isAssociatedContacts, isAssociatedContacts = _b === void 0 ? false : _b, _c = _a.isMessageAll, isMessageAll = _c === void 0 ? false : _c;
    var _d = useState(null), selectedFile = _d[0], setSelectedFile = _d[1];
    var _e = useState(''), textInput = _e[0], setTextInput = _e[1];
    var _f = useState({
        file: '',
        text: '',
        select: '',
    }), errors = _f[0], setErrors = _f[1];
    var _g = useState([]), buildingList = _g[0], setBuildingList = _g[1];
    var _h = useState([]), summonsList = _h[0], setSummonsList = _h[1];
    var _j = useState(new Date()), selectedDate = _j[0], setSelectedDate = _j[1];
    var _k = useState(null), anchorRefCitation = _k[0], setAnchorRefCitation = _k[1];
    var _l = useState(null), anchorRefBuilding = _l[0], setAnchorRefBuilding = _l[1];
    var _m = useState(building ? building : { label: '', value: '' }), selectedBuilding = _m[0], setSelected = _m[1];
    var _o = useState({
        label: '',
        value: '',
    }), selectedSummons = _o[0], setSelectedSummons = _o[1];
    var _p = useState(building ? [building] : []), selectedOption = _p[0], setSelectedOption = _p[1];
    var multiSelectOptions = __spreadArrays([{ label: ALL, value: '' }], buildingList);
    useQuery([BUILDING_API + "Modal"], function () { return getData("/buildings"); }, {
        onSuccess: function (data) {
            setBuildingList(data.buildings.map(function (_a) {
                var name = _a.name, id = _a.id;
                return ({
                    label: name,
                    value: id,
                });
            }));
        },
        refetchOnWindowFocus: false,
        retry: false,
        enabled: !uploadDocument && !uploadImage,
    });
    useQuery("getSummonsListModal", function () {
        return getData("/summons", {
            building_id: selectedBuilding.value,
        });
    }, {
        onSuccess: function (data) {
            setSummonsList(data.summons.map(function (_a) {
                var name = _a.name, id = _a.id;
                return ({
                    label: name,
                    value: id,
                });
            }));
        },
        refetchOnWindowFocus: false,
        retry: false,
        enabled: !uploadDocument && !uploadImage,
    });
    var handleFileChange = function (file) {
        setSelectedFile(file);
        isValidFile(file.name);
    };
    var isValidFile = function (file) {
        var isValid = true;
        if (file === null) {
            setErrors(function (prev) { return (__assign(__assign({}, prev), { file: REQUIRED_FIELD })); });
            setSelectedFile(null);
            isValid = false;
        }
        else {
            setErrors(function (prev) { return (__assign(__assign({}, prev), { file: '' })); });
        }
        return isValid;
    };
    var isValidField = function (text) {
        var isValid = true;
        if (text === '') {
            setErrors(function (prev) { return (__assign(__assign({}, prev), { text: REQUIRED_FIELD })); });
            isValid = false;
        }
        else {
            setErrors(function (prev) { return (__assign(__assign({}, prev), { text: '' })); });
        }
        return isValid;
    };
    var isValidSelect = function (item) {
        var isValid = true;
        if (item.value) {
            setErrors(function (prev) { return (__assign(__assign({}, prev), { select: '' })); });
        }
        else {
            setErrors(function (prev) { return (__assign(__assign({}, prev), { select: REQUIRED_FIELD })); });
            isValid = false;
        }
        return isValid;
    };
    var handleIdChange = function (e) {
        var currentId = e.target.value;
        setTextInput(currentId);
        return isValidField(currentId);
    };
    var mutateFormReceipt = useMutation(submitDataForm, {
        onSuccess: function (response) {
            var id = response.data.id;
            mutatePDF({ file: selectedFile, id: id });
        },
    })[0];
    var mutatePDF = useMutation(submitPDF)[0];
    var mutateSubmitFile = useMutation(submitFile)[0];
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var validField, validFile, validSelect, building_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!((uploadDocument || uploadImage) && onSubmit && selectedFile)) return [3 /*break*/, 1];
                    onSubmit(selectedFile);
                    setSelectedFile(null);
                    setIsOpen(false);
                    return [3 /*break*/, 4];
                case 1:
                    validField = isValidField(textInput);
                    validFile = isValidFile(selectedFile);
                    validSelect = !checkSelect ||
                        isValidSelect(selectedOption.length ? selectedOption[0] : selectedBuilding);
                    if (!(validField && validFile && validSelect)) return [3 /*break*/, 4];
                    if (!isAssembly) return [3 /*break*/, 3];
                    return [4 /*yield*/, mutateFormReceipt({
                            name: textInput,
                            summon_id: selectedSummons.value,
                            created_date: selectedDate,
                        })];
                case 2:
                    _a.sent();
                    setIsOpen(false);
                    return [3 /*break*/, 4];
                case 3:
                    if (isInvoice && onSubmit) {
                        onSubmit(textInput, moment(selectedDate).format(DATE_FORMAT_MOMENT), selectedBuilding.value, selectedFile);
                    }
                    else {
                        building_1 = selectedOption.map(function (option) { return option.value; });
                        mutateSubmitFile({
                            file: selectedFile,
                            name: textInput,
                            buildings: building_1,
                        }).then(function (res) {
                            if ((res === null || res === void 0 ? void 0 : res.status) === 200) {
                                window.location.reload();
                            }
                        });
                        setIsOpen(false);
                    }
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleOutsideClick = function () {
        setAnchorRefCitation(null);
        setAnchorRefBuilding(null);
    };
    var handleSelected = function (item) {
        setSelectedSummons(item);
    };
    useEffect(function () {
        if (selectedOption.length === 0)
            return;
        if (selectedOption.find(function (x) { return x.label === ALL; })) {
            setSelectedOption(buildingList);
        }
        if (selectedOption[0].label === ALL && selectedOption.length > 1) {
            setSelectedOption(function (prevState) { return prevState.splice(0, 1); });
        }
    }, [selectedOption]);
    return (React.createElement(ModalComponent, { open: isOpen },
        React.createElement(Center, null,
            React.createElement(Container, null,
                React.createElement(RowContainer, { className: "right close" },
                    React.createElement(Close, { onClick: function () { return setIsOpen(function (prev) { return !prev; }); } })),
                React.createElement(Title, null, title),
                (isAssociatedContacts || isMessageAll) ? (React.createElement(React.Fragment, null,
                    React.createElement(RowContainer, null, isAssociatedContacts ? NO_ASSOCIATED_CONTACTS : MESSAGE_TO_ALL),
                    React.createElement(RowContainer, { className: "right" },
                        React.createElement(AdminButton, { className: "margin", onClick: function () { return setIsOpen(false); } }, ACCEPT))))
                    :
                        React.createElement(React.Fragment, null,
                            !uploadImage && !uploadDocument && (React.createElement(InputContainer, null,
                                React.createElement(Label, null, textInputLabel),
                                React.createElement(TextInput, { className: "minutes-size", onChange: handleIdChange }),
                                React.createElement(Error, null, errors.text))),
                            isAssembly ? (React.createElement(RowContainer, null,
                                React.createElement(InputContainer, { className: "width" },
                                    React.createElement(Label, null, DATE),
                                    React.createElement(TextInput, { value: moment(selectedDate).format(DATE_FORMAT_MOMENT), disabled: true, className: "smaller-size" })),
                                React.createElement(InputContainer, { className: "width" }, selectedBuilding && (React.createElement(SelectComponent, { label: ASSOCIATED_CITATION, type: CITATION, data: selectedSummons.label, img: openedArrow, anchorRef: anchorRefCitation, setAnchorRef: setAnchorRefCitation, classNames: "height" }))),
                                React.createElement(Popover, { data: summonsList, handleOutsideClick: handleOutsideClick, anchorRef: anchorRefCitation, setSelected: handleSelected, isFilter: false, type: CITATION, className: "spaceBuildings" }))) : isInvoice ? (React.createElement(RowContainer, null,
                                React.createElement(InputContainer, { className: "width" },
                                    React.createElement(Label, null, UPLOAD_DATE),
                                    React.createElement(TextInput, { value: moment(selectedDate).format(DATE_FORMAT_MOMENT), disabled: true, className: "smaller-size" })),
                                React.createElement(InputContainer, { className: "width" },
                                    React.createElement(Label, null, BUILDING),
                                    React.createElement(TextInput, { value: selectedBuilding.label.toString(), disabled: true, className: selectedBuilding.label.toString().length > 17
                                            ? 'xSmaller-size'
                                            : 'smaller-size' })))) : uploadImage || uploadDocument ? (React.createElement(React.Fragment, null)) : (React.createElement(React.Fragment, null,
                                React.createElement(MultiSelect, { options: multiSelectOptions, label: BUILDING, type: BUILDING_KEY, setSelectedOption: setSelectedOption, initialValues: selectedOption.length === buildingList.length && buildingList.length > 0
                                        ? { label: ALL, value: '' }
                                        : selectedOption || building || [], classNames: "height-auto" }),
                                checkSelect && React.createElement(Error, { className: "select" }, errors.select))),
                            React.createElement(FileDiv, null,
                                React.createElement(FileUploader, { handleChange: handleFileChange, name: "filssse", types: uploadImage ? IMAGE_EXTENSIONS : [FILE_EXTENSIONS], label: uploadImage ? IMPORT_IMAGE : IMPORT_DOCUMENT, onSizeError: function () {
                                        setErrors(function (prev) { return (__assign(__assign({}, prev), { file: uploadImage ? IMAGE_FILE_ERROR : FILE_ERROR })); });
                                        setSelectedFile(null);
                                    }, onTypeError: function () {
                                        setErrors(function (prev) { return (__assign(__assign({}, prev), { file: uploadImage ? IMAGE_FILE_ERROR : FILE_ERROR })); });
                                        setSelectedFile(null);
                                    }, classes: true },
                                    React.createElement(ImportButton, null,
                                        uploadImage ? IMPORT_IMAGE : IMPORT_DOCUMENT,
                                        ' ')),
                                React.createElement(FileErrorContainer, null,
                                    selectedFile && (React.createElement(Label, { className: "error" },
                                        React.createElement("img", { src: exit, onClick: function () {
                                                setSelectedFile(null);
                                                onSubmit && onSubmit(null);
                                            } }), selectedFile === null || selectedFile === void 0 ? void 0 :
                                        selectedFile.name)),
                                    React.createElement(Error, null, errors.file))),
                            React.createElement(RowContainer, { className: "right" },
                                React.createElement(AdminButton, { className: "margin", onClick: handleSubmit }, UPLOAD)))))));
};
export default Modal;
