var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M } from '../../Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-sizing: border-box;\n  display: flex;\n  height: 100%;\n  min-height: 100vh;\n  justify-content: flex-start;\n  padding: 5%;\n  flex-direction: column;\n  background-color: ", ";\n\n  @media (max-width: ", "px) {\n    padding: 25px;\n    width: 150%;\n  }\n"], ["\n  box-sizing: border-box;\n  display: flex;\n  height: 100%;\n  min-height: 100vh;\n  justify-content: flex-start;\n  padding: 5%;\n  flex-direction: column;\n  background-color: ", ";\n\n  @media (max-width: ", "px) {\n    padding: 25px;\n    width: 150%;\n  }\n"])), theme.palette.admin.primary[50], M);
export var Row = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 10px;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 10px;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n  }\n"])), M);
export var Card = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  width: 100%;\n  height: 544px;\n  padding: 38px;\n  border-radius: 7px;\n  background-color: ", ";\n"], ["\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  width: 100%;\n  height: 544px;\n  padding: 38px;\n  border-radius: 7px;\n  background-color: ", ";\n"])), theme.palette.admin.primary[55]);
export var Text = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 14px;\n  margin-bottom: 20px;\n\n  &.title {\n    font-size: 24px;\n    color: ", ";\n  }\n"], ["\n  font-size: 14px;\n  margin-bottom: 20px;\n\n  &.title {\n    font-size: 24px;\n    color: ", ";\n  }\n"])), theme.palette.admin.primary[75]);
export var Bold = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: bold;\n  font-size: 18px;\n  margin-left: 10px;\n  margin-top: -4px;\n}"], ["\n  font-weight: bold;\n  font-size: 18px;\n  margin-left: 10px;\n  margin-top: -4px;\n}"])));
export var MethodsContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 9px;\n  @media (max-width: ", "px) {\n    flex-direction: row;\n    align-items: flex-start;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 9px;\n  @media (max-width: ", "px) {\n    flex-direction: row;\n    align-items: flex-start;\n  }\n"])), M);
export var MethodCard = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: grid;\n  align-items: center;\n  justify-items: center;\n  width: 140px;\n  height: 58px;\n  background-color: ", ";\n  border-radius: 10px;\n  margin-bottom: 10px;\n\n  &.row {\n    margin-right: 25px;\n  }\n\n  &.pointer {\n    cursor: pointer;\n    margin-right: 25px;\n  }\n  &.banred {\n    cursor: pointer;\n    margin-right: 25px;\n    background-color: 'red';\n  }\n  &.disabled {\n    cursor: not-allowed;\n    margin-right: 25px;\n    opacity: 0.5;\n  }\n"], ["\n  display: grid;\n  align-items: center;\n  justify-items: center;\n  width: 140px;\n  height: 58px;\n  background-color: ", ";\n  border-radius: 10px;\n  margin-bottom: 10px;\n\n  &.row {\n    margin-right: 25px;\n  }\n\n  &.pointer {\n    cursor: pointer;\n    margin-right: 25px;\n  }\n  &.banred {\n    cursor: pointer;\n    margin-right: 25px;\n    background-color: 'red';\n  }\n  &.disabled {\n    cursor: not-allowed;\n    margin-right: 25px;\n    opacity: 0.5;\n  }\n"])), theme.palette.white[100]);
export var Cancel = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: 12px;\n  text-decoration-line: underline;\n  color: ", ";\n  font-family: ", ";\n  cursor: pointer;\n"], ["\n  font-size: 12px;\n  text-decoration-line: underline;\n  color: ", ";\n  font-family: ", ";\n  cursor: pointer;\n"])), theme.palette.admin.primary[75], theme.typography.bold.ralewayBold);
export var Img = styled.img(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  max-width: 80%;\n"], ["\n  max-width: 80%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
