import React, { useCallback } from 'react';
import { Container, ImageContainer, DescriptionContainer, ContentContainer, TitleContainer, Img, Subtitle, Description, Div, DivContainer, ButtonsContainer, ImageButton, } from './styles';
import { Button } from '../Common';
import { AppSectionRectangle, AppSectionBackground, GooglePlayButtonImage, AppStoreButtonImage, AppSectionMobileBackground, Circle, } from 'src/assets/Images';
import { useWindowWidth } from 'src/useWindowWidth';
import { M } from 'src/Utils';
import { DEMO } from 'src/Utils/constants';
var AppSection = function (_a) {
    var items = _a.items, title = _a.title, image = _a.image;
    var windowWidth = useWindowWidth();
    var smallScreen = windowWidth < M;
    var renderTitle = useCallback(function () {
        return React.createElement(TitleContainer, null, title);
    }, [title]);
    return (React.createElement(Container, { style: {
            backgroundImage: "url(" + (!smallScreen ? '' : AppSectionMobileBackground) + ")",
        } },
        smallScreen && renderTitle(),
        React.createElement(ImageContainer, null,
            React.createElement(Img, { src: image }),
            !smallScreen && React.createElement(Button, null, DEMO)),
        React.createElement(ContentContainer, null,
            !smallScreen && (React.createElement(React.Fragment, null,
                React.createElement(Img, { className: "absolute", src: AppSectionBackground }),
                renderTitle())),
            React.createElement(DescriptionContainer, { style: {
                    backgroundImage: "url(" + (smallScreen ? '' : AppSectionRectangle) + ")",
                } },
                smallScreen && React.createElement(Img, { className: "absolute circle", src: Circle }),
                React.createElement(DivContainer, null,
                    items.map(function (_a) {
                        var subtitle = _a.title, description = _a.description;
                        return (React.createElement(Div, { key: subtitle },
                            React.createElement(Subtitle, null, subtitle),
                            React.createElement(Description, null, description)));
                    }),
                    smallScreen && (React.createElement(ButtonsContainer, null,
                        React.createElement(ImageButton, { src: GooglePlayButtonImage }),
                        React.createElement(ImageButton, { src: AppStoreButtonImage }))))))));
};
export default AppSection;
