var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useQuery } from 'react-query';
import { FilterProvider } from 'src/context/FilterContext';
import usePaginator from 'src/context/PaginatorContext';
import { ContactSection, Footer } from '../../Views';
import { Header } from 'src/Layouts';
import { FilterDesktop } from './Filter';
import { Properties } from './Properties';
import { useWindowWidth } from 'src/useWindowWidth';
import { XXL } from 'src/Utils/constants';
import { buildURL, defaultValues, getURLValues } from './Filter/utils';
import { Whatsapp } from '../Whatsapp';
import { getProperties } from './services';
import { Wrapper } from './styles';
export var RealEstateProperties = function () {
    var history = useHistory();
    var location = useLocation();
    var windowWidth = useWindowWidth();
    var isMobile = windowWidth < XXL;
    var _a = usePaginator(), setTotalPages = _a.setTotalPages, setCurrentPage = _a.setCurrentPage, currentPage = _a.currentPage, totalPages = _a.totalPages;
    var query = new URLSearchParams(location.search);
    var _b = getURLValues(query, defaultValues), page = _b.page, values = _b.values;
    var formMethods = useForm({
        defaultValues: values,
        shouldUnregister: false,
    });
    var watchedFields = formMethods.watch();
    var _c = useQuery({
        queryKey: ['properties', currentPage, watchedFields],
        queryFn: function () { return getProperties(watchedFields, currentPage); },
    }), data = _c.data, isLoading = _c.isLoading;
    useEffect(function () {
        var newURL = buildURL(watchedFields, currentPage);
        var currentPathWithQuery = window.location.pathname + window.location.search;
        var shouldUpdateURL = newURL !== currentPathWithQuery && (!isMobile || page !== currentPage);
        if (shouldUpdateURL) {
            var urlObjNew = new URL(newURL, window.location.origin);
            var urlObjCurrent = new URL(currentPathWithQuery, window.location.origin);
            var paramsNew = Array.from(urlObjNew.searchParams.entries()).filter(function (param) { return param[0] !== 'page'; });
            var paramsCurrent = Array.from(urlObjCurrent.searchParams.entries()).filter(function (param) { return param[0] !== 'page'; });
            var isOtherParamsChanged = JSON.stringify(paramsNew) !== JSON.stringify(paramsCurrent);
            if (isOtherParamsChanged)
                setCurrentPage(1);
            history.push(newURL);
        }
    }, [watchedFields, currentPage]);
    useEffect(function () {
        setCurrentPage(page);
    }, []);
    useEffect(function () {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }, [currentPage]);
    useEffect(function () {
        if (data && data.total_pages !== totalPages) {
            setTotalPages(data.total_pages);
        }
    }, [data]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, null),
        React.createElement(Wrapper, null,
            React.createElement(FormProvider, __assign({}, formMethods),
                React.createElement(FilterProvider, null, !isMobile && React.createElement(FilterDesktop, null)),
                React.createElement(Properties, { total: data === null || data === void 0 ? void 0 : data.total_properties, properties: data === null || data === void 0 ? void 0 : data.properties, isLoading: isLoading }))),
        React.createElement(ContactSection, { className: "padding-top", isContactPage: false }),
        React.createElement(Whatsapp, null),
        React.createElement(Footer, null)));
};
