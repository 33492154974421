var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, XXL, XL, XXS } from '../../../Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  border-radius: 6px;\n  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.05);\n  align-items: center;\n  flex-direction: column;\n  background-color: ", ";\n  height: 35vw;\n  max-height: 400px;\n  max-width: 400px;\n  margin: 0px 1%;\n  @media (max-width: ", "px) {\n    min-height: 340px;\n    height: 45vw;\n    margin-bottom: 20px;\n    max-width: 300px;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  border-radius: 6px;\n  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.05);\n  align-items: center;\n  flex-direction: column;\n  background-color: ", ";\n  height: 35vw;\n  max-height: 400px;\n  max-width: 400px;\n  margin: 0px 1%;\n  @media (max-width: ", "px) {\n    min-height: 340px;\n    height: 45vw;\n    margin-bottom: 20px;\n    max-width: 300px;\n  }\n"])), theme.palette.white[100], XL);
export var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  padding: 5%;\n  height: 100%;\n  justify-content: space-around;\n  text-align: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  padding: 5%;\n  height: 100%;\n  justify-content: space-around;\n  text-align: center;\n"])));
export var Title = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: ", ";\n  margin-bottom: 5px;\n  font-size: 18px;\n  margin-bottom: 5px;\n  @media (max-width: ", "px) {\n    font-size: 16px;\n  }\n"], ["\n  font-family: ", ";\n  margin-bottom: 5px;\n  font-size: 18px;\n  margin-bottom: 5px;\n  @media (max-width: ", "px) {\n    font-size: 16px;\n  }\n"])), theme.typography.bold.ralewayBold, XL);
export var ImageContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 80%;\n  justify-content: center;\n  display: flex;\n  align-items: center;\n  padding: 20px 0px 10px 0px;\n  @media (max-width: ", "px) {\n    width: 60%;\n  }\n"], ["\n  width: 80%;\n  justify-content: center;\n  display: flex;\n  align-items: center;\n  padding: 20px 0px 10px 0px;\n  @media (max-width: ", "px) {\n    width: 60%;\n  }\n"])), XXS);
export var Image = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 45%;\n  border-radius: 100%;\n  max-height: 100px;\n"], ["\n  width: 45%;\n  border-radius: 100%;\n  max-height: 100px;\n"])));
export var Description = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  font-size: 16px;\n  max-height: 20vw;\n  overflow: hidden;\n  font-family: ", ";\n  @media (max-width: ", "px) {\n    font-size: 14px;\n  }\n  @media (max-width: ", "px) {\n    max-height: 230px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 12px;\n  }\n"], ["\n  width: 100%;\n  font-size: 16px;\n  max-height: 20vw;\n  overflow: hidden;\n  font-family: ", ";\n  @media (max-width: ", "px) {\n    font-size: 14px;\n  }\n  @media (max-width: ", "px) {\n    max-height: 230px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 12px;\n  }\n"])), theme.typography.OpenSans, XXL, XL, XXS);
export var DescriptionContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
