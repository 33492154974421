import { useQuery } from 'react-query';
import { getData } from '../../../Utils/api';
export default function useReceipts(customerCode, paymentProvider, paymentStatus, startDate, endDate, page, enabled) {
    return useQuery([
        customerCode,
        paymentProvider,
        paymentStatus,
        startDate,
        endDate,
        page
    ], function () {
        var _a, _b;
        return getData('/common_expenses_receipts/receipts', {
            numeric_client_code: customerCode,
            payment_method: (_a = paymentProvider) === null || _a === void 0 ? void 0 : _a.value,
            payment_status: (_b = paymentStatus) === null || _b === void 0 ? void 0 : _b.value,
            payed_at_start_date: startDate,
            payed_at_end_date: endDate,
            page: page
        });
    }, {
        refetchOnWindowFocus: false,
        enabled: enabled
    });
}
