import React from 'react';
import { handleAmenityCurrency } from 'src/Views/MaintenancePages/ReservationPage/utils';
import { CheckboxContainer, Container, Price, Label, CheckboxButton, } from './styles';
var ExtraCheckbox = function (_a) {
    var selected = _a.selected, setSelected = _a.setSelected, name = _a.name, price = _a.price, currency = _a.currency;
    var currencyToShow = handleAmenityCurrency(currency);
    return (React.createElement(Container, null,
        React.createElement(CheckboxContainer, null,
            React.createElement(CheckboxButton, { type: "checkbox", checked: selected, onChange: setSelected }),
            React.createElement(Label, null, name)),
        React.createElement(Price, null, currencyToShow === 'UR'
            ? "+ " + price + " " + currencyToShow
            : "+ " + currencyToShow + " " + price)));
};
export default ExtraCheckbox;
