import React from 'react';
import { Page, Document } from '@react-pdf/renderer';
import { BathPNG, GaragePNG, RoomPNG, SizePNG } from 'src/assets/Images';
import { BATHROOM, BEDROOM, GARAGE, M2 } from 'src/Utils';
import MoreInfoPDF from './MoreInfoPDF';
import DetailInfoPDF from './DetailInfoPDF';
import LocationPDF from './LocationPDF';
import { TopInfoPDF } from './TopInfoPDF';
import PDFHeader from './PDFHeader';
import { handleCurrency } from '../../BottomInfo/Form/utils';
import PDFFooter from './PDFFooter';
import { InfoContainer } from '../../BottomInfo/styles';
import { PageLayoutContainer, Price } from './styles';
var PageLayout = function (_a) {
    var children = _a.children;
    return (React.createElement(Page, { size: "A4" },
        React.createElement(PDFHeader, null),
        React.createElement(PageLayoutContainer, { fixed: true }, children),
        React.createElement(PDFFooter, null)));
};
var PDFComponent = function (_a) {
    var info = _a.info;
    var moreInfoData = [
        { img: RoomPNG, number: info.bedrooms, label: BEDROOM },
        { img: BathPNG, number: info.bathrooms, label: BATHROOM },
        { img: SizePNG, number: info.square_meters, label: M2 },
        { img: GaragePNG, number: info.garages, label: GARAGE },
    ];
    return (React.createElement(Document, null,
        React.createElement(PageLayout, null,
            React.createElement(TopInfoPDF, { info: info }),
            React.createElement(InfoContainer, null,
                React.createElement(MoreInfoPDF, { data: moreInfoData }),
                React.createElement(Price, null,
                    handleCurrency(info.currency),
                    ' ',
                    info.price.toLocaleString().replace(',', '.')),
                info.description ? (React.createElement(DetailInfoPDF, { description: info.description })) : (React.createElement(LocationPDF, { description: info.location_description, coords: {
                        lat: Number(info.latitude),
                        lng: Number(info.longitude),
                    } })))),
        info.description && (React.createElement(PageLayout, null,
            React.createElement(LocationPDF, { description: info.location_description, coords: { lat: Number(info.latitude), lng: Number(info.longitude) } })))));
};
export { PDFComponent };
