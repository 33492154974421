import React from 'react';
import { DetailInfo } from './DetailInfo';
import { Form } from './Form';
import { Location } from './Location';
import { MoreInfo } from './MoreInfo';
import { PDFButton } from '../PDFButton';
import { BATHROOM, BEDROOM, GARAGE, L, M2 } from 'src/Utils';
import { Bath, Garage, Room, Size } from 'src/assets/Images';
import { useWindowWidth } from 'src/useWindowWidth';
import { Container, InfoContainer } from './styles';
import { Divider } from '../styles';
export var BottomInfo = function (_a) {
    var info = _a.info;
    var windowWidth = useWindowWidth();
    var moreInfoData = [
        { img: Room, number: info.bedrooms, label: BEDROOM },
        { img: Bath, number: info.bathrooms, label: BATHROOM },
        { img: Size, number: info.square_meters, label: M2 },
        { img: Garage, number: info.garages, label: GARAGE },
    ];
    return (React.createElement(Container, null,
        React.createElement(InfoContainer, null,
            React.createElement(MoreInfo, { data: moreInfoData }),
            React.createElement(DetailInfo, { cod: info.code, description: info.description, price: info.price, currency: info.currency }),
            React.createElement(Divider, null),
            React.createElement(Location, { description: info.location_description, coords: { lat: Number(info.latitude), lng: Number(info.longitude) } }),
            windowWidth < L && React.createElement(PDFButton, { info: info }),
            windowWidth < L && (React.createElement(Form, { currency: info.currency, price: info.price, propertyType: info.property_type, size: info.square_meters, operationType: info.operation_type, propertyId: info.id }))),
        windowWidth > L && (React.createElement(Form, { currency: info.currency, price: info.price, propertyType: info.property_type, size: info.square_meters, operationType: info.operation_type, propertyId: info.id }))));
};
