import React from 'react';
import { ContactContainer, Title, Label, Value, Container } from './styles';
import { ADDRESS, EMAIL, PHONE } from 'src/Utils';
var ContactForm = function (_a) {
    var name = _a.name, lastName = _a.lastName, mobile = _a.mobile, email = _a.email, address = _a.address;
    var inputs = [
        { label: PHONE, value: mobile },
        { label: EMAIL, value: email },
        { label: ADDRESS, value: address },
    ];
    return (React.createElement(ContactContainer, null,
        React.createElement(Title, null, name + " " + lastName),
        inputs.map(function (_a) {
            var label = _a.label, value = _a.value;
            return (React.createElement(Container, { key: label },
                React.createElement(Label, null,
                    label,
                    ":"),
                React.createElement(Value, null, value)));
        })));
};
export default ContactForm;
