import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { Div, DotsImg, DownloadContainer, Row, } from '../../Common/Table/TableRow/styles';
import { Cell, DownloadTag, Img } from './style';
import { DOTS_TEXT, TOWERS_TEXT, APARTMENTS_SECOND_TEXT, DATE_TEXT, PENDING, PAYED, PDF_KEY, DOWNLOAD, PAY, TYPE_CONTACT, FOURTH, ID, THIRD, DELETE, EDIT, SEND, DRAFT, PREVIEW, RECEIPT, EXPIRED_STATUS, MODIFY, SECOND, APARTMENTS_API, ASSOCIATED_CONTACTS, INFORMED, IN_PROGRESS, } from 'src/Utils/constants';
import { formatDate, downloadPDF } from 'src/Utils/utils';
import { ROUTES } from 'src/Utils/constants';
import { Popover } from 'src/Components/Common';
import { DownloadArrow, MessageDelete, MessageEdit, MessagePreview, MessageSend, } from 'src/assets/Images';
import { Dots } from 'src/Components/Common';
import { Dots as DotsImage } from 'src/assets/Images';
import { getData } from 'src/Utils/api';
import { deleteCommonExpensesReceipt } from 'src/Views/DocumentsPages/CommonExpensesPage/services';
var DotsRow = function (_a) {
    var data = _a.data, widths = _a.widths, handleDelete = _a.handleDelete, historyText = _a.historyText, topIndex = _a.topIndex, _b = _a.isCommonExpenses, isCommonExpenses = _b === void 0 ? false : _b, handlePaymentModal = _a.handlePaymentModal, isMessage = _a.isMessage, state = _a.state, setSelected = _a.setSelected, submit = _a.submit, setSelectedId = _a.setSelectedId, setSelectedPDF = _a.setSelectedPDF, selectedBuilding = _a.selectedBuilding, selectedTower = _a.selectedTower, selectedApartment = _a.selectedApartment, deleteRow = _a.deleteRow, rows = _a.rows, setOpenModalContacts = _a.setOpenModalContacts;
    var history = useHistory();
    var isContact = Cookies.get('User') === TYPE_CONTACT;
    var isSuperAdmin = Cookies.get('SuperAdmin') === 'true';
    var _c = useState(null), anchorRef = _c[0], setAnchorRef = _c[1];
    var handleClick = function (e) {
        return anchorRef ? setAnchorRef(null) : setAnchorRef(e.currentTarget);
    };
    var _d = useState([]), apartments = _d[0], setApartments = _d[1];
    var _e = useState([]), contacts = _e[0], setContacts = _e[1];
    var _f = useState({
        label: '',
        value: '',
    }), selectedOption = _f[0], setSelectedOption = _f[1];
    useEffect(function () {
        switch (selectedOption.label) {
            case DELETE:
                var id = data.ID;
                onDelete(id);
                break;
            case MODIFY:
                history.push({
                    pathname: ROUTES.edit_receipt + "/" + data.ID,
                    state: {
                        selectedBuilding: selectedBuilding,
                        selectedTower: selectedTower,
                        selectedApartment: selectedApartment,
                        apt: isSuperAdmin ? data.fifth.slice(-1) : data.second.slice(-1),
                        rows: rows,
                    },
                });
                break;
            case ASSOCIATED_CONTACTS:
                var apartment_id = data === null || data === void 0 ? void 0 : data.apartment_id;
                history.push(ROUTES.apartments + "/" + apartment_id + "/contactos");
        }
    }, [selectedOption]);
    var renderCommonExpensesButton = function () {
        if (data[THIRD] === PENDING && isContact)
            return (React.createElement(DownloadContainer, { onClick: function () {
                    setSelectedId === null || setSelectedId === void 0 ? void 0 : setSelectedId(data.ID);
                    handlePaymentModal === null || handlePaymentModal === void 0 ? void 0 : handlePaymentModal(true);
                }, className: "underline" }, PAY));
        else if (!isContact && !isCommonExpenses)
            return (React.createElement("div", { onClick: function () { return setSelected && setSelected(data.ID); } },
                React.createElement(Dots, { handleDelete: handleDelete, historyText: historyText + "/" + (isCommonExpenses ? data.ID : '') })));
    };
    var onDelete = function (id) {
        mutateDeleteCommonExpensesReceipt({ id: id });
        deleteRow === null || deleteRow === void 0 ? void 0 : deleteRow(id);
    };
    var mutateDeleteCommonExpensesReceipt = useMutation(deleteCommonExpensesReceipt, {})[0];
    var _g = useQuery([APARTMENTS_API], function () { return getData("/buildings/" + (selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value) + "/apartments"); }, {
        onSuccess: function (response) {
            setApartments(response.apartments);
        },
        refetchOnWindowFocus: false,
        retry: false,
        enabled: !!selectedBuilding,
    });
    var renderCommonExpensesDownloadButton = function (index) { return (React.createElement(Cell, { style: { width: widths[index] } },
        React.createElement(Div, null,
            React.createElement(DownloadContainer, null,
                React.createElement(DownloadTag, { onClick: function () {
                        setSelectedId === null || setSelectedId === void 0 ? void 0 : setSelectedId(data.ID);
                        data.pdf
                            ? downloadPDF(data.pdf, RECEIPT + "-" + data.receiptNumber)
                            : setSelectedPDF && setSelectedPDF(data.ID);
                    } }, DOWNLOAD),
                React.createElement("img", { src: DownloadArrow, alt: DOWNLOAD }))))); };
    var renderMessageIcons = function (index) { return (React.createElement(Cell, { style: { width: widths[index] }, className: "dots" }, state === DRAFT ? (React.createElement(Div, { className: "message" },
        React.createElement(Img, { src: MessageEdit, alt: EDIT, onClick: function () { return history.push(ROUTES.edit_message + "/" + data.id); } }),
        React.createElement(Img, { src: MessageSend, alt: SEND, onClick: function () { return submit && submit(data.id); } }),
        React.createElement(Img, { src: MessageDelete, alt: DELETE, onClick: function () {
                setSelected && setSelected({ title: data.first, id: data.id });
                handleDelete && handleDelete();
            } }))) : (React.createElement(Img, { src: MessagePreview, alt: PREVIEW, onClick: function () { return history.push(ROUTES.view_message + "/" + data.id); } })))); };
    var renderIcons = function (key, index) {
        return isMessage ? (renderMessageIcons(index)) : (React.createElement(Cell, { style: { width: widths[index] }, className: "dots" }, ((((data[FOURTH] === PENDING && !isContact) ||
            (data[THIRD] === PENDING && isContact)) &&
            isCommonExpenses) ||
            !isCommonExpenses) &&
            renderCommonExpensesButton()));
    };
    var renderDots = function (key, index, isContact) {
        var _a, _b;
        var isMultiple = false;
        var selectedApartment = apartments.filter(function (apartment) { return apartment.id === data.apartment_id; });
        if (selectedApartment.length &&
            (((_a = selectedApartment[0]) === null || _a === void 0 ? void 0 : _a.tenant) || ((_b = selectedApartment[0]) === null || _b === void 0 ? void 0 : _b.proprietary))) {
            isMultiple = true;
        }
        return (React.createElement(Cell, { style: { width: widths[index] } }, !isContact && (React.createElement(React.Fragment, null,
            React.createElement(DotsImg, { src: DotsImage, alt: "M\u00E1s", onClick: handleClick }),
            React.createElement(Popover, { data: isMultiple
                    ? [
                        { label: DELETE, value: DELETE },
                        { label: MODIFY, value: MODIFY },
                        {
                            label: ASSOCIATED_CONTACTS,
                            value: ASSOCIATED_CONTACTS,
                        },
                    ]
                    : [
                        { label: DELETE, value: DELETE },
                        { label: MODIFY, value: MODIFY },
                    ], handleOutsideClick: function () { return setAnchorRef(null); }, anchorRef: anchorRef, setSelected: setSelectedOption, className: "filter width", isFilter: false, isContact: isContact })))));
    };
    var cellIcons = function (key, index) {
        return index < topIndex &&
            key !== PDF_KEY &&
            key !== ID && (React.createElement(Cell, { style: { width: widths[index] }, className: (key === TOWERS_TEXT || key === APARTMENTS_SECOND_TEXT
                ? 'center'
                : ((key === FOURTH ||
                    (key === THIRD && isSuperAdmin) ||
                    (key === THIRD && isContact)) &&
                    data[key] === PENDING) ||
                    data[key] === EXPIRED_STATUS ||
                    data[key] === PAYED ||
                    data[key] === INFORMED ||
                    data[key] === IN_PROGRESS
                    ? 'bold'
                    : '') + " \n        " + (data[key] === IN_PROGRESS ? 'yellow' : '') + "\n        " + (data[key] === PAYED || data[key] === INFORMED ? 'green' : '') + "\n        " + (data[key] === EXPIRED_STATUS ? 'red' : '') + "\n        " + ((isMessage && index === 0) || (key === SECOND && !isContact)
                ? 'pointer'
                : ''), onClick: function () {
                if (isMessage && index === 0)
                    history.push(ROUTES.view_message + "/" + data.id);
            } }, key === DATE_TEXT ? formatDate(data[key]) : data[key]));
    };
    return (React.createElement(Row, { hover: true, tabIndex: -1, key: data.first }, Object.keys(data).map(function (key, index) {
        return isCommonExpenses && key === PDF_KEY
            ? renderCommonExpensesDownloadButton(index)
            : isCommonExpenses && key === DOTS_TEXT
                ? renderDots(key, index, isContact)
                : key === DOTS_TEXT
                    ? renderIcons(key, index)
                    : !(isMessage && key === 'id') && cellIcons(key, index);
    })));
};
export default DotsRow;
