var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M } from '../../../Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  z-index: 2;\n  flex-direction: column;\n  align-items: center;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    margin-bottom: 30px;\n    &.page {\n      background-color: #2d5068;\n      width: 25%;\n      padding: 20px 0px;\n      min-width: 200px;\n      border-radius: 7px;\n    }\n  }\n"], ["\n  display: flex;\n  z-index: 2;\n  flex-direction: column;\n  align-items: center;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    margin-bottom: 30px;\n    &.page {\n      background-color: #2d5068;\n      width: 25%;\n      padding: 20px 0px;\n      min-width: 200px;\n      border-radius: 7px;\n    }\n  }\n"])), theme.palette.primary[50], M);
export var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: space-around;\n  text-align: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: space-around;\n  text-align: center;\n"])));
export var Title = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 18px;\n  font-weight: 600;\n  margin-bottom: 5px;\n  color: ", ";\n  font-family: ", ";\n  @media (max-width: ", "px) {\n    font-size: 14px;\n  }\n"], ["\n  font-size: 18px;\n  font-weight: 600;\n  margin-bottom: 5px;\n  color: ", ";\n  font-family: ", ";\n  @media (max-width: ", "px) {\n    font-size: 14px;\n  }\n"])), theme.palette.white[100], theme.typography.normal.raleway, M);
export var ImageContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  justify-content: center;\n  display: flex;\n  align-items: center;\n  padding: 10px 0px;\n"], ["\n  width: 100%;\n  justify-content: center;\n  display: flex;\n  align-items: center;\n  padding: 10px 0px;\n"])));
export var Image = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 70px;\n  border-radius: 100%;\n  max-height: 100px;\n"], ["\n  width: 70px;\n  border-radius: 100%;\n  max-height: 100px;\n"])));
export var Description = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  text-align: center;\n  font-size: 18px;\n  font-weight: normal;\n  line-height: 19px;\n  margin-top: 5px;\n  color: ", ";\n  font-family: ", ";\n  @media (max-width: ", "px) {\n    font-size: 14px;\n  }\n"], ["\n  width: 100%;\n  text-align: center;\n  font-size: 18px;\n  font-weight: normal;\n  line-height: 19px;\n  margin-top: 5px;\n  color: ", ";\n  font-family: ", ";\n  @media (max-width: ", "px) {\n    font-size: 14px;\n  }\n"])), theme.palette.white[100], theme.typography.normal.raleway, M);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
