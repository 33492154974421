import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useMutation, useQuery } from 'react-query';
import { BeatLoader } from 'react-spinners';
import { AdminLayout } from 'src/Layouts';
import Table from 'src/Components/GenericTable';
import { M, NAME, FIRST, MODIFY_BUILDING_TEXT, ADMINS, LAST_NAME, MAIL, TYPE_TEXT, CREATE_ADMIN, ADMINS_API, getData, SUPERADMIN, ADMINISTRATOR, stableSort, getComparator, includesWord, theme, ROUTES, ADMINISTRATOR_KEY, } from 'src/Utils';
import { DeleteModal, MaintenanceCard } from 'src/Components/Common';
import { useWindowWidth } from 'src/useWindowWidth';
import { Container, Title, TableContainer } from '../styles';
import { deleteAdmin } from './services';
var headCells = [
    { id: 'first', numeric: false, disablePadding: false, label: NAME },
    { id: 'second', numeric: false, disablePadding: false, label: LAST_NAME },
    { id: 'third', numeric: false, disablePadding: false, label: MAIL },
    { id: 'fourth', numeric: false, disablePadding: false, label: TYPE_TEXT },
    { id: 'fifth', numeric: true, disablePadding: false, label: '' },
];
var AdminsPage = function () {
    var _a = useState(''), searchedItem = _a[0], setSearchedItem = _a[1];
    var _b = useState(), selectedAdmin = _b[0], setSelectedAdmin = _b[1];
    var _c = useState(false), isDeleteModalOpen = _c[0], setIsDeleteModalOpen = _c[1];
    var _d = useState(FIRST), orderBy = _d[0], setOrderBy = _d[1];
    var _e = useState(), rows = _e[0], setRows = _e[1];
    var windowWidth = useWindowWidth();
    var isSmallScreen = M >= windowWidth;
    var history = useHistory();
    var isSuperAdmin = Cookies.get('SuperAdmin') === 'true';
    var WIDTHS = isSuperAdmin
        ? ['25%', '25%', '25%', '25%']
        : ['20%', '20%', '30%', '20%', '10%'];
    var onDelete = function (id) {
        id && mutateDeleteAdmin({ id: id });
    };
    var mutateDeleteAdmin = useMutation(deleteAdmin, {
        onSuccess: function () { return setIsDeleteModalOpen(function (prev) { return !prev; }); },
    })[0];
    var isLoading = useQuery([ADMINS_API, isDeleteModalOpen], function () { return getData("/admin_users"); }, {
        onSuccess: function (_a) {
            var admin_users = _a.admin_users;
            setRows(admin_users === null || admin_users === void 0 ? void 0 : admin_users.map(function (_a) {
                var first_name = _a.first_name, last_name = _a.last_name, email = _a.email, superadmin = _a.superadmin, id = _a.id;
                return ({
                    first: first_name,
                    second: last_name,
                    third: email,
                    fourth: superadmin ? SUPERADMIN : ADMINISTRATOR,
                    fifth: '',
                    id: id,
                });
            }));
        },
        retry: false,
    }).isLoading;
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, null, ADMINS),
            React.createElement(TableContainer, null,
                rows &&
                    (isSmallScreen ? (stableSort(rows, getComparator('asc', orderBy)).map(function (_a, index) {
                        var first = _a.first, second = _a.second, third = _a.third, fourth = _a.fourth;
                        return includesWord(first, searchedItem) && (React.createElement(MaintenanceCard, { key: first + index, first: first, second: second, third: third, fourth: fourth, handleDelete: function () {
                                return setIsDeleteModalOpen(!isDeleteModalOpen);
                            }, historyText: MODIFY_BUILDING_TEXT }));
                    })) : (React.createElement(Table, { rows: rows, headCells: headCells, handleButtonClick: isSuperAdmin
                            ? function () { return history.push(ROUTES.create_admin); }
                            : undefined, modalButton: CREATE_ADMIN, handleDelete: function () { return setIsDeleteModalOpen(function (prev) { return !prev; }); }, hasDots: true, widths: WIDTHS, historyText: MODIFY_BUILDING_TEXT, topIndex: 5, className: "left", shouldDeleteAdmins: isSuperAdmin ? isSuperAdmin : false, setSelectedAdmin: setSelectedAdmin }))),
                isLoading && (React.createElement(BeatLoader, { size: "8px", color: theme.palette.black[100] }))),
            isSuperAdmin && (React.createElement(DeleteModal, { isOpen: isDeleteModalOpen, setIsOpen: setIsDeleteModalOpen, onDelete: function () { return onDelete(selectedAdmin === null || selectedAdmin === void 0 ? void 0 : selectedAdmin.id); }, itemLabel: ADMINISTRATOR_KEY, toDelete: (selectedAdmin === null || selectedAdmin === void 0 ? void 0 : selectedAdmin.first) + " " + (selectedAdmin === null || selectedAdmin === void 0 ? void 0 : selectedAdmin.second) })))));
};
export default AdminsPage;
