var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { View, Image, Text } from '@react-pdf/renderer';
import { SUSPENDED } from 'src/Utils';
import { BackgroundLogo } from 'src/assets/Images';
import styles from './styles';
export var PDFWatermarks = function (_a) {
    var printable = _a.printable, _b = _a.suspended, suspended = _b === void 0 ? false : _b;
    return (React.createElement(React.Fragment, null,
        !printable && !suspended && (React.createElement(React.Fragment, null,
            React.createElement(View, { fixed: true, style: styles.backgroundCircle3 }),
            React.createElement(View, { fixed: true, style: styles.backgroundCircle2 }),
            React.createElement(View, { fixed: true, style: styles.backgroundCircle1 }),
            React.createElement(View, { style: styles.watermarkLogo, fixed: true },
                React.createElement(Image, { src: BackgroundLogo, style: { height: 70 } })))),
        suspended && (React.createElement("div", { style: __assign(__assign({}, styles.defaultSuspendedWatermark), (printable ?
                styles.suspendedPrintable : styles.suspendedNotPrintable)) }, SUSPENDED ? React.createElement(Text, null, SUSPENDED) : null))));
};
