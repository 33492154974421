import React from 'react';
import GoogleMapReact from 'google-map-react';
import { defaultOptions } from './utils';
import { Marker } from 'src/assets/Images';
import { Column, Title, Text } from '../../styles';
import { MapContainer } from './styles';
export var Location = function (_a) {
    var description = _a.description, coords = _a.coords;
    var renderMarker = function (map, maps) {
        return new maps.Marker({
            position: coords,
            map: map,
            visible: true,
            optimized: false,
            animation: maps.Animation.DROP,
            icon: Marker,
        });
    };
    return (React.createElement(Column, { gap: "12px" },
        React.createElement(Title, null, "Ubicaci\u00F3n"),
        React.createElement(Text, null, description),
        React.createElement(MapContainer, null,
            React.createElement(GoogleMapReact, { bootstrapURLKeys: {
                    key: process.env.REACT_APP_GOOGLE_API_KEY || '',
                }, yesIWantToUseGoogleMapApiInternals: true, defaultCenter: coords, defaultZoom: 15, options: defaultOptions, onGoogleApiLoaded: function (_a) {
                    var map = _a.map, maps = _a.maps;
                    return renderMarker(map, maps);
                } }))));
};
