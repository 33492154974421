var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M } from '../../Utils';
export var Title = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 24px;\n  color: ", ";\n  margin-bottom: 36px;\n  margin-left: 20px;\n  width: 100%;\n  @media (max-width: ", "px) {\n    margin-left: 28px;\n    width: auto;\n    font-size: 18px;\n    font-family: ", ";\n    color: ", ";\n    align-self: baseline;\n  }\n"], ["\n  font-size: 24px;\n  color: ", ";\n  margin-bottom: 36px;\n  margin-left: 20px;\n  width: 100%;\n  @media (max-width: ", "px) {\n    margin-left: 28px;\n    width: auto;\n    font-size: 18px;\n    font-family: ", ";\n    color: ", ";\n    align-self: baseline;\n  }\n"])), theme.palette.admin.primary[75], M, theme.typography.normal.raleway, theme.palette.black[100]);
export var Line = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 0.5px solid;\n  color: ", ";\n  @media (max-width: ", "px) {\n    display: none;\n  }\n"], ["\n  border: 0.5px solid;\n  color: ", ";\n  @media (max-width: ", "px) {\n    display: none;\n  }\n"])), theme.palette.admin.primary[75], M);
export var Container = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  background-color: ", ";\n  border-radius: 21px;\n  align-self: flex-start;\n  padding: 20px 0;\n  padding-bottom: 40px;\n  &.row {\n    flex-direction: row;\n    width: 100%;\n    margin-left: 0px;\n    padding: 0px;\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  background-color: ", ";\n  border-radius: 21px;\n  align-self: flex-start;\n  padding: 20px 0;\n  padding-bottom: 40px;\n  &.row {\n    flex-direction: row;\n    width: 100%;\n    margin-left: 0px;\n    padding: 0px;\n  }\n"])), theme.palette.white[100]);
export var ContentContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  box-sizing: border-box;\n  width: 100%;\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n  box-shadow: ", ";\n  @media (max-width: ", "px) {\n    width: 90%;\n    margin-top: 0px;\n    &.margin {\n      margin-bottom: 40px;\n    }\n    &:first-child {\n      margin-top: 30px;\n    }\n  }\n"], ["\n  box-sizing: border-box;\n  width: 100%;\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n  box-shadow: ", ";\n  @media (max-width: ", "px) {\n    width: 90%;\n    margin-top: 0px;\n    &.margin {\n      margin-bottom: 40px;\n    }\n    &:first-child {\n      margin-top: 30px;\n    }\n  }\n"])), theme.palette.blueShadow[15], M);
export var Subtitle = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: 18px;\n  width: 100%;\n  margin-bottom: 10px;\n  color: ", ";\n  font-weight: bold;\n  line-height: 21px;\n  @media (max-width: ", "px) {\n    margin-left: 0px;\n    font-size: 18px;\n    font-family: ", ";\n    color: ", ";\n  }\n"], ["\n  font-family: ", ";\n  font-size: 18px;\n  width: 100%;\n  margin-bottom: 10px;\n  color: ", ";\n  font-weight: bold;\n  line-height: 21px;\n  @media (max-width: ", "px) {\n    margin-left: 0px;\n    font-size: 18px;\n    font-family: ", ";\n    color: ", ";\n  }\n"])), theme.typography.bold.ralewayBold, theme.palette.black[100], M, theme.typography.OpenSans, theme.palette.black[100]);
export var CarouselsContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  gap: 10px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  gap: 10px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
