var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useFormContext } from 'react-hook-form';
import { RESERVATION_API_EDIT, getData } from '../../../Utils';
var useBookingValues = function (_a) {
    var id = _a.id;
    var _b = useFormContext(), watch = _b.watch, setValue = _b.setValue;
    var _c = watch(), amenity = _c.amenity, date = _c.date, extras = _c.extras, hasInitialSchedule = _c.hasInitialSchedule;
    var data = useQuery([RESERVATION_API_EDIT, id], function () { return getData("/bookings/" + id); }, {
        enabled: !!id,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    }).data;
    useEffect(function () {
        if (amenity.label) {
            var matchedAmenity = extras.find(function (item) { return item.name === amenity.label; });
            if (matchedAmenity)
                setValue('selectedExtras', matchedAmenity.extras.map(function (extra) { return (__assign(__assign({}, extra), { selected: !!(data === null || data === void 0 ? void 0 : data.extras.includes(extra.name)) })); }));
        }
    }, [extras, amenity]);
    useEffect(function () {
        if (hasInitialSchedule)
            setValue('time', {
                label: '',
                value: 0,
                price: 0,
            });
    }, [amenity, date]);
    return { data: data };
};
export default useBookingValues;
