import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { AdminLayout } from 'src/Layouts';
import { Container, Title, TableContainer } from '../styles';
import { stableSort, getComparator } from 'src/Utils/utils';
import Table from 'src/Components/GenericTable';
import { M, NAME, FIRST, BUILDINGS, DIRECTION, APARTMENTS, MODIFY_BUILDING_TEXT, CONTACTS, } from 'src/Utils/constants';
import { MaintenanceCard } from 'src/Components/Common';
import { useWindowWidth } from 'src/useWindowWidth';
import { getData } from 'src/Utils/api';
// En esta función el id es el id del edificio. Se agregó buildingId que también es el id del edificio,
// para poder mostrar un componente diferente en app/javascript/src/Components/GenericTable/TableRow/index.tsx L:57
function createData(first, address, id, buildingId, apartments) {
    return { first: first, address: address, id: id, buildingId: buildingId, apartments: apartments };
}
var headCells = [
    { id: 'first', disablePadding: false, label: NAME },
    { id: 'address', disablePadding: false, label: DIRECTION },
    { id: 'id', disablePadding: false, label: CONTACTS },
    { id: 'buildingId', disablePadding: false, label: '' },
    { id: 'apartments', disablePadding: false, label: APARTMENTS },
];
var WIDTHS = ['25%', '25%', '25%', '25%'];
var Buildings = function () {
    var _a = useState(FIRST), orderBy = _a[0], setOrderBy = _a[1];
    var _b = useState([]), rows = _b[0], setRows = _b[1];
    var windowWidth = useWindowWidth();
    var isSmallScreen = M >= windowWidth;
    var isLoadingBuildings = useQuery("getBuildingsInTable", function () { return getData("/buildings"); }, {
        onSuccess: function (data) {
            setRows(data.buildings.map(function (_a) {
                var name = _a.name, address = _a.address, id = _a.id, apartments = _a.apartments;
                return createData(name, address, id, id, apartments);
            }));
        },
        refetchOnWindowFocus: false
    }).isLoading;
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, null, BUILDINGS),
            React.createElement(TableContainer, null, isSmallScreen ? (stableSort(rows, getComparator('asc', orderBy)).map(function (_a, index) {
                var first = _a.first, address = _a.address, apartments = _a.apartments;
                return (React.createElement(MaintenanceCard, { key: first + index, first: first, second: address, third: apartments.toString(), historyText: MODIFY_BUILDING_TEXT }));
            })) : (React.createElement(Table, { rows: rows, headCells: headCells, widths: WIDTHS, historyText: MODIFY_BUILDING_TEXT, topIndex: 5, isLoading: isLoadingBuildings }))))));
};
export default Buildings;
