import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import { BeatLoader } from 'react-spinners';
import { Container, Title } from '../styles';
import { AdminLayout } from 'src/Layouts';
import { NewMessageForm } from 'src/Components';
import { getData, MESSAGE_DETAIL_API, EDIT_MESSAGE, theme, SEE_MESSAGE, ALL_ENG, } from 'src/Utils';
var EditMessage = function (_a) {
    var _b = _a.isView, isView = _b === void 0 ? false : _b;
    var id = useParams().id;
    var _c = useState(), viewMessage = _c[0], setViewMessage = _c[1];
    var _d = useState(false), wasSent = _d[0], setWasSent = _d[1];
    var isLoading = useQuery(MESSAGE_DETAIL_API, function () { return getData("/messages/" + id); }, {
        onSuccess: function (_a) {
            var body = _a.body, building_id = _a.building_id, files = _a.files, images = _a.images, id = _a.id, contacts = _a.contacts, status = _a.status, subject = _a.subject, title = _a.title, all = _a.all, building_name = _a.building_name;
            var filesArray = files
                ? files.map(function (_a) {
                    var id = _a.id, name = _a.name, file = _a.file;
                    return { id: id, name: name, file: file, type: 'pdf' };
                })
                : [];
            var imagesArray = images
                ? images.map(function (_a) {
                    var id = _a.id, name = _a.name, file = _a.file;
                    return { id: id, name: name, file: file, type: 'image' };
                })
                : [];
            setViewMessage({
                body: body,
                building: building_id || 0,
                building_name: building_name || '',
                files: filesArray.concat(imagesArray),
                id: id,
                receiver: contacts === ALL_ENG ? contacts : (contacts === null || contacts === void 0 ? void 0 : contacts.length) ? contacts === null || contacts === void 0 ? void 0 : contacts.map(function (_a) {
                    var first_name = _a.first_name, last_name = _a.last_name, id = _a.id;
                    return { label: first_name + ' ' + last_name, value: id };
                }) : [],
                status: status,
                subject: subject,
                title: title,
                all: all || false,
            });
            if (isView && status === 'sent')
                setWasSent(true);
        },
        refetchOnWindowFocus: false,
    }).isLoading;
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, { className: "non-margin" }, isView ? SEE_MESSAGE : EDIT_MESSAGE),
            isLoading && (React.createElement(BeatLoader, { size: "8px", color: theme.palette.black[100] })),
            viewMessage && (React.createElement(NewMessageForm, { isEdit: !isView, isView: isView, viewMessage: viewMessage, wasSent: wasSent })))));
};
export default EditMessage;
