var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M } from '../../../Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  min-height: 100vh;\n  height: 100%;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 0px 5%;\n  flex-direction: column;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    padding: 0px 28px;\n  }\n"], ["\n  display: flex;\n  min-height: 100vh;\n  height: 100%;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 0px 5%;\n  flex-direction: column;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    padding: 0px 28px;\n  }\n"])), theme.palette.admin.primary[50], M);
export var TableContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 17px;\n  width: 100%;\n  background-color: ", ";\n  border-radius: 21px;\n  margin-bottom: 15px;\n  .ReactCollapse--collapse {\n    width: 100%;\n    .ReactCollapse--content {\n      @media (max-width: ", "px) {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n      }\n    }\n  }\n  @media (max-width: ", "px) {\n    background-color: ", ";\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 17px;\n  width: 100%;\n  background-color: ", ";\n  border-radius: 21px;\n  margin-bottom: 15px;\n  .ReactCollapse--collapse {\n    width: 100%;\n    .ReactCollapse--content {\n      @media (max-width: ", "px) {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n      }\n    }\n  }\n  @media (max-width: ", "px) {\n    background-color: ", ";\n  }\n"])), theme.palette.white[100], M, M, theme.palette.admin.primary[50]);
export var Label = styled.label(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 24px;\n  font-weight: 500;\n  color: ", ";\n  @media (max-width: ", "px) {\n    background-color: ", ";\n    color: ", ";\n    font-size: 16px;\n    padding: 5px 0px;\n    font-family: ", ";\n    margin-left: 28px;\n  }\n"], ["\n  font-size: 24px;\n  font-weight: 500;\n  color: ", ";\n  @media (max-width: ", "px) {\n    background-color: ", ";\n    color: ", ";\n    font-size: 16px;\n    padding: 5px 0px;\n    font-family: ", ";\n    margin-left: 28px;\n  }\n"])), theme.palette.admin.primary[75], M, theme.palette.admin.primary[45], theme.palette.black[100], theme.typography.OpenSans);
export var Img = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  cursor: pointer;\n  @media (max-width: ", "px) {\n    margin-right: 28px;\n  }\n"], ["\n  cursor: pointer;\n  @media (max-width: ", "px) {\n    margin-right: 28px;\n  }\n"])), M);
export var Div = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  @media (max-width: ", "px) {\n    display: flex;\n    justify-content: space-between;\n    position: absolute;\n    background-color: ", ";\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  @media (max-width: ", "px) {\n    display: flex;\n    justify-content: space-between;\n    position: absolute;\n    background-color: ", ";\n  }\n"])), M, theme.palette.admin.primary[45]);
export var Title = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 24px;\n  font-family: ", ";\n  width: 100%;\n  margin-bottom: 30px;\n  margin-top: 5%;\n  margin-left: -20px;\n  background-color: ", ";\n  color: ", ";\n  @media (max-width: ", "px) {\n    margin-left: 0px;\n    font-size: 18px;\n    font-family: ", ";\n    color: ", ";\n  }\n"], ["\n  font-size: 24px;\n  font-family: ", ";\n  width: 100%;\n  margin-bottom: 30px;\n  margin-top: 5%;\n  margin-left: -20px;\n  background-color: ", ";\n  color: ", ";\n  @media (max-width: ", "px) {\n    margin-left: 0px;\n    font-size: 18px;\n    font-family: ", ";\n    color: ", ";\n  }\n"])), theme.typography.bold.ralewayBold, theme.palette.admin.primary[50], theme.palette.admin.primary[75], M, theme.typography.OpenSans, theme.palette.black[100]);
export var UploadContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  z-index: 2;\n  right: 0;\n  bottom: 0;\n  position: fixed;\n"], ["\n  z-index: 2;\n  right: 0;\n  bottom: 0;\n  position: fixed;\n"])));
export var UploadButton = styled.img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
export var RelativeDiv = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: relative;\n  @media (max-width: ", "px) {\n    margin-bottom: 85px;\n  }\n"], ["\n  position: relative;\n  @media (max-width: ", "px) {\n    margin-bottom: 85px;\n  }\n"])), M);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
