import { StyleSheet, Font } from '@react-pdf/renderer';
import { theme } from 'src/Utils';
import Courier from '../../../../../public/fonts/CourierPrime-Regular.ttf';
import CourierBold from '../../../../../public/fonts/CourierPrime-Bold.ttf';
Font.register({
    family: 'CourierPrime',
    format: 'truetype',
    fonts: [
        { src: CourierBold, fontWeight: 'bold' },
        { src: Courier, fontWeight: 'normal' },
    ],
});
var styles = StyleSheet.create({
    page: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.white[100],
        padding: '30 20 40 20',
        flexGrow: 1,
        fontFamily: 'Courier',
        position: 'relative',
    },
    header: {
        padding: 20,
        borderRadius: 5,
        backgroundColor: theme.palette.primary[50],
        color: theme.palette.white[100],
        fontWeight: 'bold',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    title: {
        padding: 20,
        color: theme.palette.primary[50],
        fontWeight: 'bold',
    },
    logo: {
        height: 30,
    },
    informationContainer: {
        padding: '20 16',
    },
    information: {
        fontSize: 12,
        paddingRight: 5,
    },
    informationBold: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    table: {
        paddingBottom: 30,
    },
    tableName: {
        width: '100%',
        padding: '8 16',
        fontSize: 10,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    specialName: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bold',
        fontSize: 10,
        alignItems: 'center',
        textTransform: 'capitalize',
    },
    tableHeader: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: theme.palette.primary[14],
        fontWeight: 'bold',
        fontSize: 10,
        alignItems: 'center',
        textTransform: 'capitalize',
    },
    row: {
        color: theme.palette.black[80],
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 8,
        textTransform: 'capitalize',
    },
    rowDark: {
        color: theme.palette.black[80],
        backgroundColor: theme.palette.admin.primary[15],
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 8,
        textTransform: 'capitalize',
    },
    line: {
        backgroundColor: theme.palette.primary[80],
        width: '100%',
        height: 1,
        margin: '9 0',
    },
    lineTitle: {
        backgroundColor: theme.palette.primary[80],
        width: '100%',
        height: 1,
    },
    footerRow: {
        color: theme.palette.black[80],
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: 8,
        textTransform: 'capitalize',
    },
    footerRowDark: {
        color: theme.palette.black[80],
        backgroundColor: theme.palette.admin.primary[15],
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: 8,
        textTransform: 'capitalize',
    },
    note: {
        fontSize: 10,
        padding: '8 16',
        marginBottom: 30,
    },
    watermarkLogo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        position: 'absolute',
        bottom: 120,
        left: 0,
        right: 0,
    },
    footerContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        padding: '35 20',
    },
    footer: {
        height: 56,
        paddingTop: 6,
        paddingBottom: 6,
        borderRadius: 5,
        backgroundColor: theme.palette.primary[50],
        color: theme.palette.white[100],
        fontSize: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    footerLogos: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 5,
    },
    pageNumber: {
        position: 'absolute',
        bottom: 15,
        left: 0,
        right: 0,
        textAlign: 'center',
        fontSize: 8,
        marginTop: 5,
    },
    bold: {
        fontWeight: 'bold',
        fontFamily: 'CourierPrime',
    },
    normal: {
        fontWeight: 'normal',
        fontFamily: 'CourierPrime',
    },
});
export default styles;
