import React from 'react';
import { Container, HeaderContainer, Title, Description, CardsContainer, ElementComponent, } from './styles';
import { Card } from '../Common';
var AboutUs = function (_a) {
    var _b = _a.items, title = _b.title, description = _b.description, items = _b.items;
    return (React.createElement(ElementComponent, { name: "#" + title },
        React.createElement(Container, null,
            React.createElement(HeaderContainer, null,
                React.createElement(Title, null, title),
                React.createElement(Description, null, description)),
            React.createElement(CardsContainer, null, items.map(function (item) { return (React.createElement(Card, { key: item.title, item: item })); })))));
};
export default AboutUs;
